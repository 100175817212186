import React from "react";
import { inimports, usimports } from "./indiaimports";
export const gdpPD = {
  "1980": {
    Afghanistan: "n/a",
    Ethiopia: 445.42,
    Lebanon: 5333.97,
    Syria: 5616.25,
    Somalia: "n/a",
    Venezuela: "--",
    "Islamic Republic of Iran": 197079.02,
    Vietnam: 133.89,
    Indonesia: 422113.95,
    Korea: 1042003.32,
    Paraguay: 163757.23,
    Uzbekistan: "n/a",
    "Lao P.D.R.": 6169.15,
    Colombia: 79510.14,
    Mongolia: "n/a",
    Guinea: "n/a",
    Chile: 100616.79,
    Iceland: 72603.21,
    "Sierra Leone": 503.48,
    Cambodia: "n/a",
    "Costa Rica": 18071.99,
    Iraq: "n/a",
    Hungary: 69992.15,
    Kazakhstan: "n/a",
    Uganda: 199.37,
    Gabon: 1287451.25,
    Japan: 2190089.73,
    "Equatorial Guinea": 26072.65,
    Guyana: 2809.73,
    Tanzania: 4950.42,
    Armenia: "n/a",
    Argentina: "--",
    Madagascar: 25130.78,
    Myanmar: "n/a",
    Angola: "--",
    "Democratic Republic of the Congo": "--",
    "CÙte d'Ivoire": 366827.91,
    "Republic of Congo": 287921.87,
    Nigeria: "n/a",
    Yemen: "n/a",
    Rwanda: 27181.92,
    Serbia: "n/a",
    "Sri Lanka": 5341.12,
    Senegal: 162257.04,
    Jamaica: 2161.6,
    "Taiwan Province of China": 85195.8,
    Cameroon: 194598.59,
    Russia: "n/a",
    Benin: 142458.56,
    Uruguay: 37.67,
    Djibouti: "n/a",
    Sudan: 0.24,
    Norway: 77774.33,
    Albania: 6919.41,
    "Macao SAR": "n/a",
    "Dominican Republic": 1534.32,
    "Czech Republic": "n/a",
    Togo: 154846.39,
    Burundi: 20914.32,
    Malawi: 373.88,
    Mali: 60521.93,
    "Burkina Faso": 73878.66,
    Algeria: 8705.67,
    Comoros: 144649.35,
    "Guinea-Bissau": 78212.7,
    Sweden: 71383.57,
    Mauritius: 9744.51,
    Denmark: 78262.77,
    "Hong Kong SAR": 28383.39,
    Niger: 128760.99,
    "North Macedonia": "n/a",
    Chad: 36799.75,
    "Cabo Verde": 21752.1,
    Vanuatu: 71969.55,
    Bhutan: 2373.92,
    Pakistan: 3810.53,
    "Central African Republic": 73887.28,
    Kenya: 9685.18,
    Seychelles: 14889.11,
    Bangladesh: 4391.75,
    "South Sudan": "n/a",
    Thailand: 14445.8,
    Maldives: 3114.43,
    India: 2140.94,
    Haiti: 2239.13,
    Qatar: 99624.85,
    Mexico: 77.63,
    Philippines: 5817.48,
    Zimbabwe: "n/a",
    Nepal: 1808.08,
    Suriname: "n/a",
    Ukraine: "n/a",
    Israel: 31.24,
    "United Arab Emirates": 148348.66,
    Moldova: "n/a",
    "Kyrgyz Republic": "n/a",
    Turkey: 0.16,
    Croatia: "n/a",
    Luxembourg: 12839.4,
    "South Africa": "n/a",
    "Trinidad and Tobago": 14034.5,
    Botswana: 924.49,
    China: 460.01,
    Ireland: 3857.51,
    Singapore: 10716.73,
    Egypt: 406.03,
    Australia: 9641.88,
    "Saudi Arabia": 58733.92,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Switzerland: 32615.9,
    Poland: 7.05,
    "United States": 12552.94,
    Romania: 3.69,
    Honduras: 1910.5,
    Namibia: "n/a",
    "New Zealand": 7438.78,
    Eswatini: 1054.44,
    Canada: 13189.11,
    Malaysia: 4194.7,
    "The Gambia": 1734.39,
    "St. Kitts and Nevis": 3614.62,
    Aruba: "n/a",
    Netherlands: 12389.46,
    "São Tomé and Príncipe": 32.3,
    Austria: 10076.42,
    Brazil: "--",
    Finland: 7059.3,
    Mozambique: 13.85,
    Germany: 10309.04,
    Turkmenistan: "n/a",
    Belgium: 9068.88,
    "Antigua and Barbuda": 5184.67,
    "San Marino": "n/a",
    Guatemala: 1070.28,
    France: 8407.94,
    "Brunei Darussalam": "n/a",
    Barbados: 8073.02,
    "United Kingdom": 4609.68,
    Morocco: 4456.64,
    "Puerto Rico": 4507.15,
    Andorra: "n/a",
    "St. Lucia": 3946.23,
    "The Bahamas": 12289.31,
    Malta: "n/a",
    Italy: 3774.3,
    Grenada: 3363.17,
    Zambia: 0.57,
    Peru: "--",
    Slovenia: "n/a",
    Spain: 2634.27,
    Estonia: "n/a",
    Cyprus: 2743.13,
    Dominica: "n/a",
    Bolivia: 0.02,
    Belarus: "n/a",
    "St. Vincent and the Grenadines": 2258.71,
    Bulgaria: 3.64,
    Portugal: 833.16,
    "Solomon Islands": 658.16,
    Lithuania: "n/a",
    Georgia: "n/a",
    "Slovak Republic": "n/a",
    Ghana: 0.96,
    Latvia: "n/a",
    Lesotho: 265.18,
    Libya: 3858.08,
    Greece: 735.57,
    Panama: 2071.04,
    Palau: "n/a",
    Fiji: 1681.8,
    Tonga: 676.51,
    "Bosnia and Herzegovina": "n/a",
    Tajikistan: "n/a",
    Tunisia: 600.54,
    Nauru: "n/a",
    "Papua New Guinea": 925.72,
    Samoa: "n/a",
    "West Bank and Gaza": "n/a",
    Eritrea: "n/a",
    Bahrain: 3841.49,
    Tuvalu: "n/a",
    Belize: 2296.65,
    Montenegro: "n/a",
    Azerbaijan: "n/a",
    Kuwait: 5667.1,
    Oman: 1614.44,
    Ecuador: 2097.14,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 827.16,
    Micronesia: "n/a",
    Jordan: 505.92,
    Kiribati: 632.27,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1981": {
    Afghanistan: "n/a",
    Ethiopia: 446.29,
    Lebanon: 6387.52,
    Syria: 6965.21,
    Somalia: "n/a",
    Venezuela: "--",
    "Islamic Republic of Iran": 220382.19,
    Vietnam: 184.12,
    Indonesia: 465602.1,
    Korea: 1282686.82,
    Paraguay: 202666.31,
    Uzbekistan: "n/a",
    "Lao P.D.R.": 6924.46,
    Colombia: 97658.69,
    Mongolia: "n/a",
    Guinea: "n/a",
    Chile: 117184.36,
    Iceland: 111647.65,
    "Sierra Leone": 581.57,
    Cambodia: "n/a",
    "Costa Rica": 24186.69,
    Iraq: "n/a",
    Hungary: 75735.99,
    Kazakhstan: "n/a",
    Uganda: 313.24,
    Gabon: 1460990.13,
    Japan: 2332027.8,
    "Equatorial Guinea": 29837.48,
    Guyana: 2969.55,
    Tanzania: 5738.43,
    Armenia: "n/a",
    Argentina: "--",
    Madagascar: 28791.82,
    Myanmar: "n/a",
    Angola: "--",
    "Democratic Republic of the Congo": "--",
    "CÙte d'Ivoire": 382291.16,
    "Republic of Congo": 294479.55,
    Nigeria: "n/a",
    Yemen: "n/a",
    Rwanda: 29760.67,
    Serbia: "n/a",
    "Sri Lanka": 6382.8,
    Senegal: 184204.46,
    Jamaica: 2332.7,
    "Taiwan Province of China": 99177.5,
    Cameroon: 240888.9,
    Russia: "n/a",
    Benin: 118961.46,
    Uruguay: 49.65,
    Djibouti: "n/a",
    Sudan: 0.31,
    Norway: 88874.46,
    Albania: 7016.15,
    "Macao SAR": "n/a",
    "Dominican Republic": 1677.45,
    "Czech Republic": "n/a",
    Togo: 160004.76,
    Burundi: 21144.63,
    Malawi: 400.91,
    Mali: 63610.47,
    "Burkina Faso": 80749.18,
    Algeria: 9950.12,
    Comoros: 158813.73,
    "Guinea-Bissau": 125998.99,
    Sweden: 77960.52,
    Mauritius: 10603.95,
    Denmark: 86023.02,
    "Hong Kong SAR": 33509.07,
    Niger: 138590.04,
    "North Macedonia": "n/a",
    Chad: 54418.82,
    "Cabo Verde": 25458.08,
    Vanuatu: 83973.66,
    Bhutan: 2748.07,
    Pakistan: 4378.24,
    "Central African Republic": 92879.16,
    Kenya: 10722.32,
    Seychelles: 15177.64,
    Bangladesh: 4570.88,
    "South Sudan": "n/a",
    Thailand: 16254.39,
    Maldives: 3510.1,
    India: 2457.49,
    Haiti: 2421.22,
    Qatar: 101263.22,
    Mexico: 103.78,
    Philippines: 6555.26,
    Zimbabwe: "n/a",
    Nepal: 2066.3,
    Suriname: "n/a",
    Ukraine: "n/a",
    Israel: 73.32,
    "United Arab Emirates": 150183.98,
    Moldova: "n/a",
    "Kyrgyz Republic": "n/a",
    Turkey: 0.24,
    Croatia: "n/a",
    Luxembourg: 13978.07,
    "South Africa": "n/a",
    "Trinidad and Tobago": 15490.6,
    Botswana: 947.14,
    China: 491.81,
    Ireland: 4623.09,
    Singapore: 11983.41,
    Egypt: 432.36,
    Australia: 10880.54,
    "Saudi Arabia": 63699.66,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Switzerland: 34817.74,
    Poland: 7.66,
    "United States": 13948.7,
    Romania: 3.68,
    Honduras: 2034.13,
    Namibia: "n/a",
    "New Zealand": 8624.69,
    Eswatini: 1220.4,
    Canada: 14862.4,
    Malaysia: 4424.14,
    "The Gambia": 1790.12,
    "St. Kitts and Nevis": 4346.26,
    Aruba: "n/a",
    Netherlands: 12883.83,
    "São Tomé and Príncipe": 35.75,
    Austria: 10705.33,
    Brazil: "--",
    Finland: 7956.51,
    Mozambique: 10.62,
    Germany: 10740.65,
    Turkmenistan: "n/a",
    Belgium: 9500.36,
    "Antigua and Barbuda": 5878.14,
    "San Marino": "n/a",
    Guatemala: 1140.22,
    France: 9439.16,
    "Brunei Darussalam": "n/a",
    Barbados: 8854.11,
    "United Kingdom": 5142.84,
    Morocco: 4633.25,
    "Puerto Rico": 4940.78,
    Andorra: "n/a",
    "St. Lucia": 4443.36,
    "The Bahamas": 12493.75,
    Malta: "n/a",
    Italy: 4513.96,
    Grenada: 3446.2,
    Zambia: 0.62,
    Peru: 0,
    Slovenia: "n/a",
    Spain: 2974.1,
    Estonia: "n/a",
    Cyprus: 3120.89,
    Dominica: 3004.13,
    Bolivia: 0.03,
    Belarus: "n/a",
    "St. Vincent and the Grenadines": 2776.18,
    Bulgaria: 3.91,
    Portugal: 1007.33,
    "Solomon Islands": 707.11,
    Lithuania: "n/a",
    Georgia: "n/a",
    "Slovak Republic": "n/a",
    Ghana: 1.52,
    Latvia: "n/a",
    Lesotho: 293.33,
    Libya: 3186.28,
    Greece: 869.97,
    Panama: 2288.37,
    Palau: "n/a",
    Fiji: 1762.38,
    Tonga: 740.16,
    "Bosnia and Herzegovina": "n/a",
    Tajikistan: "n/a",
    Tunisia: 686.62,
    Nauru: "n/a",
    "Papua New Guinea": 887.17,
    Samoa: "n/a",
    "West Bank and Gaza": "n/a",
    Eritrea: "n/a",
    Bahrain: 4159.08,
    Tuvalu: "n/a",
    Belize: 2387.73,
    Montenegro: "n/a",
    Azerbaijan: "n/a",
    Kuwait: 4915.5,
    Oman: 1813.95,
    Ecuador: 2087.38,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 721.1,
    Micronesia: "n/a",
    Jordan: 606.95,
    Kiribati: 643.88,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1982": {
    Afghanistan: "n/a",
    Ethiopia: 456.55,
    Lebanon: 4768.48,
    Syria: 7049.08,
    Somalia: "n/a",
    Venezuela: "--",
    "Islamic Republic of Iran": 255495.93,
    Vietnam: 390.56,
    Indonesia: 490737.79,
    Korea: 1456697.54,
    Paraguay: 206202.36,
    Uzbekistan: "n/a",
    "Lao P.D.R.": 11853.09,
    Colombia: 120359.6,
    Mongolia: "n/a",
    Guinea: "n/a",
    Chile: 112221.23,
    Iceland: 173031.16,
    "Sierra Leone": 684.46,
    Cambodia: "n/a",
    "Costa Rica": 40097.51,
    Iraq: "n/a",
    Hungary: 82418.14,
    Kazakhstan: "n/a",
    Uganda: 492.13,
    Gabon: 1620195.88,
    Japan: 2434178.64,
    "Equatorial Guinea": 39791.2,
    Guyana: 2714.59,
    Tanzania: 6729.16,
    Armenia: "n/a",
    Argentina: 0,
    Madagascar: 36298.31,
    Myanmar: "n/a",
    Angola: "--",
    "Democratic Republic of the Congo": "--",
    "CÙte d'Ivoire": 399338.04,
    "Republic of Congo": 301186.59,
    Nigeria: "n/a",
    Yemen: "n/a",
    Rwanda: 30603.88,
    Serbia: "n/a",
    "Sri Lanka": 7358.91,
    Senegal: 212069.17,
    Jamaica: 2633.53,
    "Taiwan Province of China": 104668.87,
    Cameroon: 283377.8,
    Russia: "n/a",
    Benin: 133683.95,
    Uruguay: 51.85,
    Djibouti: "n/a",
    Sudan: 0.31,
    Norway: 98077.36,
    Albania: 7074.57,
    "Macao SAR": "n/a",
    "Dominican Republic": 1792.32,
    "Czech Republic": "n/a",
    Togo: 157364.81,
    Burundi: 21697.46,
    Malawi: 439.28,
    Mali: 66655.8,
    "Burkina Faso": 88765.22,
    Algeria: 10451.07,
    Comoros: 177975.2,
    "Guinea-Bissau": 169743.08,
    Sweden: 85296.59,
    Mauritius: 12225.1,
    Denmark: 98333.42,
    "Hong Kong SAR": 37263.3,
    Niger: 150540.54,
    "North Macedonia": "n/a",
    Chad: 60160.06,
    "Cabo Verde": 30176.47,
    Vanuatu: 89558.63,
    Bhutan: 3077.45,
    Pakistan: 4955.06,
    "Central African Republic": 99858.31,
    Kenya: 12026.3,
    Seychelles: 15031.13,
    Bangladesh: 5000.46,
    "South Sudan": "n/a",
    Thailand: 17650.6,
    Maldives: 3837.23,
    India: 2685.51,
    Haiti: 2473.84,
    Qatar: 82472.83,
    Mexico: 163.07,
    Philippines: 7203.25,
    Zimbabwe: "n/a",
    Nepal: 2199.55,
    Suriname: "n/a",
    Ukraine: "n/a",
    Israel: 163.87,
    "United Arab Emirates": 131303.06,
    Moldova: "n/a",
    "Kyrgyz Republic": "n/a",
    Turkey: 0.31,
    Croatia: "n/a",
    Luxembourg: 14106.32,
    "South Africa": "n/a",
    "Trinidad and Tobago": 17743.57,
    Botswana: 1167.2,
    China: 529.87,
    Ireland: 5426.71,
    Singapore: 12840.22,
    Egypt: 497.95,
    Australia: 12003.27,
    "Saudi Arabia": 51123.24,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Switzerland: 36590.16,
    Poland: 15.3,
    "United States": 14404.99,
    Romania: 3.66,
    Honduras: 2031.75,
    Namibia: "n/a",
    "New Zealand": 9803.16,
    Eswatini: 1312.86,
    Canada: 15475.89,
    Malaysia: 4685.84,
    "The Gambia": 1891.97,
    "St. Kitts and Nevis": 4987.58,
    Aruba: "n/a",
    Netherlands: 13332.93,
    "São Tomé and Príncipe": 35.92,
    Austria: 11476.97,
    Brazil: "--",
    Finland: 8900.6,
    Mozambique: 11.35,
    Germany: 11172.77,
    Turkmenistan: "n/a",
    Belgium: 10289.54,
    "Antigua and Barbuda": 6611.18,
    "San Marino": "n/a",
    Guatemala: 1126.19,
    France: 10784.74,
    "Brunei Darussalam": "n/a",
    Barbados: 9223.39,
    "United Kingdom": 5670.75,
    Morocco: 5307.37,
    "Puerto Rico": 5146.93,
    Andorra: "n/a",
    "St. Lucia": 4136.48,
    "The Bahamas": 13746.78,
    Malta: "n/a",
    Italy: 5323.45,
    Grenada: 3636.56,
    Zambia: 0.62,
    Peru: 0,
    Slovenia: "n/a",
    Spain: 3398.64,
    Estonia: "n/a",
    Cyprus: 3610.7,
    Dominica: 3287.45,
    Bolivia: 0.06,
    Belarus: "n/a",
    "St. Vincent and the Grenadines": 3068.68,
    Bulgaria: 4.07,
    Portugal: 1210.65,
    "Solomon Islands": 760.11,
    Lithuania: "n/a",
    Georgia: "n/a",
    "Slovak Republic": "n/a",
    Ghana: 1.72,
    Latvia: "n/a",
    Lesotho: 296,
    Libya: 3033.69,
    Greece: 1087.8,
    Panama: 2469.14,
    Palau: "n/a",
    Fiji: 1810.6,
    Tonga: 831.29,
    "Bosnia and Herzegovina": "n/a",
    Tajikistan: "n/a",
    Tunisia: 773.76,
    Nauru: "n/a",
    "Papua New Guinea": 902.25,
    Samoa: "n/a",
    "West Bank and Gaza": "n/a",
    Eritrea: "n/a",
    Bahrain: 4219.36,
    Tuvalu: "n/a",
    Belize: 2342.04,
    Montenegro: "n/a",
    Azerbaijan: "n/a",
    Kuwait: 4150.97,
    Oman: 1903.49,
    Ecuador: 2026.38,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 706.49,
    Micronesia: "n/a",
    Jordan: 664.76,
    Kiribati: 644.86,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1983": {
    Afghanistan: "n/a",
    Ethiopia: 491.96,
    Lebanon: 6234.47,
    Syria: 7268.25,
    Somalia: "n/a",
    Venezuela: "--",
    "Islamic Republic of Iran": 324024.84,
    Vietnam: 613.65,
    Indonesia: 599639.48,
    Korea: 1705823.42,
    Paraguay: 222137.04,
    Uzbekistan: "n/a",
    "Lao P.D.R.": 21140.03,
    Colombia: 144085.69,
    Mongolia: "n/a",
    Guinea: "n/a",
    Chile: 138842.05,
    Iceland: 294766.14,
    "Sierra Leone": 884.92,
    Cambodia: "n/a",
    "Costa Rica": 51669.95,
    Iraq: "n/a",
    Hungary: 87322.18,
    Kazakhstan: "n/a",
    Uganda: 701.84,
    Gabon: 1760143.15,
    Japan: 2526284.02,
    "Equatorial Guinea": 48169.17,
    Guyana: 2764.62,
    Tanzania: 7543.38,
    Armenia: "n/a",
    Argentina: 0,
    Madagascar: 42638.1,
    Myanmar: "n/a",
    Angola: "--",
    "Democratic Republic of the Congo": "--",
    "CÙte d'Ivoire": 397658.29,
    "Republic of Congo": 308046.38,
    Nigeria: "n/a",
    Yemen: "n/a",
    Rwanda: 32009.24,
    Serbia: "n/a",
    "Sri Lanka": 8913.23,
    Senegal: 213203.51,
    Jamaica: 3184.4,
    "Taiwan Province of China": 115454.17,
    Cameroon: 332349.11,
    Russia: "n/a",
    Benin: 142199.6,
    Uruguay: 70.22,
    Djibouti: "n/a",
    Sudan: 0.41,
    Norway: 108760.91,
    Albania: 6997.24,
    "Macao SAR": "n/a",
    "Dominican Republic": 1952.66,
    "Czech Republic": "n/a",
    Togo: 167490.81,
    Burundi: 23050.75,
    Malawi: 493.01,
    Mali: 73143.06,
    "Burkina Faso": 91728.9,
    Algeria: 11391.11,
    Comoros: 195704.1,
    "Guinea-Bissau": 217464.27,
    Sweden: 95616.11,
    Mauritius: 13810.19,
    Denmark: 108394.59,
    "Hong Kong SAR": 40613.45,
    Niger: 150769.75,
    "North Macedonia": "n/a",
    Chad: 72909.5,
    "Cabo Verde": 35809.65,
    Vanuatu: 92579.74,
    Bhutan: 3465.63,
    Pakistan: 5414.45,
    "Central African Republic": 102934.81,
    Kenya: 13090.03,
    Seychelles: 15433.28,
    Bangladesh: 5499.35,
    "South Sudan": "n/a",
    Thailand: 18962.41,
    Maldives: 3949.72,
    India: 3055.95,
    Haiti: 2608.35,
    Qatar: 65533.67,
    Mexico: 286.87,
    Philippines: 8175.83,
    Zimbabwe: "n/a",
    Nepal: 2507.29,
    Suriname: "n/a",
    Ukraine: "n/a",
    Israel: 414.55,
    "United Arab Emirates": 116334.24,
    Moldova: "n/a",
    "Kyrgyz Republic": "n/a",
    Turkey: 0.4,
    Croatia: "n/a",
    Luxembourg: 15518.56,
    "South Africa": "n/a",
    "Trinidad and Tobago": 16654.65,
    Botswana: 1343.27,
    China: 585.8,
    Ireland: 5940.44,
    Singapore: 14195.13,
    Egypt: 592.47,
    Australia: 12822.14,
    "Saudi Arabia": 41361.05,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Switzerland: 37500.2,
    Poland: 18.83,
    "United States": 15513.68,
    Romania: 3.65,
    Honduras: 2088.41,
    Namibia: "n/a",
    "New Zealand": 10562.32,
    Eswatini: 1345.64,
    Canada: 16629.18,
    Malaysia: 5082.41,
    "The Gambia": 2111.28,
    "St. Kitts and Nevis": 4940.56,
    Aruba: "n/a",
    Netherlands: 13800.08,
    "São Tomé and Príncipe": 33.89,
    Austria: 12266.4,
    Brazil: "--",
    Finland: 9870.47,
    Mozambique: 10.59,
    Germany: 11756.43,
    Turkmenistan: "n/a",
    Belgium: 10897.18,
    "Antigua and Barbuda": 7433.01,
    "San Marino": "n/a",
    Guatemala: 1139.96,
    France: 11903.24,
    "Brunei Darussalam": "n/a",
    Barbados: 9765.58,
    "United Kingdom": 6233.59,
    Morocco: 5532.86,
    "Puerto Rico": 5257.19,
    Andorra: "n/a",
    "St. Lucia": 4393.71,
    "The Bahamas": 14958.65,
    Malta: "n/a",
    Italy: 6194.51,
    Grenada: 3706.21,
    Zambia: 0.7,
    Peru: 0,
    Slovenia: "n/a",
    Spain: 3861.42,
    Estonia: "n/a",
    Cyprus: 3952.96,
    Dominica: 3636.06,
    Bolivia: 0.22,
    Belarus: "n/a",
    "St. Vincent and the Grenadines": 3272.89,
    Bulgaria: 4.17,
    Portugal: 1539.64,
    "Solomon Islands": 817.66,
    Lithuania: "n/a",
    Georgia: "n/a",
    "Slovak Republic": "n/a",
    Ghana: 3.63,
    Latvia: "n/a",
    Lesotho: 326.08,
    Libya: 2774.31,
    Greece: 1289.56,
    Panama: 2477.12,
    Palau: "n/a",
    Fiji: 1811.4,
    Tonga: 908.5,
    "Bosnia and Herzegovina": "n/a",
    Tajikistan: "n/a",
    Tunisia: 897.94,
    Nauru: "n/a",
    "Papua New Guinea": 996.46,
    Samoa: "n/a",
    "West Bank and Gaza": "n/a",
    Eritrea: "n/a",
    Bahrain: 4180.24,
    Tuvalu: "n/a",
    Belize: 2401.78,
    Montenegro: "n/a",
    Azerbaijan: "n/a",
    Kuwait: 3884.7,
    Oman: 1852.69,
    Ecuador: 1732.5,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 672.52,
    Micronesia: "n/a",
    Jordan: 690.68,
    Kiribati: 698.52,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1984": {
    Afghanistan: "n/a",
    Ethiopia: 450.07,
    Lebanon: 10533.22,
    Syria: 7230.87,
    Somalia: "n/a",
    Venezuela: "--",
    "Islamic Republic of Iran": 347926.6,
    Vietnam: 1076.11,
    Indonesia: 689644.44,
    Korea: 1945042.46,
    Paraguay: 279568.11,
    Uzbekistan: "n/a",
    "Lao P.D.R.": 29468.94,
    Colombia: 178162.42,
    Mongolia: "n/a",
    Guinea: "n/a",
    Chile: 166133.62,
    Iceland: 384743.97,
    "Sierra Leone": 1355.32,
    Cambodia: "n/a",
    "Costa Rica": 63288.23,
    Iraq: "n/a",
    Hungary: 95599.35,
    Kazakhstan: "n/a",
    Uganda: 1336.37,
    Gabon: 1899175.99,
    Japan: 2661523.17,
    "Equatorial Guinea": 56861.27,
    Guyana: 3299.2,
    Tanzania: 8624.03,
    Armenia: "n/a",
    Argentina: 0.03,
    Madagascar: 46371.23,
    Myanmar: "n/a",
    Angola: "--",
    "Democratic Republic of the Congo": "--",
    "CÙte d'Ivoire": 442250.88,
    "Republic of Congo": 315062.41,
    Nigeria: "n/a",
    Yemen: "n/a",
    Rwanda: 34963.62,
    Serbia: "n/a",
    "Sri Lanka": 11149.55,
    Senegal: 231555.38,
    Jamaica: 4133.28,
    "Taiwan Province of China": 126813.96,
    Cameroon: 394985.24,
    Russia: "n/a",
    Benin: 154026.55,
    Uruguay: 107.8,
    Djibouti: "n/a",
    Sudan: 0.49,
    Norway: 122167.13,
    Albania: 6763.89,
    "Macao SAR": "n/a",
    "Dominican Republic": 2397.6,
    "Czech Republic": "n/a",
    Togo: 169974.23,
    Burundi: 26216.22,
    Malawi: 565.82,
    Mali: 82030.24,
    "Burkina Faso": 91641.11,
    Algeria: 12462.81,
    Comoros: 214167.64,
    "Guinea-Bissau": 169847.19,
    Sweden: 107336.05,
    Mauritius: 14908.69,
    Denmark: 119740.5,
    "Hong Kong SAR": 48240.99,
    Niger: 135458.64,
    "North Macedonia": "n/a",
    Chad: 87758.72,
    "Cabo Verde": 39579.55,
    Vanuatu: 110288.2,
    Bhutan: 3939.46,
    Pakistan: 6068.42,
    "Central African Republic": 110340.91,
    Kenya: 14219.43,
    Seychelles: 16504.16,
    Bangladesh: 6427.1,
    "South Sudan": "n/a",
    Thailand: 19977.24,
    Maldives: 4557.04,
    India: 3346.37,
    Haiti: 2839.07,
    Qatar: 63687.39,
    Mexico: 462.2,
    Philippines: 11337.43,
    Zimbabwe: "n/a",
    Nepal: 2897.77,
    Suriname: "n/a",
    Ukraine: "n/a",
    Israel: 2020.92,
    "United Arab Emirates": 106521.75,
    Moldova: "n/a",
    "Kyrgyz Republic": "n/a",
    Turkey: 0.61,
    Croatia: "n/a",
    Luxembourg: 17191.26,
    "South Africa": "n/a",
    "Trinidad and Tobago": 16394.92,
    Botswana: 1687.65,
    China: 698.58,
    Ireland: 6532.27,
    Singapore: 15273.44,
    Egypt: 648.18,
    Australia: 14270.23,
    "Saudi Arabia": 37206.99,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Switzerland: 40061.45,
    Poland: 23.12,
    "United States": 17086.44,
    Romania: 3.64,
    Honduras: 2184.91,
    Namibia: "n/a",
    "New Zealand": 12032.85,
    Eswatini: 1475.13,
    Canada: 18062.74,
    Malaysia: 5670.35,
    "The Gambia": 2068.5,
    "St. Kitts and Nevis": 5790.96,
    Aruba: "n/a",
    Netherlands: 14374.71,
    "São Tomé and Príncipe": 35.9,
    Austria: 12875.97,
    Brazil: "--",
    Finland: 10985.54,
    Mozambique: 11.48,
    Germany: 12383.33,
    Turkmenistan: "n/a",
    Belgium: 11779.19,
    "Antigua and Barbuda": 8642.66,
    "San Marino": "n/a",
    Guatemala: 1163.06,
    France: 12879.71,
    "Brunei Darussalam": "n/a",
    Barbados: 10616.63,
    "United Kingdom": 6694.75,
    Morocco: 6124.44,
    "Puerto Rico": 5777.44,
    Andorra: "n/a",
    "St. Lucia": 5525.97,
    "The Bahamas": 15627.02,
    Malta: "n/a",
    Italy: 7082.16,
    Grenada: 3990.37,
    Zambia: 0.81,
    Peru: 0,
    Slovenia: "n/a",
    Spain: 4309.92,
    Estonia: "n/a",
    Cyprus: 4588.29,
    Dominica: 4037.23,
    Bolivia: 3.26,
    Belarus: "n/a",
    "St. Vincent and the Grenadines": 3588.36,
    Bulgaria: 4.42,
    Portugal: 1883.4,
    "Solomon Islands": 880.4,
    Lithuania: "n/a",
    Georgia: "n/a",
    "Slovak Republic": "n/a",
    Ghana: 5.01,
    Latvia: "n/a",
    Lesotho: 384.46,
    Libya: 2490.24,
    Greece: 1595.53,
    Panama: 2527.53,
    Palau: "n/a",
    Fiji: 1979.91,
    Tonga: 973.46,
    "Bosnia and Herzegovina": "n/a",
    Tajikistan: "n/a",
    Tunisia: 999.52,
    Nauru: "n/a",
    "Papua New Guinea": 965.41,
    Samoa: "n/a",
    "West Bank and Gaza": "n/a",
    Eritrea: "n/a",
    Bahrain: 4197.45,
    Tuvalu: "n/a",
    Belize: 2609.02,
    Montenegro: "n/a",
    Azerbaijan: "n/a",
    Kuwait: 4015.38,
    Oman: 1905.71,
    Ecuador: 1794.73,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 488.78,
    Micronesia: "n/a",
    Jordan: 708.28,
    Kiribati: 767.71,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1985": {
    Afghanistan: "n/a",
    Ethiopia: 510.27,
    Lebanon: 22066.71,
    Syria: 7723,
    Somalia: "n/a",
    Venezuela: "--",
    "Islamic Republic of Iran": 329663.99,
    Vietnam: 2136.36,
    Indonesia: 731002.36,
    Korea: 2159737.61,
    Paraguay: 353937.67,
    Uzbekistan: "n/a",
    "Lao P.D.R.": 46366.21,
    Colombia: 230972.01,
    Mongolia: "n/a",
    Guinea: "n/a",
    Chile: 229122.96,
    Iceland: 519557.75,
    "Sierra Leone": 2295.1,
    Cambodia: "n/a",
    "Costa Rica": 74588.51,
    Iraq: "n/a",
    Hungary: 101389.57,
    Kazakhstan: "n/a",
    Uganda: 2622.33,
    Gabon: 2012002.37,
    Japan: 2818492.76,
    "Equatorial Guinea": 92393.35,
    Guyana: 3930.01,
    Tanzania: 10669.08,
    Armenia: "n/a",
    Argentina: 0.2,
    Madagascar: 50477.52,
    Myanmar: "n/a",
    Angola: "--",
    "Democratic Republic of the Congo": "--",
    "CÙte d'Ivoire": 446517.56,
    "Republic of Congo": 322238.24,
    Nigeria: "n/a",
    Yemen: "n/a",
    Rwanda: 36920.55,
    Serbia: "n/a",
    "Sri Lanka": 11660.63,
    Senegal: 253046.03,
    Jamaica: 4962.73,
    "Taiwan Province of China": 131256.03,
    Cameroon: 462258.86,
    Russia: "n/a",
    Benin: 159838.7,
    Uruguay: 189.18,
    Djibouti: "n/a",
    Sudan: 0.58,
    Norway: 135219.22,
    Albania: 6767.92,
    "Macao SAR": "n/a",
    "Dominican Republic": 3170.09,
    "Czech Republic": "n/a",
    Togo: 176914.73,
    Burundi: 29888.4,
    Malawi: 616.66,
    Mali: 83259.26,
    "Burkina Faso": 101508.25,
    Algeria: 13135.14,
    Comoros: 216856.38,
    "Guinea-Bissau": 251268.12,
    Sweden: 116578.94,
    Mauritius: 16757.27,
    Denmark: 129904.12,
    "Hong Kong SAR": 50564.87,
    Niger: 132895.65,
    "North Macedonia": "n/a",
    Chad: 86695.07,
    "Cabo Verde": 43646.83,
    Vanuatu: 103566.12,
    Bhutan: 4317.17,
    Pakistan: 6643.33,
    "Central African Republic": 146404.06,
    Kenya: 15614.63,
    Seychelles: 18467.6,
    Bangladesh: 7183.23,
    "South Sudan": "n/a",
    Thailand: 20977.41,
    Maldives: 5236.76,
    India: 3691.21,
    Haiti: 3096.75,
    Qatar: 56404.35,
    Mexico: 733.22,
    Philippines: 12063.61,
    Zimbabwe: "n/a",
    Nepal: 3391.36,
    Suriname: "n/a",
    Ukraine: "n/a",
    Israel: 7419.86,
    "United Arab Emirates": 99249.15,
    Moldova: "n/a",
    "Kyrgyz Republic": "n/a",
    Turkey: 0.96,
    Croatia: "n/a",
    Luxembourg: 18185.33,
    "South Africa": "n/a",
    "Trinidad and Tobago": 15707.47,
    Botswana: 2159.85,
    China: 860.46,
    Ireland: 7073.82,
    Singapore: 14935.47,
    Egypt: 734.53,
    Australia: 15621.04,
    "Saudi Arabia": 31631.24,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Switzerland: 42338.18,
    Poland: 27.95,
    "United States": 18199.32,
    Romania: 3.59,
    Honduras: 2323.84,
    Namibia: "n/a",
    "New Zealand": 13936.6,
    Eswatini: 1697.97,
    Canada: 19370.98,
    Malaysia: 5349.52,
    "The Gambia": 2515.07,
    "St. Kitts and Nevis": 6496.55,
    Aruba: "n/a",
    Netherlands: 14960.67,
    "São Tomé and Príncipe": 37.36,
    Austria: 13561.52,
    Brazil: "--",
    Finland: 11910.09,
    Mozambique: 15.28,
    Germany: 12960.73,
    Turkmenistan: "n/a",
    Belgium: 12521.8,
    "Antigua and Barbuda": 10162.73,
    "San Marino": "n/a",
    Guatemala: 1338.72,
    France: 13736.88,
    "Brunei Darussalam": 43818.98,
    Barbados: 11079.12,
    "United Kingdom": 7328.01,
    Morocco: 6896.11,
    "Puerto Rico": 6058.64,
    Andorra: "n/a",
    "St. Lucia": 6153.87,
    "The Bahamas": 16797.76,
    Malta: "n/a",
    Italy: 7945.05,
    Grenada: 4530.83,
    Zambia: 1.12,
    Peru: 0.01,
    Slovenia: "n/a",
    Spain: 4786.71,
    Estonia: "n/a",
    Cyprus: 5025.86,
    Dominica: 4435.43,
    Bolivia: 389.93,
    Belarus: "n/a",
    "St. Vincent and the Grenadines": 3843.22,
    Bulgaria: 4.55,
    Portugal: 2305.75,
    "Solomon Islands": 905.15,
    Lithuania: "n/a",
    Georgia: "n/a",
    "Slovak Republic": "n/a",
    Ghana: 6.59,
    Latvia: "n/a",
    Lesotho: 457.23,
    Libya: 2463.06,
    Greece: 1937.44,
    Panama: 2614.79,
    Palau: "n/a",
    Fiji: 2012.25,
    Tonga: 1149.71,
    "Bosnia and Herzegovina": "n/a",
    Tajikistan: "n/a",
    Tunisia: 1061.39,
    Nauru: "n/a",
    "Papua New Guinea": 975.93,
    Samoa: "n/a",
    "West Bank and Gaza": "n/a",
    Eritrea: "n/a",
    Bahrain: 3828.57,
    Tuvalu: "n/a",
    Belize: 2517.79,
    Montenegro: "n/a",
    Azerbaijan: "n/a",
    Kuwait: 3793.88,
    Oman: 2117.03,
    Ecuador: 2044.31,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 471.5,
    Micronesia: "n/a",
    Jordan: 701.7,
    Kiribati: 714.93,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1986": {
    Afghanistan: "n/a",
    Ethiopia: 513.32,
    Lebanon: 40070.81,
    Syria: 8976.27,
    Somalia: "n/a",
    Venezuela: "--",
    "Islamic Republic of Iran": 323750.21,
    Vietnam: 12707.82,
    Indonesia: 782662.18,
    Korea: 2498828.42,
    Paraguay: 453045.47,
    Uzbekistan: "n/a",
    "Lao P.D.R.": 66569.4,
    Colombia: 309302.75,
    Mongolia: "n/a",
    Guinea: "n/a",
    Chile: 290249.91,
    Iceland: 681747.74,
    "Sierra Leone": 5311.09,
    Cambodia: 22922.65,
    "Costa Rica": 90211.35,
    Iraq: "n/a",
    Hungary: 107192.18,
    Kazakhstan: "n/a",
    Uganda: 5390.14,
    Gabon: 1986179.6,
    Japan: 2943480.45,
    "Equatorial Guinea": 83611.68,
    Guyana: 4501.86,
    Tanzania: 13652.44,
    Armenia: "n/a",
    Argentina: 0.36,
    Madagascar: 57299.64,
    Myanmar: "n/a",
    Angola: "--",
    "Democratic Republic of the Congo": "--",
    "CÙte d'Ivoire": 443281.27,
    "Republic of Congo": 329577.5,
    Nigeria: "n/a",
    Yemen: "n/a",
    Rwanda: 34993.19,
    Serbia: "n/a",
    "Sri Lanka": 12762.2,
    Senegal: 267720.93,
    Jamaica: 5691.24,
    "Taiwan Province of China": 152003.46,
    Cameroon: 481175.42,
    Russia: "n/a",
    Benin: 152497.59,
    Uruguay: 349.96,
    Djibouti: "n/a",
    Sudan: 0.82,
    Norway: 139413.59,
    Albania: 6845.79,
    "Macao SAR": "n/a",
    "Dominican Republic": 3503.7,
    "Czech Republic": "n/a",
    Togo: 184815.92,
    Burundi: 28933.74,
    Malawi: 659.71,
    Mali: 87593.64,
    "Burkina Faso": 100005.62,
    Algeria: 13008.77,
    Comoros: 218083.58,
    "Guinea-Bissau": 185239.99,
    Sweden: 127175.34,
    Mauritius: 19510.36,
    Denmark: 139289.87,
    "Hong Kong SAR": 57589.52,
    Niger: 131142.03,
    "North Macedonia": "n/a",
    Chad: 79896.73,
    "Cabo Verde": 51758.92,
    Vanuatu: 97449.21,
    Bhutan: 4820.61,
    Pakistan: 7048.73,
    "Central African Republic": 143819.47,
    Kenya: 17742.71,
    Seychelles: 19555.38,
    Bangladesh: 7877.25,
    "South Sudan": "n/a",
    Thailand: 22099.44,
    Maldives: 5975.63,
    India: 4039.39,
    Haiti: 3379.78,
    Qatar: 42385.82,
    Mexico: 1177.79,
    Philippines: 12539.14,
    Zimbabwe: "n/a",
    Nepal: 3966.85,
    Suriname: "n/a",
    Ukraine: "n/a",
    Israel: 11352.21,
    "United Arab Emirates": 75376.71,
    Moldova: "n/a",
    "Kyrgyz Republic": "n/a",
    Turkey: 1.36,
    Croatia: "n/a",
    Luxembourg: 19891.94,
    "South Africa": "n/a",
    "Trinidad and Tobago": 14836.22,
    Botswana: 2423.95,
    China: 966.47,
    Ireland: 7533.75,
    Singapore: 14960.53,
    Egypt: 792.72,
    Australia: 16730.89,
    "Saudi Arabia": 25777.22,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Switzerland: 44211.84,
    Poland: 34.45,
    "United States": 19034.77,
    Romania: 3.67,
    Honduras: 2360.92,
    Namibia: "n/a",
    "New Zealand": 15987.7,
    Eswatini: 2138.08,
    Canada: 20202.56,
    Malaysia: 4811.81,
    "The Gambia": 3353.51,
    "St. Kitts and Nevis": 7698.39,
    Aruba: 11925.2,
    Netherlands: 15363.23,
    "São Tomé and Príncipe": 44.1,
    Austria: 14239.51,
    Brazil: "--",
    Finland: 12776.28,
    Mozambique: 16.76,
    Germany: 13696.14,
    Turkmenistan: "n/a",
    Belgium: 13105.08,
    "Antigua and Barbuda": 12467.55,
    "San Marino": "n/a",
    Guatemala: 1849.08,
    France: 14700.92,
    "Brunei Darussalam": 25930.81,
    Barbados: 12125.57,
    "United Kingdom": 7879.38,
    Morocco: 8048.79,
    "Puerto Rico": 6495.5,
    Andorra: "n/a",
    "St. Lucia": 7245.86,
    "The Bahamas": 17850.61,
    Malta: "n/a",
    Italy: 8782.76,
    Grenada: 5055.65,
    Zambia: 2.02,
    Peru: 0.02,
    Slovenia: "n/a",
    Spain: 5442.76,
    Estonia: "n/a",
    Cyprus: 5360.87,
    Dominica: 5035.2,
    Bolivia: 1228.09,
    Belarus: "n/a",
    "St. Vincent and the Grenadines": 4215.43,
    Bulgaria: 4.8,
    Portugal: 2815.29,
    "Solomon Islands": 921.97,
    Lithuania: "n/a",
    Georgia: "n/a",
    "Slovak Republic": "n/a",
    Ghana: 9.76,
    Latvia: "n/a",
    Lesotho: 530.55,
    Libya: 2055.3,
    Greece: 2308.29,
    Panama: 2658.35,
    Palau: "n/a",
    Fiji: 2213.72,
    Tonga: 1370.82,
    "Bosnia and Herzegovina": "n/a",
    Tajikistan: "n/a",
    Tunisia: 1041.98,
    Nauru: "n/a",
    "Papua New Guinea": 1009.88,
    Samoa: "n/a",
    "West Bank and Gaza": "n/a",
    Eritrea: "n/a",
    Bahrain: 2893.62,
    Tuvalu: "n/a",
    Belize: 2665.7,
    Montenegro: "n/a",
    Azerbaijan: "n/a",
    Kuwait: 2890.46,
    Oman: 1737.57,
    Ecuador: 1461.92,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 469.94,
    Micronesia: "n/a",
    Jordan: 767.26,
    Kiribati: 714.97,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1987": {
    Afghanistan: "n/a",
    Ethiopia: 531.37,
    Lebanon: 273861.77,
    Syria: 11095.01,
    Somalia: "n/a",
    Venezuela: "--",
    "Islamic Republic of Iran": 390274.79,
    Vietnam: 53094.14,
    Indonesia: 925326.39,
    Korea: 2923903.38,
    Paraguay: 596582.35,
    Uzbekistan: "n/a",
    "Lao P.D.R.": 84040.01,
    Colombia: 393846.99,
    Mongolia: "n/a",
    Guinea: "n/a",
    Chile: 382618.92,
    Iceland: 881671.07,
    "Sierra Leone": 9578.26,
    Cambodia: 15262.1,
    "Costa Rica": 101230.61,
    Iraq: "n/a",
    Hungary: 121317.73,
    Kazakhstan: "n/a",
    Uganda: 15224.9,
    Gabon: 1276059.78,
    Japan: 3063102.17,
    "Equatorial Guinea": 85432.36,
    Guyana: 9582.75,
    Tanzania: 17913.52,
    Armenia: "n/a",
    Argentina: 0.84,
    Madagascar: 65040.55,
    Myanmar: "n/a",
    Angola: "--",
    "Democratic Republic of the Congo": "--",
    "CÙte d'Ivoire": 407403.47,
    "Republic of Congo": 337083.92,
    Nigeria: "n/a",
    Yemen: "n/a",
    Rwanda: 34131.93,
    Serbia: "n/a",
    "Sri Lanka": 13850.7,
    Senegal: 271155.49,
    Jamaica: 6372.28,
    "Taiwan Province of China": 169578.37,
    Cameroon: 446847.53,
    Russia: "n/a",
    Benin: 150147.22,
    Uruguay: 649.34,
    Djibouti: "n/a",
    Sudan: 1.45,
    Norway: 151222.32,
    Albania: 6657.96,
    "Macao SAR": "n/a",
    "Dominican Republic": 4308.64,
    "Czech Republic": "n/a",
    Togo: 182431.01,
    Burundi: 28658.46,
    Malawi: 723.92,
    Mali: 87207.22,
    "Burkina Faso": 98405.57,
    Algeria: 13363.25,
    Comoros: 222779.53,
    "Guinea-Bissau": 133430.92,
    Sweden: 137139.25,
    Mauritius: 23534.05,
    Denmark: 146040.41,
    "Hong Kong SAR": 70302.74,
    Niger: 129350.6,
    "North Macedonia": "n/a",
    Chad: 76185.33,
    "Cabo Verde": 56758.11,
    Vanuatu: 108654.62,
    Bhutan: 5797.47,
    Pakistan: 7639.62,
    "Central African Republic": 132171.26,
    Kenya: 19034.3,
    Seychelles: 20378.36,
    Bangladesh: 8825.09,
    "South Sudan": "n/a",
    Thailand: 24892.47,
    Maldives: 7100.09,
    India: 4492.12,
    Haiti: 1635.61,
    Qatar: 42854.56,
    Mexico: 2848.86,
    Philippines: 13717.59,
    Zimbabwe: "n/a",
    Nepal: 4447.25,
    Suriname: "n/a",
    Ukraine: "n/a",
    Israel: 14299.1,
    "United Arab Emirates": 79455.39,
    Moldova: "n/a",
    "Kyrgyz Republic": "n/a",
    Turkey: 1.96,
    Croatia: "n/a",
    Luxembourg: 20556.94,
    "South Africa": "n/a",
    "Trinidad and Tobago": 14708.13,
    Botswana: 3143.43,
    China: 1116.01,
    Ireland: 8038.1,
    Singapore: 16415.48,
    Egypt: 1109.65,
    Australia: 18512.28,
    "Saudi Arabia": 24465.65,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Switzerland: 45576.27,
    Poland: 44.83,
    "United States": 20000.97,
    Romania: 3.68,
    Honduras: 2499.17,
    Namibia: "n/a",
    "New Zealand": 18895.97,
    Eswatini: 2386.86,
    Canada: 21756.91,
    Malaysia: 5215.6,
    "The Gambia": 4411.64,
    "St. Kitts and Nevis": 8880.47,
    Aruba: 14503.78,
    Netherlands: 15507.15,
    "São Tomé and Príncipe": 60.27,
    Austria: 14764.84,
    Brazil: "--",
    Finland: 13754.75,
    Mozambique: 54.21,
    Germany: 14142.36,
    Turkmenistan: "n/a",
    Belgium: 13625.18,
    "Antigua and Barbuda": 14729.21,
    "San Marino": "n/a",
    Guatemala: 2016.3,
    France: 15372.77,
    "Brunei Darussalam": 27881.97,
    Barbados: 13307.44,
    "United Kingdom": 8734.24,
    Morocco: 7962.81,
    "Puerto Rico": 6987.4,
    Andorra: "n/a",
    "St. Lucia": 7889.36,
    "The Bahamas": 19326.11,
    Malta: "n/a",
    Italy: 9608.29,
    Grenada: 5838.87,
    Zambia: 2.95,
    Peru: 0.03,
    Slovenia: "n/a",
    Spain: 6061.05,
    Estonia: "n/a",
    Cyprus: 5904.08,
    Dominica: 5678,
    Bolivia: 1404.85,
    Belarus: "n/a",
    "St. Vincent and the Grenadines": 4571.22,
    Bulgaria: 5.1,
    Portugal: 3318.03,
    "Solomon Islands": 1083.31,
    Lithuania: "n/a",
    Georgia: "n/a",
    "Slovak Republic": "n/a",
    Ghana: 13.75,
    Latvia: "n/a",
    Lesotho: 594.05,
    Libya: 1727.24,
    Greece: 2590.86,
    Panama: 2613.1,
    Palau: "n/a",
    Fiji: 2210.21,
    Tonga: 1562.95,
    "Bosnia and Herzegovina": "n/a",
    Tajikistan: "n/a",
    Tunisia: 1145.56,
    Nauru: "n/a",
    "Papua New Guinea": 1053.25,
    Samoa: "n/a",
    "West Bank and Gaza": "n/a",
    Eritrea: "n/a",
    Bahrain: 3026.56,
    Tuvalu: "n/a",
    Belize: 3037.82,
    Montenegro: "n/a",
    Azerbaijan: "n/a",
    Kuwait: 3280.61,
    Oman: 1725.99,
    Ecuador: 1332.15,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 472.15,
    Micronesia: "n/a",
    Jordan: 753.88,
    Kiribati: 756.81,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1988": {
    Afghanistan: "n/a",
    Ethiopia: 533.15,
    Lebanon: 498206.65,
    Syria: 15635.51,
    Somalia: "n/a",
    Venezuela: "--",
    "Islamic Republic of Iran": 412716.42,
    Vietnam: 222998.9,
    Indonesia: 1048394.84,
    Korea: 3473482.48,
    Paraguay: 765829.36,
    Uzbekistan: "n/a",
    "Lao P.D.R.": 119132.22,
    Colombia: 512847.8,
    Mongolia: "n/a",
    Guinea: "n/a",
    Chile: 495179.6,
    Iceland: 1068472.74,
    "Sierra Leone": 14682.19,
    Cambodia: 28499.91,
    "Costa Rica": 121131.26,
    Iraq: "n/a",
    Hungary: 143100.95,
    Kazakhstan: "n/a",
    Uganda: 46059.06,
    Gabon: 1364480.23,
    Japan: 3277491.49,
    "Equatorial Guinea": 88434.14,
    Guyana: 11258.71,
    Tanzania: 26719.98,
    Armenia: "n/a",
    Argentina: 3.95,
    Madagascar: 82515.29,
    Myanmar: "n/a",
    Angola: "--",
    "Democratic Republic of the Congo": "--",
    "CÙte d'Ivoire": 404277.26,
    "Republic of Congo": 344761.31,
    Nigeria: "n/a",
    Yemen: "n/a",
    Rwanda: 33852.93,
    Serbia: "n/a",
    "Sri Lanka": 15473.87,
    Senegal: 257839.17,
    Jamaica: 7550.03,
    "Taiwan Province of China": 181196.26,
    Cameroon: 403411.56,
    Russia: "n/a",
    Benin: 150038.11,
    Uruguay: 1058.92,
    Djibouti: "n/a",
    Sudan: 1.82,
    Norway: 157340.3,
    Albania: 6440.37,
    "Macao SAR": "n/a",
    "Dominican Republic": 6170.65,
    "Czech Republic": "n/a",
    Togo: 195274.36,
    Burundi: 29649.16,
    Malawi: 908.54,
    Mali: 85566.4,
    "Burkina Faso": 104871.56,
    Algeria: 14427.39,
    Comoros: 229975.98,
    "Guinea-Bissau": 120675.26,
    Sweden: 149130.36,
    Mauritius: 28280.33,
    Denmark: 151648.56,
    "Hong Kong SAR": 82177.34,
    Niger: 126892.17,
    "North Macedonia": "n/a",
    Chad: 85498.2,
    "Cabo Verde": 62112.24,
    Vanuatu: 114038.08,
    Bhutan: 6910.74,
    Pakistan: 8783.03,
    "Central African Republic": 136008.92,
    Kenya: 20571.57,
    Seychelles: 22224,
    Bangladesh: 9452.39,
    "South Sudan": "n/a",
    Thailand: 29354.64,
    Maldives: 7888.35,
    India: 5216.82,
    Haiti: 1062.86,
    Qatar: 39508.18,
    Mexico: 5667.24,
    Philippines: 15700.98,
    Zimbabwe: "n/a",
    Nepal: 5237.45,
    Suriname: "n/a",
    Ukraine: "n/a",
    Israel: 17469.83,
    "United Arab Emirates": 67675.6,
    Moldova: "n/a",
    "Kyrgyz Republic": "n/a",
    Turkey: 3.32,
    Croatia: "n/a",
    Luxembourg: 22719.67,
    "South Africa": "n/a",
    "Trinidad and Tobago": 14601.46,
    Botswana: 4718.13,
    China: 1369.99,
    Ireland: 8625.08,
    Singapore: 18773.81,
    Egypt: 1300.62,
    Australia: 20680.97,
    "Saudi Arabia": 23995.62,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Switzerland: 48023.79,
    Poland: 78.33,
    "United States": 21376,
    Romania: 3.71,
    Honduras: 2702.74,
    Namibia: "n/a",
    "New Zealand": 20798.05,
    Eswatini: 3029.3,
    Canada: 23434,
    Malaysia: 5797.67,
    "The Gambia": 4664.68,
    "St. Kitts and Nevis": 10602.78,
    Aruba: 17640.42,
    Netherlands: 16071.14,
    "São Tomé and Príncipe": 79.69,
    Austria: 15789.32,
    Brazil: "--",
    Finland: 15541.65,
    Mozambique: 87.2,
    Germany: 14800.47,
    Turkmenistan: "n/a",
    Belgium: 14562.02,
    "Antigua and Barbuda": 17677.6,
    "San Marino": "n/a",
    Guatemala: 2281.19,
    France: 16531.69,
    "Brunei Darussalam": 24556.32,
    Barbados: 14104.01,
    "United Kingdom": 9761.88,
    Morocco: 9053.12,
    "Puerto Rico": 7579.34,
    Andorra: "n/a",
    "St. Lucia": 8896.54,
    "The Bahamas": 19683.61,
    Malta: "n/a",
    Italy: 10674.29,
    Grenada: 6503.58,
    Zambia: 4.47,
    Peru: 0.2,
    Slovenia: "n/a",
    Spain: 6709.34,
    Estonia: "n/a",
    Cyprus: 6536.01,
    Dominica: 6420.34,
    Bolivia: 1673.69,
    Belarus: "n/a",
    "St. Vincent and the Grenadines": 5194.46,
    Bulgaria: 5.37,
    Portugal: 3964.69,
    "Solomon Islands": 1246.06,
    Lithuania: "n/a",
    Georgia: "n/a",
    "Slovak Republic": "n/a",
    Ghana: 18.81,
    Latvia: "n/a",
    Lesotho: 733.17,
    Libya: 1657.6,
    Greece: 3142.99,
    Panama: 2211.67,
    Palau: "n/a",
    Fiji: 2392.1,
    Tonga: 1664.54,
    "Bosnia and Herzegovina": "n/a",
    Tajikistan: "n/a",
    Tunisia: 1210.83,
    Nauru: "n/a",
    "Papua New Guinea": 1357.08,
    Samoa: "n/a",
    "West Bank and Gaza": "n/a",
    Eritrea: "n/a",
    Bahrain: 3610.65,
    Tuvalu: "n/a",
    Belize: 3519.08,
    Montenegro: "n/a",
    Azerbaijan: "n/a",
    Kuwait: 2886.55,
    Oman: 1677.55,
    Ecuador: 1234.67,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 543.47,
    Micronesia: "n/a",
    Jordan: 744.91,
    Kiribati: 831.84,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1989": {
    Afghanistan: "n/a",
    Ethiopia: 542.82,
    Lebanon: 488405.82,
    Syria: 16986.22,
    Somalia: "n/a",
    Venezuela: 0,
    "Islamic Republic of Iran": 501053.29,
    Vietnam: 550688.72,
    Indonesia: 1233537.17,
    Korea: 3905902.41,
    Paraguay: 1032805.33,
    Uzbekistan: "n/a",
    "Lao P.D.R.": 210429.8,
    Colombia: 647840.52,
    Mongolia: "n/a",
    Guinea: "n/a",
    Chile: 611177.53,
    Iceland: 1291801.93,
    "Sierra Leone": 24238.96,
    Cambodia: 34013.11,
    "Costa Rica": 143741.34,
    Iraq: "n/a",
    Hungary: 171865.46,
    Kazakhstan: "n/a",
    Uganda: 101751.93,
    Gabon: 1562880.2,
    Japan: 3495493.92,
    "Equatorial Guinea": 80786.25,
    Guyana: 31130.5,
    Tanzania: 32423.07,
    Armenia: "n/a",
    Argentina: 113.88,
    Madagascar: 90882.44,
    Myanmar: "n/a",
    Angola: "--",
    "Democratic Republic of the Congo": "--",
    "CÙte d'Ivoire": 398228.34,
    "Republic of Congo": 367101.43,
    Nigeria: "n/a",
    Yemen: "n/a",
    Rwanda: 35762.51,
    Serbia: "n/a",
    "Sri Lanka": 17383.22,
    Senegal: 264187.97,
    Jamaica: 9054.78,
    "Taiwan Province of China": 200056.89,
    Cameroon: 377409.44,
    Russia: "n/a",
    Benin: 144175.36,
    Uruguay: 1869.79,
    Djibouti: "n/a",
    Sudan: 3.13,
    Norway: 167402.69,
    Albania: 6886.27,
    "Macao SAR": "n/a",
    "Dominican Republic": 7790.61,
    "Czech Republic": "n/a",
    Togo: 199592.93,
    Burundi: 33823.76,
    Malawi: 1060.55,
    Mali: 94196.21,
    "Burkina Faso": 109377.03,
    Algeria: 17086.76,
    Comoros: 233218.06,
    "Guinea-Bissau": 152424.1,
    Sweden: 163829.14,
    Mauritius: 32726.7,
    Denmark: 160189,
    "Hong Kong SAR": 93697.55,
    Niger: 125924.16,
    "North Macedonia": "n/a",
    Chad: 83709.85,
    "Cabo Verde": 66516.39,
    Vanuatu: 122412.77,
    Bhutan: 7847.3,
    Pakistan: 9747.37,
    "Central African Republic": 141283.14,
    Kenya: 22892.95,
    Seychelles: 24881.81,
    Bangladesh: 10261.55,
    "South Sudan": "n/a",
    Thailand: 34395.87,
    Maldives: 8930.83,
    India: 5868.21,
    Haiti: 962.9,
    Qatar: 40185.37,
    Mexico: 7335.77,
    Philippines: 17758.03,
    Zimbabwe: "n/a",
    Nepal: 5939.56,
    Suriname: "n/a",
    Ukraine: "n/a",
    Israel: 20943.78,
    "United Arab Emirates": 75176.67,
    Moldova: "n/a",
    "Kyrgyz Republic": "n/a",
    Turkey: 5.74,
    Croatia: "n/a",
    Luxembourg: 25694.55,
    "South Africa": "n/a",
    "Trinidad and Tobago": 15409.3,
    Botswana: 5057.03,
    China: 1530.6,
    Ireland: 9625.53,
    Singapore: 20917.97,
    Egypt: 1586.51,
    Australia: 22931.24,
    "Saudi Arabia": 24687.36,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Switzerland: 51495.95,
    Poland: 255,
    "United States": 22814.08,
    Romania: 3.45,
    Honduras: 2934.06,
    Namibia: "n/a",
    "New Zealand": 21889.53,
    Eswatini: 3426.52,
    Canada: 24676.89,
    Malaysia: 6448.33,
    "The Gambia": 5322.05,
    "St. Kitts and Nevis": 11860.48,
    Aruba: 20148.53,
    Netherlands: 16915.97,
    "São Tomé and Príncipe": 111.26,
    Austria: 16849.34,
    Brazil: 0,
    Finland: 17344.12,
    Mozambique: 136.35,
    Germany: 15585.18,
    Turkmenistan: "n/a",
    Belgium: 15707.51,
    "Antigua and Barbuda": 19637.77,
    "San Marino": "n/a",
    Guatemala: 2565.71,
    France: 17720.36,
    "Brunei Darussalam": 26161.42,
    Barbados: 15553.43,
    "United Kingdom": 10766.58,
    Morocco: 9415.62,
    "Puerto Rico": 8094.29,
    Andorra: "n/a",
    "St. Lucia": 9904.92,
    "The Bahamas": 21814.31,
    Malta: "n/a",
    Italy: 11711.69,
    Grenada: 7446.25,
    Zambia: 7.76,
    Peru: 5.09,
    Slovenia: "n/a",
    Spain: 7509.71,
    Estonia: "n/a",
    Cyprus: 7294.46,
    Dominica: 6971.9,
    Bolivia: 1925.98,
    Belarus: "n/a",
    "St. Vincent and the Grenadines": 5528.79,
    Bulgaria: 5.57,
    Portugal: 4690.76,
    "Solomon Islands": 1307.28,
    Lithuania: "n/a",
    Georgia: "n/a",
    "Slovak Republic": "n/a",
    Ghana: 24.94,
    Latvia: "n/a",
    Lesotho: 854.66,
    Libya: 1761.73,
    Greece: 3719.68,
    Panama: 2171.73,
    Palau: "n/a",
    Fiji: 2591.56,
    Tonga: 1791.63,
    "Bosnia and Herzegovina": "n/a",
    Tajikistan: "n/a",
    Tunisia: 1315.81,
    Nauru: "n/a",
    "Papua New Guinea": 1249.58,
    Samoa: "n/a",
    "West Bank and Gaza": "n/a",
    Eritrea: "n/a",
    Bahrain: 3635.18,
    Tuvalu: "n/a",
    Belize: 3954.46,
    Montenegro: "n/a",
    Azerbaijan: "n/a",
    Kuwait: 3401.29,
    Oman: 1770.63,
    Ecuador: 1182.53,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 611.47,
    Micronesia: "n/a",
    Jordan: 736.73,
    Kiribati: 795.28,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1990": {
    Afghanistan: "n/a",
    Ethiopia: 557,
    Lebanon: 694291.28,
    Syria: 21093.44,
    Somalia: "n/a",
    Venezuela: 0,
    "Islamic Republic of Iran": 720077.48,
    Vietnam: 789051.56,
    Indonesia: 1420350.64,
    Korea: 4678321.77,
    Paraguay: 1457664.1,
    Uzbekistan: "n/a",
    "Lao P.D.R.": 291225.23,
    Colombia: 849031.46,
    Mongolia: 7034.16,
    Guinea: 406874.43,
    Chile: 763436.89,
    Iceland: 1495061.51,
    "Sierra Leone": 33236.47,
    Cambodia: 81861.5,
    "Costa Rica": 172201.42,
    Iraq: "n/a",
    Hungary: 209356.25,
    Kazakhstan: "n/a",
    Uganda: 150912.88,
    Gabon: 1855657.02,
    Japan: 3749540.16,
    "Equatorial Guinea": 84974.44,
    Guyana: 47948.12,
    Tanzania: 41162.83,
    Armenia: "n/a",
    Argentina: 2368.88,
    Madagascar: 101287.64,
    Myanmar: "n/a",
    Angola: "--",
    "Democratic Republic of the Congo": "--",
    "CÙte d'Ivoire": 361297.01,
    "Republic of Congo": 356748.95,
    Nigeria: 5248.05,
    Yemen: 12242.44,
    Rwanda: 34503.46,
    Serbia: "n/a",
    "Sri Lanka": 21983.17,
    Senegal: 254880.33,
    Jamaica: 14196.21,
    "Taiwan Province of China": 219313.81,
    Cameroon: 349320.78,
    Russia: "n/a",
    Benin: 146489.07,
    Uruguay: 4178.68,
    Djibouti: "n/a",
    Sudan: 3.92,
    Norway: 176444.47,
    Albania: 6087.18,
    "Macao SAR": "n/a",
    "Dominican Republic": 10866.33,
    "Czech Republic": "n/a",
    Togo: 199976.69,
    Burundi: 35469.2,
    Malawi: 1147.76,
    Mali: 105754.93,
    "Burkina Faso": 107780.82,
    Algeria: 22156.5,
    Comoros: 236578.95,
    "Guinea-Bissau": 155001.04,
    Sweden: 179066.26,
    Mauritius: 37988.93,
    Denmark: 166599.58,
    "Hong Kong SAR": 104182.55,
    Niger: 118528,
    "North Macedonia": "n/a",
    Chad: 83367.48,
    "Cabo Verde": 67045.81,
    Vanuatu: 133571.93,
    Bhutan: 9008.69,
    Pakistan: 10610.6,
    "Central African Republic": 139038.85,
    Kenya: 25720.3,
    Seychelles: 28300.75,
    Bangladesh: 11282.5,
    "South Sudan": "n/a",
    Thailand: 40020.14,
    Maldives: 10426.48,
    India: 6712.78,
    Haiti: 1200.94,
    Qatar: 47866.63,
    Mexico: 9704.03,
    Philippines: 20149.05,
    Zimbabwe: 1036.53,
    Nepal: 6713.25,
    Suriname: 13.63,
    Ukraine: "n/a",
    Israel: 25207.99,
    "United Arab Emirates": 97727.57,
    Moldova: "n/a",
    "Kyrgyz Republic": "n/a",
    Turkey: 9.75,
    Croatia: "n/a",
    Luxembourg: 27429.29,
    "South Africa": "n/a",
    "Trinidad and Tobago": 17942.71,
    Botswana: 5257.21,
    China: 1659.12,
    Ireland: 10447.32,
    Singapore: 23133.98,
    Egypt: 1967.41,
    Australia: 24112.27,
    "Saudi Arabia": 29006.13,
    Mauritania: 7244.66,
    Nicaragua: "n/a",
    Switzerland: 55411.04,
    Poland: 1547.6,
    "United States": 23847.98,
    Romania: 3.69,
    Honduras: 3459.2,
    Namibia: 5458.94,
    "New Zealand": 22514.04,
    Eswatini: 4016.74,
    Canada: 25169.8,
    Malaysia: 6994.33,
    "The Gambia": 6230.92,
    "St. Kitts and Nevis": 13418.67,
    Aruba: 21320.31,
    Netherlands: 17790.68,
    "São Tomé and Príncipe": 150.53,
    Austria: 17989.32,
    Brazil: 0.08,
    Finland: 18285.48,
    Mozambique: 253.35,
    Germany: 16687.27,
    Turkmenistan: "n/a",
    Belgium: 16622.06,
    "Antigua and Barbuda": 20583.27,
    "San Marino": "n/a",
    Guatemala: 3628.44,
    France: 18621.45,
    "Brunei Darussalam": 27865.13,
    Barbados: 15544.35,
    "United Kingdom": 11686.83,
    Morocco: 10293.11,
    "Puerto Rico": 8675.58,
    Andorra: "n/a",
    "St. Lucia": 11667.54,
    "The Bahamas": 20456.26,
    Malta: "n/a",
    Italy: 12738.39,
    Grenada: 7794.93,
    Zambia: 15.44,
    Peru: 244.53,
    Slovenia: "n/a",
    Spain: 8357.51,
    Estonia: "n/a",
    Cyprus: 8093.06,
    Dominica: 7612.79,
    Bolivia: 2295.2,
    Belarus: "n/a",
    "St. Vincent and the Grenadines": 6140.97,
    Bulgaria: 6.43,
    Portugal: 5644.53,
    "Solomon Islands": 1742.35,
    Lithuania: "n/a",
    Georgia: "n/a",
    "Slovak Republic": "n/a",
    Ghana: 34.1,
    Latvia: "n/a",
    Lesotho: 989.2,
    Libya: 2035.6,
    Greece: 4461.56,
    Panama: 2312.99,
    Palau: "n/a",
    Fiji: 2901.39,
    Tonga: 1939.87,
    "Bosnia and Herzegovina": "n/a",
    Tajikistan: "n/a",
    Tunisia: 1437.55,
    Nauru: "n/a",
    "Papua New Guinea": 1208.92,
    Samoa: "n/a",
    "West Bank and Gaza": "n/a",
    Eritrea: "n/a",
    Bahrain: 3827.54,
    Tuvalu: "n/a",
    Belize: 4360.7,
    Montenegro: "n/a",
    Azerbaijan: "n/a",
    Kuwait: 2501.21,
    Oman: 2437.93,
    Ecuador: 1173.13,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 914.13,
    Micronesia: "n/a",
    Jordan: 799.67,
    Kiribati: 761.39,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1991": {
    Afghanistan: "n/a",
    Ethiopia: 595.44,
    Lebanon: 1394978.06,
    Syria: 23779.39,
    Somalia: "n/a",
    Venezuela: 0,
    "Islamic Republic of Iran": 975402.79,
    Vietnam: 1416339.16,
    Indonesia: 1653022.33,
    Korea: 5600581.07,
    Paraguay: 2100736.53,
    Uzbekistan: "n/a",
    "Lao P.D.R.": 333327.72,
    Colombia: 1073468.22,
    Mongolia: 12406.5,
    Guinea: 497880.68,
    Chile: 988188.47,
    Iceland: 1603698.68,
    "Sierra Leone": 77304.75,
    Cambodia: 176418.85,
    "Costa Rica": 282233.94,
    Iraq: "n/a",
    Hungary: 250380.34,
    Kazakhstan: "n/a",
    Uganda: 175046.92,
    Gabon: 1690756.99,
    Japan: 3975432.86,
    "Equatorial Guinea": 84369.98,
    Guyana: 118371.73,
    Tanzania: 52065.83,
    Armenia: "n/a",
    Argentina: 6134.55,
    Madagascar: 100036.18,
    Myanmar: "n/a",
    Angola: "--",
    "Democratic Republic of the Congo": "--",
    "CÙte d'Ivoire": 349813.02,
    "Republic of Congo": 374273.51,
    Nigeria: 6102.76,
    Yemen: 13912.84,
    Rwanda: 37411.44,
    Serbia: "n/a",
    "Sri Lanka": 25132.76,
    Senegal: 252338.59,
    Jamaica: 21777.26,
    "Taiwan Province of China": 243524.22,
    Cameroon: 337607.32,
    Russia: "n/a",
    Benin: 148725.99,
    Uruguay: 8637.83,
    Djibouti: 190082.7,
    Sudan: 6.67,
    Norway: 184857.85,
    Albania: 5975.01,
    "Macao SAR": "n/a",
    "Dominican Republic": 17060.65,
    "Czech Republic": "n/a",
    Togo: 200074.81,
    Burundi: 37695.53,
    Malawi: 1467.78,
    Mali: 109271.22,
    "Burkina Faso": 109910.15,
    Algeria: 33620.56,
    Comoros: 252683.33,
    "Guinea-Bissau": 221778.97,
    Sweden: 190436.06,
    Mauritius: 43538.95,
    Denmark: 173041.17,
    "Hong Kong SAR": 118880.02,
    Niger: 111404.02,
    "North Macedonia": "n/a",
    Chad: 82893.39,
    "Cabo Verde": 69614.04,
    Vanuatu: 148905.77,
    Bhutan: 10149.35,
    Pakistan: 12330.7,
    "Central African Republic": 132960.66,
    Kenya: 28314.87,
    Seychelles: 28110.85,
    Bangladesh: 12142.67,
    "South Sudan": "n/a",
    Thailand: 45140.3,
    Maldives: 12146.36,
    India: 7560.81,
    Haiti: 1271.07,
    Qatar: 41861.89,
    Mexico: 12252.6,
    Philippines: 22818.44,
    Zimbabwe: 936.26,
    Nepal: 7829.46,
    Suriname: 15.5,
    Ukraine: "n/a",
    Israel: 30437.34,
    "United Arab Emirates": 94885.83,
    Moldova: "n/a",
    "Kyrgyz Republic": "n/a",
    Turkey: 15.38,
    Croatia: "n/a",
    Luxembourg: 29935.93,
    "South Africa": "n/a",
    "Trinidad and Tobago": 18669.69,
    Botswana: 5769.87,
    China: 1899.21,
    Ireland: 10808.94,
    Singapore: 25052.86,
    Egypt: 2230.4,
    Australia: 23914,
    "Saudi Arabia": 31050.65,
    Mauritania: 8211.18,
    Nicaragua: "n/a",
    Switzerland: 57170.96,
    Poland: 2228.24,
    "United States": 24302.78,
    Romania: 9.5,
    Honduras: 4374.85,
    Namibia: 5618.08,
    "New Zealand": 21521.54,
    Eswatini: 4328.33,
    Canada: 25074.86,
    Malaysia: 7934.78,
    "The Gambia": 7000.31,
    "St. Kitts and Nevis": 13264.87,
    Aruba: 23419.09,
    Netherlands: 18647.68,
    "São Tomé and Príncipe": 185.63,
    Austria: 19120.81,
    Brazil: 0.4,
    Finland: 17385.09,
    Mozambique: 391.06,
    Germany: 19829.13,
    Turkmenistan: "n/a",
    Belgium: 17345.44,
    "Antigua and Barbuda": 21404.49,
    "San Marino": "n/a",
    Guatemala: 4857.91,
    France: 19206.41,
    "Brunei Darussalam": 27162.76,
    Barbados: 15555.73,
    "United Kingdom": 12281.97,
    Morocco: 11410.89,
    "Puerto Rico": 9093.48,
    Andorra: "n/a",
    "St. Lucia": 12182.04,
    "The Bahamas": 19767.72,
    Malta: "n/a",
    Italy: 13902.73,
    Grenada: 8418.34,
    Zambia: 28.88,
    Peru: 1182.49,
    Slovenia: "n/a",
    Spain: 9149.08,
    Estonia: "n/a",
    Cyprus: 8249.21,
    Dominica: 8335.71,
    Bolivia: 2785.32,
    Belarus: "n/a",
    "St. Vincent and the Grenadines": 6460.53,
    Bulgaria: 19.38,
    Portugal: 6487.38,
    "Solomon Islands": 1925.67,
    Lithuania: "n/a",
    Georgia: "n/a",
    "Slovak Republic": "n/a",
    Ghana: 42.01,
    Latvia: "n/a",
    Lesotho: 1181.32,
    Libya: 2182.35,
    Greece: 5428.66,
    Panama: 2489.71,
    Palau: "n/a",
    Fiji: 2968.27,
    Tonga: 2259.06,
    "Bosnia and Herzegovina": "n/a",
    Tajikistan: "n/a",
    Tunisia: 1560.03,
    Nauru: "n/a",
    "Papua New Guinea": 1374.37,
    Samoa: "n/a",
    "West Bank and Gaza": "n/a",
    Eritrea: "n/a",
    Bahrain: 3854.89,
    Tuvalu: "n/a",
    Belize: 4584.75,
    Montenegro: "n/a",
    Azerbaijan: "n/a",
    Kuwait: 2302.1,
    Oman: 2283.07,
    Ecuador: 1286.62,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 983.18,
    Micronesia: "n/a",
    Jordan: 812.68,
    Kiribati: 828.87,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1992": {
    Afghanistan: "n/a",
    Ethiopia: 607.23,
    Lebanon: 3045898.75,
    Syria: 27560.01,
    Somalia: "n/a",
    Venezuela: 0,
    "Islamic Republic of Iran": 1300072.4,
    Vietnam: 2004884.75,
    Indonesia: 1842676.2,
    Korea: 6344082.95,
    Paraguay: 2388493.11,
    Uzbekistan: 26.05,
    "Lao P.D.R.": 378707.36,
    Colombia: 1351068.63,
    Mongolia: 30249.1,
    Guinea: 612121.6,
    Chile: 1221254.35,
    Iceland: 1575793.49,
    "Sierra Leone": 113987.86,
    Cambodia: 319801.35,
    "Costa Rica": 360961.45,
    Iraq: "n/a",
    Hungary: 294898.74,
    Kazakhstan: 142.84,
    Uganda: 244948.57,
    Gabon: 1592256.36,
    Japan: 4061590.29,
    "Equatorial Guinea": 93082.03,
    Guyana: 143108.48,
    Tanzania: 63455.99,
    Armenia: 85.51,
    Argentina: 7582.15,
    Madagascar: 112582.47,
    Myanmar: "n/a",
    Angola: "--",
    "Democratic Republic of the Congo": 0,
    "CÙte d'Ivoire": 335650.84,
    "Republic of Congo": 370281.17,
    Nigeria: 9083.32,
    Yemen: 16230.75,
    Rwanda: 42464.32,
    Serbia: "n/a",
    "Sri Lanka": 28380.55,
    Senegal: 246311.98,
    Jamaica: 41501.45,
    "Taiwan Province of China": 269646.63,
    Cameroon: 313405.9,
    Russia: 137.27,
    Benin: 152362.96,
    Uruguay: 14792.87,
    Djibouti: 193887.99,
    Sudan: 11.06,
    Norway: 189125.22,
    Albania: 19507.21,
    "Macao SAR": "n/a",
    "Dominican Republic": 19671.07,
    "Czech Republic": "n/a",
    Togo: 192737.27,
    Burundi: 39003.32,
    Malawi: 1522.28,
    Mali: 103146.41,
    "Burkina Faso": 107482.32,
    Algeria: 40908.04,
    Comoros: 252904.28,
    "Guinea-Bissau": 217898.96,
    Sweden: 189768.93,
    Mauritius: 48112.29,
    Denmark: 178805.21,
    "Hong Kong SAR": 137089.65,
    Niger: 104213.96,
    "North Macedonia": 6420.42,
    Chad: 78527.56,
    "Cabo Verde": 72266.66,
    Vanuatu: 153951.42,
    Bhutan: 11469.5,
    Pakistan: 14023.38,
    "Central African Republic": 123855.53,
    Kenya: 31641,
    Seychelles: 31389.29,
    Bangladesh: 12844.14,
    "South Sudan": "n/a",
    Thailand: 50780.04,
    Maldives: 14194.56,
    India: 8517.97,
    Haiti: 1217.17,
    Qatar: 47461.87,
    Mexico: 14308.79,
    Philippines: 24154.26,
    Zimbabwe: 748.65,
    Nepal: 9399.91,
    Suriname: 20.74,
    Ukraine: 1,
    Israel: 35428.54,
    "United Arab Emirates": 95302.7,
    Moldova: 64.46,
    "Kyrgyz Republic": 167.42,
    Turkey: 26.24,
    Croatia: 676.6,
    Luxembourg: 31190.26,
    "South Africa": "n/a",
    "Trinidad and Tobago": 19015.11,
    Botswana: 6153.63,
    China: 2316.3,
    Ireland: 11419.33,
    Singapore: 26285.4,
    Egypt: 2733.41,
    Australia: 24592.74,
    "Saudi Arabia": 30291.55,
    Mauritania: 8936.84,
    Nicaragua: "n/a",
    Switzerland: 57530.45,
    Poland: 3156.46,
    "United States": 25392.93,
    Romania: 26.1,
    Honduras: 4901.08,
    Namibia: 5905.96,
    "New Zealand": 21881.4,
    Eswatini: 4847.45,
    Canada: 25364.78,
    Malaysia: 8606.9,
    "The Gambia": 7388.69,
    "St. Kitts and Nevis": 14544.74,
    Aruba: 24862.5,
    Netherlands: 19263.14,
    "São Tomé and Príncipe": 253.38,
    Austria: 19972.34,
    Brazil: 4.14,
    Finland: 16858.61,
    Mozambique: 481.24,
    Germany: 21143.6,
    Turkmenistan: 0.03,
    Belgium: 18150.38,
    "Antigua and Barbuda": 21827.95,
    "San Marino": "n/a",
    Guatemala: 5405.54,
    France: 19803.41,
    "Brunei Darussalam": 28231.62,
    Barbados: 14929.73,
    "United Kingdom": 12686.95,
    Morocco: 11469.42,
    "Puerto Rico": 9687.44,
    Andorra: "n/a",
    "St. Lucia": 13171.84,
    "The Bahamas": 19417.22,
    Malta: "n/a",
    Italy: 14623.68,
    Grenada: 8618.99,
    Zambia: 73.18,
    Peru: 1943,
    Slovenia: 3222.96,
    Spain: 9819.23,
    Estonia: "n/a",
    Cyprus: 9323.78,
    Dominica: 8877.19,
    Bolivia: 3139.34,
    Belarus: 0,
    "St. Vincent and the Grenadines": 7048.46,
    Bulgaria: 28.98,
    Portugal: 7299.1,
    "Solomon Islands": 2387.19,
    Lithuania: "n/a",
    Georgia: "n/a",
    "Slovak Republic": "n/a",
    Ghana: 48.18,
    Latvia: 662.51,
    Lesotho: 1361.2,
    Libya: 2266.06,
    Greece: 6218.48,
    Panama: 2770.95,
    Palau: "n/a",
    Fiji: 3320.04,
    Tonga: 2415.03,
    "Bosnia and Herzegovina": "n/a",
    Tajikistan: 0.13,
    Tunisia: 1745.45,
    Nauru: "n/a",
    "Papua New Guinea": 1491.93,
    Samoa: "n/a",
    "West Bank and Gaza": "n/a",
    Eritrea: 750.94,
    Bahrain: 3961.85,
    Tuvalu: "n/a",
    Belize: 5208.43,
    Montenegro: "n/a",
    Azerbaijan: 0.65,
    Kuwait: 4103.22,
    Oman: 2419.53,
    Ecuador: 1375.71,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 1073.31,
    Micronesia: "n/a",
    Jordan: 948.42,
    Kiribati: 879.83,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1993": {
    Afghanistan: "n/a",
    Ethiopia: 753.15,
    Lebanon: 3987160.25,
    Syria: 29841.92,
    Somalia: "n/a",
    Venezuela: 0,
    "Islamic Republic of Iran": 1896812.17,
    Vietnam: 2500439.13,
    Indonesia: 2114352.26,
    Korea: 7131665.41,
    Paraguay: 2756426.78,
    Uzbekistan: 292.28,
    "Lao P.D.R.": 414760.74,
    Colombia: 1736496.35,
    Mongolia: 121566.53,
    Guinea: 610875.93,
    Chile: 1433583.08,
    Iceland: 1605533.91,
    "Sierra Leone": 147077.86,
    Cambodia: 666089.91,
    "Costa Rica": 416007.47,
    Iraq: "n/a",
    Hungary: 355883.64,
    Kazakhstan: 1768.13,
    Uganda: 295296.51,
    Gabon: 1607688.96,
    Japan: 4050404.04,
    "Equatorial Guinea": 97264.38,
    Guyana: 176533.44,
    Tanzania: 77277.14,
    Armenia: 1156.38,
    Argentina: 7796.24,
    Madagascar: 122698.04,
    Myanmar: "n/a",
    Angola: 0,
    "Democratic Republic of the Congo": 0.03,
    "CÙte d'Ivoire": 323650.31,
    "Republic of Congo": 353845.74,
    Nigeria: 12259.57,
    Yemen: 18716.73,
    Rwanda: 47000.78,
    Serbia: "n/a",
    "Sri Lanka": 32987.97,
    Senegal: 241425,
    Jamaica: 56117.28,
    "Taiwan Province of China": 295309.89,
    Cameroon: 300425.63,
    Russia: 1237.48,
    Benin: 159410.96,
    Uruguay: 22318.88,
    Djibouti: 187961.56,
    Sudan: 29.71,
    Norway: 197788.81,
    Albania: 46212.78,
    "Macao SAR": "n/a",
    "Dominican Republic": 21770.86,
    "Czech Republic": "n/a",
    Togo: 153839.4,
    Burundi: 39469.24,
    Malawi: 2127.71,
    Mali: 107456.5,
    "Burkina Faso": 106669.45,
    Algeria: 44237.53,
    Comoros: 259571.25,
    "Guinea-Bissau": 178115.93,
    Sweden: 189534.63,
    Mauritius: 55570.03,
    Denmark: 179219.3,
    "Hong Kong SAR": 155219.74,
    Niger: 97174.9,
    "North Macedonia": 32052.55,
    Chad: 71097.91,
    "Cabo Verde": 84147.52,
    Vanuatu: 155260.01,
    Bhutan: 13089.34,
    Pakistan: 15115.24,
    "Central African Republic": 117192.94,
    Kenya: 38479.69,
    Seychelles: 33986.13,
    Bangladesh: 13181.3,
    "South Sudan": "n/a",
    Thailand: 55940.19,
    Maldives: 16210.15,
    India: 9611.29,
    Haiti: 1803.62,
    Qatar: 42941.67,
    Mexico: 17558.56,
    Philippines: 25756.61,
    Zimbabwe: 702.44,
    Nepal: 10522.49,
    Suriname: 49.08,
    Ukraine: 30.7,
    Israel: 39924.37,
    "United Arab Emirates": 94177.19,
    Moldova: 613.24,
    "Kyrgyz Republic": 1189.68,
    Turkey: 46.81,
    Croatia: 9972.83,
    Luxembourg: 33957.03,
    "South Africa": 12140.19,
    "Trinidad and Tobago": 20032.27,
    Botswana: 6897.35,
    China: 3001.76,
    Ireland: 12231.57,
    Singapore: 29553.03,
    Egypt: 2992.19,
    Australia: 25609.27,
    "Saudi Arabia": 28822.47,
    Mauritania: 10298.86,
    Nicaragua: "n/a",
    Switzerland: 58197.61,
    Poland: 4267.24,
    "United States": 26364.19,
    Romania: 87.22,
    Honduras: 5750.74,
    Namibia: 6281.54,
    "New Zealand": 23183.07,
    Eswatini: 5717.48,
    Canada: 26073.26,
    Malaysia: 9567.79,
    "The Gambia": 7659.89,
    "St. Kitts and Nevis": 15676.43,
    Aruba: 26308.21,
    Netherlands: 19673.63,
    "São Tomé and Príncipe": 444.05,
    Austria: 20412.3,
    Brazil: 89.64,
    Finland: 16955.15,
    Mozambique: 735.86,
    Germany: 21630.35,
    Turkmenistan: 0.82,
    Belgium: 18607.51,
    "Antigua and Barbuda": 22879.9,
    "San Marino": "n/a",
    Guatemala: 6255.25,
    France: 19908.24,
    "Brunei Darussalam": 26840.9,
    Barbados: 15773.56,
    "United Kingdom": 13327.08,
    Morocco: 11521.56,
    "Puerto Rico": 10255.53,
    Andorra: "n/a",
    "St. Lucia": 13291.3,
    "The Bahamas": 18979.01,
    Malta: "n/a",
    Italy: 15049.56,
    Grenada: 8515.28,
    Zambia: 185.05,
    Peru: 2950.57,
    Slovenia: 3922.28,
    Spain: 10202.46,
    Estonia: 1008.61,
    Cyprus: 9632.39,
    Dominica: 9311.42,
    Bolivia: 3391.09,
    Belarus: 0.03,
    "St. Vincent and the Grenadines": 7261.92,
    Bulgaria: 43.64,
    Portugal: 7624.2,
    "Solomon Islands": 2823.82,
    Lithuania: "n/a",
    Georgia: "n/a",
    "Slovak Republic": 2617.87,
    Ghana: 57.16,
    Latvia: 970.41,
    Lesotho: 1520.8,
    Libya: 2167.95,
    Greece: 6959.05,
    Panama: 2963.32,
    Palau: "n/a",
    Fiji: 3607.47,
    Tonga: 2582.02,
    "Bosnia and Herzegovina": "n/a",
    Tajikistan: 1.26,
    Tunisia: 1821.72,
    Nauru: "n/a",
    "Papua New Guinea": 1676.31,
    Samoa: "n/a",
    "West Bank and Gaza": "n/a",
    Eritrea: 873.71,
    Bahrain: 4245.14,
    Tuvalu: "n/a",
    Belize: 5462.03,
    Montenegro: "n/a",
    Azerbaijan: 4.2,
    Kuwait: 4885.53,
    Oman: 2346.48,
    Ecuador: 1572.84,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 1216.7,
    Micronesia: "n/a",
    Jordan: 956.1,
    Kiribati: 916.47,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1994": {
    Afghanistan: "n/a",
    Ethiopia: 774.26,
    Lebanon: 4435762.19,
    Syria: 35539.32,
    Somalia: "n/a",
    Venezuela: 0,
    "Islamic Republic of Iran": 2473117.3,
    Vietnam: 3129773.35,
    Indonesia: 2410617.3,
    Korea: 8344098.34,
    Paraguay: 3202657.3,
    Uzbekistan: 3649.87,
    "Lao P.D.R.": 470491.14,
    Colombia: 2252911.1,
    Mongolia: 202111.9,
    Guinea: 611795.99,
    Chile: 1693338.38,
    Iceland: 1688645.78,
    "Sierra Leone": 181157.36,
    Cambodia: 681194.4,
    "Costa Rica": 488383.66,
    Iraq: "n/a",
    Hungary: 438417.41,
    Kazakhstan: 26801.84,
    Uganda: 339137.57,
    Gabon: 2384016.65,
    Japan: 4083528.4,
    "Equatorial Guinea": 136514.03,
    Guyana: 224984.15,
    Tanzania: 99715.73,
    Armenia: 56858.57,
    Argentina: 8378.74,
    Madagascar: 165366.67,
    Myanmar: "n/a",
    Angola: 0.05,
    "Democratic Republic of the Congo": 7.64,
    "CÙte d'Ivoire": 463727.2,
    "Republic of Congo": 466091.01,
    Nigeria: 16741.82,
    Yemen: 23049.37,
    Rwanda: 31440.42,
    Serbia: "n/a",
    "Sri Lanka": 37880.35,
    Senegal: 311443,
    Jamaica: 73940.54,
    "Taiwan Province of China": 320116.93,
    Cameroon: 325344.94,
    Russia: 4408.4,
    Benin: 212205.61,
    Uruguay: 32959.96,
    Djibouti: 197072.8,
    Sudan: 59.64,
    Norway: 206338.18,
    Albania: 69701.86,
    "Macao SAR": "n/a",
    "Dominican Republic": 24179.29,
    "Czech Republic": "n/a",
    Togo: 228639.44,
    Burundi: 39787.8,
    Malawi: 2429.99,
    Mali: 154280.15,
    "Burkina Faso": 120559.51,
    Algeria: 54095.14,
    Comoros: 274971.83,
    "Guinea-Bissau": 191056.11,
    Sweden: 200447.67,
    Mauritius: 61896.94,
    Denmark: 191140.16,
    "Hong Kong SAR": 171524.52,
    Niger: 116932.47,
    "North Macedonia": 78913.39,
    Chad: 109319.24,
    "Cabo Verde": 96671.02,
    Vanuatu: 165626.76,
    Bhutan: 15210.97,
    Pakistan: 17261.66,
    "Central African Republic": 147976.67,
    Kenya: 43265.31,
    Seychelles: 33143.32,
    Bangladesh: 13935.75,
    "South Sudan": "n/a",
    Thailand: 62659.41,
    Maldives: 18434.64,
    India: 11057.41,
    Haiti: 5864.02,
    Qatar: 43714.2,
    Mexico: 19714.13,
    Philippines: 28914.74,
    Zimbabwe: 714.03,
    Nepal: 11622.9,
    Suriname: 269.91,
    Ukraine: 242.68,
    Israel: 47269.51,
    "United Arab Emirates": 94574.25,
    Moldova: 1593.43,
    "Kyrgyz Republic": 2667.88,
    Turkey: 89.93,
    Croatia: 22851.76,
    Luxembourg: 35979.03,
    "South Africa": 13439.17,
    "Trinidad and Tobago": 23862.2,
    Botswana: 7619.54,
    China: 4039.24,
    Ireland: 13103.61,
    Singapore: 32920.1,
    Egypt: 3268.35,
    Australia: 26929.77,
    "Saudi Arabia": 28598.85,
    Mauritania: 10784.98,
    Nicaragua: 6038.9,
    Switzerland: 59141.74,
    Poland: 6119.71,
    "United States": 27674.02,
    Romania: 217.99,
    Honduras: 7111.6,
    Namibia: 7454.94,
    "New Zealand": 24604.32,
    Eswatini: 6600.36,
    Canada: 27347.04,
    Malaysia: 10569.22,
    "The Gambia": 7706.94,
    "St. Kitts and Nevis": 17300.3,
    Aruba: 28738.6,
    Netherlands: 20547.92,
    "São Tomé and Príncipe": 775.8,
    Austria: 21305.53,
    Brazil: 2233.46,
    Finland: 17871.32,
    Mozambique: 1129.71,
    Germany: 22546.05,
    Turkmenistan: 7.25,
    Belgium: 19547.51,
    "Antigua and Barbuda": 24562.56,
    "San Marino": "n/a",
    Guatemala: 7068.04,
    France: 20496.25,
    "Brunei Darussalam": 24576.01,
    Barbados: 16388.76,
    "United Kingdom": 13989.94,
    Morocco: 12604.43,
    "Puerto Rico": 10942.95,
    Andorra: "n/a",
    "St. Lucia": 13579.48,
    "The Bahamas": 19660.92,
    Malta: "n/a",
    Italy: 15911.96,
    Grenada: 8835.88,
    Zambia: 271.43,
    Peru: 4030.51,
    Slovenia: 4546.91,
    Spain: 10787.22,
    Estonia: 1417.81,
    Cyprus: 10518.19,
    Dominica: 10025.38,
    Bolivia: 3725.15,
    Belarus: 0.54,
    "St. Vincent and the Grenadines": 7343,
    Bulgaria: 77.64,
    Portugal: 8244.1,
    "Solomon Islands": 3796.03,
    Lithuania: "n/a",
    Georgia: 190.1,
    "Slovak Republic": 3136.6,
    Ghana: 75.86,
    Latvia: 1372.32,
    Lesotho: 1706.84,
    Libya: 2270.8,
    Greece: 7847.63,
    Panama: 3095.16,
    Palau: "n/a",
    Fiji: 3793.45,
    Tonga: 2664.9,
    "Bosnia and Herzegovina": "n/a",
    Tajikistan: 3.6,
    Tunisia: 1925.85,
    Nauru: "n/a",
    "Papua New Guinea": 1855.79,
    Samoa: "n/a",
    "West Bank and Gaza": 3695.28,
    Eritrea: 1203.91,
    Bahrain: 4428.18,
    Tuvalu: "n/a",
    Belize: 5505.82,
    Montenegro: "n/a",
    Azerbaijan: 93.31,
    Kuwait: 4032.65,
    Oman: 2351.61,
    Ecuador: 1858.32,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 1380.71,
    Micronesia: "n/a",
    Jordan: 1022.36,
    Kiribati: 979.16,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1995": {
    Afghanistan: "n/a",
    Ethiopia: 897.63,
    Lebanon: 5039710,
    Syria: 39080.18,
    Somalia: "n/a",
    Venezuela: 0.01,
    "Islamic Republic of Iran": 3483126.94,
    Vietnam: 3947305.43,
    Indonesia: 2819806.12,
    Korea: 9690834.21,
    Paraguay: 3724109.53,
    Uzbekistan: 16728.51,
    "Lao P.D.R.": 587667.79,
    Colombia: 2769800.77,
    Mongolia: 339480.61,
    Guinea: 650116.87,
    Chile: 2030565.12,
    Iceland: 1728014.71,
    "Sierra Leone": 222965.61,
    Cambodia: 783132.31,
    "Costa Rica": 599420.91,
    Iraq: "n/a",
    Hungary: 564620.59,
    Kazakhstan: 64697.81,
    Uganda: 389400.89,
    Gabon: 2474199.94,
    Japan: 4158396.8,
    "Equatorial Guinea": 166638.81,
    Guyana: 260180.81,
    Tanzania: 127232.61,
    Armenia: 162191.4,
    Argentina: 8295.12,
    Madagascar: 242989.82,
    Myanmar: "n/a",
    Angola: 1.14,
    "Democratic Republic of the Congo": 42.27,
    "CÙte d'Ivoire": 536059.84,
    "Republic of Congo": 485673.68,
    Nigeria: 26820.33,
    Yemen: 34053.7,
    Rwanda: 64312.53,
    Serbia: "n/a",
    "Sri Lanka": 43326.39,
    Senegal: 346084.97,
    Jamaica: 93884.33,
    "Taiwan Province of China": 346065.12,
    Cameroon: 357934.11,
    Russia: 10322.48,
    Benin: 250620.86,
    Uruguay: 45531.93,
    Djibouti: 196953.24,
    Sudan: 139.12,
    Norway: 220400.11,
    Albania: 83890.18,
    "Macao SAR": "n/a",
    "Dominican Republic": 27555.46,
    "Czech Republic": 154483.8,
    Togo: 264608.81,
    Burundi: 41753.66,
    Malawi: 4889.33,
    Mali: 176775.52,
    "Burkina Faso": 132393.19,
    Algeria: 71453.67,
    Comoros: 302007.14,
    "Guinea-Bissau": 358882.11,
    Sweden: 215759.42,
    Mauritius: 67845.26,
    Denmark: 198722.98,
    "Hong Kong SAR": 178469.86,
    Niger: 120685.51,
    "North Macedonia": 90830.88,
    Chad: 116510.36,
    "Cabo Verde": 105916.26,
    Vanuatu: 165876.49,
    Bhutan: 17894.17,
    Pakistan: 20126.02,
    "Central African Republic": 170967.48,
    Kenya: 48942.64,
    Seychelles: 32137.74,
    Bangladesh: 15364.65,
    "South Sudan": "n/a",
    Thailand: 70923.28,
    Maldives: 22399.8,
    India: 12726.38,
    Haiti: 8956.81,
    Qatar: 49830.34,
    Mexico: 25167.25,
    Philippines: 31816.75,
    Zimbabwe: 717.09,
    Nepal: 12466.69,
    Suriname: 1005.45,
    Ukraine: 1108.25,
    Israel: 54667.78,
    "United Arab Emirates": 96893.93,
    Moldova: 2182.05,
    "Kyrgyz Republic": 3567.98,
    Turkey: 177.68,
    Croatia: 26129.25,
    Luxembourg: 37170.49,
    "South Africa": 15035.02,
    "Trinidad and Tobago": 25707.89,
    Botswana: 8522.81,
    China: 5040.44,
    Ireland: 15112.74,
    Singapore: 35313.69,
    Egypt: 3723.98,
    Australia: 28144.2,
    "Saudi Arabia": 29600.29,
    Mauritania: 11842.64,
    Nicaragua: 7043.11,
    Switzerland: 59513.39,
    Poland: 8757.93,
    "United States": 28671.48,
    Romania: 317.82,
    Honduras: 8982.64,
    Namibia: 7970.82,
    "New Zealand": 25803.36,
    Eswatini: 7877.01,
    Canada: 28418.85,
    Malaysia: 11715.66,
    "The Gambia": 7781.34,
    "St. Kitts and Nevis": 18413.38,
    Aruba: 29622.2,
    Netherlands: 21365.69,
    "São Tomé and Príncipe": 1165.52,
    Austria: 22215.88,
    Brazil: 4443.7,
    Finland: 19328.06,
    Mozambique: 1690.21,
    Germany: 23301.64,
    Turkmenistan: 53.03,
    Belgium: 20777.6,
    "Antigua and Barbuda": 23423.14,
    "San Marino": "n/a",
    Guatemala: 7837.78,
    France: 21094.71,
    "Brunei Darussalam": 25844.36,
    Barbados: 16828.23,
    "United Kingdom": 14651.98,
    Morocco: 12633.36,
    "Puerto Rico": 11667.59,
    Andorra: "n/a",
    "St. Lucia": 14233.96,
    "The Bahamas": 20271.25,
    Malta: 7382.27,
    Italy: 17385.05,
    Grenada: 9212.35,
    Zambia: 355.49,
    Peru: 4830.29,
    Slovenia: 5308.38,
    Spain: 11587.17,
    Estonia: 1991.91,
    Cyprus: 11897.14,
    Dominica: 10409.29,
    Bolivia: 4260.11,
    Belarus: 1.19,
    "St. Vincent and the Grenadines": 8018.95,
    Bulgaria: 131.53,
    Portugal: 8879.6,
    "Solomon Islands": 4450.21,
    Lithuania: 2137.52,
    Georgia: 532.67,
    "Slovak Republic": 3662.68,
    Ghana: 110.17,
    Latvia: 1622.42,
    Lesotho: 1864.77,
    Libya: 2381.86,
    Greece: 8758.47,
    Panama: 3099.92,
    Palau: "n/a",
    Fiji: 3902.74,
    Tonga: 2724.87,
    "Bosnia and Herzegovina": "n/a",
    Tajikistan: 11.44,
    Tunisia: 2039.47,
    Nauru: "n/a",
    "Papua New Guinea": 2024.24,
    Samoa: "n/a",
    "West Bank and Gaza": 3981.1,
    Eritrea: 1384.65,
    Bahrain: 4565.8,
    Tuvalu: "n/a",
    Belize: 5728.78,
    Montenegro: "n/a",
    Azerbaijan: 279.17,
    Kuwait: 4139.77,
    Oman: 2443.61,
    Ecuador: 1980.37,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 1585.11,
    Micronesia: 2094.48,
    Jordan: 1063.18,
    Kiribati: 972.48,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1996": {
    Afghanistan: "n/a",
    Ethiopia: 981.92,
    Lebanon: 5577698.18,
    Syria: 46139.15,
    Somalia: "n/a",
    Venezuela: 0.01,
    "Islamic Republic of Iran": 4728028.34,
    Vietnam: 4616871.04,
    Indonesia: 3266326.28,
    Korea: 10782083.24,
    Paraguay: 4130465.11,
    Uzbekistan: 30299.11,
    "Lao P.D.R.": 698307.42,
    Colombia: 3250639.87,
    Mongolia: 378998.94,
    Guinea: 659345.34,
    Chile: 2205454.01,
    Iceland: 1856310.9,
    "Sierra Leone": 293393.64,
    Cambodia: 829703.67,
    "Costa Rica": 680498.36,
    Iraq: "n/a",
    Hungary: 690078.97,
    Kazakhstan: 91453.17,
    Uganda: 419912.4,
    Gabon: 2840607.94,
    Japan: 4260257.97,
    "Equatorial Guinea": 269650.24,
    Guyana: 287561.95,
    Tanzania: 154558.15,
    Armenia: 208583.28,
    Argentina: 8645.46,
    Madagascar: 288138.94,
    Myanmar: "n/a",
    Angola: 67.17,
    "Democratic Republic of the Congo": 300.29,
    "CÙte d'Ivoire": 589555.14,
    "Republic of Congo": 561340.22,
    Nigeria: 34148.07,
    Yemen: 46954.19,
    Rwanda: 82435.03,
    Serbia: "n/a",
    "Sri Lanka": 49500.21,
    Senegal: 361597.98,
    Jamaica: 110435.65,
    "Taiwan Province of China": 373107.71,
    Cameroon: 375436.87,
    Russia: 14529.02,
    Benin: 270862.34,
    Uruguay: 60402.68,
    Djibouti: 191453.1,
    Sudan: 366.37,
    Norway: 240095.99,
    Albania: 105541.55,
    "Macao SAR": "n/a",
    "Dominican Republic": 29789.49,
    "Czech Republic": 177230.31,
    Togo: 288012.74,
    Burundi: 43168.04,
    Malawi: 7838.7,
    Mali: 180949.14,
    "Burkina Faso": 143466.6,
    Algeria: 89967.09,
    Comoros: 302794.81,
    "Guinea-Bissau": 343269.15,
    Sweden: 221203.49,
    Mauritius: 71675.23,
    Denmark: 207202.13,
    "Hong Kong SAR": 191027.9,
    Niger: 124779.68,
    "North Macedonia": 93888.39,
    Chad: 128328.02,
    "Cabo Verde": 114452.84,
    Vanuatu: 168886.41,
    Bhutan: 20378.1,
    Pakistan: 22315.47,
    "Central African Republic": 154209.13,
    Kenya: 59854.59,
    Seychelles: 32717.85,
    Bangladesh: 16402.29,
    "South Sudan": "n/a",
    Thailand: 77142.68,
    Maldives: 24806.52,
    India: 14447.55,
    Haiti: 10068.61,
    Qatar: 55122.27,
    Mexico: 33476.34,
    Philippines: 34510.47,
    Zimbabwe: 849.88,
    Nepal: 13828.58,
    Suriname: 1118.86,
    Ukraine: 1672.77,
    Israel: 61892.26,
    "United Arab Emirates": 106675.07,
    Moldova: 2634.21,
    "Kyrgyz Republic": 5091.33,
    Turkey: 333.03,
    Croatia: 28447.02,
    Luxembourg: 38302.23,
    "South Africa": 16626.49,
    "Trinidad and Tobago": 27976.84,
    Botswana: 10227.47,
    China: 5845.42,
    Ireland: 16486.13,
    Singapore: 36989.42,
    Egypt: 4102.19,
    Australia: 29512.64,
    "Saudi Arabia": 31978.76,
    Mauritania: 12409.17,
    Nicaragua: 7989.13,
    Switzerland: 59643.02,
    Poland: 10962.83,
    "United States": 29946.97,
    Romania: 482.57,
    Honduras: 10510.28,
    Namibia: 9136.04,
    "New Zealand": 26910.47,
    Eswatini: 8646.67,
    Canada: 29078.04,
    Malaysia: 13021.4,
    "The Gambia": 8094.35,
    "St. Kitts and Nevis": 19426.97,
    Aruba: 29751.33,
    Netherlands: 22242.64,
    "São Tomé and Príncipe": 2313.54,
    Austria: 22935.1,
    Brazil: 5226.69,
    Finland: 19950.45,
    Mozambique: 2729.12,
    Germany: 23584.98,
    Turkmenistan: 618.74,
    Belgium: 21126.59,
    "Antigua and Barbuda": 25019.48,
    "San Marino": "n/a",
    Guatemala: 8577.82,
    France: 21614.66,
    "Brunei Darussalam": 27102.52,
    Barbados: 17877.13,
    "United Kingdom": 15598.39,
    Morocco: 14057.43,
    "Puerto Rico": 12305.02,
    Andorra: "n/a",
    "St. Lucia": 14482.51,
    "The Bahamas": 20974.54,
    Malta: 7646.62,
    Italy: 18398.91,
    Grenada: 9808.22,
    Zambia: 457.28,
    Peru: 5372.49,
    Slovenia: 6103.36,
    Spain: 12249.33,
    Estonia: 2599.59,
    Cyprus: 12157.7,
    Dominica: 11081.94,
    Bolivia: 4863.87,
    Belarus: 1.89,
    "St. Vincent and the Grenadines": 8413.75,
    Bulgaria: 265.9,
    Portugal: 9375.25,
    "Solomon Islands": 4927.8,
    Lithuania: 2696.36,
    Georgia: 869.64,
    "Slovak Republic": 4073.91,
    Ghana: 155.37,
    Latvia: 1894.05,
    Lesotho: 2109.04,
    Libya: 2666.1,
    Greece: 9649.11,
    Panama: 3580.93,
    Palau: "n/a",
    Fiji: 4176.1,
    Tonga: 2799.16,
    "Bosnia and Herzegovina": 1467.21,
    Tajikistan: 53.78,
    Tunisia: 2238.23,
    Nauru: "n/a",
    "Papua New Guinea": 2162.98,
    Samoa: "n/a",
    "West Bank and Gaza": 4136.64,
    Eritrea: 1676.95,
    Bahrain: 4624.29,
    Tuvalu: "n/a",
    Belize: 5778.23,
    Montenegro: "n/a",
    Azerbaijan: 353.66,
    Kuwait: 4511.54,
    Oman: 2639.58,
    Ecuador: 2037.13,
    Kosovo: "n/a",
    "Marshall Islands": "n/a",
    "El Salvador": 1684.79,
    Micronesia: 2060.87,
    Jordan: 1074.03,
    Kiribati: 1081.9,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1997": {
    Afghanistan: "n/a",
    Ethiopia: 988.55,
    Lebanon: 6535265.35,
    Syria: 48622.75,
    Somalia: "n/a",
    Venezuela: 0.02,
    "Islamic Republic of Iran": 5340432.57,
    Vietnam: 5240276.85,
    Indonesia: 3805801.47,
    Korea: 11794550.07,
    Paraguay: 4363516.39,
    Uzbekistan: 51305.7,
    "Lao P.D.R.": 871125.83,
    Colombia: 3877462.1,
    Mongolia: 472571.52,
    Guinea: 688075.05,
    Chile: 2408368.76,
    Iceland: 1988783.66,
    "Sierra Leone": 280898.55,
    Cambodia: 890256.78,
    "Costa Rica": 802445.31,
    Iraq: "n/a",
    Hungary: 857641.01,
    Kazakhstan: 110094.68,
    Uganda: 459107.35,
    Gabon: 2958090.5,
    Japan: 4313477.87,
    "Equatorial Guinea": 563250.94,
    Guyana: 307820.28,
    Tanzania: 188525.72,
    Armenia: 256157.83,
    Argentina: 9196.52,
    Madagascar: 302515.91,
    Myanmar: "n/a",
    Angola: 136.73,
    "Democratic Republic of the Congo": 789.06,
    "CÙte d'Ivoire": 633100.54,
    "Republic of Congo": 564908.3,
    Nigeria: 36239.32,
    Yemen: 54115.47,
    Rwanda: 104514.16,
    Serbia: 18805.97,
    "Sri Lanka": 57049.74,
    Senegal: 375067.51,
    Jamaica: 118511.75,
    "Taiwan Province of China": 400369,
    Cameroon: 398184.06,
    Russia: 16985.82,
    Benin: 288042.98,
    Uruguay: 74821.37,
    Djibouti: 189740.51,
    Sudan: 552.65,
    Norway: 258613.5,
    Albania: 105239.73,
    "Macao SAR": "n/a",
    "Dominican Republic": 34945.23,
    "Czech Republic": 191191.95,
    Togo: 324439.79,
    Burundi: 55338.02,
    Malawi: 9591.95,
    Mali: 188855.02,
    "Burkina Faso": 150650.02,
    Algeria: 95720.43,
    Comoros: 312674.49,
    "Guinea-Bissau": 363969.55,
    Sweden: 231391.93,
    Mauritius: 77988.34,
    Denmark: 217270.66,
    "Hong Kong SAR": 210702.2,
    Niger: 130869.17,
    "North Macedonia": 98623.22,
    Chad: 135976.25,
    "Cabo Verde": 123210.91,
    Vanuatu: 178169.12,
    Bhutan: 23426.18,
    Pakistan: 24953.38,
    "Central African Republic": 165278.83,
    Kenya: 65202.49,
    Seychelles: 36596.44,
    Bangladesh: 17447.8,
    "South Sudan": "n/a",
    Thailand: 77412.89,
    Maldives: 30059.98,
    India: 15709.8,
    Haiti: 11443.91,
    Qatar: 68583.42,
    Mexico: 41833.84,
    Philippines: 37717.57,
    Zimbabwe: 881.22,
    Nepal: 15242.98,
    Suriname: 1182.75,
    Ukraine: 1932.2,
    Israel: 67970.84,
    "United Arab Emirates": 108425,
    Moldova: 3022.4,
    "Kyrgyz Republic": 6583.56,
    Turkey: 640.47,
    Croatia: 31892.26,
    Luxembourg: 39309.28,
    "South Africa": 18141.76,
    "Trinidad and Tobago": 28962.86,
    Botswana: 11373.89,
    China: 6423.88,
    Ireland: 18796.66,
    Singapore: 39163.04,
    Egypt: 4499.83,
    Australia: 30897.4,
    "Saudi Arabia": 32648.8,
    Mauritania: 12972.84,
    Nicaragua: 8882.78,
    Switzerland: 60515.79,
    Poland: 13363.8,
    "United States": 31440.09,
    Romania: 1126.57,
    Honduras: 12478.77,
    Namibia: 9876.68,
    "New Zealand": 27471.05,
    Eswatini: 9699.04,
    Canada: 30364.91,
    Malaysia: 14098.48,
    "The Gambia": 8352.04,
    "St. Kitts and Nevis": 21628.18,
    Aruba: 31773.38,
    Netherlands: 23706.78,
    "São Tomé and Príncipe": 3191.17,
    Austria: 23685.09,
    Brazil: 5731.89,
    Finland: 21590.04,
    Mozambique: 3272.76,
    Germany: 24060.16,
    Turkmenistan: 872.74,
    Belgium: 22035.01,
    "Antigua and Barbuda": 26131.47,
    "San Marino": "n/a",
    Guatemala: 9457.18,
    France: 22244.76,
    "Brunei Darussalam": 28300.61,
    Barbados: 18824.73,
    "United Kingdom": 16321.12,
    Morocco: 13742.13,
    "Puerto Rico": 12968.39,
    Andorra: "n/a",
    "St. Lucia": 14541.16,
    "The Bahamas": 21951.76,
    Malta: 8221.07,
    Italy: 19205.82,
    Grenada: 10425.73,
    Zambia: 579.34,
    Peru: 6046.16,
    Slovenia: 6963.55,
    Spain: 12992.24,
    Estonia: 3267.52,
    Cyprus: 12566.92,
    Dominica: 11486.79,
    Bolivia: 5290.89,
    Belarus: 3.63,
    "St. Vincent and the Grenadines": 8829.04,
    Bulgaria: 2331.73,
    Portugal: 10122.75,
    "Solomon Islands": 5150.46,
    Lithuania: 3278.81,
    Georgia: 1061.79,
    "Slovak Republic": 4546.65,
    Ghana: 193.65,
    Latvia: 2204.89,
    Lesotho: 2381.67,
    Libya: 2832.89,
    Greece: 10701.02,
    Panama: 3791.27,
    Palau: "n/a",
    Fiji: 4194.56,
    Tonga: 2774.43,
    "Bosnia and Herzegovina": 2113.02,
    Tajikistan: 108.54,
    Tunisia: 2444.95,
    Nauru: "n/a",
    "Papua New Guinea": 2196.42,
    Samoa: "n/a",
    "West Bank and Gaza": 4659.89,
    Eritrea: 1885.28,
    Bahrain: 4670.72,
    Tuvalu: "n/a",
    Belize: 5689.69,
    Montenegro: "n/a",
    Azerbaijan: 404.91,
    Kuwait: 4168.1,
    Oman: 2678.12,
    Ecuador: 2253.23,
    Kosovo: "n/a",
    "Marshall Islands": 2257.33,
    "El Salvador": 1778.84,
    Micronesia: 1944.21,
    Jordan: 1096.37,
    Kiribati: 1127.71,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1998": {
    Afghanistan: "n/a",
    Ethiopia: 924.82,
    Lebanon: 7000081.45,
    Syria: 50341.23,
    Somalia: "n/a",
    Venezuela: 0.02,
    "Islamic Republic of Iran": 5813298.74,
    Vietnam: 5940273.14,
    Indonesia: 5728696.1,
    Korea: 11606307.78,
    Paraguay: 4974885.29,
    Uzbekistan: 73262.98,
    "Lao P.D.R.": 855159.37,
    Colombia: 4407154.61,
    Mongolia: 472239.08,
    Guinea: 731599,
    Chile: 2506976.76,
    Iceland: 2215305.03,
    "Sierra Leone": 350155.83,
    Cambodia: 1002994.03,
    "Costa Rica": 939270.93,
    Iraq: "n/a",
    Hungary: 1015838.33,
    Kazakhstan: 115896.04,
    Uganda: 499167.25,
    Gabon: 2455153.09,
    Japan: 4246165.11,
    "Equatorial Guinea": 458542.22,
    Guyana: 306435.33,
    Tanzania: 246053.03,
    Armenia: 307197.69,
    Argentina: 9283.18,
    Madagascar: 323495.14,
    Myanmar: 35241.4,
    Angola: 193.67,
    "Democratic Republic of the Congo": 987.46,
    "CÙte d'Ivoire": 671047.17,
    "Republic of Congo": 485861.2,
    Nigeria: 39451.43,
    Yemen: 50945.75,
    Rwanda: 106132.48,
    Serbia: 24251.86,
    "Sri Lanka": 64904.49,
    Senegal: 402692.8,
    Jamaica: 126738.72,
    "Taiwan Province of China": 427128.99,
    Cameroon: 421757.79,
    Russia: 19097.39,
    Benin: 305931.76,
    Uruguay: 87192.98,
    Djibouti: 188522.82,
    Sudan: 736.1,
    Norway: 261943.64,
    Albania: 123012.54,
    "Macao SAR": "n/a",
    "Dominican Republic": 39088.85,
    "Czech Republic": 209398.76,
    Togo: 300669.35,
    Burundi: 63473.18,
    Malawi: 11590.86,
    Mali: 194106.36,
    "Burkina Faso": 169672.39,
    Algeria: 95926.08,
    Comoros: 310366.01,
    "Guinea-Bissau": 304813.8,
    Sweden: 243147.36,
    Mauritius: 88817.56,
    Denmark: 223988.36,
    "Hong Kong SAR": 198692.77,
    Niger: 147426.42,
    "North Macedonia": 102876.6,
    Chad: 149918.53,
    "Cabo Verde": 135139.04,
    Vanuatu: 183766.97,
    Bhutan: 26945,
    Pakistan: 26872.86,
    "Central African Republic": 173349.39,
    Kenya: 66199.32,
    Seychelles: 40601.93,
    Bangladesh: 18930.88,
    "South Sudan": "n/a",
    Thailand: 76342.38,
    Maldives: 31294.11,
    India: 17689.13,
    Haiti: 13109.14,
    Qatar: 56135.29,
    Mexico: 50044.48,
    Philippines: 40422.27,
    Zimbabwe: 1029.2,
    Nepal: 16012.39,
    Suriname: 1403.74,
    Ukraine: 2141.55,
    Israel: 73870.6,
    "United Arab Emirates": 95109.13,
    Moldova: 3096.74,
    "Kyrgyz Republic": 7223.64,
    Turkey: 1143.59,
    Croatia: 35722.79,
    Luxembourg: 41175.54,
    "South Africa": 19406.46,
    "Trinidad and Tobago": 30689.25,
    Botswana: 12303.29,
    China: 6796.26,
    Ireland: 21556.11,
    Singapore: 36533.52,
    Egypt: 4929.76,
    Australia: 32328.63,
    "Saudi Arabia": 28219.88,
    Mauritania: 15350.7,
    Nicaragua: 10297.52,
    Switzerland: 62065.95,
    Poland: 15573.88,
    "United States": 32833.67,
    Romania: 1673.34,
    Honduras: 13857.65,
    Namibia: 10163.67,
    "New Zealand": 27738.09,
    Eswatini: 10589.27,
    Canada: 31222.68,
    Malaysia: 13812.88,
    "The Gambia": 8677.66,
    "St. Kitts and Nevis": 22304.41,
    Aruba: 33696.77,
    Netherlands: 25187.82,
    "São Tomé and Príncipe": 3725.71,
    Austria: 24614.74,
    Brazil: 5941.87,
    Finland: 23405.06,
    Mozambique: 3717.51,
    Germany: 24733.12,
    Turkmenistan: 1084.65,
    Belgium: 22823.59,
    "Antigua and Barbuda": 27201.82,
    "San Marino": "n/a",
    Guatemala: 10591.44,
    France: 23189.02,
    "Brunei Darussalam": 24233.71,
    Barbados: 21146.22,
    "United Kingdom": 17054.25,
    Morocco: 14590.78,
    "Puerto Rico": 14430.16,
    Andorra: "n/a",
    "St. Lucia": 15587.21,
    "The Bahamas": 23300.8,
    Malta: 8702.36,
    Italy: 20013.5,
    Grenada: 11800.67,
    Zambia: 656.52,
    Peru: 6267.66,
    Slovenia: 7734.2,
    Spain: 13860.8,
    Estonia: 3679.56,
    Cyprus: 13417.5,
    Dominica: 12222.05,
    Bolivia: 5833.64,
    Belarus: 6.99,
    "St. Vincent and the Grenadines": 9487.45,
    Bulgaria: 3264.53,
    Portugal: 10959.77,
    "Solomon Islands": 5640.09,
    Lithuania: 3668.58,
    Georgia: 1203.63,
    "Slovak Republic": 4953.61,
    Ghana: 231.77,
    Latvia: 2482.12,
    Lesotho: 2630.74,
    Libya: 2795.37,
    Greece: 11615.37,
    Panama: 4027.32,
    Palau: "n/a",
    Fiji: 4534.4,
    Tonga: 2906.28,
    "Bosnia and Herzegovina": 2470.14,
    Tajikistan: 172.64,
    Tunisia: 2613.59,
    Nauru: "n/a",
    "Papua New Guinea": 2360.5,
    Samoa: 4442.76,
    "West Bank and Gaza": 5383.1,
    Eritrea: 2090.19,
    Bahrain: 4348.81,
    Tuvalu: "n/a",
    Belize: 5777.72,
    Montenegro: "n/a",
    Azerbaijan: 420.44,
    Kuwait: 3481.7,
    Oman: 2321.51,
    Ecuador: 2257.86,
    Kosovo: "n/a",
    "Marshall Islands": 2276.74,
    "El Salvador": 1886.36,
    Micronesia: 2054.62,
    Jordan: 1173.91,
    Kiribati: 1279.05,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "1999": {
    Afghanistan: "n/a",
    Ethiopia: 967.24,
    Lebanon: 6907178.97,
    Syria: 50904.71,
    Somalia: "n/a",
    Venezuela: 0.03,
    "Islamic Republic of Iran": 7603973.66,
    Vietnam: 6482797.04,
    Indonesia: 6516424.78,
    Korea: 12687583.89,
    Paraguay: 5322370.39,
    Uzbekistan: 108546.49,
    "Lao P.D.R.": 1894821.5,
    Colombia: 4694888.99,
    Mongolia: 532118.83,
    Guinea: 773935.95,
    Chile: 2519922.82,
    Iceland: 2356513.32,
    "Sierra Leone": 394949.55,
    Cambodia: 1118357.1,
    "Costa Rica": 1061145.43,
    Iraq: "n/a",
    Hungary: 1135038.14,
    Kazakhstan: 135318.36,
    Uganda: 539035.35,
    Gabon: 2599933.43,
    Japan: 4171595.82,
    "Equatorial Guinea": 770235.31,
    Guyana: 357906.9,
    Tanzania: 276244.6,
    Armenia: 319561.07,
    Argentina: 8709.07,
    Madagascar: 351792.55,
    Myanmar: 48374.01,
    Angola: 1263.29,
    "Democratic Republic of the Congo": 5003.7,
    "CÙte d'Ivoire": 669846.52,
    "Republic of Congo": 583720.54,
    Nigeria: 44502.44,
    Yemen: 68421.51,
    Rwanda: 96000,
    Serbia: 28490.42,
    "Sri Lanka": 70140.92,
    Senegal: 424060.43,
    Jamaica: 135615.12,
    "Taiwan Province of China": 443795.55,
    Cameroon: 436641.87,
    Russia: 35181.56,
    Benin: 339631.39,
    Uruguay: 88572.03,
    Djibouti: 192660.82,
    Sudan: 890.09,
    Norway: 282940.99,
    Albania: 142690.68,
    "Macao SAR": "n/a",
    "Dominican Republic": 42354.37,
    "Czech Republic": 218956.85,
    Togo: 304763.14,
    Burundi: 75185.76,
    Malawi: 16236.39,
    Mali: 204397.56,
    "Burkina Faso": 184901.55,
    Algeria: 108399.72,
    Comoros: 324217.32,
    "Guinea-Bissau": 305224.77,
    Sweden: 255545.1,
    Mauritius: 97195.8,
    Denmark: 233650.7,
    "Hong Kong SAR": 193736.59,
    Niger: 142439.06,
    "North Macedonia": 109861.72,
    Chad: 132775.1,
    "Cabo Verde": 158346.09,
    Vanuatu: 185308.39,
    Bhutan: 30503.44,
    Pakistan: 28816.29,
    "Central African Republic": 178922.19,
    Kenya: 68369.49,
    Seychelles: 41391.62,
    Bangladesh: 20360.92,
    "South Sudan": "n/a",
    Thailand: 76885.01,
    Maldives: 33458.09,
    India: 19489.56,
    Haiti: 14157.81,
    Qatar: 71670.33,
    Mexico: 58866.58,
    Philippines: 43480.8,
    Zimbabwe: 1003.83,
    Nepal: 17854.69,
    Suriname: 2366.5,
    Ukraine: 2746.7,
    Israel: 79287.44,
    "United Arab Emirates": 100336.53,
    Moldova: 4189.61,
    "Kyrgyz Republic": 10142.01,
    Turkey: 1682.51,
    Croatia: 36286.3,
    Luxembourg: 46438.25,
    "South Africa": 20929.02,
    "Trinidad and Tobago": 34517.55,
    Botswana: 15122.7,
    China: 7162.57,
    Ireland: 24590.17,
    Singapore: 36944.36,
    Egypt: 5166.68,
    Australia: 33668.21,
    "Saudi Arabia": 30347.64,
    Mauritania: 16204.09,
    Nicaragua: 11803.07,
    Switzerland: 62877.01,
    Poland: 17249.86,
    "United States": 34496.24,
    Romania: 2454.64,
    Honduras: 14388.97,
    Namibia: 11458.95,
    "New Zealand": 28968.55,
    Eswatini: 11379.64,
    Canada: 33191.47,
    Malaysia: 14298.52,
    "The Gambia": 9244.35,
    "St. Kitts and Nevis": 23411.24,
    Aruba: 34394.87,
    Netherlands: 26615.04,
    "São Tomé and Príncipe": 4047.12,
    Austria: 25505.8,
    Brazil: 6351.38,
    Finland: 24597.81,
    Mozambique: 4427.52,
    Germany: 25293.75,
    Turkmenistan: 1535.56,
    Belgium: 23723.51,
    "Antigua and Barbuda": 27943.7,
    "San Marino": "n/a",
    Guatemala: 11279.05,
    France: 23950.09,
    "Brunei Darussalam": 27222.53,
    Barbados: 22078.19,
    "United Kingdom": 17717.81,
    Morocco: 14630,
    "Puerto Rico": 15293.18,
    Andorra: "n/a",
    "St. Lucia": 16194.74,
    "The Bahamas": 25780.48,
    Malta: 9272.96,
    Italy: 20649.59,
    Grenada: 12705.68,
    Zambia: 788.58,
    Peru: 6439.25,
    Slovenia: 8707.63,
    Spain: 14823.47,
    Estonia: 3889.18,
    Cyprus: 14251.2,
    Dominica: 12575.42,
    Bolivia: 5885.11,
    Belarus: 30.19,
    "St. Vincent and the Grenadines": 9923.98,
    Bulgaria: 3104.37,
    Portugal: 11705.39,
    "Solomon Islands": 5879.52,
    Lithuania: 3606.66,
    Georgia: 1388.36,
    "Slovak Republic": 5299.75,
    Ghana: 269.91,
    Latvia: 2611.51,
    Lesotho: 2850.16,
    Libya: 3278.16,
    Greece: 12343.03,
    Panama: 4136.06,
    Palau: "n/a",
    Fiji: 5231.84,
    Tonga: 3156.44,
    "Bosnia and Herzegovina": 2824.22,
    Tajikistan: 219.96,
    Tunisia: 2833.88,
    Nauru: "n/a",
    "Papua New Guinea": 2604.79,
    Samoa: 4414.5,
    "West Bank and Gaza": 5969.01,
    Eritrea: 2238.59,
    Bahrain: 4591.04,
    Tuvalu: "n/a",
    Belize: 6029.35,
    Montenegro: "n/a",
    Azerbaijan: 474.65,
    Kuwait: 4066.37,
    Oman: 2539.98,
    Ecuador: 1598.77,
    Kosovo: "n/a",
    "Marshall Islands": 2269.42,
    "El Salvador": 1930.62,
    Micronesia: 2061.64,
    Jordan: 1188.07,
    Kiribati: 1290.23,
    "Timor-Leste": "n/a",
    Liberia: "n/a"
  },
  "2000": {
    Afghanistan: "n/a",
    Ethiopia: 1057.14,
    Lebanon: 6674025.95,
    Syria: 57364.06,
    Somalia: "n/a",
    Venezuela: 0.03,
    "Islamic Republic of Iran": 9912457.67,
    Vietnam: 7063012.53,
    Indonesia: 7328240.7,
    Korea: 13862167.32,
    Paraguay: 5842407.88,
    Uzbekistan: 166656.8,
    "Lao P.D.R.": 2654582.67,
    Colombia: 5291726.82,
    Mongolia: 595373.69,
    Guinea: 806677.42,
    Chile: 2736137.97,
    Iceland: 2542782.81,
    "Sierra Leone": 423446.56,
    Cambodia: 1152154.7,
    "Costa Rica": 1214389.35,
    Iraq: "n/a",
    Hungary: 1303468.21,
    Kazakhstan: 174893.71,
    Uganda: 584555.04,
    Gabon: 3187746.19,
    Japan: 4221493.9,
    "Equatorial Guinea": 1339441.73,
    Guyana: 367997.8,
    Tanzania: 304219.1,
    Armenia: 334850.1,
    Argentina: 8638.54,
    Madagascar: 397396.17,
    Myanmar: 59578.08,
    Angola: 6547.54,
    "Democratic Republic of the Congo": 27959.92,
    "CÙte d'Ivoire": 653726.89,
    "Republic of Congo": 903091.42,
    Nigeria: 56405.44,
    Yemen: 86882.17,
    Rwanda: 104675.33,
    Serbia: 55000.96,
    "Sri Lanka": 79274.54,
    Senegal: 435877.65,
    Jamaica: 149905.57,
    "Taiwan Province of China": 463648.66,
    Cameroon: 462778.82,
    Russia: 53501.41,
    Benin: 364105.03,
    Uruguay: 89573.38,
    Djibouti: 193245.76,
    Sudan: 1085.87,
    Norway: 334892.02,
    Albania: 162251.41,
    "Macao SAR": "n/a",
    "Dominican Republic": 46834.04,
    "Czech Republic": 232172.24,
    Togo: 290233.03,
    Burundi: 93849.93,
    Malawi: 20999.26,
    Mali: 197621.22,
    "Burkina Faso": 181613.46,
    Algeria: 135170.13,
    Comoros: 334397.89,
    "Guinea-Bissau": 232804.95,
    Sweden: 271102.94,
    Mauritius: 107691.27,
    Denmark: 248950.47,
    "Hong Kong SAR": 199284.96,
    Niger: 140044.36,
    "North Macedonia": 123734.4,
    Chad: 133558.52,
    "Cabo Verde": 162121.54,
    Vanuatu: 196082.17,
    Bhutan: 33092.32,
    Pakistan: 29984.14,
    "Central African Republic": 169313.5,
    Kenya: 68376.65,
    Seychelles: 43304.04,
    Bangladesh: 21547.6,
    "South Sudan": "n/a",
    Thailand: 80533.88,
    Maldives: 34924.57,
    India: 20608.21,
    Haiti: 15588.69,
    Qatar: 110879.17,
    Mexico: 67759.93,
    Philippines: 48051.4,
    Zimbabwe: 969.82,
    Nepal: 19453,
    Suriname: 3841.24,
    Ukraine: 3614.36,
    Israel: 85916.6,
    "United Arab Emirates": 127395.27,
    Moldova: 5571.25,
    "Kyrgyz Republic": 13407.58,
    Turkey: 2649.4,
    Croatia: 40876.06,
    Luxembourg: 53227.63,
    "South Africa": 23455.19,
    "Trinidad and Tobago": 41238.18,
    Botswana: 17310.97,
    China: 7874.12,
    Ireland: 28339.48,
    Singapore: 41121.41,
    Egypt: 5587.61,
    Australia: 35808.2,
    "Saudi Arabia": 34711.93,
    Mauritania: 16088.17,
    Nicaragua: 13074.94,
    Switzerland: 65989.8,
    Poland: 19492.43,
    "United States": 36312.78,
    Romania: 3604.74,
    Honduras: 16222.38,
    Namibia: 14839.22,
    "New Zealand": 30671.21,
    Eswatini: 12612.78,
    Canada: 36090.21,
    Malaysia: 16521.37,
    "The Gambia": 9797.86,
    "St. Kitts and Nevis": 25054.88,
    Aruba: 37019.03,
    Netherlands: 28492.73,
    "São Tomé and Príncipe": 4392.11,
    Austria: 26662.25,
    Brazil: 6900.62,
    Finland: 26384.46,
    Mozambique: 4862.94,
    Germany: 25892.15,
    Turkmenistan: 1976.27,
    Belgium: 25038.96,
    "Antigua and Barbuda": 29514.54,
    "San Marino": "n/a",
    Guatemala: 12076.67,
    France: 25121.14,
    "Brunei Darussalam": 35248.04,
    Barbados: 22674.06,
    "United Kingdom": 18610.54,
    Morocco: 14598.78,
    "Puerto Rico": 16192.13,
    Andorra: "n/a",
    "St. Lucia": 16142.5,
    "The Bahamas": 26669.44,
    Malta: 10683.61,
    Italy: 21810.19,
    Grenada: 13653.84,
    Zambia: 1058.2,
    Peru: 6768.2,
    Slovenia: 9484.57,
    Spain: 15974.87,
    Estonia: 4417.84,
    Cyprus: 15344.02,
    Dominica: 12639.18,
    Bolivia: 6159.96,
    Belarus: 94.66,
    "St. Vincent and the Grenadines": 10067.13,
    Bulgaria: 3451.12,
    Portugal: 12479.67,
    "Solomon Islands": 5177.51,
    Lithuania: 3815.23,
    Georgia: 1509.1,
    "Slovak Republic": 5864.61,
    Ghana: 330.49,
    Latvia: 2880.36,
    Lesotho: 3145.7,
    Libya: 3784.12,
    Greece: 12997.39,
    Panama: 4111.71,
    Palau: 7862.5,
    Fiji: 4868.15,
    Tonga: 3353.31,
    "Bosnia and Herzegovina": 3143.16,
    Tajikistan: 290.64,
    Tunisia: 3034.62,
    Nauru: "n/a",
    "Papua New Guinea": 2803.07,
    Samoa: 4712.57,
    "West Bank and Gaza": 5760.25,
    Eritrea: 2295.64,
    Bahrain: 5344.68,
    Tuvalu: "n/a",
    Belize: 6662.84,
    Montenegro: 1727.91,
    Azerbaijan: 587.35,
    Kuwait: 5218.68,
    Oman: 3122.42,
    Ecuador: 1461.84,
    Kosovo: 1630.14,
    "Marshall Islands": 2237.18,
    "El Salvador": 2001.54,
    Micronesia: 2179.42,
    Jordan: 1212.15,
    Kiribati: 1378.16,
    "Timor-Leste": 409.16,
    Liberia: 325.46
  },
  "2001": {
    Afghanistan: "n/a",
    Ethiopia: 1048.15,
    Lebanon: 6555413.39,
    Syria: 59558.42,
    Somalia: "n/a",
    Venezuela: 0.04,
    "Islamic Republic of Iran": 10987497.11,
    Vietnam: 7594347.33,
    Indonesia: 8558977.96,
    Korea: 14925456.03,
    Paraguay: 6477841.19,
    Uzbekistan: 248828.2,
    "Lao P.D.R.": 3081880.53,
    Colombia: 5658503.46,
    Mongolia: 669925.45,
    Guinea: 834603.29,
    Chile: 2903487.03,
    Iceland: 2831289.42,
    "Sierra Leone": 455646.68,
    Cambodia: 1253406.31,
    "Costa Rica": 1329006.37,
    Iraq: "n/a",
    Hungary: 1509676.47,
    Kazakhstan: 218878.74,
    Uganda: 610638.81,
    Gabon: 2977859.58,
    Japan: 4181893.67,
    "Equatorial Guinea": 1916309,
    Guyana: 379065.61,
    Tanzania: 330817.96,
    Armenia: 384273.46,
    Argentina: 8085.36,
    Madagascar: 440693.35,
    Myanmar: 75738.03,
    Angola: 13669.18,
    "Democratic Republic of the Congo": 46077.27,
    "CÙte d'Ivoire": 685118.29,
    "Republic of Congo": 760402.4,
    Nigeria: 64868.67,
    Yemen: 89814.18,
    Rwanda: 110253.17,
    Serbia: 109471.2,
    "Sri Lanka": 88085.39,
    Senegal: 474917.04,
    Jamaica: 162353.46,
    "Taiwan Province of China": 451647.96,
    Cameroon: 477282.06,
    Russia: 65792.01,
    Benin: 379431.39,
    Uruguay: 90224.32,
    Djibouti: 196523.53,
    Sudan: 1274.56,
    Norway: 346130.89,
    Albania: 184123.19,
    "Macao SAR": 126316.76,
    "Dominican Republic": 50196.08,
    "Czech Republic": 252064.03,
    Togo: 289286.05,
    Burundi: 105744.33,
    Malawi: 24437.21,
    Mali: 232057.26,
    "Burkina Faso": 195621.67,
    Algeria: 136560.7,
    Comoros: 370801.09,
    "Guinea-Bissau": 247813.51,
    Sweden: 281029.86,
    Mauritius: 117301.86,
    Denmark: 256397.77,
    "Hong Kong SAR": 196297.64,
    Niger: 152168.61,
    "North Macedonia": 125187.92,
    Chad: 163400.58,
    "Cabo Verde": 170937.55,
    Vanuatu: 191899.59,
    Bhutan: 35616.7,
    Pakistan: 32326.37,
    "Central African Republic": 177977.57,
    Kenya: 68356.42,
    Seychelles: 44886.83,
    Bangladesh: 22613.05,
    "South Sudan": "n/a",
    Thailand: 84121.33,
    Maldives: 35589.77,
    India: 21914.84,
    Haiti: 17303.85,
    Qatar: 104104.11,
    Mexico: 70619.41,
    Philippines: 51194.49,
    Zimbabwe: 964.08,
    Nepal: 20730.89,
    Suriname: 5368.82,
    Ukraine: 4216.41,
    Israel: 85479.52,
    "United Arab Emirates": 119801.7,
    Moldova: 6506.98,
    "Kyrgyz Republic": 15010.75,
    Turkey: 3769.12,
    Croatia: 44672.01,
    Luxembourg: 54150.11,
    "South Africa": 25499.53,
    "Trinidad and Tobago": 43921.61,
    Botswana: 18445.2,
    China: 8649.3,
    Ireland: 31396.81,
    Singapore: 38879.93,
    Egypt: 5775.87,
    Australia: 37540.94,
    "Saudi Arabia": 32919.04,
    Mauritania: 16407.52,
    Nicaragua: 14146.74,
    Switzerland: 67414.57,
    Poland: 20418.8,
    "United States": 37101.45,
    Romania: 5238.72,
    Honduras: 17538.1,
    Namibia: 16687.99,
    "New Zealand": 32492.35,
    Eswatini: 13757.98,
    Canada: 36954.88,
    Malaysia: 15918.4,
    "The Gambia": 11562.42,
    "St. Kitts and Nevis": 26898.89,
    Aruba: 37124.84,
    Netherlands: 30141.91,
    "São Tomé and Príncipe": 4467.15,
    Austria: 27420.66,
    Brazil: 7467.02,
    Finland: 27914.46,
    Mozambique: 6133.83,
    Germany: 26651.21,
    Turkmenistan: 2698.23,
    Belgium: 25755.08,
    "Antigua and Barbuda": 28110.84,
    "San Marino": "n/a",
    Guatemala: 12997.01,
    France: 25953.92,
    "Brunei Darussalam": 33363.85,
    Barbados: 22568.74,
    "United Kingdom": 19257.61,
    Morocco: 15558.91,
    "Puerto Rico": 18243.71,
    Andorra: "n/a",
    "St. Lucia": 15260.06,
    "The Bahamas": 27060.51,
    Malta: 10949.6,
    Italy: 22895.37,
    Grenada: 13610.74,
    Zambia: 1361.27,
    Peru: 6787.96,
    Slovenia: 10626.48,
    Spain: 17195.51,
    Estonia: 5033.5,
    Cyprus: 16367.17,
    Dominica: 12893.43,
    Bolivia: 6261.97,
    Belarus: 178.38,
    "St. Vincent and the Grenadines": 10927.79,
    Bulgaria: 3926.79,
    Portugal: 13102.29,
    "Solomon Islands": 5098.27,
    Lithuania: 4084.63,
    Georgia: 1699.42,
    "Slovak Republic": 6389.09,
    Ghana: 451.77,
    Latvia: 3170.4,
    Lesotho: 3573.25,
    Libya: 3923.95,
    Greece: 13926.74,
    Panama: 4094.97,
    Palau: 8099.78,
    Fiji: 5096.28,
    Tonga: 3558.54,
    "Bosnia and Herzegovina": 3366.5,
    Tajikistan: 397.04,
    Tunisia: 3244.16,
    Nauru: "n/a",
    "Papua New Guinea": 2921.41,
    Samoa: 5203.19,
    "West Bank and Gaza": 5365.08,
    Eritrea: 2772.65,
    Bahrain: 5224.27,
    Tuvalu: "n/a",
    Belize: 6809.04,
    Montenegro: 2114.38,
    Azerbaijan: 655.09,
    Kuwait: 4632.15,
    Oman: 3061.09,
    Ecuador: 1909.42,
    Kosovo: 1903.12,
    "Marshall Islands": 2416.08,
    "El Salvador": 2072.3,
    Micronesia: 2257.32,
    Jordan: 1263.51,
    Kiribati: 1415.86,
    "Timor-Leste": 525.33,
    Liberia: 328.52
  },
  "2002": {
    Afghanistan: 9555.4,
    Ethiopia: 997.73,
    Lebanon: 6787786.22,
    Syria: 64118.05,
    Somalia: "n/a",
    Venezuela: 0.04,
    "Islamic Republic of Iran": 15513914.89,
    Vietnam: 8343336.81,
    Indonesia: 9338261.5,
    Korea: 16470682.09,
    Paraguay: 7500204.48,
    Uzbekistan: 371850.06,
    "Lao P.D.R.": 3585124.83,
    Colombia: 6069768.08,
    Mongolia: 735801.3,
    Guinea: 878585.23,
    Chile: 3061471.67,
    Iceland: 2980511.21,
    "Sierra Leone": 529864,
    Cambodia: 1320331.73,
    "Costa Rica": 1483031.35,
    Iraq: "n/a",
    Hungary: 1713401.38,
    Kazakhstan: 254007.59,
    Uganda: 650628.98,
    Gabon: 2922795.13,
    Japan: 4116776.51,
    "Equatorial Guinea": 2155959.47,
    Guyana: 399054.44,
    Tanzania: 369487.66,
    Armenia: 446712.03,
    Argentina: 9315.73,
    Madagascar: 436174.58,
    Myanmar: 112746.09,
    Angola: 36627.17,
    "Democratic Republic of the Congo": 60673.15,
    "CÙte d'Ivoire": 700399.75,
    "Republic of Congo": 773157.64,
    Nigeria: 88122.9,
    Yemen: 98353.99,
    Rwanda: 115308.64,
    Serbia: 138481.45,
    "Sri Lanka": 98223.17,
    Senegal: 472635.02,
    Jamaica: 179602.41,
    "Taiwan Province of China": 472049.05,
    Cameroon: 493389.12,
    Russia: 80050.7,
    Benin: 398836.45,
    Uruguay: 93885.84,
    Djibouti: 199096.7,
    Sudan: 1460.43,
    Norway: 343286.2,
    Albania: 200095.8,
    "Macao SAR": 134431.29,
    "Dominican Republic": 55338.13,
    "Czech Republic": 263791.2,
    Togo: 307304.86,
    Burundi: 108331.29,
    Malawi: 29456.62,
    Mali: 240526.17,
    "Burkina Faso": 204413.27,
    Algeria: 143974.02,
    Comoros: 394341.8,
    "Guinea-Bissau": 259979.02,
    Sweden: 290616,
    Mauritius: 125733.41,
    Denmark: 262701.01,
    "Hong Kong SAR": 192890.07,
    Niger: 157878.84,
    "North Macedonia": 127902.38,
    Chad: 173823.5,
    "Cabo Verde": 176064.91,
    Vanuatu: 182970.6,
    Bhutan: 39825.92,
    Pakistan: 33519.55,
    "Central African Republic": 181819.51,
    Kenya: 70731.37,
    Seychelles: 46207.22,
    Bangladesh: 23926.52,
    "South Sudan": "n/a",
    Thailand: 90052.44,
    Maldives: 37788.35,
    India: 23198.45,
    Haiti: 18515.08,
    Qatar: 111752.12,
    Mexico: 73457.15,
    Philippines: 54199.07,
    Zimbabwe: 922.88,
    Nepal: 21242.24,
    Suriname: 7217.14,
    Ukraine: 4715.74,
    Israel: 87409,
    "United Arab Emirates": 120423.89,
    Moldova: 7724.1,
    "Kyrgyz Republic": 15170.06,
    Turkey: 5453.31,
    Croatia: 49029.73,
    Luxembourg: 56513.01,
    "South Africa": 29353.45,
    "Trinidad and Tobago": 44756.28,
    Botswana: 19449.12,
    China: 9445.22,
    Ireland: 34376,
    Singapore: 39679.14,
    Egypt: 5982.04,
    Australia: 39803.54,
    "Saudi Arabia": 33084.35,
    Mauritania: 17244.24,
    Nicaragua: 14420.97,
    Switzerland: 66719.96,
    Poland: 21238.68,
    "United States": 37945.76,
    Romania: 6986.6,
    Honduras: 18640.74,
    Namibia: 19048,
    "New Zealand": 33823.9,
    Eswatini: 15498.35,
    Canada: 38126.65,
    Malaysia: 16878.93,
    "The Gambia": 12579.43,
    "St. Kitts and Nevis": 27821.66,
    Aruba: 38139.97,
    Netherlands: 31116.31,
    "São Tomé and Príncipe": 5024.85,
    Austria: 28053.94,
    Brazil: 8340.6,
    Finland: 28583.03,
    Mozambique: 7163.66,
    Germany: 26944.85,
    Turkmenistan: 3350.07,
    Belgium: 26504.59,
    "Antigua and Barbuda": 28293.16,
    "San Marino": "n/a",
    Guatemala: 14042.8,
    France: 26603.08,
    "Brunei Darussalam": 33637.76,
    Barbados: 22885.12,
    "United Kingdom": 20005.91,
    Morocco: 16023.28,
    "Puerto Rico": 18972.77,
    Andorra: "n/a",
    "St. Lucia": 15269.34,
    "The Bahamas": 28477.06,
    Malta: 11442.29,
    Italy: 23691.53,
    Grenada: 14077.25,
    Zambia: 1655.93,
    Peru: 7095.98,
    Slovenia: 11809.63,
    Spain: 18094.84,
    Estonia: 5671.21,
    Cyprus: 16834.08,
    Dominica: 12636.71,
    Bolivia: 6477.98,
    Belarus: 273.02,
    "St. Vincent and the Grenadines": 11684.44,
    Bulgaria: 4342.29,
    Portugal: 13681.35,
    "Solomon Islands": 5368.97,
    Lithuania: 4409.55,
    Georgia: 1920.54,
    "Slovak Republic": 6939.92,
    Ghana: 566.11,
    Latvia: 3617.45,
    Lesotho: 3986.53,
    Libya: 4871.24,
    Greece: 14885.92,
    Panama: 4172.69,
    Palau: 8388.52,
    Fiji: 5395.95,
    Tonga: 3986.04,
    "Bosnia and Herzegovina": 3710.13,
    Tajikistan: 518.73,
    Tunisia: 3335.37,
    Nauru: "n/a",
    "Papua New Guinea": 3173.44,
    Samoa: 5546.58,
    "West Bank and Gaza": 5223.45,
    Eritrea: 3175.46,
    Bahrain: 5076.58,
    Tuvalu: 3202.05,
    Belize: 7101.83,
    Montenegro: 2202.38,
    Azerbaijan: 740.11,
    Kuwait: 4789.08,
    Oman: 3117.8,
    Ecuador: 2180.39,
    Kosovo: 1912.8,
    "Marshall Islands": 2651.49,
    "El Salvador": 2124.11,
    Micronesia: 2277.95,
    Jordan: 1325.02,
    Kiribati: 1516.57,
    "Timor-Leste": 509.28,
    Liberia: 334.82
  },
  "2003": {
    Afghanistan: 11296.1,
    Ethiopia: 1037.15,
    Lebanon: 6691632.43,
    Syria: 59835.68,
    Somalia: "n/a",
    Venezuela: 0.05,
    "Islamic Republic of Iran": 18709148.6,
    Vietnam: 9463992.84,
    Indonesia: 10176465.26,
    Korea: 17484325.78,
    Paraguay: 8849664.89,
    Uzbekistan: 484989.04,
    "Lao P.D.R.": 4241082.98,
    Colombia: 6654616.76,
    Mongolia: 856573.07,
    Guinea: 1026268.37,
    Chile: 3298194.4,
    Iceland: 3039241.38,
    "Sierra Leone": 625690.76,
    Cambodia: 1433016.52,
    "Costa Rica": 1684992.08,
    Iraq: 1155226.29,
    Hungary: 1886591.5,
    Kazakhstan: 308468.89,
    Uganda: 710419.55,
    Gabon: 2909259.89,
    Japan: 4105229.72,
    "Equatorial Guinea": 3145439.09,
    Guyana: 415696.19,
    Tanzania: 416480.58,
    Armenia: 534421.94,
    Argentina: 11098.37,
    Madagascar: 456691.78,
    Myanmar: 162622.7,
    Angola: 71022.89,
    "Democratic Republic of the Congo": 70456.19,
    "CÙte d'Ivoire": 705698.55,
    "Republic of Congo": 728282.72,
    Nigeria: 100845.02,
    Yemen: 109662.07,
    Rwanda: 138433.74,
    Serbia: 163222.91,
    "Sri Lanka": 108496.47,
    Senegal: 482376.66,
    Jamaica: 207084.24,
    "Taiwan Province of China": 483266.82,
    Cameroon: 503294.96,
    Russia: 98051.26,
    Benin: 412472.29,
    Uruguay: 110570.68,
    Djibouti: 206075.55,
    Sudan: 1658.74,
    Norway: 354338.93,
    Albania: 222968.14,
    "Macao SAR": 148082.31,
    "Dominican Republic": 71881.65,
    "Czech Republic": 277008.66,
    Togo: 310480.08,
    Burundi: 116994.76,
    Malawi: 33610.73,
    Mali: 235319.28,
    "Burkina Faso": 217245.43,
    Algeria: 164726.36,
    Comoros: 413141.07,
    "Guinea-Bissau": 252360.31,
    Sweden: 301208.82,
    Mauritius: 139658.53,
    Denmark: 266880.12,
    "Hong Kong SAR": 185782.35,
    Niger: 155109.41,
    "North Macedonia": 132594.27,
    Chad: 191978.68,
    "Cabo Verde": 189265.78,
    Vanuatu: 187939.59,
    Bhutan: 44405.67,
    Pakistan: 35808.45,
    "Central African Republic": 180347.39,
    Kenya: 72429.99,
    Seychelles: 46040.76,
    Bangladesh: 25872.51,
    "South Sudan": "n/a",
    Thailand: 97867.02,
    Maldives: 47242.14,
    India: 25564.04,
    Haiti: 21692.83,
    Qatar: 130747.92,
    Mexico: 76477.53,
    Philippines: 57597.5,
    Zimbabwe: 822.47,
    Nepal: 22435.84,
    Suriname: 9290.81,
    Ukraine: 5631.03,
    Israel: 86523.23,
    "United Arab Emirates": 128601.08,
    Moldova: 9486.42,
    "Kyrgyz Republic": 16729.86,
    Turkey: 7027.7,
    Croatia: 54004.88,
    Luxembourg: 58415.79,
    "South Africa": 31842.05,
    "Trinidad and Tobago": 56221.74,
    Botswana: 20651.31,
    China: 10612.85,
    Ireland: 36202.11,
    Singapore: 41342.67,
    Egypt: 6455.75,
    Australia: 41747.9,
    "Saudi Arabia": 36751.95,
    Mauritania: 18720.08,
    Nicaragua: 15260.96,
    Switzerland: 66905.66,
    Poland: 22166,
    "United States": 39405.35,
    Romania: 8873.78,
    Honduras: 20097.3,
    Namibia: 19728.06,
    "New Zealand": 35173.67,
    Eswatini: 16912.53,
    Canada: 39702.99,
    Malaysia: 18013.23,
    "The Gambia": 16585.32,
    "St. Kitts and Nevis": 26790.68,
    Aruba: 39289.58,
    Netherlands: 31669.46,
    "São Tomé and Príncipe": 6056.8,
    Austria: 28560.67,
    Brazil: 9506.78,
    Finland: 29147.22,
    Mozambique: 7754.81,
    Germany: 27119.52,
    Turkmenistan: 4353.78,
    Belgium: 27153.85,
    "Antigua and Barbuda": 29435.62,
    "San Marino": "n/a",
    Guatemala: 14662.34,
    France: 27131.72,
    "Brunei Darussalam": 36160.36,
    Barbados: 23577.31,
    "United Kingdom": 21063.9,
    Morocco: 16933.29,
    "Puerto Rico": 19820.21,
    Andorra: "n/a",
    "St. Lucia": 16598.32,
    "The Bahamas": 28043.46,
    Malta: 12050.27,
    Italy: 24388.55,
    Grenada: 15343.31,
    Zambia: 2030.62,
    Peru: 7512.4,
    Slovenia: 12838.54,
    Spain: 19012.74,
    Estonia: 6379.44,
    Cyprus: 17997.67,
    Dominica: 13029.58,
    Bolivia: 6947.75,
    Belarus: 384.62,
    "St. Vincent and the Grenadines": 12134.09,
    Bulgaria: 4696.4,
    Portugal: 13966.02,
    "Solomon Islands": 5919.39,
    Lithuania: 4875.22,
    Georgia: 2220.12,
    "Slovak Republic": 7717.3,
    Ghana: 745.81,
    Latvia: 4154.79,
    Lesotho: 4248.24,
    Libya: 6202.56,
    Greece: 16251.35,
    Panama: 4312.62,
    Palau: 7874.34,
    Fiji: 5838.22,
    Tonga: 4424.48,
    "Bosnia and Herzegovina": 3906.81,
    Tajikistan: 723.41,
    Tunisia: 3558.79,
    Nauru: "n/a",
    "Papua New Guinea": 3546.62,
    Samoa: 5948.25,
    "West Bank and Gaza": 5452.03,
    Eritrea: 3593.45,
    Bahrain: 5446.76,
    Tuvalu: 3184.29,
    Belize: 7286.65,
    Montenegro: 2427.99,
    Azerbaijan: 864.23,
    Kuwait: 5598.42,
    Oman: 3293.34,
    Ecuador: 2434.98,
    Kosovo: 1950.53,
    "Marshall Islands": 2619.05,
    "El Salvador": 2209.5,
    Micronesia: 2311.65,
    Jordan: 1381.26,
    Kiribati: 1559.23,
    "Timor-Leste": 523.85,
    Liberia: 248.98
  },
  "2004": {
    Afghanistan: 12166.14,
    Ethiopia: 1194.26,
    Lebanon: 6978539.31,
    Syria: 68437.35,
    Somalia: "n/a",
    Venezuela: 0.08,
    "Islamic Republic of Iran": 23952473.29,
    Vietnam: 11913182.18,
    Indonesia: 11439233.97,
    Korea: 18893338.35,
    Paraguay: 10120254.69,
    Uzbekistan: 597877.44,
    "Lao P.D.R.": 4955536.82,
    Colombia: 7427895.07,
    Mongolia: 1090182.72,
    Guinea: 1199020.37,
    Chile: 3774866.33,
    Iceland: 3339715.04,
    "Sierra Leone": 720178.62,
    Cambodia: 1630368.12,
    "Costa Rica": 1963026.31,
    Iraq: 1995811.83,
    Hungary: 2083370.27,
    Kazakhstan: 389400.19,
    Uganda: 779802.73,
    Gabon: 3079795.5,
    Japan: 4144543.14,
    "Equatorial Guinea": 4331593.15,
    Guyana: 442629.99,
    Tanzania: 467222.53,
    Armenia: 629684.96,
    Argentina: 12690.7,
    Madagascar: 531666.96,
    Myanmar: 203304.62,
    Angola: 102090.71,
    "Democratic Republic of the Congo": 77514.99,
    "CÙte d'Ivoire": 676160.64,
    "Republic of Congo": 853264.74,
    Nigeria: 128002.48,
    Yemen: 126097.96,
    Rwanda: 159534.88,
    Serbia: 194616.31,
    "Sri Lanka": 123426.17,
    Senegal: 491567.66,
    Jamaica: 235956.43,
    "Taiwan Province of China": 511092.54,
    Cameroon: 533537.59,
    Russia: 126869.49,
    Benin: 421208.24,
    Uruguay: 127720.73,
    Djibouti: 215377.55,
    Sudan: 1993.45,
    Norway: 387680.3,
    Albania: 243696.87,
    "Macao SAR": 184555.06,
    "Dominican Republic": 105669.67,
    "Czech Republic": 302020.81,
    Togo: 294006.24,
    Burundi: 134731.01,
    Malawi: 39822.75,
    Mali: 240032.29,
    "Burkina Faso": 220625.91,
    Algeria: 189986.41,
    Comoros: 417587,
    "Guinea-Bissau": 236251.08,
    Sweden: 314068.46,
    Mauritius: 154706.77,
    Denmark: 279011.2,
    "Hong Kong SAR": 193734.5,
    Niger: 150565.13,
    "North Macedonia": 138145.6,
    Chad: 271123.04,
    "Cabo Verde": 194180.75,
    Vanuatu: 195084.05,
    Bhutan: 48201.25,
    Pakistan: 40623.59,
    "Central African Republic": 177719.62,
    Kenya: 74515.7,
    Seychelles: 55971.62,
    Bangladesh: 28201.54,
    "South Sudan": "n/a",
    Thailand: 106996.69,
    Maldives: 54246.93,
    India: 28701.69,
    Haiti: 26197.52,
    Qatar: 154875.14,
    Mexico: 84666.35,
    Philippines: 63721.17,
    Zimbabwe: 806.9,
    Nepal: 24139.23,
    Suriname: 11092.19,
    Ukraine: 7311.73,
    Israel: 89276.72,
    "United Arab Emirates": 144345.8,
    Moldova: 11023.52,
    "Kyrgyz Republic": 18597.41,
    Turkey: 8570.08,
    Croatia: 56533.45,
    Luxembourg: 61402.1,
    "South Africa": 34927.8,
    "Trinidad and Tobago": 65775.13,
    Botswana: 22936.28,
    China: 12413.12,
    Ireland: 38114.55,
    Singapore: 46663.95,
    Egypt: 7363.37,
    Australia: 44477.79,
    "Saudi Arabia": 43001.64,
    Mauritania: 21145.02,
    Nicaragua: 17177.02,
    Switzerland: 68399.77,
    Poland: 24432.49,
    "United States": 41641.62,
    Romania: 11369.69,
    Honduras: 22176.1,
    Namibia: 22194.52,
    "New Zealand": 37409.45,
    Eswatini: 18041.03,
    Canada: 41870.81,
    Malaysia: 19930.52,
    "The Gambia": 19302,
    "St. Kitts and Nevis": 28530.78,
    Aruba: 42424.13,
    Netherlands: 32555.36,
    "São Tomé and Príncipe": 6859.2,
    Austria: 29665.22,
    Brazil: 10705.99,
    Finland: 30414.97,
    Mozambique: 8654.76,
    Germany: 27775.89,
    Turkmenistan: 5353.9,
    Belgium: 28550.21,
    "Antigua and Barbuda": 31274.75,
    "San Marino": 47704.98,
    Guatemala: 15515.6,
    France: 28163.08,
    "Brunei Darussalam": 40933.49,
    Barbados: 25224.41,
    "United Kingdom": 21975.96,
    Morocco: 17728.28,
    "Puerto Rico": 20988.99,
    Andorra: "n/a",
    "St. Lucia": 17732.13,
    "The Bahamas": 28239.07,
    Malta: 12242.94,
    Italy: 25208.61,
    Grenada: 15502.46,
    Zambia: 2535.38,
    Peru: 8218.92,
    Slovenia: 13838.78,
    Spain: 20052.58,
    Estonia: 7175.86,
    Cyprus: 19167.98,
    Dominica: 13946.09,
    Bolivia: 7676.53,
    Belarus: 529.47,
    "St. Vincent and the Grenadines": 13087.34,
    Bulgaria: 5308.73,
    Portugal: 14522.12,
    "Solomon Islands": 6489.3,
    Lithuania: 5395.11,
    Georgia: 2564.98,
    "Slovak Republic": 8595.73,
    Ghana: 875.46,
    Latvia: 4863.01,
    Lesotho: 4620.75,
    Libya: 7814.65,
    Greece: 17557.18,
    Panama: 4638.72,
    Palau: 8386.41,
    Fiji: 6241.98,
    Tonga: 4682.41,
    "Bosnia and Herzegovina": 4251.43,
    Tajikistan: 917.28,
    Tunisia: 3877.03,
    Nauru: 4463.47,
    "Papua New Guinea": 3583.28,
    Samoa: 6524.56,
    "West Bank and Gaza": 6054.74,
    Eritrea: 4350.65,
    Bahrain: 6002.46,
    Tuvalu: 3238.71,
    Belize: 7604.45,
    Montenegro: 2718.2,
    Azerbaijan: 1021.69,
    Kuwait: 6361.25,
    Oman: 3704.98,
    Ecuador: 2700.12,
    Kosovo: 1829.72,
    "Marshall Islands": 2625.82,
    "El Salvador": 2278.43,
    Micronesia: 2268.9,
    Jordan: 1507.2,
    Kiribati: 1531.91,
    "Timor-Leste": 462.95,
    Liberia: 307.15
  },
  "2005": {
    Afghanistan: 14557.09,
    Ethiopia: 1429.42,
    Lebanon: 6896924.53,
    Syria: 78782.71,
    Somalia: "n/a",
    Venezuela: 0.12,
    "Islamic Republic of Iran": 29253601.42,
    Vietnam: 13843379.28,
    Indonesia: 13628832.87,
    Korea: 19870426.96,
    Paraguay: 11477246.04,
    Uzbekistan: 767098.7,
    "Lao P.D.R.": 5647635.51,
    Colombia: 8110001.67,
    Mongolia: 1192202.72,
    Guinea: 1714913.73,
    Chile: 4254429.22,
    Iceland: 3615497.81,
    "Sierra Leone": 844864.63,
    Cambodia: 1928232.54,
    "Costa Rica": 2271994.96,
    Iraq: 2685958.2,
    Hungary: 2233018.42,
    Kazakhstan: 498747.64,
    Uganda: 860119.15,
    Gabon: 3658573.61,
    Japan: 4168353.53,
    "Equatorial Guinea": 5699897.56,
    Guyana: 460820.1,
    Tanzania: 519028.8,
    Armenia: 745143.16,
    Argentina: 15094.73,
    Madagascar: 640041.74,
    Myanmar: 257028.47,
    Angola: 162327.27,
    "Democratic Republic of the Congo": 103494.98,
    "CÙte d'Ivoire": 679092.99,
    "Republic of Congo": 1084658.59,
    Nigeria: 160371.42,
    Yemen: 152970.38,
    Rwanda: 185909.09,
    Serbia: 248378.97,
    "Sri Lanka": 143622.95,
    Senegal: 523403.12,
    Jamaica: 264421.95,
    "Taiwan Province of China": 528611,
    Cameroon: 533734.52,
    Russia: 161647.48,
    Benin: 433820.38,
    Uruguay: 137728.39,
    Djibouti: 225742.64,
    Sudan: 2427.96,
    Norway: 429635.87,
    Albania: 267031.94,
    "Macao SAR": 201157.6,
    "Dominican Republic": 120810.39,
    "Czech Republic": 322153.91,
    Togo: 289162.02,
    Burundi: 160841.85,
    Malawi: 44642.16,
    Mali: 266313.71,
    "Burkina Faso": 241449.25,
    Algeria: 230162.84,
    Comoros: 429079.99,
    "Guinea-Bissau": 254093.44,
    Sweden: 323957.27,
    Mauritius: 162707.05,
    Denmark: 293081.56,
    "Hong Kong SAR": 206517.45,
    Niger: 169116.8,
    "North Macedonia": 151432.97,
    Chad: 348432.41,
    "Cabo Verde": 203743.99,
    Vanuatu: 201657.27,
    Bhutan: 52967.12,
    Pakistan: 45927.71,
    "Central African Republic": 188141.73,
    Kenya: 79820.7,
    Seychelles: 61013.23,
    Bangladesh: 30934.73,
    "South Sudan": "n/a",
    Thailand: 116399.49,
    Maldives: 50693.5,
    India: 32183.14,
    Haiti: 30139.38,
    Qatar: 197862.33,
    Mexico: 90495.93,
    Philippines: 69476.11,
    Zimbabwe: 764.63,
    Nepal: 26172.83,
    Suriname: 13114.18,
    Ukraine: 9422.51,
    Israel: 92422.66,
    "United Arab Emirates": 161531.58,
    Moldova: 12995.53,
    "Kyrgyz Republic": 19645.11,
    Turkey: 9879.04,
    Croatia: 60773.75,
    Luxembourg: 65110.47,
    "South Africa": 38382.81,
    "Trinidad and Tobago": 78567.8,
    Botswana: 27175.36,
    China: 14351.74,
    Ireland: 40550.59,
    Singapore: 49867.53,
    Egypt: 8008.77,
    Australia: 47313,
    "Saudi Arabia": 52755.81,
    Mauritania: 25590.52,
    Nicaragua: 19290.2,
    Switzerland: 70552.58,
    Poland: 25947.89,
    "United States": 44034.26,
    Romania: 13415.8,
    Honduras: 24634.37,
    Namibia: 23594.98,
    "New Zealand": 38870.41,
    Eswatini: 20196.47,
    Canada: 44143.14,
    Malaysia: 21504.44,
    "The Gambia": 19023.27,
    "St. Kitts and Nevis": 30392.92,
    Aruba: 43268.03,
    Netherlands: 33785.05,
    "São Tomé and Príncipe": 8545.69,
    Austria: 30889.53,
    Brazil: 11733.46,
    Finland: 31449.16,
    Mozambique: 9612.07,
    Germany: 28133.69,
    Turkmenistan: 6407.34,
    Belgium: 29680.39,
    "Antigua and Barbuda": 34413.68,
    "San Marino": 49125.72,
    Guatemala: 16431.17,
    France: 28966.71,
    "Brunei Darussalam": 48967.2,
    Barbados: 27879.34,
    "United Kingdom": 23058.58,
    Morocco: 18291.28,
    "Puerto Rico": 21959.32,
    Andorra: "n/a",
    "St. Lucia": 18659.2,
    "The Bahamas": 30269.03,
    Malta: 12812.67,
    Italy: 25732.64,
    Grenada: 17939.38,
    Zambia: 3087.79,
    Peru: 8797.06,
    Slovenia: 14574.31,
    Spain: 21239.16,
    Estonia: 8372.81,
    Cyprus: 20219.57,
    Dominica: 13844.08,
    Bolivia: 8344.94,
    Belarus: 693.82,
    "St. Vincent and the Grenadines": 13747.32,
    Bulgaria: 6091.3,
    Portugal: 15095.51,
    "Solomon Islands": 7640.76,
    Lithuania: 6314.52,
    Georgia: 3050.11,
    "Slovak Republic": 9396.72,
    Ghana: 1043.39,
    Latvia: 6057.1,
    Lesotho: 5064.62,
    Libya: 11079.03,
    Greece: 18009.35,
    Panama: 4965.21,
    Palau: 9297.56,
    Fiji: 6668.25,
    Tonga: 5014.84,
    "Bosnia and Herzegovina": 4566.56,
    Tajikistan: 1050.62,
    Tunisia: 4144.63,
    Nauru: 4212.97,
    "Papua New Guinea": 3938.01,
    Samoa: 7190.42,
    "West Bank and Gaza": 6556.95,
    Eritrea: 4621.09,
    Bahrain: 6755.3,
    Tuvalu: 3074.86,
    Belize: 7775.47,
    Montenegro: 2956.21,
    Azerbaijan: 1482.41,
    Kuwait: 7888.24,
    Oman: 4565.24,
    Ecuador: 3025.01,
    Kosovo: 1862.65,
    "Marshall Islands": 2668.17,
    "El Salvador": 2428.57,
    Micronesia: 2370.44,
    Jordan: 1610.74,
    Kiribati: 1586.89,
    "Timor-Leste": 476.79,
    Liberia: 314.89
  },
  "2006": {
    Afghanistan: 16012.96,
    Ethiopia: 1721.8,
    Lebanon: 6974931.51,
    Syria: 88099,
    Somalia: "n/a",
    Venezuela: 0.15,
    "Islamic Republic of Iran": 34643272.82,
    Vietnam: 15929228.93,
    Indonesia: 16173465.63,
    Korea: 20760465.71,
    Paraguay: 12876892.75,
    Uzbekistan: 988989.59,
    "Lao P.D.R.": 6791598.37,
    Colombia: 9049154.85,
    Mongolia: 1559102.82,
    Guinea: 2217377.49,
    Chile: 5023298.82,
    Iceland: 4087205.02,
    "Sierra Leone": 957925.86,
    Cambodia: 2202092.65,
    "Costa Rica": 2714244.85,
    Iraq: 3406058.7,
    Hungary: 2413049.42,
    Kazakhstan: 663362.76,
    Uganda: 966530.24,
    Gabon: 3798404.11,
    Japan: 4189322.19,
    "Equatorial Guinea": 6657451.18,
    Guyana: 510834.17,
    Tanzania: 615122.17,
    Armenia: 885396.6,
    Argentina: 18370.36,
    Madagascar: 725707.15,
    Myanmar: 348431.68,
    Angola: 205891.69,
    "Democratic Republic of the Congo": 119613.88,
    "CÙte d'Ivoire": 683635.47,
    "Republic of Congo": 1272758.48,
    Nigeria: 201086.51,
    Yemen: 173897.38,
    Rwanda: 203444.44,
    Serbia: 294477.87,
    "Sri Lanka": 170755.68,
    Senegal: 536913.22,
    Jamaica: 295111.67,
    "Taiwan Province of China": 549584.6,
    Cameroon: 555381.06,
    Russia: 201875.64,
    Benin: 447224.99,
    Uruguay: 152483.34,
    Djibouti: 241417.58,
    Sudan: 2713.91,
    Norway: 474395.8,
    Albania: 291636.22,
    "Macao SAR": 231805.11,
    "Dominican Republic": 139051.48,
    "Czech Republic": 345366.5,
    Togo: 287571.71,
    Burundi: 169295.47,
    Malawi: 54523.63,
    Mali: 282402.6,
    "Burkina Faso": 247342.33,
    Algeria: 254913.97,
    Comoros: 435281.99,
    "Guinea-Bissau": 244481.14,
    Sweden: 342541.42,
    Mauritius: 180608.37,
    Denmark: 309953.52,
    "Hong Kong SAR": 217741.41,
    Niger: 175178.79,
    "North Macedonia": 163980.74,
    Chad: 372223.44,
    "Cabo Verde": 226130.4,
    Vanuatu: 222090.75,
    Bhutan: 59559.34,
    Pakistan: 52881.25,
    "Central African Republic": 199386.08,
    Kenya: 86765.41,
    Seychelles: 66315.88,
    Bangladesh: 34227.42,
    "South Sudan": "n/a",
    Thailand: 127645.04,
    Maldives: 67440.55,
    India: 36849.05,
    Haiti: 32991.16,
    Qatar: 220554.07,
    Mexico: 99210.5,
    Philippines: 75474.33,
    Zimbabwe: 677.88,
    Nepal: 28685.62,
    Suriname: 15302.23,
    Ukraine: 11712.39,
    Israel: 97401.49,
    "United Arab Emirates": 162741.52,
    Moldova: 15485.03,
    "Kyrgyz Republic": 21930.26,
    Turkey: 11411.98,
    Croatia: 66296.62,
    Luxembourg: 72072.93,
    "South Africa": 42471.91,
    "Trinidad and Tobago": 90139.67,
    Botswana: 31013.87,
    China: 16706.04,
    Ireland: 42797.1,
    Singapore: 53655.81,
    Egypt: 8995.8,
    Australia: 50277.04,
    "Saudi Arabia": 58514.94,
    Mauritania: 35650.82,
    Nicaragua: 21518.41,
    Switzerland: 74611.05,
    Poland: 28027.1,
    "United States": 46216.85,
    Romania: 16124.69,
    Honduras: 27021.21,
    Namibia: 27121,
    "New Zealand": 40372.49,
    Eswatini: 22076.01,
    Canada: 46007.77,
    Malaysia: 23297.24,
    "The Gambia": 18589.67,
    "St. Kitts and Nevis": 35357.04,
    Aruba: 44473.73,
    Netherlands: 35786.61,
    "São Tomé and Príncipe": 10451.21,
    Austria: 32393.09,
    Brazil: 12880.49,
    Finland: 32897.8,
    Mozambique: 11057.82,
    Germany: 29382.41,
    Turkmenistan: 7892.21,
    Belgium: 30933.23,
    "Antigua and Barbuda": 38529.31,
    "San Marino": 51366,
    Guatemala: 17766.85,
    France: 30100.31,
    "Brunei Darussalam": 55120.57,
    Barbados: 30668.02,
    "United Kingdom": 24178.72,
    Morocco: 19726.11,
    "Puerto Rico": 22935.95,
    Andorra: "n/a",
    "St. Lucia": 20580.42,
    "The Bahamas": 30908.21,
    Malta: 13340.78,
    Italy: 26637.74,
    Grenada: 17977.19,
    Zambia: 3712.18,
    Peru: 10170.49,
    Slovenia: 15708.83,
    Spain: 22628.75,
    Estonia: 10074.87,
    Cyprus: 21505.14,
    Dominica: 14842.58,
    Bolivia: 9770.8,
    Belarus: 851.1,
    "St. Vincent and the Grenadines": 15182.96,
    Bulgaria: 6980.91,
    Portugal: 15800.77,
    "Solomon Islands": 8519.59,
    Lithuania: 7355.95,
    Georgia: 3646.37,
    "Slovak Republic": 10489.89,
    Ghana: 1209.96,
    Latvia: 7701.52,
    Lesotho: 5697.28,
    Libya: 12688.62,
    Greece: 19630.13,
    Panama: 5401.56,
    Palau: 9649.44,
    Fiji: 6996.88,
    Tonga: 5777.87,
    "Bosnia and Herzegovina": 5155.52,
    Tajikistan: 1324.5,
    Tunisia: 4487.6,
    Nauru: 4275.63,
    "Papua New Guinea": 4315.81,
    Samoa: 7681.8,
    "West Bank and Gaza": 6597.73,
    Eritrea: 4938.75,
    Bahrain: 7244.61,
    Tuvalu: 3271.01,
    Belize: 8318.3,
    Montenegro: 3527.85,
    Azerbaijan: 2191.74,
    Kuwait: 9258.52,
    Oman: 5359.24,
    Ecuador: 3351.48,
    Kosovo: 1911.68,
    "Marshall Islands": 2749.07,
    "El Salvador": 2631.82,
    Micronesia: 2413.78,
    Jordan: 1855.11,
    Kiribati: 1548.33,
    "Timor-Leste": 458.88,
    Liberia: 357.24
  },
  "2007": {
    Afghanistan: 19062.11,
    Ethiopia: 2192.08,
    Lebanon: 7850697.67,
    Syria: 100625.83,
    Somalia: "n/a",
    Venezuela: 0.18,
    "Islamic Republic of Iran": 45501988.29,
    Vietnam: 18532644.88,
    Indonesia: 18867051.35,
    Korea: 22382472.73,
    Paraguay: 15041183.91,
    Uzbekistan: 1325131.31,
    "Lao P.D.R.": 7678390.8,
    Colombia: 10045024.89,
    Mongolia: 1891528.08,
    Guinea: 2624573.17,
    Chile: 5490042.46,
    Iceland: 4507891.52,
    "Sierra Leone": 1075855.78,
    Cambodia: 2549025,
    "Costa Rica": 3199955.05,
    Iraq: 3877987.83,
    Hungary: 2553285.22,
    Kazakhstan: 825212.09,
    Uganda: 1093315.37,
    Gabon: 4140720.09,
    Japan: 4221137.33,
    "Equatorial Guinea": 7554126.42,
    Guyana: 603193.16,
    Tanzania: 687109.21,
    Armenia: 1053272.04,
    Argentina: 22791.23,
    Madagascar: 821986.22,
    Myanmar: 477570.01,
    Angola: 237719.03,
    "Democratic Republic of the Congo": 147961.21,
    "CÙte d'Ivoire": 697960.84,
    "Republic of Congo": 1237746.21,
    Nigeria: 225470.71,
    Yemen: 193301.92,
    Rwanda: 241847.83,
    Serbia: 342100.36,
    "Sri Lanka": 206413.32,
    Senegal: 573120.57,
    Jamaica: 332045.34,
    "Taiwan Province of China": 582093.71,
    Cameroon: 572278.01,
    Russia: 249552.78,
    Benin: 462457.64,
    Uruguay: 177715.77,
    Djibouti: 262537.57,
    Sudan: 3224.95,
    Norway: 497747.17,
    Albania: 325091.66,
    "Macao SAR": 275378.18,
    "Dominican Republic": 158971.8,
    "Czech Republic": 376384.37,
    Togo: 290392.33,
    Burundi: 184106.22,
    Malawi: 60497.54,
    Mali: 295678.95,
    "Burkina Faso": 256098.84,
    Algeria: 276238.6,
    Comoros: 449332.91,
    "Guinea-Bissau": 260062.77,
    Sweden: 361570.77,
    Mauritius: 205876.75,
    Denmark: 319225.11,
    "Hong Kong SAR": 237915.95,
    Niger: 186289.85,
    "North Macedonia": 182326.03,
    Chad: 384306.34,
    "Cabo Verde": 250748.76,
    Vanuatu: 236232.99,
    Bhutan: 69072.58,
    Pakistan: 58416.81,
    "Central African Republic": 204796.71,
    Kenya: 95097.6,
    Seychelles: 81456.08,
    Bangladesh: 38539.07,
    "South Sudan": "n/a",
    Thailand: 137141.4,
    Maldives: 78444.54,
    India: 42148.83,
    Haiti: 37090,
    Qatar: 240000.73,
    Mexico: 105789.62,
    Philippines: 81437.3,
    Zimbabwe: 646.56,
    Nepal: 31537.49,
    Suriname: 16924.35,
    Ukraine: 15662.01,
    Israel: 102563.33,
    "United Arab Emirates": 152306.83,
    Moldova: 18530.16,
    "Kyrgyz Republic": 27040.68,
    Turkey: 12576.35,
    Croatia: 72647.66,
    Luxembourg: 78076.05,
    "South Africa": 47803.99,
    "Trinidad and Tobago": 106094.9,
    Botswana: 34492.63,
    China: 20472.37,
    Ireland: 44337,
    Singapore: 59429.38,
    Egypt: 10640.48,
    Australia: 53786.19,
    "Saudi Arabia": 62499.75,
    Mauritania: 37015.25,
    Nicaragua: 24474.88,
    Switzerland: 78953.9,
    Poland: 31147.36,
    "United States": 47943.35,
    Romania: 20145.81,
    Honduras: 29911.85,
    Namibia: 30888.21,
    "New Zealand": 43347.93,
    Eswatini: 24000.28,
    Canada: 48028.08,
    Malaysia: 25716.24,
    "The Gambia": 19410.71,
    "St. Kitts and Nevis": 37383.65,
    Aruba: 47858.47,
    Netherlands: 37851.22,
    "São Tomé and Príncipe": 11981.4,
    Austria: 34234.06,
    Brazil: 14390.03,
    Finland: 35450.75,
    Mozambique: 12460.16,
    Germany: 30861.4,
    Turkmenistan: 9465.94,
    Belgium: 32464.25,
    "Antigua and Barbuda": 43224.85,
    "San Marino": 53253.63,
    Guatemala: 19621.71,
    France: 31416.01,
    "Brunei Darussalam": 54712,
    Barbados: 33851.22,
    "United Kingdom": 25213.8,
    Morocco: 20889.48,
    "Puerto Rico": 23664.87,
    Andorra: "n/a",
    "St. Lucia": 21429.84,
    "The Bahamas": 31894.39,
    Malta: 14275.27,
    Italy: 27599.06,
    Grenada: 19475.11,
    Zambia: 4416.59,
    Peru: 11224.43,
    Slovenia: 17446.23,
    Spain: 23776.17,
    Estonia: 12233.59,
    Cyprus: 23104.67,
    Dominica: 16037.76,
    Bolivia: 10786.3,
    Belarus: 1048.87,
    "St. Vincent and the Grenadines": 16935.48,
    Bulgaria: 8305.35,
    Portugal: 16644.54,
    "Solomon Islands": 9643.46,
    Lithuania: 8978.23,
    Georgia: 4511.31,
    "Slovak Republic": 11755.29,
    Ghana: 1422.16,
    Latvia: 10262.25,
    Lesotho: 6636.13,
    Libya: 14748.36,
    Greece: 20907.2,
    Panama: 6127.03,
    Palau: 10017.03,
    Fiji: 7094.14,
    Tonga: 5859.68,
    "Bosnia and Herzegovina": 5820.34,
    Tajikistan: 1786.78,
    Tunisia: 4842.11,
    Nauru: 3148.26,
    "Papua New Guinea": 4673.86,
    Samoa: 8243.82,
    "West Bank and Gaza": 6423.81,
    Eritrea: 5232.63,
    Bahrain: 7861.54,
    Tuvalu: 3417.74,
    Belize: 8511.59,
    Montenegro: 4366.18,
    Azerbaijan: 3272.58,
    Kuwait: 9583.63,
    Oman: 5935.45,
    Ecuador: 3588.31,
    Kosovo: 2090.99,
    "Marshall Islands": 2864.64,
    "El Salvador": 2786.16,
    Micronesia: 2457.38,
    Jordan: 2019.97,
    Kiribati: 1641.24,
    "Timor-Leste": 537.24,
    Liberia: 415.96
  },
  "2008": {
    Afghanistan: 22503.29,
    Ethiopia: 3114.17,
    Lebanon: 9212874.98,
    Syria: 118945.9,
    Somalia: "n/a",
    Venezuela: 0.25,
    "Islamic Republic of Iran": 53797256.56,
    Vietnam: 23792321.08,
    Indonesia: 23440305.61,
    Korea: 23529170.74,
    Paraguay: 17688976.51,
    Uzbekistan: 1747832.73,
    "Lao P.D.R.": 8591564.91,
    Colombia: 11048217.47,
    Mongolia: 2458994.77,
    Guinea: 3106834.75,
    Chile: 5615919.11,
    Iceland: 5039085.9,
    "Sierra Leone": 1217970.05,
    Cambodia: 3010532.71,
    "Costa Rica": 3680436.76,
    Iraq: 5335609.04,
    Hungary: 2709543.55,
    Kazakhstan: 1004412.35,
    Uganda: 1349681.8,
    Gabon: 4647060.6,
    Japan: 4133559.05,
    "Equatorial Guinea": 10184159.01,
    Guyana: 678025.01,
    Tanzania: 817839,
    Armenia: 1197391.81,
    Argentina: 28925.11,
    Madagascar: 916287.36,
    Myanmar: 599860.18,
    Angola: 306262.73,
    "Democratic Republic of the Congo": 183203.93,
    "CÙte d'Ivoire": 756883.55,
    "Republic of Congo": 1492559.84,
    Nigeria: 260583.51,
    Yemen: 233957.57,
    Rwanda: 298000,
    Serbia: 395968.48,
    "Sri Lanka": 252594.54,
    Senegal: 626161.43,
    Jamaica: 372644.18,
    "Taiwan Province of China": 569304.96,
    Cameroon: 614275.19,
    Russia: 309844.82,
    Benin: 501940.05,
    Uruguay: 205490.26,
    Djibouti: 300541.45,
    Sudan: 3554.35,
    Norway: 544616.58,
    Albania: 366664.68,
    "Macao SAR": 307063,
    "Dominican Republic": 179064,
    "Czech Republic": 390862.91,
    Togo: 328731.46,
    Burundi: 232146.39,
    Malawi: 70913.45,
    Mali: 321425.68,
    "Burkina Faso": 286958.44,
    Algeria: 319265.23,
    Comoros: 469621.38,
    "Guinea-Bissau": 299856.91,
    Sweden: 368639.27,
    Mauritius: 228477.78,
    Denmark: 328988.09,
    "Hong Kong SAR": 245191.34,
    Niger: 212886.64,
    "North Macedonia": 202521.8,
    Chad: 415985.85,
    "Cabo Verde": 273931.38,
    Vanuatu: 261327.18,
    Bhutan: 78160.87,
    Pakistan: 64604.47,
    "Central African Republic": 217546.94,
    Kenya: 90955.43,
    Seychelles: 105192.96,
    Bangladesh: 43566.47,
    "South Sudan": "n/a",
    Thailand: 145900.89,
    Maldives: 93925.77,
    India: 46891,
    Haiti: 41101.92,
    Qatar: 263844.07,
    Mexico: 111895.25,
    Philippines: 89466.54,
    Zimbabwe: 553.38,
    Nepal: 34966.91,
    Suriname: 20083.68,
    Ukraine: 20763.38,
    Israel: 106419.96,
    "United Arab Emirates": 143501.88,
    Moldova: 21853.86,
    "Kyrgyz Republic": 35542.57,
    Turkey: 14021.23,
    Croatia: 78170.73,
    Luxembourg: 78810.83,
    "South Africa": 52447.65,
    "Trinidad and Tobago": 134900.18,
    Botswana: 37575.03,
    China: 23950.51,
    Ireland: 41473.01,
    Singapore: 56606.57,
    Egypt: 12521.23,
    Australia: 57466.69,
    "Saudi Arabia": 75589.84,
    Mauritania: 39757.97,
    Nicaragua: 29036.16,
    Switzerland: 81315.12,
    Poland: 33728.17,
    "United States": 48470.55,
    Romania: 26160.48,
    Honduras: 32880.39,
    Namibia: 34645.73,
    "New Zealand": 44444.86,
    Eswatini: 26525.42,
    Canada: 49911.75,
    Malaysia: 29220.29,
    "The Gambia": 20517.13,
    "St. Kitts and Nevis": 41718.32,
    Aruba: 50428.22,
    Netherlands: 39450.31,
    "São Tomé and Príncipe": 16544.96,
    Austria: 35301.38,
    Brazil: 16280.84,
    Finland: 36650.43,
    Mozambique: 13697.02,
    Germany: 31530.01,
    Turkmenistan: 17134.41,
    Belgium: 32975.2,
    "Antigua and Barbuda": 44630.85,
    "San Marino": 53794.71,
    Guatemala: 21727.84,
    France: 32065.41,
    "Brunei Darussalam": 60187.86,
    Barbados: 34508.46,
    "United Kingdom": 25706.18,
    Morocco: 22839.64,
    "Puerto Rico": 24898.34,
    Andorra: "n/a",
    "St. Lucia": 22792.95,
    "The Bahamas": 31248.6,
    Malta: 15217.1,
    Italy: 27756.8,
    Grenada: 21147.39,
    Zambia: 5115.42,
    Peru: 12360.63,
    Slovenia: 18866.03,
    Spain: 24129.29,
    Estonia: 12428.54,
    Cyprus: 24486.53,
    Dominica: 17451.29,
    Bolivia: 12429.84,
    Belarus: 1406.14,
    "St. Vincent and the Grenadines": 17190.11,
    Bulgaria: 9569.43,
    Portugal: 16963.38,
    "Solomon Islands": 10762.4,
    Lithuania: 10211.9,
    Georgia: 5096.81,
    "Slovak Republic": 12758.52,
    Ghana: 1785.73,
    Latvia: 11160.11,
    Lesotho: 7694.61,
    Libya: 15364.73,
    Greece: 21693.28,
    Panama: 7110.23,
    Palau: 10281.35,
    Fiji: 7229.64,
    Tonga: 6401.2,
    "Bosnia and Herzegovina": 6655.33,
    Tajikistan: 2409.02,
    Tunisia: 5310.98,
    Nauru: 4424.68,
    "Papua New Guinea": 5085.69,
    Samoa: 9253.27,
    "West Bank and Gaza": 6865.02,
    Eritrea: 4631.54,
    Bahrain: 8736.76,
    Tuvalu: 3726.15,
    Belize: 8808.99,
    Montenegro: 5029.71,
    Azerbaijan: 4571.49,
    Kuwait: 11511.36,
    Oman: 8407.58,
    Ecuador: 4267.47,
    Kosovo: 2258,
    "Marshall Islands": 2894.23,
    "El Salvador": 2933.4,
    Micronesia: 2531.36,
    Jordan: 2477.75,
    Kiribati: 1703.46,
    "Timor-Leste": 627.16,
    Liberia: 497.45
  },
  "2009": {
    Afghanistan: 25734.84,
    Ethiopia: 4137.06,
    Lebanon: 11087619.31,
    Syria: 119508.52,
    Somalia: "n/a",
    Venezuela: 0.25,
    "Islamic Republic of Iran": 56115685.63,
    Vietnam: 26375675.7,
    Indonesia: 25656746.41,
    Korea: 24445360.46,
    Paraguay: 17998913.71,
    Uzbekistan: 2232840,
    "Lao P.D.R.": 8910865.89,
    Colombia: 11501714.23,
    Mongolia: 2426351.2,
    Guinea: 3044203.25,
    Chile: 5719296.38,
    Iceland: 5092529.62,
    "Sierra Leone": 1324509.16,
    Cambodia: 3054639.9,
    "Costa Rica": 3943794.74,
    Iraq: 4331178.1,
    Hungary: 2637649.69,
    Kazakhstan: 1049641.12,
    Uganda: 1656489.05,
    Gabon: 3699042.99,
    Japan: 3880330.35,
    "Equatorial Guinea": 7805338.4,
    Guyana: 705129.9,
    Tanzania: 915022.02,
    Armenia: 1057794.95,
    Argentina: 31093.74,
    Madagascar: 914603.8,
    Myanmar: 677487.64,
    Angola: 249630,
    "Democratic Republic of the Congo": 241817.99,
    "CÙte d'Ivoire": 779556.62,
    "Republic of Congo": 1281548.51,
    Nigeria: 286962.97,
    Yemen: 215205.21,
    Rwanda: 332268.04,
    Serbia: 417200.79,
    "Sri Lanka": 274991.58,
    Senegal: 615584.81,
    Jamaica: 396602.52,
    "Taiwan Province of China": 558805.04,
    Cameroon: 620786.08,
    Russia: 291108.28,
    Benin: 512035.68,
    Uruguay: 229779.8,
    Djibouti: 305853.18,
    Sudan: 3431.12,
    Norway: 501475.01,
    Albania: 390752.87,
    "Macao SAR": 317954.25,
    "Dominican Republic": 185076.01,
    "Czech Republic": 379282.78,
    Togo: 342994.36,
    Burundi: 256836.75,
    Malawi: 80567.44,
    Mali: 340950.01,
    "Burkina Faso": 293545.46,
    Algeria: 282636.54,
    Comoros: 477094.16,
    "Guinea-Bissau": 288870.89,
    Sweden: 357700.54,
    Mauritius: 233885.86,
    Denmark: 312466.35,
    "Hong Kong SAR": 237156.97,
    Niger: 217638.46,
    "North Macedonia": 201986.44,
    Chad: 379373.34,
    "Cabo Verde": 273418.91,
    Vanuatu: 270302.49,
    Bhutan: 85880.76,
    Pakistan: 78485.59,
    "Central African Republic": 227899.62,
    Kenya: 86887.07,
    Seychelles: 132115.3,
    Bangladesh: 48317.48,
    "South Sudan": "n/a",
    Thailand: 144446.3,
    Maldives: 95370.31,
    India: 53196.11,
    Haiti: 47544.49,
    Qatar: 195915.39,
    Mexico: 108503.69,
    Philippines: 91638.5,
    Zimbabwe: 790.35,
    Nepal: 42024.87,
    Suriname: 21733.04,
    Ukraine: 19924.37,
    Israel: 109292.91,
    "United Arab Emirates": 113555.26,
    Moldova: 21010.91,
    "Kyrgyz Republic": 37624.04,
    Turkey: 13869.33,
    Croatia: 74684.13,
    Luxembourg: 74927.05,
    "South Africa": 55281.99,
    "Trinidad and Tobago": 93465.67,
    Botswana: 36226.22,
    China: 26050.98,
    Ireland: 37281.75,
    Singapore: 56619.62,
    Egypt: 14250.3,
    Australia: 57733.5,
    "Saudi Arabia": 60355.07,
    Mauritania: 38627.62,
    Nicaragua: 29394.32,
    Switzerland: 78847.56,
    Poland: 35977.27,
    "United States": 47102.43,
    Romania: 25972.93,
    Honduras: 33816.69,
    Namibia: 36618.48,
    "New Zealand": 44592.44,
    Eswatini: 29358.6,
    Canada: 46792.25,
    Malaysia: 26589.24,
    "The Gambia": 22202.26,
    "St. Kitts and Nevis": 41082.76,
    Aruba: 44991.24,
    Netherlands: 37901.86,
    "São Tomé and Príncipe": 17802.85,
    Austria: 34531.52,
    Brazil: 17271.33,
    Finland: 34122.47,
    Mozambique: 14320.6,
    Germany: 30388.16,
    Turkmenistan: 19707.98,
    Belgium: 32220.82,
    "Antigua and Barbuda": 39586.09,
    "San Marino": 48096.67,
    Guatemala: 21652.71,
    France: 30999.76,
    "Brunei Darussalam": 45588.38,
    Barbados: 32072.81,
    "United Kingdom": 24871.32,
    Morocco: 23547.57,
    "Puerto Rico": 25768.73,
    Andorra: "n/a",
    "St. Lucia": 22972.41,
    "The Bahamas": 29296.49,
    Malta: 15233.02,
    Italy: 26543.93,
    Grenada: 19685.22,
    Zambia: 5726.11,
    Peru: 12562.3,
    Slovenia: 17838.75,
    Spain: 23061.88,
    Estonia: 10589.51,
    Cyprus: 23434.43,
    Dominica: 18640.79,
    Bolivia: 12333,
    Belarus: 1493.5,
    "St. Vincent and the Grenadines": 16666.52,
    Bulgaria: 9675.34,
    Portugal: 16598.51,
    "Solomon Islands": 11507.53,
    Lithuania: 8503.89,
    Georgia: 4829.17,
    "Slovak Republic": 11908.35,
    Ghana: 2113.27,
    Latvia: 8753.63,
    Lesotho: 7913.44,
    Libya: 10686.09,
    Greece: 21228.97,
    Panama: 7532.4,
    Palau: 9822.35,
    Fiji: 7193.96,
    Tonga: 6354.63,
    "Bosnia and Herzegovina": 6632.74,
    Tajikistan: 2759.71,
    Tunisia: 5576.71,
    Nauru: 6231.69,
    "Papua New Guinea": 5050.43,
    Samoa: 9292.5,
    "West Bank and Gaza": 8106.74,
    Eritrea: 6389.87,
    Bahrain: 7318.95,
    Tuvalu: 3486.69,
    Belize: 8248.43,
    Montenegro: 4842.13,
    Azerbaijan: 3990.13,
    Kuwait: 8750.95,
    Oman: 6453.8,
    Ecuador: 4241.94,
    Kosovo: 2329,
    "Marshall Islands": 2864.63,
    "El Salvador": 2858.48,
    Micronesia: 2711.06,
    Jordan: 2554.11,
    Kiribati: 1683.39,
    "Timor-Leste": 683.31,
    Liberia: 503.6
  },
  "2010": {
    Afghanistan: 29328.41,
    Ethiopia: 4654.87,
    Lebanon: 11700636.21,
    Syria: 130500.59,
    Somalia: "n/a",
    Venezuela: 0.36,
    "Islamic Republic of Iran": 67907077.51,
    Vietnam: 31146029.44,
    Indonesia: 28884425.18,
    Korea: 26690241.17,
    Paraguay: 20602525.35,
    Uzbekistan: 2819024.01,
    "Lao P.D.R.": 9920802.88,
    Colombia: 12340774.77,
    Mongolia: 3533756.42,
    Guinea: 3608254.96,
    Chile: 6528272.54,
    Iceland: 5292217.36,
    "Sierra Leone": 1598534.8,
    Cambodia: 3301492.99,
    "Costa Rica": 4348350.66,
    Iraq: 5234241.22,
    Hungary: 2739291.99,
    Kazakhstan: 1326937.75,
    Uganda: 1761018.82,
    Gabon: 4416541.26,
    Japan: 3962014.51,
    "Equatorial Guinea": 8487287.41,
    Guyana: 781412.48,
    Tanzania: 1032681.36,
    Armenia: 1166485.13,
    Argentina: 40739.99,
    Madagascar: 986371.12,
    Myanmar: 755028.98,
    Angola: 334669.57,
    "Democratic Republic of the Congo": 302594.66,
    "CÙte d'Ivoire": 816883.7,
    "Republic of Congo": 1778795.25,
    Nigeria: 349957.73,
    Yemen: 278173.83,
    Rwanda: 356900,
    Serbia: 446116.47,
    "Sri Lanka": 316540.88,
    Senegal: 629172.16,
    Jamaica: 427661.51,
    "Taiwan Province of China": 607040.43,
    Cameroon: 636560.73,
    Russia: 347304.31,
    Benin: 512871.34,
    Uruguay: 258440.93,
    Djibouti: 326251.83,
    Sudan: 4260.56,
    Norway: 527995.39,
    Albania: 425552.92,
    "Macao SAR": 409194.28,
    "Dominican Republic": 209229.12,
    "Czech Republic": 381651.35,
    Togo: 356473.75,
    Burundi: 284978.1,
    Malawi: 93845.15,
    Mali: 362717.52,
    "Burkina Faso": 320545.61,
    Algeria: 333302.68,
    Comoros: 490424.69,
    "Guinea-Bissau": 318557.4,
    Sweden: 379539.53,
    Mauritius: 246286.79,
    Denmark: 327192.72,
    "Hong Kong SAR": 251886.96,
    Niger: 235361.64,
    "North Macedonia": 212560.35,
    Chad: 442654.6,
    "Cabo Verde": 275821.92,
    Vanuatu: 271120.55,
    Bhutan: 97855.83,
    Pakistan: 86571.92,
    "Central African Republic": 243715.15,
    Kenya: 93202.85,
    Seychelles: 130393.31,
    Bangladesh: 54042.78,
    "South Sudan": "n/a",
    Thailand: 160847.38,
    Maldives: 103509.73,
    India: 63066.02,
    Haiti: 47400.51,
    Qatar: 254057.94,
    Mexico: 117507.99,
    Philippines: 100917.45,
    Zimbabwe: 975.85,
    Nepal: 50477.55,
    Suriname: 24176.19,
    Ukraine: 23670.8,
    Israel: 115127.82,
    "United Arab Emirates": 128779.65,
    Moldova: 30024.7,
    "Kyrgyz Republic": 40671.31,
    Turkey: 15838.54,
    Croatia: 74566.32,
    Luxembourg: 80024.94,
    "South Africa": 59529.96,
    "Trinidad and Tobago": 108112.26,
    Botswana: 42111.99,
    China: 30464.79,
    Ireland: 36638.7,
    Singapore: 64407.6,
    Egypt: 16120.85,
    Australia: 61402.91,
    "Saudi Arabia": 71862.53,
    Mauritania: 47277.29,
    Nicaragua: 32164.37,
    Switzerland: 80754.19,
    Poland: 38051.93,
    "United States": 48586.29,
    Romania: 26042,
    Honduras: 35982.82,
    Namibia: 39619.63,
    "New Zealand": 46277.28,
    Eswatini: 31220.7,
    Canada: 49061.54,
    Malaysia: 29141.13,
    "The Gambia": 24108.19,
    "St. Kitts and Nevis": 40869.65,
    Aruba: 43111.93,
    Netherlands: 38563.34,
    "São Tomé and Príncipe": 20872.14,
    Austria: 35389.81,
    Brazil: 19938.57,
    Finland: 35157.54,
    Mozambique: 16025.92,
    Germany: 31941.31,
    Turkmenistan: 19539.32,
    Belgium: 33500.29,
    "Antigua and Barbuda": 36624.63,
    "San Marino": 45542.86,
    Guatemala: 22858.83,
    France: 31789.72,
    "Brunei Darussalam": 48318.98,
    Barbados: 32406.51,
    "United Kingdom": 25589.98,
    Morocco: 24380.83,
    "Puerto Rico": 26435.74,
    Andorra: 37022.48,
    "St. Lucia": 24213.8,
    "The Bahamas": 29303.26,
    Malta: 16462.22,
    Italy: 26993.99,
    Grenada: 19597.09,
    Zambia: 6985.41,
    Peru: 14269.91,
    Slovenia: 17764.69,
    Spain: 23038.05,
    Estonia: 11071.26,
    Cyprus: 23695.58,
    Dominica: 18848.58,
    Bolivia: 13746.32,
    Belarus: 1795.13,
    "St. Vincent and the Grenadines": 16805.38,
    Bulgaria: 9918.11,
    Portugal: 16987.53,
    "Solomon Islands": 12936.66,
    Lithuania: 9051.13,
    Georgia: 5742.82,
    "Slovak Republic": 12649.98,
    Ghana: 2514.35,
    Latvia: 8499.27,
    Lesotho: 8792.38,
    Libya: 14463.47,
    Greece: 20156.33,
    Panama: 8039.76,
    Palau: 9986.29,
    Fiji: 7683,
    Tonga: 6884.59,
    "Bosnia and Herzegovina": 6843.01,
    Tajikistan: 3232.91,
    Tunisia: 5926.21,
    Nauru: 5389.07,
    "Papua New Guinea": 5798.04,
    Samoa: 9050.96,
    "West Bank and Gaza": 8996.95,
    Eritrea: 7709.4,
    Bahrain: 7831.22,
    Tuvalu: 3407.34,
    Belize: 8541.88,
    Montenegro: 5045.35,
    Azerbaijan: 4719.59,
    Kuwait: 9234.67,
    Oman: 7316.18,
    Ecuador: 4633.25,
    Kosovo: 2480,
    "Marshall Islands": 3030.39,
    "El Salvador": 2983.23,
    Micronesia: 2887.35,
    Jordan: 2682.26,
    Kiribati: 1651.52,
    "Timor-Leste": 806.41,
    Liberia: 541.58
  },
  "2011": {
    Afghanistan: 33406.83,
    Ethiopia: 6114.54,
    Lebanon: 11570500.86,
    Syria: "n/a",
    Somalia: "n/a",
    Venezuela: 0.47,
    "Islamic Republic of Iran": 85114523.67,
    Vietnam: 40289838.34,
    Indonesia: 32363747.86,
    Korea: 27813991,
    Paraguay: 22234842.33,
    Uzbekistan: 3544660.99,
    "Lao P.D.R.": 11336909.68,
    Colombia: 13893951.48,
    Mongolia: 4685394.14,
    Guinea: 4047473.78,
    Chile: 7066768.32,
    Iceland: 5542464.8,
    "Sierra Leone": 1949831.69,
    Cambodia: 3614496.38,
    "Costa Rica": 4687846.1,
    Iraq: 6842788.75,
    Hungary: 2854145.9,
    Kazakhstan: 1693847.87,
    Uganda: 2199933.53,
    Gabon: 5134433.56,
    Japan: 3891462.55,
    "Equatorial Guinea": 10122418.01,
    Guyana: 898243.17,
    Tanzania: 1206858.79,
    Armenia: 1272899.58,
    Argentina: 52810.12,
    Madagascar: 1075876.1,
    Myanmar: 865919.41,
    Angola: 443020.06,
    "Democratic Republic of the Congo": 355918.97,
    "CÙte d'Ivoire": 782485.32,
    "Republic of Congo": 1967969.77,
    Nigeria: 391347.69,
    Yemen: 278432.12,
    Rwanda: 405000,
    Serbia: 499517.09,
    "Sri Lanka": 353900.82,
    Senegal: 644090.57,
    Jamaica: 459026.09,
    "Taiwan Province of China": 614090.64,
    Cameroon: 662148.64,
    Russia: 420213.06,
    Benin: 532639.64,
    Uruguay: 294885.66,
    Djibouti: 362007.35,
    Sudan: 6401.2,
    Norway: 561565.8,
    Albania: 447689.08,
    "Macao SAR": 530123.81,
    "Dominican Republic": 230707.92,
    "Czech Republic": 387377.44,
    Togo: 373259.44,
    Burundi: 311607.79,
    Malawi: 109108.73,
    Mali: 406923.1,
    "Burkina Faso": 353989.12,
    Algeria: 397336.38,
    Comoros: 512629.33,
    "Guinea-Bissau": 365379.06,
    Sweden: 393120.53,
    Mauritius: 264009.86,
    Denmark: 332130.47,
    "Hong Kong SAR": 272090.86,
    Niger: 241081.42,
    "North Macedonia": 225355.55,
    Chad: 464558.23,
    "Cabo Verde": 291150.91,
    Vanuatu: 280963.93,
    Bhutan: 113850.8,
    Pakistan: 104252.13,
    "Central African Republic": 260011.28,
    Kenya: 105380.11,
    Seychelles: 144202.09,
    Bangladesh: 61352.29,
    "South Sudan": 4941.19,
    Thailand: 167464.12,
    Maldives: 124486.68,
    India: 69874.5,
    Haiti: 51142.5,
    Qatar: 352453.4,
    Mexico: 127120.57,
    Philippines: 107124.2,
    Zimbabwe: 1131.84,
    Nepal: 57788.85,
    Suriname: 28661.3,
    Ukraine: 28600.59,
    Israel: 120895.78,
    "United Arab Emirates": 153421.26,
    Moldova: 34382.52,
    "Kyrgyz Republic": 52210.47,
    Turkey: 18801.56,
    Croatia: 78064.24,
    Luxembourg: 84332.41,
    "South Africa": 63823.34,
    "Trinidad and Tobago": 123704.84,
    Botswana: 50177.99,
    China: 35882.28,
    Ireland: 37448.11,
    Singapore: 67783.38,
    Egypt: 17909.05,
    Australia: 65111.1,
    "Saudi Arabia": 88705.72,
    Mauritania: 56438.46,
    Nicaragua: 36550.97,
    Switzerland: 81432.77,
    Poland: 41122.95,
    "United States": 50008.11,
    Romania: 27669.05,
    Honduras: 39504.87,
    Namibia: 42582.37,
    "New Zealand": 48109.8,
    Eswatini: 33387.37,
    Canada: 51724.29,
    Malaysia: 31817.67,
    "The Gambia": 22472.09,
    "St. Kitts and Nevis": 43407.48,
    Aruba: 45925.82,
    Netherlands: 39047,
    "São Tomé and Príncipe": 22960.37,
    Austria: 36970.54,
    Brazil: 22259.88,
    Finland: 36834.95,
    Mozambique: 17283.18,
    Germany: 33553.95,
    Turkmenistan: 24871.23,
    Belgium: 34176.93,
    "Antigua and Barbuda": 35897.48,
    "San Marino": 41325.33,
    Guatemala: 24707.69,
    France: 32636.08,
    "Brunei Darussalam": 59238.01,
    Barbados: 33198.03,
    "United Kingdom": 26232.77,
    Morocco: 25171.95,
    "Puerto Rico": 27278.85,
    Andorra: 37367.71,
    "St. Lucia": 25447.35,
    "The Bahamas": 28906.97,
    Malta: 16686.22,
    Italy: 27502.88,
    Grenada: 19687.69,
    Zambia: 7949.64,
    Peru: 15773.74,
    Slovenia: 18075.65,
    Spain: 22760.98,
    Estonia: 12563.48,
    Cyprus: 23582.11,
    Dominica: 19123.4,
    Bolivia: 16313.21,
    Belarus: 3243.72,
    "St. Vincent and the Grenadines": 16663,
    Bulgaria: 11015.59,
    Portugal: 16679.57,
    "Solomon Islands": 14814.79,
    Lithuania: 10342.14,
    Georgia: 6751.84,
    "Slovak Republic": 13223.04,
    Ghana: 3222.86,
    Latvia: 9838.75,
    Lesotho: 9857.39,
    Libya: 6613.08,
    Greece: 18277.52,
    Panama: 9314.69,
    Palau: 10788.49,
    Fiji: 8590.96,
    Tonga: 7355.29,
    "Bosnia and Herzegovina": 7161.1,
    Tajikistan: 3847.17,
    Tunisia: 5992.88,
    Nauru: 6616.46,
    "Papua New Guinea": 6050.82,
    Samoa: 9390.74,
    "West Bank and Gaza": 9703.59,
    Eritrea: 9878.47,
    Bahrain: 9054.24,
    Tuvalu: 3659.46,
    Belize: 8797.76,
    Montenegro: 5264.93,
    Azerbaijan: 5716.32,
    Kuwait: 11498.01,
    Oman: 7936.65,
    Ecuador: 5192.88,
    Kosovo: 2704.52,
    "Marshall Islands": 3239.11,
    "El Salvador": 3266.01,
    Micronesia: 3021.69,
    Jordan: 2767.3,
    Kiribati: 1686.8,
    "Timor-Leste": 947.51,
    Liberia: 625.26
  },
  "2012": {
    Afghanistan: 39962.17,
    Ethiopia: 8731.87,
    Lebanon: 11987821.48,
    Syria: "n/a",
    Somalia: 301.68,
    Venezuela: 0.56,
    "Islamic Republic of Iran": 96556231.09,
    Vietnam: 45870897.38,
    Indonesia: 35105215.36,
    Korea: 28687560.1,
    Paraguay: 22786654.53,
    Uzbekistan: 4316985.58,
    "Lao P.D.R.": 12663436.8,
    Colombia: 14810749.61,
    Mongolia: 5819354.73,
    Guinea: 4506486.75,
    Chile: 7447114.82,
    Iceland: 5773796.45,
    "Sierra Leone": 2460200.69,
    Cambodia: 3892826.44,
    "Costa Rica": 5083072.75,
    Iraq: 7803544.1,
    Hungary: 2911837.5,
    Kazakhstan: 1834111.37,
    Uganda: 2380737.56,
    Gabon: 5053048.96,
    Japan: 3923699.99,
    "Equatorial Guinea": 11005765.49,
    Guyana: 1095417.11,
    Tanzania: 1364009.97,
    Armenia: 1436518.69,
    Argentina: 63208.89,
    Madagascar: 1137331.94,
    Myanmar: 988865.88,
    Angola: 500731.6,
    "Democratic Republic of the Congo": 390528.32,
    "CÙte d'Ivoire": 861170.72,
    "Republic of Congo": 2350843.63,
    Nigeria: 434133.02,
    Yemen: 293102.98,
    Rwanda: 447619.05,
    Serbia: 529430.62,
    "Sri Lanka": 427537.97,
    Senegal: 672800.78,
    Jamaica: 484916.26,
    "Taiwan Province of China": 629519.52,
    Cameroon: 691571.76,
    Russia: 475094.7,
    Benin: 584658.54,
    Uruguay: 330109.29,
    Djibouti: 388950.21,
    Sudan: 6441.16,
    Norway: 588323.22,
    Albania: 459526.46,
    "Macao SAR": 592920.96,
    "Dominican Republic": 246464.76,
    "Czech Republic": 389218.35,
    Togo: 393844.52,
    Burundi: 360796.15,
    Malawi: 127132.51,
    Mali: 409444.19,
    "Burkina Faso": 387001.89,
    Algeria: 432313.64,
    Comoros: 537052.32,
    "Guinea-Bissau": 351294.09,
    Sweden: 391704.47,
    Mauritius: 279201.39,
    Denmark: 339574.69,
    "Hong Kong SAR": 284069.03,
    Niger: 269872.88,
    "North Macedonia": 226302.85,
    Chad: 495302.88,
    "Cabo Verde": 292524.17,
    Vanuatu: 276133.38,
    Bhutan: 130318.57,
    Pakistan: 112047.96,
    "Central African Republic": 288900.14,
    Kenya: 117999.79,
    Seychelles: 164426.72,
    Bangladesh: 69877.45,
    "South Sudan": 3072.32,
    Thailand: 182165.04,
    Maldives: 134019.98,
    India: 78560.16,
    Haiti: 54737.44,
    Qatar: 371036.55,
    Mexico: 135268.86,
    Philippines: 114915.21,
    Zimbabwe: 1310.37,
    Nepal: 65151.31,
    Suriname: 32487.67,
    Ukraine: 30958.46,
    Israel: 126016.15,
    "United Arab Emirates": 161343.59,
    Moldova: 36717.79,
    "Kyrgyz Republic": 55921.75,
    Turkey: 20911.57,
    Croatia: 77557.4,
    Luxembourg: 84046.58,
    "South Africa": 67379.28,
    "Trinidad and Tobago": 123174.78,
    Botswana: 51893.76,
    China: 39658.03,
    Ireland: 38115.06,
    Singapore: 69416.45,
    Egypt: 20299.39,
    Australia: 66079.49,
    "Saudi Arabia": 94530.65,
    Mauritania: 57787.81,
    Nicaragua: 40848.61,
    Switzerland: 81613.53,
    Poland: 42650.57,
    "United States": 51736.74,
    Romania: 29448.6,
    Honduras: 41819.4,
    Namibia: 49578.56,
    "New Zealand": 48957.08,
    Eswatini: 37997.55,
    Canada: 52710.5,
    Malaysia: 33380.18,
    "The Gambia": 23826.27,
    "St. Kitts and Nevis": 42418.39,
    Aruba: 44762.11,
    Netherlands: 39028.84,
    "São Tomé and Príncipe": 26316.56,
    Austria: 37816.44,
    Brazil: 24278.35,
    Finland: 37220.34,
    Mozambique: 18659.34,
    Germany: 34134.5,
    Turkmenistan: 28001.04,
    Belgium: 34866.28,
    "Antigua and Barbuda": 37289.92,
    "San Marino": 39153.78,
    Guatemala: 25599.62,
    France: 32958.93,
    "Brunei Darussalam": 59699.79,
    Barbados: 32743.9,
    "United Kingdom": 26870.26,
    Morocco: 25710.5,
    "Puerto Rico": 27944.74,
    Andorra: 35578,
    "St. Lucia": 25637.01,
    "The Bahamas": 30433.05,
    Malta: 17637.48,
    Italy: 27025.26,
    Grenada: 20099.02,
    Zambia: 8877.52,
    Peru: 16877.99,
    Slovenia: 17637.15,
    Spain: 22047.86,
    Estonia: 13545.59,
    Cyprus: 22552.61,
    Dominica: 18886.14,
    Bolivia: 18082.5,
    Belarus: 5794.27,
    "St. Vincent and the Grenadines": 17060.06,
    Bulgaria: 11289.45,
    Portugal: 16005.59,
    "Solomon Islands": 15754.26,
    Lithuania: 11182.31,
    Georgia: 7281.4,
    "Slovak Republic": 13614.25,
    Ghana: 3947.01,
    Latvia: 10780.94,
    Lesotho: 10732.47,
    Libya: 16014.78,
    Greece: 16992.79,
    Panama: 10674.49,
    Palau: 12058.41,
    Fiji: 8981.18,
    Tonga: 7724.28,
    "Bosnia and Herzegovina": 7265.76,
    Tajikistan: 4522.93,
    Tunisia: 6462.44,
    Nauru: 9101.16,
    "Papua New Guinea": 5971.28,
    Samoa: 9255.03,
    "West Bank and Gaza": 11138.21,
    Eritrea: 10667.23,
    Bahrain: 9563.35,
    Tuvalu: 3484.37,
    Belize: 8937.4,
    Montenegro: 5126.45,
    Azerbaijan: 5927.79,
    Kuwait: 12799.07,
    Oman: 8132.04,
    Ecuador: 5664.89,
    Kosovo: 2786.34,
    "Marshall Islands": 3383.56,
    "El Salvador": 3428.41,
    Micronesia: 3170.96,
    Jordan: 2810.18,
    Kiribati: 1736.1,
    "Timor-Leste": 1013.04,
    Liberia: 695.52
  },
  "2013": {
    Afghanistan: 41772.32,
    Ethiopia: 9969.71,
    Lebanon: 11959379.11,
    Syria: "n/a",
    Somalia: 316.02,
    Venezuela: 0.75,
    "Islamic Republic of Iran": 129109821.09,
    Vietnam: 49840469.24,
    Indonesia: 38365914.7,
    Korea: 29761097.47,
    Paraguay: 25362115.69,
    Uzbekistan: 5111485.33,
    "Lao P.D.R.": 14349983.21,
    Colombia: 15716802.28,
    Mongolia: 6543491.49,
    Guinea: 4926666.34,
    Chile: 7828141.53,
    Iceland: 6121184.25,
    "Sierra Leone": 3105688.31,
    Cambodia: 4167500.48,
    "Costa Rica": 5379816.08,
    Iraq: 8186959.7,
    Hungary: 3056909.88,
    Kazakhstan: 2097735.55,
    Uganda: 2495915.34,
    Gabon: 4820759.12,
    Japan: 3995054.42,
    "Equatorial Guinea": 10002824.46,
    Guyana: 1124891.35,
    Tanzania: 1550185.25,
    Armenia: 1528737.65,
    Argentina: 79338.29,
    Madagascar: 1194092.24,
    Myanmar: 1097871.96,
    Angola: 524723.13,
    "Democratic Republic of the Congo": 421127.85,
    "CÙte d'Ivoire": 947873.18,
    "Republic of Congo": 2252013.54,
    Nigeria: 471629.81,
    Yemen: 325761.69,
    Rwanda: 472336.45,
    Serbia: 575458.04,
    "Sri Lanka": 465976.44,
    Senegal: 677959.08,
    Jamaica: 527790.24,
    "Taiwan Province of China": 653334.63,
    Cameroon: 723878.37,
    Russia: 508019.94,
    Benin: 617972.02,
    Uruguay: 372095.97,
    Djibouti: 411019.08,
    Sudan: 8684.81,
    Norway: 602637.99,
    Albania: 466324.39,
    "Macao SAR": 677758.03,
    "Dominican Republic": 267741.99,
    "Czech Republic": 393948.44,
    Togo: 414058.34,
    Burundi: 396390.73,
    Malawi: 165489.78,
    Mali: 409309.63,
    "Burkina Faso": 388930.84,
    Algeria: 434705.07,
    Comoros: 555899.84,
    "Guinea-Bissau": 351615.55,
    Sweden: 396342.65,
    Mauritius: 295869.47,
    Denmark: 344423.55,
    "Hong Kong SAR": 296537.88,
    Niger: 272409.83,
    "North Macedonia": 242956.01,
    Chad: 485456.72,
    "Cabo Verde": 295561.24,
    Vanuatu: 279331.09,
    Bhutan: 143165.38,
    Pakistan: 122640.97,
    "Central African Republic": 187826.81,
    Kenya: 128292.8,
    Seychelles: 178040.07,
    Bangladesh: 78481.7,
    "South Sudan": 3944.83,
    Thailand: 189526,
    Maldives: 150497.68,
    India: 87703.9,
    Haiti: 60748.62,
    Qatar: 361016.62,
    Mexico: 137413.65,
    Philippines: 123229.3,
    Zimbabwe: 1421.55,
    Nepal: 72419.28,
    Suriname: 33045.29,
    Ukraine: 32383,
    Israel: 131847.26,
    "United Arab Emirates": 165409.65,
    Moldova: 41631.34,
    "Kyrgyz Republic": 62738.21,
    Turkey: 23783.42,
    Croatia: 78004.93,
    Luxembourg: 86584.96,
    "South Africa": 71973.18,
    "Trinidad and Tobago": 129776.84,
    Botswana: 58455.51,
    China: 43616.03,
    Ireland: 38745.24,
    Singapore: 71283.34,
    Egypt: 21990.54,
    Australia: 67299.6,
    "Saudi Arabia": 93348.69,
    Mauritania: 61397.21,
    Nicaragua: 44264.41,
    Switzerland: 82236.31,
    Poland: 43263.64,
    "United States": 53245.52,
    Romania: 31716.52,
    Honduras: 42795.76,
    Namibia: 53469.29,
    "New Zealand": 51295.51,
    Eswatini: 41747.72,
    Canada: 54297.11,
    Malaysia: 34192.6,
    "The Gambia": 25188.84,
    "St. Kitts and Nevis": 44452.9,
    Aruba: 45898.79,
    Netherlands: 39361.13,
    "São Tomé and Príncipe": 29887.49,
    Austria: 38209.44,
    Brazil: 26657.56,
    Finland: 37651.24,
    Mozambique: 19991.47,
    Germany: 34860.38,
    Turkmenistan: 28213.67,
    Belgium: 35273.92,
    "Antigua and Barbuda": 36159,
    "San Marino": 39142.44,
    Guatemala: 26698.38,
    France: 33237.99,
    "Brunei Darussalam": 56133.85,
    Barbados: 33112.21,
    "United Kingdom": 27771.75,
    Morocco: 26901.64,
    "Puerto Rico": 28513.15,
    Andorra: 34367.26,
    "St. Lucia": 26341.56,
    "The Bahamas": 29462.76,
    Malta: 18802.78,
    Italy: 26755.51,
    Grenada: 21031.97,
    Zambia: 9925.93,
    Peru: 17919.52,
    Slovenia: 17706.45,
    Spain: 21899.06,
    Estonia: 14348.13,
    Cyprus: 20782.37,
    Dominica: 19314.36,
    Bolivia: 20157.57,
    Belarus: 7103.25,
    "St. Vincent and the Grenadines": 17738.43,
    Bulgaria: 11310.86,
    Portugal: 16303.66,
    "Solomon Islands": 16420.26,
    Lithuania: 11846.95,
    Georgia: 7689.61,
    "Slovak Republic": 13759.19,
    Ghana: 4701.01,
    Latvia: 11326.92,
    Lesotho: 12075.4,
    Libya: 10532.16,
    Greece: 16323.36,
    Panama: 11841.89,
    Palau: 12865.53,
    Fiji: 9701.2,
    Tonga: 7604.52,
    "Bosnia and Herzegovina": 7550.28,
    Tajikistan: 4955.42,
    Tunisia: 6822.26,
    Nauru: 8875.93,
    "Papua New Guinea": 6285.02,
    Samoa: 9225.55,
    "West Bank and Gaza": 11276.34,
    Eritrea: 9175.1,
    Bahrain: 9762.96,
    Tuvalu: 3733.25,
    Belize: 9032.23,
    Montenegro: 5412.88,
    Azerbaijan: 6218.35,
    Kuwait: 12691.03,
    Oman: 7857.58,
    Ecuador: 6030.5,
    Kosovo: 2925.69,
    "Marshall Islands": 3451.21,
    "El Salvador": 3509.53,
    Micronesia: 3068.37,
    Jordan: 2907.56,
    Kiribati: 1787.83,
    "Timor-Leste": 1210.04,
    Liberia: 772.24
  },
  "2014": {
    Afghanistan: 42862.46,
    Ethiopia: 12007.52,
    Lebanon: 11589277.57,
    Syria: "n/a",
    Somalia: 312.73,
    Venezuela: 1,
    "Islamic Republic of Iran": 146775264.94,
    Vietnam: 54415204.83,
    Indonesia: 41915863.36,
    Korea: 30798658.1,
    Paraguay: 27064410.68,
    Uzbekistan: 6127003.6,
    "Lao P.D.R.": 16084503.85,
    Colombia: 16633297.73,
    Mongolia: 7419036.27,
    Guinea: 5112359.42,
    Chile: 8352248.34,
    Iceland: 6406342.6,
    "Sierra Leone": 3233431.01,
    Cambodia: 4534932.23,
    "Costa Rica": 5842596.57,
    Iraq: 7817229.15,
    Hungary: 3314992.2,
    Kazakhstan: 2278164.53,
    Uganda: 2620632.06,
    Gabon: 4811307.04,
    Japan: 4081286.51,
    "Equatorial Guinea": 9515338.79,
    Guyana: 1115383.51,
    Tanzania: 1702776.29,
    Armenia: 1617513.75,
    Argentina: 107315.21,
    Madagascar: 1281934.86,
    Myanmar: 1214323.16,
    Angola: 553023.4,
    "Democratic Republic of the Congo": 450388.22,
    "CÙte d'Ivoire": 1044390.25,
    "Republic of Congo": 2190850.2,
    Nigeria: 510966.16,
    Yemen: 338289.65,
    Rwanda: 510818.18,
    Serbia: 583784.4,
    "Sri Lanka": 498659.69,
    Senegal: 689609.76,
    Jamaica: 567782.68,
    "Taiwan Province of China": 693787.59,
    Cameroon: 761686.84,
    Russia: 540313.26,
    Benin: 637643.19,
    Uruguay: 418504.21,
    Djibouti: 438257.39,
    Sudan: 11818.38,
    Norway: 609103.82,
    Albania: 482953.89,
    "Macao SAR": 689273.81,
    "Dominican Republic": 296015.51,
    "Czech Republic": 413393.53,
    Togo: 426988.25,
    Burundi: 422446.55,
    Malawi: 205533.66,
    Mali: 431189.14,
    "Burkina Faso": 391473.62,
    Algeria: 440471.44,
    Comoros: 557250.96,
    "Guinea-Bissau": 351872.2,
    Sweden: 409621.89,
    Mauritius: 310862.28,
    Denmark: 352067.22,
    "Hong Kong SAR": 311600.19,
    Niger: 277858.43,
    "North Macedonia": 254996.2,
    Chad: 505890.67,
    "Cabo Verde": 293360.35,
    Vanuatu: 285535.82,
    Bhutan: 156558.23,
    Pakistan: 135178.07,
    "Central African Republic": 209582.78,
    Kenya: 141599.88,
    Seychelles: 187383.69,
    Bangladesh: 86957.87,
    "South Sudan": 3935.56,
    Thailand: 193315.99,
    Maldives: 166234.93,
    India: 96232.79,
    Haiti: 62869.22,
    Qatar: 338717.07,
    Mexico: 145779.8,
    Philippines: 132985.88,
    Zimbabwe: 1415.03,
    Nepal: 82975.05,
    Suriname: 33140.84,
    Ukraine: 37112.4,
    Israel: 135476.01,
    "United Arab Emirates": 168263.18,
    Moldova: 46521.83,
    "Kyrgyz Republic": 69365.39,
    Turkey: 26447.92,
    Croatia: 78179.57,
    Luxembourg: 90642.56,
    "South Africa": 75748.03,
    "Trinidad and Tobago": 129918.43,
    Botswana: 67277.86,
    China: 46971.79,
    Ireland: 41764.92,
    Singapore: 72937.48,
    Egypt: 24539.17,
    Australia: 68293.33,
    "Saudi Arabia": 93484.94,
    Mauritania: 55109.47,
    Nicaragua: 49757.25,
    Switzerland: 82690.3,
    Poland: 45011.59,
    "United States": 55083.51,
    Romania: 33563.9,
    Honduras: 46298.85,
    Namibia: 60251.28,
    "New Zealand": 53298.01,
    Eswatini: 44820.59,
    Canada: 56365.13,
    Malaysia: 36542.33,
    "The Gambia": 25349.81,
    "St. Kitts and Nevis": 47934.81,
    Aruba: 46331.67,
    Netherlands: 39904.24,
    "São Tomé and Príncipe": 33736.64,
    Austria: 38992.13,
    Brazil: 28648.67,
    Finland: 37953.91,
    Mozambique: 21130.75,
    Germany: 36148.59,
    Turkmenistan: 30186.24,
    Belgium: 36044.16,
    "Antigua and Barbuda": 37670.37,
    "San Marino": 38555.99,
    Guatemala: 28092.67,
    France: 33575.41,
    "Brunei Darussalam": 53149.15,
    Barbados: 33144.62,
    "United Kingdom": 28840.47,
    Morocco: 27402.31,
    "Puerto Rico": 28981.34,
    Andorra: 34897.12,
    "St. Lucia": 27466.58,
    "The Bahamas": 30937.32,
    Malta: 20378.7,
    Italy: 26967.96,
    Grenada: 22599.08,
    Zambia: 10626.07,
    Peru: 18634.87,
    Slovenia: 18259.41,
    Spain: 22218.39,
    Estonia: 15241.81,
    Cyprus: 20314.92,
    Dominica: 19949.17,
    Bolivia: 21368.67,
    Belarus: 8532.32,
    "St. Vincent and the Grenadines": 17880.6,
    Bulgaria: 11647.13,
    Portugal: 16638.02,
    "Solomon Islands": 16778.56,
    Lithuania: 12475.01,
    Georgia: 8373.67,
    "Slovak Republic": 14082.44,
    Ghana: 5857.2,
    Latvia: 11798.29,
    Lesotho: 13833.6,
    Libya: 4932.27,
    Greece: 16230.63,
    Panama: 12756.95,
    Palau: 13993.29,
    Fiji: 10590.25,
    Tonga: 7799.83,
    "Bosnia and Herzegovina": 7841.57,
    Tajikistan: 5453.38,
    Tunisia: 7256.48,
    Nauru: 9656.12,
    "Papua New Guinea": 7366.21,
    Samoa: 9145.89,
    "West Bank and Gaza": 11301.23,
    Eritrea: 12093.05,
    Bahrain: 9549.77,
    Tuvalu: 3969.95,
    Belize: 9291.39,
    Montenegro: 5561.15,
    Azerbaijan: 6227.02,
    Kuwait: 11458.67,
    Oman: 7808.6,
    Ecuador: 6347,
    Kosovo: 3084.58,
    "Marshall Islands": 3388.38,
    "El Salvador": 3589.04,
    Micronesia: 3082.9,
    Jordan: 2969.79,
    Kiribati: 1834.35,
    "Timor-Leste": 1232.45,
    Liberia: 765.37
  },
  "2015": {
    Afghanistan: 43150.71,
    Ethiopia: 14460.31,
    Lebanon: 11553296.42,
    Syria: "n/a",
    Somalia: 310.46,
    Venezuela: 2.63,
    "Islamic Republic of Iran": 143617226.82,
    Vietnam: 56603826.6,
    Indonesia: 45097333.64,
    Korea: 32500678.67,
    Paraguay: 27898776.56,
    Uzbekistan: 7135173.04,
    "Lao P.D.R.": 17393383.94,
    Colombia: 17374762.97,
    Mongolia: 7570983.11,
    Guinea: 5332411.18,
    Chile: 8876450.39,
    Iceland: 7021719.84,
    "Sierra Leone": 3009419.99,
    Cambodia: 4886533.8,
    "Costa Rica": 6219817.69,
    Iraq: 5882031.64,
    Hungary: 3544776.08,
    Kazakhstan: 2313772.57,
    Uganda: 2687781.23,
    Gabon: 4405922.36,
    Japan: 4237225.49,
    "Equatorial Guinea": 6632203.96,
    Guyana: 1152264.71,
    Tanzania: 1887404.77,
    Armenia: 1686586.15,
    Argentina: 138053.32,
    Madagascar: 1370638.92,
    Myanmar: 1347441.56,
    Angola: 522854.87,
    "Democratic Republic of the Congo": 460508.12,
    "CÙte d'Ivoire": 1142352.07,
    "Republic of Congo": 1698804.94,
    Nigeria: 525446.13,
    Yemen: 346404.96,
    Rwanda: 543982.3,
    Serbia: 608144.83,
    "Sri Lanka": 522204.15,
    Senegal: 720834.52,
    Jamaica: 610294.29,
    "Taiwan Province of China": 725992.95,
    Cameroon: 784835.05,
    Russia: 566975.33,
    Benin: 636614.81,
    Uruguay: 456164.16,
    Djibouti: 475505.82,
    Sudan: 13174.51,
    Norway: 597676.97,
    Albania: 497901.38,
    "Macao SAR": 556286.33,
    "Dominican Republic": 321200.11,
    "Czech Republic": 438912.25,
    Togo: 455398.47,
    Burundi: 478234.15,
    Malawi: 248671.39,
    Mali: 457519.34,
    "Burkina Faso": 386254.73,
    Algeria: 418203.99,
    Comoros: 545841.01,
    "Guinea-Bissau": 418474.52,
    Sweden: 432490.37,
    Mauritius: 324574.66,
    Denmark: 359798.33,
    "Hong Kong SAR": 328095.54,
    Niger: 286219.85,
    "North Macedonia": 269859.48,
    Chad: 458796.19,
    "Cabo Verde": 297795.54,
    Vanuatu: 296565.87,
    Bhutan: 171718.99,
    Pakistan: 144535.85,
    "Central African Republic": 223145.37,
    Kenya: 158991.16,
    Seychelles: 196897.61,
    Bangladesh: 97007.44,
    "South Sudan": 4450.06,
    Thailand: 200008.39,
    Maldives: 181545.66,
    India: 105116.55,
    Haiti: 66006.91,
    Qatar: 241502.76,
    Mexico: 153048.59,
    Philippines: 138293.75,
    Zimbabwe: 1425.01,
    Nepal: 89714.44,
    Suriname: 30874.19,
    Ukraine: 46689.41,
    Israel: 139237.47,
    "United Arab Emirates": 147136.69,
    Moldova: 51237.4,
    "Kyrgyz Republic": 73025.42,
    Turkey: 29856.64,
    Croatia: 80794.96,
    Luxembourg: 92485.94,
    "South Africa": 79787.62,
    "Trinidad and Tobago": 116161.05,
    Botswana: 64855.07,
    China: 50033.52,
    Ireland: 55749.64,
    Singapore: 76502.97,
    Egypt: 27459.55,
    Australia: 68407.91,
    "Saudi Arabia": 78987.43,
    Mauritania: 54026.86,
    Nicaragua: 55520.32,
    Switzerland: 82031.96,
    Poland: 47390.69,
    "United States": 56729.68,
    Romania: 35819.47,
    Honduras: 50522.27,
    Namibia: 64023.16,
    "New Zealand": 54550.28,
    Eswatini: 48024.53,
    Canada: 55788.93,
    Malaysia: 37739.28,
    "The Gambia": 28084.85,
    "St. Kitts and Nevis": 47666.14,
    Aruba: 48556.65,
    Netherlands: 40827.12,
    "São Tomé and Príncipe": 35870.91,
    Austria: 39894.38,
    Brazil: 29466.81,
    Finland: 38632.04,
    Mozambique: 23584.05,
    Germany: 37046.04,
    Turkmenistan: 26515.59,
    Belgium: 37082.13,
    "Antigua and Barbuda": 39681.61,
    "San Marino": 38813.73,
    Guatemala: 29289.34,
    France: 34189.8,
    "Brunei Darussalam": 43108.65,
    Barbados: 33178.9,
    "United Kingdom": 29483.04,
    Morocco: 28950.92,
    "Puerto Rico": 29755.35,
    Andorra: 35054.23,
    "St. Lucia": 28044.4,
    "The Bahamas": 32648.64,
    Malta: 22735.76,
    Italy: 27454.04,
    Grenada: 24560.64,
    Zambia: 11311.44,
    Peru: 19554.22,
    Slovenia: 18834.16,
    Spain: 23218.84,
    Estonia: 15684.4,
    Cyprus: 21114.09,
    Dominica: 20509.8,
    Bolivia: 21055.53,
    Belarus: 9511.25,
    "St. Vincent and the Grenadines": 18542.31,
    Bulgaria: 12491.62,
    Portugal: 17350.02,
    "Solomon Islands": 17152.81,
    Lithuania: 12856.06,
    Georgia: 9117.66,
    "Slovak Republic": 14713.61,
    Ghana: 6623.73,
    Latvia: 12366.41,
    Lesotho: 15257.1,
    Libya: 3761.48,
    Greece: 16219.35,
    Panama: 13606.6,
    Palau: 16408.16,
    Fiji: 11296.82,
    Tonga: 8351.94,
    "Bosnia and Herzegovina": 8336.49,
    Tajikistan: 5661.9,
    Tunisia: 7512.13,
    Nauru: 8362.13,
    "Papua New Guinea": 7593.53,
    Samoa: 9875.13,
    "West Bank and Gaza": 11987.51,
    Eritrea: 9259.02,
    Bahrain: 8519.93,
    Tuvalu: 4513.2,
    Belize: 9349.2,
    Montenegro: 5873.53,
    Azerbaijan: 5668.72,
    Kuwait: 8241.29,
    Oman: 6371.45,
    Ecuador: 6099.35,
    Kosovo: 3278.1,
    "Marshall Islands": 3406.48,
    "El Salvador": 3705.58,
    Micronesia: 3050.72,
    Jordan: 2991.14,
    Kiribati: 2068.13,
    "Timor-Leste": 1332.79,
    Liberia: 747.18
  },
  "2016": {
    Afghanistan: 41880.72,
    Ethiopia: 17194.72,
    Lebanon: 11537944.27,
    Syria: "n/a",
    Somalia: 312.86,
    Venezuela: 9.15,
    "Islamic Republic of Iran": 163450543.89,
    Vietnam: 60838178.11,
    Indonesia: 47976388.46,
    Korea: 33987783.51,
    Paraguay: 29855745.22,
    Uzbekistan: 8089293.27,
    "Lao P.D.R.": 18884310.53,
    Colombia: 18445010.9,
    Mongolia: 7674155.52,
    Guinea: 6092110.25,
    Chile: 9327815.36,
    Iceland: 7554396.76,
    "Sierra Leone": 3315162.12,
    Cambodia: 5351718.96,
    "Costa Rica": 6529710.51,
    Iraq: 5472437.29,
    Hungary: 3679293.29,
    Kazakhstan: 2621421.24,
    Uganda: 2859026.22,
    Gabon: 4198120.43,
    Japan: 4287683.07,
    "Equatorial Guinea": 5453476.03,
    Guyana: 1197043.07,
    Tanzania: 2103711.94,
    Armenia: 1693240.34,
    Argentina: 188760.96,
    Madagascar: 1512751.13,
    Myanmar: 1437383.18,
    Angola: 601736.74,
    "Democratic Republic of the Congo": 476174.73,
    "CÙte d'Ivoire": 1168392.07,
    "Republic of Congo": 1419787.82,
    Nigeria: 551599.37,
    Yemen: 305195.16,
    Rwanda: 594956.52,
    Serbia: 641539.46,
    "Sri Lanka": 565772.96,
    Senegal: 752551.8,
    Jamaica: 647021.46,
    "Taiwan Province of China": 745769.13,
    Cameroon: 808509.33,
    Russia: 583200.05,
    Benin: 644332.56,
    Uruguay: 496062.07,
    Djibouti: 500878.29,
    Sudan: 18785.88,
    Norway: 589880.06,
    Albania: 511970.55,
    "Macao SAR": 558939.37,
    "Dominican Republic": 346131.71,
    "Czech Republic": 454514.34,
    Togo: 475955.11,
    Burundi: 465048.82,
    Malawi: 297983.51,
    Mali: 476634.82,
    "Burkina Faso": 407861.22,
    Algeria: 428900.97,
    Comoros: 558403.93,
    "Guinea-Bissau": 443444.06,
    Sweden: 441717.2,
    Mauritius: 344095.77,
    Denmark: 369321.24,
    "Hong Kong SAR": 337590.38,
    Niger: 295130.6,
    "North Macedonia": 286827.13,
    Chad: 415268.91,
    "Cabo Verde": 307254.39,
    Vanuatu: 310898.15,
    Bhutan: 189581.85,
    Pakistan: 150215.09,
    "Central African Republic": 238324.47,
    Kenya: 171423.56,
    Seychelles: 202451.9,
    Bangladesh: 109695.16,
    "South Sudan": 13439.42,
    Thailand: 211542.11,
    Maldives: 190293.65,
    India: 116206.56,
    Haiti: 76230.05,
    Qatar: 210993.97,
    Mexico: 164030.72,
    Philippines: 147589.8,
    Zimbabwe: 1444.26,
    Nepal: 95666.04,
    Suriname: 35888.02,
    Ukraine: 56238.89,
    Israel: 143412.9,
    "United Arab Emirates": 143758.8,
    Moldova: 56937.86,
    "Kyrgyz Republic": 79131.62,
    Turkey: 32908.1,
    Croatia: 84139.67,
    Luxembourg: 95214.4,
    "South Africa": 84611.3,
    "Trinidad and Tobago": 108375.3,
    Botswana: 76122.19,
    China: 53578.24,
    Ireland: 56599.44,
    Singapore: 78535.75,
    Egypt: 29773.63,
    Australia: 69788.97,
    "Saudi Arabia": 76083.36,
    Mauritania: 59436.51,
    Nicaragua: 60092.47,
    Switzerland: 82295.75,
    Poland: 49081.48,
    "United States": 57839.99,
    Romania: 38645.21,
    Honduras: 53480.16,
    Namibia: 67849.12,
    "New Zealand": 56569.28,
    Eswatini: 51698.49,
    Canada: 56183.06,
    Malaysia: 39505.49,
    "The Gambia": 29960.81,
    "St. Kitts and Nevis": 49672.04,
    Aruba: 48396.1,
    Netherlands: 41718.12,
    "São Tomé and Príncipe": 38488.67,
    Austria: 40917.16,
    Brazil: 30558.69,
    Finland: 39640.2,
    Mozambique: 27046.49,
    Germany: 38066.64,
    Turkmenistan: 23882.99,
    Belgium: 38023.21,
    "Antigua and Barbuda": 42001.23,
    "San Marino": 40040.45,
    Guatemala: 30271.92,
    France: 34654.43,
    "Brunei Darussalam": 37741.16,
    Barbados: 33895.26,
    "United Kingdom": 30384.96,
    Morocco: 29380.03,
    "Puerto Rico": 30585.55,
    Andorra: 35795.91,
    "St. Lucia": 28648.1,
    "The Bahamas": 32565.04,
    Malta: 23459.58,
    Italy: 28186.21,
    Grenada: 25996.3,
    Zambia: 12926.85,
    Peru: 20825.3,
    Slovenia: 19592.74,
    Spain: 23979.4,
    Estonia: 16528.4,
    Cyprus: 22313.89,
    Dominica: 21840.98,
    Bolivia: 21340.6,
    Belarus: 10027.35,
    "St. Vincent and the Grenadines": 18990.42,
    Bulgaria: 13395.17,
    Portugal: 18061.09,
    "Solomon Islands": 17688.55,
    Lithuania: 13558.85,
    Georgia: 9611.12,
    "Slovak Republic": 14936.92,
    Ghana: 7753.07,
    Latvia: 12880.06,
    Lesotho: 15542.64,
    Libya: 4038.78,
    Greece: 16157.37,
    Panama: 14344.09,
    Palau: 16776.84,
    Fiji: 11773.4,
    Tonga: 9265.58,
    "Bosnia and Herzegovina": 8830.2,
    Tajikistan: 6235.99,
    Tunisia: 7874,
    Nauru: 10536.72,
    "Papua New Guinea": 8044.31,
    Samoa: 10739.98,
    "West Bank and Gaza": 12773.13,
    Eritrea: 9880.51,
    Bahrain: 8513.12,
    Tuvalu: 5105.97,
    Belize: 9468.02,
    Montenegro: 6354.19,
    Azerbaijan: 6225.81,
    Kuwait: 7635.89,
    Oman: 5703.9,
    Ecuador: 6046.3,
    Kosovo: 3403.42,
    "Marshall Islands": 3720.62,
    "El Salvador": 3805.99,
    Micronesia: 3197.21,
    Jordan: 2995.38,
    Kiribati: 2149.36,
    "Timor-Leste": 1353.75,
    Liberia: 767.37
  },
  "2017": {
    Afghanistan: 43281.49,
    Ethiopia: 19780.63,
    Lebanon: 11788033.89,
    Syria: "n/a",
    Somalia: 326.52,
    Venezuela: 65.99,
    "Islamic Republic of Iran": 188111304.99,
    Vietnam: 67191171.65,
    Indonesia: 51997473.56,
    Korea: 35740459.5,
    Paraguay: 31511853.82,
    Uzbekistan: 9883917.41,
    "Lao P.D.R.": 20235455.53,
    Colombia: 19411377.67,
    Mongolia: 8771926.74,
    Guinea: 7234647.07,
    Chile: 9755352.72,
    Iceland: 7808384.24,
    "Sierra Leone": 3667714.84,
    Cambodia: 5857677.9,
    "Costa Rica": 6915180.15,
    Iraq: 6121484.05,
    Hungary: 4004228.41,
    Kazakhstan: 2994875.89,
    Uganda: 3028099.1,
    Gabon: 4281153.51,
    Japan: 4363632.78,
    "Equatorial Guinea": 5588549.72,
    Guyana: 1260508.65,
    Tanzania: 2237320.6,
    Armenia: 1867598.1,
    Argentina: 242031.43,
    Madagascar: 1607710.46,
    Myanmar: 1588866.48,
    Angola: 713454.65,
    "Democratic Republic of the Congo": 683991.87,
    "CÙte d'Ivoire": 1200127.65,
    "Republic of Congo": 1484359.01,
    Nigeria: 601967.02,
    Yemen: 333789.11,
    Rwanda: 652033.9,
    Serbia: 678077.59,
    "Sri Lanka": 621530.64,
    Senegal: 790681.37,
    Jamaica: 695197,
    "Taiwan Province of China": 762936.4,
    Cameroon: 827497.56,
    Russia: 625294.12,
    Benin: 659970.96,
    Uruguay: 527309.17,
    Djibouti: 520842.36,
    Sudan: 21257.96,
    Norway: 622911.65,
    Albania: 537640.48,
    "Macao SAR": 620067.37,
    "Dominican Republic": 373939.57,
    "Czech Republic": 483110.88,
    Togo: 482377.81,
    Burundi: 504733.12,
    Malawi: 340722.81,
    Mali: 496622.1,
    "Burkina Faso": 426780.51,
    Algeria: 452438.82,
    Comoros: 566586.61,
    "Guinea-Bissau": 501946.49,
    Sweden: 457014.17,
    Mauritius: 361538.04,
    Denmark: 381466.02,
    "Hong Kong SAR": 358741.15,
    Niger: 300754.53,
    "North Macedonia": 297839.21,
    Chad: 389862.98,
    "Cabo Verde": 316803.08,
    Vanuatu: 340431.1,
    Bhutan: 208447.05,
    Pakistan: 161828.57,
    "Central African Republic": 261819.84,
    Kenya: 186858.95,
    Seychelles: 220825.38,
    Bangladesh: 123743.22,
    "South Sudan": 31555.41,
    Thailand: 223792.88,
    Maldives: 203434.27,
    India: 127665.39,
    Haiti: 87766.92,
    Qatar: 215224.15,
    Mexico: 176828.94,
    Philippines: 158938.76,
    Zimbabwe: 1526.71,
    Nepal: 111358.89,
    Suriname: 46097.49,
    Ukraine: 70657.18,
    Israel: 146829.23,
    "United Arab Emirates": 152202.69,
    Moldova: 64346.76,
    "Kyrgyz Republic": 86393.93,
    Turkey: 38778.19,
    Croatia: 89091.15,
    Luxembourg: 96186.68,
    "South Africa": 88938.14,
    "Trinidad and Tobago": 109649.73,
    Botswana: 75495.58,
    China: 59208.4,
    Ireland: 61531.59,
    Singapore: 84478.57,
    Egypt: 36449.58,
    Australia: 72922.48,
    "Saudi Arabia": 79177.36,
    Mauritania: 62709.67,
    Nicaragua: 64793.63,
    Switzerland: 82419.16,
    Poland: 52401.35,
    "United States": 59885.71,
    Romania: 43672.37,
    Honduras: 57588.83,
    Namibia: 72430.7,
    "New Zealand": 59555.14,
    Eswatini: 53683.63,
    Canada: 58656.27,
    Malaysia: 42854.42,
    "The Gambia": 31682.72,
    "St. Kitts and Nevis": 51689.07,
    Aruba: 49936.38,
    Netherlands: 43213.17,
    "São Tomé and Príncipe": 39969.61,
    Austria: 41900.86,
    Brazil: 31843.9,
    Finland: 41120.99,
    Mozambique: 29338.75,
    Germany: 39526.6,
    Turkmenistan: 25369.3,
    Belgium: 39205.49,
    "Antigua and Barbuda": 42269.11,
    "San Marino": 40634.37,
    Guatemala: 31116.61,
    France: 35539.49,
    "Brunei Darussalam": 38992.87,
    Barbados: 34846.5,
    "United Kingdom": 31325.82,
    Morocco: 30501.69,
    "Puerto Rico": 31347.27,
    Andorra: 35507.66,
    "St. Lucia": 30407.5,
    "The Bahamas": 33191.21,
    Malta: 25981.93,
    Italy: 28911.06,
    Grenada: 27412.65,
    Zambia: 14285.4,
    Peru: 21939.41,
    Slovenia: 20819.69,
    Spain: 24968.74,
    Estonia: 18091.64,
    Cyprus: 23537.5,
    Dominica: 20974.56,
    Bolivia: 23245.27,
    Belarus: 11166.65,
    "St. Vincent and the Grenadines": 19406.24,
    Bulgaria: 14516.96,
    Portugal: 19023.44,
    "Solomon Islands": 18400.27,
    Lithuania: 14947.06,
    Georgia: 10938.62,
    "Slovak Republic": 15544.28,
    Ghana: 9080.9,
    Latvia: 13825.98,
    Lesotho: 15156.16,
    Libya: 6490.77,
    Greece: 16451.41,
    Panama: 15178.3,
    Palau: 16028.19,
    Fiji: 12504.37,
    Tonga: 10117.58,
    "Bosnia and Herzegovina": 9357.66,
    Tajikistan: 6847.96,
    Tunisia: 8312.89,
    Nauru: 10807.93,
    "Papua New Guinea": 8786.67,
    Samoa: 10800.73,
    "West Bank and Gaza": 12264.79,
    Eritrea: 8409.7,
    Bahrain: 8885.48,
    Tuvalu: 5549.56,
    Belize: 9583.04,
    Montenegro: 6907.28,
    Azerbaijan: 7170.01,
    Kuwait: 8249.32,
    Oman: 5952.88,
    Ecuador: 6216.61,
    Kosovo: 3566.24,
    "Marshall Islands": 3898.58,
    "El Salvador": 3910.26,
    Micronesia: 3522.11,
    Jordan: 3030.19,
    Kiribati: 2160.52,
    "Timor-Leste": 1286.41,
    Liberia: 763.11
  },
  "2018": {
    Afghanistan: 42015.52,
    Ethiopia: 23371.2,
    Lebanon: 12148132.9,
    Syria: "n/a",
    Somalia: 327.32,
    Venezuela: 36578.19,
    "Islamic Republic of Iran": 232261534.99,
    Vietnam: 73704330.3,
    Indonesia: 56173024.39,
    Korea: 36781950.1,
    Paraguay: 32692049.7,
    Uzbekistan: 13005868.43,
    "Lao P.D.R.": 21583805.59,
    Colombia: 20468748.98,
    Mongolia: 10008162.51,
    Guinea: 8036911.39,
    Chile: 10173311.74,
    Iceland: 8163948.92,
    "Sierra Leone": 4235421.42,
    Cambodia: 6426142.42,
    "Costa Rica": 7170945.55,
    Iraq: 7033875.55,
    Hungary: 4433458.07,
    Kazakhstan: 3360561.22,
    Uganda: 3274004.39,
    Gabon: 4563979.98,
    Japan: 4396917.94,
    "Equatorial Guinea": 5536739.67,
    Guyana: 1271337.42,
    Tanzania: 2359470.92,
    Armenia: 2026599.62,
    Argentina: 331385,
    Madagascar: 1729033.27,
    Myanmar: 1769114.9,
    Angola: 876160.79,
    "Democratic Republic of the Congo": 909923.12,
    "CÙte d'Ivoire": 1258249.28,
    "Republic of Congo": 1700518.83,
    Nigeria: 659026.97,
    Yemen: 375733.15,
    Rwanda: 686115.7,
    Serbia: 726510.1,
    "Sri Lanka": 659478.91,
    Senegal: 809879.37,
    Jamaica: 742315.77,
    "Taiwan Province of China": 778967.95,
    Cameroon: 852328.35,
    Russia: 707596.01,
    Benin: 688906.65,
    Uruguay: 565389.49,
    Djibouti: 558374.81,
    Sudan: 32438.79,
    Norway: 667532.82,
    Albania: 567417.75,
    "Macao SAR": 668907.7,
    "Dominican Republic": 412603.28,
    "Czech Republic": 509862.11,
    Togo: 500747.8,
    Burundi: 483726.32,
    Malawi: 366919.37,
    Mali: 512194.2,
    "Burkina Faso": 451633.38,
    Algeria: 480349.01,
    Comoros: 577130.51,
    "Guinea-Bissau": 481048.75,
    Sweden: 471966.64,
    Mauritius: 380266.08,
    Denmark: 389766.64,
    "Hong Kong SAR": 378702.58,
    Niger: 317885.78,
    "North Macedonia": 318168.03,
    Chad: 395872.73,
    "Cabo Verde": 337824.75,
    Vanuatu: 353413.81,
    Bhutan: 222400.16,
    Pakistan: 172254.69,
    "Central African Republic": 271247.41,
    Kenya: 200866.81,
    Seychelles: 225556.89,
    Bangladesh: 139473,
    "South Sudan": 50889.07,
    Thailand: 235763.64,
    Maldives: 223084.24,
    India: 139632.69,
    Haiti: 94326.79,
    Qatar: 241774.96,
    Mexico: 187702.89,
    Philippines: 172704.15,
    Zimbabwe: 1606.11,
    Nepal: 123006.29,
    Suriname: 50536.65,
    Ukraine: 84802.21,
    Israel: 151088.3,
    "United Arab Emirates": 165540.03,
    Moldova: 70506.55,
    "Kyrgyz Republic": 91003.7,
    Turkey: 45836.47,
    Croatia: 94269.33,
    Luxembourg: 99755.15,
    "South Africa": 92470.36,
    "Trinidad and Tobago": 115359.74,
    Botswana: 76638.01,
    China: 65160.65,
    Ireland: 66727.38,
    Singapore: 89936.7,
    Egypt: 45699.28,
    Australia: 75468.32,
    "Saudi Arabia": 88271.02,
    Mauritania: 66078.47,
    Nicaragua: 63616.33,
    Switzerland: 84843.13,
    Poland: 55864.67,
    "United States": 62769.66,
    Romania: 48722.89,
    Honduras: 59915.06,
    Namibia: 75012.69,
    "New Zealand": 61822.28,
    Eswatini: 55948.61,
    Canada: 60297.21,
    Malaysia: 44708.38,
    "The Gambia": 35281.67,
    "St. Kitts and Nevis": 52028.3,
    Aruba: 51423.01,
    Netherlands: 45048.79,
    "São Tomé and Príncipe": 41239.19,
    Austria: 43374.17,
    Brazil: 33593.81,
    Finland: 42347.63,
    Mozambique: 30362.31,
    Germany: 40622.76,
    Turkmenistan: 24727.09,
    Belgium: 40388.33,
    "Antigua and Barbuda": 45524.58,
    "San Marino": 41877.02,
    Guatemala: 31884.12,
    France: 36446,
    "Brunei Darussalam": 41366.77,
    Barbados: 35516.17,
    "United Kingdom": 32238.43,
    Morocco: 31472.54,
    "Puerto Rico": 32789.06,
    Andorra: 35775.5,
    "St. Lucia": 31203.02,
    "The Bahamas": 34094.37,
    Malta: 27285.26,
    Italy: 29556.76,
    Grenada: 28260.37,
    Zambia: 15482.73,
    Peru: 23033.79,
    Slovenia: 22190.06,
    Spain: 25749.83,
    Estonia: 19529.61,
    Cyprus: 24799.48,
    Dominica: 21921.18,
    Bolivia: 24614.29,
    Belarus: 12946.62,
    "St. Vincent and the Grenadines": 19854.82,
    Bulgaria: 15677.54,
    Portugal: 19952.17,
    "Solomon Islands": 19180.56,
    Lithuania: 16237.84,
    Georgia: 11958.21,
    "Slovak Republic": 16416.43,
    Ghana: 10441.28,
    Latvia: 15065.58,
    Lesotho: 15687.4,
    Libya: 8813.43,
    Greece: 16732.54,
    Panama: 15612.33,
    Palau: 16328.65,
    Fiji: 13093.58,
    Tonga: 10683.27,
    "Bosnia and Herzegovina": 10064.42,
    Tajikistan: 7559.29,
    Tunisia: 9112.36,
    Nauru: 12120.48,
    "Papua New Guinea": 9424.07,
    Samoa: 10749.56,
    "West Bank and Gaza": 12039.95,
    Eritrea: 8756.24,
    Bahrain: 9418.67,
    Tuvalu: 6025.16,
    Belize: 9626.43,
    Montenegro: 7494.58,
    Azerbaijan: 8091.65,
    Kuwait: 9029.58,
    Oman: 6666.83,
    Ecuador: 6318.48,
    Kosovo: 3746,
    "Marshall Islands": 4061.68,
    "El Salvador": 4052.62,
    Micronesia: 3854.14,
    Jordan: 3077.79,
    Kiribati: 2337.32,
    "Timor-Leste": 1230.23,
    Liberia: 731.32
  },
  "2019": {
    Afghanistan: 45639.64,
    Ethiopia: 28132.89,
    Lebanon: 11776462.51,
    Syria: "n/a",
    Somalia: 346.16,
    Venezuela: 4942361.5,
    "Islamic Republic of Iran": 293191245.49,
    Vietnam: 78930885.18,
    Indonesia: 59317457.93,
    Korea: 37217783.22,
    Paraguay: 33073750.23,
    Uzbekistan: 15918912.66,
    "Lao P.D.R.": 22747383.09,
    Colombia: 21482021.16,
    Mongolia: 11307963.81,
    Guinea: 9107804.87,
    Chile: 10281164.61,
    Iceland: 8537265.65,
    "Sierra Leone": 4753199.29,
    Cambodia: 7052182.58,
    "Costa Rica": 7414164.22,
    Iraq: 7069662.38,
    Hungary: 4862782.26,
    Kazakhstan: 3731953.07,
    Uganda: 3508290.41,
    Gabon: 4752321.68,
    Japan: 4435580.52,
    "Equatorial Guinea": 4918380.47,
    Guyana: 1374927.11,
    Tanzania: 2483800.02,
    Armenia: 2208539.41,
    Argentina: 485155.34,
    Madagascar: 1853217.8,
    Myanmar: 1992320.39,
    Angola: 1023431.73,
    "Democratic Republic of the Congo": 956882.09,
    "CÙte d'Ivoire": 1305399.24,
    "Republic of Congo": 1640651.49,
    Nigeria: 724702.64,
    Yemen: 398322.1,
    Rwanda: 751209.68,
    Serbia: 777988.04,
    "Sri Lanka": 688572.81,
    Senegal: 837939.6,
    Jamaica: 771895.39,
    "Taiwan Province of China": 802119.56,
    Cameroon: 883231.42,
    Russia: 744410.8,
    Benin: 713772.44,
    Uruguay: 613527.3,
    Djibouti: 610854.66,
    Sudan: 46961.44,
    Norway: 666161.95,
    Albania: 582887.09,
    "Macao SAR": 654972.04,
    "Dominican Republic": 440463.72,
    "Czech Republic": 543704.86,
    Togo: 523290.69,
    Burundi: 482222.89,
    Malawi: 405143.41,
    Mali: 530707.9,
    "Burkina Faso": 461050.69,
    Algeria: 470438.01,
    Comoros: 597493.95,
    "Guinea-Bissau": 474894.53,
    Sweden: 488944.61,
    Mauritius: 393358.1,
    Denmark: 399243.83,
    "Hong Kong SAR": 378225.72,
    Niger: 324526.85,
    "North Macedonia": 332052.18,
    Chad: 401728.68,
    "Cabo Verde": 354752.59,
    Vanuatu: 363704.21,
    Bhutan: 234083.49,
    Pakistan: 186032.06,
    "Central African Republic": 281134.91,
    Kenya: 215454.92,
    Seychelles: 230188.2,
    Bangladesh: 155936.45,
    "South Sudan": 54211.9,
    Thailand: 242699.39,
    Maldives: 233443.07,
    India: 148807.99,
    Haiti: 107448.41,
    Qatar: 228654.1,
    Mexico: 193192.56,
    Philippines: 181899.94,
    Zimbabwe: 10710.24,
    Nepal: 135564.72,
    Suriname: 52646.35,
    Ukraine: 95301.49,
    Israel: 156716.8,
    "United Arab Emirates": 161223.3,
    Moldova: 78448.52,
    "Kyrgyz Republic": 96904.67,
    Turkey: 51924.56,
    Croatia: 98975.15,
    Luxembourg: 103464.77,
    "South Africa": 95364.25,
    "Trinidad and Tobago": 112372.46,
    Botswana: 77659.97,
    China: 70259.02,
    Ireland: 72019,
    Singapore: 89547.05,
    Egypt: 53812.94,
    Australia: 78336.24,
    "Saudi Arabia": 86901.9,
    Mauritania: 71326.94,
    Nicaragua: 63989.73,
    Switzerland: 85188.11,
    Poland: 60390.56,
    "United States": 65051.88,
    Romania: 54505.15,
    Honduras: 62937.11,
    Namibia: 73834.9,
    "New Zealand": 64024.39,
    Eswatini: 57950.77,
    Canada: 61562.83,
    Malaysia: 46525.78,
    "The Gambia": 38655.57,
    "St. Kitts and Nevis": 55626.7,
    Aruba: 52814.57,
    Netherlands: 47045.91,
    "São Tomé and Príncipe": 44245.52,
    Austria: 44879.15,
    Brazil: 35246.87,
    Finland: 43512.24,
    Mozambique: 31700.57,
    Germany: 41800.88,
    Turkmenistan: 27649.87,
    Belgium: 41581.97,
    "Antigua and Barbuda": 47119.2,
    "San Marino": 43201.21,
    Guatemala: 33651.31,
    France: 37508.87,
    "Brunei Darussalam": 39989.09,
    Barbados: 36897.65,
    "United Kingdom": 33211.66,
    Morocco: 32394.02,
    "Puerto Rico": 32785.94,
    Andorra: 36346.54,
    "St. Lucia": 31813.43,
    "The Bahamas": 34575.62,
    Malta: 28461.86,
    Italy: 29940.52,
    Grenada: 28642.63,
    Zambia: 16399.15,
    Peru: 23230.89,
    Slovenia: 23257.54,
    Spain: 26416.85,
    Estonia: 20900.78,
    Cyprus: 25444.83,
    Dominica: 23016.68,
    Bolivia: 24637.03,
    Belarus: 14289.12,
    "St. Vincent and the Grenadines": 20170.93,
    Bulgaria: 17229.74,
    Portugal: 20840.8,
    "Solomon Islands": 19159.5,
    Lithuania: 17468.22,
    Georgia: 13227.52,
    "Slovak Republic": 17228.12,
    Ghana: 11818.52,
    Latvia: 15844.5,
    Lesotho: 16082.02,
    Libya: 8318.69,
    Greece: 17102.09,
    Panama: 15830.98,
    Palau: 15725.08,
    Fiji: 13271.15,
    Tonga: 11601.59,
    "Bosnia and Herzegovina": 10692.44,
    Tajikistan: 8324.87,
    Tunisia: 9754.49,
    Nauru: 13090.14,
    "Papua New Guinea": 9778.43,
    Samoa: 11320.51,
    "West Bank and Gaza": 12271.79,
    Eritrea: 8543.46,
    Bahrain: 9747.9,
    Tuvalu: 7264.79,
    Belize: 9706.64,
    Montenegro: 7959.35,
    Azerbaijan: 8204.8,
    Kuwait: 8657,
    Oman: 6355.55,
    Ecuador: 6260.6,
    Kosovo: 3986,
    "Marshall Islands": 4292.88,
    "El Salvador": 4167.44,
    Micronesia: 3952.28,
    Jordan: 3137.81,
    Kiribati: 2437.79,
    "Timor-Leste": 1560.51,
    Liberia: 672.88
  },
  "2020": {
    Afghanistan: 46972.1,
    Ethiopia: 34724.59,
    Lebanon: 14035075.27,
    Syria: "n/a",
    Somalia: 331.64,
    Venezuela: 84532958.39,
    "Islamic Republic of Iran": 416983853.01,
    Vietnam: 81784666.81,
    Indonesia: 57120385.12,
    Korea: 37333541.6,
    Paraguay: 33301807.49,
    Uzbekistan: 17771337.06,
    "Lao P.D.R.": 23430872.98,
    Colombia: 19910159.58,
    Mongolia: 11017086.85,
    Guinea: 10537995.53,
    Chile: 10292591.73,
    Iceland: 8076968.92,
    "Sierra Leone": 5182911.56,
    Cambodia: 6602347.81,
    "Costa Rica": 7053711.73,
    Iraq: 5029053.91,
    Hungary: 4886741.56,
    Kazakhstan: 3746024.55,
    Uganda: 3490934.5,
    Gabon: 4182701.39,
    Japan: 4280443.79,
    "Equatorial Guinea": 4101914.62,
    Guyana: 1449638.32,
    Tanzania: 2560678.04,
    Armenia: 2086839.12,
    Argentina: 605476.78,
    Madagascar: 1749185.16,
    Myanmar: 2163699.34,
    Angola: 1087817.27,
    "Democratic Republic of the Congo": 1006918.65,
    "CÙte d'Ivoire": 1305485.36,
    "Republic of Congo": 1268008.78,
    Nigeria: 748289.12,
    Yemen: 431107.68,
    Rwanda: 769536.37,
    Serbia: 788764.93,
    "Sri Lanka": 683074.54,
    Senegal: 847198.77,
    Jamaica: 722862.7,
    "Taiwan Province of China": 838930.52,
    Cameroon: 864718.97,
    Russia: 731796.73,
    Benin: 741720.77,
    Uruguay: 638113.87,
    Djibouti: 618748.57,
    Sudan: 116633.82,
    Norway: 633931.07,
    Albania: 558752.52,
    "Macao SAR": 284582.05,
    "Dominican Republic": 426535.66,
    "Czech Republic": 532509.4,
    Togo: 526259.45,
    Burundi: 490223.17,
    Malawi: 422330.84,
    Mali: 511409.99,
    "Burkina Faso": 477628.31,
    Algeria: 423349.99,
    Comoros: 584222.6,
    "Guinea-Bissau": 453973.15,
    Sweden: 480125.38,
    Mauritius: 339115.58,
    Denmark: 400078.28,
    "Hong Kong SAR": 361931.53,
    Niger: 326742.74,
    "North Macedonia": 320962.12,
    Chad: 379150.45,
    "Cabo Verde": 296171.34,
    Vanuatu: 337139.26,
    Bhutan: 239325.96,
    Pakistan: 199244.5,
    "Central African Republic": 284035.73,
    Kenya: 220834.03,
    Seychelles: 205953.29,
    Bangladesh: 166333.27,
    "South Sudan": 53454.44,
    Thailand: 224903.88,
    Maldives: 152384.11,
    India: 143230.37,
    Haiti: 123468.09,
    Qatar: 197233.28,
    Mexico: 180556.49,
    Philippines: 164876.69,
    Zimbabwe: 74043.54,
    Nepal: 135736.97,
    Suriname: 63652.25,
    Ukraine: 100978.78,
    Israel: 152089.51,
    "United Arab Emirates": 141983.17,
    Moldova: 78342.58,
    "Kyrgyz Republic": 91814.18,
    Turkey: 60359.31,
    Croatia: 91908.54,
    Luxembourg: 102447.18,
    "South Africa": 92601.31,
    "Trinidad and Tobago": 104133.78,
    Botswana: 73543.23,
    China: 72536.09,
    Ireland: 74657.72,
    Singapore: 82502.96,
    Egypt: 57916.49,
    Australia: 76627.57,
    "Saudi Arabia": 74983.89,
    Mauritania: 70981.53,
    Nicaragua: 66721.19,
    Switzerland: 82023.4,
    Poland: 61221.63,
    "United States": 63358.49,
    Romania: 54610.16,
    Honduras: 58915.11,
    Namibia: 70404.25,
    "New Zealand": 63328.03,
    Eswatini: 58159.53,
    Canada: 58064.93,
    Malaysia: 43007.26,
    "The Gambia": 39699.1,
    "St. Kitts and Nevis": 46367.74,
    Aruba: 40245.44,
    Netherlands: 45962.44,
    "São Tomé and Príncipe": 47005.96,
    Austria: 42577.31,
    Brazil: 35171.89,
    Finland: 42746.7,
    Mozambique: 31178.93,
    Germany: 40494.46,
    Turkmenistan: 26857.59,
    Belgium: 39156.38,
    "Antigua and Barbuda": 37711.78,
    "San Marino": 40553.06,
    Guatemala: 33338.39,
    France: 35361.24,
    "Brunei Darussalam": 35957.44,
    Barbados: 30692.57,
    "United Kingdom": 31484.91,
    Morocco: 30304.88,
    "Puerto Rico": 32645.4,
    Andorra: 32142.4,
    "St. Lucia": 24123.69,
    "The Bahamas": 25734.16,
    Malta: 25370.85,
    Italy: 27692.05,
    Grenada: 24652,
    Zambia: 18769,
    Peru: 21442.41,
    Slovenia: 22386.03,
    Spain: 23814.84,
    Estonia: 20184.25,
    Cyprus: 23469.13,
    Dominica: 20023.86,
    Bolivia: 21729.82,
    Belarus: 15622.32,
    "St. Vincent and the Grenadines": 19721.73,
    Bulgaria: 17148.02,
    Portugal: 19406.95,
    "Solomon Islands": 18547.54,
    Lithuania: 17507.8,
    Georgia: 13292.6,
    "Slovak Republic": 16774.92,
    Ghana: 12459.49,
    Latvia: 15376.84,
    Lesotho: 15867.65,
    Libya: 4005.88,
    Greece: 15471.29,
    Panama: 12373.05,
    Palau: 14731.7,
    Fiji: 10833.47,
    Tonga: 11269.26,
    "Bosnia and Herzegovina": 10361.92,
    Tajikistan: 8711.44,
    Tunisia: 9266.08,
    Nauru: 13210.89,
    "Papua New Guinea": 9286.03,
    Samoa: 10973.23,
    "West Bank and Gaza": 10501.22,
    Eritrea: 8859.34,
    Bahrain: 8869.82,
    Tuvalu: 7395.36,
    Belize: 8153.54,
    Montenegro: 6749.08,
    Azerbaijan: 7194.94,
    Kuwait: 6946.48,
    Oman: 5481.08,
    Ecuador: 5642.74,
    Kosovo: 3810.01,
    "Marshall Islands": 4412.99,
    "El Salvador": 3798.83,
    Micronesia: 3892.27,
    Jordan: 3039.11,
    Kiribati: 2423.75,
    "Timor-Leste": 1347.86,
    Liberia: 647.1
  },
  "2021": {
    Afghanistan: "n/a",
    Ethiopia: 41308.89,
    Lebanon: "n/a",
    Syria: "n/a",
    Somalia: 350.36,
    Venezuela: 2278294609.33,
    "Islamic Republic of Iran": 600706025.13,
    Vietnam: 85331007.59,
    Indonesia: 60443649.48,
    Korea: 39923835,
    Paraguay: 34699124.68,
    Uzbekistan: 20415002.55,
    "Lao P.D.R.": 24735539.37,
    Colombia: 21826582.75,
    Mongolia: 12190738.93,
    Guinea: 12033612.39,
    Chile: 12214085.98,
    Iceland: 8654240.12,
    "Sierra Leone": 5855610.88,
    Cambodia: 6842006.09,
    "Costa Rica": 7307542.59,
    Iraq: 7094797.3,
    Hungary: 5475398.18,
    Kazakhstan: 4315962.01,
    Uganda: 3697447.63,
    Gabon: 4706383.56,
    Japan: 4414844.42,
    "Equatorial Guinea": 4643747.76,
    Guyana: 1941527.43,
    Tanzania: 2683223.47,
    Armenia: 2373433.95,
    Argentina: 950141.56,
    Madagascar: 1925846.07,
    Myanmar: 1850854.62,
    Angola: 1443713.02,
    "Democratic Republic of the Congo": 1187500.27,
    "CÙte d'Ivoire": 1367064.4,
    "Republic of Congo": 1453609.84,
    Nigeria: 863680.91,
    Yemen: 587856.03,
    Rwanda: 805538.07,
    Serbia: 867715.85,
    "Sri Lanka": 738011.57,
    Senegal: 880343.64,
    Jamaica: 800386.17,
    "Taiwan Province of China": 934004.54,
    Cameroon: 903910.12,
    Russia: 832171.29,
    Benin: 791922.78,
    Uruguay: 727098.67,
    Djibouti: 647881.85,
    Sudan: 342805.69,
    Norway: 710889.49,
    Albania: 598379.5,
    "Macao SAR": 336604.74,
    "Dominican Republic": 486938.25,
    "Czech Republic": 554700.99,
    Togo: 547590.29,
    Burundi: 512280.86,
    Malawi: 455135.66,
    Mali: 530623.53,
    "Burkina Faso": 506956.19,
    Algeria: 495094.11,
    Comoros: 572586.88,
    "Guinea-Bissau": 471252.56,
    Sweden: 499136.42,
    Mauritius: 360410.89,
    Denmark: 423199.1,
    "Hong Kong SAR": 383657.86,
    Niger: 340579.55,
    "North Macedonia": 346584.2,
    Chad: 400844.89,
    "Cabo Verde": 308962.84,
    Vanuatu: 346490.5,
    Bhutan: 243911.87,
    Pakistan: 224533.87,
    "Central African Republic": 283130.17,
    Kenya: 240471.14,
    Seychelles: 240547.9,
    Bangladesh: 181383.06,
    "South Sudan": 76359.76,
    Thailand: 230345.09,
    Maldives: 183364.94,
    India: 161359.54,
    Haiti: 140475.5,
    Qatar: 224917.68,
    Mexico: 200637.22,
    Philippines: 172263.92,
    Zimbabwe: 141877.13,
    Nepal: 141296.17,
    Suriname: 96227.79,
    Ukraine: 120667.16,
    Israel: 161861.66,
    "United Arab Emirates": 159892.16,
    Moldova: 86269.91,
    "Kyrgyz Republic": 103735.08,
    Turkey: 78369.3,
    Croatia: 99139.06,
    Luxembourg: 109936.24,
    "South Africa": 101545.26,
    "Trinidad and Tobago": 103792.68,
    Botswana: 81180.03,
    China: 79669.64,
    Ireland: 85732.49,
    Singapore: 88000.21,
    Egypt: 60806.79,
    Australia: 82998.54,
    "Saudi Arabia": 89109.08,
    Mauritania: 79254.08,
    Nicaragua: 72418.11,
    Switzerland: 84959.89,
    Poland: 66898.73,
    "United States": 69375.38,
    Romania: 61224.78,
    Honduras: 63508.88,
    Namibia: 71340.52,
    "New Zealand": 68259.82,
    Eswatini: 60274.48,
    Canada: 66040.15,
    Malaysia: 45210.94,
    "The Gambia": 42385.99,
    "St. Kitts and Nevis": 45676.33,
    Aruba: 46004.55,
    Netherlands: 48323.53,
    "São Tomé and Príncipe": 48206.08,
    Austria: 45040.12,
    Brazil: 40901.44,
    Finland: 44813.39,
    Mozambique: 32844.45,
    Germany: 42523.67,
    Turkmenistan: 30953.54,
    Belgium: 42209.57,
    "Antigua and Barbuda": 38118.18,
    "San Marino": 42646.19,
    Guatemala: 35490.36,
    France: 37706.09,
    "Brunei Darussalam": 45412.59,
    Barbados: 32210.26,
    "United Kingdom": 33227.57,
    Morocco: 32043.77,
    "Puerto Rico": 34143.27,
    Andorra: 33725.83,
    "St. Lucia": 25432.04,
    "The Bahamas": 27437.2,
    Malta: 26790.07,
    Italy: 29794.52,
    Grenada: 25853.43,
    Zambia: 23443.03,
    Peru: 25202.49,
    Slovenia: 24230.28,
    Spain: 25567.91,
    Estonia: 22690.91,
    Cyprus: 24688.28,
    Dominica: 20997.94,
    Bolivia: 22409.5,
    Belarus: 17918.98,
    "St. Vincent and the Grenadines": 18771.06,
    Bulgaria: 18556.82,
    Portugal: 20477.48,
    "Solomon Islands": 19158.58,
    Lithuania: 18764.83,
    Georgia: 15504.04,
    "Slovak Republic": 17903.8,
    Ghana: 14278.43,
    Latvia: 16359.54,
    Lesotho: 16978.48,
    Libya: 18058.88,
    Greece: 16600.89,
    Panama: 13861.06,
    Palau: 11835.18,
    Fiji: 10456.82,
    Tonga: 11211.47,
    "Bosnia and Herzegovina": 10886.38,
    Tajikistan: 9542.48,
    Tunisia: 10009.34,
    Nauru: 13567.42,
    "Papua New Guinea": 10339.96,
    Samoa: 9846.04,
    "West Bank and Gaza": 10819.81,
    Eritrea: 9436.56,
    Bahrain: 9886.52,
    Tuvalu: 7732.36,
    Belize: 8915.77,
    Montenegro: 7399.53,
    Azerbaijan: 8784.54,
    Kuwait: 8478.69,
    Oman: 6779.75,
    Ecuador: 5884.15,
    Kosovo: 4160.69,
    "Marshall Islands": 4338.17,
    "El Salvador": 4244.3,
    Micronesia: 3855.22,
    Jordan: 3115.09,
    Kiribati: 2533.06,
    "Timor-Leste": 1262.72,
    Liberia: 703.41
  },
  "2022": {
    Afghanistan: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Somalia: 369.62,
    Venezuela: 47562240013.64,
    "Islamic Republic of Iran": 767953272.7,
    Vietnam: 94023159.35,
    Indonesia: 65337960.91,
    Korea: 42007719.12,
    Paraguay: 36247466.86,
    Uzbekistan: 23597163.33,
    "Lao P.D.R.": 26585523.71,
    Colombia: 23100929.01,
    Mongolia: 13858940.15,
    Guinea: 13682253.32,
    Chile: 12951726.68,
    Iceland: 9149351.83,
    "Sierra Leone": 6852501.76,
    Cambodia: 7348965.64,
    "Costa Rica": 7586803.04,
    Iraq: 7778159.73,
    Hungary: 5986666.94,
    Kazakhstan: 4634652.34,
    Uganda: 3950076.86,
    Gabon: 4934242.44,
    Japan: 4599875.53,
    "Equatorial Guinea": 4281813.34,
    Guyana: 2561313.07,
    Tanzania: 2845418.37,
    Armenia: 2622421.43,
    Argentina: 1347057.99,
    Madagascar: 2094218.64,
    Myanmar: 1969983.87,
    Angola: 1611116.06,
    "Democratic Republic of the Congo": 1292007.32,
    "CÙte d'Ivoire": 1437071.05,
    "Republic of Congo": 1457191.77,
    Nigeria: 973630.2,
    Yemen: 757071.18,
    Rwanda: 888148.48,
    Serbia: 935403.19,
    "Sri Lanka": 803762.12,
    Senegal: 923116.25,
    Jamaica: 874310.58,
    "Taiwan Province of China": 976695.79,
    Cameroon: 939793.91,
    Russia: 874451.01,
    Benin: 835919.8,
    Uruguay: 789752.35,
    Djibouti: 687647.62,
    Sudan: 489575.2,
    Norway: 743484.79,
    Albania: 636573.89,
    "Macao SAR": 467722.65,
    "Dominican Republic": 531999.74,
    "Czech Republic": 591753.38,
    Togo: 579526.26,
    Burundi: 542745.13,
    Malawi: 492685.94,
    Mali: 553693.82,
    "Burkina Faso": 532504.43,
    Algeria: 536242.8,
    Comoros: 581166.95,
    "Guinea-Bissau": 492867.73,
    Sweden: 521068.01,
    Mauritius: 405399.57,
    Denmark: 440754.12,
    "Hong Kong SAR": 402586.06,
    Niger: 356566.34,
    "North Macedonia": 367926.59,
    Chad: 404055.43,
    "Cabo Verde": 330397.05,
    Vanuatu: 356462.61,
    Bhutan: 266616.12,
    Pakistan: 249373.73,
    "Central African Republic": 296190.02,
    Kenya: 261732.36,
    Seychelles: 265050.09,
    Bangladesh: 202014.66,
    "South Sudan": 148794.77,
    Thailand: 241806.62,
    Maldives: 208819.54,
    India: 182503.06,
    Haiti: 162244.4,
    Qatar: 235755.54,
    Mexico: 212698.61,
    Philippines: 185064.19,
    Zimbabwe: 169386.1,
    Nepal: 153893.25,
    Suriname: 124744.41,
    Ukraine: 135899.06,
    Israel: 169133.01,
    "United Arab Emirates": 164354.88,
    Moldova: 97919.68,
    "Kyrgyz Republic": 115721.37,
    Turkey: 90013.92,
    Croatia: 107433.9,
    Luxembourg: 114235.3,
    "South Africa": 105164.21,
    "Trinidad and Tobago": 110352.5,
    Botswana: 88434.41,
    China: 85698.29,
    Ireland: 89570.09,
    Singapore: 91694.4,
    Egypt: 66860.67,
    Australia: 86824.31,
    "Saudi Arabia": 90841.41,
    Mauritania: 79635.09,
    Nicaragua: 76857.1,
    Switzerland: 87204.54,
    Poland: 72488.36,
    "United States": 74725,
    Romania: 66203.88,
    Honduras: 67520.31,
    Namibia: 74400.49,
    "New Zealand": 73245.17,
    Eswatini: 63552.64,
    Canada: 70510.29,
    Malaysia: 49367.37,
    "The Gambia": 45837.81,
    "St. Kitts and Nevis": 50568.08,
    Aruba: 50039.87,
    Netherlands: 50646.45,
    "São Tomé and Príncipe": 49697.89,
    Austria: 47929.67,
    Brazil: 43889.92,
    Finland: 47063.37,
    Mozambique: 35465.13,
    Germany: 45258.44,
    Turkmenistan: 34786.89,
    Belgium: 44264.27,
    "Antigua and Barbuda": 41030.11,
    "San Marino": 44552.09,
    Guatemala: 37659.57,
    France: 39710.25,
    "Brunei Darussalam": 46574.31,
    Barbados: 35539.46,
    "United Kingdom": 35340.27,
    Morocco: 33082.29,
    "Puerto Rico": 35106.44,
    Andorra: 35085.73,
    "St. Lucia": 29443.54,
    "The Bahamas": 30584.62,
    Malta: 28614.38,
    Italy: 31607.69,
    Grenada: 27523.82,
    Zambia: 25693.71,
    Peru: 26776.4,
    Slovenia: 25692.39,
    Spain: 27469.22,
    Estonia: 24623.72,
    Cyprus: 25667.77,
    Dominica: 23109.76,
    Bolivia: 23456.6,
    Belarus: 20234.35,
    "St. Vincent and the Grenadines": 20846.49,
    Bulgaria: 19981.37,
    Portugal: 21865.4,
    "Solomon Islands": 20446.66,
    Lithuania: 20047.93,
    Georgia: 17360.16,
    "Slovak Republic": 19314.17,
    Ghana: 15917.11,
    Latvia: 17795.26,
    Lesotho: 17882.26,
    Libya: 19125.46,
    Greece: 17518.83,
    Panama: 14643.87,
    Palau: 13602.82,
    Fiji: 11231.15,
    Tonga: 12091.88,
    "Bosnia and Herzegovina": 11465.26,
    Tajikistan: 10372.21,
    Tunisia: 10913.83,
    Nauru: 13755.49,
    "Papua New Guinea": 10836.24,
    Samoa: 10145.23,
    "West Bank and Gaza": 11365.86,
    Eritrea: 10224.59,
    Bahrain: 10171.92,
    Tuvalu: 8201.94,
    Belize: 9443.29,
    Montenegro: 8042.98,
    Azerbaijan: 9023.32,
    Kuwait: 8750.24,
    Oman: 7008.11,
    Ecuador: 6107.6,
    Kosovo: 4440.99,
    "Marshall Islands": 4525.64,
    "El Salvador": 4475.18,
    Micronesia: 3973.25,
    Jordan: 3236.52,
    Kiribati: 2637.78,
    "Timor-Leste": 1301.32,
    Liberia: 743
  },
  "2023": {
    Afghanistan: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Somalia: "n/a",
    Venezuela: 964747254.82,
    "Islamic Republic of Iran": 103903272.02,
    Vietnam: 71045626.63,
    Indonesia: 43891764.28,
    Korea: 37886193.07,
    Paraguay: 26672514.87,
    Uzbekistan: 28228512.08,
    "Lao P.D.R.": 24298993.92,
    Colombia: 15553335.83,
    Mongolia: 15286038,
    Guinea: 13468635.15,
    Chile: 9481391.61,
    Iceland: 7752927.78,
    "Sierra Leone": 7952593.38,
    Cambodia: 7877373.33,
    "Costa Rica": 8028419.11,
    Iraq: 6452279.02,
    Hungary: 5009114.18,
    Kazakhstan: 4284705.13,
    Uganda: 4966898,
    Gabon: 4704816,
    Japan: 4180879.73,
    "Equatorial Guinea": 3138181.46,
    Guyana: 3031282,
    Tanzania: 2860780.47,
    Armenia: 1825017.22,
    Argentina: 2281949.73,
    Madagascar: 2151967.65,
    Myanmar: 1731892.14,
    Angola: 1414132.45,
    "Democratic Republic of the Congo": 1510028.37,
    "CÙte d'Ivoire": 1458789.84,
    "Republic of Congo": 1087319.76,
    Nigeria: 926070.41,
    Yemen: 993257.75,
    Rwanda: 1008966.36,
    Serbia: 884799.92,
    "Sri Lanka": 1012435.37,
    Senegal: 943854.64,
    Jamaica: 1005616.54,
    "Taiwan Province of China": 974548.03,
    Cameroon: 923922.97,
    Russia: 878780.05,
    Benin: 835132.26,
    Uruguay: 733662.71,
    Djibouti: 587404.34,
    Sudan: 764042.97,
    Norway: 675820.17,
    Albania: 591452.32,
    "Macao SAR": 575852.7,
    "Dominican Republic": 625583.99,
    "Czech Republic": 611543.07,
    Togo: 576423.51,
    Burundi: 532246.44,
    Malawi: 575856.91,
    Mali: 557589.58,
    "Burkina Faso": 566828.22,
    Algeria: 589066.01,
    Comoros: 520913.05,
    "Guinea-Bissau": 539817.3,
    Sweden: 430512.53,
    Mauritius: 456861.8,
    Denmark: 421269.61,
    "Hong Kong SAR": 386861.83,
    Niger: 387214.52,
    "North Macedonia": 409970.12,
    Chad: 352645.33,
    "Cabo Verde": 370352.7,
    Vanuatu: 294519.11,
    Bhutan: 278054.26,
    Pakistan: 312306.85,
    "Central African Republic": 284094.58,
    Kenya: 289354.64,
    Seychelles: 227134.48,
    Bangladesh: 203379.71,
    "South Sudan": 250424.05,
    Thailand: 234592.96,
    Maldives: 201740.94,
    India: 187887.64,
    Haiti: 238299.57,
    Qatar: 223177.72,
    Mexico: 199825.88,
    Philippines: 191958.98,
    Zimbabwe: 170654.14,
    Nepal: 151223.81,
    Suriname: 150471.53,
    Ukraine: 175304.41,
    Israel: 167723.64,
    "United Arab Emirates": 110964.78,
    Moldova: 125836.56,
    "Kyrgyz Republic": 102692.31,
    Turkey: 115147.72,
    Croatia: 117546.21,
    Luxembourg: 109956.21,
    "South Africa": 113615.06,
    "Trinidad and Tobago": 95225.42,
    Botswana: 91825.39,
    China: 93733.84,
    Ireland: 95140.69,
    Singapore: 74441.83,
    Egypt: 89921.74,
    Australia: 91394.27,
    "Saudi Arabia": 83313.67,
    Mauritania: 80459.31,
    Nicaragua: 88258.91,
    Switzerland: 77020.81,
    Poland: 77881.31,
    "United States": 71032.72,
    Romania: 71451.73,
    Honduras: 76997.79,
    Namibia: 75903.86,
    "New Zealand": 66592.84,
    Eswatini: 72653.16,
    Canada: 53072.03,
    Malaysia: 49875.22,
    "The Gambia": 53070.82,
    "St. Kitts and Nevis": 53714.46,
    Aruba: 52439.4,
    Netherlands: 51602.7,
    "São Tomé and Príncipe": 49302.44,
    Austria: 46325.92,
    Brazil: 48601.98,
    Finland: 40968.25,
    Mozambique: 46694.91,
    Germany: 38080.69,
    Turkmenistan: 45658.22,
    Belgium: 43571.16,
    "Antigua and Barbuda": 45558.02,
    "San Marino": 39565.36,
    Guatemala: 40790.95,
    France: 44482.42,
    "Brunei Darussalam": 37894.13,
    Barbados: 36142.29,
    "United Kingdom": 34461.92,
    Morocco: 35939.72,
    "Puerto Rico": 35882.85,
    Andorra: 32472.63,
    "St. Lucia": 32368.22,
    "The Bahamas": 30299.17,
    Malta: 32511.07,
    Italy: 29370.96,
    Grenada: 28010.16,
    Zambia: 28257.23,
    Peru: 27076.27,
    Slovenia: 28491.53,
    Spain: 26156.49,
    Estonia: 26833.59,
    Cyprus: 24950.29,
    Dominica: 24808.7,
    Bolivia: 21827,
    Belarus: 22621.12,
    "St. Vincent and the Grenadines": 21414.56,
    Bulgaria: 22852.26,
    Portugal: 21939.7,
    "Solomon Islands": 21203.68,
    Lithuania: 18868.78,
    Georgia: 20524.92,
    "Slovak Republic": 17419.73,
    Ghana: 18883.28,
    Latvia: 18800.73,
    Lesotho: 19449.71,
    Libya: 18213.11,
    Greece: 15481.39,
    Panama: 15695.71,
    Palau: 12345.59,
    Fiji: 12783.15,
    Tonga: 12081.86,
    "Bosnia and Herzegovina": 11208.13,
    Tajikistan: 11702.78,
    Tunisia: 13857.91,
    Nauru: 11251.54,
    "Papua New Guinea": 10693.85,
    Samoa: 11729.44,
    "West Bank and Gaza": 10720.71,
    Eritrea: 10407.11,
    Bahrain: 8757.61,
    Tuvalu: 9707.7,
    Belize: 8588.93,
    Montenegro: 9120.08,
    Azerbaijan: 8742.13,
    Kuwait: 6878.23,
    Oman: 6247.54,
    Ecuador: 4708.26,
    Kosovo: 4694.01,
    "Marshall Islands": 4626.44,
    "El Salvador": 4202.53,
    Micronesia: 3397.88,
    Jordan: 2705.12,
    Kiribati: 1344.33,
    "Timor-Leste": 750.72,
    Liberia: 403.28
  },
  "2024": {
    Afghanistan: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Somalia: "n/a",
    Venezuela: 1213282981.64,
    "Islamic Republic of Iran": 116250485.34,
    Vietnam: 76523753.96,
    Indonesia: 45808631.28,
    Korea: 40269776.28,
    Paraguay: 29651018.55,
    Uzbekistan: 30303711.49,
    "Lao P.D.R.": 25629899.43,
    Colombia: 17284311.83,
    Mongolia: 16960894.3,
    Guinea: 13978238.98,
    Chile: 9751955.15,
    Iceland: 8741334.33,
    "Sierra Leone": 8615083.59,
    Cambodia: 8218429.23,
    "Costa Rica": 8200277.2,
    Iraq: 6893924.48,
    Hungary: 5331419.39,
    Kazakhstan: 4647977.99,
    Uganda: 5041702.44,
    Gabon: 4781476.31,
    Japan: 4153950.74,
    "Equatorial Guinea": 3295083.2,
    Guyana: 3239726.42,
    Tanzania: 3104150.61,
    Armenia: 2336518.52,
    Argentina: 2459021.82,
    Madagascar: 2348111.21,
    Myanmar: 1844788.89,
    Angola: 1552104.99,
    "Democratic Republic of the Congo": 1586332.63,
    "CÙte d'Ivoire": 1522361.42,
    "Republic of Congo": 1197190.34,
    Nigeria: 1063797.55,
    Yemen: 1097246.53,
    Rwanda: 1083365.18,
    Serbia: 973787.83,
    "Sri Lanka": 1064574.44,
    Senegal: 1011595.93,
    Jamaica: 1039188.78,
    "Taiwan Province of China": 1015582.73,
    Cameroon: 974793.44,
    Russia: 926308.38,
    Benin: 885017.2,
    Uruguay: 783109.78,
    Djibouti: 666002.49,
    Sudan: 780525.51,
    Norway: 715638.69,
    Albania: 679941.04,
    "Macao SAR": 622992.27,
    "Dominican Republic": 658091.53,
    "Czech Republic": 645328.6,
    Togo: 612933.73,
    Burundi: 571101.97,
    Malawi: 598773.25,
    Mali: 583626.79,
    "Burkina Faso": 591609.66,
    Algeria: 598935.41,
    Comoros: 550549.12,
    "Guinea-Bissau": 555621.14,
    Sweden: 457656.91,
    Mauritius: 473965.21,
    Denmark: 440302.09,
    "Hong Kong SAR": 423606.47,
    Niger: 410334.69,
    "North Macedonia": 424397.54,
    Chad: 376785.91,
    "Cabo Verde": 383240.02,
    Vanuatu: 329376.46,
    Bhutan: 304812.04,
    Pakistan: 329169.74,
    "Central African Republic": 307947.1,
    Kenya: 310073.18,
    Seychelles: 254976.01,
    Bangladesh: 222984.53,
    "South Sudan": 262579.82,
    Thailand: 249174.89,
    Maldives: 222419.61,
    India: 211224.32,
    Haiti: 244278.01,
    Qatar: 233297.64,
    Mexico: 215315.36,
    Philippines: 211547.31,
    Zimbabwe: 187524.37,
    Nepal: 172231.13,
    Suriname: 165631.31,
    Ukraine: 181096.93,
    Israel: 171684.07,
    "United Arab Emirates": 126082.95,
    Moldova: 134625.71,
    "Kyrgyz Republic": 117087.76,
    Turkey: 122162.64,
    Croatia: 120736.75,
    Luxembourg: 114858.8,
    "South Africa": 116420.65,
    "Trinidad and Tobago": 101735.81,
    Botswana: 98310.08,
    China: 97631.16,
    Ireland: 98634.75,
    Singapore: 82876.94,
    Egypt: 92984.99,
    Australia: 92562.41,
    "Saudi Arabia": 86689.75,
    Mauritania: 84395.19,
    Nicaragua: 89781.4,
    Switzerland: 81437.75,
    Poland: 80714.78,
    "United States": 75727.3,
    Romania: 75685.08,
    Honduras: 79401.86,
    Namibia: 78136.83,
    "New Zealand": 70350.28,
    Eswatini: 74429.48,
    Canada: 56309.22,
    Malaysia: 53878.09,
    "The Gambia": 54907.64,
    "St. Kitts and Nevis": 55590.96,
    Aruba: 54163.32,
    Netherlands: 53789.57,
    "São Tomé and Príncipe": 51052.67,
    Austria: 49012.32,
    Brazil: 50341.04,
    Finland: 43518.1,
    Mozambique: 48160.56,
    Germany: 41801.35,
    Turkmenistan: 46933.42,
    Belgium: 45767.6,
    "Antigua and Barbuda": 46508.17,
    "San Marino": 41456.71,
    Guatemala: 41827.75,
    France: 43954.79,
    "Brunei Darussalam": 39672.58,
    Barbados: 37258.98,
    "United Kingdom": 35922.64,
    Morocco: 36693.81,
    "Puerto Rico": 36599.17,
    Andorra: 34225.51,
    "St. Lucia": 34008.96,
    "The Bahamas": 32084.79,
    Malta: 33263.32,
    Italy: 31235.97,
    Grenada: 29884.84,
    Zambia: 29400.32,
    Peru: 28473.35,
    Slovenia: 29437.81,
    Spain: 27785.71,
    Estonia: 27814.67,
    Cyprus: 26667.25,
    Dominica: 26255.91,
    Bolivia: 23474.41,
    Belarus: 24072.43,
    "St. Vincent and the Grenadines": 22881.8,
    Bulgaria: 23760.55,
    Portugal: 23084.88,
    "Solomon Islands": 22323.69,
    Lithuania: 20459.4,
    Georgia: 21634.07,
    "Slovak Republic": 19068.51,
    Ghana: 19935.57,
    Latvia: 19796.42,
    Lesotho: 19177.57,
    Libya: 18862.53,
    Greece: 16372.06,
    Panama: 16414.2,
    Palau: 13354.07,
    Fiji: 13449.42,
    Tonga: 12762.41,
    "Bosnia and Herzegovina": 12131.28,
    Tajikistan: 12479.01,
    Tunisia: 13879.3,
    Nauru: 11630.5,
    "Papua New Guinea": 11268.77,
    Samoa: 11911.85,
    "West Bank and Gaza": 11165.98,
    Eritrea: 10682.96,
    Bahrain: 9383.91,
    Tuvalu: 9901.86,
    Belize: 9133.6,
    Montenegro: 9274.19,
    Azerbaijan: 8802.2,
    Kuwait: 6825.18,
    Oman: 6400.01,
    Ecuador: 4973.46,
    Kosovo: 4844.89,
    "Marshall Islands": 4767.74,
    "El Salvador": 4387.07,
    Micronesia: 3575.57,
    Jordan: 2763.6,
    Kiribati: 1384.54,
    "Timor-Leste": 788.84,
    Liberia: 437.79
  },
  "2025": {
    Afghanistan: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Somalia: "n/a",
    Venezuela: 1527076324.06,
    "Islamic Republic of Iran": 130000201.16,
    Vietnam: 82233398.88,
    Indonesia: 47845912.47,
    Korea: 42755760.73,
    Paraguay: 32640744.47,
    Uzbekistan: 32640184.18,
    "Lao P.D.R.": 27050742.31,
    Colombia: 19074188.5,
    Mongolia: 18758400.8,
    Guinea: 14545933.35,
    Chile: 10218639.21,
    Iceland: 9731824.85,
    "Sierra Leone": 9337872.26,
    Cambodia: 8591017.66,
    "Costa Rica": 8391799.96,
    Iraq: 7301749.25,
    Hungary: 5618203.08,
    Kazakhstan: 5074687.32,
    Uganda: 5179717.12,
    Gabon: 4855659.35,
    Japan: 4088433.93,
    "Equatorial Guinea": 3496282.87,
    Guyana: 3468823.25,
    Tanzania: 3360277.84,
    Armenia: 2876154.15,
    Argentina: 2693238.92,
    Madagascar: 2560299.08,
    Myanmar: 1959629.91,
    Angola: 1703673.04,
    "Democratic Republic of the Congo": 1666646.02,
    "CÙte d'Ivoire": 1524573.57,
    "Republic of Congo": 1323909.17,
    Nigeria: 1199744.27,
    Yemen: 1211364.15,
    Rwanda: 1163868.15,
    Serbia: 1066727.3,
    "Sri Lanka": 1112420.73,
    Senegal: 1080538.59,
    Jamaica: 1076427.55,
    "Taiwan Province of China": 1064627.63,
    Cameroon: 1029461.13,
    Russia: 977289.2,
    Benin: 939131.6,
    Uruguay: 836241.82,
    Djibouti: 749580.53,
    Sudan: 796290.57,
    Norway: 757095.26,
    Albania: 709490.86,
    "Macao SAR": 674149.43,
    "Dominican Republic": 692124.69,
    "Czech Republic": 680980.72,
    Togo: 653175.25,
    Burundi: 614429.93,
    Malawi: 622583.91,
    Mali: 610784.46,
    "Burkina Faso": 614424.05,
    Algeria: 612300.53,
    Comoros: 582124.37,
    "Guinea-Bissau": 572888.51,
    Sweden: 485572.65,
    Mauritius: 491860.37,
    Denmark: 460355.75,
    "Hong Kong SAR": 452041.02,
    Niger: 435671.77,
    "North Macedonia": 440612.93,
    Chad: 403317.98,
    "Cabo Verde": 393031.51,
    Vanuatu: 360698.17,
    Bhutan: 337483.62,
    Pakistan: 346878.79,
    "Central African Republic": 333985.82,
    Kenya: 332374.54,
    Seychelles: 286050.95,
    Bangladesh: 238441.03,
    "South Sudan": 276044.41,
    Thailand: 263572.94,
    Maldives: 245029.54,
    India: 237729.8,
    Haiti: 250211.92,
    Qatar: 243711.76,
    Mexico: 231389.33,
    Philippines: 231332.44,
    Zimbabwe: 205126.23,
    Nepal: 192682.6,
    Suriname: 182193.31,
    Ukraine: 186896.12,
    Israel: 176310.17,
    "United Arab Emirates": 142728.83,
    Moldova: 144142.39,
    "Kyrgyz Republic": 133888.95,
    Turkey: 127571.41,
    Croatia: 123684.54,
    Luxembourg: 119791.11,
    "South Africa": 119303.53,
    "Trinidad and Tobago": 107646.42,
    Botswana: 105133.36,
    China: 101813.16,
    Ireland: 102083.22,
    Singapore: 92304.38,
    Egypt: 96313.42,
    Australia: 94102.42,
    "Saudi Arabia": 89335.17,
    Mauritania: 88696.5,
    Nicaragua: 90918.38,
    Switzerland: 86135.17,
    Poland: 83563.82,
    "United States": 80712.21,
    Romania: 80246.6,
    Honduras: 81785.07,
    Namibia: 80338.35,
    "New Zealand": 74401.58,
    Eswatini: 76194.14,
    Canada: 59730.54,
    Malaysia: 58097.6,
    "The Gambia": 56711.16,
    "St. Kitts and Nevis": 56996.62,
    Aruba: 55917.03,
    Netherlands: 56207.81,
    "São Tomé and Príncipe": 53677.96,
    Austria: 51799.73,
    Brazil: 52387.13,
    Finland: 46239.79,
    Mozambique: 49642.71,
    Germany: 45986.57,
    Turkmenistan: 48196.19,
    Belgium: 47611.04,
    "Antigua and Barbuda": 47819.47,
    "San Marino": 43491.12,
    Guatemala: 42803.52,
    France: 43746.85,
    "Brunei Darussalam": 41231.74,
    Barbados: 38489.07,
    "United Kingdom": 37516.64,
    Morocco: 37518.2,
    "Puerto Rico": 37073.25,
    Andorra: 35302.71,
    "St. Lucia": 35092.86,
    "The Bahamas": 33781.17,
    Malta: 34051.04,
    Italy: 32716.6,
    Grenada: 31982.28,
    Zambia: 30556.93,
    Peru: 29948.05,
    Slovenia: 30299.62,
    Spain: 29422.44,
    Estonia: 28880.44,
    Cyprus: 28354.88,
    Dominica: 27765.42,
    Bolivia: 25160.26,
    Belarus: 25348.06,
    "St. Vincent and the Grenadines": 24336.32,
    Bulgaria: 24674.27,
    Portugal: 24026.58,
    "Solomon Islands": 23405.62,
    Lithuania: 22192.71,
    Georgia: 22722.57,
    "Slovak Republic": 20868.46,
    Ghana: 21044.87,
    Latvia: 20604.55,
    Lesotho: 19731.21,
    Libya: 19536.13,
    Greece: 17319.3,
    Panama: 17017.28,
    Palau: 14181.98,
    Fiji: 14106.2,
    Tonga: 13496.67,
    "Bosnia and Herzegovina": 13138.49,
    Tajikistan: 13287.61,
    Tunisia: 13859.37,
    Nauru: 12052.18,
    "Papua New Guinea": 11887.98,
    Samoa: 12060.76,
    "West Bank and Gaza": 11622.18,
    Eritrea: 10982.6,
    Bahrain: 10061.91,
    Tuvalu: 10099.89,
    Belize: 9689.39,
    Montenegro: 9474.8,
    Azerbaijan: 8930.07,
    Kuwait: 6821.76,
    Oman: 6561.66,
    Ecuador: 5238.73,
    Kosovo: 4990.81,
    "Marshall Islands": 4894.17,
    "El Salvador": 4525.5,
    Micronesia: 3762.56,
    Jordan: 2816.68,
    Kiribati: 1453.89,
    "Timor-Leste": 829.81,
    Liberia: 464.39
  },
  "2026": {
    Afghanistan: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Somalia: "n/a",
    Venezuela: 1923552144.04,
    "Islamic Republic of Iran": 144762450.65,
    Vietnam: 88324036.76,
    Indonesia: 49892136.82,
    Korea: 45416452.23,
    Paraguay: 35803275.57,
    Uzbekistan: 35103116.79,
    "Lao P.D.R.": 28559101.16,
    Colombia: 20831254.29,
    Mongolia: 20794502.19,
    Guinea: 15173990.14,
    Chile: 10763597.66,
    Iceland: 10629878.58,
    "Sierra Leone": 10130152.35,
    Cambodia: 9024271.81,
    "Costa Rica": 8605961.6,
    Iraq: 7726205.87,
    Hungary: 5968154.51,
    Kazakhstan: 5527528.95,
    Uganda: 5397300.68,
    Gabon: 4925805.98,
    Japan: 4072011.05,
    "Equatorial Guinea": 3733214.43,
    Guyana: 3713810.57,
    Tanzania: 3634988.5,
    Armenia: 3392092.77,
    Argentina: 2916665.43,
    Madagascar: 2794172.45,
    Myanmar: 2083384.95,
    Angola: 1844466.93,
    "Democratic Republic of the Congo": 1749426.03,
    "CÙte d'Ivoire": 1520425.39,
    "Republic of Congo": 1464832.96,
    Nigeria: 1324414.61,
    Yemen: 1320277.12,
    Rwanda: 1250712.86,
    Serbia: 1163027.65,
    "Sri Lanka": 1158361.19,
    Senegal: 1152050.01,
    Jamaica: 1121379,
    "Taiwan Province of China": 1117650.79,
    Cameroon: 1088965.4,
    Russia: 1030712.44,
    Benin: 995200.2,
    Uruguay: 893363.97,
    Djibouti: 839878.29,
    Sudan: 814119.4,
    Norway: 801022.99,
    Albania: 737537.33,
    "Macao SAR": 729130.7,
    "Dominican Republic": 723353.28,
    "Czech Republic": 718336.67,
    Togo: 694794.48,
    Burundi: 660937.96,
    Malawi: 647330.2,
    Mali: 639412.12,
    "Burkina Faso": 638796.73,
    Algeria: 628247.29,
    Comoros: 615767.44,
    "Guinea-Bissau": 590763.87,
    Sweden: 514689.53,
    Mauritius: 510302.31,
    Denmark: 481754.64,
    "Hong Kong SAR": 471098.3,
    Niger: 462626.32,
    "North Macedonia": 455943.09,
    Chad: 431704.46,
    "Cabo Verde": 402960.44,
    Vanuatu: 393844.69,
    Bhutan: 370239.21,
    Pakistan: 365104.53,
    "Central African Republic": 362252.39,
    Kenya: 355628.25,
    Seychelles: 320723.68,
    Bangladesh: 292183.87,
    "South Sudan": 290722.9,
    Thailand: 278641.74,
    Maldives: 269840.81,
    India: 265468.43,
    Haiti: 257471.71,
    Qatar: 254793.23,
    Mexico: 248702.43,
    Philippines: 247183.96,
    Zimbabwe: 224202.67,
    Nepal: 214498.43,
    Suriname: 199744.38,
    Ukraine: 192929.5,
    Israel: 181588.59,
    "United Arab Emirates": 160984.05,
    Moldova: 153885.46,
    "Kyrgyz Republic": 153275.78,
    Turkey: 133348.3,
    Croatia: 126746.64,
    Luxembourg: 125188.77,
    "South Africa": 122286.84,
    "Trinidad and Tobago": 114930.07,
    Botswana: 112325.78,
    China: 106383.2,
    Ireland: 105680.95,
    Singapore: 102718.84,
    Egypt: 99815.14,
    Australia: 96369.64,
    "Saudi Arabia": 93571.28,
    Mauritania: 93353.32,
    Nicaragua: 92621.33,
    Switzerland: 90950.63,
    Poland: 86428.68,
    "United States": 85834.51,
    Romania: 85247.07,
    Honduras: 83836.59,
    Namibia: 82743.14,
    "New Zealand": 78642.62,
    Eswatini: 78152.35,
    Canada: 63452.7,
    Malaysia: 62416.76,
    "The Gambia": 58787.67,
    "St. Kitts and Nevis": 58402.66,
    Aruba: 57692.85,
    Netherlands: 57172.22,
    "São Tomé and Príncipe": 56051.09,
    Austria: 54697.54,
    Brazil: 54128.08,
    Finland: 54071.02,
    Mozambique: 51287.96,
    Germany: 50688.16,
    Turkmenistan: 49522.62,
    Belgium: 49215.63,
    "Antigua and Barbuda": 49167.74,
    "San Marino": 45670.56,
    Guatemala: 43842.78,
    France: 43836.72,
    "Brunei Darussalam": 42845.45,
    Barbados: 39766.17,
    "United Kingdom": 39162.14,
    Morocco: 38414.45,
    "Puerto Rico": 37560.14,
    Andorra: 36308.03,
    "St. Lucia": 36069.75,
    "The Bahamas": 35430.18,
    Malta: 34872.02,
    Italy: 34177.47,
    Grenada: 34122.65,
    Zambia: 31785.33,
    Peru: 31506.19,
    Slovenia: 31175.23,
    Spain: 31170.67,
    Estonia: 30102.9,
    Cyprus: 29655.94,
    Dominica: 29278.38,
    Bolivia: 26865.1,
    Belarus: 26538.3,
    "St. Vincent and the Grenadines": 25808,
    Bulgaria: 25559.38,
    Portugal: 24977.5,
    "Solomon Islands": 24454.27,
    Lithuania: 24063.01,
    Georgia: 23757.71,
    "Slovak Republic": 22762.05,
    Ghana: 22168.97,
    Latvia: 21777.06,
    Lesotho: 20378.97,
    Libya: 20209.91,
    Greece: 18326.52,
    Panama: 17566.78,
    Palau: 14899.17,
    Fiji: 14794.39,
    Tonga: 14274.23,
    "Bosnia and Herzegovina": 14236.55,
    Tajikistan: 14135.53,
    Tunisia: 13842.39,
    Nauru: 12469.56,
    "Papua New Guinea": 12462.19,
    Samoa: 12217.51,
    "West Bank and Gaza": 12104.03,
    Eritrea: 11294.36,
    Bahrain: 10730.17,
    Tuvalu: 10301.89,
    Belize: 10278.06,
    Montenegro: 9714.68,
    Azerbaijan: 9153.94,
    Kuwait: 6884.06,
    Oman: 6727.38,
    Ecuador: 5509.52,
    Kosovo: 5131.03,
    "Marshall Islands": 5011.22,
    "El Salvador": 4651.63,
    Micronesia: 3959.33,
    Jordan: 2858.08,
    Kiribati: 1530.18,
    "Timor-Leste": 872.57,
    Liberia: 490.69
  }
};
export const gdpP = {
  1980: {
    Afghanistan: "n/a",
    Ethiopia: 215.18,
    Lebanon: 1552.33,
    Pakistan: 384.9,
    Syria: 1421.84,
    Venezuela: 4671.08,
    Luxembourg: 17738.61,
    Ireland: 6252.21,
    Switzerland: 19463.95,
    "Macao SAR": "n/a",
    Norway: 15746.26,
    "United States": 12552.94,
    Denmark: 13886.37,
    Iceland: 15133.1,
    Singapore: 5004.99,
    Australia: 10987.05,
    Sweden: 16877.24,
    Netherlands: 13750.49,
    Austria: 10731.96,
    Qatar: 27242.48,
    Finland: 11257.8,
    Canada: 11280.2,
    Germany: 11109.74,
    Belgium: 12529.35,
    "San Marino": "n/a",
    "Hong Kong SAR": 5703.96,
    "United Kingdom": 10722.22,
    Israel: 6096.71,
    "New Zealand": 7246.92,
    France: 13052.06,
    Japan: 9659,
    "United Arab Emirates": 40014.58,
    Andorra: "n/a",
    "Taiwan Province of China": 2366.8,
    Malta: "n/a",
    Korea: 1714.62,
    Italy: 8548.18,
    Slovenia: "n/a",
    Spain: 6128.02,
    Estonia: "n/a",
    "Puerto Rico": 4507.15,
    Cyprus: 4549.65,
    "The Bahamas": 12289.31,
    "Czech Republic": "n/a",
    "Brunei Darussalam": "n/a",
    Aruba: "n/a",
    Portugal: 3338.76,
    Lithuania: "n/a",
    Kuwait: 20966.18,
    Bahrain: 10189.61,
    "Slovak Republic": "n/a",
    Latvia: "n/a",
    Hungary: 2151.47,
    "Saudi Arabia": 17655.09,
    Greece: 5898.16,
    Poland: 1595.05,
    Seychelles: 2329.35,
    Croatia: "n/a",
    Romania: 2052.08,
    "St. Kitts and Nevis": 1338.75,
    Barbados: 4036.51,
    Chile: 2579.92,
    Uruguay: 4139.89,
    Panama: 2071.04,
    "Antigua and Barbuda": 1920.25,
    "Trinidad and Tobago": 5847.71,
    Maldives: 412.51,
    Oman: 4674.11,
    Palau: "n/a",
    Guyana: 1101.86,
    China: 306.98,
    Bulgaria: 3673.24,
    Malaysia: 1926.96,
    "Islamic Republic of Iran": 2741.02,
    Turkey: 2133.69,
    "Costa Rica": 2108.75,
    Turkmenistan: "n/a",
    "St. Lucia": 1461.57,
    Russia: "n/a",
    Mauritius: 1268.11,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 1245.62,
    Kazakhstan: "n/a",
    Mexico: 3383.69,
    "Dominican Republic": 1534.32,
    Argentina: 8361.21,
    Dominica: "n/a",
    Brazil: 1229.88,
    Nauru: "n/a",
    Thailand: 705.48,
    Gabon: 6093.62,
    "St. Vincent and the Grenadines": 836.56,
    Botswana: 1235.79,
    "North Macedonia": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Belarus: "n/a",
    Tuvalu: "n/a",
    "South Africa": "n/a",
    Peru: 1164.83,
    Albania: 728.36,
    "Equatorial Guinea": 123.4,
    Georgia: "n/a",
    Moldova: "n/a",
    Colombia: 1681.75,
    Fiji: 2056.07,
    Ukraine: "n/a",
    Kosovo: "n/a",
    Armenia: "n/a",
    Ecuador: 2097.14,
    Jamaica: 1214.38,
    Tonga: 770.3,
    Paraguay: 1299.66,
    Vietnam: 652.59,
    Iraq: "n/a",
    Mongolia: "n/a",
    Indonesia: 673.24,
    Azerbaijan: "n/a",
    Guatemala: 1070.28,
    Jordan: 1697.14,
    Suriname: "n/a",
    Egypt: 580.04,
    Namibia: "n/a",
    Belize: 1148.32,
    "Marshall Islands": "n/a",
    Djibouti: "n/a",
    "El Salvador": 827.16,
    Bhutan: 301.91,
    Eswatini: 1353.87,
    Samoa: "n/a",
    "Cabo Verde": 541.44,
    "Sri Lanka": 323.04,
    Micronesia: "n/a",
    Libya: 13031.83,
    Philippines: 774.48,
    Tunisia: 1482.82,
    Morocco: 1132.09,
    Bolivia: 695.54,
    Nigeria: "n/a",
    "West Bank and Gaza": "n/a",
    Vanuatu: 1053.85,
    Algeria: 2268.61,
    "Papua New Guinea": 1380.84,
    "Lao P.D.R.": 596.2,
    "CÙte d'Ivoire": 1736.22,
    Bangladesh: 283.28,
    Honduras: 955.25,
    "Solomon Islands": 793.13,
    Ghana: 3489.41,
    India: 271.03,
    "São Tomé and Príncipe": 775.71,
    Uzbekistan: "n/a",
    "Republic of Congo": 1362.75,
    Kenya: 1305.25,
    Angola: 745.12,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Cambodia: "n/a",
    Senegal: 767.97,
    Kiribati: 720.46,
    Cameroon: 930.18,
    Benin: 633.15,
    Zimbabwe: "n/a",
    Haiti: 447.91,
    Comoros: 684.57,
    Nepal: 150.67,
    "Kyrgyz Republic": "n/a",
    "Timor-Leste": "n/a",
    Guinea: "n/a",
    Tanzania: 602.92,
    Myanmar: "n/a",
    Uganda: 664.57,
    Togo: 732.9,
    Lesotho: 340.85,
    Zambia: 718.8,
    "Burkina Faso": 349.67,
    Mali: 286.45,
    "Guinea-Bissau": 370.19,
    Tajikistan: "n/a",
    "The Gambia": 1007.79,
    Rwanda: 292.78,
    Sudan: 486.91,
    Niger: 609.43,
    Chad: 163.55,
    Liberia: "n/a",
    Eritrea: "n/a",
    "Democratic Republic of the Congo": 2730.7,
    Yemen: "n/a",
    "Central African Republic": 313.37,
    Mozambique: 396.82,
    Madagascar: 594.73,
    "Sierra Leone": 479.6,
    Malawi: 460.39,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 232.38
  },
  1981: {
    Afghanistan: "n/a",
    Ethiopia: 215.6,
    Lebanon: 1480.69,
    Pakistan: 442.25,
    Syria: 1763.34,
    Venezuela: 5085.58,
    Luxembourg: 15251.87,
    Ireland: 5886.37,
    Switzerland: 17725.79,
    "Macao SAR": "n/a",
    Norway: 15484.68,
    "United States": 13948.7,
    Denmark: 12076.17,
    Iceland: 15454.71,
    Singapore: 5672.11,
    Australia: 12505.14,
    Sweden: 15396.75,
    Netherlands: 11429.73,
    Austria: 9280.73,
    Qatar: 27819.57,
    Finland: 10990.45,
    Canada: 12396.67,
    Germany: 9329.59,
    Belgium: 10366.11,
    "San Marino": "n/a",
    "Hong Kong SAR": 5995.18,
    "United Kingdom": 10427.13,
    Israel: 6413.92,
    "New Zealand": 7503.12,
    France: 11393.07,
    Japan: 10574.37,
    "United Arab Emirates": 40910.92,
    Andorra: "n/a",
    "Taiwan Province of China": 2691.49,
    Malta: "n/a",
    Korea: 1883.46,
    Italy: 7739.54,
    Slovenia: "n/a",
    Spain: 5381.91,
    Estonia: "n/a",
    "Puerto Rico": 4940.78,
    Cyprus: 4352.56,
    "The Bahamas": 12493.75,
    "Czech Republic": "n/a",
    "Brunei Darussalam": "n/a",
    Aruba: "n/a",
    Portugal: 3296.65,
    Lithuania: "n/a",
    Kuwait: 17631.85,
    Bahrain: 11061.39,
    "Slovak Republic": "n/a",
    Latvia: "n/a",
    Hungary: 2207.13,
    "Saudi Arabia": 18832.08,
    Greece: 5376.98,
    Poland: 1497.84,
    Seychelles: 2403.46,
    Croatia: "n/a",
    Romania: 2450.35,
    "St. Kitts and Nevis": 1609.73,
    Barbados: 4427.06,
    Chile: 3004.73,
    Uruguay: 4588.98,
    Panama: 2288.37,
    "Antigua and Barbuda": 2177.09,
    "Trinidad and Tobago": 6454.42,
    Maldives: 464.91,
    Oman: 5251.75,
    Palau: "n/a",
    Guyana: 1055.84,
    China: 288.49,
    Bulgaria: 3950.23,
    Malaysia: 1920.13,
    "Islamic Republic of Iran": 2740.5,
    Turkey: 2113.07,
    "Costa Rica": 1111.35,
    Turkmenistan: "n/a",
    "St. Lucia": 1645.69,
    Russia: "n/a",
    Mauritius: 1186.58,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 1276.37,
    Kazakhstan: "n/a",
    Mexico: 4238.76,
    "Dominican Republic": 1677.45,
    Argentina: 6671.43,
    Dominica: 1112.64,
    Brazil: 1382.55,
    Nauru: "n/a",
    Thailand: 744.91,
    Gabon: 5376.62,
    "St. Vincent and the Grenadines": 1028.22,
    Botswana: 1071.13,
    "North Macedonia": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Belarus: "n/a",
    Tuvalu: "n/a",
    "South Africa": "n/a",
    Peru: 1373.07,
    Albania: 817.73,
    "Equatorial Guinea": 109.81,
    Georgia: "n/a",
    Moldova: "n/a",
    Colombia: 1792.26,
    Fiji: 2062.16,
    Ukraine: "n/a",
    Kosovo: "n/a",
    Armenia: "n/a",
    Ecuador: 2087.38,
    Jamaica: 1310.5,
    Tonga: 850.55,
    Paraguay: 1608.46,
    Vietnam: 318.52,
    Iraq: "n/a",
    Mongolia: "n/a",
    Indonesia: 736.99,
    Azerbaijan: "n/a",
    Guatemala: 1140.22,
    Jordan: 1838.68,
    Suriname: "n/a",
    Egypt: 617.66,
    Namibia: "n/a",
    Belize: 1193.87,
    "Marshall Islands": "n/a",
    Djibouti: "n/a",
    "El Salvador": 721.1,
    Bhutan: 317.38,
    Eswatini: 1390.76,
    Samoa: "n/a",
    "Cabo Verde": 522.81,
    "Sri Lanka": 331.64,
    Micronesia: "n/a",
    Libya: 10762.56,
    Philippines: 829.82,
    Tunisia: 1390.41,
    Morocco: 895.78,
    Bolivia: 615.66,
    Nigeria: "n/a",
    "West Bank and Gaza": "n/a",
    Vanuatu: 956.14,
    Algeria: 2305.51,
    "Papua New Guinea": 1319.41,
    "Lao P.D.R.": 341.87,
    "CÙte d'Ivoire": 1406.87,
    Bangladesh: 273.37,
    Honduras: 1017.07,
    "Solomon Islands": 812.61,
    Ghana: 5537.34,
    India: 274.73,
    "São Tomé and Príncipe": 931.01,
    Uzbekistan: "n/a",
    "Republic of Congo": 1083.72,
    Kenya: 1185.18,
    Angola: 679.02,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Cambodia: "n/a",
    Senegal: 677.9,
    Kiribati: 739.99,
    Cameroon: 1024,
    Benin: 413.92,
    Zimbabwe: "n/a",
    Haiti: 484.33,
    Comoros: 584.52,
    Nepal: 172.19,
    "Kyrgyz Republic": "n/a",
    "Timor-Leste": "n/a",
    Guinea: "n/a",
    Tanzania: 699.18,
    Myanmar: "n/a",
    Uganda: 1044.13,
    Togo: 588.84,
    Lesotho: 337.16,
    Zambia: 718.74,
    "Burkina Faso": 297.17,
    Mali: 234.09,
    "Guinea-Bissau": 463.69,
    Tajikistan: "n/a",
    "The Gambia": 907.55,
    Rwanda: 320.56,
    Sudan: 339.39,
    Niger: 510.03,
    Chad: 189.35,
    Liberia: "n/a",
    Eritrea: "n/a",
    "Democratic Republic of the Congo": 2301.28,
    Yemen: "n/a",
    "Central African Republic": 316.5,
    Mozambique: 300.91,
    Madagascar: 529.79,
    "Sierra Leone": 501.74,
    Malawi: 447.79,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 234.94
  },
  1982: {
    Afghanistan: "n/a",
    Ethiopia: 220.56,
    Lebanon: 1005.26,
    Pakistan: 469.52,
    Syria: 1784.58,
    Venezuela: 5041.59,
    Luxembourg: 12514.72,
    Ireland: 6078.14,
    Switzerland: 18022.27,
    "Macao SAR": "n/a",
    Norway: 15196.29,
    "United States": 14404.99,
    Denmark: 11801.27,
    Iceland: 14008.88,
    Singapore: 6000.03,
    Australia: 12212.05,
    Sweden: 13576.62,
    Netherlands: 11013.57,
    Austria: 9267.66,
    Qatar: 22657.37,
    Finland: 11036.94,
    Canada: 12543.94,
    Germany: 9014.98,
    Belgium: 9128.58,
    "San Marino": "n/a",
    "Hong Kong SAR": 6139.01,
    "United Kingdom": 9925.57,
    Israel: 6752.81,
    "New Zealand": 7370.59,
    France: 10764.18,
    Japan: 9772.81,
    "United Arab Emirates": 35767.65,
    Andorra: "n/a",
    "Taiwan Province of China": 2675.31,
    Malta: "n/a",
    Korea: 1992.29,
    Italy: 7642.78,
    Slovenia: "n/a",
    Spain: 5171.54,
    Estonia: "n/a",
    "Puerto Rico": 5146.93,
    Cyprus: 4452.72,
    "The Bahamas": 13746.78,
    "Czech Republic": "n/a",
    "Brunei Darussalam": "n/a",
    Aruba: "n/a",
    Portugal: 3095.1,
    Lithuania: "n/a",
    Kuwait: 14417.54,
    Bahrain: 11221.7,
    "Slovak Republic": "n/a",
    Latvia: "n/a",
    Hungary: 2249.98,
    "Saudi Arabia": 14912.69,
    Greece: 5576.51,
    Poland: 1803.46,
    Seychelles: 2293.94,
    Croatia: "n/a",
    Romania: 2441.21,
    "St. Kitts and Nevis": 1847.25,
    Barbados: 4611.69,
    Chile: 2204.38,
    Uruguay: 3727.41,
    Panama: 2469.14,
    "Antigua and Barbuda": 2448.59,
    "Trinidad and Tobago": 7393.15,
    Maldives: 534.91,
    Oman: 5510.96,
    Palau: "n/a",
    Guyana: 904.86,
    China: 279.97,
    Bulgaria: 4108.12,
    Malaysia: 2006.48,
    "Islamic Republic of Iran": 3020.64,
    Turkey: 1876.64,
    "Costa Rica": 1071.94,
    Turkmenistan: "n/a",
    "St. Lucia": 1532.03,
    Russia: "n/a",
    Mauritius: 1124.4,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 1346.87,
    Kazakhstan: "n/a",
    Mexico: 3002.23,
    "Dominican Republic": 1545.1,
    Argentina: 3257.85,
    Dominica: 1217.57,
    Brazil: 1446.23,
    Nauru: "n/a",
    Thailand: 767.41,
    Gabon: 4930.53,
    "St. Vincent and the Grenadines": 1136.55,
    Botswana: 1089.76,
    "North Macedonia": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Belarus: "n/a",
    Tuvalu: "n/a",
    "South Africa": "n/a",
    Peru: 1332.46,
    Albania: 824.54,
    "Equatorial Guinea": 121.29,
    Georgia: "n/a",
    Moldova: "n/a",
    Colombia: 1878.1,
    Fiji: 1941.77,
    Ukraine: "n/a",
    Kosovo: "n/a",
    Armenia: "n/a",
    Ecuador: 2026.38,
    Jamaica: 1479.51,
    Tonga: 843.21,
    Paraguay: 1636.53,
    Vietnam: 414.35,
    Iraq: "n/a",
    Mongolia: "n/a",
    Indonesia: 741.95,
    Azerbaijan: "n/a",
    Guatemala: 1126.19,
    Jordan: 1886.93,
    Suriname: "n/a",
    Egypt: 711.35,
    Namibia: "n/a",
    Belize: 1171.02,
    "Marshall Islands": "n/a",
    Djibouti: "n/a",
    "El Salvador": 706.49,
    Bhutan: 325.48,
    Eswatini: 1208.99,
    Samoa: "n/a",
    "Cabo Verde": 517.67,
    "Sri Lanka": 353.59,
    Micronesia: "n/a",
    Libya: 10247.11,
    Philippines: 843.47,
    Tunisia: 1309.9,
    Morocco: 881.18,
    Bolivia: 668.55,
    Nigeria: "n/a",
    "West Bank and Gaza": "n/a",
    Vanuatu: 930.89,
    Algeria: 2254.33,
    "Papua New Guinea": 1223.39,
    "Lao P.D.R.": 334.4,
    "CÙte d'Ivoire": 1215.25,
    Bangladesh: 249.37,
    Honduras: 1015.88,
    "Solomon Islands": 782.73,
    Ghana: 6271.39,
    India: 277.97,
    "São Tomé and Príncipe": 876.13,
    Uzbekistan: "n/a",
    "Republic of Congo": 916.55,
    Kenya: 1101.08,
    Angola: 661.52,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Cambodia: "n/a",
    Senegal: 645.37,
    Kiribati: 656.09,
    Cameroon: 955.19,
    Benin: 397.57,
    Zimbabwe: "n/a",
    Haiti: 494.86,
    Comoros: 541.62,
    Nepal: 170.03,
    "Kyrgyz Republic": "n/a",
    "Timor-Leste": "n/a",
    Guinea: "n/a",
    Tanzania: 778.24,
    Myanmar: "n/a",
    Uganda: 703.04,
    Togo: 478.89,
    Lesotho: 273.57,
    Zambia: 672.01,
    "Burkina Faso": 270.12,
    Mali: 202.85,
    "Guinea-Bissau": 516.55,
    Tajikistan: "n/a",
    "The Gambia": 827.98,
    Rwanda: 329.64,
    Sudan: 239.81,
    Niger: 458.11,
    Chad: 178.89,
    Liberia: "n/a",
    Eritrea: "n/a",
    "Democratic Republic of the Congo": 2422.24,
    Yemen: "n/a",
    "Central African Republic": 298.75,
    Mozambique: 300.39,
    Madagascar: 518.94,
    "Sierra Leone": 552.58,
    Malawi: 416.18,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 241.08
  },
  1983: {
    Afghanistan: "n/a",
    Ethiopia: 237.66,
    Lebanon: 1376.82,
    Pakistan: 426.13,
    Syria: 1849.43,
    Venezuela: 4883.23,
    Luxembourg: 12284.43,
    Ireland: 5839.83,
    Switzerland: 17864.54,
    "Macao SAR": "n/a",
    Norway: 14906.17,
    "United States": 15513.68,
    Denmark: 11852.89,
    Iceland: 11865.27,
    Singapore: 6717.84,
    Australia: 11570.45,
    Sweden: 12470.95,
    Netherlands: 10682.31,
    Austria: 9417.24,
    Qatar: 18003.76,
    Finland: 10545.06,
    Canada: 13493.2,
    Germany: 9025.21,
    Belgium: 8626.16,
    "San Marino": "n/a",
    "Hong Kong SAR": 5590.16,
    "United Kingdom": 9455.14,
    Israel: 7374.49,
    "New Zealand": 7064.34,
    France: 10245,
    Japan: 10636.46,
    "United Arab Emirates": 31690.07,
    Andorra: "n/a",
    "Taiwan Province of China": 2881.66,
    Malta: "n/a",
    Korea: 2198.94,
    Italy: 7925.74,
    Slovenia: "n/a",
    Spain: 4501.84,
    Estonia: "n/a",
    "Puerto Rico": 5257.19,
    Cyprus: 4399.28,
    "The Bahamas": 14958.65,
    "Czech Republic": "n/a",
    "Brunei Darussalam": "n/a",
    Aruba: "n/a",
    Portugal: 2838.66,
    Lithuania: "n/a",
    Kuwait: 12973.99,
    Bahrain: 11117.66,
    "Slovak Republic": "n/a",
    Latvia: "n/a",
    Hungary: 2046.4,
    "Saudi Arabia": 11972.2,
    Greece: 5008.02,
    Poland: 2056.96,
    Seychelles: 2280.45,
    Croatia: "n/a",
    Romania: 2125.16,
    "St. Kitts and Nevis": 1829.84,
    Barbados: 4882.79,
    Chile: 1762.22,
    Uruguay: 2032.97,
    Panama: 2477.12,
    "Antigua and Barbuda": 2752.97,
    "Trinidad and Tobago": 6939.44,
    Maldives: 560.24,
    Oman: 5363.89,
    Palau: "n/a",
    Guyana: 921.54,
    China: 296.51,
    Bulgaria: 4211.11,
    Malaysia: 2189.55,
    "Islamic Republic of Iran": 3709.15,
    Turkey: 1753.33,
    "Costa Rica": 1257.36,
    Turkmenistan: "n/a",
    "St. Lucia": 1627.3,
    Russia: "n/a",
    Mauritius: 1179.73,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 1372.67,
    Kazakhstan: "n/a",
    Mexico: 2390.4,
    "Dominican Republic": 1562.13,
    Argentina: 3962.74,
    Dominica: 1346.69,
    Brazil: 1134.93,
    Nauru: "n/a",
    Thailand: 824.45,
    Gabon: 4619.02,
    "St. Vincent and the Grenadines": 1212.18,
    Botswana: 1161.23,
    "North Macedonia": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Belarus: "n/a",
    Tuvalu: "n/a",
    "South Africa": "n/a",
    Peru: 1011.73,
    Albania: 815.53,
    "Equatorial Guinea": 126.41,
    Georgia: "n/a",
    Moldova: "n/a",
    Colombia: 1827.17,
    Fiji: 1781.08,
    Ukraine: "n/a",
    Kosovo: "n/a",
    Armenia: "n/a",
    Ecuador: 1732.5,
    Jamaica: 1289.23,
    Tonga: 818.46,
    Paraguay: 1762.99,
    Vietnam: 612.57,
    Iraq: "n/a",
    Mongolia: "n/a",
    Indonesia: 659.47,
    Azerbaijan: "n/a",
    Guatemala: 1139.96,
    Jordan: 1902.7,
    Suriname: "n/a",
    Egypt: 846.39,
    Namibia: "n/a",
    Belize: 1200.89,
    "Marshall Islands": "n/a",
    Djibouti: "n/a",
    "El Salvador": 672.52,
    Bhutan: 343.17,
    Eswatini: 1207.84,
    Samoa: "n/a",
    "Cabo Verde": 499.54,
    "Sri Lanka": 377.29,
    Micronesia: "n/a",
    Libya: 9371.01,
    Philippines: 735.71,
    Tunisia: 1322.84,
    Morocco: 778.04,
    Bolivia: 619.9,
    Nigeria: "n/a",
    "West Bank and Gaza": "n/a",
    Vanuatu: 931.69,
    Algeria: 2316.68,
    "Papua New Guinea": 1194.66,
    "Lao P.D.R.": 596.41,
    "CÙte d'Ivoire": 1043.54,
    Bangladesh: 235.35,
    Honduras: 1044.2,
    "Solomon Islands": 711.9,
    Ghana: 4114.17,
    India: 296.29,
    "São Tomé and Príncipe": 800.45,
    Uzbekistan: "n/a",
    "Republic of Congo": 808.39,
    Kenya: 983.36,
    Angola: 671.8,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Cambodia: "n/a",
    Senegal: 559.5,
    Kiribati: 630.23,
    Cameroon: 937.11,
    Benin: 340.87,
    Zimbabwe: "n/a",
    Haiti: 521.76,
    Comoros: 513.52,
    Nepal: 181.75,
    "Kyrgyz Republic": "n/a",
    "Timor-Leste": "n/a",
    Guinea: "n/a",
    Tanzania: 772.74,
    Myanmar: "n/a",
    Uganda: 779.82,
    Togo: 439.53,
    Lesotho: 293.24,
    Zambia: 562.44,
    "Burkina Faso": 240.72,
    Mali: 191.95,
    "Guinea-Bissau": 570.68,
    Tajikistan: "n/a",
    "The Gambia": 800.7,
    Rwanda: 339.3,
    Sudan: 318.51,
    Niger: 395.66,
    Chad: 174.76,
    Liberia: "n/a",
    Eritrea: "n/a",
    "Democratic Republic of the Congo": 1891.28,
    Yemen: "n/a",
    "Central African Republic": 279.72,
    Mozambique: 263.66,
    Madagascar: 495.27,
    "Sierra Leone": 469.37,
    Malawi: 419.66,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 248
  },
  1984: {
    Afghanistan: "n/a",
    Ethiopia: 217.42,
    Lebanon: 1617.73,
    Pakistan: 450.05,
    Syria: 1839.92,
    Venezuela: 3447.45,
    Luxembourg: 12033.02,
    Ireland: 5591.81,
    Switzerland: 17049.72,
    "Macao SAR": "n/a",
    Norway: 14968.79,
    "United States": 17086.44,
    Denmark: 11561.77,
    Iceland: 12139.43,
    Singapore: 7160.26,
    Australia: 12551.72,
    Sweden: 12976.14,
    Netherlands: 9904.97,
    Austria: 8882.63,
    Qatar: 17496.54,
    Finland: 10889.93,
    Canada: 13947.36,
    Germany: 8537.77,
    Belgium: 8244.85,
    "San Marino": "n/a",
    "Hong Kong SAR": 6170.5,
    "United Kingdom": 8944.86,
    Israel: 6892.4,
    "New Zealand": 6960.71,
    France: 9667.52,
    Japan: 11205.35,
    "United Arab Emirates": 29017.09,
    Andorra: "n/a",
    "Taiwan Province of China": 3202.62,
    Malta: "n/a",
    Korea: 2413.29,
    Italy: 7830.36,
    Slovenia: "n/a",
    Spain: 4471.65,
    Estonia: "n/a",
    "Puerto Rico": 5777.44,
    Cyprus: 4575.71,
    "The Bahamas": 15627.02,
    "Czech Republic": "n/a",
    "Brunei Darussalam": "n/a",
    Aruba: "n/a",
    Portugal: 2596.08,
    Lithuania: "n/a",
    Kuwait: 13410.42,
    Bahrain: 11163.44,
    "Slovak Republic": "n/a",
    Latvia: "n/a",
    Hungary: 1989.9,
    "Saudi Arabia": 10558.74,
    Greece: 4854.24,
    Poland: 2042.04,
    Seychelles: 2338.08,
    Croatia: "n/a",
    Romania: 1710.3,
    "St. Kitts and Nevis": 2144.8,
    Barbados: 5308.31,
    Chile: 1687.02,
    Uruguay: 1920.9,
    Panama: 2527.53,
    "Antigua and Barbuda": 3200.99,
    "Trinidad and Tobago": 6831.22,
    Maldives: 646.39,
    Oman: 5517.39,
    Palau: "n/a",
    Guyana: 861.06,
    China: 301.11,
    Bulgaria: 4465.39,
    Malaysia: 2419.5,
    "Islamic Republic of Iran": 3774.63,
    Turkey: 1668.46,
    "Costa Rica": 1421.16,
    Turkmenistan: "n/a",
    "St. Lucia": 2046.66,
    Russia: "n/a",
    Mauritius: 1080.41,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 1477.92,
    Kazakhstan: "n/a",
    Mexico: 2755.01,
    "Dominican Republic": 2397.6,
    Argentina: 4374.78,
    Dominica: 1495.27,
    Brazil: 1105.85,
    Nauru: "n/a",
    Thailand: 845.08,
    Gabon: 4346.39,
    "St. Vincent and the Grenadines": 1329.02,
    Botswana: 1112.15,
    "North Macedonia": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Belarus: "n/a",
    Tuvalu: "n/a",
    "South Africa": "n/a",
    Peru: 1018.79,
    Albania: 788.33,
    "Equatorial Guinea": 130.12,
    Georgia: "n/a",
    Moldova: "n/a",
    Colombia: 1767.18,
    Fiji: 1828.86,
    Ukraine: "n/a",
    Kosovo: "n/a",
    Armenia: "n/a",
    Ecuador: 1794.73,
    Jamaica: 948,
    Tonga: 854.28,
    Paraguay: 1390.89,
    Vietnam: 1044.41,
    Iraq: "n/a",
    Mongolia: "n/a",
    Indonesia: 672.2,
    Azerbaijan: "n/a",
    Guatemala: 1163.06,
    Jordan: 1843.99,
    Suriname: "n/a",
    Egypt: 925.97,
    Namibia: "n/a",
    Belize: 1304.51,
    "Marshall Islands": "n/a",
    Djibouti: "n/a",
    "El Salvador": 488.78,
    Bhutan: 346.71,
    Eswatini: 999.52,
    Samoa: "n/a",
    "Cabo Verde": 466.31,
    "Sri Lanka": 437.55,
    Micronesia: "n/a",
    Libya: 8411.48,
    Philippines: 678.34,
    Tunisia: 1286.6,
    Morocco: 695.13,
    Bolivia: 631.15,
    Nigeria: "n/a",
    "West Bank and Gaza": "n/a",
    Vanuatu: 1111.4,
    Algeria: 2432.72,
    "Papua New Guinea": 1079.59,
    "Lao P.D.R.": 831.39,
    "CÙte d'Ivoire": 1012.12,
    Bangladesh: 257.24,
    Honduras: 1092.45,
    "Solomon Islands": 691.21,
    Ghana: 1391.7,
    India: 281.1,
    "São Tomé and Príncipe": 812.99,
    Uzbekistan: "n/a",
    "Republic of Congo": 721.03,
    Kenya: 986.51,
    Angola: 693.78,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Cambodia: "n/a",
    Senegal: 529.94,
    Kiribati: 675.19,
    Cameroon: 964.51,
    Benin: 352.5,
    Zimbabwe: "n/a",
    Haiti: 567.92,
    Comoros: 490.14,
    Nepal: 187.86,
    "Kyrgyz Republic": "n/a",
    "Timor-Leste": "n/a",
    Guinea: "n/a",
    Tanzania: 682.03,
    Myanmar: "n/a",
    Uganda: 581.03,
    Togo: 389,
    Lesotho: 267.36,
    Zambia: 448.53,
    "Burkina Faso": 209.72,
    Mali: 187.73,
    "Guinea-Bissau": 388.7,
    Tajikistan: "n/a",
    "The Gambia": 552.84,
    Rwanda: 317.36,
    Sudan: 375.02,
    Niger: 310,
    Chad: 182.98,
    Liberia: "n/a",
    Eritrea: "n/a",
    "Democratic Republic of the Congo": 1221.72,
    Yemen: "n/a",
    "Central African Republic": 265.44,
    Mozambique: 270.38,
    Madagascar: 402.08,
    "Sierra Leone": 539.98,
    Malawi: 400.33,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 219
  },
  1985: {
    Afghanistan: "n/a",
    Ethiopia: 246.51,
    Lebanon: 1344.14,
    Pakistan: 438.02,
    Syria: 1965.14,
    Venezuela: 3472.19,
    Luxembourg: 12444.2,
    Ireland: 5934.55,
    Switzerland: 17230.78,
    "Macao SAR": "n/a",
    Norway: 15728.22,
    "United States": 18199.32,
    Denmark: 12259.28,
    Iceland: 12517.15,
    Singapore: 6788.39,
    Australia: 10947.21,
    Sweden: 13549.51,
    Netherlands: 10008.04,
    Austria: 9090.8,
    Qatar: 15495.7,
    Finland: 11488.75,
    Canada: 14185.92,
    Germany: 8678.85,
    Belgium: 8568.65,
    "San Marino": "n/a",
    "Hong Kong SAR": 6490.37,
    "United Kingdom": 9494.26,
    Israel: 6294.15,
    "New Zealand": 6943.52,
    France: 10028.46,
    Japan: 11815.8,
    "United Arab Emirates": 27036,
    Andorra: "n/a",
    "Taiwan Province of China": 3293.87,
    Malta: "n/a",
    Korea: 2482.4,
    Italy: 8082.89,
    Slovenia: "n/a",
    Spain: 4694.76,
    Estonia: "n/a",
    "Puerto Rico": 6058.64,
    Cyprus: 4824.74,
    "The Bahamas": 16797.76,
    "Czech Republic": "n/a",
    "Brunei Darussalam": 19916.36,
    Aruba: "n/a",
    Portugal: 2716.65,
    Lithuania: "n/a",
    Kuwait: 12670.69,
    Bahrain: 10182.37,
    "Slovak Republic": "n/a",
    Latvia: "n/a",
    Hungary: 2022.96,
    "Saudi Arabia": 8732.76,
    Greece: 4791.75,
    Poland: 1899.82,
    Seychelles: 2588.55,
    Croatia: "n/a",
    Romania: 2102,
    "St. Kitts and Nevis": 2406.13,
    Barbados: 5539.56,
    Chile: 1424.36,
    Uruguay: 1865.11,
    Panama: 2614.79,
    "Antigua and Barbuda": 3763.97,
    "Trinidad and Tobago": 6411.21,
    Maldives: 737.78,
    Oman: 6129.2,
    Palau: "n/a",
    Guyana: 924.31,
    China: 292.99,
    Bulgaria: 3820.54,
    Malaysia: 2154.46,
    "Islamic Republic of Iran": 3788.88,
    Turkey: 1835.18,
    "Costa Rica": 1478.37,
    Turkmenistan: "n/a",
    "St. Lucia": 2279.21,
    Russia: "n/a",
    Mauritius: 1085.14,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 1678.09,
    Kazakhstan: "n/a",
    Mexico: 2859.22,
    "Dominican Republic": 1022.61,
    Argentina: 3248.75,
    Dominica: 1642.75,
    Brazil: 1719.52,
    Nauru: "n/a",
    Thailand: 772.39,
    Gabon: 4478.47,
    "St. Vincent and the Grenadines": 1423.41,
    Botswana: 998.5,
    "North Macedonia": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Belarus: "n/a",
    Tuvalu: "n/a",
    "South Africa": "n/a",
    Peru: 861.6,
    Albania: 788.8,
    "Equatorial Guinea": 205.66,
    Georgia: "n/a",
    Moldova: "n/a",
    Colombia: 1623,
    Fiji: 1744.39,
    Ukraine: "n/a",
    Kosovo: "n/a",
    Armenia: "n/a",
    Ecuador: 2044.31,
    Jamaica: 878.36,
    Tonga: 802.93,
    Paraguay: 1154.15,
    Vietnam: 318.96,
    Iraq: "n/a",
    Mongolia: "n/a",
    Indonesia: 658.22,
    Azerbaijan: "n/a",
    Guatemala: 1338.72,
    Jordan: 1780.84,
    Suriname: "n/a",
    Egypt: 1049.33,
    Namibia: "n/a",
    Belize: 1258.9,
    "Marshall Islands": "n/a",
    Djibouti: "n/a",
    "El Salvador": 471.5,
    Bhutan: 349.04,
    Eswatini: 762.13,
    Samoa: "n/a",
    "Cabo Verde": 476.33,
    "Sri Lanka": 428.61,
    Micronesia: "n/a",
    Libya: 8319.67,
    Philippines: 645.6,
    Tunisia: 1271.85,
    Morocco: 685.33,
    Bolivia: 668.92,
    Nigeria: "n/a",
    "West Bank and Gaza": "n/a",
    Vanuatu: 976.75,
    Algeria: 2753.7,
    "Papua New Guinea": 975.97,
    "Lao P.D.R.": 1017.41,
    "CÙte d'Ivoire": 993.89,
    Bangladesh: 269.29,
    Honduras: 1161.92,
    "Solomon Islands": 611.27,
    Ghana: 1213.37,
    India: 302.95,
    "São Tomé and Príncipe": 837.6,
    Uzbekistan: "n/a",
    "Republic of Congo": 717.26,
    Kenya: 950.25,
    Angola: 758.53,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Cambodia: "n/a",
    Senegal: 563.25,
    Kiribati: 500.8,
    Cameroon: 981.18,
    Benin: 370.34,
    Zimbabwe: "n/a",
    Haiti: 619.46,
    Comoros: 482.7,
    Nepal: 190.72,
    "Kyrgyz Republic": "n/a",
    "Timor-Leste": "n/a",
    Guinea: "n/a",
    Tanzania: 596.87,
    Myanmar: "n/a",
    Uganda: 514.18,
    Togo: 393.79,
    Lesotho: 208.69,
    Zambia: 412.85,
    "Burkina Faso": 225.95,
    Mali: 185.33,
    "Guinea-Bissau": 559.37,
    Tajikistan: "n/a",
    "The Gambia": 645.9,
    Rwanda: 364.61,
    Sudan: 253.18,
    Niger: 295.81,
    Chad: 192.96,
    Liberia: "n/a",
    Eritrea: "n/a",
    "Democratic Republic of the Congo": 1080.9,
    Yemen: "n/a",
    "Central African Republic": 340.23,
    Mozambique: 353.79,
    Madagascar: 380.98,
    "Sierra Leone": 450.54,
    Malawi: 358.71,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 247.64
  },
  1986: {
    Afghanistan: "n/a",
    Ethiopia: 247.98,
    Lebanon: 1044.33,
    Pakistan: 436.75,
    Syria: 2284.04,
    Venezuela: 3488.56,
    Luxembourg: 18023.02,
    Ireland: 7993.15,
    Switzerland: 24576.93,
    "Macao SAR": "n/a",
    Norway: 18853.07,
    "United States": 19034.77,
    Denmark: 17215.43,
    Iceland: 16585.86,
    Singapore: 6870.77,
    Australia: 11224.32,
    Sweden: 17852.72,
    Netherlands: 13875.19,
    Austria: 12886.1,
    Qatar: 11644.46,
    Finland: 14998.34,
    Canada: 14539.75,
    Germany: 12387.01,
    Belgium: 11873.81,
    "San Marino": "n/a",
    "Hong Kong SAR": 7380.12,
    "United Kingdom": 11556.81,
    Israel: 7629.98,
    "New Zealand": 8373.55,
    France: 13922.96,
    Japan: 17466.67,
    "United Arab Emirates": 20533.02,
    Andorra: "n/a",
    "Taiwan Province of China": 4008.13,
    Malta: "n/a",
    Korea: 2834.89,
    Italy: 11446.01,
    Slovenia: "n/a",
    Spain: 6477.3,
    Estonia: "n/a",
    "Puerto Rico": 6495.5,
    Cyprus: 6057.98,
    "The Bahamas": 17850.61,
    "Czech Republic": "n/a",
    "Brunei Darussalam": 11908.98,
    Aruba: "n/a",
    Portugal: 3774.58,
    Lithuania: "n/a",
    Kuwait: 9653.47,
    Bahrain: 7695.81,
    "Slovak Republic": "n/a",
    Latvia: "n/a",
    Hungary: 2338.8,
    "Saudi Arabia": 6955.07,
    Greece: 5623.16,
    Poland: 1965.54,
    Seychelles: 3165.95,
    Croatia: "n/a",
    Romania: 2264.37,
    "St. Kitts and Nevis": 2851.26,
    Barbados: 6062.78,
    Chile: 1504.43,
    Uruguay: 2302.54,
    Panama: 2658.35,
    "Antigua and Barbuda": 4617.61,
    "Trinidad and Tobago": 4121.17,
    Maldives: 835.64,
    Oman: 4548.85,
    Palau: "n/a",
    Guyana: 1053.7,
    China: 279.91,
    Bulgaria: 3382.44,
    Malaysia: 1864.03,
    "Islamic Republic of Iran": 4257.54,
    Turkey: 1983.05,
    "Costa Rica": 1611.32,
    Turkmenistan: "n/a",
    "St. Lucia": 2683.65,
    Russia: "n/a",
    Mauritius: 1454.12,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 1872.46,
    Kazakhstan: "n/a",
    Mexico: 1937.36,
    "Dominican Republic": 1212.35,
    Argentina: 3857.03,
    Dominica: 1864.89,
    Brazil: 1955.07,
    Nauru: "n/a",
    Thailand: 840.32,
    Gabon: 5735.36,
    "St. Vincent and the Grenadines": 1561.27,
    Botswana: 1305.78,
    "North Macedonia": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Belarus: "n/a",
    Tuvalu: "n/a",
    "South Africa": "n/a",
    Peru: 1263.73,
    Albania: 855.72,
    "Equatorial Guinea": 241.44,
    Georgia: "n/a",
    Moldova: "n/a",
    Colombia: 1592.2,
    Fiji: 1954.09,
    Ukraine: "n/a",
    Kosovo: "n/a",
    Armenia: "n/a",
    Ecuador: 1461.92,
    Jamaica: 1034.77,
    Tonga: 959.29,
    Paraguay: 1335.76,
    Vietnam: 705.99,
    Iraq: "n/a",
    Mongolia: "n/a",
    Indonesia: 610.23,
    Azerbaijan: "n/a",
    Guatemala: 705.76,
    Jordan: 2193.07,
    Suriname: "n/a",
    Egypt: 1132.46,
    Namibia: "n/a",
    Belize: 1332.85,
    "Marshall Islands": "n/a",
    Djibouti: "n/a",
    "El Salvador": 469.94,
    Bhutan: 382.26,
    Eswatini: 936.25,
    Samoa: "n/a",
    "Cabo Verde": 645.82,
    "Sri Lanka": 454.83,
    Micronesia: "n/a",
    Libya: 6524.78,
    Philippines: 615.05,
    Tunisia: 1312.23,
    Morocco: 884.05,
    Bolivia: 640.94,
    Nigeria: "n/a",
    "West Bank and Gaza": "n/a",
    Vanuatu: 918.68,
    Algeria: 2698.92,
    "Papua New Guinea": 1039.81,
    "Lao P.D.R.": 691.93,
    "CÙte d'Ivoire": 1280.03,
    Bangladesh: 269.76,
    Honduras: 1180.46,
    "Solomon Islands": 529.41,
    Ghana: 1094.38,
    India: 315.16,
    "São Tomé and Príncipe": 1142.83,
    Uzbekistan: "n/a",
    "Republic of Congo": 951.7,
    Kenya: 1093.49,
    Angola: 690.45,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Cambodia: 25.71,
    Senegal: 773.09,
    Kiribati: 479.56,
    Cameroon: 1244.63,
    Benin: 462.44,
    Zimbabwe: "n/a",
    Haiti: 676.08,
    Comoros: 629.74,
    Nepal: 202.94,
    "Kyrgyz Republic": "n/a",
    "Timor-Leste": "n/a",
    Guinea: "n/a",
    Tanzania: 732.2,
    Myanmar: "n/a",
    Uganda: 494.51,
    Togo: 533.68,
    Lesotho: 233.93,
    Zambia: 276.15,
    "Burkina Faso": 288.78,
    Mali: 252.94,
    "Guinea-Bissau": 534.91,
    Tajikistan: "n/a",
    "The Gambia": 484.26,
    Rwanda: 399.28,
    Sudan: 328.59,
    Niger: 378.7,
    Chad: 230.72,
    Liberia: "n/a",
    Eritrea: "n/a",
    "Democratic Republic of the Congo": 1174.02,
    Yemen: "n/a",
    "Central African Republic": 431.34,
    Mozambique: 413.83,
    Madagascar: 423.6,
    "Sierra Leone": 330.04,
    Malawi: 354.47,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 253.42
  },
  1987: {
    Afghanistan: "n/a",
    Ethiopia: 256.7,
    Lebanon: 1219.35,
    Pakistan: 444.69,
    Syria: 2823.16,
    Venezuela: 2619.24,
    Luxembourg: 22239.62,
    Ireland: 9419.51,
    Switzerland: 30563.66,
    "Macao SAR": "n/a",
    Norway: 22445.04,
    "United States": 20000.97,
    Denmark: 21349.95,
    Iceland: 22795.64,
    Singapore: 7794.69,
    Australia: 12974.46,
    Sweden: 21629.29,
    Netherlands: 16895.4,
    Austria: 16093.01,
    Qatar: 11773.23,
    Finland: 18632.08,
    Canada: 16408.14,
    Germany: 15411.53,
    Belgium: 14740.46,
    "San Marino": "n/a",
    "Hong Kong SAR": 9015.2,
    "United Kingdom": 14312.48,
    Israel: 8966.97,
    "New Zealand": 11189.54,
    France: 16776.52,
    Japan: 21177.79,
    "United Arab Emirates": 21644.07,
    Andorra: "n/a",
    "Taiwan Province of China": 5325.17,
    Malta: "n/a",
    Korea: 3554.62,
    Italy: 14366.8,
    Slovenia: "n/a",
    Spain: 8187.29,
    Estonia: "n/a",
    "Puerto Rico": 6987.4,
    Cyprus: 7188.31,
    "The Bahamas": 19326.11,
    "Czech Republic": "n/a",
    "Brunei Darussalam": 13239.41,
    Aruba: "n/a",
    Portugal: 4724.45,
    Lithuania: "n/a",
    Kuwait: 10956.48,
    Bahrain: 8049.36,
    "Slovak Republic": "n/a",
    Latvia: "n/a",
    Hungary: 2582.85,
    "Saudi Arabia": 6524.17,
    Greece: 6522.42,
    Poland: 1691.13,
    Seychelles: 3638.99,
    Croatia: "n/a",
    Romania: 2517.95,
    "St. Kitts and Nevis": 3289.06,
    Barbados: 6653.72,
    Chile: 1743.88,
    Uruguay: 2864.7,
    Panama: 2613.1,
    "Antigua and Barbuda": 5455.26,
    "Trinidad and Tobago": 4085.59,
    Maldives: 769.82,
    Oman: 4488.92,
    Palau: "n/a",
    Guyana: 982.26,
    China: 299.84,
    Bulgaria: 3926.06,
    Malaysia: 2070.01,
    "Islamic Republic of Iran": 5602.03,
    Turkey: 2260.66,
    "Costa Rica": 1612.56,
    Turkmenistan: "n/a",
    "St. Lucia": 2921.99,
    Russia: "n/a",
    Mauritius: 1827.43,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 2162.55,
    Kazakhstan: "n/a",
    Mexico: 2080.4,
    "Dominican Republic": 1245.27,
    Argentina: 3909.98,
    Dominica: 2102.96,
    Brazil: 2087.45,
    Nauru: "n/a",
    Thailand: 967.71,
    Gabon: 4245.96,
    "St. Vincent and the Grenadines": 1693.04,
    Botswana: 1973.51,
    "North Macedonia": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Belarus: "n/a",
    Tuvalu: "n/a",
    "South Africa": "n/a",
    Peru: 2040.65,
    Albania: 832.25,
    "Equatorial Guinea": 284.26,
    Georgia: "n/a",
    Moldova: "n/a",
    Colombia: 1623.4,
    Fiji: 1776.9,
    Ukraine: "n/a",
    Kosovo: "n/a",
    Armenia: "n/a",
    Ecuador: 1332.15,
    Jamaica: 1158.6,
    Tonga: 1037.13,
    Paraguay: 1084.7,
    Vietnam: 856.91,
    Iraq: "n/a",
    Mongolia: "n/a",
    Indonesia: 562.9,
    Azerbaijan: "n/a",
    Guatemala: 796.96,
    Jordan: 2225.63,
    Suriname: "n/a",
    Egypt: 1585.22,
    Namibia: "n/a",
    Belize: 1518.91,
    "Marshall Islands": "n/a",
    Djibouti: "n/a",
    "El Salvador": 472.15,
    Bhutan: 447.28,
    Eswatini: 1172.28,
    Samoa: "n/a",
    "Cabo Verde": 783.24,
    "Sri Lanka": 468.95,
    Micronesia: "n/a",
    Libya: 5815.61,
    Philippines: 666.92,
    Tunisia: 1382.41,
    Morocco: 952.58,
    Bolivia: 683.63,
    Nigeria: "n/a",
    "West Bank and Gaza": "n/a",
    Vanuatu: 989.13,
    Algeria: 2705.11,
    "Papua New Guinea": 1159.83,
    "Lao P.D.R.": 473.88,
    "CÙte d'Ivoire": 1355.59,
    Bangladesh: 287.67,
    Honduras: 1249.59,
    "Solomon Islands": 540.76,
    Ghana: 895.17,
    India: 346.17,
    "São Tomé and Príncipe": 1111.67,
    Uzbekistan: "n/a",
    "Republic of Congo": 1121.61,
    Kenya: 1156.79,
    Angola: 769.56,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Cambodia: 17.12,
    Senegal: 902.23,
    Kiribati: 530.36,
    Cameroon: 1401.71,
    Benin: 535.19,
    Zimbabwe: "n/a",
    Haiti: 327.18,
    Comoros: 741.28,
    Nepal: 205.93,
    "Kyrgyz Republic": "n/a",
    "Timor-Leste": "n/a",
    Guinea: "n/a",
    Tanzania: 350.13,
    Myanmar: "n/a",
    Uganda: 768.94,
    Togo: 607.02,
    Lesotho: 291.92,
    Zambia: 332.27,
    "Burkina Faso": 327.43,
    Mali: 290.16,
    "Guinea-Bissau": 444.5,
    Tajikistan: "n/a",
    "The Gambia": 623.61,
    Rwanda: 428.42,
    Sudan: 516.78,
    Niger: 430.39,
    Chad: 253.53,
    Liberia: "n/a",
    Eritrea: "n/a",
    "Democratic Republic of the Congo": 1076,
    Yemen: "n/a",
    "Central African Republic": 465.18,
    Mozambique: 187.3,
    Madagascar: 304.15,
    "Sierra Leone": 281.36,
    Malawi: 327.76,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 231.93
  },
  1988: {
    Afghanistan: "n/a",
    Ethiopia: 257.56,
    Lebanon: 1217.43,
    Pakistan: 499.05,
    Syria: 1389.82,
    Venezuela: 3292.79,
    Luxembourg: 24976.67,
    Ireland: 10365.28,
    Switzerland: 32818.82,
    "Macao SAR": "n/a",
    Norway: 24143.12,
    "United States": 21376,
    Denmark: 22528.11,
    Iceland: 24840.13,
    Singapore: 9328.95,
    Australia: 16215.8,
    Sweden: 24339.27,
    Netherlands: 17956.11,
    Austria: 17632.82,
    Qatar: 10853.9,
    Finland: 22122.56,
    Canada: 19041.18,
    Germany: 16514.65,
    Belgium: 16008.64,
    "San Marino": "n/a",
    "Hong Kong SAR": 10527.46,
    "United Kingdom": 17386.5,
    Israel: 10925.93,
    "New Zealand": 13643.7,
    France: 18204.1,
    Japan: 25575.1,
    "United Arab Emirates": 18435.2,
    Andorra: "n/a",
    "Taiwan Province of China": 6338.1,
    Malta: "n/a",
    Korea: 4748.68,
    Italy: 15913.07,
    Slovenia: "n/a",
    Spain: 9598.67,
    Estonia: "n/a",
    "Puerto Rico": 7579.34,
    Cyprus: 8204.41,
    "The Bahamas": 19683.61,
    "Czech Republic": "n/a",
    "Brunei Darussalam": 12202.35,
    Aruba: "n/a",
    Portugal: 5532.62,
    Lithuania: "n/a",
    Kuwait: 9640.41,
    Bahrain: 9602.79,
    "Slovak Republic": "n/a",
    Latvia: "n/a",
    Hungary: 2838.56,
    "Saudi Arabia": 6398.83,
    Greece: 7568.29,
    Poland: 1819.39,
    Seychelles: 4128.12,
    Croatia: "n/a",
    Romania: 2593.06,
    "St. Kitts and Nevis": 3926.96,
    Barbados: 7052.01,
    Chile: 2021.05,
    Uruguay: 2946.36,
    Panama: 2211.67,
    "Antigua and Barbuda": 6547.26,
    "Trinidad and Tobago": 3798.75,
    Maldives: 897.93,
    Oman: 4362.95,
    Palau: "n/a",
    Guyana: 1125.87,
    China: 368.08,
    Bulgaria: 6433.12,
    Malaysia: 2213.87,
    "Islamic Republic of Iran": 5931.25,
    Turkey: 2333.23,
    "Costa Rica": 1598.04,
    Turkmenistan: "n/a",
    "St. Lucia": 3295.01,
    Russia: "n/a",
    Mauritius: 2104.55,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 2408.73,
    Kazakhstan: "n/a",
    Mexico: 2493.82,
    "Dominican Republic": 1113.84,
    Argentina: 4524.5,
    Dominica: 2377.9,
    Brazil: 2289.42,
    Nauru: "n/a",
    Thailand: 1160.54,
    Gabon: 4581.14,
    "St. Vincent and the Grenadines": 1923.87,
    Botswana: 2471.83,
    "North Macedonia": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Belarus: "n/a",
    Tuvalu: "n/a",
    "South Africa": "n/a",
    Peru: 1579.55,
    Albania: 805.05,
    "Equatorial Guinea": 296.91,
    Georgia: "n/a",
    Moldova: "n/a",
    Colombia: 1714.22,
    Fiji: 1672.49,
    Ukraine: "n/a",
    Kosovo: "n/a",
    Armenia: "n/a",
    Ecuador: 1234.67,
    Jamaica: 1372.73,
    Tonga: 1213.22,
    Paraguay: 1392.42,
    Vietnam: 464.58,
    Iraq: "n/a",
    Mongolia: "n/a",
    Indonesia: 621.93,
    Azerbaijan: "n/a",
    Guatemala: 841.77,
    Jordan: 2005.01,
    Suriname: "n/a",
    Egypt: 1858.03,
    Namibia: "n/a",
    Belize: 1759.54,
    "Marshall Islands": "n/a",
    Djibouti: "n/a",
    "El Salvador": 543.47,
    Bhutan: 496.57,
    Eswatini: 1332.88,
    Samoa: "n/a",
    "Cabo Verde": 861.86,
    "Sri Lanka": 485.28,
    Micronesia: "n/a",
    Libya: 5795.81,
    Philippines: 744.31,
    Tunisia: 1411.51,
    Morocco: 1102.83,
    Bolivia: 712.15,
    Nigeria: "n/a",
    "West Bank and Gaza": "n/a",
    Vanuatu: 1092.05,
    Algeria: 2143.74,
    "Papua New Guinea": 1565.17,
    "Lao P.D.R.": 300.08,
    "CÙte d'Ivoire": 1357.33,
    Bangladesh: 301.59,
    Honduras: 1351.37,
    "Solomon Islands": 598.34,
    Ghana: 930.22,
    India: 357.8,
    "São Tomé and Príncipe": 922.93,
    Uzbekistan: "n/a",
    "Republic of Congo": 1157.52,
    Kenya: 1158.82,
    Angola: 812.79,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Cambodia: 32.61,
    Senegal: 865.67,
    Kiribati: 652.24,
    Cameroon: 1382.9,
    Benin: 544.34,
    Zimbabwe: "n/a",
    Haiti: 212.61,
    Comoros: 772.13,
    Nepal: 237.47,
    "Kyrgyz Republic": "n/a",
    "Timor-Leste": "n/a",
    Guinea: "n/a",
    Tanzania: 324.72,
    Myanmar: "n/a",
    Uganda: 767.65,
    Togo: 655.62,
    Lesotho: 322.49,
    Zambia: 543.32,
    "Burkina Faso": 352.1,
    Mali: 287.28,
    "Guinea-Bissau": 406.03,
    Tajikistan: "n/a",
    "The Gambia": 695.35,
    Rwanda: 442.81,
    Sudan: 404.71,
    Niger: 426.03,
    Chad: 287,
    Liberia: "n/a",
    Eritrea: "n/a",
    "Democratic Republic of the Congo": 1206.85,
    Yemen: "n/a",
    "Central African Republic": 490.94,
    Mozambique: 164.97,
    Madagascar: 293.21,
    "Sierra Leone": 451.56,
    Malawi: 354.72,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 211.18
  },
  1989: {
    Afghanistan: "n/a",
    Ethiopia: 262.23,
    Lebanon: 983.32,
    Pakistan: 507.27,
    Syria: 800.86,
    Venezuela: 2378.16,
    Luxembourg: 26333.59,
    Ireland: 10754.62,
    Switzerland: 31478.19,
    "Macao SAR": "n/a",
    Norway: 24245.45,
    "United States": 22814.08,
    Denmark: 21913.16,
    Iceland: 22646.59,
    Singapore: 10725.74,
    Australia: 18168.82,
    Sweden: 25412.18,
    Netherlands: 17597.26,
    Austria: 17547.81,
    Qatar: 11039.94,
    Finland: 24040.64,
    Canada: 20842.46,
    Germany: 16232.12,
    Belgium: 16098.16,
    "San Marino": "n/a",
    "Hong Kong SAR": 12012.63,
    "United Kingdom": 17650.68,
    Israel: 10928.61,
    "New Zealand": 13099.98,
    France: 18218.7,
    Japan: 25336.2,
    "United Arab Emirates": 20478.53,
    Andorra: "n/a",
    "Taiwan Province of China": 7575.91,
    Malta: "n/a",
    Korea: 5817.07,
    Italy: 16538.74,
    Slovenia: "n/a",
    Spain: 10566.13,
    Estonia: "n/a",
    "Puerto Rico": 8094.29,
    Cyprus: 8635.88,
    "The Bahamas": 21814.31,
    "Czech Republic": "n/a",
    "Brunei Darussalam": 13414.34,
    Aruba: "n/a",
    Portugal: 5977.58,
    Lithuania: "n/a",
    Kuwait: 11359.51,
    Bahrain: 9668.04,
    "Slovak Republic": "n/a",
    Latvia: "n/a",
    Hungary: 2909.7,
    "Saudi Arabia": 6583.3,
    Greece: 7812.78,
    Poland: 1772.09,
    Seychelles: 4407.2,
    Croatia: "n/a",
    Romania: 2314.74,
    "St. Kitts and Nevis": 4392.77,
    Barbados: 7776.71,
    Chile: 2289.45,
    Uruguay: 3088.01,
    Panama: 2171.73,
    "Antigua and Barbuda": 7273.25,
    "Trinidad and Tobago": 3625.72,
    Maldives: 987.81,
    Oman: 4605.01,
    Palau: "n/a",
    Guyana: 1146.24,
    China: 406.53,
    Bulgaria: 6580.67,
    Malaysia: 2380.51,
    "Islamic Republic of Iran": 6955.85,
    Turkey: 2707.9,
    "Costa Rica": 1763.48,
    Turkmenistan: "n/a",
    "St. Lucia": 3668.49,
    Russia: "n/a",
    Mauritius: 2146.05,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 2757.87,
    Kazakhstan: "n/a",
    Mexico: 2979.92,
    "Dominican Republic": 1230.74,
    Argentina: 2867.31,
    Dominica: 2582.19,
    Brazil: 3087.93,
    Nauru: "n/a",
    Thailand: 1338.26,
    Gabon: 4899.23,
    "St. Vincent and the Grenadines": 2047.7,
    Botswana: 2663.03,
    "North Macedonia": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Belarus: "n/a",
    Tuvalu: "n/a",
    "South Africa": "n/a",
    Peru: 1908.46,
    Albania: 860.78,
    "Equatorial Guinea": 253.24,
    Georgia: "n/a",
    Moldova: "n/a",
    Colombia: 1693.41,
    Fiji: 1747.12,
    Ukraine: "n/a",
    Kosovo: "n/a",
    Armenia: "n/a",
    Ecuador: 1182.53,
    Jamaica: 1588.56,
    Tonga: 1461.36,
    Paraguay: 977.84,
    Vietnam: 123.36,
    Iraq: "n/a",
    Mongolia: "n/a",
    Indonesia: 696.87,
    Azerbaijan: "n/a",
    Guatemala: 946.76,
    Jordan: 1291.65,
    Suriname: "n/a",
    Egypt: 2266.44,
    Namibia: "n/a",
    Belize: 1977.23,
    "Marshall Islands": "n/a",
    Djibouti: "n/a",
    "El Salvador": 611.47,
    Bhutan: 483.64,
    Eswatini: 1306.67,
    Samoa: "n/a",
    "Cabo Verde": 853.01,
    "Sri Lanka": 482.24,
    Micronesia: "n/a",
    Libya: 5881.53,
    Philippines: 816.96,
    Tunisia: 1386.02,
    Morocco: 1109.29,
    Bolivia: 715.53,
    Nigeria: "n/a",
    "West Bank and Gaza": "n/a",
    Vanuatu: 1054.9,
    Algeria: 2127.87,
    "Papua New Guinea": 1455.04,
    "Lao P.D.R.": 360.33,
    "CÙte d'Ivoire": 1248.33,
    Bangladesh: 320.66,
    Honduras: 1467.03,
    "Solomon Islands": 570.06,
    Ghana: 924.05,
    India: 352.18,
    "São Tomé and Príncipe": 892.38,
    Uzbekistan: "n/a",
    "Republic of Congo": 1150.77,
    Kenya: 1111.27,
    Angola: 920.27,
    Mauritania: "n/a",
    Nicaragua: "n/a",
    Cambodia: 39.7,
    Senegal: 828.15,
    Kiribati: 630.11,
    Cameroon: 1196.77,
    Benin: 491.15,
    Zimbabwe: "n/a",
    Haiti: 192.61,
    Comoros: 731.08,
    Nepal: 234.55,
    "Kyrgyz Republic": "n/a",
    "Timor-Leste": "n/a",
    Guinea: "n/a",
    Tanzania: 271.5,
    Myanmar: "n/a",
    Uganda: 599.95,
    Togo: 625.67,
    Lesotho: 325.87,
    Zambia: 561.83,
    "Burkina Faso": 342.86,
    Mali: 345.97,
    "Guinea-Bissau": 478.38,
    Tajikistan: "n/a",
    "The Gambia": 701.79,
    Rwanda: 447.14,
    Sudan: 696.39,
    Niger: 394.73,
    Chad: 262.41,
    Liberia: "n/a",
    Eritrea: "n/a",
    "Democratic Republic of the Congo": 1188.85,
    Yemen: "n/a",
    "Central African Republic": 474.88,
    Mozambique: 171.73,
    Madagascar: 283.4,
    "Sierra Leone": 405.25,
    Malawi: 384.33,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 213.18
  },
  1990: {
    Afghanistan: "n/a",
    Ethiopia: 269.08,
    Lebanon: 998.85,
    Pakistan: 494.77,
    Syria: 967.15,
    Venezuela: 2492.94,
    Luxembourg: 33201.39,
    Ireland: 13644.79,
    Switzerland: 39888.21,
    "Macao SAR": "n/a",
    Norway: 28187.18,
    "United States": 23847.98,
    Denmark: 26920.58,
    Iceland: 25651.42,
    Singapore: 12763.34,
    Australia: 18835.27,
    Sweden: 30253.86,
    Netherlands: 21581.58,
    Austria: 21827.44,
    Qatar: 13150.17,
    Finland: 28489.57,
    Canada: 21572.14,
    Germany: 20249.14,
    Belgium: 20119.94,
    "San Marino": "n/a",
    "Hong Kong SAR": 13374.31,
    "United Kingdom": 20854.87,
    Israel: 12502.88,
    "New Zealand": 13439.75,
    France: 22432.06,
    Japan: 25895.96,
    "United Arab Emirates": 26621.51,
    Andorra: "n/a",
    "Taiwan Province of China": 8167.2,
    Malta: "n/a",
    Korea: 6609.99,
    Italy: 20624.44,
    Slovenia: "n/a",
    Spain: 13693.64,
    Estonia: "n/a",
    "Puerto Rico": 8675.58,
    Cyprus: 10360.13,
    "The Bahamas": 20456.26,
    "Czech Republic": "n/a",
    "Brunei Darussalam": 15373.58,
    Aruba: "n/a",
    Portugal: 7957.24,
    Lithuania: "n/a",
    Kuwait: 8588.15,
    Bahrain: 10179.63,
    "Slovak Republic": "n/a",
    Latvia: "n/a",
    Hungary: 3312.34,
    "Saudi Arabia": 7734.97,
    Greece: 9597.71,
    Poland: 1629.05,
    Seychelles: 5302.84,
    Croatia: "n/a",
    Romania: 1641.86,
    "St. Kitts and Nevis": 4969.88,
    Barbados: 7772.17,
    Chile: 2503.87,
    Uruguay: 3573.1,
    Panama: 2312.99,
    "Antigua and Barbuda": 7623.43,
    "Trinidad and Tobago": 4221.81,
    Maldives: 1091.55,
    Oman: 6340.52,
    Palau: "n/a",
    Guyana: 950.97,
    China: 346.87,
    Bulgaria: 2919.83,
    Malaysia: 2585.82,
    "Islamic Republic of Iran": 10797.11,
    Turkey: 3738.16,
    "Costa Rica": 1880.75,
    Turkmenistan: "n/a",
    "St. Lucia": 4321.31,
    Russia: "n/a",
    Mauritius: 2555.86,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 2887.01,
    Kazakhstan: "n/a",
    Mexico: 3450.2,
    "Dominican Republic": 1123.24,
    Argentina: 4857.8,
    Dominica: 2819.55,
    Brazil: 3106.12,
    Nauru: "n/a",
    Thailand: 1564.18,
    Gabon: 6815.63,
    "St. Vincent and the Grenadines": 2274.43,
    Botswana: 2834.69,
    "North Macedonia": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Belarus: "n/a",
    Tuvalu: "n/a",
    "South Africa": "n/a",
    Peru: 1301.47,
    Albania: 675.74,
    "Equatorial Guinea": 312.1,
    Georgia: "n/a",
    Moldova: "n/a",
    Colombia: 1690.42,
    Fiji: 1959.19,
    Ukraine: "n/a",
    Kosovo: "n/a",
    Armenia: "n/a",
    Ecuador: 1173.13,
    Jamaica: 1971.7,
    Tonga: 1498.5,
    Paraguay: 1185.28,
    Vietnam: 121.72,
    Iraq: "n/a",
    Mongolia: 1506.24,
    Indonesia: 770.76,
    Azerbaijan: "n/a",
    Guatemala: 808.88,
    Jordan: 1204.99,
    Suriname: 1413.62,
    Egypt: 1870.85,
    Namibia: 2109.53,
    Belize: 2180.35,
    "Marshall Islands": "n/a",
    Djibouti: "n/a",
    "El Salvador": 914.13,
    Bhutan: 514.63,
    Eswatini: 1552.21,
    Samoa: "n/a",
    "Cabo Verde": 957.37,
    "Sri Lanka": 546.46,
    Micronesia: "n/a",
    Libya: 7193.54,
    Philippines: 828.82,
    Tunisia: 1636.64,
    Morocco: 1248.81,
    Bolivia: 723.43,
    Nigeria: 652.92,
    "West Bank and Gaza": "n/a",
    Vanuatu: 1141.04,
    Algeria: 2473.51,
    "Papua New Guinea": 1265.88,
    "Lao P.D.R.": 414.26,
    "CÙte d'Ivoire": 1327.01,
    Bangladesh: 337.6,
    Honduras: 841.25,
    "Solomon Islands": 689.01,
    Ghana: 1045.58,
    India: 374,
    "São Tomé and Príncipe": 1050.25,
    Uzbekistan: "n/a",
    "Republic of Congo": 1310.37,
    Kenya: 1124.82,
    Angola: 986.64,
    Mauritania: 898.74,
    Nicaragua: "n/a",
    Cambodia: 99.83,
    Senegal: 936.15,
    Kiribati: 594.76,
    Cameroon: 1161.86,
    Benin: 580.42,
    Zimbabwe: 1036.53,
    Haiti: 240.23,
    Comoros: 868.95,
    Nepal: 235.48,
    "Kyrgyz Republic": "n/a",
    "Timor-Leste": "n/a",
    Guinea: 616.32,
    Tanzania: 211.03,
    Myanmar: "n/a",
    Uganda: 472.19,
    Togo: 734.5,
    Lesotho: 382.33,
    Zambia: 509.75,
    "Burkina Faso": 395.88,
    Mali: 388.43,
    "Guinea-Bissau": 571.82,
    Tajikistan: "n/a",
    "The Gambia": 790.83,
    Rwanda: 417.72,
    Sudan: 87.2,
    Niger: 435.34,
    Chad: 306.22,
    Liberia: "n/a",
    Eritrea: "n/a",
    "Democratic Republic of the Congo": 1192.38,
    Yemen: 1046.18,
    "Central African Republic": 539.66,
    Mozambique: 271.73,
    Madagascar: 338.95,
    "Sierra Leone": 219.46,
    Malawi: 420.59,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 207.11
  },
  1991: {
    Afghanistan: "n/a",
    Ethiopia: 287.65,
    Lebanon: 1502.89,
    Pakistan: 549.92,
    Syria: 972.18,
    Venezuela: 2685.29,
    Luxembourg: 35490.27,
    Ireland: 13748.2,
    Switzerland: 39868.41,
    "Macao SAR": "n/a",
    Norway: 28514.5,
    "United States": 24302.78,
    Denmark: 27052.65,
    Iceland: 27183.02,
    Singapore: 14501.96,
    Australia: 18630.75,
    Sweden: 31490.22,
    Netherlands: 22057.79,
    Austria: 22621.88,
    Qatar: 11500.52,
    Finland: 25646.58,
    Canada: 21885.57,
    Germany: 23453.06,
    Belgium: 20563.73,
    "San Marino": "n/a",
    "Hong Kong SAR": 15297.58,
    "United Kingdom": 21725.34,
    Israel: 13354.94,
    "New Zealand": 12426.12,
    France: 22329.53,
    Japan: 29511.78,
    "United Arab Emirates": 25847.41,
    Andorra: "n/a",
    "Taiwan Province of China": 9081.9,
    Malta: "n/a",
    Korea: 7637.2,
    Italy: 21766.81,
    Slovenia: "n/a",
    Spain: 14697.5,
    Estonia: "n/a",
    "Puerto Rico": 9093.48,
    Cyprus: 10413.86,
    "The Bahamas": 19767.72,
    "Czech Republic": "n/a",
    "Brunei Darussalam": 15723.28,
    Aruba: "n/a",
    Portugal: 9026.3,
    Lithuania: "n/a",
    Kuwait: 7960.51,
    Bahrain: 10252.37,
    "Slovak Republic": "n/a",
    Latvia: "n/a",
    Hungary: 3350.24,
    "Saudi Arabia": 8280.17,
    Greece: 10196.17,
    Poland: 2105.49,
    Seychelles: 5314.66,
    Croatia: "n/a",
    Romania: 1241.06,
    "St. Kitts and Nevis": 4912.92,
    Barbados: 7777.87,
    Chile: 2829.74,
    Uruguay: 4281.11,
    Panama: 2489.71,
    "Antigua and Barbuda": 7927.59,
    "Trinidad and Tobago": 4392.87,
    Maldives: 1184.66,
    Oman: 5937.75,
    Palau: "n/a",
    Guyana: 1036.88,
    China: 356.76,
    Bulgaria: 288.51,
    Malaysia: 2885.31,
    "Islamic Republic of Iran": 5357.88,
    Turkey: 3691.37,
    "Costa Rica": 2305.41,
    Turkmenistan: "n/a",
    "St. Lucia": 4511.87,
    Russia: "n/a",
    Mauritius: 2781.63,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 3117.9,
    Kazakhstan: "n/a",
    Mexico: 4059.99,
    "Dominican Republic": 1365.23,
    Argentina: 6429.45,
    Dominica: 3087.3,
    Brazil: 2677.84,
    Nauru: "n/a",
    Thailand: 1769.04,
    Gabon: 5993.32,
    "St. Vincent and the Grenadines": 2392.79,
    Botswana: 2755.11,
    "North Macedonia": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Belarus: "n/a",
    Tuvalu: "n/a",
    "South Africa": "n/a",
    Peru: 1530.74,
    Albania: 408.02,
    "Equatorial Guinea": 299.07,
    Georgia: "n/a",
    Moldova: "n/a",
    Colombia: 1711.65,
    Fiji: 2012.45,
    Ukraine: "n/a",
    Kosovo: "n/a",
    Armenia: "n/a",
    Ecuador: 1286.62,
    Jamaica: 1799.77,
    Tonga: 1750.86,
    Paraguay: 1585.24,
    Vietnam: 141.11,
    Iraq: "n/a",
    Mongolia: 1548.55,
    Indonesia: 847.57,
    Azerbaijan: "n/a",
    Guatemala: 966,
    Jordan: 1193.76,
    Suriname: 1535.96,
    Egypt: 923.84,
    Namibia: 2034.92,
    Belize: 2292.38,
    "Marshall Islands": "n/a",
    Djibouti: 1069.56,
    "El Salvador": 983.18,
    Bhutan: 446.27,
    Eswatini: 1567.76,
    Samoa: "n/a",
    "Cabo Verde": 974.87,
    "Sri Lanka": 591.18,
    Micronesia: "n/a",
    Libya: 7776.95,
    Philippines: 830.41,
    Tunisia: 1687.19,
    Morocco: 1310.61,
    Bolivia: 776.71,
    Nigeria: 615.66,
    "West Bank and Gaza": "n/a",
    Vanuatu: 1333.39,
    Algeria: 1819.98,
    "Papua New Guinea": 1444.1,
    "Lao P.D.R.": 474.15,
    "CÙte d'Ivoire": 1240,
    Bangladesh: 341.25,
    Honduras: 822.86,
    "Solomon Islands": 709.34,
    Ghana: 1142.78,
    India: 308.37,
    "São Tomé and Príncipe": 920.77,
    Uzbekistan: "n/a",
    "Republic of Congo": 1326.74,
    Kenya: 1029.42,
    Angola: 928.51,
    Mauritania: 1002.02,
    Nicaragua: "n/a",
    Cambodia: 215.67,
    Senegal: 894.49,
    Kiribati: 645.75,
    Cameroon: 1256.8,
    Benin: 573.66,
    Zimbabwe: 936.26,
    Haiti: 211.53,
    Comoros: 895.71,
    Nepal: 251.04,
    "Kyrgyz Republic": "n/a",
    "Timor-Leste": "n/a",
    Guinea: 660.44,
    Tanzania: 237.57,
    Myanmar: "n/a",
    Uganda: 238.48,
    Togo: 709.22,
    Lesotho: 427.81,
    Zambia: 446.78,
    "Burkina Faso": 389.6,
    Mali: 388.68,
    "Guinea-Bissau": 787.21,
    Tajikistan: "n/a",
    "The Gambia": 801.58,
    Rwanda: 298.96,
    Sudan: 95.29,
    Niger: 394.9,
    Chad: 293.84,
    Liberia: "n/a",
    Eritrea: "n/a",
    "Democratic Republic of the Congo": 1120.91,
    Yemen: 1158.44,
    "Central African Republic": 502.9,
    Mozambique: 272.61,
    Madagascar: 272.53,
    "Sierra Leone": 261.75,
    Malawi: 523.59,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 207.67
  },
  1992: {
    Afghanistan: "n/a",
    Ethiopia: 293.35,
    Lebanon: 1778.32,
    Pakistan: 564.46,
    Syria: 983.58,
    Venezuela: 2968.5,
    Luxembourg: 39219.91,
    Ireland: 15331.89,
    Switzerland: 40911.47,
    "Macao SAR": "n/a",
    Norway: 30432.89,
    "United States": 25392.93,
    Denmark: 29622.47,
    Iceland: 27383.23,
    Singapore: 16136.24,
    Australia: 18082.41,
    Sweden: 32584.88,
    Netherlands: 24191.96,
    Austria: 25068.4,
    Qatar: 13038.98,
    Finland: 22496.24,
    Canada: 20984.78,
    Germany: 26538.04,
    Belgium: 22823.03,
    "San Marino": "n/a",
    "Hong Kong SAR": 17710.51,
    "United Kingdom": 22385.42,
    Israel: 14407.21,
    "New Zealand": 11754.87,
    France: 24538.41,
    Japan: 32069.07,
    "United Arab Emirates": 25960.97,
    Andorra: "n/a",
    "Taiwan Province of China": 10715.54,
    Malta: "n/a",
    Korea: 8126.45,
    Italy: 23085.99,
    Slovenia: 9639.35,
    Spain: 16013.16,
    Estonia: "n/a",
    "Puerto Rico": 9687.44,
    Cyprus: 12158.76,
    "The Bahamas": 19417.22,
    "Czech Republic": "n/a",
    "Brunei Darussalam": 17331,
    Aruba: "n/a",
    Portugal: 10863.49,
    Lithuania: "n/a",
    Kuwait: 13989.93,
    Bahrain: 10536.82,
    "Slovak Republic": "n/a",
    Latvia: 632.63,
    Hungary: 3733.45,
    "Saudi Arabia": 8077.75,
    Greece: 11139.45,
    Poland: 2315.65,
    Seychelles: 6128.35,
    Croatia: 2572.62,
    Romania: 847.68,
    "St. Kitts and Nevis": 5386.94,
    Barbados: 7464.87,
    Chile: 3368.27,
    Uruguay: 4890.51,
    Panama: 2770.95,
    "Antigua and Barbuda": 8084.43,
    "Trinidad and Tobago": 4474.14,
    Maldives: 1343.01,
    Oman: 6292.66,
    Palau: "n/a",
    Guyana: 1145.78,
    China: 420.03,
    Bulgaria: 1183.16,
    Malaysia: 3378.72,
    "Islamic Republic of Iran": 884.44,
    Turkey: 3818.77,
    "Costa Rica": 2684.09,
    Turkmenistan: 409.7,
    "St. Lucia": 4878.46,
    Russia: 482.77,
    Mauritius: 3091.41,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 3192.22,
    Kazakhstan: 168.65,
    Mexico: 4624.01,
    "Dominican Republic": 1573.69,
    Argentina: 7653.71,
    Dominica: 3287.85,
    Brazil: 2523.74,
    Nauru: "n/a",
    Thailand: 1999.2,
    Gabon: 6015.51,
    "St. Vincent and the Grenadines": 2610.54,
    Botswana: 2766.06,
    "North Macedonia": 1263.86,
    "Bosnia and Herzegovina": "n/a",
    Belarus: 1252.79,
    Tuvalu: "n/a",
    "South Africa": "n/a",
    Peru: 1562.58,
    Albania: 259.75,
    "Equatorial Guinea": 351.66,
    Georgia: "n/a",
    Moldova: 290.06,
    Colombia: 1985.74,
    Fiji: 2208.96,
    Ukraine: 427.86,
    Kosovo: "n/a",
    Armenia: 31.32,
    Ecuador: 1375.71,
    Jamaica: 1804.41,
    Tonga: 1832.84,
    Paraguay: 1592.06,
    Vietnam: 178.97,
    Iraq: "n/a",
    Mongolia: 844.16,
    Indonesia: 907.77,
    Azerbaijan: 159.97,
    Guatemala: 1045.43,
    Jordan: 1395.29,
    Suriname: 1406.58,
    Egypt: 825.45,
    Namibia: 2071,
    Belize: 2604.22,
    "Marshall Islands": "n/a",
    Djibouti: 1090.97,
    "El Salvador": 1073.31,
    Bhutan: 442.84,
    Eswatini: 1699.81,
    Samoa: "n/a",
    "Cabo Verde": 1062.47,
    "Sri Lanka": 630.11,
    Micronesia: "n/a",
    Libya: 7703.53,
    Philippines: 946.76,
    Tunisia: 1973.45,
    Morocco: 1343.36,
    Bolivia: 803.55,
    Nigeria: 521.91,
    "West Bank and Gaza": "n/a",
    Vanuatu: 1357.7,
    Algeria: 1873.42,
    "Papua New Guinea": 1546.58,
    "Lao P.D.R.": 528.18,
    "CÙte d'Ivoire": 1268.08,
    Bangladesh: 340.03,
    Honduras: 891.44,
    "Solomon Islands": 815.27,
    Ghana: 1102.83,
    India: 322.51,
    "São Tomé and Príncipe": 788.5,
    Uzbekistan: 209.57,
    "Republic of Congo": 1398.92,
    Kenya: 983.51,
    Angola: 695.06,
    Mauritania: 1026.9,
    Nicaragua: "n/a",
    Cambodia: 252.49,
    Senegal: 930.57,
    Kiribati: 646.91,
    Cameroon: 1117.82,
    Benin: 615.86,
    Zimbabwe: 748.65,
    Haiti: 124.53,
    Comoros: 955.47,
    Nepal: 222.01,
    "Kyrgyz Republic": 207.98,
    "Timor-Leste": "n/a",
    Guinea: 678.63,
    Tanzania: 213.15,
    Myanmar: "n/a",
    Uganda: 216.04,
    Togo: 728.16,
    Lesotho: 477.28,
    Zambia: 424.95,
    "Burkina Faso": 406.07,
    Mali: 390.52,
    "Guinea-Bissau": 843.6,
    Tajikistan: 52.62,
    "The Gambia": 830.59,
    Rwanda: 317.04,
    Sudan: 113.48,
    Niger: 393.72,
    Chad: 296.67,
    Liberia: "n/a",
    Eritrea: 268.19,
    "Democratic Republic of the Congo": 973.2,
    Yemen: 1351.44,
    "Central African Republic": 489.79,
    Mozambique: 191.23,
    Madagascar: 302,
    "Sierra Leone": 228.23,
    Malawi: 422.47,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 187.24
  },
  1993: {
    Afghanistan: "n/a",
    Ethiopia: 176.47,
    Lebanon: 2289.63,
    Pakistan: 582.26,
    Syria: 995.06,
    Venezuela: 2876.08,
    Luxembourg: 39643.66,
    Ireland: 14262.8,
    Switzerland: 39386.14,
    "Macao SAR": "n/a",
    Norway: 27880.63,
    "United States": 26364.19,
    Denmark: 27640.5,
    Iceland: 23749.38,
    Singapore: 18290.13,
    Australia: 17425.03,
    Sweden: 24351.05,
    Netherlands: 23356.33,
    Austria: 24152.66,
    Qatar: 11797.16,
    Finland: 17661.59,
    Canada: 20210.45,
    Germany: 25602.96,
    Belgium: 21723.62,
    "San Marino": "n/a",
    "Hong Kong SAR": 20065.68,
    "United Kingdom": 19997.19,
    Israel: 14107.14,
    "New Zealand": 12536.07,
    France: 23059.19,
    Japan: 36425.22,
    "United Arab Emirates": 25654.37,
    Andorra: "n/a",
    "Taiwan Province of China": 11256.71,
    Malta: "n/a",
    Korea: 8886.39,
    Italy: 18548.32,
    Slovenia: 8357.94,
    Spain: 13409.7,
    Estonia: 1194.19,
    "Puerto Rico": 10255.53,
    Cyprus: 11342.56,
    "The Bahamas": 18979.01,
    "Czech Republic": "n/a",
    "Brunei Darussalam": 16611.62,
    Aruba: "n/a",
    Portugal: 9547.64,
    Lithuania: "n/a",
    Kuwait: 16213.49,
    Bahrain: 11290.27,
    "Slovak Republic": 2571.86,
    Latvia: 1021.78,
    Hungary: 3871.07,
    "Saudi Arabia": 7685.99,
    Greece: 10363.63,
    Poland: 2351.74,
    Seychelles: 6559.09,
    Croatia: 2786.37,
    Romania: 1147.33,
    "St. Kitts and Nevis": 5806.09,
    Barbados: 7886.78,
    Chile: 3547.02,
    Uruguay: 5663.12,
    Panama: 2963.32,
    "Antigua and Barbuda": 8474.04,
    "Trinidad and Tobago": 3743.58,
    Maldives: 1479.45,
    Oman: 6102.67,
    Palau: "n/a",
    Guyana: 1392.98,
    China: 520.97,
    Bulgaria: 649.65,
    Malaysia: 3716.95,
    "Islamic Republic of Iran": 1137.1,
    Turkey: 4261.64,
    "Costa Rica": 2926.91,
    Turkmenistan: 2288.15,
    "St. Lucia": 4922.71,
    Russia: 1322.01,
    Mauritius: 3148.8,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 3153.81,
    Kazakhstan: 304.85,
    Mexico: 5636.36,
    "Dominican Republic": 1741.67,
    Argentina: 7796.24,
    Dominica: 3448.67,
    Brazil: 2787.16,
    Nauru: "n/a",
    Thailand: 2209.36,
    Gabon: 5677.62,
    "St. Vincent and the Grenadines": 2689.6,
    Botswana: 2709.97,
    "North Macedonia": 1383.54,
    "Bosnia and Herzegovina": "n/a",
    Belarus: 1114.1,
    Tuvalu: "n/a",
    "South Africa": 3716.2,
    Peru: 1487.86,
    Albania: 452.62,
    "Equatorial Guinea": 343.49,
    Georgia: "n/a",
    Moldova: 373.93,
    Colombia: 2208.31,
    Fiji: 2339.82,
    Ukraine: 677.26,
    Kosovo: "n/a",
    Armenia: 247.88,
    Ecuador: 1572.84,
    Jamaica: 2249.32,
    Tonga: 1884.49,
    Paraguay: 1580.21,
    Vietnam: 234.98,
    Iraq: "n/a",
    Mongolia: 411.79,
    Indonesia: 1013.06,
    Azerbaijan: 174.88,
    Guatemala: 1110,
    Jordan: 1361.59,
    Suriname: 1093.09,
    Egypt: 897.2,
    Namibia: 1922.82,
    Belize: 2731.02,
    "Marshall Islands": "n/a",
    Djibouti: 1057.62,
    "El Salvador": 1216.7,
    Bhutan: 470.84,
    Eswatini: 1750.16,
    Samoa: "n/a",
    "Cabo Verde": 1046.27,
    "Sri Lanka": 666.57,
    Micronesia: "n/a",
    Libya: 6780.86,
    Philippines: 949.73,
    Tunisia: 1814.88,
    Morocco: 1239.05,
    Bolivia: 793.65,
    Nigeria: 553.13,
    "West Bank and Gaza": "n/a",
    Vanuatu: 1277.01,
    Algeria: 1894.95,
    "Papua New Guinea": 1713.71,
    "Lao P.D.R.": 578.47,
    "CÙte d'Ivoire": 1142.98,
    Bangladesh: 335.75,
    Honduras: 888.61,
    "Solomon Islands": 885.84,
    Ghana: 881.06,
    India: 306.44,
    "São Tomé and Príncipe": 1033.02,
    Uzbekistan: 315.61,
    "Republic of Congo": 1249.63,
    Kenya: 663.43,
    Angola: 489.76,
    Mauritania: 852.51,
    Nicaragua: "n/a",
    Cambodia: 242.48,
    Senegal: 852.61,
    Kiribati: 623.58,
    Cameroon: 1132.11,
    Benin: 579.43,
    Zimbabwe: 702.44,
    Haiti: 147.03,
    Comoros: 916.7,
    Nepal: 232.01,
    "Kyrgyz Republic": 148.16,
    "Timor-Leste": "n/a",
    Guinea: 639.33,
    Tanzania: 190.68,
    Myanmar: "n/a",
    Uganda: 247.11,
    Togo: 543.29,
    Lesotho: 465.4,
    Zambia: 405.59,
    "Burkina Faso": 376.71,
    Mali: 379.88,
    "Guinea-Bissau": 636.88,
    Tajikistan: 121.65,
    "The Gambia": 840.42,
    Rwanda: 325.86,
    Sudan: 186.51,
    Niger: 343.18,
    Chad: 251.1,
    Liberia: "n/a",
    Eritrea: 167.08,
    "Democratic Republic of the Congo": 1222.44,
    Yemen: 1558.43,
    "Central African Republic": 418.92,
    Mozambique: 189.94,
    Madagascar: 320.56,
    "Sierra Leone": 259.19,
    Malawi: 483.26,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 162.57
  },
  1994: {
    Afghanistan: "n/a",
    Ethiopia: 151.97,
    Lebanon: 2640.18,
    Pakistan: 572.26,
    Syria: 1060.71,
    Venezuela: 2741.98,
    Luxembourg: 43483.05,
    Ireland: 15455.35,
    Switzerland: 43241.99,
    "Macao SAR": "n/a",
    Norway: 29236.38,
    "United States": 27674.02,
    Denmark: 30050.88,
    Iceland: 24142.7,
    Singapore: 21552.41,
    Australia: 19703.54,
    Sweden: 25978.29,
    Netherlands: 24935.53,
    Austria: 25725.56,
    Qatar: 12009.4,
    Finland: 20429.39,
    Canada: 20024.58,
    Germany: 27233.63,
    Belgium: 23624.47,
    "San Marino": "n/a",
    "Hong Kong SAR": 22194.01,
    "United Kingdom": 21425.4,
    Israel: 15698.65,
    "New Zealand": 14607.51,
    France: 24215.69,
    Japan: 39953.2,
    "United Arab Emirates": 25762.53,
    Andorra: "n/a",
    "Taiwan Province of China": 12099.75,
    Malta: "n/a",
    Korea: 10381.18,
    Italy: 19124.22,
    Slovenia: 8468.24,
    Spain: 13419.62,
    Estonia: 1711.27,
    "Puerto Rico": 10942.95,
    Cyprus: 12525.48,
    "The Bahamas": 19660.92,
    "Czech Republic": "n/a",
    "Brunei Darussalam": 16089.63,
    Aruba: "n/a",
    Portugal: 9977.62,
    Lithuania: "n/a",
    Kuwait: 13549.78,
    Bahrain: 11777.07,
    "Slovak Republic": 2950.87,
    Latvia: 1724.21,
    Hungary: 4170.57,
    "Saudi Arabia": 7626.36,
    Greece: 11029.1,
    Poland: 2692.7,
    Seychelles: 6555.43,
    Croatia: 3809.89,
    Romania: 1317.05,
    "St. Kitts and Nevis": 6407.52,
    Barbados: 8194.38,
    Chile: 4030.06,
    Uruguay: 6534.6,
    Panama: 3095.16,
    "Antigua and Barbuda": 9097.24,
    "Trinidad and Tobago": 4027.42,
    Maldives: 1591.14,
    Oman: 6116.03,
    Palau: "n/a",
    Guyana: 1626.9,
    China: 468.66,
    Bulgaria: 1155.8,
    Malaysia: 4027.51,
    "Islamic Republic of Iran": 1413.21,
    Turkey: 3026.72,
    "Costa Rica": 3110.15,
    Turkmenistan: 1888.79,
    "St. Lucia": 5029.44,
    Russia: 1979.41,
    Mauritius: 3446.31,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 3272.55,
    Kazakhstan: 751.98,
    Mexico: 5841.02,
    "Dominican Republic": 1916.44,
    Argentina: 8378.74,
    Dominica: 3713.11,
    Brazil: 3495.24,
    Nauru: "n/a",
    Thailand: 2491.43,
    Gabon: 4293.94,
    "St. Vincent and the Grenadines": 2719.63,
    Botswana: 2839.75,
    "North Macedonia": 1824.42,
    "Bosnia and Herzegovina": "n/a",
    Belarus: 1533.72,
    Tuvalu: "n/a",
    "South Africa": 3786.21,
    Peru: 1839.23,
    Albania: 736.03,
    "Equatorial Guinea": 245.88,
    Georgia: 172.5,
    Moldova: 389.59,
    Colombia: 2725.71,
    Fiji: 2590.93,
    Ukraine: 740.97,
    Kosovo: "n/a",
    Armenia: 196.98,
    Ecuador: 1858.32,
    Jamaica: 2234.8,
    Tonga: 1948.14,
    Paraguay: 1681.4,
    Vietnam: 285.95,
    Iraq: "n/a",
    Mongolia: 489.98,
    Indonesia: 1115.61,
    Azerbaijan: 297.16,
    Guatemala: 1228.97,
    Jordan: 1453.79,
    Suriname: 1213.31,
    Egypt: 968.91,
    Namibia: 2100.28,
    Belize: 2752.91,
    "Marshall Islands": "n/a",
    Djibouti: 1108.89,
    "El Salvador": 1380.71,
    Bhutan: 484.43,
    Eswatini: 1859.52,
    Samoa: "n/a",
    "Cabo Verde": 1180.49,
    "Sri Lanka": 761.61,
    Micronesia: "n/a",
    Libya: 6192.59,
    Philippines: 1094.54,
    Tunisia: 1903.85,
    Morocco: 1369.64,
    Bolivia: 805.18,
    Nigeria: 760.99,
    "West Bank and Gaza": 1227.24,
    Vanuatu: 1422.85,
    Algeria: 1542.97,
    "Papua New Guinea": 1834.98,
    "Lao P.D.R.": 654.37,
    "CÙte d'Ivoire": 835.24,
    Bangladesh: 349.36,
    Honduras: 845.74,
    "Solomon Islands": 1153.33,
    Ghana: 793.78,
    India: 352.17,
    "São Tomé and Príncipe": 1058.93,
    Uzbekistan: 366.87,
    "Republic of Congo": 839.5,
    Kenya: 771.9,
    Angola: 346.24,
    Mauritania: 872.71,
    Nicaragua: 898.2,
    Cambodia: 265.06,
    Senegal: 560.96,
    Kiribati: 716.42,
    Cameroon: 748,
    Benin: 389.73,
    Zimbabwe: 714.03,
    Haiti: 397.21,
    Comoros: 660.36,
    Nepal: 235.99,
    "Kyrgyz Republic": 245.85,
    "Timor-Leste": "n/a",
    Guinea: 626.43,
    Tanzania: 195.66,
    Myanmar: "n/a",
    Uganda: 346.26,
    Togo: 411.81,
    Lesotho: 480.69,
    Zambia: 405.54,
    "Burkina Faso": 220.47,
    Mali: 283.34,
    "Guinea-Bissau": 344.12,
    Tajikistan: 147.92,
    "The Gambia": 804.33,
    Rwanda: 223.45,
    Sudan: 205.92,
    Niger: 211.02,
    Chad: 196.9,
    Liberia: "n/a",
    Eritrea: 195.44,
    "Democratic Republic of the Congo": 639.61,
    Yemen: 1919.18,
    "Central African Republic": 268.11,
    Mozambique: 187.08,
    Madagascar: 269.56,
    "Sierra Leone": 308.75,
    Malawi: 278.15,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 157.47
  },
  1995: {
    Afghanistan: "n/a",
    Ethiopia: 152.66,
    Lebanon: 3108.25,
    Pakistan: 652.37,
    Syria: 1133.16,
    Venezuela: 3559.77,
    Luxembourg: 50911.34,
    Ireland: 19086.8,
    Switzerland: 50329.76,
    "Macao SAR": "n/a",
    Norway: 34790,
    "United States": 28671.48,
    Denmark: 35471.26,
    Iceland: 26711.55,
    Singapore: 24914.85,
    Australia: 20868.82,
    Sweden: 30246.92,
    Netherlands: 29350.8,
    Austria: 30350.53,
    Qatar: 13689.65,
    Finland: 26347.51,
    Canada: 20706.73,
    Germany: 31829.61,
    Belgium: 28458.47,
    "San Marino": "n/a",
    "Hong Kong SAR": 23070.54,
    "United Kingdom": 23125.86,
    Israel: 18154.26,
    "New Zealand": 16938.02,
    France: 27721.67,
    Japan: 44210.24,
    "United Arab Emirates": 26394.42,
    Andorra: "n/a",
    "Taiwan Province of China": 13066.13,
    Malta: 8978.86,
    Korea: 12565.03,
    Italy: 20675.32,
    Slovenia: 10748.46,
    Spain: 15475.61,
    Estonia: 2721.01,
    "Puerto Rico": 11667.59,
    Cyprus: 15397.12,
    "The Bahamas": 20271.25,
    "Czech Republic": 5820.04,
    "Brunei Darussalam": 18233.96,
    Aruba: 16548.72,
    Portugal: 11787.32,
    Lithuania: 1845.1,
    Kuwait: 13871.99,
    Bahrain: 12143.09,
    "Slovak Republic": 3711.96,
    Latvia: 2162.49,
    Hungary: 4491.22,
    "Saudi Arabia": 7893.41,
    Greece: 12887.89,
    Poland: 3612.26,
    Seychelles: 6748.83,
    Croatia: 4995.31,
    Romania: 1563.12,
    "St. Kitts and Nevis": 6819.77,
    Barbados: 8414.12,
    Chile: 5117.7,
    Uruguay: 7171.51,
    Panama: 3099.92,
    "Antigua and Barbuda": 8675.24,
    "Trinidad and Tobago": 4322.26,
    Maldives: 1903.13,
    Oman: 6355.28,
    Palau: "n/a",
    Guyana: 1832.4,
    China: 603.53,
    Bulgaria: 1958.07,
    Malaysia: 4678.02,
    "Islamic Republic of Iran": 1990.36,
    Turkey: 3880.86,
    "Costa Rica": 3336.71,
    Turkmenistan: 2388.87,
    "St. Lucia": 5271.84,
    Russia: 2264.34,
    Mauritius: 3902.22,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 3411.98,
    Kazakhstan: 1061.48,
    Mexico: 3920.74,
    "Dominican Republic": 2141.06,
    Argentina: 8295.12,
    Dominica: 3855.29,
    Brazil: 4851.99,
    Nauru: "n/a",
    Thailand: 2846.59,
    Gabon: 4956.84,
    "St. Vincent and the Grenadines": 2969.98,
    Botswana: 3075.19,
    "North Macedonia": 2387.16,
    "Bosnia and Herzegovina": "n/a",
    Belarus: 1034.93,
    Tuvalu: "n/a",
    "South Africa": 4145.21,
    Peru: 2147.43,
    Albania: 903.99,
    "Equatorial Guinea": 333.85,
    Georgia: 413.49,
    Moldova: 485.35,
    Colombia: 3034.09,
    Fiji: 2775.12,
    Ukraine: 752.34,
    Kosovo: "n/a",
    Armenia: 399.58,
    Ecuador: 1980.37,
    Jamaica: 2660.15,
    Tonga: 2123.28,
    Paraguay: 1897.13,
    Vietnam: 358.66,
    Iraq: "n/a",
    Mongolia: 756.76,
    Indonesia: 1254.02,
    Azerbaijan: 316.23,
    Guatemala: 1348.88,
    Jordan: 1499.56,
    Suriname: 2270.81,
    Egypt: 1098.09,
    Namibia: 2197.59,
    Belize: 2864.39,
    "Marshall Islands": "n/a",
    Djibouti: 1108.22,
    "El Salvador": 1585.11,
    Bhutan: 569.74,
    Eswatini: 2171.72,
    Samoa: "n/a",
    "Cabo Verde": 1378.16,
    "Sri Lanka": 802.2,
    Micronesia: 2094.48,
    Libya: 6882.21,
    Philippines: 1237.31,
    Tunisia: 2156.45,
    Morocco: 1479.28,
    Bolivia: 886.37,
    Nigeria: 1224.94,
    "West Bank and Gaza": 1322.06,
    Vanuatu: 1479.57,
    Algeria: 1499.14,
    "Papua New Guinea": 1581.63,
    "Lao P.D.R.": 741.54,
    "CÙte d'Ivoire": 1073.95,
    Bangladesh: 381.78,
    Honduras: 948.44,
    "Solomon Islands": 1306.62,
    Ghana: 918.73,
    India: 380.32,
    "São Tomé and Príncipe": 820.59,
    Uzbekistan: 561.75,
    "Republic of Congo": 973.01,
    Kenya: 951.64,
    Angola: 419.53,
    Mauritania: 912.59,
    Nicaragua: 935.34,
    Cambodia: 319.54,
    Senegal: 693.42,
    Kiribati: 721.09,
    Cameroon: 690.15,
    Benin: 506.86,
    Zimbabwe: 717.09,
    Haiti: 618.38,
    Comoros: 806.81,
    Nepal: 249.86,
    "Kyrgyz Republic": 329.93,
    "Timor-Leste": "n/a",
    Guinea: 655.75,
    Tanzania: 221.37,
    Myanmar: "n/a",
    Uganda: 401.89,
    Togo: 530.12,
    Lesotho: 514.12,
    Zambia: 410.54,
    "Burkina Faso": 265.24,
    Mali: 357.52,
    "Guinea-Bissau": 725.82,
    Tajikistan: 100.45,
    "The Gambia": 815.29,
    Rwanda: 245.28,
    Sudan: 239.51,
    Niger: 241.95,
    Chad: 233.42,
    Liberia: "n/a",
    Eritrea: 219.09,
    "Democratic Republic of the Congo": 601.8,
    Yemen: 840.83,
    "Central African Republic": 340.99,
    Mozambique: 187.3,
    Madagascar: 284.82,
    "Sierra Leone": 295.71,
    Malawi: 319.89,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 167.18
  },
  1996: {
    Afghanistan: "n/a",
    Ethiopia: 155.12,
    Lebanon: 3550.64,
    Pakistan: 664.78,
    Syria: 1186.16,
    Venezuela: 3174.63,
    Luxembourg: 49921.11,
    Ireland: 20781.39,
    Switzerland: 48254.48,
    "Macao SAR": "n/a",
    Norway: 37225.29,
    "United States": 29946.97,
    Denmark: 35732.69,
    Iceland: 27914.45,
    Singapore: 26232.87,
    Australia: 23106.51,
    Sweden: 32986.12,
    Netherlands: 29084.07,
    Austria: 29820.6,
    Qatar: 15143.48,
    Finland: 25833.03,
    Canada: 21325.69,
    Germany: 30664.39,
    Belgium: 27535.29,
    "San Marino": "n/a",
    "Hong Kong SAR": 24698.96,
    "United Kingdom": 24359.37,
    Israel: 19391.93,
    "New Zealand": 18504.23,
    France: 27716.21,
    Japan: 39164.32,
    "United Arab Emirates": 29058.86,
    Andorra: "n/a",
    "Taiwan Province of China": 13588.31,
    Malta: 9107.64,
    Korea: 13402.93,
    Italy: 23094.4,
    Slovenia: 10810.79,
    Spain: 16095.75,
    Estonia: 3379.95,
    "Puerto Rico": 12305.02,
    Cyprus: 15260.55,
    "The Bahamas": 20974.54,
    "Czech Republic": 6529.04,
    "Brunei Darussalam": 19221.09,
    Aruba: 16620.85,
    Portugal: 12187.58,
    Lithuania: 2327.5,
    Kuwait: 15068.13,
    Bahrain: 12298.65,
    "Slovak Republic": 4004.8,
    Latvia: 2417.08,
    Hungary: 4520.76,
    "Saudi Arabia": 8527.67,
    Greece: 13660.51,
    Poland: 4066.18,
    Seychelles: 6583.09,
    Croatia: 5217.74,
    Romania: 1565.47,
    "St. Kitts and Nevis": 7195.17,
    Barbados: 8938.57,
    Chile: 5349.58,
    Uruguay: 7577.01,
    Panama: 3580.93,
    "Antigua and Barbuda": 9266.47,
    "Trinidad and Tobago": 4658.87,
    Maldives: 2107.61,
    Oman: 6864.96,
    Palau: "n/a",
    Guyana: 2048.53,
    China: 703.06,
    Bulgaria: 1494.79,
    Malaysia: 5175.56,
    "Islamic Republic of Iran": 2694.67,
    Turkey: 4099.23,
    "Costa Rica": 3277.61,
    Turkmenistan: 949.57,
    "St. Lucia": 5363.89,
    Russia: 2787.02,
    Mauritius: 3993.48,
    Serbia: "n/a",
    Montenegro: "n/a",
    Grenada: 3632.68,
    Kazakhstan: 1358.8,
    Mexico: 4405.11,
    "Dominican Republic": 2309.92,
    Argentina: 8645.46,
    Dominica: 4104.42,
    Brazil: 5204.57,
    Nauru: "n/a",
    Thailand: 3043.98,
    Gabon: 5552.92,
    "St. Vincent and the Grenadines": 3116.2,
    Botswana: 3097.82,
    "North Macedonia": 2348.11,
    "Bosnia and Herzegovina": 952.33,
    Belarus: 1429.06,
    Tuvalu: "n/a",
    "South Africa": 3869.7,
    Peru: 2193.69,
    Albania: 1009.97,
    "Equatorial Guinea": 527.12,
    Georgia: 688.64,
    Moldova: 572.3,
    Colombia: 3135.8,
    Fiji: 2975.91,
    Ukraine: 914.35,
    Kosovo: "n/a",
    Armenia: 503.77,
    Ecuador: 2037.13,
    Jamaica: 2975.13,
    Tonga: 2228.29,
    Paraguay: 2008.19,
    Vietnam: 419.06,
    Iraq: "n/a",
    Mongolia: 692.47,
    Indonesia: 1394.5,
    Azerbaijan: 411.14,
    Guatemala: 1408.23,
    Jordan: 1514.85,
    Suriname: 2790.05,
    Egypt: 1209.49,
    Namibia: 2126.35,
    Belize: 2889.12,
    "Marshall Islands": "n/a",
    Djibouti: 1077.27,
    "El Salvador": 1684.79,
    Bhutan: 594.21,
    Eswatini: 2012.45,
    Samoa: "n/a",
    "Cabo Verde": 1385.77,
    "Sri Lanka": 873.9,
    Micronesia: 2060.87,
    Libya: 7372.45,
    Philippines: 1316.41,
    Tunisia: 2299.38,
    Morocco: 1612.85,
    Bolivia: 956.97,
    Nigeria: 1560.38,
    "West Bank and Gaza": 1296.08,
    Vanuatu: 1511.71,
    Algeria: 1643.27,
    "Papua New Guinea": 1639.77,
    "Lao P.D.R.": 753.99,
    "CÙte d'Ivoire": 1152.48,
    Bangladesh: 399.7,
    Honduras: 888.01,
    "Solomon Islands": 1381.75,
    Ghana: 950,
    India: 406.97,
    "São Tomé and Príncipe": 1050.1,
    Uzbekistan: 754.53,
    "Republic of Congo": 1097.23,
    Kenya: 1047.97,
    Angola: 525.4,
    Mauritania: 904.33,
    Nicaragua: 947.14,
    Cambodia: 316.19,
    Senegal: 706.86,
    Kiribati: 847.06,
    Cameroon: 748.16,
    Benin: 533.49,
    Zimbabwe: 849.88,
    Haiti: 626.3,
    Comoros: 789.15,
    Nepal: 251.55,
    "Kyrgyz Republic": 396.5,
    "Timor-Leste": "n/a",
    Guinea: 656.71,
    Tanzania: 266.49,
    Myanmar: "n/a",
    Uganda: 401.41,
    Togo: 563.02,
    Lesotho: 490.55,
    Zambia: 378.71,
    "Burkina Faso": 280.45,
    Mali: 356.4,
    "Guinea-Bissau": 676.1,
    Tajikistan: 183.37,
    "The Gambia": 826.19,
    Rwanda: 268.93,
    Sudan: 292.91,
    Niger: 243.97,
    Chad: 250.86,
    Liberia: "n/a",
    Eritrea: 263.81,
    "Democratic Republic of the Congo": 750.74,
    Yemen: 411.27,
    "Central African Republic": 298.44,
    Mozambique: 241.65,
    Madagascar: 354.74,
    "Sierra Leone": 318.65,
    Malawi: 512.06,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 142.59
  },
  1997: {
    Afghanistan: "n/a",
    Ethiopia: 152.17,
    Lebanon: 4245.06,
    Pakistan: 639.94,
    Syria: 1080.81,
    Venezuela: 3778.54,
    Luxembourg: 44382.36,
    Ireland: 22468.61,
    Switzerland: 41697.28,
    "Macao SAR": "n/a",
    Norway: 36561.41,
    "United States": 31440.09,
    Denmark: 32897.57,
    Iceland: 28048.85,
    Singapore: 26375.87,
    Australia: 22989.52,
    Sweden: 30307.16,
    Netherlands: 26808.39,
    Austria: 26737.48,
    Qatar: 18841.6,
    Finland: 24759.98,
    Canada: 21930.49,
    Germany: 27170.75,
    Belgium: 24878.75,
    "San Marino": "n/a",
    "Hong Kong SAR": 27214.6,
    "United Kingdom": 26726.5,
    Israel: 19705.4,
    "New Zealand": 18214.21,
    France: 24999.79,
    Japan: 35651.27,
    "United Arab Emirates": 29523.49,
    Andorra: "n/a",
    "Taiwan Province of China": 13948.72,
    Malta: 9147.98,
    Korea: 12416.75,
    Italy: 21858.43,
    Slovenia: 10470.5,
    Spain: 14782.95,
    Estonia: 3686.89,
    "Puerto Rico": 12968.39,
    Cyprus: 14324.09,
    "The Bahamas": 21951.76,
    "Czech Republic": 6030.63,
    "Brunei Darussalam": 19060.15,
    Aruba: 17750.49,
    Portugal: 11594.76,
    Lithuania: 2830.27,
    Kuwait: 13740.68,
    Bahrain: 12422.12,
    "Slovak Republic": 4076.5,
    Latvia: 2667.91,
    Hungary: 4591.49,
    "Saudi Arabia": 8706.35,
    Greece: 13370.35,
    Poland: 4075.98,
    Seychelles: 7280.93,
    Croatia: 5209.72,
    Romania: 1571.69,
    "St. Kitts and Nevis": 8010.44,
    Barbados: 9412.37,
    Chile: 5743.72,
    Uruguay: 7925.92,
    Panama: 3791.27,
    "Antigua and Barbuda": 9678.32,
    "Trinidad and Tobago": 4632.81,
    Maldives: 2553.95,
    Oman: 6965.2,
    Palau: "n/a",
    Guyana: 2161.65,
    China: 774.91,
    Bulgaria: 1386.47,
    Malaysia: 5011.56,
    "Islamic Republic of Iran": 3042.98,
    Turkey: 4221.92,
    "Costa Rica": 3450.91,
    Turkmenistan: 1053.19,
    "St. Lucia": 5385.62,
    Russia: 2935.03,
    Mauritius: 3703.63,
    Serbia: 2927.95,
    Montenegro: "n/a",
    Grenada: 3861.38,
    Kazakhstan: 1459.42,
    Mexico: 5283.08,
    "Dominican Republic": 2495.03,
    Argentina: 9196.52,
    Dominica: 4254.37,
    Brazil: 5321.14,
    Nauru: "n/a",
    Thailand: 2468.18,
    Gabon: 5068.09,
    "St. Vincent and the Grenadines": 3270.01,
    Botswana: 3117.96,
    "North Macedonia": 1980.43,
    "Bosnia and Herzegovina": 1225.46,
    Belarus: 1396.11,
    Tuvalu: "n/a",
    "South Africa": 3937.66,
    Peru: 2272.35,
    Albania: 717.38,
    "Equatorial Guinea": 965.02,
    Georgia: 818.4,
    Moldova: 653.53,
    Colombia: 3397.95,
    Fiji: 2905.47,
    Ukraine: 1037.89,
    Kosovo: "n/a",
    Armenia: 521.87,
    Ecuador: 2253.23,
    Jamaica: 3347.37,
    Tonga: 2263.66,
    Paraguay: 2003.58,
    Vietnam: 449.34,
    Iraq: "n/a",
    Mongolia: 598.4,
    Indonesia: 1308.11,
    Azerbaijan: 508.04,
    Guatemala: 1559.64,
    Jordan: 1546.36,
    Suriname: 2945.34,
    Egypt: 1327.41,
    Namibia: 2143.73,
    Belize: 2844.85,
    "Marshall Islands": 2257.33,
    Djibouti: 1067.63,
    "El Salvador": 1778.84,
    Bhutan: 654.92,
    Eswatini: 2105.17,
    Samoa: "n/a",
    "Cabo Verde": 1322.34,
    "Sri Lanka": 971.24,
    Micronesia: 1944.21,
    Libya: 7424.95,
    Philippines: 1279.84,
    Tunisia: 2210.81,
    Morocco: 1442.43,
    Bolivia: 1006.1,
    Nigeria: 1655.82,
    "West Bank and Gaza": 1350.95,
    Vanuatu: 1537.62,
    Algeria: 1658.73,
    "Papua New Guinea": 1527.44,
    "Lao P.D.R.": 696.05,
    "CÙte d'Ivoire": 1084.69,
    Bangladesh: 407.25,
    Honduras: 948.72,
    "Solomon Islands": 1385.67,
    Ghana: 945.57,
    India: 422.81,
    "São Tomé and Príncipe": 700.97,
    Uzbekistan: 772.33,
    "Republic of Congo": 967.81,
    Kenya: 1034.12,
    Angola: 599.08,
    Mauritania: 854.32,
    Nicaragua: 940.14,
    Cambodia: 302.17,
    Senegal: 642.57,
    Kiribati: 839.09,
    Cameroon: 735.87,
    Benin: 497.34,
    Zimbabwe: 881.22,
    Haiti: 706.8,
    Comoros: 714.27,
    Nepal: 267.45,
    "Kyrgyz Republic": 379.06,
    "Timor-Leste": "n/a",
    Guinea: 628.19,
    Tanzania: 307.99,
    Myanmar: "n/a",
    Uganda: 423.92,
    Togo: 555.86,
    Lesotho: 516.86,
    Zambia: 440.7,
    "Burkina Faso": 258.11,
    Mali: 326.08,
    "Guinea-Bissau": 628.44,
    Tajikistan: 192.58,
    "The Gambia": 818.81,
    Rwanda: 345.66,
    Sudan: 350.72,
    Niger: 224.48,
    Chad: 232.97,
    Liberia: "n/a",
    Eritrea: 270.57,
    "Democratic Republic of the Congo": 657.55,
    Yemen: 418.53,
    "Central African Republic": 285.8,
    Mozambique: 283.51,
    Madagascar: 297.12,
    "Sierra Leone": 286.07,
    Malawi: 583.42,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 157.05
  },
  1998: {
    Afghanistan: "n/a",
    Ethiopia: 134.23,
    Lebanon: 4617.16,
    Pakistan: 622.12,
    Syria: 1028.17,
    Venezuela: 3970.43,
    Luxembourg: 45822.19,
    Ireland: 24202.5,
    Switzerland: 42809.62,
    "Macao SAR": "n/a",
    Norway: 34717.06,
    "United States": 32833.67,
    Denmark: 33426.97,
    Iceland: 31219.8,
    Singapore: 21829.28,
    Australia: 20347.56,
    Sweden: 30585.08,
    Netherlands: 28018.71,
    Austria: 27399.09,
    Qatar: 15421.78,
    Finland: 26074.07,
    Canada: 21046.56,
    Germany: 27528.16,
    Belgium: 25399.22,
    "San Marino": "n/a",
    "Hong Kong SAR": 25649.09,
    "United Kingdom": 28247.54,
    Israel: 19439.25,
    "New Zealand": 14885.53,
    France: 25783.48,
    Japan: 32436.92,
    "United Arab Emirates": 25897.65,
    Andorra: "n/a",
    "Taiwan Province of China": 12767.08,
    Malta: 9621.95,
    Korea: 8271.41,
    Italy: 22348.03,
    Slovenia: 11168.22,
    Spain: 15457.11,
    Estonia: 4096.13,
    "Puerto Rico": 14430.16,
    Cyprus: 15181.64,
    "The Bahamas": 23300.8,
    "Czech Republic": 6484.2,
    "Brunei Darussalam": 14479.97,
    Aruba: 18825.01,
    Portugal: 12216.69,
    Lithuania: 3166.72,
    Kuwait: 11424.92,
    Bahrain: 11565.97,
    "Slovak Republic": 4236.59,
    Latvia: 2958.62,
    Hungary: 4738.02,
    "Saudi Arabia": 7525.3,
    Greece: 13412.55,
    Poland: 4459.1,
    Seychelles: 7715.78,
    Croatia: 5594.8,
    Romania: 1885.35,
    "St. Kitts and Nevis": 8260.89,
    Barbados: 10573.11,
    Chile: 5446.55,
    Uruguay: 8329.08,
    Panama: 4027.32,
    "Antigua and Barbuda": 10074.75,
    "Trinidad and Tobago": 4872.62,
    Maldives: 2658.8,
    Oman: 6037.75,
    Palau: "n/a",
    Guyana: 2035.86,
    China: 820.9,
    Bulgaria: 1854.47,
    Malaysia: 3519.77,
    "Islamic Republic of Iran": 3312.42,
    Turkey: 4384.47,
    "Costa Rica": 3653.15,
    Turkmenistan: 1109.02,
    "St. Lucia": 5773.04,
    Russia: 1949.8,
    Mauritius: 3701.87,
    Serbia: 2302.29,
    Montenegro: "n/a",
    Grenada: 4370.62,
    Kazakhstan: 1480.09,
    Mexico: 5477.93,
    "Dominican Republic": 2658.54,
    Argentina: 9283.18,
    Dominica: 4526.68,
    Brazil: 5123.55,
    Nauru: "n/a",
    Thailand: 1845.83,
    Gabon: 4161.62,
    "St. Vincent and the Grenadines": 3513.87,
    Botswana: 2928.59,
    "North Macedonia": 1888.76,
    "Bosnia and Herzegovina": 1415.1,
    Belarus: 1514.67,
    Tuvalu: "n/a",
    "South Africa": 3508.14,
    Peru: 2141.11,
    Albania: 818.32,
    "Equatorial Guinea": 777.25,
    Georgia: 864.47,
    Moldova: 575.97,
    Colombia: 3089.65,
    Fiji: 2282.25,
    Ukraine: 874.27,
    Kosovo: "n/a",
    Armenia: 608.42,
    Ecuador: 2257.86,
    Jamaica: 3467.54,
    Tonga: 2153.25,
    Paraguay: 1824.65,
    Vietnam: 448.12,
    Iraq: "n/a",
    Mongolia: 562.12,
    Indonesia: 572.09,
    Azerbaijan: 543.34,
    Guatemala: 1656.36,
    Jordan: 1655.73,
    Suriname: 3495.32,
    Egypt: 1454.96,
    Namibia: 1837.31,
    Belize: 2888.86,
    "Marshall Islands": 2276.74,
    Djibouti: 1060.78,
    "El Salvador": 1886.36,
    Bhutan: 701.8,
    Eswatini: 1914.24,
    Samoa: 1533.12,
    "Cabo Verde": 1376.76,
    "Sri Lanka": 992.72,
    Micronesia: 2054.62,
    Libya: 5980.04,
    Philippines: 988.49,
    Tunisia: 2295.19,
    Morocco: 1519.17,
    Bolivia: 1057.72,
    Nigeria: 1802.59,
    "West Bank and Gaza": 1416.58,
    Vanuatu: 1441.11,
    Algeria: 1633.09,
    "Papua New Guinea": 1138.37,
    "Lao P.D.R.": 259.3,
    "CÙte d'Ivoire": 1137.46,
    Bangladesh: 416.99,
    Honduras: 1023.4,
    "Solomon Islands": 1171.2,
    Ghana: 1002.59,
    India: 420.57,
    "São Tomé and Príncipe": 541.27,
    Uzbekistan: 773.32,
    "Republic of Congo": 823.56,
    Kenya: 1096.62,
    Angola: 493.04,
    Mauritania: 812.21,
    Nicaragua: 973.07,
    Cambodia: 267.86,
    Senegal: 686.37,
    Kiribati: 805.03,
    Cameroon: 714.92,
    Benin: 519.56,
    Zimbabwe: 1029.2,
    Haiti: 773.84,
    Comoros: 701.45,
    Nepal: 260.46,
    "Kyrgyz Republic": 344.4,
    "Timor-Leste": "n/a",
    Guinea: 591.51,
    Tanzania: 370.19,
    Myanmar: 116.22,
    Uganda: 402.46,
    Togo: 509.65,
    Lesotho: 475.87,
    Zambia: 352.63,
    "Burkina Faso": 287.6,
    Mali: 329.65,
    "Guinea-Bissau": 517.66,
    Tajikistan: 222.28,
    "The Gambia": 815.33,
    Rwanda: 338.31,
    Sudan: 366.58,
    Niger: 250.23,
    Chad: 254.12,
    Liberia: "n/a",
    Eritrea: 283.92,
    "Democratic Republic of the Congo": 470.22,
    Yemen: 374.91,
    "Central African Republic": 296.1,
    Mozambique: 313.06,
    Madagascar: 297.25,
    "Sierra Leone": 223.94,
    Malawi: 373.08,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 141.76
  },
  1999: {
    Afghanistan: "n/a",
    Ethiopia: 128.21,
    Lebanon: 4580.86,
    Pakistan: 575.7,
    Syria: 1043.17,
    Venezuela: 4133.37,
    Luxembourg: 49541.93,
    Ireland: 26233.64,
    Switzerland: 41857.87,
    "Macao SAR": "n/a",
    Norway: 36278.34,
    "United States": 34496.24,
    Denmark: 33492.35,
    Iceland: 32577.64,
    Singapore: 21796.64,
    Australia: 21727.09,
    Sweden: 30928.57,
    Netherlands: 28393.85,
    Austria: 27210.47,
    Qatar: 19689.65,
    Finland: 26241.8,
    Canada: 22340.55,
    Germany: 26984.24,
    Belgium: 25309.06,
    "San Marino": "n/a",
    "Hong Kong SAR": 24969.01,
    "United Kingdom": 28672.04,
    Israel: 19152.87,
    "New Zealand": 15340.08,
    France: 25517.13,
    Japan: 36622.89,
    "United Arab Emirates": 27321.04,
    Andorra: "n/a",
    "Taiwan Province of China": 13752.72,
    Malta: 9983.88,
    Korea: 10666.86,
    Italy: 22029.7,
    Slovenia: 11509.46,
    Spain: 15814.19,
    Estonia: 4151.43,
    "Puerto Rico": 15293.18,
    Cyprus: 15379.99,
    "The Bahamas": 25780.48,
    "Czech Republic": 6333.86,
    "Brunei Darussalam": 16060.9,
    Aruba: 19215.01,
    Portugal: 12487.71,
    Lithuania: 3113.27,
    Kuwait: 13358.13,
    Bahrain: 12210.22,
    "Slovak Republic": 3869.05,
    Latvia: 3137.09,
    Hungary: 4786.25,
    "Saudi Arabia": 8092.71,
    Greece: 13784.36,
    Poland: 4348.15,
    Seychelles: 7747.49,
    Croatia: 5099.54,
    Romania: 1600.88,
    "St. Kitts and Nevis": 8670.83,
    Barbados: 11039.1,
    Chile: 4952.87,
    Uruguay: 7814.55,
    Panama: 4136.06,
    "Antigua and Barbuda": 10349.52,
    "Trinidad and Tobago": 5478.86,
    Maldives: 2842.66,
    Oman: 6605.92,
    Palau: "n/a",
    Guyana: 2010.77,
    China: 865.24,
    Bulgaria: 1689.29,
    Malaysia: 3762.77,
    "Islamic Republic of Iran": 4332.75,
    Turkey: 4020.27,
    "Costa Rica": 3715.41,
    Turkmenistan: 1476.5,
    "St. Lucia": 5998.05,
    Russia: 1427.31,
    Mauritius: 3859.15,
    Serbia: 1508.72,
    Montenegro: "n/a",
    Grenada: 4705.81,
    Kazakhstan: 1132.13,
    Mexico: 6157.25,
    "Dominican Republic": 2674.83,
    Argentina: 8709.07,
    Dominica: 4657.56,
    Brazil: 3501.44,
    Nauru: "n/a",
    Thailand: 2031.17,
    Gabon: 4222.73,
    "St. Vincent and the Grenadines": 3675.55,
    Botswana: 3270.46,
    "North Macedonia": 1931.99,
    "Bosnia and Herzegovina": 1540.51,
    Belarus: 1210.97,
    Tuvalu: "n/a",
    "South Africa": 3423.6,
    Peru: 1903.93,
    Albania: 1032.26,
    "Equatorial Guinea": 1250.99,
    Georgia: 687.03,
    Moldova: 398.41,
    Colombia: 2672.58,
    Fiji: 2656.26,
    Ukraine: 664.99,
    Kosovo: "n/a",
    Armenia: 597.24,
    Ecuador: 1598.77,
    Jamaica: 3473.44,
    Tonga: 1994.28,
    Paraguay: 1706.4,
    Vietnam: 465.25,
    Iraq: "n/a",
    Mongolia: 520.78,
    Indonesia: 829.57,
    Azerbaijan: 576.01,
    Guatemala: 1527.24,
    Jordan: 1675.7,
    Suriname: 2837.25,
    Egypt: 1518.2,
    Namibia: 1874.47,
    Belize: 3014.68,
    "Marshall Islands": 2269.42,
    Djibouti: 1084.06,
    "El Salvador": 1930.62,
    Bhutan: 716.17,
    Eswatini: 1861.5,
    Samoa: 1469.93,
    "Cabo Verde": 1541.83,
    "Sri Lanka": 992.28,
    Micronesia: 2061.64,
    Libya: 7067.88,
    Philippines: 1112.35,
    Tunisia: 2388.99,
    Morocco: 1492.18,
    Bolivia: 1010.58,
    Nigeria: 481.95,
    "West Bank and Gaza": 1441.89,
    Vanuatu: 1435.66,
    Algeria: 1630.07,
    "Papua New Guinea": 1013.23,
    "Lao P.D.R.": 270.23,
    "CÙte d'Ivoire": 1088.34,
    Bangladesh: 424.23,
    Honduras: 1003.07,
    "Solomon Islands": 1215.24,
    Ghana: 1012.27,
    India: 449.73,
    "São Tomé and Príncipe": 568.5,
    Uzbekistan: 868.99,
    "Republic of Congo": 949.35,
    Kenya: 972.18,
    Angola: 452.68,
    Mauritania: 773.41,
    Nicaragua: 999.41,
    Cambodia: 293.7,
    Senegal: 689.68,
    Kiribati: 832.62,
    Cameroon: 708.66,
    Benin: 552.37,
    Zimbabwe: 1003.83,
    Haiti: 847.95,
    Comoros: 703.07,
    Nepal: 262.75,
    "Kyrgyz Republic": 258.47,
    "Timor-Leste": "n/a",
    Guinea: 557.83,
    Tanzania: 370.92,
    Myanmar: 143.15,
    Uganda: 370.52,
    Togo: 495.79,
    Lesotho: 466.51,
    Zambia: 330.28,
    "Burkina Faso": 300.31,
    Mali: 332.43,
    "Guinea-Bissau": 496.41,
    Tajikistan: 177.71,
    "The Gambia": 811.26,
    Rwanda: 284.17,
    Sudan: 352.44,
    Niger: 231.66,
    Chad: 215.94,
    Liberia: "n/a",
    Eritrea: 274.58,
    "Democratic Republic of the Congo": 416.98,
    Yemen: 439.29,
    "Central African Republic": 290.99,
    Mozambique: 346.57,
    Madagascar: 279.92,
    "Sierra Leone": 218.91,
    Malawi: 368.27,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 133.41
  },
  2000: {
    Afghanistan: "n/a",
    Ethiopia: 129.68,
    Lebanon: 4427.22,
    Pakistan: 579.17,
    Syria: 1202.89,
    Venezuela: 4820.65,
    Luxembourg: 49183.43,
    Ireland: 26186.26,
    Switzerland: 39073.98,
    "Macao SAR": "n/a",
    Norway: 38047.95,
    "United States": 36312.78,
    Denmark: 30798.72,
    Iceland: 32344.36,
    Singapore: 23852.83,
    Australia: 20850.53,
    Sweden: 29589.14,
    Netherlands: 26327.87,
    Austria: 24636.47,
    Qatar: 30461.31,
    Finland: 24379.78,
    Canada: 24296.72,
    Germany: 23924.88,
    Belgium: 23136.51,
    "San Marino": "n/a",
    "Hong Kong SAR": 25574.49,
    "United Kingdom": 28212.73,
    Israel: 21071.76,
    "New Zealand": 14028.14,
    France: 23149.27,
    Japan: 39172.96,
    "United Arab Emirates": 34688.98,
    Andorra: "n/a",
    "Taiwan Province of China": 14844.24,
    Malta: 10482,
    Korea: 12263.47,
    Italy: 20153.07,
    Slovenia: 10259.44,
    Spain: 14761.11,
    Estonia: 4084.94,
    "Puerto Rico": 16192.13,
    Cyprus: 14464.82,
    "The Bahamas": 26669.44,
    "Czech Republic": 6015.07,
    "Brunei Darussalam": 20445.93,
    Aruba: 20681.02,
    Portugal: 11531.47,
    Lithuania: 3293.31,
    Kuwait: 17012.78,
    Bahrain: 14214.58,
    "Slovak Republic": 3839.79,
    Latvia: 3339.15,
    Hungary: 4619.29,
    "Saudi Arabia": 9256.52,
    Greece: 12164.72,
    Poland: 4485.06,
    Seychelles: 7578.83,
    Croatia: 4938.11,
    Romania: 1661.71,
    "St. Kitts and Nevis": 9279.58,
    Barbados: 11337.03,
    Chile: 5071.7,
    Uruguay: 7405.97,
    Panama: 4111.71,
    "Antigua and Barbuda": 10931.31,
    "Trinidad and Tobago": 6545.95,
    Maldives: 2967.25,
    Oman: 8120.72,
    Palau: 7862.5,
    Guyana: 2017.2,
    China: 951.16,
    Bulgaria: 1625.32,
    Malaysia: 4347.73,
    "Islamic Republic of Iran": 5648.12,
    Turkey: 4237.96,
    "Costa Rica": 3941.14,
    Turkmenistan: 1900.26,
    "St. Lucia": 5978.7,
    Russia: 1901.96,
    Mauritius: 4102.59,
    Serbia: 1239.75,
    Montenegro: 1596.63,
    Grenada: 5056.98,
    Kazakhstan: 1230.49,
    Mexico: 7166.14,
    "Dominican Republic": 2894.3,
    Argentina: 8638.54,
    Dominica: 4681.18,
    Brazil: 3772.05,
    Nauru: "n/a",
    Thailand: 2003.61,
    Gabon: 4477.32,
    "St. Vincent and the Grenadines": 3728.57,
    Botswana: 3401.88,
    "North Macedonia": 1878.24,
    "Bosnia and Herzegovina": 1480.62,
    Belarus: 1079.61,
    Tuvalu: "n/a",
    "South Africa": 3382.07,
    Peru: 1940.24,
    Albania: 1127.64,
    "Equatorial Guinea": 1885.99,
    Georgia: 763.51,
    Moldova: 448.06,
    Colombia: 2534.45,
    Fiji: 2286.99,
    Ukraine: 664.38,
    Kosovo: 1506.28,
    Armenia: 620.64,
    Ecuador: 1461.84,
    Jamaica: 3510.58,
    Tonga: 2045.81,
    Paraguay: 1675.79,
    Vietnam: 498.58,
    Iraq: "n/a",
    Mongolia: 553.1,
    Indonesia: 870.15,
    Azerbaijan: 656.39,
    Guatemala: 1555.64,
    Jordan: 1709.66,
    Suriname: 2904.55,
    Egypt: 1636.75,
    Namibia: 2139.71,
    Belize: 3331.42,
    "Marshall Islands": 2237.18,
    Djibouti: 1087.36,
    "El Salvador": 2001.54,
    Bhutan: 758.31,
    Eswatini: 1818.67,
    Samoa: 1462.99,
    "Cabo Verde": 1399.09,
    "Sri Lanka": 1030.76,
    Micronesia: 2179.42,
    Libya: 7388.14,
    Philippines: 1087.29,
    Tunisia: 2213.95,
    Morocco: 1373.92,
    Bolivia: 994.62,
    Nigeria: 554.64,
    "West Bank and Gaza": 1412.75,
    Vanuatu: 1422.23,
    Algeria: 1794.7,
    "Papua New Guinea": 1007.52,
    "Lao P.D.R.": 323.04,
    "CÙte d'Ivoire": 920.47,
    Bangladesh: 425.73,
    Honduras: 1093.11,
    "Solomon Islands": 1017.41,
    Ghana: 606.49,
    India: 451.11,
    "São Tomé and Príncipe": 550.52,
    Uzbekistan: 702.21,
    "Republic of Congo": 1268.42,
    Kenya: 897.62,
    Angola: 652.11,
    Mauritania: 673.36,
    Nicaragua: 1030.74,
    Cambodia: 299.98,
    Senegal: 614,
    Kiribati: 802.48,
    Cameroon: 631.79,
    Benin: 512.9,
    Zimbabwe: 969.82,
    Haiti: 794.06,
    Comoros: 628.07,
    Nepal: 273.64,
    "Kyrgyz Republic": 280.64,
    "Timor-Leste": 409.16,
    Guinea: 461.78,
    Tanzania: 380.08,
    Myanmar: 169.7,
    Uganda: 355.47,
    Togo: 409.01,
    Lesotho: 453.28,
    Zambia: 340.16,
    "Burkina Faso": 255.08,
    Mali: 278.38,
    "Guinea-Bissau": 327.94,
    Tajikistan: 159.42,
    "The Gambia": 766.2,
    Rwanda: 266.06,
    Sudan: 422.32,
    Niger: 197.28,
    Chad: 188.14,
    Liberia: 325.46,
    Eritrea: 238.51,
    "Democratic Republic of the Congo": 404.97,
    Yemen: 539.64,
    "Central African Republic": 238.51,
    Mozambique: 319.36,
    Madagascar: 293.61,
    "Sierra Leone": 205.25,
    Malawi: 352.65,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 130.23
  },
  2001: {
    Afghanistan: "n/a",
    Ethiopia: 125.72,
    Lebanon: 4348.53,
    Pakistan: 553.18,
    Syria: 1236.9,
    Venezuela: 4963.92,
    Luxembourg: 48498.77,
    Ireland: 28120.1,
    Switzerland: 39946.66,
    "Macao SAR": 15723.75,
    Norway: 38494.69,
    "United States": 37101.45,
    Denmark: 30806.61,
    Iceland: 29061.34,
    Singapore: 21699.75,
    Australia: 19431.83,
    Sweden: 27207.49,
    Netherlands: 26996.17,
    Austria: 24558.92,
    Qatar: 28600.03,
    Finland: 25001.18,
    Canada: 23859.71,
    Germany: 23869.77,
    Belgium: 23067.16,
    "San Marino": "n/a",
    "Hong Kong SAR": 25166.9,
    "United Kingdom": 27727.89,
    Israel: 20324.93,
    "New Zealand": 13667.83,
    France: 23229.18,
    Japan: 34410.68,
    "United Arab Emirates": 32621.29,
    Andorra: "n/a",
    "Taiwan Province of China": 13357.2,
    Malta: 10447.79,
    Korea: 11563.04,
    Italy: 20505.91,
    Slovenia: 10502.32,
    Spain: 15400.91,
    Estonia: 4516.22,
    "Puerto Rico": 18243.71,
    Cyprus: 14905.02,
    "The Bahamas": 27060.51,
    "Czech Republic": 6627.1,
    "Brunei Darussalam": 18621.1,
    Aruba: 20740.13,
    Portugal: 11734.88,
    Lithuania: 3525.85,
    Kuwait: 15108.11,
    Bahrain: 13894.34,
    "Slovak Republic": 3982.44,
    Latvia: 3549,
    Hungary: 5269.61,
    "Saudi Arabia": 8778.41,
    Greece: 12473.29,
    Poland: 4987.86,
    Seychelles: 7663.08,
    Croatia: 5355.37,
    Romania: 1802.68,
    "St. Kitts and Nevis": 9962.55,
    Barbados: 11284.37,
    Chile: 4572.87,
    Uruguay: 6776.82,
    Panama: 4094.97,
    "Antigua and Barbuda": 10411.42,
    "Trinidad and Tobago": 7046.38,
    Maldives: 2780.45,
    Oman: 7961.21,
    Palau: 8099.78,
    Guyana: 2023.62,
    China: 1044.96,
    Bulgaria: 1797.39,
    Malaysia: 4189.05,
    "Islamic Republic of Iran": 4842.26,
    Turkey: 3082.9,
    "Costa Rica": 4041.94,
    Turkmenistan: 2594.45,
    "St. Lucia": 5651.87,
    Russia: 2255.25,
    Mauritius: 4026.94,
    Serbia: 1642.08,
    Montenegro: 1893.72,
    Grenada: 5041.02,
    Kazakhstan: 1491.65,
    Mexico: 7558.98,
    "Dominican Republic": 3007.38,
    Argentina: 8085.36,
    Dominica: 4775.34,
    Brazil: 3177.94,
    Nauru: "n/a",
    Thailand: 1890.25,
    Gabon: 4065.93,
    "St. Vincent and the Grenadines": 4047.33,
    Botswana: 3172.04,
    "North Macedonia": 1839.62,
    "Bosnia and Herzegovina": 1540.27,
    Belarus: 1283.27,
    Tuvalu: "n/a",
    "South Africa": 2964.03,
    Peru: 1935.55,
    Albania: 1283.57,
    "Equatorial Guinea": 2616.49,
    Georgia: 819.5,
    Moldova: 505.78,
    Colombia: 2460.33,
    Fiji: 2238.55,
    Ukraine: 784.86,
    Kosovo: 1704.5,
    Armenia: 692.29,
    Ecuador: 1909.42,
    Jamaica: 3529.71,
    Tonga: 1818.18,
    Paraguay: 1577.68,
    Vietnam: 513.2,
    Iraq: "n/a",
    Mongolia: 610.33,
    Indonesia: 834.14,
    Azerbaijan: 674.78,
    Guatemala: 1654.95,
    Jordan: 1782.11,
    Suriname: 2464.82,
    Egypt: 1566.2,
    Namibia: 1939.79,
    Belize: 3404.52,
    "Marshall Islands": 2416.08,
    Djibouti: 1105.8,
    "El Salvador": 2072.3,
    Bhutan: 767.83,
    Eswatini: 1599.21,
    Samoa: 1501.91,
    "Cabo Verde": 1387.33,
    "Sri Lanka": 984.01,
    Micronesia: 2257.32,
    Libya: 6485.16,
    Philippines: 1003.96,
    Tunisia: 2254.91,
    Morocco: 1376.53,
    Bolivia: 949.38,
    Nigeria: 583.19,
    "West Bank and Gaza": 1275.69,
    Vanuatu: 1317.09,
    Algeria: 1768.58,
    "Papua New Guinea": 862.1,
    "Lao P.D.R.": 324.75,
    "CÙte d'Ivoire": 935.45,
    Bangladesh: 418.96,
    Honduras: 1133.19,
    "Solomon Islands": 965.95,
    Ghana: 630.69,
    India: 459.47,
    "São Tomé and Príncipe": 505.21,
    Uzbekistan: 587.63,
    "Republic of Congo": 1037.33,
    Kenya: 870.08,
    Angola: 619.7,
    Mauritania: 641.85,
    Nicaragua: 1054.66,
    Cambodia: 320.05,
    Senegal: 648.45,
    Kiribati: 732.87,
    Cameroon: 651.44,
    Benin: 518.07,
    Zimbabwe: 964.08,
    Haiti: 720.82,
    Comoros: 675.05,
    Nepal: 276.6,
    "Kyrgyz Republic": 309.91,
    "Timor-Leste": 525.33,
    Guinea: 427.88,
    Tanzania: 377.47,
    Myanmar: 154.59,
    Uganda: 347.81,
    Togo: 395.01,
    Lesotho: 374.97,
    Zambia: 356.32,
    "Burkina Faso": 267.1,
    Mali: 316.85,
    "Guinea-Bissau": 338.36,
    Tajikistan: 167.04,
    "The Gambia": 737.06,
    Rwanda: 248.99,
    Sudan: 492.68,
    Niger: 207.77,
    Chad: 223.1,
    Liberia: 328.52,
    Eritrea: 245.16,
    "Democratic Republic of the Congo": 149.63,
    Yemen: 532.42,
    "Central African Republic": 242.79,
    Mozambique: 296.27,
    Madagascar: 334.44,
    "Sierra Leone": 228.03,
    Malawi: 338.48,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 127.35
  },
  2002: {
    Afghanistan: 233.43,
    Ethiopia: 116.79,
    Lebanon: 4502.68,
    Pakistan: 546.61,
    Syria: 1305.09,
    Venezuela: 3778.26,
    Luxembourg: 53399.45,
    Ireland: 32482.08,
    Switzerland: 42807.41,
    "Macao SAR": 16733.98,
    Norway: 42997.96,
    "United States": 37945.76,
    Denmark: 33275.56,
    Iceland: 32516.44,
    Singapore: 22159.83,
    Australia: 21648.34,
    Sweden: 29846.19,
    Netherlands: 29401.98,
    Austria: 26508.32,
    Qatar: 30701.13,
    Finland: 27008.26,
    Canada: 24279.19,
    Germany: 25460.33,
    Belgium: 25044.33,
    "San Marino": "n/a",
    "Hong Kong SAR": 24731.08,
    "United Kingdom": 30032.21,
    Israel: 18449.18,
    "New Zealand": 15701.88,
    France: 25058.79,
    Japan: 32832.3,
    "United Arab Emirates": 32790.71,
    Andorra: "n/a",
    "Taiwan Province of China": 13651.36,
    Malta: 11346.46,
    Korea: 13159.68,
    Italy: 22386.26,
    Slovenia: 11804.82,
    Spain: 17097.92,
    Estonia: 5358.42,
    "Puerto Rico": 18972.77,
    Cyprus: 16188.53,
    "The Bahamas": 28477.06,
    "Czech Republic": 8056.32,
    "Brunei Darussalam": 18785.87,
    Aruba: 21307.25,
    Portugal: 12927.58,
    Lithuania: 4140.73,
    Kuwait: 15759.07,
    Bahrain: 13501.55,
    "Slovak Republic": 4626.83,
    Latvia: 4115.58,
    Hungary: 6644.61,
    "Saudi Arabia": 8822.49,
    Greece: 14065.79,
    Poland: 5205.52,
    Seychelles: 8431.92,
    Croatia: 6228.62,
    Romania: 2113.6,
    "St. Kitts and Nevis": 10304.32,
    Barbados: 11442.56,
    Chile: 4443.76,
    Uruguay: 4425.12,
    Panama: 4172.69,
    "Antigua and Barbuda": 10478.95,
    "Trinidad and Tobago": 7162.51,
    Maldives: 2952.22,
    Oman: 8108.72,
    Palau: 8388.52,
    Guyana: 2092.96,
    China: 1141.14,
    Bulgaria: 2090.68,
    Malaysia: 4441.82,
    "Islamic Republic of Iran": 1947.97,
    Turkey: 3617.23,
    "Costa Rica": 4122.83,
    Turkmenistan: 3221.22,
    "St. Lucia": 5655.31,
    Russia: 2552.79,
    Mauritius: 4196.43,
    Serbia: 2158.38,
    Montenegro: 2081.05,
    Grenada: 5213.8,
    Kazakhstan: 1657.16,
    Mexico: 7607.44,
    "Dominican Republic": 3158.39,
    Argentina: 2997.62,
    Dominica: 4680.26,
    Brazil: 2856.03,
    Nauru: "n/a",
    Thailand: 2094.26,
    Gabon: 4210.28,
    "St. Vincent and the Grenadines": 4327.57,
    Botswana: 3083.47,
    "North Macedonia": 1974.06,
    "Bosnia and Herzegovina": 1785.29,
    Belarus: 1524.49,
    Tuvalu: 1741.53,
    "South Africa": 2791.18,
    Peru: 2017.77,
    Albania: 1425.13,
    "Equatorial Guinea": 3107.85,
    Georgia: 874.69,
    Moldova: 569.11,
    Colombia: 2423.37,
    Fiji: 2467.53,
    Ukraine: 885.32,
    Kosovo: 1807.42,
    Armenia: 779.12,
    Ecuador: 2180.39,
    Jamaica: 3709.57,
    Tonga: 1828.63,
    Paraguay: 1312.08,
    Vietnam: 546.56,
    Iraq: "n/a",
    Mongolia: 662.7,
    Indonesia: 1002.91,
    Azerbaijan: 760.81,
    Guatemala: 1795.77,
    Jordan: 1868.86,
    Suriname: 3075.38,
    Egypt: 1355.28,
    Namibia: 1811.25,
    Belize: 3550.92,
    "Marshall Islands": 2651.49,
    Djibouti: 1120.28,
    "El Salvador": 2124.11,
    Bhutan: 813.42,
    Eswatini: 1473.72,
    Samoa: 1620.79,
    "Cabo Verde": 1501.55,
    "Sri Lanka": 1027.09,
    Micronesia: 2277.95,
    Libya: 3833.57,
    Philippines: 1050.3,
    Tunisia: 2345.99,
    Morocco: 1453.94,
    Bolivia: 904.85,
    Nigeria: 730.84,
    "West Bank and Gaza": 1102.5,
    Vanuatu: 1313.46,
    Algeria: 1806.86,
    "Papua New Guinea": 814.7,
    "Lao P.D.R.": 335.69,
    "CÙte d'Ivoire": 1009.64,
    Bangladesh: 420.89,
    Honduras: 1134.07,
    "Solomon Islands": 795.55,
    Ghana: 714.41,
    India: 479.06,
    "São Tomé and Príncipe": 552.89,
    Uzbekistan: 482,
    "Republic of Congo": 1109.28,
    Kenya: 898.19,
    Angola: 841.42,
    Mauritania: 634.59,
    Nicaragua: 1011.9,
    Cambodia: 337.5,
    Senegal: 680.83,
    Kiribati: 824.83,
    Cameroon: 708.45,
    Benin: 574.52,
    Zimbabwe: 922.88,
    Haiti: 684.33,
    Comoros: 757.4,
    Nepal: 276.3,
    "Kyrgyz Republic": 323.34,
    "Timor-Leste": 509.28,
    Guinea: 444.66,
    Tanzania: 382.35,
    Myanmar: 148.55,
    Uganda: 361.95,
    Togo: 442.77,
    Lesotho: 410.13,
    Zambia: 376.47,
    "Burkina Faso": 294.46,
    Mali: 346.48,
    "Guinea-Bissau": 374.5,
    Tajikistan: 187.96,
    "The Gambia": 631.57,
    Rwanda: 242.08,
    Sudan: 554.65,
    Niger: 227.42,
    Chad: 250.26,
    Liberia: 334.82,
    Eritrea: 227.5,
    "Democratic Republic of the Congo": 174.85,
    Yemen: 560.03,
    "Central African Republic": 260.87,
    Mozambique: 302.55,
    Madagascar: 319.22,
    "Sierra Leone": 251.7,
    Malawi: 384.14,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 116.39
  },
  2003: {
    Afghanistan: 233.76,
    Ethiopia: 120.87,
    Lebanon: 4438.89,
    Pakistan: 612.9,
    Syria: 1208.89,
    Venezuela: 3266.1,
    Luxembourg: 66061.09,
    Ireland: 40940.14,
    Switzerland: 49683.01,
    "Macao SAR": 18461.84,
    Norway: 50046.34,
    "United States": 39405.35,
    Denmark: 40512.05,
    Iceland: 39620.41,
    Singapore: 23730.38,
    Australia: 27213.27,
    Sweden: 37249.26,
    Netherlands: 35814.27,
    Austria: 32298.61,
    Qatar: 35919.76,
    Finland: 32961.92,
    Canada: 28338.75,
    Germany: 30668.85,
    Belgium: 30707.67,
    "San Marino": "n/a",
    "Hong Kong SAR": 23856.48,
    "United Kingdom": 34421.36,
    Israel: 18998.84,
    "New Zealand": 20480.43,
    France: 30628.86,
    Japan: 35410.22,
    "United Arab Emirates": 35017.31,
    Andorra: "n/a",
    "Taiwan Province of China": 14040.6,
    Malta: 13730.31,
    Korea: 14672.42,
    Italy: 27580.46,
    Slovenia: 14873.63,
    Spain: 21501.07,
    Estonia: 7216.27,
    "Puerto Rico": 19820.21,
    Cyprus: 20389.34,
    "The Bahamas": 28043.46,
    "Czech Republic": 9823.29,
    "Brunei Darussalam": 20755.77,
    Aruba: 21949.49,
    Portugal: 15793.85,
    Lithuania: 5499.48,
    Kuwait: 18786.75,
    Bahrain: 14486.06,
    "Slovak Republic": 6341.55,
    Latvia: 5111.94,
    Hungary: 8410.77,
    "Saudi Arabia": 9800.52,
    Greece: 18378.28,
    Poland: 5699.56,
    Seychelles: 8524.94,
    Croatia: 8054.46,
    Romania: 2672.81,
    "St. Kitts and Nevis": 9922.48,
    Barbados: 11788.65,
    Chile: 4770.33,
    Uruguay: 3926.71,
    Panama: 4312.62,
    "Antigua and Barbuda": 10902.08,
    "Trinidad and Tobago": 8931.03,
    Maldives: 3690.79,
    Oman: 8565.26,
    Palau: 7874.34,
    Guyana: 2144.11,
    China: 1282.21,
    Bulgaria: 2710.58,
    Malaysia: 4740.32,
    "Islamic Republic of Iran": 2256.51,
    Turkey: 4684.7,
    "Costa Rica": 4227.82,
    Turkmenistan: 4186.32,
    "St. Lucia": 6147.53,
    Russia: 3197.57,
    Mauritius: 5005.42,
    Serbia: 2838.5,
    Montenegro: 2745.76,
    Grenada: 5682.71,
    Kazakhstan: 2062.29,
    Mexico: 7088.46,
    "Dominican Republic": 2460.55,
    Argentina: 3761.09,
    Dominica: 4825.77,
    Brazil: 3089.14,
    Nauru: "n/a",
    Thailand: 2356.97,
    Gabon: 5015.6,
    "St. Vincent and the Grenadines": 4494.11,
    Botswana: 4187.36,
    "North Macedonia": 2440.81,
    "Bosnia and Herzegovina": 2254.45,
    Belarus: 1873.67,
    Tuvalu: 2075.67,
    "South Africa": 4209.22,
    Peru: 2159.74,
    Albania: 1846.12,
    "Equatorial Guinea": 5424.13,
    Georgia: 1034.71,
    Moldova: 680.27,
    Colombia: 2312.6,
    Fiji: 3079.61,
    Ukraine: 1055.95,
    Kosovo: 2205.81,
    Armenia: 923.39,
    Ecuador: 2434.98,
    Jamaica: 3586.44,
    Tonga: 2015.25,
    Paraguay: 1377.52,
    Vietnam: 610.36,
    Iraq: 608.66,
    Mongolia: 747.09,
    Indonesia: 1186.85,
    Azerbaijan: 879.86,
    Guatemala: 1847.29,
    Jordan: 1948.19,
    Suriname: 3571.56,
    Egypt: 1252.4,
    Namibia: 2607.86,
    Belize: 3643.32,
    "Marshall Islands": 2619.05,
    Djibouti: 1159.55,
    "El Salvador": 2209.5,
    Bhutan: 950.5,
    Eswatini: 2235.68,
    Samoa: 1953.98,
    "Cabo Verde": 1935.48,
    "Sri Lanka": 1124.83,
    Micronesia: 2311.65,
    Libya: 4831.42,
    Philippines: 1062.62,
    Tunisia: 2762.05,
    Morocco: 1768.6,
    Bolivia: 908.2,
    Nigeria: 780.4,
    "West Bank and Gaza": 1197.16,
    Vanuatu: 1542.06,
    Algeria: 2128.39,
    "Papua New Guinea": 995.28,
    "Lao P.D.R.": 405.11,
    "CÙte d'Ivoire": 1216.94,
    Bangladesh: 445.93,
    Honduras: 1158.18,
    "Solomon Islands": 788.63,
    Ghana: 860.46,
    India: 556.33,
    "São Tomé and Príncipe": 647.95,
    Uzbekistan: 499.33,
    "Republic of Congo": 1255.57,
    Kenya: 953.84,
    Angola: 951.97,
    Mauritania: 711.71,
    Nicaragua: 1010.23,
    Cambodia: 360.66,
    Senegal: 831.62,
    Kiribati: 1016.38,
    Cameroon: 866.49,
    Benin: 711.11,
    Zimbabwe: 822.47,
    Haiti: 540.56,
    Comoros: 949.68,
    Nepal: 288.42,
    "Kyrgyz Republic": 382.89,
    "Timor-Leste": 523.85,
    Guinea: 517.03,
    Tanzania: 401.07,
    Myanmar: 189.04,
    Uganda: 361.77,
    Togo: 535.37,
    Lesotho: 592.3,
    Zambia: 429.01,
    "Burkina Faso": 374.53,
    Mali: 405.69,
    "Guinea-Bissau": 435.07,
    Tajikistan: 236.39,
    "The Gambia": 581.32,
    Rwanda: 257.48,
    Sudan: 635.58,
    Niger: 267.41,
    Chad: 330.96,
    Liberia: 248.98,
    Eritrea: 258.93,
    "Democratic Republic of the Congo": 175.43,
    Yemen: 597.77,
    "Central African Republic": 310.3,
    Mozambique: 326.08,
    Madagascar: 368.8,
    "Sierra Leone": 265.32,
    Malawi: 345.16,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 108.07
  },
  2004: {
    Afghanistan: 254.26,
    Ethiopia: 138.55,
    Lebanon: 4629.21,
    Pakistan: 705.39,
    Syria: 1361.5,
    Venezuela: 4312,
    Luxembourg: 76343.55,
    Ireland: 47389.26,
    Switzerland: 55006.03,
    "Macao SAR": 23005.59,
    Norway: 57512.22,
    "United States": 41641.62,
    Denmark: 46571.28,
    Iceland: 47579.94,
    Singapore: 27608.08,
    Australia: 32761.41,
    Sweden: 42736.88,
    Netherlands: 40477.3,
    Austria: 36883.89,
    Qatar: 42548.12,
    Finland: 37816.08,
    Canada: 32176.59,
    Germany: 34534.81,
    Belgium: 35497.55,
    "San Marino": 59293.61,
    "Hong Kong SAR": 24873.9,
    "United Kingdom": 40252.45,
    Israel: 19919.02,
    "New Zealand": 24840.18,
    France: 34980.08,
    Japan: 38307.1,
    "United Arab Emirates": 39304.51,
    Andorra: "n/a",
    "Taiwan Province of China": 15290.31,
    Malta: 15260.89,
    Korea: 16482.75,
    Italy: 31342.81,
    Slovenia: 17254.83,
    Spain: 24932.13,
    Estonia: 8923.19,
    "Puerto Rico": 20988.99,
    Cyprus: 23969.82,
    "The Bahamas": 28239.07,
    "Czech Republic": 11751.9,
    "Brunei Darussalam": 24217.73,
    Aruba: 23700.63,
    Portugal: 18055.89,
    Lithuania: 6699.37,
    Kuwait: 21585.56,
    Bahrain: 15963.98,
    "Slovak Republic": 8040.07,
    Latvia: 6327.98,
    Hungary: 10275.77,
    "Saudi Arabia": 11467.1,
    Greece: 21829.5,
    Poland: 6684.68,
    Seychelles: 10176.66,
    Croatia: 9368.62,
    Romania: 3483.73,
    "St. Kitts and Nevis": 10566.96,
    Barbados: 12612.21,
    Chile: 6193.09,
    Uruguay: 4456.8,
    Panama: 4638.72,
    "Antigua and Barbuda": 11583.24,
    "Trinidad and Tobago": 10442.17,
    Maldives: 4238.04,
    Oman: 9635.83,
    Palau: 8386.41,
    Guyana: 2233,
    China: 1499.71,
    Bulgaria: 3370.38,
    Malaysia: 5244.87,
    "Islamic Republic of Iran": 2744.11,
    Turkey: 6015.67,
    "Costa Rica": 4483.59,
    Turkmenistan: 5147.98,
    "St. Lucia": 6567.46,
    Russia: 4403.96,
    Mauritius: 5626,
    Serbia: 3317.62,
    Montenegro: 3379.64,
    Grenada: 5741.65,
    Kazakhstan: 2862.5,
    Mexico: 7501.91,
    "Dominican Republic": 2540.85,
    Argentina: 4314.1,
    Dominica: 5165.22,
    Brazil: 3660.02,
    Nauru: 3174.96,
    Thailand: 2657.91,
    Gabon: 5837.62,
    "St. Vincent and the Grenadines": 4847.16,
    Botswana: 4892.27,
    "North Macedonia": 2796.62,
    "Bosnia and Herzegovina": 2699.05,
    Belarus: 2450.9,
    Tuvalu: 2385.56,
    "South Africa": 5415.09,
    Peru: 2408.09,
    Albania: 2373.58,
    "Equatorial Guinea": 8214.07,
    Georgia: 1338.26,
    Moldova: 894.06,
    Colombia: 2826.05,
    Fiji: 3601.62,
    Ukraine: 1374.75,
    Kosovo: 2274.96,
    Armenia: 1180.4,
    Ecuador: 2700.12,
    Jamaica: 3855.67,
    Tonga: 2288.9,
    Paraguay: 1693.89,
    Vietnam: 756.98,
    Iraq: 1373.74,
    Mongolia: 919.77,
    Indonesia: 1280.7,
    Azerbaijan: 1039.82,
    Guatemala: 1951.8,
    Jordan: 2125.81,
    Suriname: 4057.75,
    Egypt: 1195.6,
    Namibia: 3440.97,
    Belize: 3802.23,
    "Marshall Islands": 2625.82,
    Djibouti: 1211.89,
    "El Salvador": 2278.43,
    Bhutan: 1059.21,
    Eswatini: 2797.02,
    Samoa: 2327.8,
    "Cabo Verde": 2188,
    "Sri Lanka": 1218.73,
    Micronesia: 2268.9,
    Libya: 5988.39,
    Philippines: 1137.07,
    Tunisia: 3112.91,
    Morocco: 1999.13,
    Bolivia: 968.55,
    Nigeria: 963.24,
    "West Bank and Gaza": 1350.91,
    Vanuatu: 1743.29,
    Algeria: 2636.48,
    "Papua New Guinea": 1111.94,
    "Lao P.D.R.": 467.76,
    "CÙte d'Ivoire": 1282.22,
    Bangladesh: 479.36,
    Honduras: 1217.82,
    "Solomon Islands": 867,
    Ghana: 973.28,
    India: 638.79,
    "São Tomé and Príncipe": 692.69,
    Uzbekistan: 585.22,
    "Republic of Congo": 1617.33,
    Kenya: 941.17,
    Angola: 1222.04,
    Mauritania: 796.92,
    Nicaragua: 1077.78,
    Cambodia: 405.63,
    Senegal: 931.75,
    Kiribati: 1128.37,
    Cameroon: 1010.49,
    Benin: 798.38,
    Zimbabwe: 806.9,
    Haiti: 660.22,
    Comoros: 1055.36,
    Nepal: 327.13,
    "Kyrgyz Republic": 436.5,
    "Timor-Leste": 462.95,
    Guinea: 538.88,
    Tanzania: 428.93,
    Myanmar: 227.35,
    Uganda: 430.76,
    Togo: 557.46,
    Lesotho: 738.56,
    Zambia: 530.54,
    "Burkina Faso": 418.19,
    Mali: 454.97,
    "Guinea-Bissau": 447.8,
    Tajikistan: 308.84,
    "The Gambia": 642.76,
    Rwanda: 277.63,
    Sudan: 772.94,
    Niger: 285.39,
    Chad: 513.89,
    Liberia: 307.15,
    Eritrea: 315.55,
    "Democratic Republic of the Congo": 193.61,
    Yemen: 682.15,
    "Central African Republic": 336.41,
    Mozambique: 383.27,
    Madagascar: 284.49,
    "Sierra Leone": 264.78,
    Malawi: 365.7,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 122.38
  },
  2005: {
    Afghanistan: 294.4,
    Ethiopia: 165.22,
    Lebanon: 4575.07,
    Pakistan: 772.29,
    Syria: 1510.39,
    Venezuela: 5421.66,
    Luxembourg: 81048.07,
    Ireland: 50476.48,
    Switzerland: 56660.7,
    "Macao SAR": 25109.97,
    Norway: 66687.76,
    "United States": 44034.26,
    Denmark: 48872.1,
    Iceland: 57405.56,
    Singapore: 29961.31,
    Australia: 36139.69,
    Sweden: 43349.85,
    Netherlands: 42054.88,
    Austria: 38450.61,
    Qatar: 54357.78,
    Finland: 39147.22,
    Canada: 36439.62,
    Germany: 35020.2,
    Belgium: 36945.5,
    "San Marino": 61193.01,
    "Hong Kong SAR": 26551.77,
    "United Kingdom": 41971.8,
    Israel: 20594.66,
    "New Zealand": 27378.7,
    France: 36000.92,
    Japan: 37819.1,
    "United Arab Emirates": 43984.09,
    Andorra: "n/a",
    "Taiwan Province of China": 16427.46,
    Malta: 15930.14,
    Korea: 19398.49,
    Italy: 32031.43,
    Slovenia: 18152.06,
    Spain: 26438.03,
    Estonia: 10426.69,
    "Puerto Rico": 21959.32,
    Cyprus: 25527.08,
    "The Bahamas": 30269.03,
    "Czech Republic": 13446.94,
    "Brunei Darussalam": 29420.37,
    Aruba: 24172.09,
    Portugal: 18790.56,
    Lithuania: 7860.18,
    Kuwait: 27014.77,
    Bahrain: 17966.22,
    "Slovak Republic": 9141.84,
    Latvia: 7550.18,
    Hungary: 11188.45,
    "Saudi Arabia": 14068.22,
    Greece: 22417.64,
    Poland: 8023.92,
    Seychelles: 11093.31,
    Croatia: 10215.39,
    Romania: 4604.45,
    "St. Kitts and Nevis": 11256.64,
    Barbados: 13939.67,
    Chile: 7600.35,
    Uruguay: 5637.98,
    Panama: 4965.21,
    "Antigua and Barbuda": 12745.81,
    "Trinidad and Tobago": 12471.95,
    Maldives: 3960.43,
    Oman: 11873.18,
    Palau: 9297.56,
    Guyana: 2305.91,
    China: 1751.37,
    Bulgaria: 3869.59,
    Malaysia: 5678.35,
    "Islamic Republic of Iran": 3239.14,
    Turkey: 7350.89,
    "Costa Rica": 4756.34,
    Turkmenistan: 6160.91,
    "St. Lucia": 6910.81,
    Russia: 5708.84,
    Mauritius: 5516.2,
    Serbia: 3697.9,
    Montenegro: 3679.83,
    Grenada: 6644.22,
    Kazakhstan: 3753.44,
    Mexico: 8303.99,
    "Dominican Republic": 4008.36,
    Argentina: 5163.55,
    Dominica: 5127.44,
    Brazil: 4819.87,
    Nauru: 3167.65,
    Thailand: 2890.48,
    Gabon: 6942.7,
    "St. Vincent and the Grenadines": 5091.6,
    Botswana: 5361.37,
    "North Macedonia": 3071.9,
    "Bosnia and Herzegovina": 2903.6,
    Belarus: 3221.46,
    Tuvalu: 2348.71,
    "South Africa": 6033.22,
    Peru: 2669.21,
    Albania: 2673.77,
    "Equatorial Guinea": 10810.45,
    Georgia: 1682.66,
    Moldova: 1031.42,
    Colombia: 3493.99,
    Fiji: 3943.27,
    Ukraine: 1839.52,
    Kosovo: 2318.59,
    Armenia: 1628.06,
    Ecuador: 3025.01,
    Jamaica: 4241.4,
    Tonga: 2587.3,
    Paraguay: 1857.78,
    Vietnam: 873.14,
    Iraq: 1828.74,
    Mongolia: 989.18,
    Indonesia: 1403.88,
    Azerbaijan: 1571.29,
    Guatemala: 2151.74,
    Jordan: 2271.86,
    Suriname: 4800.8,
    Egypt: 1331.36,
    Namibia: 3708.79,
    Belize: 3887.73,
    "Marshall Islands": 2668.17,
    Djibouti: 1270.21,
    "El Salvador": 2428.57,
    Bhutan: 1215.29,
    Eswatini: 3174.59,
    Samoa: 2660.17,
    "Cabo Verde": 2298.4,
    "Sri Lanka": 1429.45,
    Micronesia: 2370.44,
    Libya: 8467.72,
    Philippines: 1261.27,
    Tunisia: 3194.48,
    Morocco: 2063.31,
    Bolivia: 1037.18,
    Nigeria: 1221.65,
    "West Bank and Gaza": 1461.09,
    Vanuatu: 1824.51,
    Algeria: 3141.03,
    "Papua New Guinea": 1269.53,
    "Lao P.D.R.": 530.06,
    "CÙte d'Ivoire": 1287.97,
    Bangladesh: 499.59,
    Honduras: 1308.09,
    "Solomon Islands": 1014.73,
    Ghana: 1151.29,
    India: 726.92,
    "São Tomé and Príncipe": 809.41,
    Uzbekistan: 689.39,
    "Republic of Congo": 2058.3,
    Kenya: 1056.47,
    Angola: 1862.42,
    Mauritania: 963.5,
    Nicaragua: 1152.8,
    Cambodia: 470.68,
    Senegal: 993.24,
    Kiribati: 1212.13,
    Cameroon: 1013.55,
    Benin: 823.24,
    Zimbabwe: 764.63,
    Haiti: 773.35,
    Comoros: 1085.66,
    Nepal: 363.23,
    "Kyrgyz Republic": 478.77,
    "Timor-Leste": 476.79,
    Guinea: 470.57,
    Tanzania: 459.76,
    Myanmar: 254.76,
    Uganda: 483.03,
    Togo: 548.8,
    Lesotho: 791.27,
    Zambia: 691.55,
    "Burkina Faso": 458.19,
    Mali: 505.37,
    "Guinea-Bissau": 482.18,
    Tajikistan: 337.13,
    "The Gambia": 665.72,
    Rwanda: 334.41,
    Sudan: 996.68,
    Niger: 320.92,
    Chad: 661.2,
    Liberia: 314.89,
    Eritrea: 300.7,
    "Democratic Republic of the Congo": 220.29,
    Yemen: 797.7,
    "Central African Republic": 356.69,
    Mozambique: 416.81,
    Madagascar: 319.54,
    "Sierra Leone": 285.11,
    Malawi: 377.04,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 148.71
  },
  2006: {
    Afghanistan: 320.67,
    Ethiopia: 198.34,
    Lebanon: 4626.82,
    Pakistan: 883.48,
    Syria: 1726.03,
    Venezuela: 6646.8,
    Luxembourg: 90499.47,
    Ireland: 53738.84,
    Switzerland: 59505.88,
    "Macao SAR": 28970.43,
    Norway: 73970.24,
    "United States": 46216.85,
    Denmark: 52121.25,
    Iceland: 58238.89,
    Singapore: 33768.45,
    Australia: 37876.24,
    Sweden: 46425.84,
    Netherlands: 44936.01,
    Austria: 40674.88,
    Qatar: 60591.78,
    Finland: 41308.62,
    Canada: 40558.9,
    Germany: 36894.47,
    Belgium: 38841.79,
    "San Marino": 64494.91,
    "Hong Kong SAR": 28028.16,
    "United Kingdom": 44549.73,
    Israel: 21859.44,
    "New Zealand": 26221.94,
    France: 37762.46,
    Japan: 36021.9,
    "United Arab Emirates": 44313.55,
    Andorra: "n/a",
    "Taiwan Province of China": 16892.88,
    Malta: 16818.01,
    Korea: 21730.95,
    Italy: 33448.09,
    Slovenia: 19723.86,
    Spain: 28414.13,
    Estonia: 12657.06,
    "Puerto Rico": 22935.95,
    Cyprus: 27448.57,
    "The Bahamas": 30908.21,
    "Czech Republic": 15284.67,
    "Brunei Darussalam": 34690.3,
    Aruba: 24845.66,
    Portugal: 19840.47,
    Lithuania: 9236.62,
    Kuwait: 31907.2,
    Bahrain: 19267.57,
    "Slovak Republic": 10700.34,
    Latvia: 9666.81,
    Hungary: 11469.41,
    "Saudi Arabia": 15603.99,
    Greece: 24648.87,
    Poland: 9031.8,
    Seychelles: 12014.42,
    Croatia: 11356.45,
    Romania: 5740.4,
    "St. Kitts and Nevis": 13095.2,
    Barbados: 15334.01,
    Chile: 9473.01,
    Uruguay: 6347.28,
    Panama: 5401.56,
    "Antigua and Barbuda": 14270.11,
    "Trinidad and Tobago": 14280.04,
    Maldives: 5268.79,
    Oman: 13938.21,
    Palau: 9649.44,
    Guyana: 2551.77,
    China: 2095.24,
    Bulgaria: 4477.03,
    Malaysia: 6351.18,
    "Islamic Republic of Iran": 3765.88,
    Turkey: 7961.08,
    "Costa Rica": 5309.27,
    Turkmenistan: 7588.66,
    "St. Lucia": 7622.38,
    Russia: 7426.01,
    Mauritius: 5695.98,
    Serbia: 4401.87,
    Montenegro: 4429.8,
    Grenada: 6658.22,
    Kazakhstan: 5261.03,
    Mexico: 9102.51,
    "Dominican Republic": 4188.72,
    Argentina: 5976.08,
    Dominica: 5497.25,
    Brazil: 5921.18,
    Nauru: 3200.72,
    Thailand: 3366.82,
    Gabon: 7271.09,
    "St. Vincent and the Grenadines": 5623.32,
    Botswana: 5339.07,
    "North Macedonia": 3359.61,
    "Bosnia and Herzegovina": 3306.79,
    Belarus: 3968.75,
    Tuvalu: 2464.22,
    "South Africa": 6276.17,
    Peru: 3106.72,
    Albania: 2972.75,
    "Equatorial Guinea": 12743.35,
    Georgia: 2048.02,
    Moldova: 1179.32,
    Colombia: 3836.67,
    Fiji: 4041.49,
    Ukraine: 2319.29,
    Kosovo: 2400.44,
    Armenia: 2128.15,
    Ecuador: 3351.48,
    Jamaica: 4494.63,
    Tonga: 2876.12,
    Paraguay: 2285.13,
    Vietnam: 996.26,
    Iraq: 2321.26,
    Mongolia: 1321.61,
    Indonesia: 1764.79,
    Azerbaijan: 2458.42,
    Guatemala: 2336.96,
    Jordan: 2616.51,
    Suriname: 5577.12,
    Egypt: 1563.74,
    Namibia: 4007.73,
    Belize: 4159.15,
    "Marshall Islands": 2749.07,
    Djibouti: 1358.41,
    "El Salvador": 2631.82,
    Bhutan: 1293.19,
    Eswatini: 3262.22,
    Samoa: 2759.76,
    "Cabo Verde": 2571.82,
    "Sri Lanka": 1642.83,
    Micronesia: 2413.78,
    Libya: 9659.64,
    Philippines: 1470.83,
    Tunisia: 3371.54,
    Morocco: 2242.73,
    Bolivia: 1226.85,
    Nigeria: 1563.03,
    "West Bank and Gaza": 1480.7,
    Vanuatu: 1985.36,
    Algeria: 3508.96,
    "Papua New Guinea": 1411.9,
    "Lao P.D.R.": 669.72,
    "CÙte d'Ivoire": 1308.58,
    Bangladesh: 509.61,
    Honduras: 1430.06,
    "Solomon Islands": 1119.61,
    Ghana: 1320.27,
    India: 814.35,
    "São Tomé and Príncipe": 839.55,
    Uzbekistan: 811.16,
    "Republic of Congo": 2436.38,
    Kenya: 1203.39,
    Angola: 2561.86,
    Mauritania: 1357.43,
    Nicaragua: 1224.72,
    Cambodia: 536.15,
    Senegal: 1027.79,
    Kiribati: 1166.44,
    Cameroon: 1063.14,
    Benin: 856.1,
    Zimbabwe: 677.88,
    Haiti: 794.79,
    Comoros: 1110.98,
    Nepal: 396.62,
    "Kyrgyz Republic": 546.62,
    "Timor-Leste": 458.88,
    Guinea: 426.42,
    Tanzania: 491.06,
    Myanmar: 283.7,
    Uganda: 527.74,
    Togo: 550.52,
    Lesotho: 809.56,
    Zambia: 1030.69,
    "Burkina Faso": 473.48,
    Mali: 540.59,
    "Guinea-Bissau": 468,
    Tajikistan: 401.59,
    "The Gambia": 662.36,
    Rwanda: 368.51,
    Sudan: 1249.77,
    Niger: 335.33,
    Chad: 712.53,
    Liberia: 357.24,
    Eritrea: 321.22,
    "Democratic Republic of the Congo": 254.09,
    Yemen: 881.65,
    "Central African Republic": 381.32,
    Mozambique: 435.33,
    Madagascar: 338.75,
    "Sierra Leone": 323.26,
    Malawi: 400.9,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 164.58
  },
  2007: {
    Afghanistan: 381.5,
    Ethiopia: 249.21,
    Lebanon: 5207.76,
    Pakistan: 963.51,
    Syria: 2016.05,
    Venezuela: 8538.1,
    Luxembourg: 107014.12,
    Ireland: 60770.05,
    Switzerland: 65774.86,
    "Macao SAR": 34268.7,
    Norway: 84915.64,
    "United States": 47943.35,
    Denmark: 58641.19,
    Iceland: 70375.33,
    Singapore: 39432.9,
    Australia: 45102.98,
    Sweden: 53496.53,
    Netherlands: 51880.38,
    Austria: 46922.56,
    Qatar: 65934.27,
    Finland: 48590.2,
    Canada: 44716.99,
    Germany: 42299.86,
    Belgium: 44496.79,
    "San Marino": 72991.53,
    "Hong Kong SAR": 30494.55,
    "United Kingdom": 50467.11,
    Israel: 24966.23,
    "New Zealand": 31909.42,
    France: 42998.45,
    Japan: 35847.23,
    "United Arab Emirates": 41472.25,
    Andorra: "n/a",
    "Taiwan Province of China": 17723.7,
    Malta: 19696.18,
    Korea: 24083.34,
    Italy: 37828.36,
    Slovenia: 23912.49,
    Spain: 32588.56,
    Estonia: 16764.94,
    "Puerto Rico": 23664.87,
    Cyprus: 31774.25,
    "The Bahamas": 31894.39,
    "Czech Republic": 18527.74,
    "Brunei Darussalam": 36302.79,
    Aruba: 26736.58,
    Portugal: 22813.67,
    Lithuania: 12305.92,
    Kuwait: 33732.52,
    Bahrain: 20908.34,
    "Slovak Republic": 14374.85,
    Latvia: 14057.16,
    Hungary: 13904.83,
    "Saudi Arabia": 16666.6,
    Greece: 28656.23,
    Poland: 11252.86,
    Seychelles: 12155.7,
    Croatia: 13542.21,
    Romania: 8262.41,
    "St. Kitts and Nevis": 13845.79,
    Barbados: 16925.61,
    Chile: 10507.91,
    Uruguay: 7587.88,
    Panama: 6127.03,
    "Antigua and Barbuda": 16009.2,
    "Trinidad and Tobago": 16818.28,
    Maldives: 6128.48,
    Oman: 15436.81,
    Palau: 10017.03,
    Guyana: 2982.22,
    China: 2691.05,
    Bulgaria: 5811.85,
    Malaysia: 7480.94,
    "Islamic Republic of Iran": 4905.24,
    Turkey: 9640.57,
    "Costa Rica": 6194.05,
    Turkmenistan: 9101.87,
    "St. Lucia": 7936.98,
    Russia: 9761.37,
    Mauritius: 6574.66,
    Serbia: 5882.94,
    Montenegro: 5984.45,
    Grenada: 7213,
    Kazakhstan: 6733.45,
    Mexico: 9680.43,
    "Dominican Republic": 4803.47,
    Argentina: 7315.73,
    Dominica: 5939.91,
    Brazil: 7390.65,
    Nauru: 2470.84,
    Thailand: 3973.99,
    Gabon: 8652.15,
    "St. Vincent and the Grenadines": 6272.4,
    Botswana: 5619.89,
    "North Macedonia": 4076.3,
    "Bosnia and Herzegovina": 4073.01,
    Belarus: 4887.28,
    Tuvalu: 2865.98,
    "South Africa": 6776.46,
    Peru: 3587.8,
    Albania: 3595.05,
    "Equatorial Guinea": 15782.69,
    Georgia: 2700.59,
    Moldova: 1526.38,
    Colombia: 4834.43,
    Fiji: 4405.16,
    Ukraine: 3101.39,
    Kosovo: 2865.99,
    Armenia: 3079.03,
    Ecuador: 3588.31,
    Jamaica: 4829.3,
    Tonga: 2925.94,
    Paraguay: 2988.68,
    Vietnam: 1152.27,
    Iraq: 3090.85,
    Mongolia: 1616.14,
    Indonesia: 2064.23,
    Azerbaijan: 3818.27,
    Guatemala: 2556.76,
    Jordan: 2849.05,
    Suriname: 6165.52,
    Egypt: 1862.16,
    Namibia: 4378.56,
    Belize: 4255.8,
    "Marshall Islands": 2864.64,
    Djibouti: 1477.25,
    "El Salvador": 2786.16,
    Bhutan: 1563.08,
    Eswatini: 3402.16,
    Samoa: 3117.26,
    "Cabo Verde": 3110.45,
    "Sri Lanka": 1865.88,
    Micronesia: 2457.38,
    Libya: 11711.14,
    Philippines: 1764.68,
    Tunisia: 3778.89,
    Morocco: 2549.88,
    Bolivia: 1383.89,
    Nigeria: 1791.82,
    "West Bank and Gaza": 1563.7,
    Vanuatu: 2308.55,
    Algeria: 3986.56,
    "Papua New Guinea": 1576.16,
    "Lao P.D.R.": 800.1,
    "CÙte d'Ivoire": 1458.24,
    Bangladesh: 558.09,
    Honduras: 1583.05,
    "Solomon Islands": 1260.25,
    Ghana: 1520.63,
    India: 1046.9,
    "São Tomé and Príncipe": 885.1,
    Uzbekistan: 1048.72,
    "Republic of Congo": 2586.3,
    Kenya: 1412.69,
    Angola: 3099.09,
    Mauritania: 1425.47,
    Nicaragua: 1326.66,
    Cambodia: 627.78,
    Senegal: 1197.55,
    Kiribati: 1376.28,
    Cameroon: 1195.79,
    Benin: 966.32,
    Zimbabwe: 646.56,
    Haiti: 991.68,
    Comoros: 1251.86,
    Nepal: 447.38,
    "Kyrgyz Republic": 725.49,
    "Timor-Leste": 537.24,
    Guinea: 628.71,
    Tanzania: 551.78,
    Myanmar: 370.54,
    Uganda: 634.36,
    Togo: 606.78,
    Lesotho: 930.8,
    Zambia: 1103.69,
    "Burkina Faso": 535.13,
    Mali: 617.83,
    "Guinea-Bissau": 543.41,
    Tajikistan: 519.03,
    "The Gambia": 780.38,
    Rwanda: 442.14,
    Sudan: 1599.6,
    Niger: 389.26,
    Chad: 803.02,
    Liberia: 415.96,
    Eritrea: 340.33,
    "Democratic Republic of the Congo": 287.02,
    Yemen: 971.33,
    "Central African Republic": 427.31,
    Mozambique: 482.2,
    Madagascar: 438.66,
    "Sierra Leone": 360.43,
    Malawi: 432.12,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 170.17
  },
  2008: {
    Afghanistan: 447.75,
    Ethiopia: 334.15,
    Lebanon: 6111.36,
    Pakistan: 1030.76,
    Syria: 2557.21,
    Venezuela: 11079.07,
    Luxembourg: 115898.76,
    Ireland: 60989.98,
    Switzerland: 75076.97,
    "Macao SAR": 38286.23,
    Norway: 96563.22,
    "United States": 48470.55,
    Denmark: 64531.12,
    Iceland: 57296.25,
    Singapore: 40008.58,
    Australia: 49149.23,
    Sweden: 55929.86,
    Netherlands: 58015.4,
    Austria: 51914.01,
    Qatar: 72484.63,
    Finland: 53897.91,
    Canada: 46773.84,
    Germany: 46367.86,
    Belgium: 48493.14,
    "San Marino": 79110.2,
    "Hong Kong SAR": 31487.94,
    "United Kingdom": 47753.72,
    Israel: 29659.79,
    "New Zealand": 31759.19,
    France: 46927.31,
    Japan: 39992.06,
    "United Arab Emirates": 39074.71,
    Andorra: "n/a",
    "Taiwan Province of China": 18053.59,
    Malta: 22378.18,
    Korea: 21387.71,
    Italy: 40818.99,
    Slovenia: 27744.28,
    Spain: 35484.39,
    Estonia: 18273.3,
    "Puerto Rico": 24898.34,
    Cyprus: 36009.75,
    "The Bahamas": 31248.6,
    "Czech Republic": 22925.75,
    "Brunei Darussalam": 42470.56,
    Aruba: 28172.19,
    Portugal: 24946.26,
    Lithuania: 15017.56,
    Kuwait: 42827.13,
    Bahrain: 23236.06,
    "Slovak Republic": 18074.64,
    Latvia: 16390.27,
    Hungary: 15742.79,
    "Saudi Arabia": 20157.29,
    Greece: 31902.01,
    Poland: 13999.5,
    Seychelles: 11480.43,
    Croatia: 15839.94,
    Romania: 10385.85,
    "St. Kitts and Nevis": 15451.23,
    Barbados: 17254.23,
    Chile: 10748.98,
    Uruguay: 9808.92,
    Panama: 7110.23,
    "Antigua and Barbuda": 16529.95,
    "Trinidad and Tobago": 21479.28,
    Maldives: 7337.95,
    Oman: 21866.27,
    Palau: 10281.35,
    Guyana: 3329.64,
    China: 3446.7,
    Bulgaria: 7156.18,
    Malaysia: 8759.52,
    "Islamic Republic of Iran": 5612.15,
    Turkey: 10778.13,
    "Costa Rica": 6993.9,
    Turkmenistan: 7452.44,
    "St. Lucia": 8441.83,
    Russia: 12464.24,
    Mauritius: 8030.05,
    Serbia: 7092.36,
    Montenegro: 7396.67,
    Grenada: 7832.37,
    Kazakhstan: 8349.29,
    Mexico: 10053.74,
    "Dominican Republic": 5194.8,
    Argentina: 9146.79,
    Dominica: 6463.44,
    Brazil: 8878.36,
    Nauru: 3965.36,
    Thailand: 4373.45,
    Gabon: 10418.28,
    "St. Vincent and the Grenadines": 6366.71,
    Botswana: 5545.42,
    "North Macedonia": 4838.15,
    "Bosnia and Herzegovina": 4984.54,
    Belarus: 6581.18,
    Tuvalu: 3186.85,
    "South Africa": 6355.88,
    Peru: 4225.86,
    Albania: 4370.56,
    "Equatorial Guinea": 22834.44,
    Georgia: 3418.86,
    Moldova: 2102.94,
    Colombia: 5622.11,
    Fiji: 4535.43,
    Ukraine: 3944.72,
    Kosovo: 3320.6,
    Armenia: 3913.44,
    Ecuador: 4267.47,
    Jamaica: 5134.25,
    Tonga: 3367.71,
    Paraguay: 4054.04,
    Vietnam: 1446.56,
    Iraq: 4472.12,
    Mongolia: 2109.27,
    Indonesia: 2418.04,
    Azerbaijan: 5578.56,
    Guatemala: 2873.09,
    Jordan: 3489.79,
    Suriname: 7316.46,
    Egypt: 2271.23,
    Namibia: 4198.55,
    Belize: 4404.5,
    "Marshall Islands": 2894.23,
    Djibouti: 1691.09,
    "El Salvador": 2933.4,
    Bhutan: 1936.11,
    Eswatini: 3214.49,
    Samoa: 3596.53,
    "Cabo Verde": 3636.13,
    "Sri Lanka": 2332.26,
    Micronesia: 2531.36,
    Libya: 12570.87,
    Philippines: 2011.63,
    Tunisia: 4308.91,
    Morocco: 2946.93,
    Bolivia: 1729.37,
    Nigeria: 2197.78,
    "West Bank and Gaza": 1913.32,
    Vanuatu: 2540.72,
    Algeria: 4943.5,
    "Papua New Guinea": 1883.53,
    "Lao P.D.R.": 983.76,
    "CÙte d'Ivoire": 1697.05,
    Bangladesh: 634.98,
    Honduras: 1739.36,
    "Solomon Islands": 1389.07,
    Ghana: 1688.06,
    India: 1019.51,
    "São Tomé and Príncipe": 1125.88,
    Uzbekistan: 1324.54,
    "Republic of Congo": 3346.18,
    Kenya: 1314.84,
    Angola: 4081.69,
    Mauritania: 1647.29,
    Nicaragua: 1498.88,
    Cambodia: 741.86,
    Senegal: 1403.8,
    Kiribati: 1456.91,
    Cameroon: 1377.15,
    Benin: 1125.3,
    Zimbabwe: 553.38,
    Haiti: 1074.36,
    Comoros: 1403.8,
    Nepal: 537.81,
    "Kyrgyz Republic": 971.68,
    "Timor-Leste": 627.16,
    Guinea: 675.39,
    Tanzania: 683.14,
    Myanmar: 525.2,
    Uganda: 784.5,
    Togo: 736.99,
    Lesotho: 868.53,
    Zambia: 1365.93,
    "Burkina Faso": 643.33,
    Mali: 720.61,
    "Guinea-Bissau": 672.25,
    Tajikistan: 702.45,
    "The Gambia": 924.51,
    Rwanda: 545.19,
    Sudan: 1700.51,
    Niger: 477.27,
    Chad: 932.6,
    Liberia: 497.45,
    Eritrea: 301.24,
    "Democratic Republic of the Congo": 323.59,
    Yemen: 1171.17,
    "Central African Republic": 485.81,
    Mozambique: 563.65,
    Madagascar: 536.35,
    "Sierra Leone": 409.44,
    Malawi: 504.65,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 195.79
  },
  2009: {
    Afghanistan: 511.37,
    Ethiopia: 397.81,
    Lebanon: 7354.97,
    Pakistan: 993.73,
    Syria: 2557.27,
    Venezuela: 9557.55,
    Luxembourg: 104393.46,
    Ireland: 51943.47,
    Switzerland: 72460.75,
    "Macao SAR": 39822.35,
    Norway: 79746.89,
    "United States": 47102.43,
    Denmark: 58286.54,
    Iceland: 41188.91,
    Singapore: 38926.81,
    Australia: 45684.73,
    Sweden: 46734.91,
    Netherlands: 52807.45,
    Austria: 48111.66,
    Qatar: 53822.91,
    Finland: 47541.75,
    Canada: 40990.63,
    Germany: 42338.84,
    Belgium: 44892.23,
    "San Marino": 67011.54,
    "Hong Kong SAR": 30593.99,
    "United Kingdom": 38885.08,
    Israel: 27793.38,
    "New Zealand": 28283.69,
    France: 43079.37,
    Japan: 41469.77,
    "United Arab Emirates": 30920.43,
    Andorra: "n/a",
    "Taiwan Province of China": 16904.54,
    Malta: 21223.68,
    Korea: 19139.73,
    Italy: 36982.81,
    Slovenia: 24854.16,
    Spain: 32131.38,
    Estonia: 14756.39,
    "Puerto Rico": 25768.73,
    Cyprus: 32650.44,
    "The Bahamas": 29296.49,
    "Czech Republic": 19908.15,
    "Brunei Darussalam": 31341.5,
    Aruba: 25134.77,
    Portugal: 23126.18,
    Lithuania: 11848.2,
    Kuwait: 30414.74,
    Bahrain: 19465.29,
    "Slovak Republic": 16591.52,
    Latvia: 12199.67,
    Hungary: 13035.62,
    "Saudi Arabia": 16094.69,
    Greece: 29577.64,
    Poland: 11532.28,
    Seychelles: 9707.27,
    Croatia: 14134.16,
    Romania: 8517.6,
    "St. Kitts and Nevis": 15215.84,
    Barbados: 16036.4,
    Chile: 10220.07,
    Uruguay: 10181.7,
    Panama: 7532.4,
    "Antigua and Barbuda": 14661.52,
    "Trinidad and Tobago": 14809.02,
    Maldives: 7450.81,
    Oman: 16784.91,
    Palau: 9822.35,
    Guyana: 3457.37,
    China: 3813.41,
    Bulgaria: 6878.06,
    Malaysia: 7544.11,
    "Islamic Republic of Iran": 5656.19,
    Turkey: 8941.4,
    "Costa Rica": 6879.27,
    Turkmenistan: 6915.08,
    "St. Lucia": 8508.3,
    Russia: 9156.97,
    Mauritius: 7318.13,
    Serbia: 6172.49,
    Montenegro: 6746.38,
    Grenada: 7290.82,
    Kazakhstan: 7116.37,
    Mexico: 8029.3,
    "Dominican Republic": 5151.17,
    Argentina: 8337.81,
    Dominica: 6904,
    Brazil: 8649.58,
    Nauru: 4587.75,
    Thailand: 4208.36,
    Gabon: 7856.85,
    "St. Vincent and the Grenadines": 6172.79,
    Botswana: 5065.33,
    "North Macedonia": 4579.09,
    "Bosnia and Herzegovina": 4711.12,
    Belarus: 5345.33,
    Tuvalu: 2759.03,
    "South Africa": 6552.26,
    Peru: 4171.73,
    Albania: 4114.09,
    "Equatorial Guinea": 16596.74,
    Georgia: 2890.88,
    Moldova: 1890.65,
    Colombia: 5330.8,
    Fiji: 3673.96,
    Ukraine: 2557.28,
    Kosovo: 3244.92,
    Armenia: 2911.76,
    Ecuador: 4241.94,
    Jamaica: 4507.19,
    Tonga: 3046.76,
    Paraguay: 3623.93,
    Vietnam: 1481.44,
    Iraq: 3701.86,
    Mongolia: 1687.55,
    Indonesia: 2464.96,
    Azerbaijan: 4963.77,
    Guatemala: 2654.1,
    Jordan: 3597.34,
    Suriname: 7917.32,
    Egypt: 2578.88,
    Namibia: 4340.18,
    Belize: 4124.22,
    "Marshall Islands": 2864.63,
    Djibouti: 1720.97,
    "El Salvador": 2858.48,
    Bhutan: 1797.42,
    Eswatini: 3479.71,
    Samoa: 3302.76,
    "Cabo Verde": 3416.22,
    "Sri Lanka": 2391.7,
    Micronesia: 2711.06,
    Libya: 8524.77,
    Philippines: 1923.67,
    Tunisia: 4131.02,
    Morocco: 2922.59,
    Bolivia: 1769.44,
    Nigeria: 1927.48,
    "West Bank and Gaza": 2061.56,
    Vanuatu: 2519.63,
    Algeria: 3890.52,
    "Papua New Guinea": 1833.09,
    "Lao P.D.R.": 1045.89,
    "CÙte d'Ivoire": 1657.6,
    Bangladesh: 702.26,
    Honduras: 1789.71,
    "Solomon Islands": 1428.61,
    Ghana: 1477.63,
    India: 1121.25,
    "São Tomé and Príncipe": 1098.37,
    Uzbekistan: 1523.42,
    "Republic of Congo": 2722.04,
    Kenya: 1123.29,
    Angola: 3146.8,
    Mauritania: 1475.59,
    Nicaragua: 1444.94,
    Cambodia: 737.19,
    Senegal: 1307.52,
    Kiribati: 1332.07,
    Cameroon: 1318.56,
    Benin: 1087.58,
    Zimbabwe: 790.35,
    Haiti: 1168.75,
    Comoros: 1351.15,
    Nepal: 546.64,
    "Kyrgyz Republic": 876.93,
    "Timor-Leste": 683.31,
    Guinea: 637.48,
    Tanzania: 693.03,
    Myanmar: 632.23,
    Uganda: 815.81,
    Togo: 728.53,
    Lesotho: 1011.42,
    Zambia: 1135.01,
    "Burkina Faso": 623.5,
    Mali: 724.19,
    "Guinea-Bissau": 613.57,
    Tajikistan: 666.02,
    "The Gambia": 833.28,
    Rwanda: 584.68,
    Sudan: 1401.24,
    Niger: 462.27,
    Chad: 805.8,
    Liberia: 503.6,
    Eritrea: 415.6,
    "Democratic Republic of the Congo": 296.17,
    Yemen: 1060.93,
    "Central African Republic": 482.65,
    Mozambique: 520.4,
    Madagascar: 467.54,
    "Sierra Leone": 391.21,
    Malawi: 571.11,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 208.78
  },
  2010: {
    Afghanistan: 631.49,
    Ethiopia: 360.83,
    Lebanon: 7761.62,
    Pakistan: 1029.17,
    Syria: 2806.69,
    Venezuela: 11158.18,
    Luxembourg: 106177.04,
    Ireland: 48612.2,
    Switzerland: 77431.92,
    "Macao SAR": 51135.16,
    Norway: 87356.19,
    "United States": 48586.29,
    Denmark: 58177.16,
    Iceland: 43293.02,
    Singapore: 47236.67,
    Australia: 56459.92,
    Sweden: 52658.79,
    Netherlands: 51165.82,
    Austria: 46955.17,
    Qatar: 69796.14,
    Finland: 46647,
    Canada: 47627.35,
    Germany: 42379.71,
    Belgium: 44448.17,
    "San Marino": 60426.24,
    "Hong Kong SAR": 32421.36,
    "United Kingdom": 39579.62,
    Israel: 30791.28,
    "New Zealand": 33379.61,
    France: 42100.29,
    Japan: 45135.8,
    "United Arab Emirates": 35065.94,
    Andorra: 49030.23,
    "Taiwan Province of China": 19181.36,
    Malta: 21842.07,
    Korea: 23077.16,
    Italy: 35815.62,
    Slovenia: 23570.18,
    Spain: 30566.88,
    Estonia: 14696.81,
    "Puerto Rico": 26435.74,
    Cyprus: 31439.29,
    "The Bahamas": 29303.26,
    "Czech Republic": 19983.58,
    "Brunei Darussalam": 35437.22,
    Aruba: 24084.88,
    Portugal: 22539.04,
    Lithuania: 12009.03,
    Kuwait: 32216.41,
    Bahrain: 20827.72,
    "Slovak Republic": 16783.99,
    Latvia: 11276.11,
    Hungary: 13173.21,
    "Saudi Arabia": 19163.34,
    Greece: 26743.4,
    Poland: 12619.62,
    Seychelles: 10805.1,
    Croatia: 13562.42,
    Romania: 8194.72,
    "St. Kitts and Nevis": 15136.91,
    Barbados: 16203.26,
    Chile: 12794.28,
    Uruguay: 12883.88,
    Panama: 8039.76,
    "Antigua and Barbuda": 13564.68,
    "Trinidad and Tobago": 16957.43,
    Maldives: 8086.7,
    Oman: 19027.78,
    Palau: 9986.29,
    Guyana: 3837.3,
    China: 4499.8,
    Bulgaria: 6713.33,
    Malaysia: 9046.99,
    "Islamic Republic of Iran": 6564.06,
    Turkey: 10533.45,
    "Costa Rica": 8268.86,
    Turkmenistan: 6855.9,
    "St. Lucia": 8968.07,
    Russia: 11431.15,
    Mauritius: 8000.38,
    Serbia: 5677.55,
    Montenegro: 6694.17,
    Grenada: 7258.18,
    Kazakhstan: 9005.04,
    Mexico: 9299.46,
    "Dominican Republic": 5688.75,
    Argentina: 10412.97,
    Dominica: 6980.96,
    Brazil: 11333.02,
    Nauru: 4748.08,
    Thailand: 5073.71,
    Gabon: 8933.31,
    "St. Vincent and the Grenadines": 6224.22,
    Botswana: 6202.47,
    "North Macedonia": 4576.23,
    "Bosnia and Herzegovina": 4633.86,
    Belarus: 6025.69,
    Tuvalu: 3133.04,
    "South Africa": 8130.19,
    Peru: 5051.22,
    Albania: 4097.83,
    "Equatorial Guinea": 17153.17,
    Georgia: 3222.07,
    Moldova: 2428.11,
    Colombia: 6498.57,
    Fiji: 4004.67,
    Ukraine: 2982.81,
    Kosovo: 3290.46,
    Armenia: 3121.78,
    Ecuador: 4633.25,
    Jamaica: 4894.37,
    Tonga: 3569.1,
    Paraguay: 4329.69,
    Vietnam: 1628.01,
    Iraq: 4473.71,
    Mongolia: 2602.37,
    Indonesia: 3178.13,
    Azerbaijan: 5880.81,
    Guatemala: 2836.06,
    Jordan: 3777.82,
    Suriname: 8806.02,
    Egypt: 2922.8,
    Namibia: 5410.98,
    Belize: 4270.94,
    "Marshall Islands": 3030.39,
    Djibouti: 1835.75,
    "El Salvador": 2983.23,
    Bhutan: 2097.66,
    Eswatini: 4263.91,
    Samoa: 3617.36,
    "Cabo Verde": 3312.04,
    "Sri Lanka": 2799.96,
    Micronesia: 2887.35,
    Libya: 11417.42,
    Philippines: 2237.16,
    Tunisia: 4140.47,
    Morocco: 2896.56,
    Bolivia: 1972.68,
    Nigeria: 2328.43,
    "West Bank and Gaza": 2406.26,
    Vanuatu: 2719.25,
    Algeria: 4480.72,
    "Papua New Guinea": 2132.19,
    "Lao P.D.R.": 1200.87,
    "CÙte d'Ivoire": 1650.96,
    Bangladesh: 781.15,
    Honduras: 1904.35,
    "Solomon Islands": 1604.15,
    Ghana: 1757.03,
    India: 1384.17,
    "São Tomé and Príncipe": 1128.31,
    Uzbekistan: 1777.49,
    "Republic of Congo": 3597.96,
    Kenya: 1176.31,
    Angola: 3641.44,
    Mauritania: 1715.92,
    Nicaragua: 1506.07,
    Cambodia: 788.19,
    Senegal: 1272.62,
    Kiribati: 1518.57,
    Cameroon: 1287.57,
    Benin: 1037.38,
    Zimbabwe: 975.85,
    Haiti: 1175.2,
    Comoros: 1322.64,
    Nepal: 677.19,
    "Kyrgyz Republic": 884.85,
    "Timor-Leste": 806.41,
    Guinea: 630.55,
    Tanzania: 732.32,
    Myanmar: 774.72,
    Uganda: 808.71,
    Togo: 721.04,
    Lesotho: 1222.2,
    Zambia: 1456.05,
    "Burkina Faso": 648.37,
    Mali: 733.67,
    "Guinea-Bissau": 644.34,
    Tajikistan: 738.29,
    "The Gambia": 860.64,
    Rwanda: 612.05,
    Sudan: 1637.42,
    Niger: 476.06,
    Chad: 895.35,
    Liberia: 541.58,
    Eritrea: 501.42,
    "Democratic Republic of the Congo": 333.64,
    Yemen: 1266.79,
    "Central African Republic": 492.08,
    Mozambique: 471.9,
    Madagascar: 471.96,
    "Sierra Leone": 401.84,
    Malawi: 623.41,
    Somalia: "n/a",
    "South Sudan": "n/a",
    Burundi: 231.55
  },
  2011: {
    Afghanistan: 714.7,
    Ethiopia: 379.38,
    Lebanon: 7675.29,
    Pakistan: 1216.75,
    Syria: "n/a",
    Venezuela: 12180.03,
    Luxembourg: 117366.28,
    Ireland: 52116.93,
    Switzerland: 91699.23,
    "Macao SAR": 66111.72,
    Norway: 100197.17,
    "United States": 50008.11,
    Denmark: 61864.09,
    Iceland: 47798.81,
    Singapore: 53891.46,
    Australia: 67208.49,
    Sweden: 60540.22,
    Netherlands: 54342.11,
    Austria: 51452.28,
    Qatar: 96827.86,
    Finland: 51263.57,
    Canada: 52285.94,
    Germany: 46697.38,
    Belgium: 47564.38,
    "San Marino": 57512.89,
    "Hong Kong SAR": 34955.15,
    "United Kingdom": 42044.62,
    Israel: 33787.43,
    "New Zealand": 38062.95,
    France: 45378.3,
    Japan: 48760.91,
    "United Arab Emirates": 41775.7,
    Andorra: 51957.31,
    "Taiwan Province of China": 20838.59,
    Malta: 23222.39,
    Korea: 25100.19,
    Italy: 38276.03,
    Slovenia: 25156.07,
    Spain: 31676.69,
    Estonia: 17484.73,
    "Puerto Rico": 27278.85,
    Cyprus: 32819.47,
    "The Bahamas": 28906.97,
    "Czech Republic": 21890.78,
    "Brunei Darussalam": 47092.3,
    Aruba: 25656.88,
    Portugal: 23213.13,
    Lithuania: 14393.27,
    Kuwait: 41657.44,
    Bahrain: 24080.43,
    "Slovak Republic": 18402.63,
    Latvia: 13809.19,
    Hungary: 14195.85,
    "Saudi Arabia": 23654.86,
    Greece: 25437.01,
    Poland: 13879.53,
    Seychelles: 11647.02,
    Croatia: 14608.2,
    Romania: 9075.96,
    "St. Kitts and Nevis": 16076.85,
    Barbados: 16599.01,
    Chile: 14610.8,
    Uruguay: 15267.78,
    Panama: 9314.69,
    "Antigua and Barbuda": 13295.36,
    "Trinidad and Tobago": 19300.83,
    Maldives: 8078.31,
    Oman: 20641.47,
    Palau: 10788.49,
    Guyana: 4402.78,
    China: 5553.24,
    Bulgaria: 7832.19,
    Malaysia: 10397.92,
    "Islamic Republic of Iran": 7719.43,
    Turkey: 11221.4,
    "Costa Rica": 9270.57,
    Turkmenistan: 8726.75,
    "St. Lucia": 9424.95,
    Russia: 14306.43,
    Mauritius: 9197.04,
    Serbia: 6814.65,
    Montenegro: 7327.25,
    Grenada: 7291.74,
    Kazakhstan: 11552.57,
    Mexico: 10232.41,
    "Dominican Republic": 6063.35,
    Argentina: 12787.81,
    Dominica: 7082.74,
    Brazil: 13295.9,
    Nauru: 6529.4,
    Thailand: 5493.74,
    Gabon: 10893.46,
    "St. Vincent and the Grenadines": 6171.48,
    Botswana: 7357.59,
    "North Macedonia": 5097.26,
    "Bosnia and Herzegovina": 5089.85,
    Belarus: 6478.89,
    Tuvalu: 3777.34,
    "South Africa": 8799.48,
    Peru: 5727.14,
    Albania: 4439.89,
    "Equatorial Guinea": 21480,
    Georgia: 4003.47,
    Moldova: 2930.06,
    Colombia: 7518.3,
    Fiji: 4797.05,
    Ukraine: 3589.63,
    Kosovo: 3763.91,
    Armenia: 3417.17,
    Ecuador: 5192.88,
    Jamaica: 5332.52,
    Tonga: 4014.77,
    Paraguay: 5301.83,
    Vietnam: 1949.83,
    Iraq: 5848.54,
    Mongolia: 3702.36,
    Indonesia: 3688.53,
    Azerbaijan: 7242.81,
    Guatemala: 3172.12,
    Jordan: 3897.61,
    Suriname: 8770.29,
    Egypt: 3077.34,
    Namibia: 5870.93,
    Belize: 4398.88,
    "Marshall Islands": 3239.11,
    Djibouti: 2036.94,
    "El Salvador": 3266.01,
    Bhutan: 2511.6,
    Eswatini: 4603.2,
    Samoa: 4044.81,
    "Cabo Verde": 3672.58,
    "Sri Lanka": 3199.48,
    Micronesia: 3021.69,
    Libya: 5402.25,
    Philippines: 2473.25,
    Tunisia: 4256.84,
    Morocco: 3111.54,
    Bolivia: 2368.52,
    Nigeria: 2543.5,
    "West Bank and Gaza": 2711.92,
    Vanuatu: 3007.56,
    Algeria: 5447.6,
    "Papua New Guinea": 2552.04,
    "Lao P.D.R.": 1412.04,
    "CÙte d'Ivoire": 1660.45,
    Bangladesh: 861.54,
    Honduras: 2088.31,
    "Solomon Islands": 1938.79,
    Ghana: 2131.73,
    India: 1458.11,
    "São Tomé and Príncipe": 1302.78,
    Uzbekistan: 2067.52,
    "Republic of Congo": 4175.34,
    Kenya: 1178.58,
    Angola: 4716.25,
    Mauritania: 2012.83,
    Nicaragua: 1629.97,
    Cambodia: 889.79,
    Senegal: 1366.53,
    Kiribati: 1741.14,
    Cameroon: 1404.85,
    Benin: 1130.07,
    Zimbabwe: 1131.84,
    Haiti: 1269.35,
    Comoros: 1450.16,
    Nepal: 803.72,
    "Kyrgyz Republic": 1131.47,
    "Timor-Leste": 947.51,
    Guinea: 540.56,
    Tanzania: 768.16,
    Myanmar: 1088.75,
    Uganda: 872.04,
    Togo: 791.93,
    Lesotho: 1324.61,
    Zambia: 1635.17,
    "Burkina Faso": 751.04,
    Mali: 863.35,
    "Guinea-Bissau": 775.21,
    Tajikistan: 834.52,
    "The Gambia": 762.76,
    Rwanda: 674.66,
    Sudan: 2034.46,
    Niger: 511.49,
    Chad: 985.63,
    Liberia: 625.26,
    Eritrea: 642.5,
    "Democratic Republic of the Congo": 387.28,
    Yemen: 1302.3,
    "Central African Republic": 551.03,
    Mozambique: 594.59,
    Madagascar: 531.27,
    "Sierra Leone": 448.32,
    Malawi: 695.33,
    Somalia: "n/a",
    "South Sudan": 1644.74,
    Burundi: 247.1
  },
  2012: {
    Afghanistan: 784.61,
    Ethiopia: 493.32,
    Lebanon: 7952.12,
    Pakistan: 1247.1,
    Syria: "n/a",
    Venezuela: 11993.39,
    Luxembourg: 108050.47,
    Ireland: 49000.81,
    Switzerland: 87037.3,
    "Macao SAR": 74209.14,
    Norway: 101129.91,
    "United States": 51736.74,
    Denmark: 58623.41,
    Iceland: 46159.8,
    Singapore: 55547.55,
    Australia: 68445.12,
    Sweden: 57816.02,
    Netherlands: 50175.56,
    Austria: 48616.9,
    Qatar: 101933.12,
    Finland: 47850.56,
    Canada: 52744,
    Germany: 43883.39,
    Belgium: 44824.16,
    "San Marino": 50336.19,
    "Hong Kong SAR": 36623.75,
    "United Kingdom": 42453.47,
    Israel: 32681.2,
    "New Zealand": 39683.43,
    France: 42348.74,
    Japan: 49175.05,
    "United Arab Emirates": 43932.9,
    Andorra: 45713.97,
    "Taiwan Province of China": 21256.36,
    Malta: 22674.79,
    Korea: 25459.17,
    Italy: 34743.73,
    Slovenia: 22674.37,
    Spain: 28344.78,
    Estonia: 17414.24,
    "Puerto Rico": 27944.74,
    Cyprus: 28993.69,
    "The Bahamas": 30433.05,
    "Czech Republic": 19880.9,
    "Brunei Darussalam": 47772.21,
    Aruba: 25006.76,
    Portugal: 20576.83,
    Lithuania: 14376,
    Kuwait: 45726.19,
    Bahrain: 25434.45,
    "Slovak Republic": 17502.51,
    Latvia: 13862.39,
    Hungary: 12935.51,
    "Saudi Arabia": 25208.17,
    Greece: 21845.97,
    Poland: 13096.89,
    Seychelles: 11999.9,
    Croatia: 13257.02,
    Romania: 8491.03,
    "St. Kitts and Nevis": 15710.51,
    Barbados: 16371.95,
    Chile: 15307.8,
    Uruguay: 16253.47,
    Panama: 10674.49,
    "Antigua and Barbuda": 13811.08,
    "Trinidad and Tobago": 19157.45,
    Maldives: 8719.58,
    Oman: 21149.65,
    Palau: 12058.41,
    Guyana: 5360.28,
    China: 6282.71,
    Bulgaria: 7417.27,
    Malaysia: 10806.84,
    "Islamic Republic of Iran": 5159.2,
    Turkey: 11637.92,
    "Costa Rica": 10107.45,
    Turkmenistan: 9824.93,
    "St. Lucia": 9495.19,
    Russia: 15287.97,
    Mauritius: 9291.24,
    Serbia: 6016.79,
    Montenegro: 6590.58,
    Grenada: 7444.08,
    Kazakhstan: 12300.19,
    Mexico: 10271.41,
    "Dominican Republic": 6274.87,
    Argentina: 13889.79,
    Dominica: 6994.87,
    Brazil: 12424.95,
    Nauru: 9382.64,
    Thailand: 5862.99,
    Gabon: 9903.41,
    "St. Vincent and the Grenadines": 6318.54,
    Botswana: 7609.17,
    "North Macedonia": 4728.37,
    "Bosnia and Herzegovina": 4773.16,
    Belarus: 6948.35,
    Tuvalu: 3609.11,
    "South Africa": 8207.06,
    Peru: 6399.34,
    Albania: 4248.91,
    "Equatorial Guinea": 21556.42,
    Georgia: 4409.61,
    Moldova: 3031.13,
    Colombia: 8237.29,
    Fiji: 5017.72,
    Ukraine: 3872.53,
    Kosovo: 3582.13,
    Armenia: 3575.53,
    Ecuador: 5664.89,
    Jamaica: 5448.46,
    Tonga: 4557.28,
    Paraguay: 5153.42,
    Vietnam: 2197.62,
    Iraq: 6692.58,
    Mongolia: 4281.33,
    Indonesia: 3744.53,
    Azerbaijan: 7545.87,
    Guatemala: 3267.79,
    Jordan: 3963.59,
    Suriname: 9844.75,
    Egypt: 3379.01,
    Namibia: 6038.86,
    Belize: 4468.7,
    "Marshall Islands": 3383.56,
    Djibouti: 2188.54,
    "El Salvador": 3428.41,
    Bhutan: 2592.37,
    Eswatini: 4628.25,
    Samoa: 4014.46,
    "Cabo Verde": 3388.88,
    "Sri Lanka": 3347.89,
    Micronesia: 3170.96,
    Libya: 12693.64,
    Philippines: 2721.25,
    Tunisia: 4137.57,
    Morocco: 2979.74,
    Bolivia: 2635.93,
    Nigeria: 2756.41,
    "West Bank and Gaza": 2888.6,
    Vanuatu: 2934.85,
    Algeria: 5575.65,
    "Papua New Guinea": 2865.78,
    "Lao P.D.R.": 1581.9,
    "CÙte d'Ivoire": 1686.73,
    Bangladesh: 882.76,
    Honduras: 2144.34,
    "Solomon Islands": 2141.92,
    Ghana: 2197.89,
    India: 1443.88,
    "São Tomé and Príncipe": 1380.11,
    Uzbekistan: 2284.53,
    "Republic of Congo": 4607.39,
    Kenya: 1396.21,
    Angola: 5245.02,
    Mauritania: 1946.08,
    Nicaragua: 1734.79,
    Cambodia: 965.41,
    Senegal: 1318.61,
    Kiribati: 1798.26,
    Cameroon: 1355.4,
    Benin: 1145.87,
    Zimbabwe: 1310.37,
    Haiti: 1316.5,
    Comoros: 1403.42,
    Nepal: 804.14,
    "Kyrgyz Republic": 1189.52,
    "Timor-Leste": 1013.04,
    Guinea: 637.79,
    Tanzania: 867.86,
    Myanmar: 1182.15,
    Uganda: 950.56,
    Togo: 771.89,
    Lesotho: 1261.44,
    Zambia: 1724.63,
    "Burkina Faso": 758.48,
    Mali: 802.47,
    "Guinea-Bissau": 688.5,
    Tajikistan: 949.65,
    "The Gambia": 742.78,
    Rwanda: 728.67,
    Sudan: 1396.29,
    Niger: 528.92,
    Chad: 970.74,
    Liberia: 695.52,
    Eritrea: 693.8,
    "Democratic Republic of the Congo": 425.23,
    Yemen: 1367.72,
    "Central African Republic": 566.2,
    Mozambique: 657.65,
    Madagascar: 518.16,
    "Sierra Leone": 566.37,
    Malawi: 506.3,
    Somalia: 301.68,
    "South Sudan": 1041.46,
    Burundi: 250.12
  },
  2013: {
    Afghanistan: 754.4,
    Ethiopia: 535.26,
    Lebanon: 7933.25,
    Pakistan: 1263.59,
    Syria: "n/a",
    Venezuela: 8695.04,
    Luxembourg: 114996.72,
    Ireland: 51459,
    Switzerland: 88721.54,
    "Macao SAR": 84833.69,
    Norway: 102576.68,
    "United States": 53245.52,
    Denmark: 61325.58,
    Iceland: 50100.08,
    Singapore: 56967.43,
    Australia: 65174.72,
    Sweden: 60845.01,
    Netherlands: 52276.99,
    Austria: 50747.38,
    Qatar: 99180.39,
    Finland: 50006.02,
    Canada: 52708.61,
    Germany: 46299.37,
    Belgium: 46848.61,
    "San Marino": 51986.53,
    "Hong Kong SAR": 38233.35,
    "United Kingdom": 43444.63,
    Israel: 36515.12,
    "New Zealand": 42089.46,
    France: 44130.83,
    Japan: 40934.76,
    "United Arab Emirates": 45040.07,
    Andorra: 45630.2,
    "Taiwan Province of China": 21945.46,
    Malta: 24972.68,
    Korea: 27179.52,
    Italy: 35534.99,
    Slovenia: 23516.59,
    Spain: 29084.96,
    Estonia: 19056.28,
    "Puerto Rico": 28513.15,
    Cyprus: 27601.85,
    "The Bahamas": 29462.76,
    "Czech Republic": 20129.62,
    "Brunei Darussalam": 44860.43,
    Aruba: 25641.78,
    Portugal: 21653.51,
    Lithuania: 15734.38,
    Kuwait: 44754.34,
    Bahrain: 25965.31,
    "Slovak Republic": 18274.09,
    Latvia: 15041.85,
    Hungary: 13665.53,
    "Saudi Arabia": 24892.98,
    Greece: 21679.67,
    Poland: 13688.36,
    Seychelles: 14762.22,
    Croatia: 13673.37,
    Romania: 9530.44,
    "St. Kitts and Nevis": 16464.04,
    Barbados: 16556.1,
    Chile: 15804.5,
    Uruguay: 18167.34,
    Panama: 11841.89,
    "Antigua and Barbuda": 13392.22,
    "Trinidad and Tobago": 20143.46,
    Maldives: 9766.24,
    Oman: 20435.85,
    Palau: 12865.53,
    Guyana: 5476.74,
    China: 7039.57,
    Bulgaria: 7675.91,
    Malaysia: 10851.66,
    "Islamic Republic of Iran": 5199.85,
    Turkey: 12488.97,
    "Costa Rica": 10764.53,
    Turkmenistan: 9899.53,
    "St. Lucia": 9756.13,
    Russia: 15928.7,
    Mauritius: 9637.02,
    Serbia: 6757.47,
    Montenegro: 7189.05,
    Grenada: 7789.62,
    Kazakhstan: 13789.17,
    Mexico: 10758.98,
    "Dominican Republic": 6413.93,
    Argentina: 14488.83,
    Dominica: 7153.47,
    Brazil: 12358.34,
    Nauru: 9103.52,
    Thailand: 6168.71,
    Gabon: 9760.75,
    "St. Vincent and the Grenadines": 6569.79,
    Botswana: 6966.08,
    "North Macedonia": 5239.65,
    "Bosnia and Herzegovina": 5125.6,
    Belarus: 7995.73,
    Tuvalu: 3615.37,
    "South Africa": 7458.04,
    Peru: 6631.03,
    Albania: 4415.6,
    "Equatorial Guinea": 20252.75,
    Georgia: 4622.97,
    Moldova: 3307.35,
    Colombia: 8409.68,
    Fiji: 5268.16,
    Ukraine: 3968.85,
    Kosovo: 3885.72,
    Armenia: 3732.04,
    Ecuador: 6030.5,
    Jamaica: 5238.15,
    Tonga: 4386.11,
    Paraguay: 5892.85,
    Vietnam: 2369.97,
    Iraq: 7021.41,
    Mongolia: 4293.83,
    Indonesia: 3684,
    Azerbaijan: 7926.09,
    Guatemala: 3397.63,
    Jordan: 4100.94,
    Suriname: 10013.73,
    Egypt: 3404.34,
    Namibia: 5540.62,
    Belize: 4516.11,
    "Marshall Islands": 3451.21,
    Djibouti: 2312.72,
    "El Salvador": 3509.53,
    Bhutan: 2609.65,
    Eswatini: 4326,
    Samoa: 4007.89,
    "Cabo Verde": 3557.87,
    "Sri Lanka": 3607.15,
    Micronesia: 3068.37,
    Libya: 8282.15,
    Philippines: 2903.19,
    Tunisia: 4198.85,
    Morocco: 3200.48,
    Bolivia: 2938.42,
    Nigeria: 2998.07,
    "West Bank and Gaza": 3122.99,
    Vanuatu: 2940.19,
    Algeria: 5477.06,
    "Papua New Guinea": 2800.17,
    "Lao P.D.R.": 1830.51,
    "CÙte d'Ivoire": 1919.16,
    Bangladesh: 982.07,
    Honduras: 2102.6,
    "Solomon Islands": 2248.69,
    Ghana: 2405.78,
    India: 1449.61,
    "São Tomé and Príncipe": 1619.71,
    Uzbekistan: 2440.26,
    "Republic of Congo": 4559.73,
    Kenya: 1490.41,
    Angola: 5436.52,
    Mauritania: 2072.49,
    Nicaragua: 1790.43,
    Cambodia: 1034.82,
    Senegal: 1372.69,
    Kiribati: 1731.38,
    Cameroon: 1465.66,
    Benin: 1251.23,
    Zimbabwe: 1421.55,
    Haiti: 1408.81,
    Comoros: 1500.73,
    Nepal: 823.32,
    "Kyrgyz Republic": 1295.23,
    "Timor-Leste": 1210.04,
    Guinea: 712.98,
    Tanzania: 970.35,
    Myanmar: 1210.22,
    Uganda: 964.83,
    Togo: 838.36,
    Lesotho: 1191.99,
    Zambia: 1839.33,
    "Burkina Faso": 787.48,
    Mali: 828.74,
    "Guinea-Bissau": 711.93,
    Tajikistan: 1040.15,
    "The Gambia": 700.52,
    Rwanda: 730.47,
    Sudan: 1462.58,
    Niger: 551.55,
    Chad: 982.92,
    Liberia: 772.24,
    Eritrea: 596.75,
    "Democratic Republic of the Congo": 458.18,
    Yemen: 1515.95,
    "Central African Republic": 380.3,
    Mozambique: 664.08,
    Madagascar: 541.07,
    "Sierra Leone": 716.15,
    Malawi: 447,
    Somalia: 316.02,
    "South Sudan": 1337.23,
    Burundi: 255.32
  },
  2014: {
    Afghanistan: 746.92,
    Ethiopia: 613.1,
    Lebanon: 7687.75,
    Pakistan: 1310.17,
    Syria: "n/a",
    Venezuela: 6747.67,
    Luxembourg: 120449.74,
    Ireland: 55499.03,
    Switzerland: 90258.36,
    "Macao SAR": 86298.09,
    Norway: 96657.58,
    "United States": 55083.51,
    Denmark: 62729.5,
    Iceland: 54864.16,
    Singapore: 57564.8,
    Australia: 61648.08,
    Sweden: 59704.81,
    Netherlands: 53026.48,
    Austria: 51814.42,
    Qatar: 93054.14,
    Finland: 50434.79,
    Canada: 51020.84,
    Germany: 48035.8,
    Belgium: 47897.04,
    "San Marino": 51234.87,
    "Hong Kong SAR": 40185.3,
    "United Kingdom": 47480.8,
    Israel: 37864.41,
    "New Zealand": 44282.11,
    France: 44545.72,
    Japan: 38522.77,
    "United Arab Emirates": 45817.07,
    Andorra: 46299.28,
    "Taiwan Province of China": 22844.32,
    Malta: 27080.1,
    Korea: 29252.93,
    Italy: 35836.18,
    Slovenia: 24263.9,
    Spain: 29524.76,
    Estonia: 20253.97,
    "Puerto Rico": 28981.34,
    Cyprus: 26995.34,
    "The Bahamas": 30937.32,
    "Czech Republic": 19915.38,
    "Brunei Darussalam": 41947.16,
    Aruba: 25883.62,
    Portugal: 22109.32,
    Lithuania: 16577.33,
    Kuwait: 40278.05,
    Bahrain: 25398.32,
    "Slovak Republic": 18713.36,
    Latvia: 15678.08,
    Hungary: 14251.8,
    "Saudi Arabia": 24929.32,
    Greece: 21567.97,
    Poland: 14272.29,
    Seychelles: 14701.37,
    Croatia: 13600.79,
    Romania: 10021.54,
    "St. Kitts and Nevis": 17753.63,
    Barbados: 16572.31,
    Chile: 14643.5,
    Uruguay: 18003.21,
    Panama: 12756.95,
    "Antigua and Barbuda": 13951.99,
    "Trinidad and Tobago": 20270.95,
    Maldives: 10787.47,
    Oman: 20308.46,
    Palau: 13993.29,
    Guyana: 5402.7,
    China: 7645.88,
    Bulgaria: 7900.7,
    Malaysia: 11165.26,
    "Islamic Republic of Iran": 5519.14,
    Turkey: 12079.29,
    "Costa Rica": 10853.64,
    Turkmenistan: 10591.66,
    "St. Lucia": 10172.81,
    Russia: 14007.51,
    Mauritius: 10151.73,
    Serbia: 6603.5,
    Montenegro: 7389.89,
    Grenada: 8370.03,
    Kazakhstan: 12713.56,
    Mexico: 10967.11,
    "Dominican Republic": 6805.66,
    Argentina: 13208.83,
    Dominica: 7388.58,
    Brazil: 12175.68,
    Nauru: 8865.6,
    Thailand: 5952.38,
    Gabon: 9746.79,
    "St. Vincent and the Grenadines": 6622.44,
    Botswana: 7499.16,
    "North Macedonia": 5498.61,
    "Bosnia and Herzegovina": 5319.32,
    Belarus: 8337.15,
    Tuvalu: 3583.65,
    "South Africa": 6984.93,
    Peru: 6564.46,
    Albania: 4584.92,
    "Equatorial Guinea": 19271.29,
    Georgia: 4742.5,
    Moldova: 3314.55,
    Colombia: 8312.06,
    Fiji: 5611.19,
    Ukraine: 3053.61,
    Kosovo: 4098.93,
    Armenia: 3889,
    Ecuador: 6347,
    Jamaica: 5105.51,
    Tonga: 4303.82,
    Paraguay: 6065.28,
    Vietnam: 2566.85,
    Iraq: 6704.31,
    Mongolia: 4081.02,
    Indonesia: 3533.61,
    Azerbaijan: 7939.17,
    Guatemala: 3632.1,
    Jordan: 4188.72,
    Suriname: 10042.68,
    Egypt: 3520.36,
    Namibia: 5555.93,
    Belize: 4645.69,
    "Marshall Islands": 3388.38,
    Djibouti: 2465.99,
    "El Salvador": 3589.04,
    Bhutan: 2546.91,
    Eswatini: 4133.03,
    Samoa: 3937.71,
    "Cabo Verde": 3532.99,
    "Sri Lanka": 3817.13,
    Micronesia: 3082.9,
    Libya: 3876.41,
    Philippines: 2995.5,
    Tunisia: 4274.47,
    Morocco: 3259.72,
    Bolivia: 3114.97,
    Nigeria: 3222.69,
    "West Bank and Gaza": 3158.6,
    Vanuatu: 2947.95,
    Algeria: 5466.33,
    "Papua New Guinea": 2992.71,
    "Lao P.D.R.": 1997.98,
    "CÙte d'Ivoire": 2115.19,
    Bangladesh: 1118.86,
    Honduras: 2206.06,
    "Solomon Islands": 2278.42,
    Ghana: 2003.71,
    India: 1573.88,
    "São Tomé and Príncipe": 1826.92,
    Uzbekistan: 2651.38,
    "Republic of Congo": 4438.24,
    Kenya: 1613.09,
    Angola: 5625.74,
    Mauritania: 1826.78,
    Nicaragua: 1916.77,
    Cambodia: 1123.18,
    Senegal: 1397.02,
    Kiribati: 1655.86,
    Cameroon: 1543.03,
    Benin: 1291.74,
    Zimbabwe: 1415.03,
    Haiti: 1408.67,
    Comoros: 1505.18,
    Nepal: 844.5,
    "Kyrgyz Republic": 1292.62,
    "Timor-Leste": 1232.45,
    Guinea: 728.73,
    Tanzania: 1030.74,
    Myanmar: 1244.18,
    Uganda: 960.7,
    Togo: 865,
    Lesotho: 1250.63,
    Zambia: 1726.65,
    "Burkina Faso": 793.05,
    Mali: 873.51,
    "Guinea-Bissau": 712.83,
    Tajikistan: 1105.2,
    "The Gambia": 607.43,
    Rwanda: 748.53,
    Sudan: 1628.5,
    Niger: 562.89,
    Chad: 1024.84,
    Liberia: 765.37,
    Eritrea: 786.54,
    "Democratic Republic of the Congo": 486.82,
    Yemen: 1574.25,
    "Central African Republic": 424.67,
    Mozambique: 673.97,
    Madagascar: 530.86,
    "Sierra Leone": 713.53,
    Malawi: 484.32,
    Somalia: 312.73,
    "South Sudan": 1334.09,
    Burundi: 273.13
  },
  2015: {
    Afghanistan: 705.6,
    Ethiopia: 702.77,
    Lebanon: 7663.88,
    Pakistan: 1422.92,
    Syria: "n/a",
    Venezuela: 10568.1,
    Luxembourg: 102624.73,
    Ireland: 61861.2,
    Switzerland: 85238.52,
    "Macao SAR": 69666.75,
    Norway: 74115.16,
    "United States": 56729.68,
    Denmark: 53478.5,
    Iceland: 53227.63,
    Singapore: 55645.61,
    Australia: 51484.64,
    Sweden: 51274.28,
    Netherlands: 45302.8,
    Austria: 44267.81,
    Qatar: 66346.91,
    Finland: 42867.09,
    Canada: 43626.47,
    Germany: 41107.22,
    Belgium: 41147.26,
    "San Marino": 43068.69,
    "Hong Kong SAR": 42325.35,
    "United Kingdom": 45053.49,
    Israel: 35822.86,
    "New Zealand": 38200.04,
    France: 37918.77,
    Japan: 35005.66,
    "United Arab Emirates": 40064.45,
    Andorra: 38877.49,
    "Taiwan Province of China": 22752.99,
    Malta: 25228.18,
    Korea: 28737.44,
    Italy: 30463.7,
    Slovenia: 20898.86,
    Spain: 25764.22,
    Estonia: 17403.81,
    "Puerto Rico": 29755.35,
    Cyprus: 23428.72,
    "The Bahamas": 32648.64,
    "Czech Republic": 17842.87,
    "Brunei Darussalam": 31355.74,
    Aruba: 27126.62,
    Portugal: 19252.02,
    Lithuania: 14265.41,
    Kuwait: 27398.08,
    Bahrain: 22659.37,
    "Slovak Republic": 16326.59,
    Latvia: 13722.08,
    Hungary: 12690.17,
    "Saudi Arabia": 21063.32,
    Greece: 17997.4,
    Poland: 12563.61,
    Seychelles: 14786.12,
    Croatia: 11780.6,
    Romania: 8942.2,
    "St. Kitts and Nevis": 17654.13,
    Barbados: 16589.45,
    Chile: 13571.02,
    Uruguay: 16692.65,
    Panama: 13606.6,
    "Antigua and Barbuda": 14696.89,
    "Trinidad and Tobago": 18214.37,
    Maldives: 11781.03,
    Oman: 16570.74,
    Palau: 16408.16,
    Guyana: 5579.97,
    China: 8034.29,
    Bulgaria: 7079.78,
    Malaysia: 9663.11,
    "Islamic Republic of Iran": 4844.5,
    Turkey: 10973.59,
    "Costa Rica": 11635.2,
    Turkmenistan: 7575.88,
    "St. Lucia": 10386.82,
    Russia: 9257.94,
    Mauritius: 9258.56,
    Serbia: 5588.98,
    Montenegro: 6517.42,
    Grenada: 9096.54,
    Kazakhstan: 10435.17,
    Mexico: 9657.12,
    "Dominican Republic": 7139.51,
    Argentina: 14895.32,
    Dominica: 7596.22,
    Brazil: 8846.48,
    Nauru: 6994.05,
    Thailand: 5837.81,
    Gabon: 7453.11,
    "St. Vincent and the Grenadines": 6867.52,
    Botswana: 6413.79,
    "North Macedonia": 4860.42,
    "Bosnia and Herzegovina": 4727.26,
    Belarus: 5958.84,
    Tuvalu: 3396.69,
    "South Africa": 6256.67,
    Peru: 6139.23,
    Albania: 3953.61,
    "Equatorial Guinea": 11217.99,
    Georgia: 4017.62,
    Moldova: 2715.78,
    Colombia: 6337.04,
    Fiji: 5385.52,
    Ukraine: 2124.33,
    Kosovo: 3637.47,
    Armenia: 3529.03,
    Ecuador: 6099.35,
    Jamaica: 5204.83,
    Tonga: 4298.22,
    Paraguay: 5360.08,
    Vietnam: 2581.91,
    Iraq: 5044.62,
    Mongolia: 3842.54,
    Indonesia: 3367.69,
    Azerbaijan: 5300.14,
    Guatemala: 3825.88,
    Jordan: 4218.83,
    Suriname: 9036.35,
    Egypt: 3731.18,
    Namibia: 5020.47,
    Belize: 4674.6,
    "Marshall Islands": 3406.48,
    Djibouti: 2675.58,
    "El Salvador": 3705.58,
    Bhutan: 2693.63,
    Eswatini: 3765.92,
    Samoa: 4073.68,
    "Cabo Verde": 2996.36,
    "Sri Lanka": 3841.52,
    Micronesia: 3050.72,
    Libya: 2723.37,
    Philippines: 3039.23,
    Tunisia: 3829.5,
    Morocco: 2964.96,
    Bolivia: 3069.32,
    Nigeria: 2718.59,
    "West Bank and Gaza": 3084.13,
    Vanuatu: 2771.66,
    Algeria: 4153.32,
    "Papua New Guinea": 2742.92,
    "Lao P.D.R.": 2130.63,
    "CÙte d'Ivoire": 1932.22,
    Bangladesh: 1248.89,
    Honduras: 2302.2,
    "Solomon Islands": 2174.31,
    Ghana: 1784.25,
    India: 1605.61,
    "São Tomé and Príncipe": 1623.81,
    Uzbekistan: 2761.29,
    "Republic of Congo": 2873.72,
    Kenya: 1625.16,
    Angola: 4354.92,
    Mauritania: 1668.07,
    Nicaragua: 2036.93,
    Cambodia: 1203.48,
    Senegal: 1219.37,
    Kiribati: 1556.5,
    Cameroon: 1327.64,
    Benin: 1076.91,
    Zimbabwe: 1425.01,
    Haiti: 1359.21,
    Comoros: 1231.14,
    Nepal: 901.75,
    "Kyrgyz Republic": 1132.84,
    "Timor-Leste": 1332.79,
    Guinea: 712.05,
    Tanzania: 947.9,
    Myanmar: 1223.74,
    Uganda: 773.28,
    Togo: 770.36,
    Lesotho: 1106.83,
    Zambia: 1310.46,
    "Burkina Faso": 653.39,
    Mali: 773.95,
    "Guinea-Bissau": 707.9,
    Tajikistan: 919.07,
    "The Gambia": 649.51,
    Rwanda: 755.95,
    Sudan: 1679.07,
    Niger: 484.17,
    Chad: 776.11,
    Liberia: 747.18,
    Eritrea: 603.19,
    "Democratic Republic of the Congo": 497.28,
    Yemen: 1500.67,
    "Central African Republic": 377.48,
    Mozambique: 589.86,
    Madagascar: 467.24,
    "Sierra Leone": 592.9,
    Malawi: 497.74,
    Somalia: 310.46,
    "South Sudan": 1245.68,
    Burundi: 304.24
  },
  2016: {
    Afghanistan: 617.13,
    Ethiopia: 790.82,
    Lebanon: 7653.69,
    Pakistan: 1436.37,
    Syria: "n/a",
    Venezuela: 9092.02,
    Luxembourg: 105364.03,
    Ireland: 62632.81,
    Switzerland: 83515.54,
    "Macao SAR": 69910.52,
    Norway: 70223.82,
    "United States": 57839.99,
    Denmark: 54862.85,
    Iceland: 62530.42,
    Singapore: 56846.27,
    Australia: 51915.24,
    Sweden: 51590.47,
    Netherlands: 46165.18,
    Austria: 45278.83,
    Qatar: 57965.38,
    Finland: 43865.76,
    Canada: 42382.64,
    Germany: 42124.45,
    Belgium: 42076.4,
    "San Marino": 44308.67,
    "Hong Kong SAR": 43491.31,
    "United Kingdom": 41177.83,
    Israel: 37341.6,
    "New Zealand": 39426.72,
    France: 38332.68,
    Japan: 39411.42,
    "United Arab Emirates": 39144.67,
    Andorra: 39595.32,
    "Taiwan Province of China": 23070.73,
    Malta: 25960.31,
    Korea: 29274.23,
    Italy: 31190.79,
    Slovenia: 21681.28,
    Spain: 26535.55,
    Estonia: 18290.29,
    "Puerto Rico": 30585.55,
    Cyprus: 24692.5,
    "The Bahamas": 32565.04,
    "Czech Republic": 18597.21,
    "Brunei Darussalam": 27318.05,
    Aruba: 27036.93,
    Portugal: 19986.36,
    Lithuania: 15004.19,
    Kuwait: 25267.15,
    Bahrain: 22641.28,
    "Slovak Republic": 16529.16,
    Latvia: 14253.04,
    Hungary: 13069.23,
    "Saudi Arabia": 20288.9,
    Greece: 17879.71,
    Poland: 12438.52,
    Seychelles: 15199.21,
    Croatia: 12362.59,
    Romania: 9520.44,
    "St. Kitts and Nevis": 18397.05,
    Barbados: 16947.63,
    Chile: 13779.4,
    Uruguay: 16446.23,
    Panama: 14344.09,
    "Antigua and Barbuda": 15556.01,
    "Trinidad and Tobago": 16256.46,
    Maldives: 12348.71,
    Oman: 14834.59,
    Palau: 16776.84,
    Guyana: 5796.82,
    China: 8063.45,
    Bulgaria: 7575.77,
    Malaysia: 9523.3,
    "Islamic Republic of Iran": 5195.91,
    Turkey: 10891.19,
    "Costa Rica": 11986.92,
    Turkmenistan: 6823.71,
    "St. Lucia": 10610.41,
    Russia: 8723.52,
    Mauritius: 9681.42,
    Serbia: 5765.2,
    Montenegro: 7031.53,
    Grenada: 9628.26,
    Kazakhstan: 7662.01,
    Mexico: 8788.59,
    "Dominican Republic": 7521.27,
    Argentina: 12772.87,
    Dominica: 8089.25,
    Brazil: 8757.31,
    Nauru: 7674.48,
    Thailand: 5995.21,
    Gabon: 7082.22,
    "St. Vincent and the Grenadines": 7033.49,
    Botswana: 6988.16,
    "North Macedonia": 5152.97,
    "Bosnia and Herzegovina": 4994.07,
    Belarus: 5037.85,
    Tuvalu: 3798.27,
    "South Africa": 5750.79,
    Peru: 6169.39,
    Albania: 4124.41,
    "Equatorial Guinea": 9202.54,
    Georgia: 4060.89,
    Moldova: 2857.88,
    Colombia: 6037.13,
    Fiji: 5620.58,
    Ukraine: 2200,
    Kosovo: 3766.21,
    Armenia: 3524,
    Ecuador: 6046.3,
    Jamaica: 5183.46,
    Tonga: 4178.29,
    Paraguay: 5265.06,
    Vietnam: 2720.17,
    Iraq: 4637,
    Mongolia: 3576.82,
    Indonesia: 3605.72,
    Azerbaijan: 3897.71,
    Guatemala: 3982.02,
    Jordan: 4224.8,
    Suriname: 5761.78,
    Egypt: 3653.67,
    Namibia: 4611.51,
    Belize: 4734.01,
    "Marshall Islands": 3720.62,
    Djibouti: 2818.34,
    "El Salvador": 3805.99,
    Bhutan: 2803.64,
    Eswatini: 3513.8,
    Samoa: 4109.09,
    "Cabo Verde": 3082.16,
    "Sri Lanka": 3885.76,
    Micronesia: 3197.21,
    Libya: 2907.46,
    Philippines: 3107.65,
    Tunisia: 3665.68,
    Morocco: 2995.68,
    Bolivia: 3110.87,
    Nigeria: 2176,
    "West Bank and Gaza": 3325.85,
    Vanuatu: 2844.13,
    Algeria: 3918.94,
    "Papua New Guinea": 2567.58,
    "Lao P.D.R.": 2323.28,
    "CÙte d'Ivoire": 1971.62,
    Bangladesh: 1401.51,
    Honduras: 2342.02,
    "Solomon Islands": 2229.57,
    Ghana: 1982.24,
    India: 1732.05,
    "São Tomé and Príncipe": 1737.73,
    Uzbekistan: 2712.81,
    "Republic of Congo": 2395.18,
    Kenya: 1688.87,
    Angola: 3676.83,
    Mauritania: 1690.81,
    Nicaragua: 2099.59,
    Cambodia: 1320.33,
    Senegal: 1269.55,
    Kiribati: 1598.89,
    Cameroon: 1363.95,
    Benin: 1086.99,
    Zimbabwe: 1444.26,
    Haiti: 1263.43,
    Comoros: 1256.04,
    Nepal: 899.52,
    "Kyrgyz Republic": 1131.84,
    "Timor-Leste": 1353.75,
    Guinea: 679.96,
    Tanzania: 966.29,
    Myanmar: 1163.79,
    Uganda: 809.57,
    Togo: 802.94,
    Lesotho: 1105.77,
    Zambia: 1254.12,
    "Burkina Faso": 688.06,
    Mali: 804.08,
    "Guinea-Bissau": 748.09,
    Tajikistan: 795.98,
    "The Gambia": 684,
    Rwanda: 755.74,
    Sudan: 1638.51,
    Niger: 497.88,
    Chad: 700.56,
    Liberia: 767.37,
    Eritrea: 655.42,
    "Democratic Republic of the Congo": 465.03,
    Yemen: 1075.03,
    "Central African Republic": 402.05,
    Mozambique: 428.93,
    Madagascar: 476.23,
    "Sierra Leone": 525.95,
    Malawi: 415.02,
    Somalia: 312.86,
    "South Sudan": 286.21,
    Burundi: 281.06
  },
  2017: {
    Afghanistan: 635.79,
    Ethiopia: 829.31,
    Lebanon: 7819.59,
    Pakistan: 1544.17,
    Syria: "n/a",
    Venezuela: 4725.05,
    Luxembourg: 108621.94,
    Ireland: 69486.55,
    Switzerland: 83700.47,
    "Macao SAR": 77257.33,
    Norway: 75306.67,
    "United States": 59885.71,
    Denmark: 57772.55,
    Iceland: 73085.13,
    Singapore: 61175.44,
    Australia: 55915,
    Sweden: 53459.07,
    Netherlands: 48799.87,
    Austria: 47317.91,
    Qatar: 59127.51,
    Finland: 46437.21,
    Canada: 45191.96,
    Germany: 44636.7,
    Belgium: 44274.07,
    "San Marino": 45887.69,
    "Hong Kong SAR": 46032.29,
    "United Kingdom": 40349.87,
    Israel: 40790.93,
    "New Zealand": 42334.7,
    France: 40053.62,
    Japan: 38903.3,
    "United Arab Emirates": 41443.89,
    Andorra: 40017.74,
    "Taiwan Province of China": 25061.62,
    Malta: 29340.93,
    Korea: 31600.74,
    Italy: 32648.76,
    Slovenia: 23511.32,
    Spain: 28196.76,
    Estonia: 20430.57,
    "Puerto Rico": 31347.27,
    Cyprus: 26580.49,
    "The Bahamas": 33191.21,
    "Czech Republic": 20666.67,
    "Brunei Darussalam": 28236.77,
    Aruba: 27897.42,
    Portugal: 21482.83,
    Lithuania: 16879.45,
    Kuwait: 27193.9,
    Bahrain: 23631.59,
    "Slovak Republic": 17553.89,
    Latvia: 15613.44,
    Hungary: 14590.9,
    "Saudi Arabia": 21113.96,
    Greece: 18578.29,
    Poland: 13868.91,
    Seychelles: 16180.94,
    Croatia: 13450.09,
    Romania: 10776.67,
    "St. Kitts and Nevis": 19144.1,
    Barbados: 17423.25,
    Chile: 15034.8,
    Uruguay: 18388.31,
    Panama: 15178.3,
    "Antigua and Barbuda": 15655.23,
    "Trinidad and Tobago": 16173.66,
    Maldives: 13201.45,
    Oman: 15482.13,
    Palau: 16028.19,
    Guyana: 6104.16,
    China: 8760.26,
    Bulgaria: 8381.99,
    Malaysia: 9965.12,
    "Islamic Republic of Iran": 5471.79,
    Turkey: 10628.9,
    "Costa Rica": 12185.26,
    Turkmenistan: 7248.37,
    "St. Lucia": 11262.04,
    Russia: 10724,
    Mauritius: 10485.01,
    Serbia: 6292.55,
    Montenegro: 7800.27,
    Grenada: 10152.83,
    Kazakhstan: 9186.71,
    Mexico: 9342.92,
    "Dominican Republic": 7874.94,
    Argentina: 14618.33,
    Dominica: 7768.35,
    Brazil: 9978.09,
    Nauru: 8152.26,
    Thailand: 6596.22,
    Gabon: 7370.35,
    "St. Vincent and the Grenadines": 7187.49,
    Botswana: 7286.77,
    "North Macedonia": 5462.38,
    "Bosnia and Herzegovina": 5392.37,
    Belarus: 5778.56,
    Tuvalu: 4255.25,
    "South Africa": 6678.29,
    Peru: 6728.07,
    Albania: 4525.89,
    "Equatorial Guinea": 9624.53,
    Georgia: 4358.59,
    Moldova: 3478.52,
    Colombia: 6577.3,
    Fiji: 6049.89,
    Ukraine: 2655.66,
    Kosovo: 4027.29,
    Armenia: 3868.91,
    Ecuador: 6216.61,
    Jamaica: 5413.03,
    Tonga: 4577,
    Paraguay: 5608.16,
    Vietnam: 2957.9,
    Iraq: 5178.92,
    Mongolia: 3595.38,
    Indonesia: 3885.47,
    Azerbaijan: 4217.65,
    Guatemala: 4233.01,
    Jordan: 4273.91,
    Suriname: 6156.46,
    Egypt: 2484.54,
    Namibia: 5438.76,
    Belize: 4791.52,
    "Marshall Islands": 3898.58,
    Djibouti: 2930.67,
    "El Salvador": 3910.26,
    Bhutan: 3234.75,
    Eswatini: 4031.06,
    Samoa: 4259.62,
    "Cabo Verde": 3239.07,
    "Sri Lanka": 4076.75,
    Micronesia: 3522.11,
    Libya: 4685.07,
    Philippines: 3153.31,
    Tunisia: 3436.2,
    Morocco: 3147.1,
    Bolivia: 3388.52,
    Nigeria: 1968.56,
    "West Bank and Gaza": 3407.31,
    Vanuatu: 3158.84,
    Algeria: 4079.65,
    "Papua New Guinea": 2755.49,
    "Lao P.D.R.": 2452.86,
    "CÙte d'Ivoire": 2066.85,
    Bangladesh: 1563.81,
    Honduras: 2451.94,
    "Solomon Islands": 2291.68,
    Ghana: 2086.6,
    India: 1980.69,
    "São Tomé and Príncipe": 1842.33,
    Uzbekistan: 1899.66,
    "Republic of Congo": 2555.44,
    Kenya: 1805.38,
    Angola: 4300.1,
    Mauritania: 1758.55,
    Nicaragua: 2156.15,
    Cambodia: 1448.11,
    Senegal: 1361.22,
    Kiribati: 1656.63,
    Cameroon: 1424.6,
    Benin: 1136.19,
    Zimbabwe: 1526.71,
    Haiti: 1337.16,
    Comoros: 1300.57,
    Nepal: 1048.45,
    "Kyrgyz Republic": 1254.51,
    "Timor-Leste": 1286.41,
    Guinea: 796.04,
    Tanzania: 1002.87,
    Myanmar: 1177.08,
    Uganda: 833.42,
    Togo: 830.45,
    Lesotho: 1166.07,
    Zambia: 1500.96,
    "Burkina Faso": 734.74,
    Mali: 854.98,
    "Guinea-Bissau": 864.14,
    Tajikistan: 800.8,
    "The Gambia": 676.66,
    Rwanda: 784.14,
    Sudan: 1172.97,
    Niger: 517.77,
    Chad: 671.18,
    Liberia: 763.11,
    Eritrea: 557.86,
    "Democratic Republic of the Congo": 466.6,
    Yemen: 895.43,
    "Central African Republic": 450.74,
    Mozambique: 461.42,
    Madagascar: 515.94,
    "Sierra Leone": 495.81,
    Malawi: 466.56,
    Somalia: 326.52,
    "South Sudan": 277.69,
    Burundi: 291.86
  },
  2018: {
    Afghanistan: 582.32,
    Ethiopia: 852.01,
    Lebanon: 8058.46,
    Pakistan: 1557.89,
    Syria: "n/a",
    Venezuela: 3404.43,
    Luxembourg: 117859.72,
    Ireland: 78837.74,
    Switzerland: 86762.02,
    "Macao SAR": 82862.88,
    Norway: 82082.12,
    "United States": 62769.66,
    Denmark: 61724.49,
    Iceland: 75382.6,
    Singapore: 66675.77,
    Australia: 56460.9,
    Sweden: 54295.73,
    Netherlands: 53224.69,
    Austria: 51246.15,
    Qatar: 66421.69,
    Finland: 50033.31,
    Canada: 46532.17,
    Germany: 47995.38,
    Belgium: 47718.41,
    "San Marino": 49477.28,
    "Hong Kong SAR": 48313.14,
    "United Kingdom": 43063.74,
    Israel: 42079.34,
    "New Zealand": 42839.82,
    France: 43021.38,
    Japan: 39818.8,
    "United Arab Emirates": 45075.57,
    Andorra: 42229.9,
    "Taiwan Province of China": 25825.57,
    Malta: 32237.26,
    Korea: 33433.17,
    Italy: 34921.01,
    Slovenia: 26217.34,
    Spain: 30423.17,
    Estonia: 23074.04,
    "Puerto Rico": 32789.06,
    Cyprus: 29300.33,
    "The Bahamas": 34094.37,
    "Czech Republic": 23463.6,
    "Brunei Darussalam": 30668.37,
    Aruba: 28727.94,
    Portugal: 23573.29,
    Lithuania: 19184.84,
    Kuwait: 29903.56,
    Bahrain: 25049.64,
    "Slovak Republic": 19395.85,
    Latvia: 17799.83,
    Hungary: 16407.35,
    "Saudi Arabia": 23538.94,
    Greece: 19769.33,
    Poland: 15468.26,
    Seychelles: 16143.37,
    Croatia: 15013.37,
    Romania: 12361.14,
    "St. Kitts and Nevis": 19269.74,
    Barbados: 17758.08,
    Chile: 15865.54,
    Uruguay: 18472.86,
    Panama: 15612.33,
    "Antigua and Barbuda": 16860.96,
    "Trinidad and Tobago": 17037.95,
    Maldives: 14476.59,
    Oman: 17338.96,
    Palau: 16328.65,
    Guyana: 6120.54,
    China: 9848.95,
    Bulgaria: 9470.58,
    Malaysia: 11079.79,
    "Islamic Republic of Iran": 5543.9,
    Turkey: 9508,
    "Costa Rica": 12428.92,
    Turkmenistan: 7064.88,
    "St. Lucia": 11556.67,
    Russia: 11261.71,
    Mauritius: 11205.9,
    Serbia: 7252.4,
    Montenegro: 8854.78,
    Grenada: 10466.81,
    Kazakhstan: 9749.07,
    Mexico: 9753.67,
    "Dominican Republic": 8340.96,
    Argentina: 11786.43,
    Dominica: 8118.96,
    Brazil: 9194.15,
    Nauru: 9398.51,
    Thailand: 7293.89,
    Gabon: 8220.5,
    "St. Vincent and the Grenadines": 7353.64,
    Botswana: 7513.63,
    "North Macedonia": 6111.19,
    "Bosnia and Herzegovina": 6073.94,
    Belarus: 6351.76,
    Tuvalu: 4507.67,
    "South Africa": 6984.47,
    Peru: 7007.28,
    Albania: 5254.46,
    "Equatorial Guinea": 9968.09,
    Georgia: 4718.79,
    Moldova: 4196.16,
    Colombia: 6923.64,
    Fiji: 6272.73,
    Ukraine: 3118.27,
    Kosovo: 4425.86,
    Armenia: 4195.96,
    Ecuador: 6318.48,
    Jamaica: 5729.76,
    Tonga: 4836.44,
    Paraguay: 5703.32,
    Vietnam: 3201.69,
    Iraq: 5950.83,
    Mongolia: 4056.73,
    Indonesia: 3947.25,
    Azerbaijan: 4759.8,
    Guatemala: 4240.55,
    Jordan: 4341.04,
    Suriname: 6772.07,
    Egypt: 2577.27,
    Namibia: 5665.86,
    Belize: 4813.21,
    "Marshall Islands": 4061.68,
    Djibouti: 3141.86,
    "El Salvador": 4052.62,
    Bhutan: 3280.72,
    Eswatini: 4225.91,
    Samoa: 4266.5,
    "Cabo Verde": 3616.44,
    "Sri Lanka": 4057.33,
    Micronesia: 3854.14,
    Libya: 6361.58,
    Philippines: 3279.52,
    Tunisia: 3442.7,
    Morocco: 3353.1,
    Bolivia: 3588.09,
    Nigeria: 2153.09,
    "West Bank and Gaza": 3353.23,
    Vanuatu: 3256.32,
    Algeria: 4118.74,
    "Papua New Guinea": 2861.45,
    "Lao P.D.R.": 2567.55,
    "CÙte d'Ivoire": 2265.29,
    Bangladesh: 1693.77,
    Honduras: 2506.62,
    "Solomon Islands": 2398.81,
    Ghana: 2275.77,
    India: 1996.95,
    "São Tomé and Príncipe": 1988.72,
    Uzbekistan: 1611.23,
    "Republic of Congo": 3062.92,
    Kenya: 1983.37,
    Angola: 3465.06,
    Mauritania: 1851.73,
    Nicaragua: 2016.16,
    Cambodia: 1588,
    Senegal: 1458.73,
    Kiribati: 1748.65,
    Cameroon: 1535.19,
    Benin: 1240.84,
    Zimbabwe: 1606.11,
    Haiti: 1441.86,
    Comoros: 1386.01,
    Nepal: 1178.53,
    "Kyrgyz Republic": 1321.95,
    "Timor-Leste": 1230.23,
    Guinea: 891.89,
    Tanzania: 1036.93,
    Myanmar: 1271.68,
    Uganda: 881.5,
    Togo: 901.93,
    Lesotho: 1140.52,
    Zambia: 1480.42,
    "Burkina Faso": 813.47,
    Mali: 922.55,
    "Guinea-Bissau": 866.45,
    Tajikistan: 825.77,
    "The Gambia": 729.04,
    Rwanda: 796.81,
    Sudan: 796.3,
    Niger: 572.56,
    Chad: 713.03,
    Liberia: 731.32,
    Eritrea: 580.85,
    "Democratic Republic of the Congo": 560.81,
    Yemen: 701.13,
    "Central African Republic": 488.56,
    Mozambique: 503.3,
    Madagascar: 518.49,
    "Sierra Leone": 533.97,
    Malawi: 501.11,
    Somalia: 327.32,
    "South Sudan": 359.06,
    Burundi: 271.29
  },
  2019: {
    Afghanistan: 586.2,
    Ethiopia: 968.25,
    Lebanon: 7639.1,
    Pakistan: 1352.72,
    Syria: "n/a",
    Venezuela: 2299.31,
    Luxembourg: 115838.95,
    Ireland: 80632.33,
    Switzerland: 85721.73,
    "Macao SAR": 81156.61,
    Norway: 75700.22,
    "United States": 65051.88,
    Denmark: 59861.61,
    Iceland: 69631.27,
    Singapore: 65641.31,
    Australia: 54476.91,
    Sweden: 51694.5,
    Netherlands: 52672.5,
    Austria: 50246.61,
    Qatar: 62817.06,
    Finland: 48716.22,
    Canada: 46399.71,
    Germany: 46800.18,
    Belgium: 46555.09,
    "San Marino": 48368,
    "Hong Kong SAR": 48268.22,
    "United Kingdom": 42416.6,
    Israel: 43965.66,
    "New Zealand": 42210.23,
    France: 41989.67,
    Japan: 40689.79,
    "United Arab Emirates": 43900.15,
    Andorra: 40688.49,
    "Taiwan Province of China": 25935.9,
    Malta: 31865.84,
    Korea: 31936.8,
    Italy: 33521.34,
    Slovenia: 26039.1,
    Spain: 29576.25,
    Estonia: 23400.47,
    "Puerto Rico": 32785.94,
    Cyprus: 28487.98,
    "The Bahamas": 34575.62,
    "Czech Republic": 23709.18,
    "Brunei Darussalam": 29314.11,
    Aruba: 29505.34,
    Portugal: 23333.32,
    Lithuania: 19557.39,
    Kuwait: 28513.43,
    Bahrain: 25925.28,
    "Slovak Republic": 19288.57,
    Latvia: 17739.47,
    Hungary: 16729.13,
    "Saudi Arabia": 23173.84,
    Greece: 19147.46,
    Poland: 15726.88,
    Seychelles: 16389.25,
    Croatia: 14945.18,
    Romania: 12861.28,
    "St. Kitts and Nevis": 20602.48,
    Barbados: 18448.83,
    Chile: 14619.5,
    Uruguay: 17601.27,
    Panama: 15830.98,
    "Antigua and Barbuda": 17451.55,
    "Trinidad and Tobago": 16637.11,
    Maldives: 15148.8,
    Oman: 16529.39,
    Palau: 15725.08,
    Guyana: 6594.38,
    China: 10170.06,
    Bulgaria: 9863.02,
    Malaysia: 11231.41,
    "Islamic Republic of Iran": 6980.74,
    Turkey: 9145.77,
    "Costa Rica": 12623.19,
    Turkmenistan: 7899.96,
    "St. Lucia": 11782.75,
    Russia: 11516.6,
    Mauritius: 11088.78,
    Serbia: 7391.84,
    Montenegro: 8911.27,
    Grenada: 10608.38,
    Kazakhstan: 9750.43,
    Mexico: 10028.87,
    "Dominican Republic": 8595.61,
    Argentina: 10054.02,
    Dominica: 8524.7,
    Brazil: 8935.76,
    Nauru: 9365.1,
    Thailand: 7816.23,
    Gabon: 8111.34,
    "St. Vincent and the Grenadines": 7470.71,
    Botswana: 7219.46,
    "North Macedonia": 6044.44,
    "Bosnia and Herzegovina": 6120.16,
    Belarus: 6831.52,
    Tuvalu: 5052.11,
    "South Africa": 6598.88,
    Peru: 6961.7,
    Albania: 5305.07,
    "Equatorial Guinea": 8394.42,
    Georgia: 4693.66,
    Moldova: 4464.12,
    Colombia: 6546.63,
    Fiji: 6142.93,
    Ukraine: 3690.07,
    Kosovo: 4462.72,
    Armenia: 4596.86,
    Ecuador: 6260.6,
    Jamaica: 5781.79,
    Tonga: 5150.55,
    Paraguay: 5299.67,
    Vietnam: 3398.21,
    Iraq: 5981.1,
    Mongolia: 4245.46,
    Indonesia: 4196.3,
    Azerbaijan: 4826.35,
    Guatemala: 4371.27,
    Jordan: 4425.69,
    Suriname: 6662.87,
    Egypt: 3056.98,
    Namibia: 5109.12,
    Belize: 4853.32,
    "Marshall Islands": 4292.88,
    Djibouti: 3437.16,
    "El Salvador": 4167.44,
    Bhutan: 3371.02,
    Eswatini: 4009.99,
    Samoa: 4291.25,
    "Cabo Verde": 3601.73,
    "Sri Lanka": 3851.67,
    Micronesia: 3952.28,
    Libya: 6004.47,
    Philippines: 3511.87,
    Tunisia: 3324.13,
    Morocco: 3368.39,
    Bolivia: 3591.4,
    Nigeria: 2229.85,
    "West Bank and Gaza": 3442.75,
    Vanuatu: 3147.05,
    Algeria: 3939.54,
    "Papua New Guinea": 2886.59,
    "Lao P.D.R.": 2618.18,
    "CÙte d'Ivoire": 2227.98,
    Bangladesh: 1854.67,
    Honduras: 2567.97,
    "Solomon Islands": 2356.62,
    Ghana: 2265.71,
    India: 2098.93,
    "São Tomé and Príncipe": 2021.93,
    Uzbekistan: 1801.43,
    "Republic of Congo": 2800.29,
    Kenya: 2110.46,
    Angola: 2805.26,
    Mauritania: 1943.8,
    Nicaragua: 1934.14,
    Cambodia: 1736.42,
    Senegal: 1430.21,
    Kiribati: 1695.3,
    Cameroon: 1507.51,
    Benin: 1218.28,
    Zimbabwe: 1314.09,
    Haiti: 1277.21,
    Comoros: 1359.75,
    Nepal: 1200.97,
    "Kyrgyz Republic": 1388.65,
    "Timor-Leste": 1560.51,
    Guinea: 991.74,
    Tanzania: 1079.68,
    Myanmar: 1302.28,
    Uganda: 954.25,
    Togo: 893.16,
    Lesotho: 1087.59,
    Zambia: 1272.24,
    "Burkina Faso": 786.93,
    Mali: 905.82,
    "Guinea-Bissau": 810.56,
    Tajikistan: 873.5,
    "The Gambia": 768.92,
    Rwanda: 835.19,
    Sudan: 776.55,
    Niger: 553.91,
    Chad: 685.68,
    Liberia: 672.88,
    Eritrea: 566.73,
    "Democratic Republic of the Congo": 580.7,
    Yemen: 691.59,
    "Central African Republic": 479.85,
    Mozambique: 506.82,
    Madagascar: 512.18,
    "Sierra Leone": 527.17,
    Malawi: 543.69,
    Somalia: 346.16,
    "South Sudan": 343.01,
    Burundi: 261.29
  },
  2020: {
    Afghanistan: 611.27,
    Ethiopia: 994.2,
    Lebanon: 2784.82,
    Pakistan: 1254.86,
    Syria: "n/a",
    Venezuela: 1690.66,
    Luxembourg: 116921.11,
    Ireland: 85205.5,
    Switzerland: 87366.6,
    "Macao SAR": 35621.46,
    Norway: 67326.07,
    "United States": 63358.49,
    Denmark: 61153.92,
    Iceland: 59643.09,
    Singapore: 59794.6,
    Australia: 52905.4,
    Sweden: 52129.13,
    Netherlands: 52456.09,
    Austria: 48592.71,
    Qatar: 54184.97,
    Finland: 48786.03,
    Canada: 43294.8,
    Germany: 46215.6,
    Belgium: 44688.46,
    "San Marino": 46282.47,
    "Hong Kong SAR": 46657.2,
    "United Kingdom": 40394.13,
    Israel: 44181.17,
    "New Zealand": 41164.58,
    France: 40298.85,
    Japan: 40088.6,
    "United Arab Emirates": 38661.18,
    Andorra: 36630.55,
    "Taiwan Province of China": 28358.3,
    Malta: 28955.29,
    Korea: 31638.46,
    Italy: 31604.43,
    Slovenia: 25548.77,
    Spain: 27179.45,
    Estonia: 23035.91,
    "Puerto Rico": 32645.4,
    Cyprus: 26784.89,
    "The Bahamas": 25734.16,
    "Czech Republic": 22942.86,
    "Brunei Darussalam": 26061,
    Aruba: 22483.49,
    Portugal: 22148.8,
    Lithuania: 19981.34,
    Kuwait: 22683.64,
    Bahrain: 23589.96,
    "Slovak Republic": 19144.92,
    Latvia: 17549.3,
    Hungary: 15866.22,
    "Saudi Arabia": 19995.7,
    Greece: 17657.1,
    Poland: 15699.3,
    Seychelles: 11701.89,
    Croatia: 13895.94,
    Romania: 12867.64,
    "St. Kitts and Nevis": 17173.24,
    Barbados: 15346.28,
    Chile: 12992.98,
    Uruguay: 16023.35,
    Panama: 12373.05,
    "Antigua and Barbuda": 13967.33,
    "Trinidad and Tobago": 15424.82,
    Maldives: 9888.65,
    Oman: 14255.09,
    Palau: 14731.7,
    Guyana: 6952.7,
    China: 10511.34,
    Bulgaria: 10006.35,
    Malaysia: 10231.34,
    "Islamic Republic of Iran": 9928.19,
    Turkey: 8610.03,
    "Costa Rica": 12056.96,
    Turkmenistan: 7673.6,
    "St. Lucia": 8934.7,
    Russia: 10115.35,
    Mauritius: 8618.6,
    Serbia: 7645.79,
    Montenegro: 7702.6,
    Grenada: 9130.37,
    Kazakhstan: 9071.3,
    Mexico: 8403.6,
    "Dominican Republic": 7553.51,
    Argentina: 8571.94,
    Dominica: 7416.24,
    Brazil: 6822.56,
    Nauru: 8866.87,
    Thailand: 7187.86,
    Gabon: 7277.37,
    "St. Vincent and the Grenadines": 7304.34,
    Botswana: 6420.23,
    "North Macedonia": 5939.41,
    "Bosnia and Herzegovina": 6035,
    Belarus: 6397.51,
    Tuvalu: 5105.93,
    "South Africa": 5624.5,
    Peru: 6134.22,
    Albania: 5152.56,
    "Equatorial Guinea": 7136.81,
    Georgia: 4275.29,
    Moldova: 4522.6,
    Colombia: 5390.92,
    Fiji: 4995.16,
    Ukraine: 3741.06,
    Kosovo: 4348.29,
    Armenia: 4267.49,
    Ecuador: 5642.74,
    Jamaica: 5102.76,
    Tonga: 4978.8,
    Paraguay: 4918.23,
    Vietnam: 3522.51,
    Iraq: 4223.22,
    Mongolia: 3916.09,
    Indonesia: 3921.62,
    Azerbaijan: 4232.32,
    Guatemala: 4317.45,
    Jordan: 4286.49,
    Suriname: 4786.83,
    Egypt: 3600.84,
    Namibia: 4276.28,
    Belize: 4076.77,
    "Marshall Islands": 4412.99,
    Djibouti: 3481.57,
    "El Salvador": 3798.83,
    Bhutan: 3358.96,
    Eswatini: 3532.55,
    Samoa: 4090.06,
    "Cabo Verde": 3065.48,
    "Sri Lanka": 3681.55,
    Micronesia: 3892.27,
    Libya: 2891.46,
    Philippines: 3322.51,
    Tunisia: 3294.88,
    Morocco: 3187.63,
    Bolivia: 3167.61,
    Nigeria: 2083.16,
    "West Bank and Gaza": 3050.55,
    Vanuatu: 3090.47,
    Algeria: 3337.32,
    "Papua New Guinea": 2651.09,
    "Lao P.D.R.": 2586.78,
    "CÙte d'Ivoire": 2271.38,
    Bangladesh: 1961.61,
    Honduras: 2396.69,
    "Solomon Islands": 2281.35,
    Ghana: 2225.51,
    India: 1929.68,
    "São Tomé and Príncipe": 2189.68,
    Uzbekistan: 1767.49,
    "Republic of Congo": 2206.17,
    Kenya: 2103.55,
    Angola: 1881.2,
    Mauritania: 1955.5,
    Nicaragua: 1942.64,
    Cambodia: 1606.81,
    Senegal: 1474.02,
    Kiribati: 1673.41,
    Cameroon: 1504.5,
    Benin: 1290.5,
    Zimbabwe: 1443.42,
    Haiti: 1235.48,
    Comoros: 1355.3,
    Nepal: 1178.33,
    "Kyrgyz Republic": 1188.82,
    "Timor-Leste": 1347.86,
    Guinea: 1101.71,
    Tanzania: 1110.37,
    Myanmar: 1527.43,
    Uganda: 925.26,
    Togo: 915.63,
    Lesotho: 969.71,
    Zambia: 1023.16,
    "Burkina Faso": 831.01,
    Mali: 889.79,
    "Guinea-Bissau": 789.86,
    Tajikistan: 843.97,
    "The Gambia": 769.66,
    Rwanda: 815.82,
    Sudan: 775.64,
    Niger: 568.49,
    Chad: 659.67,
    Liberia: 647.1,
    Eritrea: 587.68,
    "Democratic Republic of the Congo": 543.83,
    Yemen: 580.22,
    "Central African Republic": 494.19,
    Mozambique: 448.84,
    Madagascar: 461.8,
    "Sierra Leone": 526.72,
    Malawi: 567.59,
    Somalia: 331.64,
    "South Sudan": 322.2,
    Burundi: 255.98
  },
  2021: {
    Afghanistan: "n/a",
    Ethiopia: 939.51,
    Lebanon: "n/a",
    Pakistan: "n/a",
    Syria: "n/a",
    Venezuela: 1627.35,
    Luxembourg: 131301.6,
    Ireland: 102394.02,
    Switzerland: 93515.48,
    "Macao SAR": 42106.56,
    Norway: 82244.23,
    "United States": 69375.38,
    Denmark: 67919.59,
    Iceland: 68843.65,
    Singapore: 66263.42,
    Australia: 62618.59,
    Sweden: 58639.19,
    Netherlands: 57714.88,
    Austria: 53793.37,
    Qatar: 61790.57,
    Finland: 53522.57,
    Canada: 52791.23,
    Germany: 50787.86,
    Belgium: 50412.71,
    "San Marino": 50934.19,
    "Hong Kong SAR": 49485.08,
    "United Kingdom": 46200.26,
    Israel: 49840.25,
    "New Zealand": 48348.99,
    France: 45028.27,
    Japan: 40704.3,
    "United Arab Emirates": 43537.69,
    Andorra: 40417.27,
    "Taiwan Province of China": 33401.71,
    Malta: 31996.53,
    Korea: 35195.52,
    Italy: 35584.88,
    Slovenia: 28939.27,
    Spain: 30536.86,
    Estonia: 27100.74,
    "Puerto Rico": 34143.27,
    Cyprus: 29486.28,
    "The Bahamas": 27437.2,
    "Czech Republic": 25806.38,
    "Brunei Darussalam": 33979.37,
    Aruba: 25700.87,
    Portugal: 24457.14,
    Lithuania: 22411.65,
    Kuwait: 27927.16,
    Bahrain: 26293.95,
    "Slovak Republic": 21383.29,
    Latvia: 19538.9,
    Hungary: 18527.59,
    "Saudi Arabia": 23762.42,
    Greece: 19827.16,
    Poland: 17318.5,
    Seychelles: 13140.37,
    Croatia: 15807.7,
    Romania: 14863.89,
    "St. Kitts and Nevis": 16917.16,
    Barbados: 16105.13,
    Chile: 16799.37,
    Uruguay: 16965.08,
    Panama: 13861.06,
    "Antigua and Barbuda": 14117.84,
    "Trinidad and Tobago": 15352.72,
    Maldives: 11899.09,
    Oman: 17632.65,
    Palau: 11835.18,
    Guyana: 9369.01,
    China: 11891.2,
    Bulgaria: 11331.87,
    Malaysia: 11124.67,
    "Islamic Republic of Iran": 12725.04,
    Turkey: 9406.58,
    "Costa Rica": 11860.22,
    Turkmenistan: 8843.87,
    "St. Lucia": 9419.27,
    Russia: 11273.24,
    Mauritius: 8681.61,
    Serbia: 8793.84,
    Montenegro: 8837.58,
    Grenada: 9575.34,
    Kazakhstan: 10144.68,
    Mexico: 9967.39,
    "Dominican Republic": 8491.62,
    Argentina: 9929.15,
    Dominica: 7777.02,
    Brazil: 7741.15,
    Nauru: 10138.2,
    Thailand: 7808.66,
    Gabon: 8569.22,
    "St. Vincent and the Grenadines": 6952.24,
    Botswana: 7349.88,
    "North Macedonia": 6711.73,
    "Bosnia and Herzegovina": 6647.83,
    Belarus: 7032.48,
    Tuvalu: 6004.1,
    "South Africa": 6861.17,
    Peru: 6676.52,
    Albania: 5837.18,
    "Equatorial Guinea": 8625.76,
    Georgia: 4807.95,
    Moldova: 4791.63,
    Colombia: 5892.14,
    Fiji: 5127.44,
    Ukraine: 4384.24,
    Kosovo: 4986.26,
    Armenia: 4594.91,
    Ecuador: 5884.15,
    Jamaica: 5421.64,
    Tonga: 5009.78,
    Paraguay: 5028.22,
    Vietnam: 3742.86,
    Iraq: 4892.96,
    Mongolia: 4185.54,
    Indonesia: 4224.98,
    Azerbaijan: 5167.38,
    Guatemala: 4542.25,
    Jordan: 4393.64,
    Suriname: 4620.4,
    Egypt: 3851.75,
    Namibia: 4693.46,
    Belize: 4457.89,
    "Marshall Islands": 4338.17,
    Djibouti: 3645.5,
    "El Salvador": 4244.3,
    Bhutan: 3296.11,
    Eswatini: 3965.43,
    Samoa: 3906.41,
    "Cabo Verde": 3346.55,
    "Sri Lanka": 3665.77,
    Micronesia: 3855.22,
    Libya: 4068.61,
    Philippines: 3492.07,
    Tunisia: 3555.59,
    Morocco: 3470.8,
    Bolivia: 3266.69,
    Nigeria: 2272.84,
    "West Bank and Gaza": 3320.85,
    Vanuatu: 3235.21,
    Algeria: 3638.33,
    "Papua New Guinea": 2951.98,
    "Lao P.D.R.": 2625.61,
    "CÙte d'Ivoire": 2489.1,
    Bangladesh: 2138.79,
    Honduras: 2602.17,
    "Solomon Islands": 2356.51,
    Ghana: 2413.1,
    India: 2116.44,
    "São Tomé and Príncipe": 2392.89,
    Uzbekistan: 1901.49,
    "Republic of Congo": 2655.72,
    Kenya: 2198.59,
    Angola: 2200.68,
    Mauritania: 2161.28,
    Nicaragua: 2047.1,
    Cambodia: 1647.02,
    Senegal: 1602.9,
    Kiribati: 1927.08,
    Cameroon: 1645.81,
    Benin: 1446.83,
    Zimbabwe: 1664.76,
    Haiti: 1691.83,
    Comoros: 1390.06,
    Nepal: 1172.64,
    "Kyrgyz Republic": 1224.75,
    "Timor-Leste": 1262.72,
    Guinea: 1168.18,
    Tanzania: 1159.19,
    Myanmar: 1246.32,
    Uganda: 1018.44,
    Togo: 1000.44,
    Lesotho: 1187.51,
    Zambia: 1115.27,
    "Burkina Faso": 926.2,
    Mali: 966.14,
    "Guinea-Bissau": 858.04,
    Tajikistan: 839.18,
    "The Gambia": 819.22,
    Rwanda: 802.26,
    Sudan: 789.45,
    Niger: 622.23,
    Chad: 729.84,
    Liberia: 703.41,
    Eritrea: 625.97,
    "Democratic Republic of the Congo": 593.56,
    Yemen: 585,
    "Central African Republic": 525.91,
    Mozambique: 492.27,
    Madagascar: 498.68,
    "Sierra Leone": 541.06,
    Malawi: 565.8,
    Somalia: 350.36,
    "South Sudan": 230.13,
    Burundi: 261.05
  },
  2022: {
    Afghanistan: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Pakistan: "n/a",
    Syria: "n/a",
    Venezuela: 1617.76,
    Luxembourg: 137947.81,
    Ireland: 108162.69,
    Switzerland: 98770.71,
    "Macao SAR": 59113.56,
    Norway: 84119.22,
    "United States": 74725,
    Denmark: 70769.47,
    Iceland: 72317.58,
    Singapore: 69129.04,
    Australia: 64895.02,
    Sweden: 61680.6,
    Netherlands: 61159.44,
    Austria: 57878.72,
    Qatar: 64768.01,
    Finland: 56832.6,
    Canada: 56739.82,
    Germany: 54653,
    Belgium: 53452.46,
    "San Marino": 53800.03,
    "Hong Kong SAR": 51895.03,
    "United Kingdom": 50879.45,
    Israel: 52504.19,
    "New Zealand": 51706.9,
    France: 47952.13,
    Japan: 43118.83,
    "United Arab Emirates": 44752.86,
    Andorra: 42713.08,
    "Taiwan Province of China": 36227.09,
    Malta: 34554.04,
    Korea: 36791.51,
    Italy: 38168.69,
    Slovenia: 31025.51,
    Spain: 33171.17,
    Estonia: 29735.02,
    "Puerto Rico": 35106.44,
    Cyprus: 30995.78,
    "The Bahamas": 30584.62,
    "Czech Republic": 28076.59,
    "Brunei Darussalam": 35148.83,
    Aruba: 27955.24,
    Portugal: 26404.14,
    Lithuania: 24209.4,
    Kuwait: 28821.61,
    Bahrain: 27052.97,
    "Slovak Republic": 23323.33,
    Latvia: 21489.13,
    Hungary: 20380.16,
    "Saudi Arabia": 24224.38,
    Greece: 21155.31,
    Poland: 19055.72,
    Seychelles: 17706.04,
    Croatia: 17222.7,
    Romania: 16293.05,
    "St. Kitts and Nevis": 18728.92,
    Barbados: 17769.73,
    Chile: 17701.62,
    Uruguay: 17930.51,
    Panama: 14643.87,
    "Antigua and Barbuda": 15196.34,
    "Trinidad and Tobago": 16323.03,
    Maldives: 13550.91,
    Oman: 18226.55,
    Palau: 13602.82,
    Guyana: 12426.39,
    China: 12990.01,
    Bulgaria: 12336.97,
    Malaysia: 12295.73,
    "Islamic Republic of Iran": 13243.34,
    Turkey: 9863.96,
    "Costa Rica": 12294.13,
    Turkmenistan: 9939.11,
    "St. Lucia": 10905.01,
    Russia: 11664.62,
    Mauritius: 9437.5,
    Serbia: 9560.92,
    Montenegro: 9712.51,
    Grenada: 10194.01,
    Kazakhstan: 10510.36,
    Mexico: 10541.44,
    "Dominican Republic": 9158.02,
    Argentina: 10448.39,
    Dominica: 8559.17,
    Brazil: 8464.33,
    Nauru: 10361.2,
    Thailand: 8356.18,
    Gabon: 9083.63,
    "St. Vincent and the Grenadines": 7720.92,
    Botswana: 7770.51,
    "North Macedonia": 7203.98,
    "Bosnia and Herzegovina": 7078.9,
    Belarus: 7592.19,
    Tuvalu: 6455.94,
    "South Africa": 7081.87,
    Peru: 6781.12,
    Albania: 6284.03,
    "Equatorial Guinea": 8058.55,
    Georgia: 5297.52,
    Moldova: 5240.23,
    Colombia: 6186.66,
    Fiji: 5507.13,
    Ukraine: 4958.31,
    Kosovo: 5406.54,
    Armenia: 5083.22,
    Ecuador: 6107.6,
    Jamaica: 5697.35,
    Tonga: 5398,
    Paraguay: 5258.69,
    Vietnam: 4187.47,
    Iraq: 5364.25,
    Mongolia: 4564.45,
    Indonesia: 4538.15,
    Azerbaijan: 5307.83,
    Guatemala: 4767.44,
    Jordan: 4564.91,
    Suriname: 4759.46,
    Egypt: 4176.6,
    Namibia: 4853.26,
    Belize: 4721.64,
    "Marshall Islands": 4525.64,
    Djibouti: 3869.25,
    "El Salvador": 4475.18,
    Bhutan: 3605.55,
    Eswatini: 4164.29,
    Samoa: 4019.18,
    "Cabo Verde": 3618.37,
    "Sri Lanka": 3760.38,
    Micronesia: 3973.25,
    Libya: 4308.91,
    Philippines: 3621.21,
    Tunisia: 3747.54,
    Morocco: 3617.25,
    Bolivia: 3419.33,
    Nigeria: 2562.19,
    "West Bank and Gaza": 3514.22,
    Vanuatu: 3355.57,
    Algeria: 3672.46,
    "Papua New Guinea": 3093.66,
    "Lao P.D.R.": 2757.81,
    "CÙte d'Ivoire": 2645.56,
    Bangladesh: 2326.63,
    Honduras: 2712.44,
    "Solomon Islands": 2514.94,
    Ghana: 2556.51,
    India: 2312.9,
    "São Tomé and Príncipe": 2523.79,
    Uzbekistan: 2078.94,
    "Republic of Congo": 2704.46,
    Kenya: 2290.5,
    Angola: 2276.75,
    Mauritania: 2154.15,
    Nicaragua: 2109.3,
    Cambodia: 1749.81,
    Senegal: 1699.4,
    Kiribati: 2001.24,
    Cameroon: 1730.1,
    Benin: 1551.42,
    Zimbabwe: 1757.99,
    Haiti: 1560.23,
    Comoros: 1426.52,
    Nepal: 1226.01,
    "Kyrgyz Republic": 1313.86,
    "Timor-Leste": 1301.32,
    Guinea: 1241.83,
    Tanzania: 1211.77,
    Myanmar: 1170.09,
    Uganda: 1074.68,
    Togo: 1075.57,
    Lesotho: 1227.37,
    Zambia: 1195.78,
    "Burkina Faso": 988.3,
    Mali: 1019.32,
    "Guinea-Bissau": 907.34,
    Tajikistan: 890.17,
    "The Gambia": 846.51,
    Rwanda: 832.57,
    Sudan: 809.09,
    Niger: 661.76,
    Chad: 743.84,
    Liberia: 743,
    Eritrea: 678.25,
    "Democratic Republic of the Congo": 622.07,
    Yemen: 587.41,
    "Central African Republic": 557.44,
    Mozambique: 506.47,
    Madagascar: 531.41,
    "Sierra Leone": 551.69,
    Malawi: 543.55,
    Somalia: 369.62,
    "South Sudan": 313.59,
    Burundi: 269.56
  },
  2023: {
    Afghanistan: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Pakistan: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    Luxembourg: 144013.31,
    Ireland: 114839.26,
    Switzerland: 102030.38,
    "Macao SAR": 75353.68,
    Norway: 86429.97,
    "United States": 77881.31,
    Denmark: 74314.29,
    Iceland: 73881.65,
    Singapore: 72492.13,
    Australia: 67842.29,
    Sweden: 65769.75,
    Netherlands: 64246.82,
    Austria: 60403.54,
    Qatar: 65466.92,
    Finland: 59545.36,
    Canada: 59125.56,
    Germany: 57208.89,
    Belgium: 55938.77,
    "San Marino": 55816.02,
    "Hong Kong SAR": 54237.7,
    "United Kingdom": 52730.91,
    Israel: 54444.87,
    "New Zealand": 53795.62,
    France: 49974.95,
    Japan: 46138.06,
    "United Arab Emirates": 45670.15,
    Andorra: 44321.89,
    "Taiwan Province of China": 38396.98,
    Malta: 37121.43,
    Korea: 38790.77,
    Italy: 39831.37,
    Slovenia: 33172.85,
    Spain: 34906.78,
    Estonia: 32045.97,
    "Puerto Rico": 35939.72,
    Cyprus: 32875.53,
    "The Bahamas": 32368.22,
    "Czech Republic": 30114.28,
    "Brunei Darussalam": 33907.08,
    Aruba: 30008.08,
    Portugal: 27997.75,
    Lithuania: 25977.97,
    Kuwait: 28794.89,
    Bahrain: 27678.48,
    "Slovak Republic": 25146.38,
    Latvia: 23135.1,
    Hungary: 22174.26,
    "Saudi Arabia": 24371.81,
    Greece: 22314.03,
    Poland: 20561.52,
    Seychelles: 19463.21,
    Croatia: 18728.15,
    Romania: 17869.73,
    "St. Kitts and Nevis": 19655.86,
    Barbados: 18947.06,
    Chile: 18606.54,
    Uruguay: 18466.47,
    Panama: 15481.39,
    "Antigua and Barbuda": 16137.47,
    "Trinidad and Tobago": 16805.62,
    Maldives: 15223.42,
    Oman: 17888.76,
    Palau: 15695.71,
    Guyana: 15152.58,
    China: 14040.08,
    Bulgaria: 13414.42,
    Malaysia: 13396.64,
    "Islamic Republic of Iran": 13716.84,
    Turkey: 10922.67,
    "Costa Rica": 12883.88,
    Turkmenistan: 10880.2,
    "St. Lucia": 12026.9,
    Russia: 12024.44,
    Mauritius: 10308.72,
    Serbia: 10421.8,
    Montenegro: 10522.84,
    Grenada: 10878.13,
    Kazakhstan: 11104.66,
    Mexico: 11024.77,
    "Dominican Republic": 9732.88,
    Argentina: 10189.26,
    Dominica: 9240.85,
    Brazil: 9102.21,
    Nauru: 10576.17,
    Thailand: 8791.91,
    Gabon: 9276.92,
    "St. Vincent and the Grenadines": 8378.19,
    Botswana: 8257.27,
    "North Macedonia": 7692.06,
    "Bosnia and Herzegovina": 7568.24,
    Belarus: 7911.69,
    Tuvalu: 6965.41,
    "South Africa": 7270.27,
    Peru: 7149.52,
    Albania: 6622.85,
    "Equatorial Guinea": 7886.88,
    Georgia: 5804.01,
    Moldova: 5729.29,
    Colombia: 6446.91,
    Fiji: 6053.59,
    Ukraine: 5439.55,
    Kosovo: 5815.64,
    Armenia: 5453.16,
    Ecuador: 6247.54,
    Jamaica: 5931.29,
    Tonga: 5700.37,
    Paraguay: 5467.53,
    Vietnam: 4605.45,
    Iraq: 5536.84,
    Mongolia: 4925.58,
    Indonesia: 4888.9,
    Azerbaijan: 5364.76,
    Guatemala: 4927.43,
    Jordan: 4792.51,
    Suriname: 4986.29,
    Egypt: 4449.8,
    Namibia: 4932.59,
    Belize: 4853.85,
    "Marshall Islands": 4694.01,
    Djibouti: 4128.17,
    "El Salvador": 4626.44,
    Bhutan: 3910.25,
    Eswatini: 4284.71,
    Samoa: 4247.63,
    "Cabo Verde": 3918.27,
    "Sri Lanka": 3991.48,
    Micronesia: 4202.53,
    Libya: 4381.96,
    Philippines: 3848.48,
    Tunisia: 3916.28,
    Morocco: 3801.03,
    Bolivia: 3616.43,
    Nigeria: 2861.37,
    "West Bank and Gaza": 3648.08,
    Vanuatu: 3486.33,
    Algeria: 3696.74,
    "Papua New Guinea": 3212.23,
    "Lao P.D.R.": 2878.08,
    "CÙte d'Ivoire": 2820.36,
    Bangladesh: 2534.45,
    Honduras: 2821.8,
    "Solomon Islands": 2698.58,
    Ghana: 2666.59,
    India: 2478.62,
    "São Tomé and Príncipe": 2656.96,
    Uzbekistan: 2277.04,
    "Republic of Congo": 2746.97,
    Kenya: 2394.73,
    Angola: 2338.75,
    Mauritania: 2212.37,
    Nicaragua: 2143.84,
    Cambodia: 1872.93,
    Senegal: 1890.98,
    Kiribati: 2073.46,
    Cameroon: 1820.21,
    Benin: 1654.79,
    Zimbabwe: 1731.92,
    Haiti: 1619.04,
    Comoros: 1466.97,
    Nepal: 1312.94,
    "Kyrgyz Republic": 1387.09,
    "Timor-Leste": 1344.33,
    Guinea: 1299.51,
    Tanzania: 1264.24,
    Myanmar: 1228.54,
    Uganda: 1097.51,
    Togo: 1151.57,
    Lesotho: 1271.6,
    Zambia: 1186.79,
    "Burkina Faso": 1049.97,
    Mali: 1075.56,
    "Guinea-Bissau": 972.94,
    Tajikistan: 929.2,
    "The Gambia": 893.57,
    Rwanda: 880.53,
    Sudan: 841.11,
    Niger: 728.48,
    Chad: 765.72,
    Liberia: 750.72,
    Eritrea: 711.16,
    "Democratic Republic of the Congo": 655.06,
    Yemen: 612.95,
    "Central African Republic": 589.14,
    Mozambique: 568.71,
    Madagascar: 565.09,
    "Sierra Leone": 545.34,
    Malawi: 520.63,
    Somalia: 403.28,
    "South Sudan": 389.67,
    Burundi: 280.26
  },
  2024: {
    Afghanistan: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Pakistan: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    Luxembourg: 149736.4,
    Ireland: 121081.09,
    Switzerland: 106657.09,
    "Macao SAR": 87687.89,
    Norway: 88167.22,
    "United States": 80714.78,
    Denmark: 78079.21,
    Iceland: 76323.21,
    Singapore: 75869.29,
    Australia: 70479.93,
    Sweden: 68523.93,
    Netherlands: 67172.75,
    Austria: 63314.96,
    Qatar: 67109.34,
    Finland: 62432.4,
    Canada: 61237.17,
    Germany: 59728.2,
    Belgium: 58206.32,
    "San Marino": 57678.93,
    "Hong Kong SAR": 56619.57,
    "United Kingdom": 55160.08,
    Israel: 56247.48,
    "New Zealand": 55273.04,
    France: 51873.76,
    Japan: 48159.22,
    "United Arab Emirates": 46748.56,
    Andorra: 45773.99,
    "Taiwan Province of China": 40749.6,
    Malta: 39791.21,
    Korea: 40759.46,
    Italy: 41252.8,
    Slovenia: 35312.34,
    Spain: 36508.45,
    Estonia: 34459.54,
    "Puerto Rico": 36693.81,
    Cyprus: 34495.44,
    "The Bahamas": 34008.96,
    "Czech Republic": 32067.7,
    "Brunei Darussalam": 33825.98,
    Aruba: 31056.4,
    Portugal: 29467.57,
    Lithuania: 27685.6,
    Kuwait: 28992.74,
    Bahrain: 28412.12,
    "Slovak Republic": 26830.34,
    Latvia: 24723.87,
    Hungary: 23863.29,
    "Saudi Arabia": 24683.31,
    Greece: 23393.11,
    Poland: 22052.31,
    Seychelles: 21089.79,
    Croatia: 20112.76,
    Romania: 19284.43,
    "St. Kitts and Nevis": 20336.16,
    Barbados: 19836.29,
    Chile: 19446.29,
    Uruguay: 19170.47,
    Panama: 16372.06,
    "Antigua and Barbuda": 16950.96,
    "Trinidad and Tobago": 17220.62,
    Maldives: 16169.69,
    Oman: 17750.79,
    Palau: 16414.2,
    Guyana: 15767.57,
    China: 15141.55,
    Bulgaria: 14509.32,
    Malaysia: 14300.47,
    "Islamic Republic of Iran": 14218.14,
    Turkey: 12110.44,
    "Costa Rica": 13509.16,
    Turkmenistan: 11943.24,
    "St. Lucia": 12676.12,
    Russia: 12450.98,
    Mauritius: 11281.78,
    Serbia: 11278.83,
    Montenegro: 11327.39,
    Grenada: 11568.88,
    Kazakhstan: 11656.71,
    Mexico: 11479.68,
    "Dominican Republic": 10338.38,
    Argentina: 10337.88,
    Dominica: 9876.76,
    Brazil: 9800.05,
    Nauru: 10668.97,
    Thailand: 9316.65,
    Gabon: 9532.13,
    "St. Vincent and the Grenadines": 8915.71,
    Botswana: 8718.33,
    "North Macedonia": 8251.31,
    "Bosnia and Herzegovina": 8092.6,
    Belarus: 8335.22,
    Tuvalu: 7468.56,
    "South Africa": 7450.59,
    Peru: 7422.85,
    Albania: 7027.58,
    "Equatorial Guinea": 7846.44,
    Georgia: 6347.65,
    Moldova: 6288.49,
    Colombia: 6723.38,
    Fiji: 6548.09,
    Ukraine: 5957.96,
    Kosovo: 6220.3,
    Armenia: 5861.56,
    Ecuador: 6400.01,
    Jamaica: 6178.47,
    Tonga: 6034.76,
    Paraguay: 5747.83,
    Vietnam: 5081.41,
    Iraq: 5655.36,
    Mongolia: 5270.88,
    Indonesia: 5205.48,
    Azerbaijan: 5455.4,
    Guatemala: 5162.97,
    Jordan: 5043.13,
    Suriname: 5175.62,
    Egypt: 4718.11,
    Namibia: 4993.83,
    Belize: 4950.93,
    "Marshall Islands": 4844.89,
    Djibouti: 4406.4,
    "El Salvador": 4767.74,
    Bhutan: 4298.46,
    Eswatini: 4435.94,
    Samoa: 4476.95,
    "Cabo Verde": 4237.84,
    "Sri Lanka": 4240.87,
    Micronesia: 4387.07,
    Libya: 4320.65,
    Philippines: 4081.49,
    Tunisia: 4094.26,
    Morocco: 3991.9,
    Bolivia: 3827.39,
    Nigeria: 3150.5,
    "West Bank and Gaza": 3720.81,
    Vanuatu: 3607.64,
    Algeria: 3673.8,
    "Papua New Guinea": 3320.42,
    "Lao P.D.R.": 3036.72,
    "CÙte d'Ivoire": 2999.21,
    Bangladesh: 2754.41,
    Honduras: 2935.21,
    "Solomon Islands": 2839.44,
    Ghana: 2787.11,
    India: 2649.21,
    "São Tomé and Príncipe": 2794.22,
    Uzbekistan: 2501.74,
    "Republic of Congo": 2902.65,
    Kenya: 2510.4,
    Angola: 2422.66,
    Mauritania: 2255.12,
    Nicaragua: 2183.22,
    Cambodia: 2006.88,
    Senegal: 2012.74,
    Kiribati: 2128.71,
    Cameroon: 1920.12,
    Benin: 1766.17,
    Zimbabwe: 1728.27,
    Haiti: 1650.35,
    Comoros: 1509.84,
    Nepal: 1400.35,
    "Kyrgyz Republic": 1440.64,
    "Timor-Leste": 1384.54,
    Guinea: 1362.59,
    Tanzania: 1321.45,
    Myanmar: 1289.98,
    Uganda: 1170.95,
    Togo: 1230.43,
    Lesotho: 1314.76,
    Zambia: 1187.48,
    "Burkina Faso": 1112.79,
    Mali: 1132.07,
    "Guinea-Bissau": 1040.9,
    Tajikistan: 968.77,
    "The Gambia": 942.07,
    Rwanda: 928.79,
    Sudan: 889.61,
    Niger: 807.68,
    Chad: 802.39,
    Liberia: 788.84,
    Eritrea: 740.7,
    "Democratic Republic of the Congo": 689.72,
    Yemen: 637.37,
    "Central African Republic": 621.77,
    Mozambique: 585.67,
    Madagascar: 592.48,
    "Sierra Leone": 548.34,
    Malawi: 508.56,
    Somalia: 437.79,
    "South Sudan": 388.39,
    Burundi: 291.83
  },
  2025: {
    Afghanistan: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Pakistan: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    Luxembourg: 155059.54,
    Ireland: 127640.06,
    Switzerland: 110400.08,
    "Macao SAR": 92654.97,
    Norway: 89679.84,
    "United States": 83563.82,
    Denmark: 81950.18,
    Iceland: 80325.24,
    Singapore: 79169.49,
    Australia: 73061.7,
    Sweden: 72034.54,
    Netherlands: 70101.47,
    Austria: 67294.42,
    Qatar: 68739.54,
    Finland: 65676.15,
    Canada: 63293.42,
    Germany: 62235.56,
    Belgium: 60422.1,
    "San Marino": 59949.81,
    "Hong Kong SAR": 59126.85,
    "United Kingdom": 57857.4,
    Israel: 57972.97,
    "New Zealand": 56739.21,
    France: 53661.16,
    Japan: 50047.52,
    "United Arab Emirates": 48008.21,
    Andorra: 46863.75,
    "Taiwan Province of China": 42801.84,
    Malta: 42350.42,
    Korea: 42719.39,
    Italy: 42688.76,
    Slovenia: 37544.96,
    Spain: 37985.71,
    Estonia: 36886.02,
    "Puerto Rico": 37518.2,
    Cyprus: 36206.53,
    "The Bahamas": 35092.86,
    "Czech Republic": 34092.84,
    "Brunei Darussalam": 33948.82,
    Aruba: 31841.69,
    Portugal: 30933.38,
    Lithuania: 29342.91,
    Kuwait: 29413.94,
    Bahrain: 29209.05,
    "Slovak Republic": 28486.6,
    Latvia: 26383.31,
    Hungary: 25422.59,
    "Saudi Arabia": 25093.98,
    Greece: 24491.86,
    Poland: 23691.58,
    Seychelles: 22897.99,
    Croatia: 21231.56,
    Romania: 20777.38,
    "St. Kitts and Nevis": 21004.13,
    Barbados: 20615.87,
    Chile: 20304.19,
    Uruguay: 19954.91,
    Panama: 17319.3,
    "Antigua and Barbuda": 17633.72,
    "Trinidad and Tobago": 17647.04,
    Maldives: 17104.02,
    Oman: 17741.89,
    Palau: 17017.28,
    Guyana: 16563.39,
    China: 16295.35,
    Bulgaria: 15599.36,
    Malaysia: 15251.52,
    "Islamic Republic of Iran": 14739.1,
    Turkey: 13445.84,
    "Costa Rica": 14140.21,
    Turkmenistan: 13139.02,
    "St. Lucia": 13075.08,
    Russia: 12908.4,
    Mauritius: 12274.92,
    Serbia: 12235.27,
    Montenegro: 12147.3,
    Grenada: 12117.26,
    Kazakhstan: 12103.15,
    Mexico: 11933.77,
    "Dominican Republic": 10984.13,
    Argentina: 10723,
    Dominica: 10501.81,
    Brazil: 10371.9,
    Nauru: 10687.36,
    Thailand: 9869.3,
    Gabon: 9899.51,
    "St. Vincent and the Grenadines": 9388.17,
    Botswana: 9144.08,
    "North Macedonia": 8856.04,
    "Bosnia and Herzegovina": 8651.22,
    Belarus: 8723.33,
    Tuvalu: 7982.84,
    "South Africa": 7627.63,
    Peru: 7689.99,
    Albania: 7445.97,
    "Equatorial Guinea": 7722.7,
    Georgia: 6945.4,
    Moldova: 6884.59,
    Colombia: 7016.12,
    Fiji: 6954.05,
    Ukraine: 6553.72,
    Kosovo: 6622.24,
    Armenia: 6304.91,
    Ecuador: 6561.66,
    Jamaica: 6431.85,
    Tonga: 6332.73,
    Paraguay: 6016.24,
    Vietnam: 5601.4,
    Iraq: 5787.45,
    Mongolia: 5603.76,
    Indonesia: 5529.56,
    Azerbaijan: 5573.41,
    Guatemala: 5416.34,
    Jordan: 5306.87,
    Suriname: 5364.2,
    Egypt: 5004.58,
    Namibia: 5052.58,
    Belize: 5049.95,
    "Marshall Islands": 4990.81,
    Djibouti: 4705.36,
    "El Salvador": 4894.17,
    Bhutan: 4626.95,
    Eswatini: 4596.44,
    Samoa: 4715.21,
    "Cabo Verde": 4585.57,
    "Sri Lanka": 4505.44,
    Micronesia: 4525.5,
    Libya: 4445.38,
    Philippines: 4317.12,
    Tunisia: 4257.46,
    Morocco: 4195.76,
    Bolivia: 4047.44,
    Nigeria: 3483.97,
    "West Bank and Gaza": 3778.58,
    Vanuatu: 3699.82,
    Algeria: 3632.98,
    "Papua New Guinea": 3440.8,
    "Lao P.D.R.": 3214.82,
    "CÙte d'Ivoire": 3185.31,
    Bangladesh: 2994.46,
    Honduras: 3057.54,
    "Solomon Islands": 2955.27,
    Ghana: 2919.42,
    India: 2829.65,
    "São Tomé and Príncipe": 2938.2,
    Uzbekistan: 2740.84,
    "Republic of Congo": 2938,
    Kenya: 2637.89,
    Angola: 2526.5,
    Mauritania: 2277.22,
    Nicaragua: 2237.72,
    Cambodia: 2151.59,
    Senegal: 2126.07,
    Kiribati: 2169.59,
    Cameroon: 2034.72,
    Benin: 1883.33,
    Zimbabwe: 1769.63,
    Haiti: 1696.4,
    Comoros: 1560.31,
    Nepal: 1488.45,
    "Kyrgyz Republic": 1497.56,
    "Timor-Leste": 1453.89,
    Guinea: 1425.85,
    Tanzania: 1383.63,
    Myanmar: 1353.51,
    Uganda: 1282.49,
    Togo: 1312.32,
    Lesotho: 1343.73,
    Zambia: 1212.44,
    "Burkina Faso": 1177.04,
    Mali: 1178.11,
    "Guinea-Bissau": 1112.56,
    Tajikistan: 1013.32,
    "The Gambia": 995.04,
    Rwanda: 988.37,
    Sudan: 944.04,
    Niger: 871.12,
    Chad: 842.1,
    Liberia: 829.81,
    Eritrea: 770.96,
    "Democratic Republic of the Congo": 726.24,
    Yemen: 666.75,
    "Central African Republic": 655.22,
    Mozambique: 602.8,
    Madagascar: 631.72,
    "Sierra Leone": 560.36,
    Malawi: 505.32,
    Somalia: 464.39,
    "South Sudan": 377.55,
    Burundi: 304.54
  },
  2026: {
    Afghanistan: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Pakistan: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    Luxembourg: 160140.74,
    Ireland: 134412.12,
    Switzerland: 115413.44,
    "Macao SAR": 97329.24,
    Norway: 91231.21,
    "United States": 86428.68,
    Denmark: 85818.7,
    Iceland: 84467.26,
    Singapore: 82521.65,
    Australia: 75670.28,
    Sweden: 75506.93,
    Netherlands: 72893.26,
    Austria: 70818.95,
    Qatar: 70733.99,
    Finland: 68389.28,
    Canada: 65414.36,
    Germany: 64800.87,
    Belgium: 62570.41,
    "San Marino": 62122.03,
    "Hong Kong SAR": 61800.65,
    "United Kingdom": 60613.93,
    Israel: 59647.13,
    "New Zealand": 58293.3,
    France: 55393.83,
    Japan: 51797.33,
    "United Arab Emirates": 49445.5,
    Andorra: 47842.56,
    "Taiwan Province of China": 44978.6,
    Malta: 44765.02,
    Korea: 44610.65,
    Italy: 44059.79,
    Slovenia: 39807.17,
    Spain: 39389.01,
    Estonia: 39383.24,
    "Puerto Rico": 38414.45,
    Cyprus: 38034.16,
    "The Bahamas": 36069.75,
    "Czech Republic": 35909.72,
    "Brunei Darussalam": 34239.63,
    Aruba: 32627.18,
    Portugal: 32293.54,
    Lithuania: 30897.27,
    Kuwait: 30151.31,
    Bahrain: 30038.18,
    "Slovak Republic": 30017.18,
    Latvia: 28009.86,
    Hungary: 26975.87,
    "Saudi Arabia": 25698.57,
    Greece: 25534.64,
    Poland: 25412.12,
    Seychelles: 24945.34,
    Croatia: 22366.51,
    Romania: 22268.77,
    "St. Kitts and Nevis": 21773.21,
    Barbados: 21422.72,
    Chile: 21195.99,
    Uruguay: 20711.26,
    Panama: 18326.52,
    "Antigua and Barbuda": 18228.01,
    "Trinidad and Tobago": 18088.33,
    Maldives: 18081.88,
    Oman: 17903.92,
    Palau: 17566.78,
    Guyana: 17509.34,
    China: 17492.96,
    Bulgaria: 16672.04,
    Malaysia: 16260.22,
    "Islamic Republic of Iran": 15274.04,
    Turkey: 14906.4,
    "Costa Rica": 14853.31,
    Turkmenistan: 14482.33,
    "St. Lucia": 13447.42,
    Russia: 13398.98,
    Mauritius: 13291.62,
    Serbia: 13228.98,
    Montenegro: 12986.03,
    Grenada: 12658.32,
    Kazakhstan: 12647.7,
    Mexico: 12395.96,
    "Dominican Republic": 11664.19,
    Argentina: 11129.62,
    Dominica: 10983.68,
    Brazil: 10922.3,
    Nauru: 10564.32,
    Thailand: 10441.09,
    Gabon: 10396.01,
    "St. Vincent and the Grenadines": 9829,
    Botswana: 9823.11,
    "North Macedonia": 9477.47,
    "Bosnia and Herzegovina": 9221.16,
    Belarus: 9087.23,
    Tuvalu: 8497.59,
    "South Africa": 8162.35,
    Peru: 7973.34,
    Albania: 7880.29,
    "Equatorial Guinea": 7689.31,
    Georgia: 7530.72,
    Moldova: 7517.89,
    Colombia: 7326.74,
    Fiji: 7305.72,
    Ukraine: 7185.05,
    Kosovo: 7017.83,
    Armenia: 6776.74,
    Ecuador: 6727.38,
    Jamaica: 6681.14,
    Tonga: 6668.22,
    Paraguay: 6292.03,
    Vietnam: 6148.54,
    Iraq: 5935.15,
    Mongolia: 5898.82,
    Indonesia: 5870.63,
    Azerbaijan: 5714.52,
    Guatemala: 5687.76,
    Jordan: 5584.39,
    Suriname: 5561.57,
    Egypt: 5329.68,
    Namibia: 5302.76,
    Belize: 5150.95,
    "Marshall Islands": 5131.03,
    Djibouti: 5026.78,
    "El Salvador": 5011.22,
    Bhutan: 4965.99,
    Eswatini: 4965.45,
    Samoa: 4928.04,
    "Cabo Verde": 4908.31,
    "Sri Lanka": 4786.45,
    Micronesia: 4651.63,
    Libya: 4591.32,
    Philippines: 4567.06,
    Tunisia: 4399.4,
    Morocco: 4379.79,
    Bolivia: 4267.99,
    Nigeria: 3854.82,
    "West Bank and Gaza": 3833.63,
    Vanuatu: 3793.28,
    Algeria: 3596.42,
    "Papua New Guinea": 3559.96,
    "Lao P.D.R.": 3398.17,
    "CÙte d'Ivoire": 3369.66,
    Bangladesh: 3253.5,
    Honduras: 3191.61,
    "Solomon Islands": 3072.23,
    Ghana: 3053.53,
    India: 3018.1,
    "São Tomé and Príncipe": 3002.28,
    Uzbekistan: 3002.19,
    "Republic of Congo": 2952.43,
    Kenya: 2771.25,
    Angola: 2602.68,
    Mauritania: 2336.88,
    Nicaragua: 2312.77,
    Cambodia: 2308.74,
    Senegal: 2231.18,
    Kiribati: 2201.48,
    Cameroon: 2152.76,
    Benin: 2001.48,
    Zimbabwe: 1846.61,
    Haiti: 1745.55,
    Comoros: 1613.46,
    Nepal: 1580.37,
    "Kyrgyz Republic": 1552.21,
    "Timor-Leste": 1530.18,
    Guinea: 1495.3,
    Tanzania: 1448.61,
    Myanmar: 1421.46,
    Uganda: 1402.67,
    Togo: 1394.9,
    Lesotho: 1394.55,
    Zambia: 1243.12,
    "Burkina Faso": 1241.64,
    Mali: 1237.18,
    "Guinea-Bissau": 1186.06,
    Tajikistan: 1050.58,
    "The Gambia": 1045.86,
    Rwanda: 1045.86,
    Sudan: 998.77,
    Niger: 914.79,
    Chad: 878.22,
    Liberia: 872.57,
    Eritrea: 802.92,
    "Democratic Republic of the Congo": 758.87,
    Yemen: 689.64,
    "Central African Republic": 689.44,
    Mozambique: 682.2,
    Madagascar: 666.99,
    "Sierra Leone": 578.46,
    Malawi: 508.47,
    Somalia: 490.69,
    "South Sudan": 440.62,
    Burundi: 317.21
  }
};

const governmentdebt = {
  "1980": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    "Islamic Republic of Iran": "n/a",
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: "n/a",
    Japan: 122341.9,
    Colombia: "n/a",
    Uzbekistan: "n/a",
    India: "n/a",
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: "n/a",
    Paraguay: "n/a",
    China: "n/a",
    Guinea: "n/a",
    Chile: "n/a",
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: "n/a",
    Sudan: "n/a",
    Pakistan: "n/a",
    "Sierra Leone": "n/a",
    Hungary: "n/a",
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: "n/a",
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": "n/a",
    "Sri Lanka": "n/a",
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: "n/a",
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: "n/a",
    Philippines: "n/a",
    Rwanda: "n/a",
    Kenya: "n/a",
    Senegal: "n/a",
    Thailand: "n/a",
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: "n/a",
    Benin: "n/a",
    Niger: "n/a",
    Armenia: "n/a",
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": "n/a",
    "Dominican Republic": "n/a",
    "Czech Republic": "n/a",
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 94.1,
    Chad: "n/a",
    "United Kingdom": 110.53,
    Italy: "n/a",
    "Equatorial Guinea": 9.73,
    Canada: 143.93,
    Germany: "n/a",
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: "n/a",
    Sweden: "n/a",
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 151.77,
    Spain: 16,
    Australia: "n/a",
    Poland: "n/a",
    Albania: "n/a",
    Malaysia: "n/a",
    Israel: "n/a",
    Denmark: "n/a",
    Morocco: "n/a",
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": "n/a",
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: "n/a",
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: "n/a",
    Belgium: 68.64,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 76.15,
    Peru: "n/a",
    Honduras: "n/a",
    Greece: 1.6,
    Croatia: "n/a",
    Austria: "n/a",
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: "n/a",
    Bolivia: "n/a",
    Ireland: "n/a",
    Bhutan: "n/a",
    Qatar: "n/a",
    "Cabo Verde": "n/a",
    Portugal: "n/a",
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": "n/a",
    Finland: 3.67,
    "Hong Kong SAR": "n/a",
    Comoros: "n/a",
    Suriname: "n/a",
    Namibia: "n/a",
    Tunisia: "n/a",
    Moldova: "n/a",
    "Trinidad and Tobago": "n/a",
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: "n/a",
    "Slovak Republic": "n/a",
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": "n/a",
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: "n/a",
    Panama: "n/a",
    Slovenia: "n/a",
    Georgia: "n/a",
    Azerbaijan: "n/a",
    Bulgaria: "n/a",
    "El Salvador": "n/a",
    Jordan: "n/a",
    Kuwait: "n/a",
    Bahrain: "n/a",
    Lithuania: "n/a",
    Cyprus: "n/a",
    Luxembourg: "n/a",
    Seychelles: "n/a",
    Lesotho: "n/a",
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: "n/a",
    Latvia: "n/a",
    Barbados: "n/a",
    "The Bahamas": "n/a",
    Malta: "n/a",
    Fiji: "n/a",
    Estonia: "n/a",
    "São Tomé and Príncipe": "n/a",
    Aruba: "n/a",
    "St. Lucia": "n/a",
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": "n/a",
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": "n/a",
    Liberia: "n/a",
    "St. Kitts and Nevis": "n/a",
    Grenada: "n/a",
    Dominica: "n/a",
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: "n/a",
    Micronesia: "n/a",
    "Marshall Islands": "n/a",
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1981": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    "Islamic Republic of Iran": "n/a",
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: "n/a",
    Japan: 145038.3,
    Colombia: "n/a",
    Uzbekistan: "n/a",
    India: "n/a",
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: "n/a",
    Paraguay: "n/a",
    China: "n/a",
    Guinea: "n/a",
    Chile: "n/a",
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: "n/a",
    Sudan: "n/a",
    Pakistan: "n/a",
    "Sierra Leone": "n/a",
    Hungary: "n/a",
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: "n/a",
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": "n/a",
    "Sri Lanka": "n/a",
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: "n/a",
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: "n/a",
    Philippines: "n/a",
    Rwanda: "n/a",
    Kenya: "n/a",
    Senegal: "n/a",
    Thailand: "n/a",
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: "n/a",
    Benin: "n/a",
    Niger: "n/a",
    Armenia: "n/a",
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": "n/a",
    "Dominican Republic": "n/a",
    "Czech Republic": "n/a",
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 112.4,
    Chad: "n/a",
    "United Kingdom": 129.78,
    Italy: "n/a",
    "Equatorial Guinea": 17.81,
    Canada: 169.73,
    Germany: "n/a",
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: "n/a",
    Sweden: "n/a",
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 158.4,
    Spain: 22.02,
    Australia: "n/a",
    Poland: "n/a",
    Albania: "n/a",
    Malaysia: "n/a",
    Israel: "n/a",
    Denmark: "n/a",
    Morocco: "n/a",
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": "n/a",
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: "n/a",
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: "n/a",
    Belgium: 84.04,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 85.94,
    Peru: "n/a",
    Honduras: "n/a",
    Greece: 2.27,
    Croatia: "n/a",
    Austria: "n/a",
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: "n/a",
    Bolivia: "n/a",
    Ireland: "n/a",
    Bhutan: "n/a",
    Qatar: "n/a",
    "Cabo Verde": "n/a",
    Portugal: "n/a",
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": "n/a",
    Finland: 4.4,
    "Hong Kong SAR": "n/a",
    Comoros: "n/a",
    Suriname: "n/a",
    Namibia: "n/a",
    Tunisia: "n/a",
    Moldova: "n/a",
    "Trinidad and Tobago": "n/a",
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: "n/a",
    "Slovak Republic": "n/a",
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": "n/a",
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: "n/a",
    Panama: "n/a",
    Slovenia: "n/a",
    Georgia: "n/a",
    Azerbaijan: "n/a",
    Bulgaria: "n/a",
    "El Salvador": "n/a",
    Jordan: "n/a",
    Kuwait: "n/a",
    Bahrain: "n/a",
    Lithuania: "n/a",
    Cyprus: "n/a",
    Luxembourg: "n/a",
    Seychelles: "n/a",
    Lesotho: "n/a",
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: "n/a",
    Latvia: "n/a",
    Barbados: "n/a",
    "The Bahamas": "n/a",
    Malta: "n/a",
    Fiji: "n/a",
    Estonia: "n/a",
    "São Tomé and Príncipe": "n/a",
    Aruba: "n/a",
    "St. Lucia": "n/a",
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": "n/a",
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": "n/a",
    Liberia: "n/a",
    "St. Kitts and Nevis": "n/a",
    Grenada: "n/a",
    Dominica: "n/a",
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: "n/a",
    Micronesia: "n/a",
    "Marshall Islands": "n/a",
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1982": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    "Islamic Republic of Iran": "n/a",
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: "n/a",
    Japan: 166617.3,
    Colombia: "n/a",
    Uzbekistan: "n/a",
    India: "n/a",
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: "n/a",
    Paraguay: "n/a",
    China: "n/a",
    Guinea: "n/a",
    Chile: "n/a",
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: "n/a",
    Sudan: "n/a",
    Pakistan: "n/a",
    "Sierra Leone": "n/a",
    Hungary: "n/a",
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: "n/a",
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": "n/a",
    "Sri Lanka": "n/a",
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: "n/a",
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: "n/a",
    Philippines: "n/a",
    Rwanda: "n/a",
    Kenya: "n/a",
    Senegal: "n/a",
    Thailand: "n/a",
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: "n/a",
    Benin: "n/a",
    Niger: "n/a",
    Armenia: "n/a",
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": "n/a",
    "Dominican Republic": "n/a",
    "Czech Republic": "n/a",
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 148.6,
    Chad: "n/a",
    "United Kingdom": 137.42,
    Italy: "n/a",
    "Equatorial Guinea": 30.14,
    Canada: 200.72,
    Germany: "n/a",
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 11.63,
    Sweden: "n/a",
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 155.62,
    Spain: 31.77,
    Australia: "n/a",
    Poland: "n/a",
    Albania: "n/a",
    Malaysia: "n/a",
    Israel: "n/a",
    Denmark: "n/a",
    Morocco: "n/a",
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": "n/a",
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: "n/a",
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: "n/a",
    Belgium: 101.03,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 100.03,
    Peru: "n/a",
    Honduras: "n/a",
    Greece: 3.14,
    Croatia: "n/a",
    Austria: "n/a",
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: "n/a",
    Bolivia: "n/a",
    Ireland: "n/a",
    Bhutan: "n/a",
    Qatar: "n/a",
    "Cabo Verde": "n/a",
    Portugal: "n/a",
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": "n/a",
    Finland: 5.93,
    "Hong Kong SAR": "n/a",
    Comoros: "n/a",
    Suriname: "n/a",
    Namibia: "n/a",
    Tunisia: "n/a",
    Moldova: "n/a",
    "Trinidad and Tobago": "n/a",
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: "n/a",
    "Slovak Republic": "n/a",
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": "n/a",
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: "n/a",
    Panama: "n/a",
    Slovenia: "n/a",
    Georgia: "n/a",
    Azerbaijan: "n/a",
    Bulgaria: "n/a",
    "El Salvador": "n/a",
    Jordan: "n/a",
    Kuwait: "n/a",
    Bahrain: "n/a",
    Lithuania: "n/a",
    Cyprus: "n/a",
    Luxembourg: "n/a",
    Seychelles: "n/a",
    Lesotho: "n/a",
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: "n/a",
    Latvia: "n/a",
    Barbados: "n/a",
    "The Bahamas": "n/a",
    Malta: "n/a",
    Fiji: "n/a",
    Estonia: "n/a",
    "São Tomé and Príncipe": "n/a",
    Aruba: "n/a",
    "St. Lucia": "n/a",
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": "n/a",
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": "n/a",
    Liberia: "n/a",
    "St. Kitts and Nevis": "n/a",
    Grenada: "n/a",
    Dominica: "n/a",
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: "n/a",
    Micronesia: "n/a",
    "Marshall Islands": "n/a",
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1983": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    "Islamic Republic of Iran": "n/a",
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: "n/a",
    Japan: 191704.6,
    Colombia: "n/a",
    Uzbekistan: "n/a",
    India: "n/a",
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: "n/a",
    Paraguay: "n/a",
    China: "n/a",
    Guinea: "n/a",
    Chile: "n/a",
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: "n/a",
    Sudan: "n/a",
    Pakistan: "n/a",
    "Sierra Leone": "n/a",
    Hungary: "n/a",
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: "n/a",
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": "n/a",
    "Sri Lanka": "n/a",
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: "n/a",
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: "n/a",
    Philippines: "n/a",
    Rwanda: "n/a",
    Kenya: "n/a",
    Senegal: "n/a",
    Thailand: "n/a",
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: "n/a",
    Benin: "n/a",
    Niger: "n/a",
    Armenia: "n/a",
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": "n/a",
    "Dominican Republic": "n/a",
    "Czech Republic": "n/a",
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 173.6,
    Chad: "n/a",
    "United Kingdom": 146.92,
    Italy: "n/a",
    "Equatorial Guinea": 39.28,
    Canada: 241.04,
    Germany: "n/a",
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 21.34,
    Sweden: "n/a",
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 159.2,
    Spain: 43.82,
    Australia: "n/a",
    Poland: "n/a",
    Albania: "n/a",
    Malaysia: "n/a",
    Israel: "n/a",
    Denmark: "n/a",
    Morocco: "n/a",
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": "n/a",
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: "n/a",
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: "n/a",
    Belgium: 118.48,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 115.73,
    Peru: "n/a",
    Honduras: "n/a",
    Greece: 4.29,
    Croatia: "n/a",
    Austria: "n/a",
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: "n/a",
    Bolivia: "n/a",
    Ireland: "n/a",
    Bhutan: "n/a",
    Qatar: "n/a",
    "Cabo Verde": "n/a",
    Portugal: "n/a",
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": "n/a",
    Finland: 7.35,
    "Hong Kong SAR": "n/a",
    Comoros: "n/a",
    Suriname: "n/a",
    Namibia: "n/a",
    Tunisia: "n/a",
    Moldova: "n/a",
    "Trinidad and Tobago": "n/a",
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: "n/a",
    "Slovak Republic": "n/a",
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": "n/a",
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: "n/a",
    Panama: "n/a",
    Slovenia: "n/a",
    Georgia: "n/a",
    Azerbaijan: "n/a",
    Bulgaria: "n/a",
    "El Salvador": "n/a",
    Jordan: "n/a",
    Kuwait: "n/a",
    Bahrain: "n/a",
    Lithuania: "n/a",
    Cyprus: "n/a",
    Luxembourg: "n/a",
    Seychelles: "n/a",
    Lesotho: "n/a",
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: "n/a",
    Latvia: "n/a",
    Barbados: "n/a",
    "The Bahamas": "n/a",
    Malta: "n/a",
    Fiji: "n/a",
    Estonia: "n/a",
    "São Tomé and Príncipe": "n/a",
    Aruba: "n/a",
    "St. Lucia": "n/a",
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": "n/a",
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": "n/a",
    Liberia: "n/a",
    "St. Kitts and Nevis": "n/a",
    Grenada: "n/a",
    Dominica: "n/a",
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: "n/a",
    Micronesia: "n/a",
    "Marshall Islands": "n/a",
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1984": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    "Islamic Republic of Iran": "n/a",
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: "n/a",
    Japan: 209705.2,
    Colombia: "n/a",
    Uzbekistan: "n/a",
    India: "n/a",
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: "n/a",
    Paraguay: "n/a",
    China: "n/a",
    Guinea: "n/a",
    Chile: "n/a",
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: "n/a",
    Sudan: "n/a",
    Pakistan: "n/a",
    "Sierra Leone": "n/a",
    Hungary: "n/a",
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: "n/a",
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": "n/a",
    "Sri Lanka": "n/a",
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: "n/a",
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: "n/a",
    Philippines: "n/a",
    Rwanda: "n/a",
    Kenya: "n/a",
    Senegal: "n/a",
    Thailand: "n/a",
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: "n/a",
    Benin: "n/a",
    Niger: "n/a",
    Armenia: "n/a",
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": "n/a",
    "Dominican Republic": "n/a",
    "Czech Republic": "n/a",
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 205.7,
    Chad: "n/a",
    "United Kingdom": 159.53,
    Italy: "n/a",
    "Equatorial Guinea": 49.59,
    Canada: 278.18,
    Germany: "n/a",
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 29.72,
    Sweden: "n/a",
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 178.35,
    Spain: 59.93,
    Australia: "n/a",
    Poland: "n/a",
    Albania: "n/a",
    Malaysia: "n/a",
    Israel: "n/a",
    Denmark: "n/a",
    Morocco: "n/a",
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": "n/a",
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: "n/a",
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: "n/a",
    Belgium: 133.02,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 128.3,
    Peru: "n/a",
    Honduras: "n/a",
    Greece: 6.36,
    Croatia: "n/a",
    Austria: "n/a",
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: "n/a",
    Bolivia: "n/a",
    Ireland: "n/a",
    Bhutan: "n/a",
    Qatar: "n/a",
    "Cabo Verde": "n/a",
    Portugal: "n/a",
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": "n/a",
    Finland: 8.12,
    "Hong Kong SAR": "n/a",
    Comoros: 124.16,
    Suriname: "n/a",
    Namibia: "n/a",
    Tunisia: "n/a",
    Moldova: "n/a",
    "Trinidad and Tobago": "n/a",
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: "n/a",
    "Slovak Republic": "n/a",
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": "n/a",
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: "n/a",
    Panama: "n/a",
    Slovenia: "n/a",
    Georgia: "n/a",
    Azerbaijan: "n/a",
    Bulgaria: "n/a",
    "El Salvador": "n/a",
    Jordan: "n/a",
    Kuwait: "n/a",
    Bahrain: "n/a",
    Lithuania: "n/a",
    Cyprus: "n/a",
    Luxembourg: "n/a",
    Seychelles: "n/a",
    Lesotho: "n/a",
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: "n/a",
    Latvia: "n/a",
    Barbados: "n/a",
    "The Bahamas": "n/a",
    Malta: "n/a",
    Fiji: "n/a",
    Estonia: "n/a",
    "São Tomé and Príncipe": "n/a",
    Aruba: "n/a",
    "St. Lucia": "n/a",
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": "n/a",
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": "n/a",
    Liberia: "n/a",
    "St. Kitts and Nevis": "n/a",
    Grenada: "n/a",
    Dominica: "n/a",
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: "n/a",
    Micronesia: "n/a",
    "Marshall Islands": "n/a",
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1985": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    "Islamic Republic of Iran": "n/a",
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: "n/a",
    Japan: 232523.5,
    Colombia: "n/a",
    Uzbekistan: "n/a",
    India: "n/a",
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: "n/a",
    Paraguay: "n/a",
    China: "n/a",
    Guinea: "n/a",
    Chile: "n/a",
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: "n/a",
    Sudan: "n/a",
    Pakistan: "n/a",
    "Sierra Leone": "n/a",
    Hungary: "n/a",
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: "n/a",
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": "n/a",
    "Sri Lanka": "n/a",
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: "n/a",
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: "n/a",
    Philippines: "n/a",
    Rwanda: "n/a",
    Kenya: "n/a",
    Senegal: "n/a",
    Thailand: "n/a",
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: "n/a",
    Benin: "n/a",
    Niger: "n/a",
    Armenia: "n/a",
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": "n/a",
    "Dominican Republic": "n/a",
    "Czech Republic": "n/a",
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 232.6,
    Chad: "n/a",
    "United Kingdom": 171.18,
    Italy: "n/a",
    "Equatorial Guinea": 60.93,
    Canada: 325.85,
    Germany: "n/a",
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 39.99,
    Sweden: "n/a",
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 207.65,
    Spain: 75.77,
    Australia: "n/a",
    Poland: "n/a",
    Albania: "n/a",
    Malaysia: "n/a",
    Israel: "n/a",
    Denmark: "n/a",
    Morocco: "n/a",
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": "n/a",
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: "n/a",
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: "n/a",
    Belgium: 147.37,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 145.29,
    Peru: "n/a",
    Honduras: "n/a",
    Greece: 9.04,
    Croatia: "n/a",
    Austria: "n/a",
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: "n/a",
    Bolivia: "n/a",
    Ireland: "n/a",
    Bhutan: "n/a",
    Qatar: "n/a",
    "Cabo Verde": "n/a",
    Portugal: "n/a",
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": 29.01,
    Finland: 9.23,
    "Hong Kong SAR": "n/a",
    Comoros: 123.26,
    Suriname: "n/a",
    Namibia: "n/a",
    Tunisia: "n/a",
    Moldova: "n/a",
    "Trinidad and Tobago": "n/a",
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: "n/a",
    "Slovak Republic": "n/a",
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": "n/a",
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: "n/a",
    Panama: "n/a",
    Slovenia: "n/a",
    Georgia: "n/a",
    Azerbaijan: "n/a",
    Bulgaria: "n/a",
    "El Salvador": "n/a",
    Jordan: "n/a",
    Kuwait: "n/a",
    Bahrain: "n/a",
    Lithuania: "n/a",
    Cyprus: "n/a",
    Luxembourg: "n/a",
    Seychelles: "n/a",
    Lesotho: "n/a",
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: "n/a",
    Latvia: "n/a",
    Barbados: "n/a",
    "The Bahamas": "n/a",
    Malta: "n/a",
    Fiji: "n/a",
    Estonia: "n/a",
    "São Tomé and Príncipe": "n/a",
    Aruba: "n/a",
    "St. Lucia": "n/a",
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": "n/a",
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": "n/a",
    Liberia: "n/a",
    "St. Kitts and Nevis": "n/a",
    Grenada: "n/a",
    Dominica: "n/a",
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: "n/a",
    Micronesia: "n/a",
    "Marshall Islands": "n/a",
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1986": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    "Islamic Republic of Iran": "n/a",
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: "n/a",
    Japan: 264667,
    Colombia: "n/a",
    Uzbekistan: "n/a",
    India: "n/a",
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: "n/a",
    Paraguay: "n/a",
    China: "n/a",
    Guinea: "n/a",
    Chile: "n/a",
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: "n/a",
    Sudan: "n/a",
    Pakistan: "n/a",
    "Sierra Leone": "n/a",
    Hungary: "n/a",
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: "n/a",
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": "n/a",
    "Sri Lanka": "n/a",
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: "n/a",
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: "n/a",
    Philippines: "n/a",
    Rwanda: "n/a",
    Kenya: "n/a",
    Senegal: "n/a",
    Thailand: "n/a",
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: "n/a",
    Benin: "n/a",
    Niger: "n/a",
    Armenia: "n/a",
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": "n/a",
    "Dominican Republic": "n/a",
    "Czech Republic": "n/a",
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 255,
    Chad: "n/a",
    "United Kingdom": 184.34,
    Italy: "n/a",
    "Equatorial Guinea": 47.94,
    Canada: 365.08,
    Germany: "n/a",
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 49.27,
    Sweden: "n/a",
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 267.66,
    Spain: 88.96,
    Australia: "n/a",
    Poland: "n/a",
    Albania: "n/a",
    Malaysia: "n/a",
    Israel: "n/a",
    Denmark: "n/a",
    Morocco: "n/a",
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": "n/a",
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: "n/a",
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: "n/a",
    Belgium: 161.08,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 154.12,
    Peru: "n/a",
    Honduras: "n/a",
    Greece: 10.92,
    Croatia: "n/a",
    Austria: "n/a",
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: "n/a",
    Bolivia: "n/a",
    Ireland: "n/a",
    Bhutan: "n/a",
    Qatar: "n/a",
    "Cabo Verde": "n/a",
    Portugal: "n/a",
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": 35.86,
    Finland: 10.32,
    "Hong Kong SAR": "n/a",
    Comoros: 122.27,
    Suriname: "n/a",
    Namibia: "n/a",
    Tunisia: "n/a",
    Moldova: "n/a",
    "Trinidad and Tobago": "n/a",
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: "n/a",
    "Slovak Republic": "n/a",
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": "n/a",
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: "n/a",
    Panama: "n/a",
    Slovenia: "n/a",
    Georgia: "n/a",
    Azerbaijan: "n/a",
    Bulgaria: "n/a",
    "El Salvador": "n/a",
    Jordan: "n/a",
    Kuwait: "n/a",
    Bahrain: "n/a",
    Lithuania: "n/a",
    Cyprus: "n/a",
    Luxembourg: "n/a",
    Seychelles: "n/a",
    Lesotho: "n/a",
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: "n/a",
    Latvia: "n/a",
    Barbados: "n/a",
    "The Bahamas": "n/a",
    Malta: "n/a",
    Fiji: "n/a",
    Estonia: "n/a",
    "São Tomé and Príncipe": "n/a",
    Aruba: "n/a",
    "St. Lucia": "n/a",
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": "n/a",
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": "n/a",
    Liberia: "n/a",
    "St. Kitts and Nevis": "n/a",
    Grenada: "n/a",
    Dominica: "n/a",
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: "n/a",
    Micronesia: "n/a",
    "Marshall Islands": "n/a",
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1987": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    "Islamic Republic of Iran": "n/a",
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: "n/a",
    Japan: 283065.9,
    Colombia: "n/a",
    Uzbekistan: "n/a",
    India: "n/a",
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: "n/a",
    Paraguay: "n/a",
    China: "n/a",
    Guinea: "n/a",
    Chile: "n/a",
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: "n/a",
    Sudan: "n/a",
    Pakistan: "n/a",
    "Sierra Leone": "n/a",
    Hungary: "n/a",
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: "n/a",
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": "n/a",
    "Sri Lanka": "n/a",
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: "n/a",
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: "n/a",
    Philippines: "n/a",
    Rwanda: "n/a",
    Kenya: "n/a",
    Senegal: "n/a",
    Thailand: "n/a",
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: "n/a",
    Benin: "n/a",
    Niger: "n/a",
    Armenia: "n/a",
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": "n/a",
    "Dominican Republic": "n/a",
    "Czech Republic": "n/a",
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 288.3,
    Chad: "n/a",
    "United Kingdom": 195.16,
    Italy: "n/a",
    "Equatorial Guinea": 47.11,
    Canada: 400.66,
    Germany: "n/a",
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 58.78,
    Sweden: "n/a",
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 244.55,
    Spain: 98.92,
    Australia: "n/a",
    Poland: "n/a",
    Albania: "n/a",
    Malaysia: "n/a",
    Israel: "n/a",
    Denmark: "n/a",
    Morocco: "n/a",
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": "n/a",
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: "n/a",
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: "n/a",
    Belgium: 173.69,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 161.81,
    Peru: "n/a",
    Honduras: "n/a",
    Greece: 13.68,
    Croatia: "n/a",
    Austria: "n/a",
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: "n/a",
    Bolivia: "n/a",
    Ireland: "n/a",
    Bhutan: "n/a",
    Qatar: "n/a",
    "Cabo Verde": "n/a",
    Portugal: "n/a",
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": 39.27,
    Finland: 11.96,
    "Hong Kong SAR": "n/a",
    Comoros: 121.4,
    Suriname: "n/a",
    Namibia: "n/a",
    Tunisia: "n/a",
    Moldova: "n/a",
    "Trinidad and Tobago": "n/a",
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: "n/a",
    "Slovak Republic": "n/a",
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": "n/a",
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: "n/a",
    Panama: "n/a",
    Slovenia: "n/a",
    Georgia: "n/a",
    Azerbaijan: "n/a",
    Bulgaria: "n/a",
    "El Salvador": "n/a",
    Jordan: "n/a",
    Kuwait: "n/a",
    Bahrain: "n/a",
    Lithuania: "n/a",
    Cyprus: "n/a",
    Luxembourg: "n/a",
    Seychelles: "n/a",
    Lesotho: "n/a",
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: "n/a",
    Latvia: "n/a",
    Barbados: "n/a",
    "The Bahamas": "n/a",
    Malta: "n/a",
    Fiji: "n/a",
    Estonia: "n/a",
    "São Tomé and Príncipe": "n/a",
    Aruba: "n/a",
    "St. Lucia": "n/a",
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": "n/a",
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": "n/a",
    Liberia: "n/a",
    "St. Kitts and Nevis": "n/a",
    Grenada: "n/a",
    Dominica: "n/a",
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: "n/a",
    Micronesia: "n/a",
    "Marshall Islands": "n/a",
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1988": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    "Islamic Republic of Iran": "n/a",
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: "n/a",
    Japan: 288184.9,
    Colombia: "n/a",
    Uzbekistan: "n/a",
    India: "n/a",
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: "n/a",
    Paraguay: "n/a",
    China: "n/a",
    Guinea: "n/a",
    Chile: "n/a",
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: "n/a",
    Sudan: "n/a",
    Pakistan: "n/a",
    "Sierra Leone": "n/a",
    Hungary: "n/a",
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: "n/a",
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": "n/a",
    "Sri Lanka": "n/a",
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: "n/a",
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: "n/a",
    Philippines: "n/a",
    Rwanda: "n/a",
    Kenya: "n/a",
    Senegal: "n/a",
    Thailand: "n/a",
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: "n/a",
    Benin: "n/a",
    Niger: "n/a",
    Armenia: "n/a",
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": "n/a",
    "Dominican Republic": "n/a",
    "Czech Republic": "n/a",
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 311.2,
    Chad: "n/a",
    "United Kingdom": 206.02,
    Italy: 575.96,
    "Equatorial Guinea": 54.97,
    Canada: 437.22,
    Germany: "n/a",
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 80.85,
    Sweden: "n/a",
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 215.36,
    Spain: 100.8,
    Australia: "n/a",
    Poland: "n/a",
    Albania: "n/a",
    Malaysia: "n/a",
    Israel: "n/a",
    Denmark: "n/a",
    Morocco: "n/a",
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": "n/a",
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: "n/a",
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: "n/a",
    Belgium: 186.5,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 174.52,
    Peru: "n/a",
    Honduras: "n/a",
    Greece: 18.12,
    Croatia: "n/a",
    Austria: 68.62,
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: "n/a",
    Bolivia: "n/a",
    Ireland: "n/a",
    Bhutan: "n/a",
    Qatar: "n/a",
    "Cabo Verde": "n/a",
    Portugal: "n/a",
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": 37.95,
    Finland: 12.69,
    "Hong Kong SAR": "n/a",
    Comoros: 120.22,
    Suriname: "n/a",
    Namibia: "n/a",
    Tunisia: "n/a",
    Moldova: "n/a",
    "Trinidad and Tobago": 8.61,
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: "n/a",
    "Slovak Republic": "n/a",
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": "n/a",
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: "n/a",
    Panama: "n/a",
    Slovenia: "n/a",
    Georgia: "n/a",
    Azerbaijan: "n/a",
    Bulgaria: "n/a",
    "El Salvador": "n/a",
    Jordan: 4.35,
    Kuwait: "n/a",
    Bahrain: "n/a",
    Lithuania: "n/a",
    Cyprus: "n/a",
    Luxembourg: "n/a",
    Seychelles: "n/a",
    Lesotho: 0.28,
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: "n/a",
    Latvia: "n/a",
    Barbados: "n/a",
    "The Bahamas": "n/a",
    Malta: "n/a",
    Fiji: "n/a",
    Estonia: "n/a",
    "São Tomé and Príncipe": "n/a",
    Aruba: "n/a",
    "St. Lucia": "n/a",
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": "n/a",
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": "n/a",
    Liberia: "n/a",
    "St. Kitts and Nevis": "n/a",
    Grenada: "n/a",
    Dominica: "n/a",
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: 0,
    Micronesia: "n/a",
    "Marshall Islands": "n/a",
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1989": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    "Islamic Republic of Iran": "n/a",
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: "n/a",
    Japan: 281739.7,
    Colombia: "n/a",
    Uzbekistan: "n/a",
    India: "n/a",
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: "n/a",
    Paraguay: "n/a",
    China: "n/a",
    Guinea: "n/a",
    Chile: "n/a",
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: "n/a",
    Sudan: "n/a",
    Pakistan: "n/a",
    "Sierra Leone": "n/a",
    Hungary: "n/a",
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: "n/a",
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": "n/a",
    "Sri Lanka": "n/a",
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: "n/a",
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: "n/a",
    Philippines: "n/a",
    Rwanda: "n/a",
    Kenya: "n/a",
    Senegal: "n/a",
    Thailand: "n/a",
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: "n/a",
    Benin: "n/a",
    Niger: "n/a",
    Armenia: "n/a",
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": 0,
    "Dominican Republic": "n/a",
    "Czech Republic": "n/a",
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 343.4,
    Chad: "n/a",
    "United Kingdom": 199.96,
    Italy: 650.08,
    "Equatorial Guinea": 62.57,
    Canada: 476.73,
    Germany: "n/a",
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 114.73,
    Sweden: "n/a",
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 229.16,
    Spain: 117.06,
    Australia: 66.24,
    Poland: "n/a",
    Albania: "n/a",
    Malaysia: "n/a",
    Israel: "n/a",
    Denmark: "n/a",
    Morocco: "n/a",
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": "n/a",
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: "n/a",
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: "n/a",
    Belgium: 197.12,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 184.94,
    Peru: "n/a",
    Honduras: "n/a",
    Greece: 22.57,
    Croatia: "n/a",
    Austria: 72.08,
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: "n/a",
    Bolivia: "n/a",
    Ireland: "n/a",
    Bhutan: "n/a",
    Qatar: "n/a",
    "Cabo Verde": "n/a",
    Portugal: "n/a",
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": 40.49,
    Finland: 12.26,
    "Hong Kong SAR": "n/a",
    Comoros: 119.28,
    Suriname: "n/a",
    Namibia: "n/a",
    Tunisia: "n/a",
    Moldova: "n/a",
    "Trinidad and Tobago": 9.56,
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: "n/a",
    "Slovak Republic": "n/a",
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": "n/a",
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: "n/a",
    Panama: "n/a",
    Slovenia: "n/a",
    Georgia: "n/a",
    Azerbaijan: "n/a",
    Bulgaria: "n/a",
    "El Salvador": "n/a",
    Jordan: 5.35,
    Kuwait: "n/a",
    Bahrain: "n/a",
    Lithuania: "n/a",
    Cyprus: "n/a",
    Luxembourg: "n/a",
    Seychelles: "n/a",
    Lesotho: 0.37,
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: "n/a",
    Latvia: "n/a",
    Barbados: "n/a",
    "The Bahamas": "n/a",
    Malta: "n/a",
    Fiji: "n/a",
    Estonia: "n/a",
    "São Tomé and Príncipe": "n/a",
    Aruba: "n/a",
    "St. Lucia": "n/a",
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": "n/a",
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": "n/a",
    Liberia: "n/a",
    "St. Kitts and Nevis": "n/a",
    Grenada: "n/a",
    Dominica: "n/a",
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: 0,
    Micronesia: "n/a",
    "Marshall Islands": "n/a",
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1990": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: 509.17,
    Venezuela: "n/a",
    "Islamic Republic of Iran": "n/a",
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: 26432.99,
    Japan: 291689.2,
    Colombia: "n/a",
    Uzbekistan: "n/a",
    India: "n/a",
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: 358.18,
    Paraguay: 4041.07,
    China: "n/a",
    Guinea: 1753.93,
    Chile: "n/a",
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: "n/a",
    Sudan: "n/a",
    Pakistan: "n/a",
    "Sierra Leone": "n/a",
    Hungary: "n/a",
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: 1089.06,
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": "n/a",
    "Sri Lanka": 310.78,
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: "n/a",
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: "n/a",
    Philippines: "n/a",
    Rwanda: "n/a",
    Kenya: "n/a",
    Senegal: "n/a",
    Thailand: "n/a",
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: 1553.09,
    Benin: "n/a",
    Niger: "n/a",
    Armenia: "n/a",
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": 0,
    "Dominican Republic": "n/a",
    "Czech Republic": "n/a",
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 374.9,
    Chad: "n/a",
    "United Kingdom": 191.04,
    Italy: 731.43,
    "Equatorial Guinea": 56.95,
    Canada: 512.89,
    Germany: "n/a",
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 134.54,
    Sweden: "n/a",
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 217.04,
    Spain: 135.2,
    Australia: 68.08,
    Poland: "n/a",
    Albania: "n/a",
    Malaysia: 94.71,
    Israel: "n/a",
    Denmark: "n/a",
    Morocco: 189.73,
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": "n/a",
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: 53.29,
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: 0.09,
    Belgium: 215.47,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 199.05,
    Peru: "n/a",
    Honduras: 40.55,
    Greece: 33.31,
    Croatia: "n/a",
    Austria: 76.92,
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: 123.12,
    Bolivia: "n/a",
    Ireland: 34.19,
    Bhutan: "n/a",
    Qatar: 2.87,
    "Cabo Verde": "n/a",
    Portugal: 31.8,
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": 42.52,
    Finland: 12.6,
    "Hong Kong SAR": "n/a",
    Comoros: 118.34,
    Suriname: 0,
    Namibia: "n/a",
    Tunisia: "n/a",
    Moldova: "n/a",
    "Trinidad and Tobago": 10.45,
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: "n/a",
    "Slovak Republic": "n/a",
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": "n/a",
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: "n/a",
    Panama: "n/a",
    Slovenia: "n/a",
    Georgia: "n/a",
    Azerbaijan: "n/a",
    Bulgaria: "n/a",
    "El Salvador": "n/a",
    Jordan: 6.32,
    Kuwait: "n/a",
    Bahrain: 0.14,
    Lithuania: "n/a",
    Cyprus: "n/a",
    Luxembourg: "n/a",
    Seychelles: 1.58,
    Lesotho: 0.31,
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: 0.92,
    Latvia: "n/a",
    Barbados: "n/a",
    "The Bahamas": 0.69,
    Malta: "n/a",
    Fiji: "n/a",
    Estonia: "n/a",
    "São Tomé and Príncipe": "n/a",
    Aruba: "n/a",
    "St. Lucia": 0.27,
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": 1.22,
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": 0.38,
    Liberia: "n/a",
    "St. Kitts and Nevis": "n/a",
    Grenada: "n/a",
    Dominica: 0.31,
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: 0,
    Micronesia: "n/a",
    "Marshall Islands": "n/a",
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1991": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: 568.37,
    Venezuela: "n/a",
    "Islamic Republic of Iran": "n/a",
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: 29810.11,
    Japan: 306569.4,
    Colombia: "n/a",
    Uzbekistan: "n/a",
    India: 5076.45,
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: 446.81,
    Paraguay: 4571.59,
    China: "n/a",
    Guinea: 2240.79,
    Chile: 4937.07,
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: "n/a",
    Sudan: "n/a",
    Pakistan: "n/a",
    "Sierra Leone": "n/a",
    Hungary: "n/a",
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: 1360.01,
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": "n/a",
    "Sri Lanka": 366.7,
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: 805.26,
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: "n/a",
    Philippines: "n/a",
    Rwanda: "n/a",
    Kenya: "n/a",
    Senegal: "n/a",
    Thailand: "n/a",
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: 1450.24,
    Benin: "n/a",
    Niger: "n/a",
    Armenia: "n/a",
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": 0,
    "Dominican Republic": "n/a",
    "Czech Republic": "n/a",
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 398.2,
    Chad: "n/a",
    "United Kingdom": 201.04,
    Italy: 827.25,
    "Equatorial Guinea": 63,
    Canada: 573.06,
    Germany: 618.22,
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 154.18,
    Sweden: "n/a",
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 310.04,
    Spain: 150.41,
    Australia: 89.98,
    Poland: "n/a",
    Albania: "n/a",
    Malaysia: 99.07,
    Israel: "n/a",
    Denmark: "n/a",
    Morocco: 179.9,
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": 194.86,
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: 61.18,
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: 0.11,
    Belgium: 228.31,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 209.71,
    Peru: "n/a",
    Honduras: 30.21,
    Greece: 42,
    Croatia: "n/a",
    Austria: 82.76,
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: 135.37,
    Bolivia: "n/a",
    Ireland: 36,
    Bhutan: "n/a",
    Qatar: 4.42,
    "Cabo Verde": "n/a",
    Portugal: 38.71,
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": 43.64,
    Finland: 19.03,
    "Hong Kong SAR": "n/a",
    Comoros: 117.5,
    Suriname: 0.01,
    Namibia: "n/a",
    Tunisia: 8.72,
    Moldova: "n/a",
    "Trinidad and Tobago": 11.35,
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: 3.58,
    "Slovak Republic": "n/a",
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": "n/a",
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: "n/a",
    Panama: "n/a",
    Slovenia: "n/a",
    Georgia: "n/a",
    Azerbaijan: "n/a",
    Bulgaria: "n/a",
    "El Salvador": 2.28,
    Jordan: 6.18,
    Kuwait: 4.73,
    Bahrain: 0.14,
    Lithuania: "n/a",
    Cyprus: "n/a",
    Luxembourg: "n/a",
    Seychelles: 1.77,
    Lesotho: 0.25,
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: 1.13,
    Latvia: "n/a",
    Barbados: "n/a",
    "The Bahamas": 0.79,
    Malta: "n/a",
    Fiji: "n/a",
    Estonia: "n/a",
    "São Tomé and Príncipe": "n/a",
    Aruba: "n/a",
    "St. Lucia": 0.33,
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": 1.29,
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": 0.39,
    Liberia: "n/a",
    "St. Kitts and Nevis": "n/a",
    Grenada: 0.34,
    Dominica: 0.35,
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: 0,
    Micronesia: "n/a",
    "Marshall Islands": "n/a",
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1992": {
    Afghanistan: "n/a",
    Argentina: 63.25,
    Ethiopia: 25.98,
    Lebanon: "n/a",
    Syria: 645.23,
    Venezuela: "n/a",
    "Islamic Republic of Iran": "n/a",
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: 33356.41,
    Japan: 336606.4,
    Colombia: "n/a",
    Uzbekistan: "n/a",
    India: 5995.4,
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: 638.36,
    Paraguay: 3692.9,
    China: "n/a",
    Guinea: 2555.79,
    Chile: 5117.63,
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: "n/a",
    Sudan: 1.5,
    Pakistan: "n/a",
    "Sierra Leone": "n/a",
    Hungary: "n/a",
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: 1535.52,
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": "n/a",
    "Sri Lanka": 405.56,
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: 808.34,
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: "n/a",
    Philippines: "n/a",
    Rwanda: "n/a",
    Kenya: "n/a",
    Senegal: "n/a",
    Thailand: "n/a",
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: 1385.13,
    Benin: "n/a",
    Niger: "n/a",
    Armenia: "n/a",
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": 0,
    "Dominican Republic": "n/a",
    "Czech Republic": "n/a",
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 454.9,
    Chad: "n/a",
    "United Kingdom": 242.85,
    Italy: 933.91,
    "Equatorial Guinea": 57.99,
    Canada: 633.88,
    Germany: 705.57,
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 185.64,
    Sweden: "n/a",
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 365.85,
    Spain: 170.74,
    Australia: 119.51,
    Poland: "n/a",
    Albania: "n/a",
    Malaysia: 97.01,
    Israel: "n/a",
    Denmark: 616.3,
    Morocco: 208.8,
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": 245.4,
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: 69.69,
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: 0.17,
    Belgium: 244.99,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 220.61,
    Peru: "n/a",
    Honduras: 31.67,
    Greece: 51.99,
    Croatia: "n/a",
    Austria: 87.27,
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: 155.35,
    Bolivia: "n/a",
    Ireland: 37.04,
    Bhutan: "n/a",
    Qatar: 4.63,
    "Cabo Verde": "n/a",
    Portugal: 39.56,
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": 45.35,
    Finland: 33.31,
    "Hong Kong SAR": "n/a",
    Comoros: 116.82,
    Suriname: 0.01,
    Namibia: "n/a",
    Tunisia: 9.78,
    Moldova: "n/a",
    "Trinidad and Tobago": 11.56,
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: 4.99,
    "Slovak Republic": "n/a",
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": "n/a",
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: "n/a",
    Panama: "n/a",
    Slovenia: "n/a",
    Georgia: "n/a",
    Azerbaijan: "n/a",
    Bulgaria: "n/a",
    "El Salvador": 2.11,
    Jordan: 5.7,
    Kuwait: 6.86,
    Bahrain: 0.14,
    Lithuania: "n/a",
    Cyprus: "n/a",
    Luxembourg: "n/a",
    Seychelles: 1.78,
    Lesotho: 1.61,
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: 1.27,
    Latvia: "n/a",
    Barbados: "n/a",
    "The Bahamas": 0.92,
    Malta: "n/a",
    Fiji: 1.05,
    Estonia: "n/a",
    "São Tomé and Príncipe": "n/a",
    Aruba: "n/a",
    "St. Lucia": 0.4,
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": 1.29,
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": 0.4,
    Liberia: "n/a",
    "St. Kitts and Nevis": "n/a",
    Grenada: 0.34,
    Dominica: 0.35,
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: 0.01,
    Micronesia: "n/a",
    "Marshall Islands": "n/a",
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1993": {
    Afghanistan: "n/a",
    Argentina: 71.11,
    Ethiopia: 53.42,
    Lebanon: "n/a",
    Syria: 711.41,
    Venezuela: "n/a",
    "Islamic Republic of Iran": "n/a",
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: 35372.26,
    Japan: 367463.7,
    Colombia: "n/a",
    Uzbekistan: "n/a",
    India: 6861.49,
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: 894.25,
    Paraguay: 3361.84,
    China: "n/a",
    Guinea: 3004.07,
    Chile: 5635.74,
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: "n/a",
    Sudan: 2.39,
    Pakistan: "n/a",
    "Sierra Leone": "n/a",
    Hungary: "n/a",
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: 1636,
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": "n/a",
    "Sri Lanka": 483.91,
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: 991.56,
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: "n/a",
    Philippines: 1242.87,
    Rwanda: "n/a",
    Kenya: "n/a",
    Senegal: "n/a",
    Thailand: "n/a",
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: 1469.38,
    Benin: "n/a",
    Niger: "n/a",
    Armenia: "n/a",
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": 0,
    "Dominican Republic": "n/a",
    "Czech Republic": "n/a",
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 531.7,
    Chad: "n/a",
    "United Kingdom": 292.24,
    Italy: 1056.78,
    "Equatorial Guinea": 69.84,
    Canada: 707.67,
    Germany: 789.57,
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 220.08,
    Sweden: 1089.15,
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 458.94,
    Spain: 220.03,
    Australia: 139.4,
    Poland: "n/a",
    Albania: "n/a",
    Malaysia: 95.9,
    Israel: "n/a",
    Denmark: 730.03,
    Morocco: 237.97,
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": 291.8,
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: 72.36,
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: 0.32,
    Belgium: 260.27,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 230.17,
    Peru: "n/a",
    Honduras: 34.66,
    Greece: 73.42,
    Croatia: "n/a",
    Austria: 97.55,
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: 178.34,
    Bolivia: "n/a",
    Ireland: 41.13,
    Bhutan: 4.35,
    Qatar: 9.78,
    "Cabo Verde": "n/a",
    Portugal: 40.75,
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": 45.23,
    Finland: 46.4,
    "Hong Kong SAR": "n/a",
    Comoros: 116.09,
    Suriname: 0.01,
    Namibia: 1.77,
    Tunisia: 10.71,
    Moldova: "n/a",
    "Trinidad and Tobago": 14.65,
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: 4.94,
    "Slovak Republic": "n/a",
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": "n/a",
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: 0.74,
    Panama: "n/a",
    Slovenia: "n/a",
    Georgia: "n/a",
    Azerbaijan: "n/a",
    Bulgaria: "n/a",
    "El Salvador": 2.13,
    Jordan: 5.54,
    Kuwait: 6.43,
    Bahrain: 0.14,
    Lithuania: "n/a",
    Cyprus: "n/a",
    Luxembourg: "n/a",
    Seychelles: 2.02,
    Lesotho: 2.04,
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: 1.22,
    Latvia: "n/a",
    Barbados: "n/a",
    "The Bahamas": 1.01,
    Malta: "n/a",
    Fiji: 1.21,
    Estonia: "n/a",
    "São Tomé and Príncipe": "n/a",
    Aruba: "n/a",
    "St. Lucia": 0.42,
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": 1.3,
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": 0.4,
    Liberia: "n/a",
    "St. Kitts and Nevis": "n/a",
    Grenada: 0.38,
    Dominica: 0.37,
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: 0.01,
    Micronesia: "n/a",
    "Marshall Islands": "n/a",
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1994": {
    Afghanistan: "n/a",
    Argentina: 81.82,
    Ethiopia: 62.45,
    Lebanon: "n/a",
    Syria: 824.85,
    Venezuela: "n/a",
    "Islamic Republic of Iran": "n/a",
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: 37079.17,
    Japan: 431014.9,
    Colombia: "n/a",
    Uzbekistan: "n/a",
    India: 7681.37,
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: 985.35,
    Paraguay: 2802.27,
    China: "n/a",
    Guinea: 3276.79,
    Chile: 5454.23,
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: "n/a",
    Sudan: 6.67,
    Pakistan: 1492.45,
    "Sierra Leone": "n/a",
    Hungary: "n/a",
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: 2073.54,
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": "n/a",
    "Sri Lanka": 551.91,
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: 1716.74,
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: "n/a",
    Philippines: 1229.22,
    Rwanda: "n/a",
    Kenya: "n/a",
    Senegal: "n/a",
    Thailand: "n/a",
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: 1765.84,
    Benin: "n/a",
    Niger: "n/a",
    Armenia: "n/a",
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": 0,
    "Dominican Republic": "n/a",
    "Czech Republic": "n/a",
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 588.6,
    Chad: "n/a",
    "United Kingdom": 330.19,
    Italy: 1178.32,
    "Equatorial Guinea": 143.63,
    Canada: 772.02,
    Germany: 869.71,
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 245.07,
    Sweden: 1204.37,
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 454.39,
    Spain: 243.75,
    Australia: 153.15,
    Poland: "n/a",
    Albania: "n/a",
    Malaysia: 93.08,
    Israel: "n/a",
    Denmark: 747.32,
    Morocco: 245.71,
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": 343.58,
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: 78.08,
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: 0.65,
    Belgium: 270.66,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 232.02,
    Peru: "n/a",
    Honduras: 33.74,
    Greece: 81.61,
    Croatia: "n/a",
    Austria: 107.64,
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: 193.97,
    Bolivia: "n/a",
    Ireland: 41.67,
    Bhutan: 4.4,
    Qatar: 11.86,
    "Cabo Verde": "n/a",
    Portugal: 46.65,
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": 43.6,
    Finland: 50.94,
    "Hong Kong SAR": "n/a",
    Comoros: 115.18,
    Suriname: 0.04,
    Namibia: 2.06,
    Tunisia: 11.56,
    Moldova: "n/a",
    "Trinidad and Tobago": 15.68,
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: 5.88,
    "Slovak Republic": "n/a",
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": 3.27,
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: 0.77,
    Panama: 6.77,
    Slovenia: "n/a",
    Georgia: "n/a",
    Azerbaijan: "n/a",
    Bulgaria: "n/a",
    "El Salvador": 2.35,
    Jordan: 5.74,
    Kuwait: 6.53,
    Bahrain: 0.14,
    Lithuania: "n/a",
    Cyprus: "n/a",
    Luxembourg: "n/a",
    Seychelles: 3.04,
    Lesotho: 2.2,
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: 1.35,
    Latvia: "n/a",
    Barbados: 2.45,
    "The Bahamas": 1.1,
    Malta: "n/a",
    Fiji: 1.38,
    Estonia: "n/a",
    "São Tomé and Príncipe": "n/a",
    Aruba: "n/a",
    "St. Lucia": 0.46,
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": 1.43,
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": 0.45,
    Liberia: "n/a",
    "St. Kitts and Nevis": "n/a",
    Grenada: 0.4,
    Dominica: 0.41,
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: 0.01,
    Micronesia: "n/a",
    "Marshall Islands": "n/a",
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1995": {
    Afghanistan: "n/a",
    Argentina: 88.71,
    Ethiopia: 70.57,
    Lebanon: "n/a",
    Syria: 871.55,
    Venezuela: "n/a",
    "Islamic Republic of Iran": "n/a",
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: 38366.08,
    Japan: 482626,
    Colombia: "n/a",
    Uzbekistan: "n/a",
    India: 8544.61,
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: 985.5,
    Paraguay: 3123.29,
    China: 1319.84,
    Guinea: 3389.95,
    Chile: 5062.57,
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: "n/a",
    Sudan: 9.29,
    Pakistan: 1594.9,
    "Sierra Leone": "n/a",
    Hungary: 4905.21,
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: 3138.07,
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": "n/a",
    "Sri Lanka": 635.7,
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: 2086.02,
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: "n/a",
    Philippines: 1324.43,
    Rwanda: 388.29,
    Kenya: "n/a",
    Senegal: "n/a",
    Thailand: "n/a",
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: 1933.66,
    Benin: "n/a",
    Niger: 794.61,
    Armenia: "n/a",
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": 0,
    "Dominican Republic": "n/a",
    "Czech Republic": 216.65,
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 683.5,
    Chad: "n/a",
    "United Kingdom": 371.85,
    Italy: 1179.59,
    "Equatorial Guinea": 115.15,
    Canada: 832.32,
    Germany: 1040.19,
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 267.61,
    Sweden: 1302.81,
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 315.31,
    Spain: 283.46,
    Australia: 159.28,
    Poland: 165.2,
    Albania: "n/a",
    Malaysia: 92.48,
    Israel: "n/a",
    Denmark: 739.97,
    Morocco: 260.51,
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": 398.58,
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: 89.65,
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: 0.9,
    Belgium: 276.34,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 237.97,
    Peru: "n/a",
    Honduras: 33.53,
    Greece: 92.12,
    Croatia: "n/a",
    Austria: 119.83,
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: 208.74,
    Bolivia: "n/a",
    Ireland: 43.06,
    Bhutan: 3.75,
    Qatar: 12.54,
    "Cabo Verde": "n/a",
    Portugal: 51.91,
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": 41.23,
    Finland: 54.35,
    "Hong Kong SAR": "n/a",
    Comoros: 114.37,
    Suriname: 0.07,
    Namibia: 2.58,
    Tunisia: 12.8,
    Moldova: 5.15,
    "Trinidad and Tobago": 16.17,
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: 6.73,
    "Slovak Republic": 4.27,
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": 3.35,
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: 0.88,
    Panama: 6.77,
    Slovenia: 1.93,
    Georgia: "n/a",
    Azerbaijan: 0.41,
    Bulgaria: "n/a",
    "El Salvador": 2.5,
    Jordan: 5.65,
    Kuwait: 6.33,
    Bahrain: 0.36,
    Lithuania: "n/a",
    Cyprus: 3.59,
    Luxembourg: 1.54,
    Seychelles: 3.23,
    Lesotho: 2.37,
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: 1.33,
    Latvia: "n/a",
    Barbados: 2.63,
    "The Bahamas": 1.16,
    Malta: 1.25,
    Fiji: 1.45,
    Estonia: 0.25,
    "São Tomé and Príncipe": "n/a",
    Aruba: 1.3,
    "St. Lucia": 0.47,
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": 1.53,
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": 0.43,
    Liberia: "n/a",
    "St. Kitts and Nevis": "n/a",
    Grenada: 0.4,
    Dominica: 0.44,
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: 0.01,
    Micronesia: 0.12,
    "Marshall Islands": 0.01,
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1996": {
    Afghanistan: "n/a",
    Argentina: 99.05,
    Ethiopia: 72.05,
    Lebanon: "n/a",
    Syria: 977.42,
    Venezuela: "n/a",
    "Islamic Republic of Iran": 61300.88,
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: 39659.45,
    Japan: 525137.5,
    Colombia: 28020.8,
    Uzbekistan: "n/a",
    India: 9363.91,
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: 952.59,
    Paraguay: 3380.43,
    China: 1532.81,
    Guinea: 3612.07,
    Chile: 4724.48,
    Myanmar: "n/a",
    Uganda: "n/a",
    Tanzania: "n/a",
    Cambodia: 2774.46,
    Sudan: 23.14,
    Pakistan: 1819.3,
    "Sierra Leone": "n/a",
    Hungary: 5077.85,
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: 3954.48,
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": 817.12,
    "Sri Lanka": 716.39,
    Russia: "n/a",
    "CÙte d'Ivoire": "n/a",
    Algeria: 2529.12,
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: 1397,
    Philippines: 1316.94,
    Rwanda: 407.56,
    Kenya: "n/a",
    Senegal: 2.3,
    Thailand: 685.23,
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: 2002.22,
    Benin: "n/a",
    Niger: 778.7,
    Armenia: 269.65,
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": 0,
    "Dominican Republic": "n/a",
    "Czech Republic": 211.78,
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: "n/a",
    France: 751.3,
    Chad: "n/a",
    "United Kingdom": 397.8,
    Italy: 1245.73,
    "Equatorial Guinea": 128.84,
    Canada: 861.93,
    Germany: 1110.44,
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 274.45,
    Sweden: 1343.66,
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 300.01,
    Spain: 319.98,
    Australia: 159.17,
    Poland: 183.29,
    Albania: "n/a",
    Malaysia: 90.56,
    Israel: "n/a",
    Denmark: 742.7,
    Morocco: 265.99,
    "Taiwan Province of China": "n/a",
    "Saudi Arabia": 446.87,
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: 98.27,
    "Guinea-Bissau": "n/a",
    Haiti: "n/a",
    Zambia: "n/a",
    Guyana: "n/a",
    "Central African Republic": "n/a",
    "Kyrgyz Republic": "n/a",
    Ghana: 1.17,
    Belgium: 276.44,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 242.9,
    Peru: "n/a",
    Honduras: 39.91,
    Greece: 104.41,
    Croatia: "n/a",
    Austria: 123.78,
    Djibouti: "n/a",
    Nicaragua: "n/a",
    Switzerland: 215.9,
    Bolivia: "n/a",
    Ireland: 42.06,
    Bhutan: 4.09,
    Qatar: 16.34,
    "Cabo Verde": "n/a",
    Portugal: 56.15,
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": 37.44,
    Finland: 56.46,
    "Hong Kong SAR": "n/a",
    Comoros: 113.59,
    Suriname: 0.06,
    Namibia: 3.18,
    Tunisia: 14.52,
    Moldova: 6.82,
    "Trinidad and Tobago": 16.53,
    Maldives: "n/a",
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: 6.73,
    "Slovak Republic": 6.75,
    "Puerto Rico": "n/a",
    Turkmenistan: "n/a",
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": 3.79,
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: 0.91,
    Panama: 6.77,
    Slovenia: 2.62,
    Georgia: "n/a",
    Azerbaijan: 0.46,
    Bulgaria: "n/a",
    "El Salvador": 2.74,
    Jordan: 5.83,
    Kuwait: 5.56,
    Bahrain: 0.36,
    Lithuania: "n/a",
    Cyprus: 3.9,
    Luxembourg: 1.6,
    Seychelles: 3.67,
    Lesotho: 3.03,
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: 1.43,
    Latvia: "n/a",
    Barbados: 2.82,
    "The Bahamas": 1.2,
    Malta: 1.48,
    Fiji: 1.58,
    Estonia: 0.27,
    "São Tomé and Príncipe": "n/a",
    Aruba: 1.35,
    "St. Lucia": 0.51,
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": 1.57,
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": 0.39,
    Liberia: "n/a",
    "St. Kitts and Nevis": 0.41,
    Grenada: 0.44,
    Dominica: 0.43,
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: 0.01,
    Micronesia: 0.1,
    "Marshall Islands": 0.01,
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1997": {
    Afghanistan: "n/a",
    Argentina: 103.72,
    Ethiopia: 44.81,
    Lebanon: "n/a",
    Syria: 1100.63,
    Venezuela: "n/a",
    "Islamic Republic of Iran": 62139.12,
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: 54334.56,
    Japan: 570564.6,
    Colombia: 36737.57,
    Uzbekistan: 187.76,
    India: 10663.67,
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: 992.4,
    Paraguay: 3898.58,
    China: 1636.36,
    Guinea: 3874.31,
    Chile: 4593.96,
    Myanmar: "n/a",
    Uganda: 4023.95,
    Tanzania: "n/a",
    Cambodia: 3203.51,
    Sudan: 27.06,
    Pakistan: 2093.45,
    "Sierra Leone": "n/a",
    Hungary: 5468.39,
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: 3895.11,
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": 896.65,
    "Sri Lanka": 764.07,
    Russia: 1293.12,
    "CÙte d'Ivoire": 7961.37,
    Algeria: 2400.6,
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: 1621.47,
    Philippines: 1577.79,
    Rwanda: 468.23,
    Kenya: "n/a",
    Senegal: 2323.5,
    Thailand: 1901.36,
    Malawi: "n/a",
    Cameroon: "n/a",
    Brazil: "n/a",
    Egypt: "n/a",
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: 1888.53,
    Benin: "n/a",
    Niger: 920.43,
    Armenia: 374.04,
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": 0,
    "Dominican Republic": 38.82,
    "Czech Republic": 240.34,
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: 27.9,
    France: 794.1,
    Chad: "n/a",
    "United Kingdom": 411.8,
    Italy: 1275.68,
    "Equatorial Guinea": 141.92,
    Canada: 864.05,
    Germany: 1154.46,
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 279.35,
    Sweden: 1380.46,
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 293.97,
    Spain: 333.63,
    Australia: 148.47,
    Poland: 221.22,
    Albania: 239.2,
    Malaysia: 90.91,
    Israel: "n/a",
    Denmark: 736.71,
    Morocco: 274.29,
    "Taiwan Province of China": 2174.76,
    "Saudi Arabia": 476.74,
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: 106.09,
    "Guinea-Bissau": 384.37,
    Haiti: 24.42,
    Zambia: "n/a",
    Guyana: 228.96,
    "Central African Republic": 544.17,
    "Kyrgyz Republic": "n/a",
    Ghana: 1.57,
    Belgium: 278.49,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 239.34,
    Peru: "n/a",
    Honduras: 53.45,
    Greece: 114.08,
    Croatia: "n/a",
    Austria: 119,
    Djibouti: "n/a",
    Nicaragua: 35.84,
    Switzerland: 229.65,
    Bolivia: "n/a",
    Ireland: 42.74,
    Bhutan: 4.28,
    Qatar: 19.75,
    "Cabo Verde": 45.02,
    Portugal: 56.49,
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": 35.98,
    Finland: 57.86,
    "Hong Kong SAR": "n/a",
    Comoros: 112.7,
    Suriname: 0.09,
    Namibia: 3.36,
    Tunisia: 16.04,
    Moldova: 7.94,
    "Trinidad and Tobago": 16.83,
    Maldives: 2.93,
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: 7.25,
    "Slovak Republic": 8.07,
    "Puerto Rico": "n/a",
    Turkmenistan: 1.13,
    Tajikistan: "n/a",
    Botswana: "n/a",
    "Papua New Guinea": 4.59,
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: 1.14,
    Panama: 6.77,
    Slovenia: 3.05,
    Georgia: "n/a",
    Azerbaijan: 0.39,
    Bulgaria: "n/a",
    "El Salvador": 2.84,
    Jordan: 5.71,
    Kuwait: 4.8,
    Bahrain: 0.42,
    Lithuania: "n/a",
    Cyprus: 4.48,
    Luxembourg: 1.64,
    Seychelles: 4.05,
    Lesotho: 3.14,
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": "n/a",
    Oman: 1.45,
    Latvia: "n/a",
    Barbados: 3.04,
    "The Bahamas": 1.32,
    Malta: 1.89,
    Fiji: 1.77,
    Estonia: 0.28,
    "São Tomé and Príncipe": "n/a",
    Aruba: 1.4,
    "St. Lucia": 0.59,
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": 1.6,
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": 0.39,
    Liberia: "n/a",
    "St. Kitts and Nevis": 0.63,
    Grenada: 0.44,
    Dominica: 0.4,
    "San Marino": "n/a",
    Samoa: "n/a",
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: 0.01,
    Micronesia: 0.1,
    "Marshall Islands": 0.01,
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1998": {
    Afghanistan: "n/a",
    Argentina: 114.13,
    Ethiopia: 47.87,
    Lebanon: "n/a",
    Syria: 1195.35,
    Venezuela: "--",
    "Islamic Republic of Iran": 79462.21,
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: 76932.25,
    Japan: 622444,
    Colombia: 46082.57,
    Uzbekistan: 319.56,
    India: 12279.18,
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: 1021.75,
    Paraguay: 5589.63,
    China: 1752.15,
    Guinea: 4698.53,
    Chile: 4572.51,
    Myanmar: 3145.95,
    Uganda: 4603.18,
    Tanzania: "n/a",
    Cambodia: 4358,
    Sudan: 39.41,
    Pakistan: 2349.37,
    "Sierra Leone": "n/a",
    Hungary: 6257.78,
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: 5195.83,
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": 1432.1,
    "Sri Lanka": 924.7,
    Russia: 3809.22,
    "CÙte d'Ivoire": 7730.1,
    Algeria: 2454.14,
    Bangladesh: "n/a",
    Yemen: "n/a",
    Mexico: 2022.31,
    Philippines: 1546.67,
    Rwanda: 503.58,
    Kenya: 517.14,
    Senegal: 707.68,
    Thailand: 2327.84,
    Malawi: "n/a",
    Cameroon: 4532.08,
    Brazil: "n/a",
    Egypt: 223.09,
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: 2466.99,
    Benin: 775.92,
    Niger: 952.8,
    Armenia: 431.73,
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": 0,
    "Dominican Republic": 42.22,
    "Czech Republic": 300.98,
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: 49.38,
    France: 829.4,
    Chad: "n/a",
    "United Kingdom": 408.17,
    Italy: 1299.74,
    "Equatorial Guinea": 153.91,
    Canada: 877.44,
    Germany: 1199.28,
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 303.4,
    Sweden: 1402.2,
    Jamaica: "n/a",
    Mozambique: "n/a",
    Norway: 274.33,
    Spain: 346.42,
    Australia: 143.64,
    Poland: 233.69,
    Albania: 273.95,
    Malaysia: 103.77,
    Israel: "n/a",
    Denmark: 714.91,
    Morocco: 280.36,
    "Taiwan Province of China": 2218.57,
    "Saudi Arabia": 558.79,
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: 119.68,
    "Guinea-Bissau": 380.42,
    Haiti: 26.07,
    Zambia: "n/a",
    Guyana: 243.99,
    "Central African Republic": 517.39,
    "Kyrgyz Republic": "n/a",
    Ghana: 1.61,
    Belgium: 277.26,
    "United Arab Emirates": "n/a",
    Mauritius: "n/a",
    "North Macedonia": "n/a",
    Netherlands: 243.14,
    Peru: "n/a",
    Honduras: 58.12,
    Greece: 122.04,
    Croatia: 35.39,
    Austria: 135.12,
    Djibouti: "n/a",
    Nicaragua: 42.41,
    Switzerland: 245.93,
    Bolivia: "n/a",
    Ireland: 41.35,
    Bhutan: 5.56,
    Qatar: 23.66,
    "Cabo Verde": 46.37,
    Portugal: 57.73,
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": 36.5,
    Finland: 56.41,
    "Hong Kong SAR": "n/a",
    Comoros: 111.81,
    Suriname: 0.14,
    Namibia: 4.15,
    Tunisia: 15.16,
    Moldova: 14.54,
    "Trinidad and Tobago": 16.56,
    Maldives: 3.2,
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: 9.38,
    "Slovak Republic": 9.05,
    "Puerto Rico": "n/a",
    Turkmenistan: 1.8,
    Tajikistan: 0.99,
    Botswana: 2.42,
    "Papua New Guinea": 5.27,
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: 1.54,
    Panama: 7.09,
    Slovenia: 3.49,
    Georgia: "n/a",
    Azerbaijan: 0.47,
    Bulgaria: 17.81,
    "El Salvador": 2.81,
    Jordan: 6.41,
    Kuwait: 4.36,
    Bahrain: 0.55,
    Lithuania: 2.84,
    Cyprus: 4.98,
    Luxembourg: 1.65,
    Seychelles: 5.16,
    Lesotho: 4.2,
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": 5.02,
    Oman: 1.91,
    Latvia: 0.58,
    Barbados: 3.09,
    "The Bahamas": 1.37,
    Malta: 2.2,
    Fiji: 1.31,
    Estonia: 0.28,
    "São Tomé and Príncipe": "n/a",
    Aruba: 1.22,
    "St. Lucia": 0.7,
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": 2.02,
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": 0.43,
    Liberia: "n/a",
    "St. Kitts and Nevis": 0.77,
    Grenada: 0.49,
    Dominica: 0.5,
    "San Marino": "n/a",
    Samoa: 0.46,
    Andorra: "n/a",
    Tonga: "n/a",
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: 0.01,
    Micronesia: 0.09,
    "Marshall Islands": 0.02,
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "1999": {
    Afghanistan: "n/a",
    Argentina: 123.37,
    Ethiopia: 56.37,
    Lebanon: "n/a",
    Syria: 1209.78,
    Venezuela: "--",
    "Islamic Republic of Iran": 77472.61,
    Indonesia: "n/a",
    Vietnam: "n/a",
    Korea: 96614.46,
    Japan: 683856.8,
    Colombia: 61541.28,
    Uzbekistan: 490.52,
    India: 14169.69,
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: 3446.48,
    Paraguay: 8824.49,
    China: 1969.75,
    Guinea: 6140.73,
    Chile: 5106.57,
    Myanmar: 3164.14,
    Uganda: 5430.55,
    Tanzania: "n/a",
    Cambodia: 4646.44,
    Sudan: 43.43,
    Pakistan: 2912.04,
    "Sierra Leone": "n/a",
    Hungary: 6962.75,
    Angola: "n/a",
    Mongolia: "n/a",
    Madagascar: 5597.49,
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": 1587.55,
    "Sri Lanka": 1051.33,
    Russia: 4774,
    "CÙte d'Ivoire": 8217.93,
    Algeria: 2456.24,
    Bangladesh: "n/a",
    Yemen: 1142.86,
    Mexico: 2514.61,
    Philippines: 1814.99,
    Rwanda: 564.24,
    Kenya: 541.75,
    Senegal: 608.22,
    Thailand: 2607.06,
    Malawi: "n/a",
    Cameroon: 4866.9,
    Brazil: "n/a",
    Egypt: 234.16,
    "Democratic Republic of the Congo": "n/a",
    "Burkina Faso": "n/a",
    Mali: "n/a",
    "South Africa": "n/a",
    Gabon: 2234.49,
    Benin: 891.96,
    Niger: 986.22,
    Armenia: 386.99,
    Burundi: "n/a",
    Turkey: "n/a",
    "Republic of Congo": 0,
    "Dominican Republic": 56,
    "Czech Republic": 342.08,
    Serbia: "n/a",
    Nepal: "n/a",
    Togo: "n/a",
    Ukraine: 79.54,
    France: 847.6,
    Chad: 619.84,
    "United Kingdom": 409.99,
    Italy: 1331.32,
    "Equatorial Guinea": 274.81,
    Canada: 896.71,
    Germany: 1243.67,
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 497.64,
    Sweden: 1360.22,
    Jamaica: 297.09,
    Mozambique: 76.02,
    Norway: 316.1,
    Spain: 362.22,
    Australia: 143.92,
    Poland: 263.39,
    Albania: 302.45,
    Malaysia: 112.56,
    Israel: "n/a",
    Denmark: 704.74,
    Morocco: 279.9,
    "Taiwan Province of China": 2322.7,
    "Saudi Arabia": 624.58,
    Romania: "n/a",
    "South Sudan": "n/a",
    Singapore: 128.5,
    "Guinea-Bissau": 392.02,
    Haiti: 28.82,
    Zambia: "n/a",
    Guyana: 257.18,
    "Central African Republic": 537.51,
    "Kyrgyz Republic": "n/a",
    Ghana: 2.64,
    Belgium: 279.53,
    "United Arab Emirates": 14.65,
    Mauritius: "n/a",
    "North Macedonia": 66.96,
    Netherlands: 241.32,
    Peru: "n/a",
    Honduras: 69.73,
    Greece: 132.33,
    Croatia: 46.78,
    Austria: 124.56,
    Djibouti: "n/a",
    Nicaragua: 57.25,
    Switzerland: 235.51,
    Bolivia: "n/a",
    Ireland: 43.21,
    Bhutan: 6.98,
    Qatar: 33.56,
    "Cabo Verde": 47.23,
    Portugal: 61.08,
    Guatemala: "n/a",
    Mauritania: "n/a",
    "New Zealand": 35.59,
    Finland: 55.86,
    "Hong Kong SAR": "n/a",
    Comoros: 110.86,
    Suriname: 0.35,
    Namibia: 5.1,
    Tunisia: 17.68,
    Moldova: 18.57,
    "Trinidad and Tobago": 18.39,
    Maldives: 3.47,
    "The Gambia": "n/a",
    Belarus: "n/a",
    Vanuatu: 10.59,
    "Slovak Republic": 13.45,
    "Puerto Rico": "n/a",
    Turkmenistan: 2.11,
    Tajikistan: 1.45,
    Botswana: 2.42,
    "Papua New Guinea": 5.45,
    Ecuador: "n/a",
    Eritrea: "n/a",
    Eswatini: 1.8,
    Panama: 7.08,
    Slovenia: 4.09,
    Georgia: "n/a",
    Azerbaijan: 0.96,
    Bulgaria: 19.7,
    "El Salvador": 3.16,
    Jordan: 6.5,
    Kuwait: 4.27,
    Bahrain: 0.73,
    Lithuania: 3.57,
    Cyprus: 5.42,
    Luxembourg: 1.66,
    Seychelles: 5.32,
    Lesotho: 4.76,
    "West Bank and Gaza": "n/a",
    "Bosnia and Herzegovina": 5.92,
    Oman: 1.98,
    Latvia: 0.91,
    Barbados: 3.22,
    "The Bahamas": 1.43,
    Malta: 2.48,
    Fiji: 1.36,
    Estonia: 0.32,
    "São Tomé and Príncipe": "n/a",
    Aruba: 1.21,
    "St. Lucia": 0.72,
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": 2.16,
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": 0.6,
    Liberia: "n/a",
    "St. Kitts and Nevis": 0.94,
    Grenada: 0.45,
    Dominica: 0.56,
    "San Marino": "n/a",
    Samoa: 0.47,
    Andorra: "n/a",
    Tonga: 0.12,
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: 0.01,
    Micronesia: 0.08,
    "Marshall Islands": 0.03,
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "2000": {
    Afghanistan: "n/a",
    Argentina: 129.75,
    Ethiopia: 62.85,
    Lebanon: 37989.09,
    Syria: 1440.53,
    Venezuela: "--",
    "Islamic Republic of Iran": 70866.27,
    Indonesia: 1321664.26,
    Vietnam: 138816.71,
    Korea: 108705.57,
    Japan: 726072.6,
    Colombia: 78704.17,
    Uzbekistan: 1203.04,
    India: 16036.45,
    Iraq: "n/a",
    "Lao P.D.R.": "n/a",
    Nigeria: 3972.98,
    Paraguay: 10367.51,
    China: 2294.05,
    Guinea: 6455.03,
    Chile: 5549.44,
    Myanmar: 4310.03,
    Uganda: 6191.76,
    Tanzania: "n/a",
    Cambodia: 4961.96,
    Sudan: 48.38,
    Pakistan: 3174.38,
    "Sierra Leone": "n/a",
    Hungary: 7339.55,
    Angola: 150.14,
    Mongolia: "n/a",
    Madagascar: 5652.36,
    Kazakhstan: "n/a",
    "United States": "n/a",
    "Costa Rica": 1797.62,
    "Sri Lanka": 1218.7,
    Russia: 4373.08,
    "CÙte d'Ivoire": 7803.5,
    Algeria: 2390,
    Bangladesh: "n/a",
    Yemen: 948.12,
    Mexico: 2698.24,
    Philippines: 2189.05,
    Rwanda: 693.09,
    Kenya: 562.44,
    Senegal: 2456.2,
    Thailand: 2804.28,
    Malawi: "n/a",
    Cameroon: 5694.49,
    Brazil: 786.14,
    Egypt: 256.36,
    "Democratic Republic of the Congo": 1777.82,
    "Burkina Faso": "n/a",
    Mali: 1903.92,
    "South Africa": 399.45,
    Gabon: 2785.53,
    Benin: 989.77,
    Niger: 1302.93,
    Armenia: 408.28,
    Burundi: 855.74,
    Turkey: 88.01,
    "Republic of Congo": 3741.9,
    "Dominican Republic": 65.71,
    "Czech Republic": 405.42,
    Serbia: 928.49,
    Nepal: 236.01,
    Togo: "n/a",
    Ukraine: 77.02,
    France: 870.6,
    Chad: 758.69,
    "United Kingdom": 402.57,
    Italy: 1353.57,
    "Equatorial Guinea": 301.39,
    Canada: 889.69,
    Germany: 1251.53,
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 537.82,
    Sweden: 1209.6,
    Jamaica: 362.65,
    Mozambique: 86.53,
    Norway: 432.64,
    Spain: 374.56,
    Australia: 134.02,
    Poland: 272.32,
    Albania: 319.61,
    Malaysia: 126.22,
    Israel: 429.41,
    Denmark: 694.7,
    Morocco: 289.82,
    "Taiwan Province of China": 2708.9,
    "Saudi Arabia": 616.17,
    Romania: 23.91,
    "South Sudan": "n/a",
    Singapore: 138.87,
    "Guinea-Bissau": 603.51,
    Haiti: 43.39,
    Zambia: 29.23,
    Guyana: 265.49,
    "Central African Republic": 583.24,
    "Kyrgyz Republic": 80.59,
    Ghana: 5.01,
    Belgium: 280.96,
    "United Arab Emirates": 11.8,
    Mauritius: 77.37,
    "North Macedonia": 113.29,
    Netherlands: 230.08,
    Peru: 78.98,
    Honduras: 70.13,
    Greece: 148.22,
    Croatia: 59.86,
    Austria: 140.42,
    Djibouti: "n/a",
    Nicaragua: 61.71,
    Switzerland: 246.35,
    Bolivia: 34.74,
    Ireland: 39.49,
    Bhutan: 8.73,
    Qatar: 33.97,
    "Cabo Verde": 58.29,
    Portugal: 64.64,
    Guatemala: 25.33,
    Mauritania: 52.77,
    "New Zealand": 35.53,
    Finland: 57.89,
    "Hong Kong SAR": "n/a",
    Comoros: 109.9,
    Suriname: 0.64,
    Namibia: 5.7,
    Tunisia: 19.41,
    Moldova: 14.56,
    "Trinidad and Tobago": 20.33,
    Maldives: 3.68,
    "The Gambia": 9.46,
    Belarus: "n/a",
    Vanuatu: 13.52,
    "Slovak Republic": 15.97,
    "Puerto Rico": "n/a",
    Turkmenistan: 2.27,
    Tajikistan: 2.01,
    Botswana: 2.43,
    "Papua New Guinea": 6.09,
    Ecuador: "n/a",
    Eritrea: 11.53,
    Eswatini: 2.19,
    Panama: 6.98,
    Slovenia: 4.89,
    Georgia: 3.64,
    Azerbaijan: 1.08,
    Bulgaria: 20.63,
    "El Salvador": 3.48,
    Jordan: 6.14,
    Kuwait: 4.04,
    Bahrain: 0.88,
    Lithuania: 3.14,
    Cyprus: 5.93,
    Luxembourg: 1.72,
    Seychelles: 6.25,
    Lesotho: 5.6,
    "West Bank and Gaza": 3.52,
    "Bosnia and Herzegovina": 4.09,
    Oman: 1.86,
    Latvia: 1.01,
    Barbados: 3.75,
    "The Bahamas": 1.51,
    Malta: 2.65,
    Fiji: 1.43,
    Estonia: 0.32,
    "São Tomé and Príncipe": "n/a",
    Aruba: 1.3,
    "St. Lucia": 0.82,
    "Solomon Islands": "n/a",
    Belize: "n/a",
    Montenegro: "n/a",
    "Antigua and Barbuda": 2.35,
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": 0.63,
    Liberia: 4.23,
    "St. Kitts and Nevis": 1.1,
    Grenada: 0.58,
    Dominica: 0.63,
    "San Marino": "n/a",
    Samoa: 0.47,
    Andorra: "n/a",
    Tonga: 0.15,
    "Timor-Leste": "n/a",
    "Brunei Darussalam": 0,
    Kiribati: 0.01,
    Micronesia: 0.06,
    "Marshall Islands": 0.03,
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": "n/a"
  },
  "2001": {
    Afghanistan: "n/a",
    Argentina: 144.22,
    Ethiopia: 66.72,
    Lebanon: 42680.58,
    Syria: 1459.57,
    Venezuela: "--",
    "Islamic Republic of Iran": 125828.38,
    Indonesia: 1319703.22,
    Vietnam: 155345.74,
    Korea: 121815.72,
    Japan: 771554.9,
    Colombia: 92214.27,
    Uzbekistan: 2768.42,
    India: 18547.49,
    Iraq: "n/a",
    "Lao P.D.R.": 15792.24,
    Nigeria: 4318.95,
    Paraguay: 14072.26,
    China: 2712.75,
    Guinea: 6714.09,
    Chile: 6515.33,
    Myanmar: 8831.09,
    Uganda: 7063.74,
    Tanzania: 5610.75,
    Cambodia: 5451.12,
    Sudan: 51.06,
    Pakistan: 3684.66,
    "Sierra Leone": 3913.62,
    Hungary: 7953.81,
    Angola: 273.65,
    Mongolia: "n/a",
    Madagascar: 5886.49,
    Kazakhstan: "n/a",
    "United States": 5623.86,
    "Costa Rica": 2082.92,
    "Sri Lanka": 1452.71,
    Russia: 4258.3,
    "CÙte d'Ivoire": 8071.6,
    Algeria: 2293.94,
    Bangladesh: "n/a",
    Yemen: 1006.71,
    Mexico: 2775.16,
    Philippines: 2393.35,
    Rwanda: 731.83,
    Kenya: 642.03,
    Senegal: 2536.1,
    Thailand: 2931.72,
    Malawi: "n/a",
    Cameroon: 4988.41,
    Brazil: 921.74,
    Egypt: 298.51,
    "Democratic Republic of the Congo": 4052.7,
    "Burkina Faso": "n/a",
    Mali: 1968.6,
    "South Africa": 443.58,
    Gabon: 2979.26,
    Benin: 1019.99,
    Niger: 1322.94,
    Armenia: 447.45,
    Burundi: 927.33,
    Turkey: 186.71,
    "Republic of Congo": 4010.25,
    "Dominican Republic": 86.97,
    "Czech Republic": 585.62,
    Serbia: 872.6,
    Nepal: 255.67,
    Togo: "n/a",
    Ukraine: 74.63,
    France: 897.4,
    Chad: 800.44,
    "United Kingdom": 387.04,
    Italy: 1420.03,
    "Equatorial Guinea": 312.34,
    Canada: 932.37,
    Germany: 1264.27,
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 668.96,
    Sweden: 1296.01,
    Jamaica: 467.97,
    Mozambique: 116.71,
    Norway: 426.67,
    Spain: 378.88,
    Australia: 124.96,
    Poland: 291.18,
    Albania: 341.52,
    Malaysia: 146.17,
    Israel: 460.12,
    Denmark: 665.3,
    Morocco: 291.71,
    "Taiwan Province of China": 3048.54,
    "Saudi Arabia": 643.2,
    Romania: 32.18,
    "South Sudan": "n/a",
    Singapore: 151.13,
    "Guinea-Bissau": 617.81,
    Haiti: 43.39,
    Zambia: 31.08,
    Guyana: 294.8,
    "Central African Republic": 679.77,
    "Kyrgyz Republic": 79.95,
    Ghana: 5.43,
    Belgium: 286.06,
    "United Arab Emirates": 10.21,
    Mauritius: 86.03,
    "North Macedonia": 114.12,
    Netherlands: 232.07,
    Peru: 78.47,
    Honduras: 75.1,
    Greece: 162.97,
    Croatia: 67.22,
    Austria: 146.33,
    Djibouti: "n/a",
    Nicaragua: 62.6,
    Switzerland: 247.2,
    Bolivia: 32.25,
    Ireland: 40.97,
    Bhutan: 11.51,
    Qatar: 37.72,
    "Cabo Verde": 60.61,
    Portugal: 72.55,
    Guatemala: 29.62,
    Mauritania: 55.9,
    "New Zealand": 35.57,
    Finland: 59.14,
    "Hong Kong SAR": 0,
    Comoros: 108.56,
    Suriname: 0.95,
    Namibia: 7.51,
    Tunisia: 17.37,
    Moldova: 16.27,
    "Trinidad and Tobago": 20.45,
    Maldives: 4.11,
    "The Gambia": 11.83,
    Belarus: "n/a",
    Vanuatu: 13.88,
    "Slovak Republic": 17.57,
    "Puerto Rico": "n/a",
    Turkmenistan: 1.94,
    Tajikistan: 2.5,
    Botswana: 2.43,
    "Papua New Guinea": 7.32,
    Ecuador: 14.76,
    Eritrea: 15.71,
    Eswatini: 2.89,
    Panama: 7.65,
    Slovenia: 5.51,
    Georgia: 3.64,
    Azerbaijan: 1.3,
    Bulgaria: 20.78,
    "El Salvador": 4.48,
    Jordan: 6.2,
    Kuwait: 3.76,
    Bahrain: 0.9,
    Lithuania: 3.25,
    Cyprus: 6.57,
    Luxembourg: 1.82,
    Seychelles: 7.28,
    Lesotho: 7.93,
    "West Bank and Gaza": 3.73,
    "Bosnia and Herzegovina": 4.45,
    Oman: 1.92,
    Latvia: 1.3,
    Barbados: 4.14,
    "The Bahamas": 1.52,
    Malta: 2.99,
    Fiji: 1.68,
    Estonia: 0.33,
    "São Tomé and Príncipe": 2.6,
    Aruba: 1.51,
    "St. Lucia": 0.92,
    "Solomon Islands": "n/a",
    Belize: 1.16,
    Montenegro: "n/a",
    "Antigua and Barbuda": 2.55,
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": 0.66,
    Liberia: 4.39,
    "St. Kitts and Nevis": 1.3,
    Grenada: 0.63,
    Dominica: 0.9,
    "San Marino": "n/a",
    Samoa: 0.5,
    Andorra: 0.27,
    Tonga: 0.19,
    "Timor-Leste": 0,
    "Brunei Darussalam": 0,
    Kiribati: 0.02,
    Micronesia: 0.06,
    "Marshall Islands": 0.04,
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": 0
  },
  "2002": {
    Afghanistan: 618.46,
    Argentina: 514.45,
    Ethiopia: 72.01,
    Lebanon: 46320.63,
    Syria: 1480.68,
    Venezuela: "--",
    "Islamic Republic of Iran": 224706.95,
    Indonesia: 1235243.74,
    Vietnam: 188511.87,
    Korea: 133730,
    Japan: 808198.9,
    Colombia: 115886.18,
    Uzbekistan: 3888.23,
    India: 21013.76,
    Iraq: "n/a",
    "Lao P.D.R.": 18770.01,
    Nigeria: 4902.98,
    Paraguay: 22431.28,
    China: 3144.25,
    Guinea: 6565.42,
    Chile: 7286.3,
    Myanmar: 9662.08,
    Uganda: 8285.88,
    Tanzania: 6008.42,
    Cambodia: 6669.98,
    Sudan: 58.13,
    Pakistan: 3650.1,
    "Sierra Leone": 4178.95,
    Hungary: 9574.5,
    Angola: 490.63,
    Mongolia: "n/a",
    Madagascar: 6339.42,
    Kazakhstan: 664.32,
    "United States": 6069.72,
    "Costa Rica": 2471.93,
    "Sri Lanka": 1863.85,
    Russia: 4360.63,
    "CÙte d'Ivoire": 7491.94,
    Algeria: 2319.13,
    Bangladesh: "n/a",
    Yemen: 1086,
    Mexico: 3120.62,
    Philippines: 2835.87,
    Rwanda: 860.45,
    Kenya: 719.02,
    Senegal: 2527.08,
    Thailand: 2934.94,
    Malawi: 383.12,
    Cameroon: 4875.54,
    Brazil: 1173.13,
    Egypt: 341.74,
    "Democratic Republic of the Congo": 4116.47,
    "Burkina Faso": 1087.75,
    Mali: 1156.1,
    "South Africa": 432.71,
    Gabon: 3000.62,
    Benin: 895.25,
    Niger: 1328.61,
    Armenia: 521.11,
    Burundi: 1222.03,
    Turkey: 258.99,
    "Republic of Congo": 3794.91,
    "Dominican Republic": 102.58,
    "Czech Republic": 695.13,
    Serbia: 790.05,
    Nepal: 271.55,
    Togo: "n/a",
    Ukraine: 75.73,
    France: 956.8,
    Chad: 853.36,
    "United Kingdom": 406.36,
    Italy: 1436.14,
    "Equatorial Guinea": 279.4,
    Canada: 950.23,
    Germany: 1317.68,
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 701.76,
    Sweden: 1293.12,
    Jamaica: 575.2,
    Mozambique: 89.26,
    Norway: 530.95,
    Spain: 384.15,
    Australia: 117.44,
    Poland: 338.67,
    Albania: 391.31,
    Malaysia: 164.99,
    Israel: 517.39,
    Denmark: 692.18,
    Morocco: 299.09,
    "Taiwan Province of China": 3165.76,
    "Saudi Arabia": 685.08,
    Romania: 41.7,
    "South Sudan": "n/a",
    Singapore: 161.1,
    "Guinea-Bissau": 639.43,
    Haiti: 52.42,
    Zambia: 33.25,
    Guyana: 311.6,
    "Central African Republic": 674.81,
    "Kyrgyz Republic": 81.35,
    Ghana: 6.52,
    Belgium: 288.11,
    "United Arab Emirates": 13.82,
    Mauritius: 102.38,
    "North Macedonia": 104.63,
    Netherlands: 238.21,
    Peru: 86.26,
    Honduras: 82.99,
    Greece: 171.41,
    Croatia: 77.68,
    Austria: 151.86,
    Djibouti: "n/a",
    Nicaragua: 82.18,
    Switzerland: 278.41,
    Bolivia: 39.19,
    Ireland: 41.99,
    Bhutan: 14.6,
    Qatar: 33.55,
    "Cabo Verde": 65.71,
    Portugal: 80.13,
    Guatemala: 29.77,
    Mauritania: 53.58,
    "New Zealand": 35.29,
    Finland: 59.57,
    "Hong Kong SAR": 0,
    Comoros: 107.22,
    Suriname: 1.23,
    Namibia: 7.92,
    Tunisia: 17.82,
    Moldova: 18.12,
    "Trinidad and Tobago": 20.36,
    Maldives: 4.69,
    "The Gambia": 16.58,
    Belarus: "n/a",
    Vanuatu: 14.19,
    "Slovak Republic": 16.91,
    "Puerto Rico": "n/a",
    Turkmenistan: 1.73,
    Tajikistan: 3.29,
    Botswana: 2.92,
    "Papua New Guinea": 8.28,
    Ecuador: 14.89,
    Eritrea: 19.18,
    Eswatini: 2.59,
    Panama: 7.83,
    Slovenia: 6.44,
    Georgia: 3.85,
    Azerbaijan: 1.4,
    Bulgaria: 18.19,
    "El Salvador": 5.36,
    Jordan: 6.71,
    Kuwait: 3.47,
    Bahrain: 1.02,
    Lithuania: 3.37,
    Cyprus: 7.25,
    Luxembourg: 1.86,
    Seychelles: 7.49,
    Lesotho: 6.56,
    "West Bank and Gaza": 3.52,
    "Bosnia and Herzegovina": 4.35,
    Oman: 1.41,
    Latvia: 1.25,
    Barbados: 4.45,
    "The Bahamas": 1.67,
    Malta: 2.93,
    Fiji: 1.89,
    Estonia: 0.44,
    "São Tomé and Príncipe": 2.63,
    Aruba: 1.65,
    "St. Lucia": 1.19,
    "Solomon Islands": "n/a",
    Belize: 1.32,
    Montenegro: 1.03,
    "Antigua and Barbuda": 2.78,
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": 0.69,
    Liberia: 4.55,
    "St. Kitts and Nevis": 1.55,
    Grenada: 1.15,
    Dominica: 0.89,
    "San Marino": "n/a",
    Samoa: 0.51,
    Andorra: 0.29,
    Tonga: 0.24,
    "Timor-Leste": 0,
    "Brunei Darussalam": 0,
    Kiribati: 0.02,
    Micronesia: 0.06,
    "Marshall Islands": 0.05,
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": 0
  },
  "2003": {
    Afghanistan: 595.36,
    Argentina: 526.05,
    Ethiopia: 76.72,
    Lebanon: 50321.89,
    Syria: 1432.62,
    Venezuela: 0,
    "Islamic Republic of Iran": 273436.57,
    Indonesia: 1218649.9,
    Vietnam: 232387.38,
    Korea: 165825,
    Japan: 838458.7,
    Colombia: 121870.52,
    Uzbekistan: 3873.85,
    India: 23983.06,
    Iraq: "n/a",
    "Lao P.D.R.": 21320.98,
    Nigeria: 5597.98,
    Paraguay: 19922.73,
    China: 3675.58,
    Guinea: 7705.62,
    Chile: 6650.79,
    Myanmar: 10763.77,
    Uganda: 9446.9,
    Tanzania: 6524.41,
    Cambodia: 7988.55,
    Sudan: 65.41,
    Pakistan: 3707.17,
    "Sierra Leone": 5218.14,
    Hungary: 10982.26,
    Angola: 770.36,
    Mongolia: "n/a",
    Madagascar: 6775.57,
    Kazakhstan: 690.07,
    "United States": 6716.27,
    "Costa Rica": 2793.18,
    "Sri Lanka": 1863.85,
    Russia: 4009.89,
    "CÙte d'Ivoire": 6933.19,
    Algeria: 2173.87,
    Bangladesh: 1545.67,
    Yemen: 1227.72,
    Mexico: 3479.97,
    Philippines: 3368.19,
    Rwanda: 914.65,
    Kenya: 766.95,
    Senegal: 2180.6,
    Thailand: 2930.04,
    Malawi: 373.07,
    Cameroon: 4771.4,
    Brazil: 1268.24,
    Egypt: 426.24,
    "Democratic Republic of the Congo": 4147.32,
    "Burkina Faso": 1090.69,
    Mali: 1206.91,
    "South Africa": 469.69,
    Gabon: 2652.47,
    Benin: 725.28,
    Niger: 1188.94,
    Armenia: 536.39,
    Burundi: 1460.68,
    Turkey: 307.71,
    "Republic of Congo": 4152.43,
    "Dominican Republic": 302.68,
    "Czech Republic": 795.04,
    Serbia: 875.57,
    Nepal: 297.43,
    Togo: "n/a",
    Ukraine: 78.49,
    France: 1050.4,
    Chad: 803.63,
    "United Kingdom": 444.67,
    Italy: 1471.33,
    "Equatorial Guinea": 146.67,
    Canada: 952.5,
    Germany: 1405.2,
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 745.82,
    Sweden: 1332.06,
    Jamaica: 701.25,
    Mozambique: 98.89,
    Norway: 699.62,
    Spain: 382.78,
    Australia: 109.47,
    Poland: 394.46,
    Albania: 408.3,
    Malaysia: 188.79,
    Israel: 536.42,
    Denmark: 663.06,
    Morocco: 307.11,
    "Taiwan Province of China": 3512.07,
    "Saudi Arabia": 660.08,
    Romania: 47.75,
    "South Sudan": "n/a",
    Singapore: 173.11,
    "Guinea-Bissau": 588.74,
    Haiti: 73.8,
    Zambia: 37,
    Guyana: 295.14,
    "Central African Republic": 662.25,
    "Kyrgyz Republic": 90.77,
    Ghana: 8.03,
    Belgium: 285.87,
    "United Arab Emirates": 15.22,
    Mauritius: 121.57,
    "North Macedonia": 97.97,
    Netherlands: 249.82,
    Peru: 100.54,
    Honduras: 96.77,
    Greece: 181.51,
    Croatia: 88.8,
    Austria: 150.47,
    Djibouti: "n/a",
    Nicaragua: 88.06,
    Switzerland: 277.37,
    Bolivia: 45.85,
    Ireland: 43.41,
    Bhutan: 19.71,
    Qatar: 33.48,
    "Cabo Verde": 70.86,
    Portugal: 85.73,
    Guatemala: 36.3,
    Mauritania: 53.29,
    "New Zealand": 34.98,
    Finland: 64.78,
    "Hong Kong SAR": 0,
    Comoros: 105.83,
    Suriname: 1.41,
    Namibia: 10.21,
    Tunisia: 19.5,
    Moldova: 18.8,
    "Trinidad and Tobago": 20.96,
    Maldives: 5.06,
    "The Gambia": 22.05,
    Belarus: "n/a",
    Vanuatu: 14.7,
    "Slovak Republic": 17.94,
    "Puerto Rico": "n/a",
    Turkmenistan: 1.59,
    Tajikistan: 3.32,
    Botswana: 2.7,
    "Papua New Guinea": 7.91,
    Ecuador: 14.62,
    Eritrea: 24.67,
    Eswatini: 2.91,
    Panama: 8.18,
    Slovenia: 6.86,
    Georgia: 4.8,
    Azerbaijan: 1.56,
    Bulgaria: 16.64,
    "El Salvador": 6.09,
    Jordan: 6.66,
    Kuwait: 3.28,
    Bahrain: 1.35,
    Lithuania: 3.4,
    Cyprus: 8.09,
    Luxembourg: 1.95,
    Seychelles: 6.75,
    Lesotho: 4.69,
    "West Bank and Gaza": 3.73,
    "Bosnia and Herzegovina": 4.06,
    Oman: 1.24,
    Latvia: 1.37,
    Barbados: 4.63,
    "The Bahamas": 1.85,
    Malta: 3.27,
    Fiji: 2.13,
    Estonia: 0.49,
    "São Tomé and Príncipe": 2.91,
    Aruba: 1.5,
    "St. Lucia": 1.22,
    "Solomon Islands": 1.67,
    Belize: 1.76,
    Montenegro: 0.61,
    "Antigua and Barbuda": 2.89,
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": 0.7,
    Liberia: 4.71,
    "St. Kitts and Nevis": 1.78,
    Grenada: 1.27,
    Dominica: 0.91,
    "San Marino": "n/a",
    Samoa: 0.46,
    Andorra: 0.32,
    Tonga: 0.25,
    "Timor-Leste": 0,
    "Brunei Darussalam": 0,
    Kiribati: 0.02,
    Micronesia: 0.06,
    "Marshall Islands": 0.06,
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": 0
  },
  "2004": {
    Afghanistan: 603.13,
    Argentina: 571.84,
    Ethiopia: 90.13,
    Lebanon: 54082,
    Syria: 1432.19,
    Venezuela: 0,
    "Islamic Republic of Iran": 336906.9,
    Indonesia: 1281670.37,
    Vietnam: 290556.45,
    Korea: 203687,
    Japan: 897281.2,
    Colombia: 126841.32,
    Uzbekistan: 4130.98,
    India: 27048.87,
    Iraq: 183298.61,
    "Lao P.D.R.": 22630.73,
    Nigeria: 6146.98,
    Paraguay: 17401.81,
    China: 4258.7,
    Guinea: 9769.28,
    Chile: 6228.85,
    Myanmar: 11746.33,
    Uganda: 9529.02,
    Tanzania: 7553.93,
    Cambodia: 9154.9,
    Sudan: 67.21,
    Pakistan: 3852.21,
    "Sierra Leone": 5932.69,
    Hungary: 12296.42,
    Angola: 938.87,
    Mongolia: "n/a",
    Madagascar: 7751.64,
    Kazakhstan: 668.7,
    "United States": 8076.01,
    "Costa Rica": 3341.69,
    "Sri Lanka": 2139.53,
    Russia: 3799.83,
    "CÙte d'Ivoire": 6856.42,
    Algeria: 2165.15,
    Bangladesh: 1679.8,
    Yemen: 1334.76,
    Mexico: 3598.17,
    Philippines: 3784.35,
    Rwanda: 1110.54,
    Kenya: 769.38,
    Senegal: 2019.2,
    Thailand: 3126.55,
    Malawi: 397.96,
    Cameroon: 5135.48,
    Brazil: 1371.93,
    Egypt: 492.48,
    "Democratic Republic of the Congo": 5831.44,
    "Burkina Faso": 1171.24,
    Mali: 1220.51,
    "South Africa": 507.85,
    Gabon: 2470.86,
    Benin: 702.15,
    Niger: 1087.95,
    Armenia: 504.57,
    Burundi: 1740.32,
    Turkey: 333.21,
    "Republic of Congo": 3298.91,
    "Dominican Republic": 321.47,
    "Czech Republic": 873.49,
    Serbia: 902.49,
    Nepal: 314.23,
    Togo: "n/a",
    Ukraine: 85.4,
    France: 1123.6,
    Chad: 861.34,
    "United Kingdom": 505.79,
    Italy: 1526.4,
    "Equatorial Guinea": 142.1,
    Canada: 960.25,
    Germany: 1475.18,
    Uruguay: "n/a",
    Zimbabwe: "n/a",
    Iceland: 784.7,
    Sweden: 1371.97,
    Jamaica: 767.45,
    Mozambique: 90.99,
    Norway: 783.72,
    Spain: 389.89,
    Australia: 106.49,
    Poland: 420.95,
    Albania: 423.96,
    Malaysia: 216.64,
    Israel: 553.97,
    Denmark: 664.99,
    Morocco: 311.48,
    "Taiwan Province of China": 3878.45,
    "Saudi Arabia": 610.58,
    Romania: 52.19,
    "South Sudan": "n/a",
    Singapore: 189.76,
    "Guinea-Bissau": 620.02,
    Haiti: 72.94,
    Zambia: 38.62,
    Guyana: 301.47,
    "Central African Republic": 690.06,
    "Kyrgyz Republic": 87.43,
    Ghana: 7.51,
    Belgium: 288.42,
    "United Arab Emirates": 22.15,
    Mauritius: 117.05,
    "North Macedonia": 97.1,
    Netherlands: 259.95,
    Peru: 105.48,
    Honduras: 98.37,
    Greece: 199.28,
    Croatia: 101.17,
    Austria: 157.16,
    Djibouti: "n/a",
    Nicaragua: 77.6,
    Switzerland: 290,
    Bolivia: 62.53,
    Ireland: 43.89,
    Bhutan: 24.21,
    Qatar: 33.54,
    "Cabo Verde": 76.05,
    Portugal: 94.45,
    Guatemala: 40.81,
    Mauritania: 60.47,
    "New Zealand": 34.44,
    Finland: 67.59,
    "Hong Kong SAR": 25.61,
    Comoros: 104.53,
    Suriname: 1.61,
    Namibia: 12.56,
    Tunisia: 21.01,
    Moldova: 15.8,
    "Trinidad and Tobago": 19.69,
    Maldives: 5.45,
    "The Gambia": 23.49,
    Belarus: 0.48,
    Vanuatu: 12.41,
    "Slovak Republic": 19.26,
    "Puerto Rico": "n/a",
    Turkmenistan: 1.33,
    Tajikistan: 3.04,
    Botswana: 4.49,
    "Papua New Guinea": 7.52,
    Ecuador: 14.15,
    Eritrea: 22.9,
    Eswatini: 2.69,
    Panama: 9.33,
    Slovenia: 7.43,
    Georgia: 4.47,
    Azerbaijan: 0.83,
    Bulgaria: 15.56,
    "El Salvador": 6.43,
    Jordan: 6.8,
    Kuwait: 3.03,
    Bahrain: 1.45,
    Lithuania: 3.41,
    Cyprus: 8.96,
    Luxembourg: 2.21,
    Seychelles: 7.54,
    Lesotho: 4.54,
    "West Bank and Gaza": 4.35,
    "Bosnia and Herzegovina": 4.08,
    Oman: 1.59,
    Latvia: 1.56,
    Barbados: 4.99,
    "The Bahamas": 1.94,
    Malta: 3.45,
    Fiji: 2.28,
    Estonia: 0.5,
    "São Tomé and Príncipe": 3.59,
    Aruba: 1.72,
    "St. Lucia": 1.43,
    "Solomon Islands": 1.58,
    Belize: 1.98,
    Montenegro: 0.76,
    "Antigua and Barbuda": 2.98,
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": 0.87,
    Liberia: 4.88,
    "St. Kitts and Nevis": 2.1,
    Grenada: 1.53,
    Dominica: 0.85,
    "San Marino": 0.23,
    Samoa: 0.48,
    Andorra: 0.35,
    Tonga: 0.25,
    "Timor-Leste": 0,
    "Brunei Darussalam": 0,
    Kiribati: 0.02,
    Micronesia: 0.06,
    "Marshall Islands": 0.06,
    Nauru: "n/a",
    Tuvalu: "n/a",
    "Macao SAR": 0
  },
  "2005": {
    Afghanistan: 629.23,
    Argentina: 467.67,
    Ethiopia: 83.99,
    Lebanon: 57984.5,
    Syria: 763.92,
    Venezuela: 0,
    "Islamic Republic of Iran": 354460.6,
    Indonesia: 1285763.62,
    Vietnam: 332713.26,
    Korea: 247972,
    Japan: 928138.7,
    Colombia: 130223.65,
    Uzbekistan: 4194.09,
    India: 29920.7,
    Iraq: 167174.43,
    "Lao P.D.R.": 23778.67,
    Nigeria: 4217.97,
    Paraguay: 15963.35,
    China: 4937.58,
    Guinea: 16080.22,
    Chile: 4819.88,
    Myanmar: 14042.39,
    Uganda: 9443.26,
    Tanzania: 8936.19,
    Cambodia: 9158.49,
    Sudan: 64.69,
    Pakistan: 4126.72,
    "Sierra Leone": 6243.54,
    Hungary: 13584.74,
    Angola: 1078.33,
    Mongolia: "n/a",
    Madagascar: 8727.84,
    Kazakhstan: 614.8,
    "United States": 8535.12,
    "Costa Rica": 3574.22,
    "Sri Lanka": 2222.35,
    Russia: 3438.5,
    "CÙte d'Ivoire": 7245.36,
    Algeria: 1989.67,
    Bangladesh: 1819.14,
    Yemen: 1405.35,
    Mexico: 3683.9,
    Philippines: 3826.96,
    Rwanda: 964.8,
    Kenya: 767.08,
    Senegal: 2097.2,
    Thailand: 3388.99,
    Malawi: 432.39,
    Cameroon: 4510.04,
    Brazil: 1488.7,
    Egypt: 556.38,
    "Democratic Republic of the Congo": 5753.55,
    "Burkina Faso": 1270.83,
    Mali: 1535.9,
    "South Africa": 544.43,
    Gabon: 2461.02,
    Benin: 934.21,
    Niger: 1139.63,
    Armenia: 459.05,
    Burundi: 1655.05,
    Turkey: 341.66,
    "Republic of Congo": 3497.56,
    "Dominican Republic": 415.19,
    "Czech Republic": 910.18,
    Serbia: 947.65,
    Nepal: 303.43,
    Togo: 1023.63,
    Ukraine: 78.15,
    France: 1189.9,
    Chad: 1002.1,
    "United Kingdom": 551.53,
    Italy: 1591.58,
    "Equatorial Guinea": 122.98,
    Canada: 1004.22,
    Germany: 1545.67,
    Uruguay: "n/a",
    Zimbabwe: 2.99,
    Iceland: 731.65,
    Sweden: 1429.43,
    Jamaica: 894.36,
    Mozambique: 122.88,
    Norway: 845.28,
    Spain: 393.48,
    Australia: 104.7,
    Poland: 461.63,
    Albania: 468.05,
    Malaysia: 232.18,
    Israel: 563.58,
    Denmark: 593.35,
    Morocco: 327.57,
    "Taiwan Province of China": 4100.63,
    "Saudi Arabia": 459.6,
    Romania: 50.97,
    "South Sudan": "n/a",
    Singapore: 202.02,
    "Guinea-Bissau": 686.97,
    Haiti: 83.21,
    Zambia: 28.17,
    Guyana: 310.48,
    "Central African Republic": 767.29,
    "Kyrgyz Republic": 86.43,
    Ghana: 7.58,
    Belgium: 294.98,
    "United Arab Emirates": 29.73,
    Mauritius: 124.87,
    "North Macedonia": 113.11,
    Netherlands: 267.04,
    Peru: 98.74,
    Honduras: 102.17,
    Greece: 213.97,
    Croatia: 111.41,
    Austria: 173.58,
    Djibouti: "n/a",
    Nicaragua: 70.46,
    Switzerland: 285.85,
    Bolivia: 63.28,
    Ireland: 44.37,
    Bhutan: 28.81,
    Qatar: 31.08,
    "Cabo Verde": 82.51,
    Portugal: 106.92,
    Guatemala: 43.07,
    Mauritania: 65.42,
    "New Zealand": 33.42,
    Finland: 65.65,
    "Hong Kong SAR": 24.59,
    Comoros: 103.39,
    Suriname: 1.77,
    Namibia: 12.53,
    Tunisia: 21.95,
    Moldova: 15.22,
    "Trinidad and Tobago": 19.37,
    Maldives: 6.43,
    "The Gambia": 24.26,
    Belarus: 0.55,
    Vanuatu: 11.34,
    "Slovak Republic": 17.54,
    "Puerto Rico": "n/a",
    Turkmenistan: 0.96,
    Tajikistan: 3.3,
    Botswana: 3.94,
    "Papua New Guinea": 7.19,
    Ecuador: 14.4,
    Eritrea: 26.82,
    Eswatini: 2.73,
    Panama: 10.27,
    Slovenia: 7.69,
    Georgia: 4.22,
    Azerbaijan: 0.86,
    Bulgaria: 13.39,
    "El Salvador": 6.8,
    Jordan: 6.79,
    Kuwait: 2.79,
    Bahrain: 1.45,
    Lithuania: 3.7,
    Cyprus: 9.49,
    Luxembourg: 2.41,
    Seychelles: 7.28,
    Lesotho: 5.39,
    "West Bank and Gaza": 5.52,
    "Bosnia and Herzegovina": 4.39,
    Oman: 1.15,
    Latvia: 1.57,
    Barbados: 5.75,
    "The Bahamas": 2.17,
    Malta: 3.61,
    Fiji: 2.42,
    Estonia: 0.53,
    "São Tomé and Príncipe": 3.91,
    Aruba: 1.91,
    "St. Lucia": 1.59,
    "Solomon Islands": 1.72,
    Belize: 2.15,
    Montenegro: 0.7,
    "Antigua and Barbuda": 2.56,
    Kosovo: "n/a",
    "St. Vincent and the Grenadines": 0.97,
    Liberia: 5.06,
    "St. Kitts and Nevis": 2.32,
    Grenada: 1.64,
    Dominica: 0.84,
    "San Marino": 0.21,
    Samoa: 0.45,
    Andorra: 0.38,
    Tonga: 0.22,
    "Timor-Leste": 0,
    "Brunei Darussalam": 0,
    Kiribati: 0.02,
    Micronesia: 0.06,
    "Marshall Islands": 0.06,
    Nauru: "n/a",
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2006": {
    Afghanistan: 79.49,
    Argentina: 506.81,
    Ethiopia: 90.04,
    Lebanon: 60850.92,
    Syria: 776.49,
    Venezuela: 0,
    "Islamic Republic of Iran": 357609.29,
    Indonesia: 1301930.66,
    Vietnam: 407714.01,
    Korea: 282783,
    Japan: 931374.3,
    Colombia: 137259.82,
    Uzbekistan: 3523.13,
    India: 33161.06,
    Iraq: 136848.35,
    "Lao P.D.R.": 23825.3,
    Nigeria: 2695.45,
    Paraguay: 13761.75,
    China: 5614.65,
    Guinea: 20689.79,
    Chile: 4097.15,
    Myanmar: 16597.93,
    Uganda: 7157.49,
    Tanzania: 8144.64,
    Cambodia: 9158.49,
    Sudan: 62.62,
    Pakistan: 4431.39,
    "Sierra Leone": 5764.28,
    Hungary: 15611.53,
    Angola: 788.44,
    Mongolia: 1648.77,
    Madagascar: 4415.5,
    Kazakhstan: 680.98,
    "United States": 8868.71,
    "Costa Rica": 3836.67,
    "Sri Lanka": 2582.65,
    Russia: 2827.5,
    "CÙte d'Ivoire": 7394.26,
    Algeria: 2009.41,
    Bangladesh: 2041.47,
    Yemen: 1535.69,
    Mexico: 3978.39,
    Philippines: 3746.09,
    Rwanda: 412.05,
    Kenya: 818.83,
    Senegal: 1068.66,
    Thailand: 3233.12,
    Malawi: 137.13,
    Cameroon: 2005,
    Brazil: 1586.51,
    Egypt: 558.08,
    "Democratic Republic of the Congo": 6982.78,
    "Burkina Faso": 687.13,
    Mali: 651.79,
    "South Africa": 576.75,
    Gabon: 2102.43,
    Benin: 307.44,
    Niger: 453,
    Armenia: 429.85,
    Burundi: 1706.21,
    Turkey: 352.18,
    "Republic of Congo": 3994.72,
    "Dominican Republic": 454.38,
    "Czech Republic": 973.11,
    Serbia: 828.18,
    Nepal: 319.84,
    Togo: 1122.69,
    Ukraine: 80.55,
    France: 1194.1,
    Chad: 1018.45,
    "United Kingdom": 595.87,
    Italy: 1657.33,
    "Equatorial Guinea": 64.35,
    Canada: 1046.42,
    Germany: 1595.31,
    Uruguay: "n/a",
    Zimbabwe: 3.21,
    Iceland: 865.98,
    Sweden: 1363.13,
    Jamaica: 949.59,
    Mozambique: 96.69,
    Norway: 1169.99,
    Spain: 392.13,
    Australia: 103.35,
    Poland: 505.53,
    Albania: 494.74,
    Malaysia: 247.93,
    Israel: 549.14,
    Denmark: 530.74,
    Morocco: 330.88,
    "Taiwan Province of China": 4186.29,
    "Saudi Arabia": 364.6,
    Romania: 43.5,
    "South Sudan": "n/a",
    Singapore: 211.06,
    "Guinea-Bissau": 631.44,
    Haiti: 100.23,
    Zambia: 11.49,
    Guyana: 283.14,
    "Central African Republic": 375.84,
    "Kyrgyz Republic": 83.48,
    Ghana: 4.9,
    Belgium: 297.5,
    "United Arab Emirates": 41.21,
    Mauritius: 133.99,
    "North Macedonia": 102.41,
    Netherlands: 257.6,
    Peru: 100.04,
    Honduras: 80.87,
    Greece: 225.65,
    Croatia: 114.09,
    Austria: 179.44,
    Djibouti: "n/a",
    Nicaragua: 60.79,
    Switzerland: 268.52,
    Bolivia: 49.89,
    Ireland: 43.72,
    Bhutan: 32.32,
    Qatar: 29.76,
    "Cabo Verde": 84.51,
    Portugal: 115,
    Guatemala: 49.65,
    Mauritania: 49.14,
    "New Zealand": 31.09,
    Finland: 65.7,
    "Hong Kong SAR": 21.67,
    Comoros: 103.8,
    Suriname: 1.74,
    Namibia: 13.64,
    Tunisia: 21.94,
    Moldova: 15.66,
    "Trinidad and Tobago": 19.03,
    Maldives: 7.42,
    "The Gambia": 25.84,
    Belarus: 1.01,
    Vanuatu: 10.77,
    "Slovak Republic": 17.71,
    "Puerto Rico": 29.19,
    Turkmenistan: 0.74,
    Tajikistan: 3.41,
    Botswana: 3.89,
    "Papua New Guinea": 6.64,
    Ecuador: 14.57,
    Eritrea: 28.69,
    Eswatini: 3.25,
    Panama: 10.5,
    Slovenia: 8.2,
    Georgia: 4.01,
    Azerbaijan: 0.99,
    Bulgaria: 12.12,
    "El Salvador": 7.51,
    Jordan: 7.43,
    Kuwait: 2.43,
    Bahrain: 1.41,
    Lithuania: 4.15,
    Cyprus: 9.45,
    Luxembourg: 2.81,
    Seychelles: 7.58,
    Lesotho: 6.3,
    "West Bank and Gaza": 4.59,
    "Bosnia and Herzegovina": 4.13,
    Oman: 1.24,
    Latvia: 1.65,
    Barbados: 6.56,
    "The Bahamas": 2.32,
    Malta: 3.47,
    Fiji: 2.86,
    Estonia: 0.63,
    "São Tomé and Príncipe": 4.48,
    Aruba: 2.01,
    "St. Lucia": 1.66,
    "Solomon Islands": 1.73,
    Belize: 2.27,
    Montenegro: 0.8,
    "Antigua and Barbuda": 2.79,
    Kosovo: 0.52,
    "St. Vincent and the Grenadines": 1.06,
    Liberia: 5.24,
    "St. Kitts and Nevis": 2.47,
    Grenada: 1.75,
    Dominica: 0.85,
    "San Marino": 0.22,
    Samoa: 0.48,
    Andorra: 0.45,
    Tonga: 0.23,
    "Timor-Leste": 0,
    "Brunei Darussalam": 0.12,
    Kiribati: 0.02,
    Micronesia: 0.06,
    "Marshall Islands": 0.06,
    Nauru: "n/a",
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2007": {
    Afghanistan: 86.08,
    Argentina: 557.32,
    Ethiopia: 73.48,
    Lebanon: 63364.15,
    Syria: 862.33,
    Venezuela: 0,
    "Islamic Republic of Iran": 441380.05,
    Indonesia: 1389400,
    Vietnam: 509893.13,
    Korea: 298902,
    Japan: 931927.5,
    Colombia: 139963.99,
    Uzbekistan: 3376.19,
    India: 36962.89,
    Iraq: 130497.85,
    "Lao P.D.R.": 25530.64,
    Nigeria: 2678.02,
    Paraguay: 13432.84,
    China: 7888.94,
    Guinea: 16032.38,
    Chile: 3517.36,
    Myanmar: 17177.56,
    Uganda: 5123.54,
    Tanzania: 6452.41,
    Cambodia: 10297.96,
    Sudan: 64.34,
    Pakistan: 4852.23,
    "Sierra Leone": 2717.15,
    Hungary: 16757.36,
    Angola: 1053.51,
    Mongolia: 1790,
    Madagascar: 4506.33,
    Kazakhstan: 525.81,
    "United States": 9345.74,
    "Costa Rica": 3746.61,
    "Sri Lanka": 3041.79,
    Russia: 2861.5,
    "CÙte d'Ivoire": 7214.74,
    Algeria: 1263.2,
    Bangladesh: 2304.4,
    Yemen: 1741.03,
    Mexico: 4280.09,
    Philippines: 3611.19,
    Rwanda: 491.9,
    Kenya: 825.56,
    Senegal: 1270.15,
    Thailand: 3183.42,
    Malawi: 165.52,
    Cameroon: 1581,
    Brazil: 1744.78,
    Egypt: 597.28,
    "Democratic Republic of the Congo": 7347.08,
    "Burkina Faso": 832.71,
    Mali: 723.58,
    "South Africa": 570.85,
    Gabon: 2339.15,
    Benin: 558.58,
    Niger: 487.79,
    Armenia: 448.76,
    Burundi: 1901.73,
    Turkey: 335.55,
    "Republic of Congo": 3941.28,
    "Dominican Republic": 479.92,
    "Czech Republic": 1054.63,
    Serbia: 787.44,
    Nepal: 314.52,
    Togo: 1268.82,
    Ukraine: 88.75,
    France: 1252.9,
    Chad: 917.52,
    "United Kingdom": 642.36,
    Italy: 1677.65,
    "Equatorial Guinea": 47.23,
    Canada: 1054.85,
    Germany: 1603.67,
    Uruguay: "n/a",
    Zimbabwe: 3.48,
    Iceland: 948.74,
    Sweden: 1294.14,
    Jamaica: 1047.25,
    Mozambique: 87.11,
    Norway: 1168.64,
    Spain: 384.66,
    Australia: 109.51,
    Poland: 528.43,
    Albania: 517.04,
    Malaysia: 274.22,
    Israel: 536.36,
    Denmark: 475.5,
    Morocco: 329.82,
    "Taiwan Province of China": 4297.37,
    "Saudi Arabia": 266.8,
    Romania: 52.92,
    "South Sudan": "n/a",
    Singapore: 243.92,
    "Guinea-Bissau": 591.1,
    Haiti: 107.53,
    Zambia: 12.33,
    Guyana: 214.73,
    "Central African Republic": 402.46,
    "Kyrgyz Republic": 81.94,
    Ghana: 7.18,
    Belgium: 300.06,
    "United Arab Emirates": 84.21,
    Mauritius: 137.06,
    "North Macedonia": 87.75,
    Netherlands: 259.88,
    Peru: 101.89,
    Honduras: 56.11,
    Greece: 239.92,
    Croatia: 120.57,
    Austria: 183.85,
    Djibouti: "n/a",
    Nicaragua: 42.34,
    Switzerland: 263.79,
    Bolivia: 41.24,
    Ireland: 47.18,
    Bhutan: 31.83,
    Qatar: 25.9,
    "Cabo Verde": 79.26,
    Portugal: 127.63,
    Guatemala: 55.86,
    Mauritania: 46.43,
    "New Zealand": 29.88,
    Finland: 63.43,
    "Hong Kong SAR": 20.06,
    Comoros: 101.73,
    Suriname: 1.42,
    Namibia: 11.93,
    Tunisia: 22.34,
    Moldova: 15.11,
    "Trinidad and Tobago": 21.73,
    Maldives: 8.57,
    "The Gambia": 12.1,
    Belarus: 1.59,
    Vanuatu: 10.31,
    "Slovak Republic": 19.17,
    "Puerto Rico": 33.03,
    Turkmenistan: 0.65,
    Tajikistan: 4.38,
    Botswana: 3.81,
    "Papua New Guinea": 6.27,
    Ecuador: 14.52,
    Eritrea: 31.57,
    Eswatini: 3.94,
    Panama: 10.51,
    Slovenia: 8.01,
    Georgia: 3.94,
    Azerbaijan: 1.14,
    Bulgaria: 11.17,
    "El Salvador": 7.87,
    Jordan: 8.18,
    Kuwait: 2.3,
    Bahrain: 1.34,
    Lithuania: 4.61,
    Cyprus: 9.31,
    Luxembourg: 3.05,
    Seychelles: 9.97,
    Lesotho: 6.87,
    "West Bank and Gaza": 5.67,
    "Bosnia and Herzegovina": 4.1,
    Oman: 0.83,
    Latvia: 1.84,
    Barbados: 7.28,
    "The Bahamas": 2.44,
    Malta: 3.59,
    Fiji: 2.74,
    Estonia: 0.62,
    "São Tomé and Príncipe": 2.03,
    Aruba: 2.14,
    "St. Lucia": 1.76,
    "Solomon Islands": 1.63,
    Belize: 2.27,
    Montenegro: 0.85,
    "Antigua and Barbuda": 2.76,
    Kosovo: 0.51,
    "St. Vincent and the Grenadines": 0.98,
    Liberia: 5.3,
    "St. Kitts and Nevis": 2.49,
    Grenada: 1.82,
    Dominica: 0.85,
    "San Marino": 0.2,
    Samoa: 0.48,
    Andorra: 0.53,
    Tonga: 0.23,
    "Timor-Leste": 0,
    "Brunei Darussalam": 0.14,
    Kiribati: 0.02,
    Micronesia: 0.07,
    "Marshall Islands": 0.06,
    Nauru: "n/a",
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2008": {
    Afghanistan: 98.62,
    Argentina: 618.67,
    Ethiopia: 96.39,
    Lebanon: 70888,
    Syria: 913.6,
    Venezuela: 0,
    "Islamic Republic of Iran": 417185.69,
    Indonesia: 1638100,
    Vietnam: 637028.12,
    Korea: 311046,
    Japan: 953855.3,
    Colombia: 154319.71,
    Uzbekistan: 3943.07,
    India: 41007.44,
    Iraq: 116462.32,
    "Lao P.D.R.": 26870.36,
    Nigeria: 2849.17,
    Paraguay: 15505.61,
    China: 8638.33,
    Guinea: 18735.19,
    Chile: 4614.5,
    Myanmar: 16788.31,
    Uganda: 6037.77,
    Tanzania: 7226.78,
    Cambodia: 11329.51,
    Sudan: 75.56,
    Pakistan: 6127.72,
    "Sierra Leone": 3165.58,
    Hungary: 19372.11,
    Angola: 2084.57,
    Mongolia: 2031.73,
    Madagascar: 5668.24,
    Kazakhstan: 1086.12,
    "United States": 10845.12,
    "Costa Rica": 3891.31,
    "Sri Lanka": 3588.96,
    Russia: 3293.2,
    "CÙte d'Ivoire": 7683.45,
    Algeria: 889.73,
    Bangladesh: 2550.77,
    Yemen: 1957.94,
    Mexico: 5249.29,
    Philippines: 4025.91,
    Rwanda: 517.87,
    Kenya: 1029.69,
    Senegal: 1433.73,
    Thailand: 3408.23,
    Malawi: 247.65,
    Cameroon: 1378.62,
    Brazil: 1938.47,
    Egypt: 628.62,
    "Democratic Republic of the Congo": 9721.85,
    "Burkina Faso": 970.83,
    Mali: 884.88,
    "South Africa": 627.96,
    Gabon: 1392.66,
    Benin: 799.15,
    Niger: 460.06,
    Armenia: 522.1,
    Burundi: 1959.21,
    Turkey: 379,
    "Republic of Congo": 3616.2,
    "Dominican Republic": 558.43,
    "Czech Republic": 1136.77,
    Serbia: 888.71,
    Nepal: 341.98,
    Togo: 1374.57,
    Ukraine: 194.81,
    France: 1370.3,
    Chad: 935.07,
    "United Kingdom": 783.93,
    Italy: 1738.65,
    "Equatorial Guinea": 43.17,
    Canada: 1124.9,
    Germany: 1672.58,
    Uruguay: 320.74,
    Zimbabwe: 4.1,
    Iceland: 1754.26,
    Sweden: 1279.95,
    Jamaica: 1285.65,
    Mozambique: 102.84,
    Norway: 1246.69,
    Spain: 440.62,
    Australia: 145.05,
    Poland: 600.81,
    Albania: 595.88,
    Malaysia: 317.44,
    Israel: 556.84,
    Denmark: 600.13,
    Morocco: 325.81,
    "Taiwan Province of China": 4374.3,
    "Saudi Arabia": 235,
    Romania: 70.2,
    "South Sudan": "n/a",
    Singapore: 264.21,
    "Guinea-Bissau": 632.3,
    Haiti: 131.39,
    Zambia: 12.88,
    Guyana: 244.69,
    "Central African Republic": 325.63,
    "Kyrgyz Republic": 92.14,
    Ghana: 10.08,
    Belgium: 327.68,
    "United Arab Emirates": 103.68,
    Mauritius: 142.32,
    "North Macedonia": 85.64,
    Netherlands: 348.13,
    Peru: 99.47,
    Honduras: 58.58,
    Greece: 264.78,
    Croatia: 136.2,
    Austria: 200.98,
    Djibouti: "n/a",
    Nicaragua: 42.8,
    Switzerland: 275.2,
    Bolivia: 44.4,
    Ireland: 79.62,
    Bhutan: 33.08,
    Qatar: 46.56,
    "Cabo Verde": 77.59,
    Portugal: 135.48,
    Guatemala: 59.56,
    Mauritania: 50.75,
    "New Zealand": 36,
    Finland: 63.25,
    "Hong Kong SAR": 16.62,
    Comoros: 101.74,
    Suriname: 1.53,
    Namibia: 13.39,
    Tunisia: 26.12,
    Moldova: 13.97,
    "Trinidad and Tobago": 23.09,
    Maldives: 11.33,
    "The Gambia": 13.7,
    Belarus: 2.72,
    Vanuatu: 12.7,
    "Slovak Republic": 19.62,
    "Puerto Rico": 38.17,
    Turkmenistan: 1.39,
    Tajikistan: 5.32,
    Botswana: 5.69,
    "Papua New Guinea": 6.79,
    Ecuador: 14.96,
    Eritrea: 36.84,
    Eswatini: 3.97,
    Panama: 10.48,
    Slovenia: 8.26,
    Georgia: 5.17,
    Azerbaijan: 1.29,
    Bulgaria: 10.71,
    "El Salvador": 8.65,
    Jordan: 8.72,
    Kuwait: 2.13,
    Bahrain: 1.21,
    Lithuania: 4.76,
    Cyprus: 8.39,
    Luxembourg: 5.86,
    Seychelles: 17.57,
    Lesotho: 6.98,
    "West Bank and Gaza": 6.03,
    "Bosnia and Herzegovina": 7.72,
    Oman: 0.86,
    Latvia: 4.39,
    Barbados: 7.85,
    "The Bahamas": 2.68,
    Malta: 3.84,
    Fiji: 2.89,
    Estonia: 0.75,
    "São Tomé and Príncipe": 1.62,
    Aruba: 2.05,
    "St. Lucia": 1.8,
    "Solomon Islands": 1.12,
    Belize: 2.25,
    Montenegro: 1.06,
    "Antigua and Barbuda": 2.81,
    Kosovo: 0.38,
    "St. Vincent and the Grenadines": 1.07,
    Liberia: 3.92,
    "St. Kitts and Nevis": 2.62,
    Grenada: 1.87,
    Dominica: 0.82,
    "San Marino": 0.25,
    Samoa: 0.49,
    Andorra: 0.7,
    Tonga: 0.22,
    "Timor-Leste": 0,
    "Brunei Darussalam": 0.21,
    Kiribati: 0.02,
    Micronesia: 0.08,
    "Marshall Islands": 0.07,
    Nauru: "n/a",
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2009": {
    Afghanistan: 98.66,
    Argentina: 691.32,
    Ethiopia: 118.79,
    Lebanon: 77112,
    Syria: 786.77,
    Venezuela: 0,
    "Islamic Republic of Iran": 497481.18,
    Indonesia: 1592000,
    Vietnam: 834964.9,
    Korea: 361409,
    Japan: 983455.3,
    Colombia: 177490.81,
    Uzbekistan: 4488.48,
    India: 46328.25,
    Iraq: 114148.74,
    "Lao P.D.R.": 28393.32,
    Nigeria: 3816.19,
    Paraguay: 15234,
    China: 12017.24,
    Guinea: 19769.98,
    Chile: 5619.22,
    Myanmar: 18004.35,
    Uganda: 7240.79,
    Tanzania: 9189.92,
    Cambodia: 12256.17,
    Sudan: 95.29,
    Pakistan: 7746.96,
    "Sierra Leone": 3992.17,
    Hungary: 20470.89,
    Angola: 3140.17,
    Mongolia: 3198.81,
    Madagascar: 6557.43,
    Kazakhstan: 1739.38,
    "United States": 12535.27,
    "Costa Rica": 4587.32,
    "Sri Lanka": 4161.42,
    Russia: 4124,
    "CÙte d'Ivoire": 7364.42,
    Algeria: 974.35,
    Bangladesh: 2788.05,
    Yemen: 2540.76,
    Mexico: 5312.14,
    Philippines: 4178.87,
    Rwanda: 596.31,
    Kenya: 1176.83,
    Senegal: 2273.24,
    Thailand: 4001.94,
    Malawi: 289.02,
    Cameroon: 1478.62,
    Brazil: 2181.9,
    Egypt: 761.07,
    "Democratic Republic of the Congo": 13781.3,
    "Burkina Faso": 1149.87,
    Mali: 1054.6,
    "South Africa": 754.29,
    Gabon: 1491.44,
    Benin: 858.65,
    Niger: 549.49,
    Armenia: 1072.5,
    Burundi: 561.12,
    Turkey: 437.68,
    "Republic of Congo": 3833.64,
    "Dominican Republic": 637.72,
    "Czech Republic": 1319,
    Serbia: 1035.45,
    Nepal: 444.85,
    Togo: 1280.19,
    Ukraine: 323.14,
    France: 1608,
    Chad: 1382.57,
    "United Kingdom": 978.56,
    Italy: 1839.23,
    "Equatorial Guinea": 307.75,
    Canada: 1245.63,
    Germany: 1789.21,
    Uruguay: 360.14,
    Zimbabwe: 5.68,
    Iceland: 2094.36,
    Sweden: 1361.16,
    Jamaica: 1546.99,
    Mozambique: 132.73,
    Norway: 1037.95,
    Spain: 569.54,
    Australia: 210.24,
    Poland: 683.56,
    Albania: 682.55,
    Malaysia: 376.39,
    Israel: 608.12,
    Denmark: 691.99,
    Morocco: 345.25,
    "Taiwan Province of China": 4742.83,
    "Saudi Arabia": 225.1,
    Romania: 119.2,
    "South Sudan": "n/a",
    Singapore: 300.47,
    "Guinea-Bissau": 620.67,
    Haiti: 86.19,
    Zambia: 15.87,
    Guyana: 277.04,
    "Central African Republic": 197.38,
    "Kyrgyz Republic": 117.7,
    Ghana: 13.26,
    Belgium: 347.22,
    "United Arab Emirates": 196.81,
    Mauritius: 172.73,
    "North Macedonia": 98.39,
    Netherlands: 348.86,
    Peru: 103.62,
    Honduras: 64.42,
    Greece: 301.06,
    Croatia: 160.97,
    Austria: 229.23,
    Djibouti: 74.83,
    Nicaragua: 49.39,
    Switzerland: 259.71,
    Bolivia: 47.73,
    Ireland: 104.69,
    Bhutan: 37.16,
    Qatar: 115.51,
    "Cabo Verde": 87.16,
    Portugal: 154.01,
    Guatemala: 70.61,
    Mauritania: 50.98,
    "New Zealand": 46.69,
    Finland: 75.48,
    "Hong Kong SAR": 11.21,
    Comoros: 101.32,
    Suriname: 1.67,
    Namibia: 11.92,
    Tunisia: 27.37,
    Moldova: 19.71,
    "Trinidad and Tobago": 24.74,
    Maldives: 14.53,
    "The Gambia": 15.02,
    Belarus: 4.63,
    Vanuatu: 13.36,
    "Slovak Republic": 23.31,
    "Puerto Rico": 44.83,
    Turkmenistan: 1.4,
    Tajikistan: 7.62,
    Botswana: 13.78,
    "Papua New Guinea": 6.95,
    Ecuador: 11.61,
    Eritrea: 41.25,
    Eswatini: 3.18,
    Panama: 11.29,
    Slovenia: 12.52,
    Georgia: 6.22,
    Azerbaijan: 1.68,
    Bulgaria: 10.64,
    "El Salvador": 10.4,
    Jordan: 10.1,
    Kuwait: 2.03,
    Bahrain: 1.84,
    Lithuania: 7.53,
    Cyprus: 9.87,
    Luxembourg: 5.97,
    Seychelles: 12.24,
    Lesotho: 5.53,
    "West Bank and Gaza": 7.62,
    "Bosnia and Herzegovina": 8.69,
    Oman: 1.24,
    Latvia: 6.77,
    Barbados: 8.97,
    "The Bahamas": 3.09,
    Malta: 4.15,
    Fiji: 3.13,
    Estonia: 1.02,
    "São Tomé and Príncipe": 2.21,
    Aruba: 2.23,
    "St. Lucia": 1.93,
    "Solomon Islands": 1.63,
    Belize: 2.35,
    Montenegro: 1.31,
    "Antigua and Barbuda": 3.34,
    Kosovo: 0.25,
    "St. Vincent and the Grenadines": 1.16,
    Liberia: 2.32,
    "St. Kitts and Nevis": 2.76,
    Grenada: 1.9,
    Dominica: 0.83,
    "San Marino": 0.3,
    Samoa: 0.59,
    Andorra: 0.82,
    Tonga: 0.26,
    "Timor-Leste": 0,
    "Brunei Darussalam": 0.19,
    Kiribati: 0.02,
    Micronesia: 0.09,
    "Marshall Islands": 0.06,
    Nauru: 0.15,
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2010": {
    Afghanistan: 54.78,
    Argentina: 722.09,
    Ethiopia: 152.87,
    Lebanon: 79298,
    Syria: 838.21,
    Venezuela: 0,
    "Islamic Republic of Iran": 703854.4,
    Indonesia: 1683400,
    Vietnam: 1009568.58,
    Korea: 390037.5,
    Japan: 1039841.6,
    Colombia: 198565.8,
    Uzbekistan: 5217.33,
    India: 51686.73,
    Iraq: 86757.83,
    "Lao P.D.R.": 30573.72,
    Nigeria: 5210.27,
    Paraguay: 15560.93,
    China: 13858.25,
    Guinea: 27020.33,
    Chile: 9535.01,
    Myanmar: 19477.93,
    Uganda: 9865.42,
    Tanzania: 12252.16,
    Cambodia: 13523.49,
    Sudan: 127.51,
    Pakistan: 9027.58,
    "Sierra Leone": 4804.66,
    Hungary: 21989.86,
    Angola: 2862.15,
    Mongolia: 3022.11,
    Madagascar: 6747.5,
    Kazakhstan: 2330.49,
    "United States": 14317.89,
    "Costa Rica": 5562.34,
    "Sri Lanka": 4590.25,
    Russia: 5013.99,
    "CÙte d'Ivoire": 7770.54,
    Algeria: 1258.11,
    Bangladesh: 2830.1,
    Yemen: 2876.22,
    Mexico: 5608.94,
    Philippines: 4472.87,
    Rwanda: 672.16,
    Kenya: 1407.25,
    Senegal: 2273.24,
    Thailand: 4230.31,
    Malawi: 284.79,
    Cameroon: 1904,
    Brazil: 2449.83,
    Egypt: 882.89,
    "Democratic Republic of the Congo": 5972.84,
    "Burkina Faso": 1388.8,
    Mali: 1337.51,
    "South Africa": 952.9,
    Gabon: 1514.4,
    Benin: 990.78,
    Niger: 584.28,
    Armenia: 1168.06,
    Burundi: 1173.28,
    Turkey: 463.83,
    "Republic of Congo": 2829.16,
    "Dominican Republic": 739.95,
    "Czech Republic": 1480.1,
    Serbia: 1339.73,
    Nepal: 481.51,
    Togo: 785.67,
    Ukraine: 438.49,
    France: 1701.1,
    Chad: 1589.87,
    "United Kingdom": 1192.84,
    Italy: 1920.62,
    "Equatorial Guinea": 637.24,
    Canada: 1353.14,
    Germany: 2114.89,
    Uruguay: 360.32,
    Zimbabwe: 5.73,
    Iceland: 2238.06,
    Sweden: 1360.31,
    Jamaica: 1657.04,
    Mozambique: 149.41,
    Norway: 1119.04,
    Spain: 649.15,
    Australia: 277.66,
    Poland: 774.71,
    Albania: 715.52,
    Malaysia: 426.65,
    Israel: 617.81,
    Denmark: 771.24,
    Morocco: 384.45,
    "Taiwan Province of China": 5186.84,
    "Saudi Arabia": 166.9,
    Romania: 163.02,
    "South Sudan": "n/a",
    Singapore: 331.16,
    "Guinea-Bissau": 287.13,
    Haiti: 111,
    Zambia: 18.37,
    Guyana: 312.32,
    "Central African Republic": 210.79,
    "Kyrgyz Republic": 131.56,
    Ghana: 21.4,
    Belgium: 364.13,
    "United Arab Emirates": 207.24,
    Mauritius: 176.01,
    "North Macedonia": 106.14,
    Netherlands: 379.68,
    Peru: 106.62,
    Honduras: 67.72,
    Greece: 330.57,
    Croatia: 190.03,
    Austria: 243.87,
    Djibouti: 76.49,
    Nicaragua: 56.77,
    Switzerland: 258.69,
    Bolivia: 51.89,
    Ireland: 144.23,
    Bhutan: 39.79,
    Qatar: 132.57,
    "Cabo Verde": 100.53,
    Portugal: 180,
    Guatemala: 80.28,
    Mauritania: 68.25,
    "New Zealand": 59.81,
    Finland: 88.25,
    "Hong Kong SAR": 11.23,
    Comoros: 102.63,
    Suriname: 2.22,
    Namibia: 13.49,
    Tunisia: 28.68,
    Moldova: 21.98,
    "Trinidad and Tobago": 28.12,
    Maldives: 17.48,
    "The Gambia": 18.57,
    Belarus: 6.27,
    Vanuatu: 13.14,
    "Slovak Republic": 27.93,
    "Puerto Rico": 47.56,
    Turkmenistan: 2.6,
    Tajikistan: 9.1,
    Botswana: 18.63,
    "Papua New Guinea": 6.7,
    Ecuador: 12.31,
    Eritrea: 48.48,
    Eswatini: 4.58,
    Panama: 11.94,
    Slovenia: 13.92,
    Georgia: 6.97,
    Azerbaijan: 2.12,
    Bulgaria: 10.53,
    "El Salvador": 11.08,
    Jordan: 11.45,
    Kuwait: 2.04,
    Bahrain: 2.9,
    Lithuania: 10.15,
    Cyprus: 10.77,
    Luxembourg: 8.1,
    Seychelles: 9.63,
    Lesotho: 5.78,
    "West Bank and Gaza": 8.22,
    "Bosnia and Herzegovina": 10.34,
    Oman: 1.37,
    Latvia: 8.41,
    Barbados: 9.87,
    "The Bahamas": 3.4,
    Malta: 4.45,
    Fiji: 3.38,
    Estonia: 0.98,
    "São Tomé and Príncipe": 2.91,
    Aruba: 2.4,
    "St. Lucia": 2.12,
    "Solomon Islands": 1.57,
    Belize: 2.39,
    Montenegro: 1.41,
    "Antigua and Barbuda": 2.78,
    Kosovo: 0.26,
    "St. Vincent and the Grenadines": 1.2,
    Liberia: 0.5,
    "St. Kitts and Nevis": 2.98,
    Grenada: 2.02,
    Dominica: 0.9,
    "San Marino": 0.28,
    Samoa: 0.7,
    Andorra: 0.91,
    Tonga: 0.32,
    "Timor-Leste": 0,
    "Brunei Darussalam": 0.21,
    Kiribati: 0.01,
    Micronesia: 0.09,
    "Marshall Islands": 0.06,
    Nauru: 0.13,
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2011": {
    Afghanistan: 62.73,
    Argentina: 848.4,
    Ethiopia: 233.46,
    Lebanon: 80887,
    Syria: "n/a",
    Venezuela: 0,
    "Islamic Republic of Iran": 695261.6,
    Indonesia: 1809600,
    Vietnam: 1266472.17,
    Korea: 459200,
    Japan: 1089883,
    Colombia: 221507.78,
    Uzbekistan: 6535.63,
    India: 59973.01,
    Iraq: 88494.22,
    "Lao P.D.R.": 30946.8,
    Nigeria: 11105.73,
    Paraguay: 14604.4,
    China: 16349.15,
    Guinea: 26239.23,
    Chile: 13520.21,
    Myanmar: 20531.81,
    Uganda: 12460.08,
    Tanzania: 15191.08,
    Cambodia: 15482.88,
    Sudan: 163.31,
    Pakistan: 10779.01,
    "Sierra Leone": 5379.11,
    Hungary: 22927.1,
    Angola: 3103.84,
    Mongolia: 4301.57,
    Madagascar: 7005.69,
    Kazakhstan: 2869.78,
    "United States": 15519.45,
    "Costa Rica": 6381.77,
    "Sri Lanka": 5133.37,
    Russia: 6215.88,
    "CÙte d'Ivoire": 8376.34,
    Algeria: 1350.11,
    Bangladesh: 3354.08,
    Yemen: 3199.41,
    Mexico: 6285.52,
    Philippines: 4609.09,
    Rwanda: 773.58,
    Kenya: 1604,
    Senegal: 2759.98,
    Thailand: 4448.3,
    Malawi: 352.48,
    Cameroon: 2172,
    Brazil: 2678.5,
    Egypt: 1049.97,
    "Democratic Republic of the Congo": 5930.96,
    "Burkina Faso": 1394.95,
    Mali: 1467.59,
    "South Africa": 1155.88,
    Gabon: 1838.6,
    Benin: 1101.59,
    Niger: 608.46,
    Armenia: 1348.82,
    Burundi: 1204.1,
    Turkey: 508.16,
    "Republic of Congo": 2537.14,
    "Dominican Republic": 863.15,
    "Czech Republic": 1613.65,
    Serbia: 1588.23,
    Nepal: 505.57,
    Togo: 863.93,
    Ukraine: 479.38,
    France: 1808,
    Chad: 1755.21,
    "United Kingdom": 1327.69,
    Italy: 1973.43,
    "Equatorial Guinea": 721.68,
    Canada: 1450.79,
    Germany: 2151.49,
    Uruguay: 417.08,
    Zimbabwe: 6.04,
    Iceland: 2438.82,
    Sweden: 1384.88,
    Jamaica: 1758.42,
    Mozambique: 145.14,
    Norway: 832.04,
    Spain: 743.04,
    Australia: 352.97,
    Poland: 856.58,
    Albania: 772.74,
    Malaysia: 479.9,
    Israel: 643.65,
    Denmark: 850.86,
    Morocco: 430.92,
    "Taiwan Province of China": 5468.5,
    "Saudi Arabia": 135.5,
    Romania: 191.42,
    "South Sudan": 0,
    Singapore: 367.17,
    "Guinea-Bissau": 251.55,
    Haiti: 111.86,
    Zambia: 23.73,
    Guyana: 350.9,
    "Central African Republic": 226.18,
    "Kyrgyz Republic": 143.15,
    Ghana: 25.45,
    Belgium: 389.11,
    "United Arab Emirates": 276.46,
    Mauritius: 189.57,
    "North Macedonia": 128.71,
    Netherlands: 402.12,
    Peru: 108.18,
    Honduras: 82.17,
    Greece: 373.82,
    Croatia: 214.49,
    Austria: 254.86,
    Djibouti: 79.43,
    Nicaragua: 63.11,
    Switzerland: 266.26,
    Bolivia: 58.72,
    Ireland: 189.73,
    Bhutan: 53.12,
    Qatar: 204.43,
    "Cabo Verde": 116.12,
    Portugal: 201.46,
    Guatemala: 87.83,
    Mauritania: 73.86,
    "New Zealand": 73.27,
    Finland: 95.58,
    "Hong Kong SAR": 11.21,
    Comoros: 100.05,
    Suriname: 2.9,
    Namibia: 24.73,
    Tunisia: 31.82,
    Moldova: 23.9,
    "Trinidad and Tobago": 46.24,
    Maldives: 21.01,
    "The Gambia": 20.45,
    Belarus: 17.89,
    Vanuatu: 14.68,
    "Slovak Republic": 30.98,
    "Puerto Rico": 50.17,
    Turkmenistan: 8.37,
    Tajikistan: 10.68,
    Botswana: 21.62,
    "Papua New Guinea": 6.94,
    Ecuador: 13.31,
    Eritrea: 51.04,
    Eswatini: 5.14,
    Panama: 13.12,
    Slovenia: 17.22,
    Georgia: 7.23,
    Azerbaijan: 2.59,
    Bulgaria: 11.63,
    "El Salvador": 12,
    Jordan: 13.01,
    Kuwait: 1.97,
    Bahrain: 3.55,
    Lithuania: 11.63,
    Cyprus: 12.87,
    Luxembourg: 8.2,
    Seychelles: 10.4,
    Lesotho: 6.97,
    "West Bank and Gaza": 10.52,
    "Bosnia and Herzegovina": 10.37,
    Oman: 1.32,
    Latvia: 8.8,
    Barbados: 10.47,
    "The Bahamas": 3.56,
    Malta: 4.8,
    Fiji: 3.57,
    Estonia: 1.03,
    "São Tomé and Príncipe": 3.21,
    Aruba: 2.92,
    "St. Lucia": 2.34,
    "Solomon Islands": 1.47,
    Belize: 2.43,
    Montenegro: 1.59,
    "Antigua and Barbuda": 2.82,
    Kosovo: 0.25,
    "St. Vincent and the Grenadines": 1.26,
    Liberia: 0.53,
    "St. Kitts and Nevis": 2.98,
    Grenada: 2.12,
    Dominica: 0.93,
    "San Marino": 0.22,
    Samoa: 0.74,
    Andorra: 0.95,
    Tonga: 0.39,
    "Timor-Leste": 0,
    "Brunei Darussalam": 0.5,
    Kiribati: 0.01,
    Micronesia: 0.09,
    "Marshall Islands": 0.06,
    Nauru: 0.13,
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2012": {
    Afghanistan: 69.87,
    Argentina: 1066.67,
    Ethiopia: 315.19,
    Lebanon: 86959,
    Syria: "n/a",
    Venezuela: 0.01,
    "Islamic Republic of Iran": 859469.6,
    Indonesia: 1978200,
    Vietnam: 1561471.82,
    Korea: 504600,
    Japan: 1131537.4,
    Colombia: 226389.62,
    Uzbekistan: 8620.72,
    India: 67603.89,
    Iraq: 88424.2,
    "Lao P.D.R.": 37624.79,
    Nigeria: 12760.7,
    Paraguay: 18126.5,
    China: 18539.23,
    Guinea: 14029.03,
    Chile: 15517.62,
    Myanmar: 18020.87,
    Uganda: 15103.48,
    Tanzania: 18723.99,
    Cambodia: 17874.03,
    Sudan: 265.7,
    Pakistan: 12714.06,
    "Sierra Leone": 6015.8,
    Hungary: 22662.23,
    Angola: 3262.91,
    Mongolia: 7291.51,
    Madagascar: 7735.24,
    Kazakhstan: 3760.67,
    "United States": 16742.21,
    "Costa Rica": 8014.51,
    "Sri Lanka": 6078.56,
    Russia: 7605.41,
    "CÙte d'Ivoire": 4668.4,
    Algeria: 1511.65,
    Bangladesh: 3822.02,
    Yemen: 3589.38,
    Mexico: 6746.29,
    Philippines: 5053.71,
    Rwanda: 897.44,
    Kenya: 1869.88,
    Senegal: 3109.8,
    Thailand: 4937.24,
    Malawi: 604.86,
    Cameroon: 2290,
    Brazil: 2994.57,
    Egypt: 1235.92,
    "Democratic Republic of the Congo": 5871.86,
    "Burkina Faso": 1616.78,
    Mali: 1612.86,
    "South Africa": 1334.11,
    Gabon: 1879.75,
    Benin: 1111.5,
    Niger: 870.67,
    Armenia: 1518.88,
    Burundi: 1394.47,
    Turkey: 513.07,
    "Republic of Congo": 2727.82,
    "Dominican Republic": 1009.77,
    "Czech Republic": 1805.31,
    Serbia: 2074.18,
    Nepal: 606.9,
    Togo: 949.18,
    Ukraine: 527.33,
    France: 1892.5,
    Chad: 1821.51,
    "United Kingdom": 1423.53,
    Italy: 2054.7,
    "Equatorial Guinea": 811.25,
    Canada: 1560.53,
    Germany: 2229.14,
    Uruguay: 565.89,
    Zimbabwe: 6.55,
    Iceland: 2471.13,
    Sweden: 1403.63,
    Jamaica: 1927.6,
    Mozambique: 173.67,
    Norway: 922.02,
    Spain: 889.91,
    Australia: 417.19,
    Poland: 883.52,
    Albania: 828.27,
    Malaysia: 529.83,
    Israel: 678.81,
    Denmark: 850.75,
    Morocco: 479.33,
    "Taiwan Province of China": 5754.41,
    "Saudi Arabia": 83.8,
    Romania: 224.68,
    "South Sudan": 2.96,
    Singapore: 396.47,
    "Guinea-Bissau": 253.63,
    Haiti: 129.9,
    Zambia: 33.39,
    Guyana: 371.1,
    "Central African Republic": 404.11,
    "Kyrgyz Republic": 156.72,
    Ghana: 36.09,
    Belgium: 404.75,
    "United Arab Emirates": 291.78,
    Mauritius: 199.06,
    "North Macedonia": 157.1,
    Netherlands: 433.76,
    Peru: 107.7,
    Honduras: 105.44,
    Greece: 305.14,
    Croatia: 231.76,
    Austria: 260.22,
    Djibouti: 84.65,
    Nicaragua: 69.07,
    Switzerland: 274.05,
    Bolivia: 66.24,
    Ireland: 210.04,
    Bhutan: 70.67,
    Qatar: 218.43,
    "Cabo Verde": 136.96,
    Portugal: 217.16,
    Guatemala: 96.08,
    Mauritania: 78.47,
    "New Zealand": 77.15,
    Finland: 107.8,
    "Hong Kong SAR": 11.2,
    Comoros: 97.53,
    Suriname: 3.54,
    Namibia: 26.32,
    Tunisia: 38.26,
    Moldova: 32.93,
    "Trinidad and Tobago": 40.7,
    Maldives: 25.33,
    "The Gambia": 22.46,
    Belarus: 20.23,
    Vanuatu: 13.36,
    "Slovak Republic": 38.1,
    "Puerto Rico": 54.57,
    Turkmenistan: 18.11,
    Tajikistan: 11.74,
    Botswana: 21.78,
    "Papua New Guinea": 8.49,
    Ecuador: 15.38,
    Eritrea: 53.6,
    Eswatini: 5.92,
    Panama: 14.57,
    Slovenia: 19.42,
    Georgia: 7.85,
    Azerbaijan: 3.19,
    Bulgaria: 13.67,
    "El Salvador": 13.6,
    Jordan: 15.84,
    Kuwait: 1.76,
    Bahrain: 4.19,
    Lithuania: 13.26,
    Cyprus: 15.43,
    Luxembourg: 9.7,
    Seychelles: 11.63,
    Lesotho: 8.36,
    "West Bank and Gaza": 14.03,
    "Bosnia and Herzegovina": 11.06,
    Oman: 1.54,
    Latvia: 9.37,
    Barbados: 11.44,
    "The Bahamas": 3.91,
    Malta: 4.86,
    Fiji: 3.67,
    Estonia: 1.76,
    "São Tomé and Príncipe": 3.9,
    Aruba: 3.07,
    "St. Lucia": 2.62,
    "Solomon Islands": 1.33,
    Belize: 2.42,
    Montenegro: 1.81,
    "Antigua and Barbuda": 2.84,
    Kosovo: 0.41,
    "St. Vincent and the Grenadines": 1.36,
    Liberia: 0.55,
    "St. Kitts and Nevis": 2.74,
    Grenada: 2.23,
    Dominica: 0.97,
    "San Marino": 0.22,
    Samoa: 0.91,
    Andorra: 1.03,
    Tonga: 0.43,
    "Timor-Leste": "--",
    "Brunei Darussalam": 0.5,
    Kiribati: 0.01,
    Micronesia: 0.09,
    "Marshall Islands": 0.07,
    Nauru: 0.12,
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2013": {
    Afghanistan: 77.11,
    Argentina: 1456.38,
    Ethiopia: 411.8,
    Lebanon: 95692,
    Syria: "n/a",
    Venezuela: 0.01,
    "Islamic Republic of Iran": 1027450.12,
    Indonesia: 2371900,
    Vietnam: 1852670.41,
    Korea: 565600,
    Japan: 1168109.1,
    Colombia: 268443.69,
    Uzbekistan: 9548.26,
    India: 76065.93,
    Iraq: 87512.43,
    "Lao P.D.R.": 46458.21,
    Nigeria: 14816.15,
    Paraguay: 22448.68,
    China: 22085.79,
    Guinea: 19654.86,
    Chile: 17553.69,
    Myanmar: 19920.28,
    Uganda: 18466.87,
    Tanzania: 23878.66,
    Cambodia: 19461.33,
    Sudan: 332.14,
    Pakistan: 14428.21,
    "Sierra Leone": 6520.72,
    Hungary: 23431.2,
    Angola: 4374.03,
    Mongolia: 9476.57,
    Madagascar: 9936.99,
    Kazakhstan: 4536.78,
    "United States": 17600.12,
    "Costa Rica": 8931.44,
    "Sri Lanka": 6884.82,
    Russia: 9012.32,
    "CÙte d'Ivoire": 5257.25,
    Algeria: 1180.92,
    Bangladesh: 4294.55,
    Yemen: 4186.07,
    Mexico: 7471.03,
    Philippines: 5277.77,
    Rwanda: 1318.11,
    Kenya: 2085.93,
    Senegal: 3444.21,
    Thailand: 5430.56,
    Malawi: 1000.91,
    Cameroon: 2910.5,
    Brazil: 3209.25,
    Egypt: 1563.12,
    "Democratic Republic of the Congo": 5745.54,
    "Burkina Faso": 1719.85,
    Mali: 1725.52,
    "South Africa": 1561.22,
    Gabon: 2703.86,
    Benin: 1144.01,
    Niger: 985.69,
    Armenia: 1655.09,
    Burundi: 1443.32,
    Turkey: 568.19,
    "Republic of Congo": 3010.34,
    "Dominican Republic": 1223.82,
    "Czech Republic": 1840.25,
    Serbia: 2370.03,
    Nepal: 621.8,
    Togo: 1221.51,
    Ukraine: 593.68,
    France: 1977.7,
    Chad: 1965.81,
    "United Kingdom": 1498.26,
    Italy: 2136.18,
    "Equatorial Guinea": 679.92,
    Canada: 1638.39,
    Germany: 2214.37,
    Uruguay: 643.51,
    Zimbabwe: 7.05,
    Iceland: 2402.8,
    Sweden: 1535.22,
    Jamaica: 2028.81,
    Mozambique: 256.19,
    Norway: 971.21,
    Spain: 977.31,
    Australia: 478.17,
    Poland: 931.06,
    Albania: 950.32,
    Malaysia: 574.98,
    Israel: 709.11,
    Denmark: 849.94,
    Morocco: 554.26,
    "Taiwan Province of China": 5939.51,
    "Saudi Arabia": 60.14,
    Romania: 248,
    "South Sudan": 7.76,
    Singapore: 381.14,
    "Guinea-Bissau": 278.42,
    Haiti: 156.68,
    Zambia: 40.98,
    Guyana: 354.74,
    "Central African Republic": 432.68,
    "Kyrgyz Republic": 167.43,
    Ghana: 53.44,
    Belgium: 414.43,
    "United Arab Emirates": 229.45,
    Mauritius: 219.94,
    "North Macedonia": 170.49,
    Netherlands: 448.02,
    Peru: 109.02,
    Honduras: 148.49,
    Greece: 321.47,
    Croatia: 268.91,
    Austria: 262.4,
    Djibouti: 89.29,
    Nicaragua: 78.3,
    Switzerland: 274.79,
    Bolivia: 76.47,
    Ireland: 215.36,
    Bhutan: 101.31,
    Qatar: 223.4,
    "Cabo Verde": 157.55,
    Portugal: 224.08,
    Guatemala: 104.08,
    Mauritania: 86.92,
    "New Zealand": 78.86,
    Finland: 114.89,
    "Hong Kong SAR": 11.2,
    Comoros: 42.49,
    Suriname: 5.07,
    Namibia: 30.66,
    Tunisia: 36.97,
    Moldova: 35.6,
    "Trinidad and Tobago": 42.83,
    Maldives: 28.33,
    "The Gambia": 28.81,
    Belarus: 24.72,
    Vanuatu: 13.36,
    "Slovak Republic": 40.74,
    "Puerto Rico": 53.59,
    Turkmenistan: 22.33,
    Tajikistan: 11.87,
    Botswana: 22.67,
    "Papua New Guinea": 11.88,
    Ecuador: 19.06,
    Eritrea: 56.29,
    Eswatini: 6.66,
    Panama: 16.25,
    Slovenia: 25.52,
    Georgia: 8.43,
    Azerbaijan: 3.6,
    Bulgaria: 14.12,
    "El Salvador": 13.89,
    Jordan: 18.49,
    Kuwait: 1.53,
    Bahrain: 5.38,
    Lithuania: 13.55,
    Cyprus: 18.52,
    Luxembourg: 11.02,
    Seychelles: 10.92,
    Lesotho: 9.79,
    "West Bank and Gaza": 14.75,
    "Bosnia and Herzegovina": 11.37,
    Oman: 1.61,
    Latvia: 9.18,
    Barbados: 12.66,
    "The Bahamas": 4.69,
    Malta: 5.23,
    Fiji: 3.83,
    Estonia: 1.93,
    "São Tomé and Príncipe": 3.98,
    Aruba: 3.42,
    "St. Lucia": 2.78,
    "Solomon Islands": 1.27,
    Belize: 2.55,
    Montenegro: 1.97,
    "Antigua and Barbuda": 3.06,
    Kosovo: 0.48,
    "St. Vincent and the Grenadines": 1.46,
    Liberia: 0.63,
    "St. Kitts and Nevis": 2.15,
    Grenada: 2.46,
    Dominica: 1.02,
    "San Marino": 0.29,
    Samoa: 0.99,
    Andorra: 1.02,
    Tonga: 0.38,
    "Timor-Leste": 0.01,
    "Brunei Darussalam": 0.5,
    Kiribati: 0.02,
    Micronesia: 0.09,
    "Marshall Islands": 0.06,
    Nauru: 0.12,
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2014": {
    Afghanistan: 102.93,
    Argentina: 2046.71,
    Ethiopia: 504.42,
    Lebanon: 100345,
    Syria: "n/a",
    Venezuela: 0.01,
    "Islamic Republic of Iran": 1264923.75,
    Indonesia: 2608775.69,
    Vietnam: 2154635.91,
    Korea: 620600,
    Japan: 1211567.9,
    Colombia: 330480.71,
    Uzbekistan: 11372.97,
    India: 83662.56,
    Iraq: 89912.75,
    "Lao P.D.R.": 57172.98,
    Nigeria: 15810.7,
    Paraguay: 28086.92,
    China: 25842.03,
    Guinea: 21647.99,
    Chile: 22221.91,
    Myanmar: 21709.87,
    Uganda: 22378.25,
    Tanzania: 29810.69,
    Cambodia: 21513.14,
    Sudan: 372.06,
    Pakistan: 15989.1,
    "Sierra Leone": 7961.61,
    Hungary: 25109.36,
    Angola: 5702.31,
    Mongolia: 12685.59,
    Madagascar: 11445.61,
    Kazakhstan: 5751.55,
    "United States": 18332.1,
    "Costa Rica": 10482.71,
    "Sri Lanka": 7482.47,
    Russia: 11961.85,
    "CÙte d'Ivoire": 6446.73,
    Algeria: 1319.42,
    Bangladesh: 4739.6,
    Yemen: 4525.99,
    Mexico: 8541.53,
    Philippines: 5314.46,
    Rwanda: 1591.76,
    Kenya: 2315.46,
    Senegal: 4141.27,
    Thailand: 5690.81,
    Malawi: 1212.61,
    Cameroon: 3720.28,
    Brazil: 3600.87,
    Egypt: 1813.2,
    "Democratic Republic of the Congo": 5582.56,
    "Burkina Faso": 1828.2,
    Mali: 1929.99,
    "South Africa": 1788.04,
    Gabon: 3061.68,
    Benin: 1461.56,
    Niger: 1180.72,
    Armenia: 1900.63,
    Burundi: 1589.07,
    Turkey: 585.41,
    "Republic of Congo": 3743.53,
    "Dominican Republic": 1312.24,
    "Czech Republic": 1818.89,
    Serbia: 2811.97,
    Nepal: 615.08,
    Togo: 1416.09,
    Ukraine: 1115.87,
    France: 2039.9,
    Chad: 2730.86,
    "United Kingdom": 1603.32,
    Italy: 2202.95,
    "Equatorial Guinea": 1349.8,
    Canada: 1707.22,
    Germany: 2216.2,
    Uruguay: 742.81,
    Zimbabwe: 8.23,
    Iceland: 2403.44,
    Sweden: 1791.62,
    Jamaica: 2163.1,
    Mozambique: 357.38,
    Norway: 937.81,
    Spain: 1039.39,
    Australia: 549.36,
    Poland: 873.91,
    Albania: 1004.51,
    Malaysia: 621.37,
    Israel: 729.34,
    Denmark: 877.07,
    Morocco: 586.13,
    "Taiwan Province of China": 6094.89,
    "Saudi Arabia": 44.3,
    Romania: 270.74,
    "South Sudan": 17.08,
    Singapore: 395.99,
    "Guinea-Bissau": 322.12,
    Haiti: 140.54,
    Zambia: 60.35,
    Guyana: 329.56,
    "Central African Republic": 582.3,
    "Kyrgyz Republic": 214.76,
    Ghana: 79.52,
    Belgium: 431.4,
    "United Arab Emirates": 210.15,
    Mauritius: 243.07,
    "North Macedonia": 200.58,
    Netherlands: 456.75,
    Peru: 118.56,
    Honduras: 153.64,
    Greece: 321.96,
    Croatia: 280.61,
    Austria: 279.04,
    Djibouti: 105.99,
    Nicaragua: 88.48,
    Switzerland: 280.07,
    Bolivia: 85.74,
    Ireland: 203.39,
    Bhutan: 108.37,
    Qatar: 187,
    "Cabo Verde": 179.03,
    Portugal: 230.06,
    Guatemala: 110.27,
    Mauritania: 96.74,
    "New Zealand": 82.33,
    Finland: 123.78,
    "Hong Kong SAR": 1.5,
    Comoros: 50.64,
    Suriname: 4.67,
    Namibia: 37.18,
    Tunisia: 43.3,
    Moldova: 46.67,
    "Trinidad and Tobago": 41.5,
    Maldives: 31.31,
    "The Gambia": 36.47,
    Belarus: 31.26,
    Vanuatu: 15.08,
    "Slovak Republic": 40.84,
    "Puerto Rico": 55.88,
    Turkmenistan: 20.86,
    Tajikistan: 12.72,
    Botswana: 25.31,
    "Papua New Guinea": 15.37,
    Ecuador: 27.54,
    Eritrea: 54.68,
    Eswatini: 6.78,
    Panama: 18.25,
    Slovenia: 30.22,
    Georgia: 9.62,
    Azerbaijan: 5.04,
    Bulgaria: 22.1,
    "El Salvador": 14.75,
    Jordan: 19.63,
    Kuwait: 1.59,
    Bahrain: 5.57,
    Lithuania: 14.83,
    Cyprus: 19.01,
    Luxembourg: 11.33,
    Seychelles: 12.45,
    Lesotho: 11.32,
    "West Bank and Gaza": 16.99,
    "Bosnia and Herzegovina": 12.52,
    Oman: 1.44,
    Latvia: 9.83,
    Barbados: 13.09,
    "The Bahamas": 5.16,
    Malta: 5.39,
    Fiji: 4.08,
    Estonia: 2.13,
    "São Tomé and Príncipe": 4.48,
    Aruba: 3.87,
    "St. Lucia": 2.95,
    "Solomon Islands": 1.03,
    Belize: 2.63,
    Montenegro: 2.19,
    "Antigua and Barbuda": 3.42,
    Kosovo: 0.6,
    "St. Vincent and the Grenadines": 1.56,
    Liberia: 0.78,
    "St. Kitts and Nevis": 1.86,
    Grenada: 2.5,
    Dominica: 1.03,
    "San Marino": 0.27,
    Samoa: 1.02,
    Andorra: 1.04,
    Tonga: 0.38,
    "Timor-Leste": 0.02,
    "Brunei Darussalam": 0.7,
    Kiribati: 0.02,
    Micronesia: 0.09,
    "Marshall Islands": 0.07,
    Nauru: 0.12,
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2015": {
    Afghanistan: 112.28,
    Argentina: 3129.85,
    Ethiopia: 706.81,
    Lebanon: 106008,
    Syria: "n/a",
    Venezuela: 0.01,
    "Islamic Republic of Iran": 4481215,
    Indonesia: 3113643.31,
    Vietnam: 2394030.82,
    Korea: 676200,
    Japan: 1228848.8,
    Colombia: 405717.94,
    Uzbekistan: 14883.96,
    India: 95092.82,
    Iraq: 117923.59,
    "Lao P.D.R.": 62204.56,
    Nigeria: 19348.06,
    Paraguay: 35081.14,
    China: 28714.15,
    Guinea: 29221.08,
    Chile: 27560.19,
    Myanmar: 25093.75,
    Uganda: 27351.93,
    Tanzania: 36996.08,
    Cambodia: 22876.08,
    Sudan: 471.9,
    Pakistan: 17376.95,
    "Sierra Leone": 9860.55,
    Hungary: 26489.81,
    Angola: 7964.65,
    Mongolia: 14378.59,
    Madagascar: 14635.81,
    Kazakhstan: 8946.24,
    "United States": 19093.85,
    "Costa Rica": 12004.11,
    "Sri Lanka": 8594.8,
    Russia: 12701.08,
    "CÙte d'Ivoire": 7978.57,
    Algeria: 1459.57,
    Bangladesh: 5105.39,
    Yemen: 5587.7,
    Mexico: 9801.39,
    Philippines: 5527.46,
    Rwanda: 1990.69,
    Kenya: 3054.89,
    Senegal: 4677.27,
    Thailand: 5783.32,
    Malawi: 1596.75,
    Cameroon: 6021.42,
    Brazil: 4351.32,
    Egypt: 2158.08,
    "Democratic Republic of the Congo": 5967.03,
    "Burkina Faso": 2194.65,
    Mali: 2375.95,
    "South Africa": 1998,
    Gabon: 3801.31,
    Benin: 2080.46,
    Niger: 1710.03,
    Armenia: 2225.91,
    Burundi: 1948.31,
    Turkey: 643.27,
    "Republic of Congo": 5215.32,
    "Dominican Republic": 1440.02,
    "Czech Republic": 1836.05,
    Serbia: 3073.7,
    Nepal: 623.61,
    Togo: 1777.8,
    Ukraine: 1580.95,
    France: 2101.3,
    Chad: 2840.93,
    "United Kingdom": 1664.7,
    Italy: 2239.36,
    "Equatorial Guinea": 2467.77,
    Canada: 1815.13,
    Germany: 2189.12,
    Uruguay: 919.69,
    Zimbabwe: 9.48,
    Iceland: 2246.63,
    Sweden: 1863.47,
    Jamaica: 2058.44,
    Mozambique: 557.57,
    Norway: 1074.73,
    Spain: 1070.08,
    Australia: 618.54,
    Poland: 923.42,
    Albania: 1057.32,
    Malaysia: 670.51,
    Israel: 744.55,
    Denmark: 809.93,
    Morocco: 629.2,
    "Taiwan Province of China": 6129.84,
    "Saudi Arabia": 142.3,
    Romania: 280.42,
    "South Sudan": 31.34,
    Singapore: 435.98,
    "Guinea-Bissau": 368.37,
    Haiti: 156.59,
    Zambia: 120.62,
    Guyana: 372.97,
    "Central African Republic": 599.45,
    "Kyrgyz Republic": 288.8,
    Ghana: 98.91,
    Belgium: 438.49,
    "United Arab Emirates": 218.99,
    Mauritius: 274.33,
    "North Macedonia": 212.83,
    Netherlands: 445.97,
    Peru: 146.65,
    Honduras: 170.88,
    Greece: 315.21,
    Croatia: 286.41,
    Austria: 290.57,
    Djibouti: 173.47,
    Nicaragua: 100.53,
    Switzerland: 281.51,
    Bolivia: 93.28,
    Ireland: 201.68,
    Bhutan: 120.74,
    Qatar: 209.28,
    "Cabo Verde": 200.99,
    Portugal: 235.75,
    Guatemala: 118.07,
    Mauritania: 117.62,
    "New Zealand": 86.1,
    Finland: 134.53,
    "Hong Kong SAR": 1.5,
    Comoros: 61.86,
    Suriname: 7.2,
    Namibia: 61.46,
    Tunisia: 49.56,
    Moldova: 61.77,
    "Trinidad and Tobago": 44.71,
    Maldives: 34.68,
    "The Gambia": 40.64,
    Belarus: 47.66,
    Vanuatu: 29.92,
    "Slovak Republic": 41.41,
    "Puerto Rico": 54.52,
    Turkmenistan: 27.69,
    Tajikistan: 16.95,
    Botswana: 26.16,
    "Papua New Guinea": 18,
    Ecuador: 33.56,
    Eritrea: 55.94,
    Eswatini: 10.18,
    Panama: 19.47,
    Slovenia: 32.09,
    Georgia: 12.44,
    Azerbaijan: 9.78,
    Bulgaria: 22.71,
    "El Salvador": 15.71,
    Jordan: 21.49,
    Kuwait: 1.6,
    Bahrain: 7.73,
    Lithuania: 15.94,
    Cyprus: 19.16,
    Luxembourg: 11.45,
    Seychelles: 12.32,
    Lesotho: 13.86,
    "West Bank and Gaza": 20.13,
    "Bosnia and Herzegovina": 13.02,
    Oman: 4.19,
    Latvia: 9.11,
    Barbados: 13.95,
    "The Bahamas": 5.64,
    Malta: 5.59,
    Fiji: 4.23,
    Estonia: 2.08,
    "São Tomé and Príncipe": 6.03,
    Aruba: 3.92,
    "St. Lucia": 2.98,
    "Solomon Islands": 0.93,
    Belize: 2.85,
    Montenegro: 2.51,
    "Antigua and Barbuda": 3.58,
    Kosovo: 0.76,
    "St. Vincent and the Grenadines": 1.62,
    Liberia: 0.8,
    "St. Kitts and Nevis": 1.67,
    Grenada: 2.43,
    Dominica: 1.04,
    "San Marino": 0.28,
    Samoa: 1.13,
    Andorra: 1.03,
    Tonga: 0.44,
    "Timor-Leste": 0.05,
    "Brunei Darussalam": 0.53,
    Kiribati: 0.05,
    Micronesia: 0.08,
    "Marshall Islands": 0.06,
    Nauru: 0.11,
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2016": {
    Afghanistan: 103.18,
    Argentina: 4365.88,
    Ethiopia: 860.43,
    Lebanon: 112890,
    Syria: "n/a",
    Venezuela: 0.01,
    "Islamic Republic of Iran": 5871993,
    Indonesia: 3466960.36,
    Vietnam: 2686426.65,
    Korea: 717500,
    Japan: 1265756,
    Colombia: 430131.62,
    Uzbekistan: 20923.19,
    India: 106114.93,
    Iraq: 132626.97,
    "Lao P.D.R.": 70423.91,
    Nigeria: 24013,
    Paraguay: 39681,
    China: 35984.6,
    Guinea: 33154.95,
    Chile: 35610.2,
    Myanmar: 28450.15,
    Uganda: 32397.32,
    Tanzania: 43125.13,
    Cambodia: 23661.02,
    Sudan: 817.82,
    Pakistan: 19668.56,
    "Sierra Leone": 14753.89,
    Hungary: 27100.85,
    Angola: 12521.84,
    Mongolia: 20971.52,
    Madagascar: 15160.71,
    Kazakhstan: 9242.68,
    "United States": 19990.16,
    "Costa Rica": 14122.56,
    "Sri Lanka": 9478.88,
    Russia: 12713.41,
    "CÙte d'Ivoire": 9023.24,
    Algeria: 3579.68,
    Bangladesh: 5775.85,
    Yemen: 6424.22,
    Mexico: 11418.02,
    Philippines: 5650.93,
    Rwanda: 2501.62,
    Kenya: 3548.98,
    Senegal: 5362.28,
    Thailand: 5988.39,
    Malawi: 2058.72,
    Cameroon: 6434.02,
    Brazil: 4908,
    Egypt: 2622.94,
    "Democratic Republic of the Congo": 7328.49,
    "Burkina Faso": 2528.59,
    Mali: 2986.45,
    "South Africa": 2243.37,
    Gabon: 5335.31,
    Benin: 2516.51,
    Niger: 2014.06,
    Armenia: 2631.39,
    Burundi: 2258.5,
    Turkey: 735.41,
    "Republic of Congo": 5482.86,
    "Dominican Republic": 1623.98,
    "Czech Republic": 1754.74,
    Serbia: 3114.14,
    Nepal: 650.93,
    Togo: 2149.86,
    Ukraine: 1896.67,
    France: 2188.5,
    Chad: 3104.87,
    "United Kingdom": 1730.92,
    Italy: 2285.65,
    "Equatorial Guinea": 2740.45,
    Canada: 1858.08,
    Germany: 2172.33,
    Uruguay: 980.26,
    Zimbabwe: 10.09,
    Iceland: 2069.26,
    Sweden: 1865.59,
    Jamaica: 2032.78,
    Mozambique: 902.34,
    Norway: 1181.19,
    Spain: 1104.55,
    Australia: 689.5,
    Poland: 1010.02,
    Albania: 1079.69,
    Malaysia: 697.18,
    Israel: 760.06,
    Denmark: 783.96,
    Morocco: 657.38,
    "Taiwan Province of China": 6208.23,
    "Saudi Arabia": 316.65,
    Romania: 297.45,
    "South Sudan": 170.95,
    Singapore: 479.23,
    "Guinea-Bissau": 420.33,
    Haiti: 182.16,
    Zambia: 131.72,
    Guyana: 409.16,
    "Central African Republic": 582.8,
    "Kyrgyz Republic": 281.38,
    Ghana: 122.85,
    Belgium: 451.61,
    "United Arab Emirates": 253.93,
    Mauritius: 290.1,
    "North Macedonia": 236.79,
    Netherlands: 438.37,
    Peru: 160.65,
    Honduras: 189.31,
    Greece: 319.57,
    Croatia: 283.75,
    Austria: 295.2,
    Djibouti: 212.6,
    Nicaragua: 117.56,
    Switzerland: 277.42,
    Bolivia: 109.03,
    Ireland: 200.64,
    Bhutan: 160.56,
    Qatar: 257.96,
    "Cabo Verde": 212.81,
    Portugal: 245.25,
    Guatemala: 125.28,
    Mauritania: 127.52,
    "New Zealand": 89.02,
    Finland: 137.42,
    "Hong Kong SAR": 1.5,
    Comoros: 73.27,
    Suriname: 15.45,
    Namibia: 72.28,
    Tunisia: 57.61,
    Moldova: 63.09,
    "Trinidad and Tobago": 56.25,
    Maldives: 41.91,
    "The Gambia": 52.12,
    Belarus: 50.78,
    Vanuatu: 36.93,
    "Slovak Republic": 42.48,
    "Puerto Rico": 52.04,
    Turkmenistan: 31.87,
    Tajikistan: 23.11,
    Botswana: 26.99,
    "Papua New Guinea": 21.94,
    Ecuador: 43.14,
    Eritrea: 55.89,
    Eswatini: 14.12,
    Panama: 20.44,
    Slovenia: 31.76,
    Georgia: 14.43,
    Azerbaijan: 12.45,
    Bulgaria: 25.75,
    "El Salvador": 16.64,
    Jordan: 21.93,
    Kuwait: 3.31,
    Bahrain: 9.86,
    Lithuania: 15.52,
    Cyprus: 19.51,
    Luxembourg: 11.03,
    Seychelles: 13.13,
    Lesotho: 12.61,
    "West Bank and Gaza": 18.36,
    "Bosnia and Herzegovina": 13.18,
    Oman: 8.48,
    Latvia: 10.25,
    Barbados: 14.55,
    "The Bahamas": 5.97,
    Malta: 5.74,
    Fiji: 4.62,
    Estonia: 2.17,
    "São Tomé and Príncipe": 7.34,
    Aruba: 4.03,
    "St. Lucia": 3.09,
    "Solomon Islands": 0.78,
    Belize: 3.16,
    Montenegro: 2.63,
    "Antigua and Barbuda": 3.34,
    Kosovo: 0.87,
    "St. Vincent and the Grenadines": 1.75,
    Liberia: 0.94,
    "St. Kitts and Nevis": 1.56,
    Grenada: 2.34,
    Dominica: 1.13,
    "San Marino": 0.32,
    Samoa: 1.08,
    Andorra: 1.04,
    Tonga: 0.46,
    "Timor-Leste": 0.08,
    "Brunei Darussalam": 0.47,
    Kiribati: 0.05,
    Micronesia: 0.08,
    "Marshall Islands": 0.06,
    Nauru: 0.11,
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2017": {
    Afghanistan: 102.81,
    Argentina: 6079.3,
    Ethiopia: 1057.94,
    Lebanon: 119898,
    Syria: "n/a",
    Venezuela: 0.52,
    "Islamic Republic of Iran": 5654349.86,
    Indonesia: 3994802.26,
    Vietnam: 2914618.42,
    Korea: 735200,
    Japan: 1279900,
    Colombia: 455046.92,
    Uzbekistan: 61191.51,
    India: 119077.74,
    Iraq: 134275.21,
    "Lao P.D.R.": 80476.63,
    Nigeria: 29115.98,
    Paraguay: 43488.57,
    China: 42885.58,
    Guinea: 39301.34,
    Chile: 42410.92,
    Myanmar: 31814.76,
    Uganda: 38374.84,
    Tanzania: 48367.49,
    Cambodia: 26921.75,
    Sudan: 1325.41,
    Pakistan: 21407.22,
    "Sierra Leone": 18993.61,
    Hungary: 28311.91,
    Angola: 14034.66,
    Mongolia: 23518.3,
    Madagascar: 16479.37,
    Kazakhstan: 10804.77,
    "United States": 20645.22,
    "Costa Rica": 16163.49,
    "Sri Lanka": 10382.83,
    Russia: 13143.74,
    "CÙte d'Ivoire": 10045.26,
    Algeria: 5060.39,
    Bangladesh: 6594.54,
    Yemen: 7747.28,
    Mexico: 11836.28,
    Philippines: 6309.47,
    Rwanda: 3178.9,
    Kenya: 4644.83,
    Senegal: 7453.99,
    Thailand: 6369.33,
    Malawi: 2713.01,
    Cameroon: 7658.82,
    Brazil: 5507.56,
    Egypt: 3575.48,
    "Democratic Republic of the Congo": 10683.85,
    "Burkina Faso": 2756.18,
    Mali: 3165.24,
    "South Africa": 2467.4,
    Gabon: 5451.59,
    Benin: 2920.51,
    Niger: 2371.03,
    Armenia: 2988.38,
    Burundi: 2572.62,
    Turkey: 878.29,
    "Republic of Congo": 6080.5,
    "Dominican Republic": 1857.2,
    "Czech Republic": 1749.68,
    Serbia: 2792.1,
    Nepal: 768.95,
    Togo: 2102.22,
    Ukraine: 2136.36,
    France: 2258.6,
    Chad: 2942.55,
    "United Kingdom": 1785.63,
    Italy: 2329.37,
    "Equatorial Guinea": 2567.09,
    Canada: 1901.77,
    Germany: 2122.86,
    Uruguay: 1041.1,
    Zimbabwe: 12,
    Iceland: 1892.65,
    Sweden: 1883.82,
    Jamaica: 1952.1,
    Mozambique: 837.06,
    Norway: 1273.21,
    Spain: 1145.1,
    Australia: 742.02,
    Poland: 1007.18,
    Albania: 1114.83,
    Malaysia: 746.48,
    Israel: 769.38,
    Denmark: 787.13,
    Morocco: 692.3,
    "Taiwan Province of China": 6206.7,
    "Saudi Arabia": 443.1,
    Romania: 316.02,
    "South Sudan": 241.06,
    Singapore: 517.09,
    "Guinea-Bissau": 426.94,
    Haiti: 187.1,
    Zambia: 163.31,
    Guyana: 423.88,
    "Central African Republic": 604.97,
    "Kyrgyz Republic": 311.85,
    Ghana: 149.75,
    Belgium: 453.98,
    "United Arab Emirates": 306.22,
    Mauritius: 302.12,
    "North Macedonia": 243.43,
    Netherlands: 420.3,
    Peru: 177.46,
    Honduras: 211.14,
    Greece: 323.09,
    Croatia: 284.92,
    Austria: 290.33,
    Djibouti: 235.62,
    Nicaragua: 141.19,
    Switzerland: 285.95,
    Bolivia: 132.86,
    Ireland: 201.27,
    Bhutan: 170.26,
    Qatar: 302.4,
    "Cabo Verde": 220.21,
    Portugal: 247.18,
    Guatemala: 132.12,
    Mauritania: 133.29,
    "New Zealand": 89.17,
    Finland: 138.42,
    "Hong Kong SAR": 1.5,
    Comoros: 80.9,
    Suriname: 19.22,
    Namibia: 75.2,
    Tunisia: 70.52,
    Moldova: 61.36,
    "Trinidad and Tobago": 64.36,
    Maldives: 47.23,
    "The Gambia": 61.01,
    Belarus: 56.22,
    Vanuatu: 49.11,
    "Slovak Republic": 43.57,
    "Puerto Rico": 52.97,
    Turkmenistan: 40.57,
    Tajikistan: 30.73,
    Botswana: 24.47,
    "Papua New Guinea": 23.56,
    Ecuador: 46.53,
    Eritrea: 58.13,
    Eswatini: 16.43,
    Panama: 21.93,
    Slovenia: 31.89,
    Georgia: 16.08,
    Azerbaijan: 15.83,
    Bulgaria: 23.53,
    "El Salvador": 17.62,
    Jordan: 22.35,
    Kuwait: 7.5,
    Bahrain: 11.76,
    Lithuania: 16.63,
    Cyprus: 18.81,
    Luxembourg: 12.69,
    Seychelles: 12.96,
    Lesotho: 12.21,
    "West Bank and Gaza": 18.45,
    "Bosnia and Herzegovina": 12.31,
    Oman: 12.47,
    Latvia: 10.52,
    Barbados: 15.84,
    "The Bahamas": 6.55,
    Malta: 5.68,
    Fiji: 4.91,
    Estonia: 2.17,
    "São Tomé and Príncipe": 7,
    Aruba: 4.2,
    "St. Lucia": 3.26,
    "Solomon Islands": 0.98,
    Belize: 3.54,
    Montenegro: 2.85,
    "Antigua and Barbuda": 3.65,
    Kosovo: 1.04,
    "St. Vincent and the Grenadines": 1.57,
    Liberia: 1.12,
    "St. Kitts and Nevis": 1.6,
    Grenada: 2.13,
    Dominica: 1.2,
    "San Marino": 0.83,
    Samoa: 1.05,
    Andorra: 1.01,
    Tonga: 0.46,
    "Timor-Leste": 0.11,
    "Brunei Darussalam": 0.47,
    Kiribati: 0.05,
    Micronesia: 0.08,
    "Marshall Islands": 0.05,
    Nauru: 0.11,
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2018": {
    Afghanistan: 98.04,
    Argentina: 12569.41,
    Ethiopia: 1344.42,
    Lebanon: 128347,
    Syria: "n/a",
    Venezuela: 1911.36,
    "Islamic Republic of Iran": 7359199,
    Indonesia: 4514353.69,
    Vietnam: 3049894.15,
    Korea: 759700,
    Japan: 1293192.1,
    Colombia: 529298.87,
    Uzbekistan: 82689.54,
    India: 133039.21,
    Iraq: 128261.75,
    "Lao P.D.R.": 90915.68,
    Nigeria: 35743.22,
    Paraguay: 51489.14,
    China: 49309.59,
    Guinea: 41954.67,
    Chile: 48870.46,
    Myanmar: 37501.06,
    Uganda: 44173.46,
    Tanzania: 52307.49,
    Cambodia: 28328.77,
    Sudan: 2543.28,
    Pakistan: 24950.46,
    "Sierra Leone": 22386.06,
    Hungary: 29962.64,
    Angola: 23832.49,
    Mongolia: 24073.67,
    Madagascar: 18534.91,
    Kazakhstan: 12523.74,
    "United States": 21976.1,
    "Costa Rica": 18669.83,
    "Sri Lanka": 12034.29,
    Russia: 14145.56,
    "CÙte d'Ivoire": 11607.77,
    Algeria: 7734.03,
    Bangladesh: 7778.79,
    Yemen: 8631.25,
    Mexico: 12620.62,
    Philippines: 6782.6,
    Rwanda: 3730.19,
    Kenya: 5356.12,
    Senegal: 7899.59,
    Thailand: 6780.95,
    Malawi: 3177.17,
    Cameroon: 8511.72,
    Brazil: 5998.5,
    Egypt: 4103.88,
    "Democratic Republic of the Congo": 11514.6,
    "Burkina Faso": 3387.89,
    Mali: 3424.08,
    "South Africa": 2763.99,
    Gabon: 5706.97,
    Benin: 3251.78,
    Niger: 2633.06,
    Armenia: 3082.68,
    Burundi: 2868.1,
    Turkey: 1133.99,
    "Republic of Congo": 5845.1,
    "Dominican Republic": 2135.74,
    "Czech Republic": 1734.6,
    Serbia: 2759.95,
    Nepal: 1039.58,
    Togo: 2251.92,
    Ukraine: 2151.29,
    France: 2314.9,
    Chad: 3005.77,
    "United Kingdom": 1837.13,
    Italy: 2380.98,
    "Equatorial Guinea": 2997.71,
    Canada: 1981.99,
    Germany: 2074.13,
    Uruguay: 1160.95,
    Zimbabwe: 14.46,
    Iceland: 1795.98,
    Sweden: 1878.59,
    Jamaica: 1938.01,
    Mozambique: 959.57,
    Norway: 1409.4,
    Spain: 1173.35,
    Australia: 791.01,
    Poland: 1035.72,
    Albania: 1136.96,
    Malaysia: 805.62,
    Israel: 809.75,
    Denmark: 766.17,
    Morocco: 722.69,
    "Taiwan Province of China": 6231.88,
    "Saudi Arabia": 559.8,
    Romania: 347.08,
    "South Sudan": 305.73,
    Singapore: 561.63,
    "Guinea-Bissau": 494.63,
    Haiti: 232,
    Zambia: 221.13,
    Guyana: 471.06,
    "Central African Republic": 633.25,
    "Kyrgyz Republic": 312.13,
    Ghana: 191.32,
    Belgium: 459.31,
    "United Arab Emirates": 324.17,
    Mauritius: 325.21,
    "North Macedonia": 267.16,
    Netherlands: 405.77,
    Peru: 193.72,
    Honduras: 228.51,
    Greece: 341.28,
    Croatia: 286.14,
    Austria: 285.32,
    Djibouti: 249.02,
    Nicaragua: 154.88,
    Switzerland: 282.41,
    Bolivia: 149.91,
    Ireland: 205.9,
    Bhutan: 180.56,
    Qatar: 348.2,
    "Cabo Verde": 230.71,
    Portugal: 249.26,
    Guatemala: 145.73,
    Mauritania: 154.39,
    "New Zealand": 85,
    Finland: 139.56,
    "Hong Kong SAR": 1.5,
    Comoros: 83.16,
    Suriname: 19.73,
    Namibia: 91.26,
    Tunisia: 85.08,
    Moldova: 60.12,
    "Trinidad and Tobago": 67.04,
    Maldives: 58.75,
    "The Gambia": 67.25,
    Belarus: 58.12,
    Vanuatu: 48.5,
    "Slovak Republic": 44.38,
    "Puerto Rico": 52.68,
    Turkmenistan: 44.91,
    Tajikistan: 32.92,
    Botswana: 27.27,
    "Papua New Guinea": 29.12,
    Ecuador: 52.8,
    Eritrea: 56.12,
    Eswatini: 21.21,
    Panama: 24.23,
    Slovenia: 32.24,
    Georgia: 17.34,
    Azerbaijan: 14.97,
    Bulgaria: 22.07,
    "El Salvador": 18.31,
    Jordan: 22.88,
    Kuwait: 6.3,
    Bahrain: 13.45,
    Lithuania: 15.32,
    Cyprus: 21.26,
    Luxembourg: 12.58,
    Seychelles: 12.73,
    Lesotho: 15.82,
    "West Bank and Gaza": 19.37,
    "Bosnia and Herzegovina": 11.46,
    Oman: 15.72,
    Latvia: 10.82,
    Barbados: 12.95,
    "The Bahamas": 7.78,
    Malta: 5.64,
    Fiji: 5.41,
    Estonia: 2.13,
    "São Tomé and Príncipe": 8.09,
    Aruba: 4.3,
    "St. Lucia": 3.37,
    "Solomon Islands": 1.04,
    Belize: 3.61,
    Montenegro: 3.35,
    "Antigua and Barbuda": 3.8,
    Kosovo: 1.14,
    "St. Vincent and the Grenadines": 1.66,
    Liberia: 1.31,
    "St. Kitts and Nevis": 1.56,
    Grenada: 2.03,
    Dominica: 1.35,
    "San Marino": 0.89,
    Samoa: 1.11,
    Andorra: 0.99,
    Tonga: 0.49,
    "Timor-Leste": 0.15,
    "Brunei Darussalam": 0.47,
    Kiribati: 0.05,
    Micronesia: 0.08,
    "Marshall Islands": 0.05,
    Nauru: 0.12,
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2019": {
    Afghanistan: 90.09,
    Argentina: 19344.08,
    Ethiopia: 1558.41,
    Lebanon: 138150,
    Syria: "n/a",
    Venezuela: 320039.47,
    "Islamic Republic of Iran": 11684887,
    Indonesia: 4839007.01,
    Vietnam: 3318942.2,
    Korea: 810700,
    Japan: 1318186.7,
    Colombia: 555485.75,
    Uzbekistan: 149652.43,
    India: 150771.8,
    Iraq: 124821.73,
    "Lao P.D.R.": 99680.86,
    Nigeria: 42483.63,
    Paraguay: 60987.91,
    China: 56586.74,
    Guinea: 47624.81,
    Chile: 55393.44,
    Myanmar: 40790.59,
    Uganda: 51755.16,
    Tanzania: 54608.67,
    Cambodia: 31450.68,
    Sudan: 4066.58,
    Pakistan: 32487.39,
    "Sierra Leone": 26611.47,
    Hungary: 31122.42,
    Angola: 35012.67,
    Mongolia: 25514.31,
    Madagascar: 19668.96,
    Kazakhstan: 13867.4,
    "United States": 23181.39,
    "Costa Rica": 21346.97,
    "Sri Lanka": 13031.54,
    Russia: 15068.42,
    "CÙte d'Ivoire": 13300.23,
    Algeria: 9350.18,
    Bangladesh: 9075.16,
    Yemen: 9647.2,
    Mexico: 13038.75,
    Philippines: 7215.07,
    Rwanda: 4676.38,
    Kenya: 6048.64,
    Senegal: 8717.95,
    Thailand: 6901.8,
    Malawi: 3723.74,
    Cameroon: 9668.75,
    Brazil: 6492.84,
    Egypt: 4481.95,
    "Democratic Republic of the Congo": 12459.45,
    "Burkina Faso": 3936.65,
    Mali: 4106.01,
    "South Africa": 3155.82,
    Gabon: 5910.21,
    Benin: 3476.59,
    Niger: 3011.04,
    Armenia: 3277.28,
    Burundi: 3354.12,
    Turkey: 1410.33,
    "Republic of Congo": 6122.1,
    "Dominican Republic": 2442.5,
    "Czech Republic": 1739.93,
    Serbia: 2858.87,
    Nepal: 1278.62,
    Togo: 2214.77,
    Ukraine: 2008.18,
    France: 2379.5,
    Chad: 3351.58,
    "United Kingdom": 1891.06,
    Italy: 2409.94,
    "Equatorial Guinea": 2875.72,
    Canada: 2006.17,
    Germany: 2057.63,
    Uruguay: 1305.07,
    Zimbabwe: 181.87,
    Iceland: 2015.89,
    Sweden: 1760.95,
    Jamaica: 1999.33,
    Mozambique: 1014.85,
    Norway: 1458.79,
    Spain: 1188.82,
    Australia: 933.1,
    Poland: 1045.65,
    Albania: 1138.4,
    Malaysia: 863.51,
    Israel: 844.04,
    Denmark: 778.13,
    Morocco: 750.12,
    "Taiwan Province of China": 6188,
    "Saudi Arabia": 677.7,
    Romania: 389.66,
    "South Sudan": 226.69,
    Singapore: 656.26,
    "Guinea-Bissau": 555.6,
    Haiti: 320.89,
    Zambia: 292.59,
    Guyana: 473.25,
    "Central African Republic": 629.36,
    "Kyrgyz Republic": 319.48,
    Ghana: 223.16,
    Belgium: 467.17,
    "United Arab Emirates": 415.02,
    Mauritius: 387.18,
    "North Macedonia": 280.18,
    Netherlands: 385.66,
    Peru: 208.62,
    Honduras: 266.43,
    Greece: 339.15,
    Croatia: 293.02,
    Austria: 280.34,
    Djibouti: 231.79,
    Nicaragua: 174.14,
    Switzerland: 289.66,
    Bolivia: 167.11,
    Ireland: 204.22,
    Bhutan: 184.18,
    Qatar: 398.6,
    "Cabo Verde": 243.85,
    Portugal: 249.98,
    Guatemala: 157,
    Mauritania: 164.51,
    "New Zealand": 102.23,
    Finland: 142.87,
    "Hong Kong SAR": 7.75,
    Comoros: 101.88,
    Suriname: 26.84,
    Namibia: 107.47,
    Tunisia: 85.25,
    Moldova: 59.53,
    "Trinidad and Tobago": 73.26,
    Maldives: 67.98,
    "The Gambia": 75.33,
    Belarus: 55.24,
    Vanuatu: 48.55,
    "Slovak Republic": 45.28,
    "Puerto Rico": 52.71,
    Turkmenistan: 52.15,
    Tajikistan: 34.09,
    Botswana: 28.77,
    "Papua New Guinea": 33.68,
    Ecuador: 55.58,
    Eritrea: 56.57,
    Eswatini: 25.95,
    Panama: 28.18,
    Slovenia: 31.74,
    Georgia: 19.92,
    Azerbaijan: 14.46,
    Bulgaria: 22.04,
    "El Salvador": 19.17,
    Jordan: 24.65,
    Kuwait: 4.81,
    Bahrain: 14.77,
    Lithuania: 17.52,
    Cyprus: 20.96,
    Luxembourg: 13.98,
    Seychelles: 12.81,
    Lesotho: 16.68,
    "West Bank and Gaza": 21.05,
    "Bosnia and Herzegovina": 11.48,
    Oman: 17.76,
    Latvia: 11.25,
    Barbados: 12.67,
    "The Bahamas": 7.88,
    Malta: 5.7,
    Fiji: 5.81,
    Estonia: 2.37,
    "São Tomé and Príncipe": 6.75,
    Aruba: 4.32,
    "St. Lucia": 3.51,
    "Solomon Islands": 1.07,
    Belize: 3.74,
    Montenegro: 3.9,
    "Antigua and Barbuda": 3.7,
    Kosovo: 1.25,
    "St. Vincent and the Grenadines": 1.67,
    Liberia: 1.69,
    "St. Kitts and Nevis": 1.62,
    Grenada: 1.94,
    Dominica: 1.48,
    "San Marino": 0.91,
    Samoa: 1.06,
    Andorra: 1,
    Tonga: 0.48,
    "Timor-Leste": 0.19,
    "Brunei Darussalam": 0.47,
    Kiribati: 0.05,
    Micronesia: 0.08,
    "Marshall Islands": 0.06,
    Nauru: 0.1,
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2020": {
    Afghanistan: 114.46,
    Argentina: 28248.09,
    Ethiopia: 1870.22,
    Lebanon: 144108,
    Syria: "n/a",
    Venezuela: 7185788.36,
    "Islamic Republic of Iran": 13867812.45,
    Indonesia: 5652145.78,
    Vietnam: 3688239.42,
    Korea: 925523.38,
    Japan: 1368968.63,
    Colombia: 655736.41,
    Uzbekistan: 219548.66,
    India: 176936.83,
    Iraq: 169961.01,
    "Lao P.D.R.": 116224.86,
    Nigeria: 53987.98,
    Paraguay: 88526.4,
    China: 68052.98,
    Guinea: 64438.13,
    Chile: 65167.4,
    Myanmar: 45237.66,
    Uganda: 63457.2,
    Tanzania: 58144.36,
    Cambodia: 35440.27,
    Sudan: 14115.89,
    Pakistan: 36387.99,
    "Sierra Leone": 30484.94,
    Hungary: 38408.02,
    Angola: 46090.59,
    Mongolia: 28441.05,
    Madagascar: 22970.71,
    Kazakhstan: 18621.06,
    "United States": 27980.86,
    "Costa Rica": 24419.54,
    "Sri Lanka": 15157.97,
    Russia: 20623.13,
    "CÙte d'Ivoire": 16802.31,
    Algeria: 10411.21,
    Bangladesh: 10658.33,
    Yemen: 11782.03,
    Mexico: 14081.64,
    Philippines: 9269.84,
    Rwanda: 5858.66,
    Kenya: 7266.04,
    Senegal: 9742.72,
    Thailand: 7892.28,
    Malawi: 4823.6,
    Cameroon: 10511.22,
    Brazil: 7369.16,
    Egypt: 5248.84,
    "Democratic Republic of the Congo": 13670.88,
    "Burkina Faso": 4648.69,
    Mali: 4768.17,
    "South Africa": 3834.25,
    Gabon: 6823.97,
    Benin: 4156.85,
    Niger: 3556.8,
    Armenia: 3923.89,
    Burundi: 3899.81,
    Turkey: 2006.91,
    "Republic of Congo": 5998.85,
    "Dominican Republic": 3184.68,
    "Czech Republic": 2153.03,
    Serbia: 3188.99,
    Nepal: 1650.4,
    Togo: 2628.13,
    Ukraine: 2547.98,
    France: 2650.1,
    Chad: 2983.7,
    "United Kingdom": 2206.45,
    Italy: 2573.39,
    "Equatorial Guinea": 2818.67,
    Canada: 2589.87,
    Germany: 2325.46,
    Uruguay: 1533.39,
    Zimbabwe: 968.86,
    Iceland: 2266.88,
    Sweden: 1861.06,
    Jamaica: 2124.39,
    Mozambique: 1251.77,
    Norway: 1413.17,
    Spain: 1345.44,
    Australia: 1128.82,
    Poland: 1335.57,
    Albania: 1247.28,
    Malaysia: 955.27,
    Israel: 1008.78,
    Denmark: 981.35,
    Morocco: 821.2,
    "Taiwan Province of China": 6453.92,
    "Saudi Arabia": 854.25,
    Romania: 525.69,
    "South Sudan": 263.76,
    Singapore: 729,
    "Guinea-Bissau": 653.45,
    Haiti: 309.51,
    Zambia: 456.12,
    Guyana: 586.05,
    "Central African Republic": 605.56,
    "Kyrgyz Republic": 407.12,
    Ghana: 302.66,
    Belgium: 514.97,
    "United Arab Emirates": 518.76,
    Mauritius: 429.29,
    "North Macedonia": 340.3,
    Netherlands: 419.99,
    Peru: 251.89,
    Honduras: 300.71,
    Greece: 350.26,
    Croatia: 329.68,
    Austria: 315.16,
    Djibouti: 249.91,
    Nicaragua: 207.7,
    Switzerland: 299.17,
    Bolivia: 199.22,
    Ireland: 218.2,
    Bhutan: 215.37,
    Qatar: 381.7,
    "Cabo Verde": 260.75,
    Portugal: 270.49,
    Guatemala: 188.91,
    Mauritania: 174.25,
    "New Zealand": 140.57,
    Finland: 164.27,
    "Hong Kong SAR": 27.22,
    Comoros: 116.91,
    Suriname: 56.86,
    Namibia: 116.69,
    Tunisia: 98.98,
    Moldova: 71.77,
    "Trinidad and Tobago": 88.01,
    Maldives: 84.13,
    "The Gambia": 80.19,
    Belarus: 70.63,
    Vanuatu: 49.5,
    "Slovak Republic": 55.18,
    "Puerto Rico": 54.28,
    Turkmenistan: 51.48,
    Tajikistan: 42.35,
    Botswana: 34.65,
    "Papua New Guinea": 39.85,
    Ecuador: 60.46,
    Eritrea: 58.09,
    Eswatini: 27.31,
    Panama: 35.09,
    Slovenia: 37.43,
    Georgia: 29.65,
    Azerbaijan: 15.48,
    Bulgaria: 27.95,
    "El Salvador": 21.97,
    Jordan: 27.3,
    Kuwait: 3.8,
    Bahrain: 16.94,
    Lithuania: 23.06,
    Cyprus: 24.83,
    Luxembourg: 15.92,
    Seychelles: 19.32,
    Lesotho: 16.48,
    "West Bank and Gaza": 25.28,
    "Bosnia and Herzegovina": 12.48,
    Oman: 19.77,
    Latvia: 12.75,
    Barbados: 13.29,
    "The Bahamas": 8.49,
    Malta: 6.96,
    Fiji: 6.9,
    Estonia: 4.95,
    "São Tomé and Príncipe": 8.34,
    Aruba: 5.15,
    "St. Lucia": 4.08,
    "Solomon Islands": 1.78,
    Belize: 4.21,
    Montenegro: 4.49,
    "Antigua and Barbuda": 3.75,
    Kosovo: 1.64,
    "St. Vincent and the Grenadines": 1.85,
    Liberia: 1.88,
    "St. Kitts and Nevis": 1.51,
    Grenada: 1.98,
    Dominica: 1.64,
    "San Marino": 1.05,
    Samoa: 1.01,
    Andorra: 1.17,
    Tonga: 0.49,
    "Timor-Leste": 0.22,
    "Brunei Darussalam": 0.47,
    Kiribati: 0.05,
    Micronesia: 0.07,
    "Marshall Islands": 0.05,
    Nauru: 0.1,
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2021": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: 2329.3,
    Lebanon: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    "Islamic Republic of Iran": 17151505.27,
    Indonesia: 6811991.28,
    Vietnam: 4019075.56,
    Korea: 1061275.23,
    Japan: 1421696.68,
    Colombia: 743492.05,
    Uzbekistan: 273398.38,
    India: 203498.82,
    Iraq: 173543.74,
    "Lao P.D.R.": 129454.52,
    Nigeria: 65199.2,
    Paraguay: 98013.17,
    China: 77895.09,
    Guinea: 81775.96,
    Chile: 82856.56,
    Myanmar: 57838.69,
    Uganda: 77048.12,
    Tanzania: 63606.84,
    Cambodia: 40044.22,
    Sudan: 32743.12,
    Pakistan: 39797.42,
    "Sierra Leone": 33893.73,
    Hungary: 40955.82,
    Angola: 47857.56,
    Mongolia: 32320.8,
    Madagascar: 26585.89,
    Kazakhstan: 20781.94,
    "United States": 30574.78,
    "Costa Rica": 26962.13,
    "Sri Lanka": 17768.52,
    Russia: 21813.21,
    "CÙte d'Ivoire": 18983.97,
    Algeria: 13035.91,
    Bangladesh: 12047,
    Yemen: 14377.32,
    Mexico: 15467.3,
    Philippines: 11248.53,
    Rwanda: 7810.95,
    Kenya: 8350.65,
    Senegal: 10885.31,
    Thailand: 9293.75,
    Malawi: 5800.5,
    Cameroon: 11269.79,
    Brazil: 7874.27,
    Egypt: 5716.18,
    "Democratic Republic of the Congo": 13025.04,
    "Burkina Faso": 5256.67,
    Mali: 5477.81,
    "South Africa": 4230.63,
    Gabon: 7244.17,
    Benin: 5167.89,
    Niger: 4156.11,
    Armenia: 4369.9,
    Burundi: 4538.5,
    Turkey: 2504.62,
    "Republic of Congo": 5959.98,
    "Dominican Republic": 3394.74,
    "Czech Republic": 2678.93,
    Serbia: 3583.05,
    Nepal: 1926.81,
    Togo: 2923.36,
    Ukraine: 2710.16,
    France: 2851.94,
    Chad: 2983.51,
    "United Kingdom": 2425.54,
    Italy: 2747.22,
    "Equatorial Guinea": 2880.01,
    Canada: 2771.09,
    Germany: 2567.76,
    Uruguay: 1738.15,
    Zimbabwe: 1187.21,
    Iceland: 2427.89,
    Sweden: 2099.68,
    Jamaica: 2139.7,
    Mozambique: 1410.98,
    Norway: 1644.29,
    Spain: 1449.48,
    Australia: 1324.82,
    Poland: 1405.12,
    Albania: 1400.64,
    Malaysia: 1066.01,
    Israel: 1112.15,
    Denmark: 957.85,
    Morocco: 881.88,
    "Taiwan Province of China": 5980.45,
    "Saudi Arabia": 937.83,
    Romania: 604.93,
    "South Sudan": 696.98,
    Singapore: 698.69,
    "Guinea-Bissau": 691.85,
    Haiti: 417.23,
    Zambia: 460.72,
    Guyana: 720.66,
    "Central African Republic": 647.5,
    "Kyrgyz Republic": 466.33,
    Ghana: 373.15,
    Belgium: 552.53,
    "United Arab Emirates": 562.15,
    Mauritius: 490,
    "North Macedonia": 379.67,
    Netherlands: 489.79,
    Peru: 298.41,
    Honduras: 378.13,
    Greece: 366.26,
    Croatia: 346.01,
    Austria: 339.26,
    Djibouti: 274.59,
    Nicaragua: 234.73,
    Switzerland: 314.51,
    Bolivia: 218.81,
    Ireland: 247.89,
    Bhutan: 226.41,
    Qatar: 363.41,
    "Cabo Verde": 279.7,
    Portugal: 275.63,
    Guatemala: 208.67,
    Mauritania: 186.03,
    "New Zealand": 181.68,
    Finland: 179.01,
    "Hong Kong SAR": 62.32,
    Comoros: 140.18,
    Suriname: 82.47,
    Namibia: 132.51,
    Tunisia: 108.51,
    Moldova: 85.12,
    "Trinidad and Tobago": 102.79,
    Maldives: 96.7,
    "The Gambia": 86.95,
    Belarus: 75.16,
    Vanuatu: 49.29,
    "Slovak Republic": 59.98,
    "Puerto Rico": 55.8,
    Turkmenistan: 50.22,
    Tajikistan: 45.47,
    Botswana: 45.52,
    "Papua New Guinea": 42.16,
    Ecuador: 63.69,
    Eritrea: 59.5,
    Eswatini: 32.12,
    Panama: 37.39,
    Slovenia: 39.35,
    Georgia: 31.22,
    Azerbaijan: 25.85,
    Bulgaria: 31.85,
    "El Salvador": 23.3,
    Jordan: 29.22,
    Kuwait: 3.18,
    Bahrain: 18.14,
    Lithuania: 24.87,
    Cyprus: 24.68,
    Luxembourg: 18.42,
    Seychelles: 19.31,
    Lesotho: 17.72,
    "West Bank and Gaza": 25.28,
    "Bosnia and Herzegovina": 13.83,
    Oman: 21.14,
    Latvia: 14.81,
    Barbados: 13.54,
    "The Bahamas": 10.14,
    Malta: 8.81,
    Fiji: 8.21,
    Estonia: 6.02,
    "São Tomé and Príncipe": 6.53,
    Aruba: 6.02,
    "St. Lucia": 4.61,
    "Solomon Islands": 2.73,
    Belize: 4.5,
    Montenegro: 4.3,
    "Antigua and Barbuda": 3.99,
    Kosovo: 1.93,
    "St. Vincent and the Grenadines": 2.1,
    Liberia: 1.92,
    "St. Kitts and Nevis": 1.63,
    Grenada: 2.05,
    Dominica: 1.74,
    "San Marino": 1.37,
    Samoa: 0.94,
    Andorra: 1.38,
    Tonga: 0.51,
    "Timor-Leste": 0.28,
    "Brunei Darussalam": 0.47,
    Kiribati: 0.06,
    Micronesia: 0.06,
    "Marshall Islands": 0.04,
    Nauru: 0.05,
    Tuvalu: 0.01,
    "Macao SAR": 0
  },
  "2022": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    "Islamic Republic of Iran": 21952978.95,
    Indonesia: 7774628.88,
    Vietnam: 4459426.08,
    Korea: 1200938.2,
    Japan: 1448989.59,
    Colombia: 805541.25,
    Uzbekistan: 339021.94,
    India: 227737.22,
    Iraq: 181868.62,
    "Lao P.D.R.": 142388.7,
    Nigeria: 77824.64,
    Paraguay: 106588.09,
    China: 87773,
    Guinea: 91898.91,
    Chile: 96250.22,
    Myanmar: 67455.48,
    Uganda: 86736.43,
    Tanzania: 69344.75,
    Cambodia: 45223.21,
    Sudan: 40370.32,
    Pakistan: 43690.77,
    "Sierra Leone": 38764.79,
    Hungary: 44163.36,
    Angola: 48172.68,
    Mongolia: 33963.65,
    Madagascar: 29917.89,
    Kazakhstan: 23839.02,
    "United States": 32397.12,
    "Costa Rica": 29100.52,
    "Sri Lanka": 19841.96,
    Russia: 22827.86,
    "CÙte d'Ivoire": 20834.34,
    Algeria: 15531.94,
    Bangladesh: 13893.17,
    Yemen: 16283.02,
    Mexico: 16624.63,
    Philippines: 12920.23,
    Rwanda: 9202.78,
    Kenya: 9355.11,
    Senegal: 11440.07,
    Thailand: 9922.88,
    Malawi: 7116.62,
    Cameroon: 11479.73,
    Brazil: 8467.2,
    Egypt: 6279.83,
    "Democratic Republic of the Congo": 12391.42,
    "Burkina Faso": 5769.61,
    Mali: 5840.48,
    "South Africa": 4675.26,
    Gabon: 6797.14,
    Benin: 5246.33,
    Niger: 4603.06,
    Armenia: 4665.72,
    Burundi: 4866.1,
    Turkey: 2922.69,
    "Republic of Congo": 5514.04,
    "Dominican Republic": 3607.24,
    "Czech Republic": 3048.78,
    Serbia: 3740.38,
    Nepal: 2402.06,
    Togo: 3154.12,
    Ukraine: 2888.75,
    France: 2950.13,
    Chad: 3115.17,
    "United Kingdom": 2560.51,
    Italy: 2830.23,
    "Equatorial Guinea": 2915.51,
    Canada: 2828.68,
    Germany: 2634.66,
    Uruguay: 1931.78,
    Zimbabwe: 1615.81,
    Iceland: 2591.65,
    Sweden: 2228.89,
    Jamaica: 2131.24,
    Mozambique: 1497.92,
    Norway: 1717.34,
    Spain: 1514.79,
    Australia: 1490.51,
    Poland: 1459.9,
    Albania: 1450.08,
    Malaysia: 1165.52,
    Israel: 1182.05,
    Denmark: 994.17,
    Morocco: 929.37,
    "Taiwan Province of China": 5220.22,
    "Saudi Arabia": 1013.36,
    Romania: 676.38,
    "South Sudan": 762.77,
    Singapore: 738.86,
    "Guinea-Bissau": 729.59,
    Haiti: 490.64,
    Zambia: 550.18,
    Guyana: 734.08,
    "Central African Republic": 654.53,
    "Kyrgyz Republic": 495.77,
    Ghana: 433.37,
    Belgium: 578.63,
    "United Arab Emirates": 607.37,
    Mauritius: 528.43,
    "North Macedonia": 411.4,
    Netherlands: 498.74,
    Peru: 337.5,
    Honduras: 407.16,
    Greece: 371.43,
    Croatia: 357.47,
    Austria: 349.25,
    Djibouti: 281.86,
    Nicaragua: 244.46,
    Switzerland: 316.81,
    Bolivia: 235.26,
    Ireland: 267.84,
    Bhutan: 244.28,
    Qatar: 349.49,
    "Cabo Verde": 286.52,
    Portugal: 282.36,
    Guatemala: 224.63,
    Mauritania: 198.91,
    "New Zealand": 215.9,
    Finland: 187.94,
    "Hong Kong SAR": 97.42,
    Comoros: 165.99,
    Suriname: 105.86,
    Namibia: 148.88,
    Tunisia: 122.76,
    Moldova: 98.17,
    "Trinidad and Tobago": 111.38,
    Maldives: 108.81,
    "The Gambia": 93.09,
    Belarus: 84.17,
    Vanuatu: 55.07,
    "Slovak Republic": 65.5,
    "Puerto Rico": 58.19,
    Turkmenistan: 49.69,
    Tajikistan: 49.03,
    Botswana: 60.22,
    "Papua New Guinea": 48.67,
    Ecuador: 65.89,
    Eritrea: 59.66,
    Eswatini: 37.75,
    Panama: 39.37,
    Slovenia: 40.59,
    Georgia: 34.61,
    Azerbaijan: 26.9,
    Bulgaria: 35.57,
    "El Salvador": 24.76,
    Jordan: 30.52,
    Kuwait: 4.57,
    Bahrain: 19.4,
    Lithuania: 25.47,
    Cyprus: 24.29,
    Luxembourg: 19.83,
    Seychelles: 21.71,
    Lesotho: 18.97,
    "West Bank and Gaza": 24.05,
    "Bosnia and Herzegovina": 14.91,
    Oman: 20.34,
    Latvia: 15.94,
    Barbados: 13.5,
    "The Bahamas": 10.91,
    Malta: 9.85,
    Fiji: 9.2,
    Estonia: 7,
    "São Tomé and Príncipe": 6.67,
    Aruba: 6.42,
    "St. Lucia": 5.07,
    "Solomon Islands": 3.18,
    Belize: 4.7,
    Montenegro: 4.12,
    "Antigua and Barbuda": 4.1,
    Kosovo: 2.27,
    "St. Vincent and the Grenadines": 2.17,
    Liberia: 2.01,
    "St. Kitts and Nevis": 1.7,
    Grenada: 2.17,
    Dominica: 1.82,
    "San Marino": 1.4,
    Samoa: 1.01,
    Andorra: 1.33,
    Tonga: 0.54,
    "Timor-Leste": 0.33,
    "Brunei Darussalam": 0.47,
    Kiribati: 0.09,
    Micronesia: 0.06,
    "Marshall Islands": 0.04,
    Nauru: 0.08,
    Tuvalu: 0,
    "Macao SAR": 0
  },
  "2023": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    "Islamic Republic of Iran": 28619524.93,
    Indonesia: 8440321.27,
    Vietnam: 4967456.51,
    Korea: 1332903.01,
    Japan: 1466958.15,
    Colombia: 882978.91,
    Uzbekistan: 383764.61,
    India: 252162.38,
    Iraq: 192386.6,
    "Lao P.D.R.": 155117.63,
    Nigeria: 91038.37,
    Paraguay: 114217.71,
    China: 97386.99,
    Guinea: 100023.04,
    Chile: 107425.93,
    Myanmar: 77906.59,
    Uganda: 95155.67,
    Tanzania: 74654.19,
    Cambodia: 50767.92,
    Sudan: 44241.76,
    Pakistan: 46485.58,
    "Sierra Leone": 43299.64,
    Hungary: 46069.68,
    Angola: 48902.07,
    Mongolia: 36261.96,
    Madagascar: 33493.39,
    Kazakhstan: 27132.11,
    "United States": 34002.96,
    "Costa Rica": 30728.81,
    "Sri Lanka": 22050.79,
    Russia: 23826.16,
    "CÙte d'Ivoire": 22515.04,
    Algeria: 17997.97,
    Bangladesh: 15764.19,
    Yemen: 17410.02,
    Mexico: 17725.57,
    Philippines: 14409.54,
    Rwanda: 10896.98,
    Kenya: 10289.93,
    Senegal: 11868.77,
    Thailand: 10579.61,
    Malawi: 8414.52,
    Cameroon: 11658.76,
    Brazil: 9135.81,
    Egypt: 6869.32,
    "Democratic Republic of the Congo": 11486.23,
    "Burkina Faso": 6241.95,
    Mali: 6204.64,
    "South Africa": 5135.44,
    Gabon: 6807.93,
    Benin: 5373.36,
    Niger: 4966.16,
    Armenia: 4913.18,
    Burundi: 5064.15,
    Turkey: 3465.71,
    "Republic of Congo": 5366.21,
    "Dominican Republic": 3841.83,
    "Czech Republic": 3390.4,
    Serbia: 3830,
    Nepal: 2827.53,
    Togo: 3359.34,
    Ukraine: 3015.95,
    France: 3068.73,
    Chad: 3148.12,
    "United Kingdom": 2686.26,
    Italy: 2889.83,
    "Equatorial Guinea": 2868.66,
    Canada: 2839.99,
    Germany: 2648.82,
    Uruguay: 2085.31,
    Zimbabwe: 1924.61,
    Iceland: 2672.3,
    Sweden: 2275.49,
    Jamaica: 2094.81,
    Mozambique: 1608.24,
    Norway: 1750.3,
    Spain: 1574.94,
    Australia: 1578.25,
    Poland: 1515.24,
    Albania: 1529.2,
    Malaysia: 1272.56,
    Israel: 1245.46,
    Denmark: 1038.75,
    Morocco: 977.46,
    "Taiwan Province of China": 4297.46,
    "Saudi Arabia": 1026.36,
    Romania: 753.09,
    "South Sudan": 780.21,
    Singapore: 778.95,
    "Guinea-Bissau": 767.95,
    Haiti: 573.76,
    Zambia: 631.29,
    Guyana: 751.01,
    "Central African Republic": 676.69,
    "Kyrgyz Republic": 534.77,
    Ghana: 495.21,
    Belgium: 605.77,
    "United Arab Emirates": 632.86,
    Mauritius: 571.47,
    "North Macedonia": 439.36,
    Netherlands: 504.43,
    Peru: 375.18,
    Honduras: 415.39,
    Greece: 370.96,
    Croatia: 365.11,
    Austria: 355.46,
    Djibouti: 299.05,
    Nicaragua: 262.86,
    Switzerland: 317.68,
    Bolivia: 252.87,
    Ireland: 279.55,
    Bhutan: 265.18,
    Qatar: 317.1,
    "Cabo Verde": 292.01,
    Portugal: 287.52,
    Guatemala: 244.08,
    Mauritania: 212.78,
    "New Zealand": 233.28,
    Finland: 198.08,
    "Hong Kong SAR": 132.52,
    Comoros: 190.36,
    Suriname: 130.67,
    Namibia: 157.85,
    Tunisia: 136.28,
    Moldova: 115.08,
    "Trinidad and Tobago": 119.5,
    Maldives: 119.49,
    "The Gambia": 97.39,
    Belarus: 81.47,
    Vanuatu: 60.08,
    "Slovak Republic": 67.51,
    "Puerto Rico": 61.25,
    Turkmenistan: 51.53,
    Tajikistan: 53.38,
    Botswana: 64.98,
    "Papua New Guinea": 52.64,
    Ecuador: 66.04,
    Eritrea: 59.74,
    Eswatini: 41.4,
    Panama: 41.8,
    Slovenia: 41.82,
    Georgia: 36.56,
    Azerbaijan: 29.43,
    Bulgaria: 38.9,
    "El Salvador": 26.59,
    Jordan: 31.46,
    Kuwait: 9.23,
    Bahrain: 20.74,
    Lithuania: 25.89,
    Cyprus: 24.65,
    Luxembourg: 20.84,
    Seychelles: 22.9,
    Lesotho: 20.44,
    "West Bank and Gaza": 22.76,
    "Bosnia and Herzegovina": 15.7,
    Oman: 19.5,
    Latvia: 16.08,
    Barbados: 13.45,
    "The Bahamas": 11.5,
    Malta: 10.69,
    Fiji: 9.84,
    Estonia: 7.77,
    "São Tomé and Príncipe": 6.82,
    Aruba: 6.59,
    "St. Lucia": 5.36,
    "Solomon Islands": 3.82,
    Belize: 4.82,
    Montenegro: 4.21,
    "Antigua and Barbuda": 4.19,
    Kosovo: 2.53,
    "St. Vincent and the Grenadines": 2.26,
    Liberia: 2.09,
    "St. Kitts and Nevis": 1.79,
    Grenada: 2.18,
    Dominica: 1.87,
    "San Marino": 1.43,
    Samoa: 1.07,
    Andorra: 1.14,
    Tonga: 0.59,
    "Timor-Leste": 0.38,
    "Brunei Darussalam": 0.47,
    Kiribati: 0.12,
    Micronesia: 0.06,
    "Marshall Islands": 0.04,
    Nauru: 0.08,
    Tuvalu: 0,
    "Macao SAR": 0
  },
  "2024": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    "Islamic Republic of Iran": 37748230.15,
    Indonesia: 9142880.31,
    Vietnam: 5510495.47,
    Korea: 1462985.01,
    Japan: 1484663.49,
    Colombia: 922174.54,
    Uzbekistan: 425256.58,
    India: 277839.45,
    Iraq: 207846.89,
    "Lao P.D.R.": 167571.96,
    Nigeria: 106676.41,
    Paraguay: 120712.45,
    China: 107405.7,
    Guinea: 110042.5,
    Chile: 116110.21,
    Myanmar: 89884.67,
    Uganda: 102106.05,
    Tanzania: 80402.72,
    Cambodia: 56197.39,
    Sudan: 48115.63,
    Pakistan: 49175.01,
    "Sierra Leone": 47228.23,
    Hungary: 47644.88,
    Angola: 48798.79,
    Mongolia: 39250.01,
    Madagascar: 37366.9,
    Kazakhstan: 30931.78,
    "United States": 35533.94,
    "Costa Rica": 32111.97,
    "Sri Lanka": 24326.05,
    Russia: 25208.24,
    "CÙte d'Ivoire": 23968.4,
    Algeria: 20571.37,
    Bangladesh: 17874.59,
    Yemen: 18295.88,
    Mexico: 18787.08,
    Philippines: 15797.09,
    Rwanda: 12051.59,
    Kenya: 11191.83,
    Senegal: 12311.42,
    Thailand: 11204.62,
    Malawi: 9748.68,
    Cameroon: 11859.72,
    Brazil: 9797.87,
    Egypt: 7446.13,
    "Democratic Republic of the Congo": 10538.51,
    "Burkina Faso": 6619.93,
    Mali: 6531.81,
    "South Africa": 5633.47,
    Gabon: 6714.62,
    Benin: 5634.29,
    Niger: 5305.6,
    Armenia: 5223.48,
    Burundi: 5275.41,
    Turkey: 4079.49,
    "Republic of Congo": 5217.87,
    "Dominican Republic": 4090.87,
    "Czech Republic": 3694.97,
    Serbia: 3894.55,
    Nepal: 3219.28,
    Togo: 3525.6,
    Ukraine: 3138.05,
    France: 3179.3,
    Chad: 3260.75,
    "United Kingdom": 2806.53,
    Italy: 2940.11,
    "Equatorial Guinea": 2870.92,
    Canada: 2840.96,
    Germany: 2649.25,
    Uruguay: 2230.25,
    Zimbabwe: 2169.96,
    Iceland: 2651.71,
    Sweden: 2297.01,
    Jamaica: 2068.92,
    Mozambique: 1789.8,
    Norway: 1764.36,
    Spain: 1634.45,
    Australia: 1643.06,
    Poland: 1576.23,
    Albania: 1585.36,
    Malaysia: 1384.95,
    Israel: 1308.33,
    Denmark: 1080.08,
    Morocco: 1028.78,
    "Taiwan Province of China": 3296.96,
    "Saudi Arabia": 1026.68,
    Romania: 833.84,
    "South Sudan": 805.6,
    Singapore: 816.71,
    "Guinea-Bissau": 803.71,
    Haiti: 663.94,
    Zambia: 700.6,
    Guyana: 768.71,
    "Central African Republic": 695.94,
    "Kyrgyz Republic": 583.05,
    Ghana: 562.63,
    Belgium: 635.24,
    "United Arab Emirates": 653.32,
    Mauritius: 607.13,
    "North Macedonia": 467.47,
    Netherlands: 506.53,
    Peru: 410.9,
    Honduras: 443.75,
    Greece: 374.32,
    Croatia: 369.68,
    Austria: 361.41,
    Djibouti: 314.08,
    Nicaragua: 285.19,
    Switzerland: 318.8,
    Bolivia: 272.5,
    Ireland: 290.17,
    Bhutan: 282.07,
    Qatar: 313.1,
    "Cabo Verde": 296.22,
    Portugal: 291.16,
    Guatemala: 261.67,
    Mauritania: 226.57,
    "New Zealand": 245.61,
    Finland: 207.39,
    "Hong Kong SAR": 159.87,
    Comoros: 201.62,
    Suriname: 153.02,
    Namibia: 165.87,
    Tunisia: 149.01,
    Moldova: 128.58,
    "Trinidad and Tobago": 127.42,
    Maldives: 129.22,
    "The Gambia": 99.78,
    Belarus: 86,
    Vanuatu: 66.05,
    "Slovak Republic": 69.1,
    "Puerto Rico": 64.66,
    Turkmenistan: 55.29,
    Tajikistan: 58.32,
    Botswana: 68.24,
    "Papua New Guinea": 57.84,
    Ecuador: 66.58,
    Eritrea: 59.71,
    Eswatini: 43.7,
    Panama: 43.66,
    Slovenia: 42.54,
    Georgia: 38.55,
    Azerbaijan: 30.74,
    Bulgaria: 39.83,
    "El Salvador": 28.47,
    Jordan: 31.77,
    Kuwait: 15.03,
    Bahrain: 22.23,
    Lithuania: 26,
    Cyprus: 24.24,
    Luxembourg: 21.66,
    Seychelles: 23.16,
    Lesotho: 21.38,
    "West Bank and Gaza": 21.43,
    "Bosnia and Herzegovina": 16.55,
    Oman: 18.56,
    Latvia: 16.11,
    Barbados: 13.26,
    "The Bahamas": 12.05,
    Malta: 11.36,
    Fiji: 10.3,
    Estonia: 8.33,
    "São Tomé and Príncipe": 6.95,
    Aruba: 6.63,
    "St. Lucia": 5.55,
    "Solomon Islands": 4.42,
    Belize: 4.92,
    Montenegro: 4.32,
    "Antigua and Barbuda": 4.21,
    Kosovo: 2.85,
    "St. Vincent and the Grenadines": 2.34,
    Liberia: 2.13,
    "St. Kitts and Nevis": 1.86,
    Grenada: 2.16,
    Dominica: 1.89,
    "San Marino": 1.45,
    Samoa: 1.13,
    Andorra: 1.14,
    Tonga: 0.6,
    "Timor-Leste": 0.44,
    "Brunei Darussalam": 0.47,
    Kiribati: 0.14,
    Micronesia: 0.08,
    "Marshall Islands": 0.05,
    Nauru: 0.07,
    Tuvalu: 0,
    "Macao SAR": 0
  },
  "2025": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    "Islamic Republic of Iran": 50027279.46,
    Indonesia: 9875687.15,
    Vietnam: 6101010.15,
    Korea: 1594720.99,
    Japan: 1502229.62,
    Colombia: 960414.04,
    Uzbekistan: 454971.34,
    India: 305373.06,
    Iraq: 224709,
    "Lao P.D.R.": 179785.89,
    Nigeria: 125476.93,
    Paraguay: 127891.12,
    China: 117764.95,
    Guinea: 120894.36,
    Chile: 121418.08,
    Myanmar: 102989.85,
    Uganda: 108752.01,
    Tanzania: 86331.65,
    Cambodia: 61959.73,
    Sudan: 52070.27,
    Pakistan: 52316.52,
    "Sierra Leone": 50452.28,
    Hungary: 48703.13,
    Angola: 47652.01,
    Mongolia: 42738.35,
    Madagascar: 41498.13,
    Kazakhstan: 35443.1,
    "United States": 37151.98,
    "Costa Rica": 33226.35,
    "Sri Lanka": 26654.54,
    Russia: 26200.25,
    "CÙte d'Ivoire": 25551.58,
    Algeria: 23339.75,
    Bangladesh: 20110.57,
    Yemen: 19673.31,
    Mexico: 19883.15,
    Philippines: 16995.66,
    Rwanda: 13118.92,
    Kenya: 12869.61,
    Senegal: 12775.18,
    Thailand: 11796.2,
    Malawi: 11082.42,
    Cameroon: 11879.54,
    Brazil: 10435.56,
    Egypt: 8039.29,
    "Democratic Republic of the Congo": 9521.54,
    "Burkina Faso": 7036.4,
    Mali: 6899.69,
    "South Africa": 6180.39,
    Gabon: 6550.82,
    Benin: 5922.82,
    Niger: 5694.05,
    Armenia: 5537.82,
    Burundi: 5499.09,
    Turkey: 4794.61,
    "Republic of Congo": 5210.5,
    "Dominican Republic": 4357.12,
    "Czech Republic": 3954.16,
    Serbia: 3962.2,
    Nepal: 3574.34,
    Togo: 3710.63,
    Ukraine: 3314.56,
    France: 3285.6,
    Chad: 3338.94,
    "United Kingdom": 2928.35,
    Italy: 2985.79,
    "Equatorial Guinea": 2860.97,
    Canada: 2833.72,
    Germany: 2628.09,
    Uruguay: 2383.1,
    Zimbabwe: 2357.23,
    Iceland: 2590.06,
    Sweden: 2286.34,
    Jamaica: 2037.28,
    Mozambique: 1950.78,
    Norway: 1788.72,
    Spain: 1696.1,
    Australia: 1695.84,
    Poland: 1641.05,
    Albania: 1619.27,
    Malaysia: 1503.87,
    Israel: 1370.7,
    Denmark: 1136.52,
    Morocco: 1079.77,
    "Taiwan Province of China": 2237.31,
    "Saudi Arabia": 1026.62,
    Romania: 916.78,
    "South Sudan": 886.82,
    Singapore: 864.8,
    "Guinea-Bissau": 836.16,
    Haiti: 761.95,
    Zambia: 753.3,
    Guyana: 787.37,
    "Central African Republic": 715.1,
    "Kyrgyz Republic": 640.2,
    Ghana: 628.18,
    Belgium: 665.48,
    "United Arab Emirates": 668.96,
    Mauritius: 604.44,
    "North Macedonia": 495.95,
    Netherlands: 505.98,
    Peru: 452.44,
    Honduras: 457.15,
    Greece: 377.36,
    Croatia: 371.16,
    Austria: 372.87,
    Djibouti: 337.5,
    Nicaragua: 306.08,
    Switzerland: 319.14,
    Bolivia: 293.74,
    Ireland: 298.97,
    Bhutan: 304.15,
    Qatar: 309.1,
    "Cabo Verde": 298.88,
    Portugal: 294.72,
    Guatemala: 279.23,
    Mauritania: 240.95,
    "New Zealand": 251.13,
    Finland: 216.37,
    "Hong Kong SAR": 187.22,
    Comoros: 211.83,
    Suriname: 179.56,
    Namibia: 173.33,
    Tunisia: 160.91,
    Moldova: 141.04,
    "Trinidad and Tobago": 135.66,
    Maldives: 137.28,
    "The Gambia": 101,
    Belarus: 87.1,
    Vanuatu: 72.37,
    "Slovak Republic": 71.45,
    "Puerto Rico": 68.06,
    Turkmenistan: 61.1,
    Tajikistan: 63.63,
    Botswana: 68.87,
    "Papua New Guinea": 61.23,
    Ecuador: 65.12,
    Eritrea: 59.62,
    Eswatini: 46.52,
    Panama: 45.25,
    Slovenia: 43.06,
    Georgia: 40.62,
    Azerbaijan: 34.87,
    Bulgaria: 40.26,
    "El Salvador": 30.71,
    Jordan: 31.89,
    Kuwait: 21.34,
    Bahrain: 24,
    Lithuania: 25.87,
    Cyprus: 24.43,
    Luxembourg: 22.49,
    Seychelles: 23.2,
    Lesotho: 22.3,
    "West Bank and Gaza": 20.05,
    "Bosnia and Herzegovina": 17.45,
    Oman: 17.6,
    Latvia: 16.06,
    Barbados: 13.02,
    "The Bahamas": 12.4,
    Malta: 11.93,
    Fiji: 10.71,
    Estonia: 8.62,
    "São Tomé and Príncipe": 7.1,
    Aruba: 6.59,
    "St. Lucia": 5.74,
    "Solomon Islands": 5.11,
    Belize: 5.02,
    Montenegro: 4.46,
    "Antigua and Barbuda": 4.19,
    Kosovo: 3.18,
    "St. Vincent and the Grenadines": 2.41,
    Liberia: 2.2,
    "St. Kitts and Nevis": 1.93,
    Grenada: 2.02,
    Dominica: 1.88,
    "San Marino": 1.47,
    Samoa: 1.18,
    Andorra: 1.13,
    Tonga: 0.71,
    "Timor-Leste": 0.49,
    "Brunei Darussalam": 0.47,
    Kiribati: 0.17,
    Micronesia: 0.1,
    "Marshall Islands": 0.06,
    Nauru: 0.07,
    Tuvalu: 0,
    "Macao SAR": 0
  },
  "2026": {
    Afghanistan: "n/a",
    Argentina: "n/a",
    Ethiopia: "n/a",
    Lebanon: "n/a",
    Syria: "n/a",
    Venezuela: "n/a",
    "Islamic Republic of Iran": 66644500.2,
    Indonesia: 10667228.85,
    Vietnam: 6717205.68,
    Korea: 1728157.25,
    Japan: 1519811.88,
    Colombia: 993240.92,
    Uzbekistan: 493095.59,
    India: 334676.08,
    Iraq: 240562.42,
    "Lao P.D.R.": 191280.57,
    Nigeria: 147248.26,
    Paraguay: 135213.94,
    China: 128635.2,
    Guinea: 128627.66,
    Chile: 127100.43,
    Myanmar: 117790.04,
    Uganda: 113493.91,
    Tanzania: 92919.07,
    Cambodia: 68037.82,
    Sudan: 56339.31,
    Pakistan: 54941.78,
    "Sierra Leone": 53116.03,
    Hungary: 49189.69,
    Angola: 47478.12,
    Mongolia: 47410.5,
    Madagascar: 45943.81,
    Kazakhstan: 40599.68,
    "United States": 38838.73,
    "Costa Rica": 34073.45,
    "Sri Lanka": 29011.74,
    Russia: 27675.06,
    "CÙte d'Ivoire": 27111.26,
    Algeria: 26261.42,
    Bangladesh: 22700.02,
    Yemen: 21640.64,
    Mexico: 21043.65,
    Philippines: 18018.06,
    Rwanda: 14078.09,
    Kenya: 14005.91,
    Senegal: 13311.07,
    Thailand: 12407.26,
    Malawi: 12389.36,
    Cameroon: 11994.22,
    Brazil: 11051.69,
    Egypt: 8649.9,
    "Democratic Republic of the Congo": 8528.34,
    "Burkina Faso": 7490.47,
    Mali: 7324.14,
    "South Africa": 6782.93,
    Gabon: 6502.17,
    Benin: 6159.65,
    Niger: 6122.29,
    Armenia: 5817.66,
    Burundi: 5728.36,
    Turkey: 5639.51,
    "Republic of Congo": 5344.55,
    "Dominican Republic": 4656.96,
    "Czech Republic": 4194.36,
    Serbia: 4042.93,
    Nepal: 3935.23,
    Togo: 3913.24,
    Ukraine: 3523.7,
    France: 3393.22,
    Chad: 3312.58,
    "United Kingdom": 3048.29,
    Italy: 3035.03,
    "Equatorial Guinea": 2840.68,
    Canada: 2818.05,
    Germany: 2606.18,
    Uruguay: 2527.64,
    Zimbabwe: 2518.86,
    Iceland: 2451.64,
    Sweden: 2264.94,
    Jamaica: 2035.88,
    Mozambique: 2020.27,
    Norway: 1821.58,
    Spain: 1760.03,
    Australia: 1740.53,
    Poland: 1710.54,
    Albania: 1663.3,
    Malaysia: 1631.73,
    Israel: 1432.42,
    Denmark: 1182.25,
    Morocco: 1131.16,
    "Taiwan Province of China": 1109.23,
    "Saudi Arabia": 1026.55,
    Romania: 1001.38,
    "South Sudan": 983.59,
    Singapore: 915.77,
    "Guinea-Bissau": 871.67,
    Haiti: 871.52,
    Zambia: 791.89,
    Guyana: 790.92,
    "Central African Republic": 732.66,
    "Kyrgyz Republic": 699.38,
    Ghana: 698.38,
    Belgium: 697.72,
    "United Arab Emirates": 681.04,
    Mauritius: 665.87,
    "North Macedonia": 523.62,
    Netherlands: 502.75,
    Peru: 495.05,
    Honduras: 480.08,
    Greece: 379.49,
    Croatia: 372.09,
    Austria: 371.2,
    Djibouti: 350.3,
    Nicaragua: 325.46,
    Switzerland: 319.49,
    Bolivia: 315.22,
    Ireland: 307.48,
    Bhutan: 306.56,
    Qatar: 305.1,
    "Cabo Verde": 302.98,
    Portugal: 298.05,
    Guatemala: 297.14,
    Mauritania: 255.56,
    "New Zealand": 250.96,
    Finland: 225.04,
    "Hong Kong SAR": 222.32,
    Comoros: 219.07,
    Suriname: 209.19,
    Namibia: 180.71,
    Tunisia: 175.38,
    Moldova: 153.52,
    "Trinidad and Tobago": 143.65,
    Maldives: 143.1,
    "The Gambia": 103.08,
    Belarus: 90,
    Vanuatu: 77.79,
    "Slovak Republic": 74.08,
    "Puerto Rico": 71.37,
    Turkmenistan: 69.37,
    Tajikistan: 69.16,
    Botswana: 68.67,
    "Papua New Guinea": 64.44,
    Ecuador: 63.58,
    Eritrea: 59.49,
    Eswatini: 49.37,
    Panama: 46.96,
    Slovenia: 43.35,
    Georgia: 42.95,
    Azerbaijan: 40.39,
    Bulgaria: 40.27,
    "El Salvador": 33.01,
    Jordan: 32.06,
    Kuwait: 28.34,
    Bahrain: 25.95,
    Lithuania: 25.69,
    Cyprus: 24.2,
    Luxembourg: 23.36,
    Seychelles: 22.79,
    Lesotho: 20.85,
    "West Bank and Gaza": 18.61,
    "Bosnia and Herzegovina": 18.47,
    Oman: 16.44,
    Latvia: 15.96,
    Barbados: 12.74,
    "The Bahamas": 12.7,
    Malta: 12.47,
    Fiji: 11.1,
    Estonia: 8.69,
    "São Tomé and Príncipe": 7.29,
    Aruba: 6.5,
    "St. Lucia": 5.92,
    "Solomon Islands": 5.88,
    Belize: 5.13,
    Montenegro: 4.56,
    "Antigua and Barbuda": 4.16,
    Kosovo: 3.53,
    "St. Vincent and the Grenadines": 2.38,
    Liberia: 2.35,
    "St. Kitts and Nevis": 2,
    Grenada: 2,
    Dominica: 1.87,
    "San Marino": 1.5,
    Samoa: 1.23,
    Andorra: 1.12,
    Tonga: 0.94,
    "Timor-Leste": 0.55,
    "Brunei Darussalam": 0.47,
    Kiribati: 0.2,
    Micronesia: 0.12,
    "Marshall Islands": 0.07,
    Nauru: 0.06,
    Tuvalu: 0,
    "Macao SAR": 0
  }
};
class GdpPDEBT extends React.Component {
  constructor(props) {
    super(props);

    let noData = [];
    let date = [];
    let debt = [];
    let debtData = [];
    let gdp = [];
    let gdpData = [];
    let comp = [];
    let compData = [];
    let exportToUS = [];
    let exportToUSData = [];
    let exportToIN = [];
    let exportToINData = [];
    //let exportToUSData = [];
    let countries = [];

    const country = props.suggCountry ? props.suggCountry : "United States";
    const laborExchange = true;
    var obj = {};
    Object.keys(governmentdebt).forEach((yr, i) => {
      const year = Number(yr);
      date.push(year);
      const data = Object.values(governmentdebt)[i];
      Object.keys(data).forEach((name) => {
        if (i === 0)
          obj[name] = {
            debt: [],
            debtData: [],
            gdp: [],
            gdpData: [],
            comp: [],
            compData: [],
            exportToUSData: [],
            exportToINData: []
          };
        // if (i === 0) countries.push(name);
        //if (/*name === country && */ data[name] && !isNaN(data[country])) {
        const data2 = gdpP[yr];
        const data3 = gdpPD[yr];
        if (data2[name] && data3[name]) {
          const ex =
            data2[laborExchange ? "United States" : country] / data3[country];
          const de = data[name] * 1000000000;
          obj[name].debt.push(de * ex);
          obj[name].debtData.push([year, de * ex]);
          obj[name].gdp.push(data3[name] * ex);
          obj[name].gdpData.push([year, data3[name] * ex]);
        }
        //}
      });
      /*const data2 = Object.values(gdpP)[i];
      Object.keys(data2).forEach((name) => {
        if (name === country && data2[country] && !isNaN(data2[country])) {
          gdp.push(data2[country]);
          gdpData.push([year, data2[country]]);
        }
      });*/
      /*const data3 = Object.values(gdpPD)[i];
      Object.keys(data3).forEach((name) => {
        if (name === country && data3[country] && !isNaN(data3[country])) {
          gdp.push(data3[country]);
          gdpData.push([year, data3[country]]);
        }
      });*/
    });
    Object.keys(obj).forEach((name, i) => {
      obj[name].debtData.forEach((x) => {
        const num = obj[name].gdpData.find((r) => r[0] === x[0]);
        if (num) {
          obj[name].comp.push(x[1] / num[1]);
          obj[name].compData.push([x[0], x[1] / num[1]]);
        } else console.log(name);
      });
    });
    const arr = [
      ...Object.keys(obj).map((name, i) => {
        return { name, ...obj[name] };
      })
    ].sort((a, b) =>
      b.comp[b.comp.length - 1] - b.comp[Math.round(b.comp.length / 2)] <
      a.comp[a.comp.length - 1] - a.comp[Math.round(a.comp.length / 2)]
        ? 1
        : -1
    );
    //console.log(arr);
    arr.forEach((x) => {
      countries.push(x.name);
      /*console.log(
        x.name,
        x.comp[x.comp.length - 1] - x.comp[Math.round(x.comp.length / 2)]
      );*/
      if (x.name === country) {
        debt = x.debt;
        debtData = x.debtData;
        gdp = x.gdp;
        gdpData = x.gdpData;
        comp = x.comp;
        compData = x.compData;
        //exportToUSData = x.exportToUSData
        const usimport = usimports.filter((x) => x[0] === country);
        usimport &&
          usimport.forEach((x) => {
            usimport.forEach((x) => {
              exportToUS.push(x[2]);
              exportToUSData.push([
                x[1], //new Date(x[1] + "-01-01").getTime(),
                x[2]
              ]);
            });
          });

        //const usimport = inimports.filter((x) => x[0] === country);
      }
    });
    const inexporter = inimports[country];
    inexporter &&
      Object.keys(inexporter).forEach((yr) => {
        const data2 = gdpP[yr];
        const indiagdp = gdpPD[yr]["India"];
        exportToIN.push(
          inexporter[yr] * 100000 * (data2["United States"] / indiagdp)
        );
        exportToINData.push([
          Number(yr), //new Date(yr + "-01-01").getTime(),
          inexporter[yr] * 100000 * (data2["United States"] / indiagdp)
        ]);
      });

    /*Object.keys(gdpPD).forEach((yr, i) => {
      if (i === 0) {
        const data = Object.values(gdpPD)[i];
        Object.keys(data).forEach((name) => {
          countries.push(name);
        });
      }
    });*/
    var lowDollar = Math.min(...gdp);
    var lowComp = Math.min(...comp);
    var lowDebt = Math.min(...debt);
    var lowDate = Math.min(...date);
    var highDollar = Math.max(...gdp);
    var highComp = Math.max(...comp);
    var highDebt = Math.max(...debt);
    var highDate = Math.max(...date);
    var state = {
      country,
      countries,
      date,
      highDollar,
      debtData,
      gdpData,
      noData,
      lowDebt,
      highDebt,
      highComp,
      lowComp,
      compData,
      yAxisComp: highComp - lowComp,
      yAxisDebt: highDebt - lowDebt,
      yAxis: highDollar - lowDollar,
      xAxis: highDate - lowDate,
      lowDollar,
      highDate,
      lowDate,
      lowDateReport: lowDate,
      exportToUSData: [],
      exportToINData: [],
      laborExchange
    };
    this.state = state;
  }
  componentDidUpdate = () => {
    if (
      this.state.laborExchange !== this.state.laststate ||
      this.state.country !== this.state.lastcountry
    ) {
      this.setState(
        {
          laststate: this.state.laborExchange,
          lastcountry: this.state.country
        },
        () => {
          const { country } = this.state;
          let noData = [];
          let date = [];
          let debt = [];
          let debtData = [];
          let gdp = [];
          let gdpData = [];
          let comp = [];
          let compData = [];
          let exportToUS = [];
          let exportToUSData = [];
          let exportToIN = [];
          let exportToINData = [];
          let dateshere = [];

          Object.keys(governmentdebt).forEach((yr, i) => {
            const year = Number(yr);
            date.push(year);
            const data = Object.values(governmentdebt)[i];
            Object.keys(data).forEach((name) => {
              if (name === country && data[country] && !isNaN(data[country])) {
                /*if (
                [
                  "Saudi Arabia",
                  "Russia",
                  "India",
                  "Germany",
                  "China"
                ].includes(country)
              ) {*/
                const usimport = usimports.filter((x) => x[0] === country);
                usimport &&
                  usimport.forEach((x) => {
                    const year = Number(x[1]); //new Date(x[1] + "-01-01").getFullYear();
                    //date.push(year);
                    exportToUS.push(x[2]);
                    exportToUSData.push([year, x[2]]);
                  });
                //}
                const data2 = gdpP[yr];
                const data3 = gdpPD[yr];

                if (data2[country] && data3[country]) {
                  dateshere.push(year);
                  const ex =
                    data2[
                      this.state.laborExchange ? "United States" : country
                    ] / data3[country];
                  const de = data[country] * 1000000000;
                  debt.push(de * ex);
                  debtData.push([year, de * ex]);
                  gdp.push(data3[country] * ex);
                  gdpData.push([year, data3[country] * ex]);
                  //const usimport = inimports.filter((x) => x[0] === country);
                }
              }
            });
            /*const data2 = Object.values(gdpP)[i];
          Object.keys(data2).forEach((name) => {
            if (name === country && data2[country] && !isNaN(data2[country])) {
              gdp.push(data2[country]);
              gdpData.push([year, data2[country]]);
            }
          });*/
            /*const data3 = Object.values(gdpPD)[i];
          Object.keys(data3).forEach((name) => {
            if (name === country && data3[country] && !isNaN(data3[country])) {
              gdp.push(data3[country]);
              gdpData.push([year, data3[country]]);
            }
          });*/
          });
          const inexporter = inimports[country];
          inexporter &&
            Object.keys(inexporter).forEach((yr) => {
              const data2 = gdpP[yr];
              const indiagdp = gdpPD[yr]["India"];
              exportToIN.push(
                inexporter[yr] * 100000 * (data2["United States"] / indiagdp)
              );
              exportToINData.push([
                Number(yr), //new Date(yr + "-01-01").getTime(),
                inexporter[yr] * 100000 * (data2["United States"] / indiagdp)
              ]);
            });
          debtData.forEach((x) => {
            const num = gdpData.find((r) => r[0] === x[0]);
            comp.push(x[1] / num[1]);
            compData.push([x[0], x[1] / num[1]]);
          });
          var lowDollar = Math.min(...gdp);
          var lowComp = Math.min(...comp);
          var lowDebt = Math.min(...debt);
          var lowExports = Math.min(...exportToUS, ...exportToIN);
          var lowDate = Math.min(...date);
          var highDollar = Math.max(...gdp);
          var highComp = Math.max(...comp);
          var highDebt = Math.max(...debt);
          var highExports = Math.max(...exportToUS, ...exportToIN);
          var highDate = Math.max(...date);
          var lowDateReport = Math.min(...dateshere);
          //var highDate = Math.max(...dateshere);
          //console.log(dataData);
          var state = {
            lowDateReport,
            date,
            noData,
            exportToINData,
            exportToUSData,
            gdpData,
            lowDebt,
            highDebt,
            highDollar,
            debtData,
            highComp,
            lowComp,
            compData,
            yAxisComp: highComp - lowComp,
            yAxisDebt: highDebt - lowDebt,
            yAxisExports: highExports - lowExports,
            yAxis: highDollar - lowDollar,
            xAxis: highDate - lowDate,
            lowExports,
            highExports,
            lowDollar,
            highDate,
            lowDate
          };
          this.setState(state);
        }
      );
    }
  };
  render() {
    const { countries } = this.state;
    const labelstyle = {
      backgroundColor: "rgba(50,120,200,.6)",
      top: "0px",
      height: "min-content",
      display: "flex",
      maxWidth: "100%",
      left: "2px",
      flexWrap: "wrap"
    };
    const buttonStyle = {
      userSelect: "none",
      border: "1px solid black",
      color: "black",
      backgroundColor: "rgb(220,220,220)",
      borderRadius: "4px",
      padding: "5px",
      margin: "2px"
    };

    const linecss = {
      left: "0px",
      bottom: "0px",
      display: "flex",
      position: "absolute",
      width: "100%",
      height: "200px",
      transform: "translate(0%,0%) scale(1,-1)"
    };
    const shortNumber = (scler, notRound) => {
      var newnum = String(Math.round(scler));
      if (notRound) newnum = String(scler);
      var app = null;
      var decimal = null;
      const suff = ["", "k", "m", "b", "t", "q", "qu", "s"];
      for (let i = 0; i < suff.length; i++) {
        if (newnum.length > 3) {
          decimal = newnum[newnum.length - 3];
          newnum = newnum.substring(0, newnum.length - 3);
        } else {
          app = i;
          break;
        }
      }
      if (decimal && decimal.includes(".")) decimal = decimal.replace(".", ""); //decimal.substring(0, decimal.indexOf("."));
      return newnum + (decimal ? "." + decimal : "") + suff[app];
    };
    //console.log(this.state.oilprice);
    const noData = this.state.noData.map(([x, y]) => [
      ((x - this.state.lowDate) / this.state.xAxis) *
        0.9 *
        this.props.lastWidth,
      0
    ]);
    //console.log(this.state.oilprice);

    const debtData = this.state.debtData
      .filter((x) => x[1] !== 0)
      .map(([x, y]) => [
        ((x - this.state.lowDate) / this.state.xAxis) *
          0.9 *
          this.props.lastWidth,
        ((y - this.state.lowDebt) / this.state.yAxisDebt) * 150
      ]);
    const gdpData = this.state.gdpData
      .filter((x) => x[1] !== 0)
      .map(([x, y]) => [
        ((x - this.state.lowDate) / this.state.xAxis) *
          0.9 *
          this.props.lastWidth,
        ((y - this.state.lowDollar) / this.state.yAxis) * 150
      ]);
    const compData = this.state.compData
      .filter((x) => x[1] !== 0)
      .map(([x, y]) => [
        ((x - this.state.lowDate) / this.state.xAxis) *
          0.9 *
          this.props.lastWidth,
        ((y - this.state.lowComp) / this.state.yAxisComp) * 150
      ]);
    const exportToUSData = this.state.exportToUSData
      .filter((x) => x[1] !== 0)
      .map(([x, y]) => [
        ((x - this.state.lowDate) / this.state.xAxis) *
          0.9 *
          this.props.lastWidth,
        ((y - this.state.lowExports) / this.state.yAxisExports) * 150
      ]);
    const exportToINData = this.state.exportToINData
      .filter((x) => x[1] !== 0)
      .map(([x, y]) => [
        ((x - this.state.lowDate) / this.state.xAxis) *
          0.9 *
          this.props.lastWidth,
        ((y - this.state.lowExports) / this.state.yAxisExports) * 150
      ]);
    const endofData = [[2021, 0]]
      .filter((x) => x[1] !== 0)
      .map(([x, y]) => [
        ((x - this.state.lowDate) / this.state.xAxis) *
          0.9 *
          this.props.lastWidth,
        ((y - this.state.lowExports) / this.state.yAxisExports) * 150
      ]);
    const labelitem = {
      fontSize: "10px",
      color: "white",
      width: "max-content",
      margin: "0px 4px"
    };

    const StringDecimal = (y, suffindex = 3) => {
      var string;
      if (y) {
        const x = String(y);
        if (x.includes(".")) {
          const here = x.indexOf(".");
          string = x.substring(0, here) + x.substring(here, here + suffindex);
        } else string = x;
      }
      return string;
    };
    const checkFCSU = (x) =>
      this.setState({
        FCSU: this.state.FCSU.includes(x.target.id)
          ? this.state.FCSU.filter((c) => c !== x.target.id)
          : [...this.state.FCSU, x.target.id]
      });
    //console.log(exportToINData)
    return (
      <div
        style={{
          width: "100%",
          minHeight: "240px",
          position: "relative",
          backgroundColor: "black"
        }}
      >
        <div
          style={{
            bottom: "0px",
            zIndex: "1",
            backgroundColor: "rgba(255,255,255,.3)",
            padding: "4px 8px",
            position: "absolute",
            right: "0px"
          }}
        >
          <a
            style={{ color: "grey" }}
            href={
              "https://www.imf.org/en/Publications/WEO/weo-database/2021/October/download-entire-database"
            }
          >
            {this.state.lowDateReport}
            &nbsp;-&nbsp;
            {this.state.highDate}
          </a>
        </div>
        <div
          style={{
            color: "lightslategrey",
            zIndex: "1",
            textAlign: "left",
            position: "absolute"
          }}
        >
          <div
            style={{ margin: "4px 10px", fontSize: "14px", textAlign: "right" }}
          >
            labor-cost despondency of U.S.&nbsp;
            <span style={{ fontSize: "10px" }}>
              (being so financial service oriented, laborless-demand, that is,
              1/hour-GDP/p economic welfare deprecative)
            </span>
            .
          </div>
          <div style={{ float: "left" }}>
            <div style={{ display: "flex", maxWidth: "max-content" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  fontSize: "12px",
                  backgroundColor: "rgba(250,250,250,.6)",
                  color: "white",
                  padding: "4px",
                  maxWidth: "max-content"
                }}
              >
                ${shortNumber(this.state.lowDollar)}-
                {shortNumber(this.state.highDollar)}
                {this.state.laborExchange
                  ? " PPP coefficience"
                  : " customs' exchange"}
                <br />
                <span>
                  <input
                    type="checkbox"
                    checked={this.state.laborExchange}
                    onChange={(x) =>
                      this.setState({
                        laborExchange: !this.state.laborExchange
                      })
                    }
                  />
                  import*(
                  {this.state.laborExchange
                    ? `($GDP/p)/(GDP/p)`
                    : `USD/${
                        this.state.country && this.state.country.substring(0, 4)
                      }`}
                  )
                </span>
                <span style={{ height: "14px", marginTop: "4px" }}>
                  <select
                    style={{ width: "150px" }}
                    value={this.state.country}
                    onChange={(name) => {
                      this.setState({ country: name.target.value });
                    }}
                  >
                    {countries.map((name) => (
                      <option key={name}>{name}</option>
                    ))}
                  </select>
                  {countries.length}
                </span>
              </div>
              <div
                style={{
                  position: "float",
                  marginTop: "6px",
                  width: "max-content"
                }}
              >
                <div style={labelitem}>
                  <div
                    style={{
                      width: "5px",
                      height: "5px",
                      backgroundColor: "deepskyblue"
                    }}
                  />
                  gdp/p
                </div>
              </div>

              <span
                style={{
                  maxWidth: "max-content",
                  width: "calc(100% - 80px)"
                }}
              >
                <div
                  style={{
                    fontSize: "12px",
                    backgroundColor: "rgba(250,250,250,.6)",
                    color: "white",
                    padding: "4px",
                    opacity: isFinite(this.state.highExports) ? 1 : 0
                  }}
                >
                  ${shortNumber(this.state.lowExports)}-
                  {shortNumber(this.state.highExports)}
                </div>
                {
                  //exportToINData.length > 0 && (
                  <div
                    style={{
                      marginTop: "6px",
                      width: "max-content",
                      opacity: exportToINData.length > 0 ? 1 : 0,
                      transition: ".3s ease-out"
                    }}
                  >
                    <div style={labelitem}>
                      <div
                        style={{
                          width: "5px",
                          height: "5px",
                          backgroundColor: "orange"
                        }}
                      />
                      exports to India
                    </div>
                  </div>
                }
                {
                  //exportToUSData.length > 0 && (
                  <div
                    style={{
                      marginTop: "6px",
                      width: "max-content",
                      opacity: exportToUSData.length > 0 ? 1 : 0,
                      transition: ".3s ease-out"
                    }}
                  >
                    <div style={labelitem}>
                      <div
                        style={{
                          width: "5px",
                          height: "5px",
                          backgroundColor: "yellow"
                        }}
                      />
                      exports to U.S.
                    </div>
                  </div>
                }
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  fontSize: "12px",
                  backgroundColor: "rgba(250,250,250,.6)",
                  color: "white",
                  padding: "4px",
                  maxWidth: "max-content",
                  width: "calc(100% - 80px)"
                }}
              >
                ${shortNumber(this.state.lowDebt)}-
                {shortNumber(this.state.highDebt)}
              </div>
              <div style={{ marginTop: "6px", width: "max-content" }}>
                <div style={labelitem}>
                  <div
                    style={{
                      width: "5px",
                      height: "5px",
                      backgroundColor: "red"
                    }}
                  />
                  debt*(($GDP/p)/(GDP/p))
                </div>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  border: "1px solid",
                  padding: "4px 10px",
                  userSelect: "none"
                }}
                onClick={() => {
                  const country = countries.find(
                    (x, i) => i === countries.indexOf(this.state.country) - 1
                  );

                  this.setState({
                    country: country ? country : countries[countries.length]
                  });
                }}
              >
                {"<"}
              </div>
              {this.state.chosenDecade}
              <div
                style={{
                  border: "1px solid",
                  padding: "4px 10px",
                  userSelect: "none"
                }}
                onClick={() => {
                  const country = countries.find(
                    (x, i) => i === countries.indexOf(this.state.country) + 1
                  );

                  this.setState({
                    country: country ? country : countries[0]
                  });
                }}
              >
                {">"}
              </div>
            </div>
          </div>
        </div>
        <div style={{ transform: "translate(0px,230px)" }}>
          <svg style={linecss} xmlns="http://www.w3.org/2000/svg">
            {noData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={4}
                    height={4}
                    stroke="rgb(230,230,230)"
                    fill="transparent"
                    strokeWidth={0}
                    key={i}
                  />
                )
            )}
            {endofData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={1}
                    height={200}
                    stroke="dimgray"
                    fill="blue"
                    strokeWidth={1}
                    key={i}
                  />
                )
            )}
            {debtData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={2}
                    height={1}
                    stroke="red"
                    fill="blue"
                    strokeWidth={1}
                    key={i}
                  />
                )
            )}
            {gdpData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={2}
                    height={1}
                    stroke="deepskyblue"
                    fill="blue"
                    strokeWidth={1}
                    key={i}
                  />
                )
            )}
            {compData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={2}
                    height={1}
                    stroke="green"
                    fill="blue"
                    strokeWidth={1}
                    key={i}
                  />
                )
            )}
            {exportToUSData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={2}
                    height={1}
                    stroke="yellow"
                    fill="blue"
                    strokeWidth={1}
                    key={i}
                  />
                )
            )}
            {exportToINData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={2}
                    height={1}
                    stroke="orange"
                    fill="blue"
                    strokeWidth={1}
                    key={i}
                  />
                )
            )}
          </svg>
        </div>
        {/*<div
          style={{
            backgroundColor: "rgba(250,250,250,0.6)",
            top: "10px",
            height: "40px",
            display: "flex",
            position: "relative",
            width: "100%",
            left: "2px",
            zIndex: "0",
            overflowX: "auto",
            overflowY: "hidden"
          }}
        >
          <div
            style={{
              fontSize: "15px",
              display: "flex",
              position: "absolute",
              width: "max-content"
            }}
          ></div>
          </div>*/}
      </div>
    );
  }
}

export default GdpPDEBT; /**

        const basics =
          expense -
          poverty[yr].clothing +
          poverty[yr].food +
          poverty[yr].rentandutilities +
          poverty[yr].standardizedguaranteeschemesmedicine;
        var last2 = null;
        var lastexp = null;
        Object.keys(flows).forEach((x, i) => {
          if (yr === x) {
            const yar = Object.keys(flows)[i - 1];
            //var flo = flows[Object.keys(flows)[i - 1]];
            //if (flo) last3 = flo.income;
            var povert = flows[yar];
            if (povert) lastexp = povert.expenses;
          }
        });
        //var last3 = null;
        Object.keys(poverty).forEach((x, i) => {
          if (yr === x) {
            const yar = Object.keys(poverty)[i - 1];
            //var flo = flows[Object.keys(flows)[i - 1]];
            //if (flo) last3 = flo.income;
            var povert = poverty[yar];
            if (povert)
              last2 =
                lastexp -
                povert.clothing +
                povert.food +
                povert.rentandutilities +
                povert.standardizedguaranteeschemesmedicine;
          }
        });
        if (last2) {
          debt.push((basics - last2) / last2);
          debtData.push([year, (basics - last2) / last2]);
          //growthcurren.push((income - last3) / last3);
          //growthcurrenData.push([year, (income - last3) / last3]);
        }
* */
