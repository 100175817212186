export const inimports =
  //https://tradestat.commerce.gov.in/eidb/default.asp
  {
    Afghanistan: {
      "1997": 3976.82,
      "1998": 11840.21,
      "1999": 9124.45,
      "2000": 12146.81,
      "2001": 8356.93,
      "2002": 8932.26,
      "2003": 18615.99,
      "2004": 21120.4,
      "2005": 25866.13,
      "2006": 15613.31,
      "2007": 43976.2,
      "2008": 59245.85,
      "2009": 59040.37,
      "2010": 66202.4,
      "2011": 64285.52,
      "2012": 86097.21,
      "2013": 128759.29,
      "2014": 161292.04,
      "2015": 202947.88,
      "2016": 196747.09,
      "2017": 279662.29,
      "2018": 307843.46,
      "2019": 376560.85,
      "2020": 375347.66
    },
    Albania: {
      "1997": 11.73,
      "1998": 74.99,
      "1999": null,
      "2000": 16.34,
      "2001": 3.71,
      "2002": 1.84,
      "2003": 7.09,
      "2004": 14.35,
      "2005": 85.62,
      "2006": 16.76,
      "2007": 224.63,
      "2008": 81.46,
      "2009": 130.18,
      "2010": 52.19,
      "2011": 63505.11,
      "2012": 16657.1,
      "2013": 124358.56,
      "2014": 30133.36,
      "2015": 11392.27,
      "2016": 4375.42,
      "2017": 25075.76,
      "2018": 15963.93,
      "2019": 33538.49,
      "2020": 6093.21
    },
    Algeria: {
      "1997": 213.98,
      "1998": 195.87,
      "1999": 7391.48,
      "2000": 1189.3,
      "2001": 672.89,
      "2002": 534.78,
      "2003": 2055.13,
      "2004": 2836.78,
      "2005": 6485.22,
      "2006": 339488.55,
      "2007": 496169.26,
      "2008": 451753.95,
      "2009": 464561.87,
      "2010": 827286.04,
      "2011": 1018718.86,
      "2012": 371539.82,
      "2013": 523166.26,
      "2014": 337952.5,
      "2015": 197212.09,
      "2016": 405542.09,
      "2017": 813219.02,
      "2018": 1189954.07,
      "2019": 1412219.37,
      "2020": 302507.82
    },
    "Ameri Samoa": {
      "1997": 0.01,
      "1998": 0.1,
      "1999": 0,
      "2000": 0,
      "2001": null,
      "2002": 204.14,
      "2003": 2.21,
      "2004": 24.38,
      "2005": 128.92,
      "2006": 38119.73,
      "2007": 467.23,
      "2008": 114.02,
      "2009": 161.08,
      "2010": 70.07,
      "2011": 657.08,
      "2012": 421.7,
      "2013": 81,
      "2014": 404.12,
      "2015": 739.29,
      "2016": 92.66,
      "2017": 195.42,
      "2018": 0.35,
      "2019": 2195.86,
      "2020": 725.72
    },
    Andorra: {
      "1997": 9.04,
      "1998": 29.41,
      "1999": 0,
      "2000": 0,
      "2001": null,
      "2002": 0.27,
      "2003": 1.05,
      "2004": 10.19,
      "2005": "",
      "2006": 12.43,
      "2007": 4.08,
      "2008": 40.8,
      "2009": 1.95,
      "2010": 3.25,
      "2011": 15.91,
      "2012": 5.18,
      "2013": 0.16,
      "2014": 3285.62,
      "2015": 0.13,
      "2016": 59.88,
      "2017": null,
      "2018": 27.22,
      "2019": 24.05,
      "2020": 4.78
    },
    Angola: {
      "1997": 0,
      "1998": 0,
      "1999": 1.56,
      "2000": null,
      "2001": 5.14,
      "2002": 3482.05,
      "2003": null,
      "2004": 407.66,
      "2005": 1438.17,
      "2006": 111098.32,
      "2007": 409608.66,
      "2008": 653900.05,
      "2009": 2001769.68,
      "2010": 2327303.79,
      "2011": 3186848.18,
      "2012": 3894642.34,
      "2013": 3665135.82,
      "2014": 2811832.13,
      "2015": 1798417.69,
      "2016": 1740617.22,
      "2017": 2786327.74,
      "2018": 2814652.81,
      "2019": 2583209.32,
      "2020": 1390885.64
    },
    Anguilla: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 0,
      "2008": 0,
      "2009": null,
      "2010": 3.23,
      "2011": 61.18,
      "2012": 0.13,
      "2013": null,
      "2014": 40.77,
      "2015": 3.84,
      "2016": 0.77,
      "2017": 118.41,
      "2018": 129.85,
      "2019": 68.72,
      "2020": 132.06
    },
    Antartica: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 5.36,
      "2008": 17.51,
      "2009": 33.81,
      "2010": 35.1,
      "2011": 200.55,
      "2012": null,
      "2013": 222.93,
      "2014": 3010.33,
      "2015": 704.21,
      "2016": 333.89,
      "2017": 31.95,
      "2018": 22.98,
      "2019": 53.3,
      "2020": 491.69
    },
    "Antigua and Barbuda": {
      "1997": 0,
      "1998": 0,
      "1999": null,
      "2000": 3.02,
      "2001": 0,
      "2002": 0,
      "2003": 211.44,
      "2004": 2.88,
      "2005": 50.99,
      "2006": 506.13,
      "2007": 25.61,
      "2008": 35.38,
      "2009": 6.44,
      "2010": 7276.6,
      "2011": 28.46,
      "2012": 118.63,
      "2013": 26.34,
      "2014": 301.51,
      "2015": 5.9,
      "2016": 0.94,
      "2017": 15.31,
      "2018": 106.63,
      "2019": 139.91,
      "2020": 104.94
    },
    Argentina: {
      "1997": 47640,
      "1998": 142739.7,
      "1999": 150567.46,
      "2000": 173948.56,
      "2001": 207937.11,
      "2002": 195584.32,
      "2003": 240770.07,
      "2004": 242448.07,
      "2005": 333837.75,
      "2006": 398459.26,
      "2007": 364530.43,
      "2008": 234594.26,
      "2009": 316135.28,
      "2010": 469004.96,
      "2011": 521481.14,
      "2012": 650435.38,
      "2013": 815532.36,
      "2014": 1216692.17,
      "2015": 1625135.05,
      "2016": 1676299.53,
      "2017": 1436507.43,
      "2018": 1361496.66,
      "2019": 1650963.5,
      "2020": 1946338.08
    },
    Armenia: {
      "1997": null,
      "1998": 6.99,
      "1999": 0.78,
      "2000": 257.67,
      "2001": 215.85,
      "2002": 77.62,
      "2003": 303.06,
      "2004": 348.6,
      "2005": 919.42,
      "2006": 34480.4,
      "2007": 1515.24,
      "2008": 1276.4,
      "2009": 1403.26,
      "2010": 3224.82,
      "2011": 2891.22,
      "2012": 810.6,
      "2013": 1043.16,
      "2014": 500.47,
      "2015": 9286.74,
      "2016": 704.48,
      "2017": 418.49,
      "2018": 629.32,
      "2019": 1102.56,
      "2020": 70814.42
    },
    Aruba: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": null,
      "2004": 8.36,
      "2005": "",
      "2006": 18.17,
      "2007": null,
      "2008": 8.3,
      "2009": null,
      "2010": 864.3,
      "2011": 54.17,
      "2012": 128.88,
      "2013": 83.34,
      "2014": 17.21,
      "2015": 195.3,
      "2016": 2850.27,
      "2017": 257.77,
      "2018": 382.45,
      "2019": 215.68,
      "2020": 597.69
    },
    Australia: {
      "1997": 552105.22,
      "1998": 607925.36,
      "1999": 468754.15,
      "2000": 485514.69,
      "2001": 622902.64,
      "2002": 646942.5,
      "2003": 1217359.07,
      "2004": 1718418,
      "2005": 2190614.78,
      "2006": 3171090.05,
      "2007": 3155208.44,
      "2008": 5049651.71,
      "2009": 5866199.07,
      "2010": 4918755.83,
      "2011": 7461961.91,
      "2012": 7121710.96,
      "2013": 5895759.05,
      "2014": 6270102.96,
      "2015": 5818066.66,
      "2016": 7486443.46,
      "2017": 9019721.94,
      "2018": 9163831.81,
      "2019": 6918324.06,
      "2020": 6097078.89
    },
    Austria: {
      "1997": 30151.43,
      "1998": 25090.85,
      "1999": 28854.97,
      "2000": 31668.84,
      "2001": 37113.31,
      "2002": 79470.13,
      "2003": 92670.76,
      "2004": 117873.85,
      "2005": 152310.25,
      "2006": 206156.66,
      "2007": 235735.88,
      "2008": 320738.18,
      "2009": 372695.63,
      "2010": 371772.18,
      "2011": 519590.96,
      "2012": 503394.79,
      "2013": 501811.16,
      "2014": 498236.97,
      "2015": 542210.45,
      "2016": 608498.12,
      "2017": 627436.73,
      "2018": 500420.45,
      "2019": 445860.82,
      "2020": 460978.73
    },
    Azerbaijan: {
      "1997": 38.84,
      "1998": 9.71,
      "1999": 169.55,
      "2000": 61.46,
      "2001": 103.95,
      "2002": 839.11,
      "2003": 1389.1,
      "2004": 3464.81,
      "2005": 2595.41,
      "2006": 30339.88,
      "2007": 69510.92,
      "2008": 86416.95,
      "2009": 133572.24,
      "2010": 91110.79,
      "2011": 326550.13,
      "2012": 284778.89,
      "2013": 685702.7,
      "2014": 118897.41,
      "2015": 48686.71,
      "2016": 310494.67,
      "2017": 382752.21,
      "2018": 102919.28,
      "2019": 194303.53,
      "2020": 168328.63
    },
    Bahamas: {
      "1997": 0.01,
      "1998": 14.09,
      "1999": 7.43,
      "2000": 34.64,
      "2001": 0.03,
      "2002": 172.72,
      "2003": 30.82,
      "2004": 20397.65,
      "2005": 95.08,
      "2006": 5485.8,
      "2007": 98.28,
      "2008": 19754.74,
      "2009": 229.12,
      "2010": 2028.91,
      "2011": 1593.74,
      "2012": 55553.32,
      "2013": 305211.14,
      "2014": 413.04,
      "2015": 51061.23,
      "2016": 172764.65,
      "2017": 26090.62,
      "2018": 27517.98,
      "2019": 10265,
      "2020": 26347.56
    },
    "Baharain Is": {
      "1997": 49401.39,
      "1998": 197917.27,
      "1999": 68972.65,
      "2000": 92522.52,
      "2001": 63911.6,
      "2002": 58621.74,
      "2003": 34243.41,
      "2004": 54758.43,
      "2005": 83925.72,
      "2006": 213109.67,
      "2007": 333881.62,
      "2008": 636671.99,
      "2009": 238454.94,
      "2010": 291949.76,
      "2011": 432624.88,
      "2012": 359671.24,
      "2013": 344888.13,
      "2014": 272004.96,
      "2015": 233866.38,
      "2016": 195085.05,
      "2017": 278292.2,
      "2018": 377480.56,
      "2019": 299033.2,
      "2020": 404103.61
    },
    Bangladesh: {
      "1997": 18884.54,
      "1998": 26252.5,
      "1999": 33865.95,
      "2000": 36778.27,
      "2001": 28194.03,
      "2002": 30029.34,
      "2003": 35670.79,
      "2004": 26676.51,
      "2005": 56240.09,
      "2006": 103390.55,
      "2007": 103468.16,
      "2008": 141846.07,
      "2009": 120528.14,
      "2010": 203138.64,
      "2011": 279636.1,
      "2012": 346792.73,
      "2013": 290332.86,
      "2014": 379440.83,
      "2015": 476707.24,
      "2016": 470773.13,
      "2017": 441997.78,
      "2018": 733869.49,
      "2019": 897514.07,
      "2020": 805347.95
    },
    Barbados: {
      "1997": 3.8,
      "1998": 35.77,
      "1999": 16.11,
      "2000": 11.64,
      "2001": 4.67,
      "2002": 2.5,
      "2003": 23.92,
      "2004": 4.47,
      "2005": 15.58,
      "2006": 65.82,
      "2007": 13.07,
      "2008": 37.46,
      "2009": 95.62,
      "2010": 69.8,
      "2011": 363.41,
      "2012": 52.03,
      "2013": 68.7,
      "2014": 100.64,
      "2015": 107.46,
      "2016": 121.21,
      "2017": 128.98,
      "2018": 1227.96,
      "2019": 1046.94,
      "2020": 448.4
    },
    Belarus: {
      "1997": 30.62,
      "1998": 134.81,
      "1999": 668.6,
      "2000": 954.79,
      "2001": 2363.03,
      "2002": 3436.71,
      "2003": 3098.28,
      "2004": 5537.57,
      "2005": 16761.77,
      "2006": 42248.92,
      "2007": 50410.47,
      "2008": 125541.11,
      "2009": 206616.33,
      "2010": 93994.27,
      "2011": 89883.91,
      "2012": 116201.59,
      "2013": 94705.35,
      "2014": 111371.05,
      "2015": 106587.44,
      "2016": 114293.44,
      "2017": 133782.43,
      "2018": 158218.52,
      "2019": 103650.19,
      "2020": 213145.94
    },
    Belgium: {
      "1997": 991587.24,
      "1998": 1210285.36,
      "1999": 1595195.6,
      "2000": 1311164.68,
      "2001": 1317726.62,
      "2002": 1796398.83,
      "2003": 1826986.34,
      "2004": 2061865.48,
      "2005": 2091983.27,
      "2006": 1874160.28,
      "2007": 1754571.92,
      "2008": 2605788.66,
      "2009": 2846578.31,
      "2010": 3917866.54,
      "2011": 4975727.42,
      "2012": 5453675.79,
      "2013": 6467230.82,
      "2014": 6593873.86,
      "2015": 5400131.46,
      "2016": 4439563.86,
      "2017": 3864569.12,
      "2018": 7300229.11,
      "2019": 6283075.35,
      "2020": 5124677.1
    },
    Belize: {
      "1997": 0,
      "1998": 0,
      "1999": null,
      "2000": 0.89,
      "2001": 0.03,
      "2002": 233.02,
      "2003": null,
      "2004": 4.45,
      "2005": 13.8,
      "2006": 2670.88,
      "2007": 5408.71,
      "2008": 48.19,
      "2009": 174.38,
      "2010": 95.1,
      "2011": 38.02,
      "2012": 114.9,
      "2013": 943.78,
      "2014": 130.36,
      "2015": 651.66,
      "2016": 348.61,
      "2017": 876.9,
      "2018": 451.78,
      "2019": 672.63,
      "2020": 371.57
    },
    Benin: {
      "1997": 5022.81,
      "1998": 5398.28,
      "1999": 18520.92,
      "2000": 23793.04,
      "2001": 20533.69,
      "2002": 18481.23,
      "2003": 24441.65,
      "2004": 35849.35,
      "2005": 34299.65,
      "2006": 36563.01,
      "2007": 28987.66,
      "2008": 49119.57,
      "2009": 59721.3,
      "2010": 70043.33,
      "2011": 119981.42,
      "2012": 134996.28,
      "2013": 100098.27,
      "2014": 134270.18,
      "2015": 177788.1,
      "2016": 139130.1,
      "2017": 143688.67,
      "2018": 260431.23,
      "2019": 253394.3,
      "2020": 243990.03
    },
    Bermuda: {
      "1997": 9.05,
      "1998": 0.04,
      "1999": 0.01,
      "2000": null,
      "2001": 130.39,
      "2002": null,
      "2003": null,
      "2004": 585.64,
      "2005": 19.91,
      "2006": 250.24,
      "2007": 155.04,
      "2008": 31262.39,
      "2009": 93.99,
      "2010": 1.77,
      "2011": null,
      "2012": 24.22,
      "2013": null,
      "2014": 57.7,
      "2015": 0.2,
      "2016": 11,
      "2017": 2.75,
      "2018": 5342.19,
      "2019": 0.01,
      "2020": 29.15
    },
    Bhutan: {
      "1997": 4994.65,
      "1998": 2577.76,
      "1999": 7806.04,
      "2000": 9634.97,
      "2001": 11408.66,
      "2002": 15561.04,
      "2003": 24065.51,
      "2004": 31902.98,
      "2005": 39301.99,
      "2006": 64000.12,
      "2007": 78260.06,
      "2008": 68786.18,
      "2009": 72298.5,
      "2010": 91735.68,
      "2011": 97357.11,
      "2012": 89244.65,
      "2013": 91932.97,
      "2014": 91527.93,
      "2015": 182684.98,
      "2016": 206299.31,
      "2017": 243580.96,
      "2018": 259008.62,
      "2019": 287076.86,
      "2020": 321422.74
    },
    Bolivia: {
      "1997": 2.52,
      "1998": 16.32,
      "1999": 18.19,
      "2000": 6560.93,
      "2001": 1296.28,
      "2002": 496.59,
      "2003": 795.42,
      "2004": 96.06,
      "2005": 417.16,
      "2006": 1332.79,
      "2007": 1427.16,
      "2008": 3421.21,
      "2009": 1589.63,
      "2010": 3048.25,
      "2011": 1932.69,
      "2012": 4002.79,
      "2013": 1443.16,
      "2014": 2191.87,
      "2015": 158033.53,
      "2016": 116654.77,
      "2017": 430403.16,
      "2018": 598857.66,
      "2019": 599581.85,
      "2020": 853717.82
    },
    "Bosnia and Herzegovina": {
      "1997": 0,
      "1998": 0,
      "1999": 6.13,
      "2000": 162.05,
      "2001": 30.57,
      "2002": 1602.57,
      "2003": 936.14,
      "2004": 54.87,
      "2005": 173.4,
      "2006": 190.71,
      "2007": 4922.6,
      "2008": 632.41,
      "2009": 239.31,
      "2010": 8604.01,
      "2011": 8631.3,
      "2012": 10592.59,
      "2013": 1006.65,
      "2014": 2607.82,
      "2015": 2874.95,
      "2016": 2226.79,
      "2017": 5638.05,
      "2018": 1711.64,
      "2019": 2374.83,
      "2020": 1425.31
    },
    Botswana: {
      "1997": 0.1,
      "1998": null,
      "1999": null,
      "2000": 0.63,
      "2001": 0.05,
      "2002": 3.08,
      "2003": 24.43,
      "2004": 187.57,
      "2005": 63.13,
      "2006": 26.01,
      "2007": 0.25,
      "2008": 9778.74,
      "2009": 3275.86,
      "2010": 12165.86,
      "2011": 25398.22,
      "2012": 31281.84,
      "2013": 236081.22,
      "2014": 616766.77,
      "2015": 353875.58,
      "2016": 876627.31,
      "2017": 1056068.63,
      "2018": 680985.35,
      "2019": 528431.82,
      "2020": 339400.69
    },
    "Br Virgn Is": {
      "1997": 0,
      "1998": 0,
      "1999": 0.24,
      "2000": null,
      "2001": null,
      "2002": 43.02,
      "2003": 0.55,
      "2004": 0.97,
      "2005": 82.48,
      "2006": 8712.27,
      "2007": 70.95,
      "2008": 147.86,
      "2009": 77.57,
      "2010": 397.61,
      "2011": 204.99,
      "2012": 535.8,
      "2013": 97.53,
      "2014": 501.21,
      "2015": 1031.6,
      "2016": 4499.18,
      "2017": 565.22,
      "2018": 11714.91,
      "2019": 6053.71,
      "2020": 8174.1
    },
    Brazil: {
      "1997": 74037.51,
      "1998": 82380,
      "1999": 143385.26,
      "2000": 66318.94,
      "2001": 146973.63,
      "2002": 153310.57,
      "2003": 144066.71,
      "2004": 356035.76,
      "2005": 395386.84,
      "2006": 448731.16,
      "2007": 381813.84,
      "2008": 544949.69,
      "2009": 1625997.86,
      "2010": 1606429.86,
      "2011": 2042032.01,
      "2012": 2643882.72,
      "2013": 2254729.99,
      "2014": 3303653.63,
      "2015": 2639248.46,
      "2016": 2765261.71,
      "2017": 3542854.16,
      "2018": 3083996.67,
      "2019": 2182203.85,
      "2020": 2234485.65
    },
    "Brunei Darussalam": {
      "1997": 7.05,
      "1998": 18.95,
      "1999": 47.44,
      "2000": 69.26,
      "2001": 169.6,
      "2002": 156.29,
      "2003": 154.97,
      "2004": 242.27,
      "2005": 389.35,
      "2006": 129068.31,
      "2007": 90868.7,
      "2008": 188194.37,
      "2009": 202852.73,
      "2010": 106485.7,
      "2011": 285369.97,
      "2012": 440597.91,
      "2013": 457496.7,
      "2014": 513396.97,
      "2015": 361037.07,
      "2016": 420846.95,
      "2017": 280459.23,
      "2018": 412202.7,
      "2019": 417035.82,
      "2020": 242739.7
    },
    Bulgaria: {
      "1997": 4306.05,
      "1998": 914.3,
      "1999": 16694.18,
      "2000": 5959.65,
      "2001": 7699.34,
      "2002": 4090.02,
      "2003": 5117.7,
      "2004": 8067.64,
      "2005": 10653.7,
      "2006": 35181.48,
      "2007": 48163.66,
      "2008": 27187.64,
      "2009": 15470.76,
      "2010": 26266.37,
      "2011": 47006.33,
      "2012": 49112.72,
      "2013": 56792.55,
      "2014": 63418.9,
      "2015": 61564.98,
      "2016": 122007.72,
      "2017": 91573.5,
      "2018": 87564.22,
      "2019": 101849.78,
      "2020": 92850.81
    },
    "Burkina Faso": {
      "1997": null,
      "1998": 895.05,
      "1999": 5524.15,
      "2000": 4802.18,
      "2001": 6060.97,
      "2002": 4639.89,
      "2003": 4804.15,
      "2004": 3792.39,
      "2005": 1191.19,
      "2006": 2517.05,
      "2007": 7793.52,
      "2008": 18889.87,
      "2009": 2934.78,
      "2010": 1895.13,
      "2011": 7767.35,
      "2012": 7960.96,
      "2013": 10094.28,
      "2014": 93735.45,
      "2015": 155032.22,
      "2016": 171503.61,
      "2017": 408139.06,
      "2018": 614860.58,
      "2019": 383941.87,
      "2020": 265072.62
    },
    Burundi: {
      "1997": null,
      "1998": 65.97,
      "1999": null,
      "2000": 34.58,
      "2001": 31.08,
      "2002": null,
      "2003": 138.59,
      "2004": 194.24,
      "2005": 16.21,
      "2006": 2.03,
      "2007": 742.95,
      "2008": 323.13,
      "2009": 353.81,
      "2010": 110.15,
      "2011": 293.07,
      "2012": 124.31,
      "2013": 112.13,
      "2014": 179.21,
      "2015": 70.57,
      "2016": 1069.45,
      "2017": 10646.98,
      "2018": 3082.01,
      "2019": 2689.5,
      "2020": 51.37
    },
    "Central African Republic": {
      "1997": null,
      "1998": 0.45,
      "1999": null,
      "2000": 64.73,
      "2001": 2169.74,
      "2002": null,
      "2003": 56.57,
      "2004": 115.4,
      "2005": 162.24,
      "2006": 200.71,
      "2007": 461.53,
      "2008": 1149.03,
      "2009": 313.86,
      "2010": 700.9,
      "2011": 963.1,
      "2012": 1090.02,
      "2013": 547.19,
      "2014": 384.69,
      "2015": 544.08,
      "2016": 253.99,
      "2017": 6367.75,
      "2018": 1414.18,
      "2019": 397.06,
      "2020": 168.61
    },
    Cambodia: {
      "1997": 562.92,
      "1998": 1026.45,
      "1999": 106.14,
      "2000": 604.75,
      "2001": 532.35,
      "2002": 294.46,
      "2003": 126.77,
      "2004": 108.27,
      "2005": 343.61,
      "2006": 714.94,
      "2007": 1155.25,
      "2008": 1197.33,
      "2009": 2405.9,
      "2010": 3637.69,
      "2011": 3463.66,
      "2012": 6469.52,
      "2013": 7729.41,
      "2014": 10978.57,
      "2015": 35973.42,
      "2016": 24213.58,
      "2017": 35970.85,
      "2018": 29827.59,
      "2019": 33060.57,
      "2020": 29138.91
    },
    Cameroon: {
      "1997": 6064.72,
      "1998": 2165.44,
      "1999": 4092.51,
      "2000": 2697.52,
      "2001": 4210.81,
      "2002": 3181.42,
      "2003": 4731.85,
      "2004": 5262.64,
      "2005": 5413.18,
      "2006": 3443.26,
      "2007": 7571.91,
      "2008": 14390.41,
      "2009": 63985.51,
      "2010": 62759.24,
      "2011": 246180.5,
      "2012": 241352.92,
      "2013": 162409.49,
      "2014": 454151.41,
      "2015": 361947.4,
      "2016": 241123.52,
      "2017": 137331.13,
      "2018": 235575.89,
      "2019": 479379.09,
      "2020": 148600.28
    },
    Canada: {
      "1997": 156431.12,
      "1998": 162212.66,
      "1999": 164883.66,
      "2000": 181400.33,
      "2001": 252496.69,
      "2002": 274058.15,
      "2003": 333556.75,
      "2004": 348543.47,
      "2005": 407258.01,
      "2006": 804269.25,
      "2007": 794017.51,
      "2008": 1129675.53,
      "2009": 995411.34,
      "2010": 924330.56,
      "2011": 1411266.24,
      "2012": 1527698.7,
      "2013": 1902983.03,
      "2014": 2296607.16,
      "2015": 2779277.24,
      "2016": 2772611.24,
      "2017": 3047906.85,
      "2018": 2465576.68,
      "2019": 2742345.93,
      "2020": 1995930.67
    },
    "Cape Verde Is": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 0.78,
      "2006": 93.05,
      "2007": 1.07,
      "2008": 159.85,
      "2009": 54.63,
      "2010": 950.91,
      "2011": 1617.64,
      "2012": 1857.14,
      "2013": 1883.94,
      "2014": 1828.58,
      "2015": 1805.56,
      "2016": 1276.72,
      "2017": 1816.12,
      "2018": 2032.26,
      "2019": 1443.44,
      "2020": 1506.76
    },
    "Canary Is": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 2.6,
      "2002": null,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 0,
      "2008": 0,
      "2009": 0,
      "2010": 0,
      "2011": 0,
      "2012": 0,
      "2013": 0,
      "2014": 0,
      "2015": 0,
      "2016": 0,
      "2017": 0,
      "2018": 0,
      "2019": 0,
      "2020": 0
    },
    "Cayman Is": {
      "1997": null,
      "1998": 10.7,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": null,
      "2004": 1.77,
      "2005": 10.87,
      "2006": 26533.75,
      "2007": 65058.47,
      "2008": 58.67,
      "2009": 692.36,
      "2010": 11.75,
      "2011": 3.9,
      "2012": 0.11,
      "2013": 7.69,
      "2014": 0.23,
      "2015": null,
      "2016": 12.71,
      "2017": 4166,
      "2018": 165.42,
      "2019": 1030.28,
      "2020": 63204.7
    },
    Chad: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": null,
      "2002": 907.76,
      "2003": 1581.82,
      "2004": 701.81,
      "2005": 32.14,
      "2006": 74.65,
      "2007": 394.76,
      "2008": 1883.47,
      "2009": 3323.91,
      "2010": 258.22,
      "2011": 19983.8,
      "2012": 84524.18,
      "2013": 6784.39,
      "2014": 38954.85,
      "2015": 207730.58,
      "2016": 113839.58,
      "2017": 96153.19,
      "2018": 335532.33,
      "2019": 191444.39,
      "2020": 36743.17
    },
    "Channel Is": {
      "1997": null,
      "1998": 132.53,
      "1999": 109.64,
      "2000": 24.27,
      "2001": 22.49,
      "2002": null,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 0,
      "2008": 0,
      "2009": 0.08,
      "2010": null,
      "2011": 0,
      "2012": 0,
      "2013": 0,
      "2014": 0,
      "2015": 0,
      "2016": 0,
      "2017": 0,
      "2018": 0,
      "2019": 0,
      "2020": 0
    },
    Chile: {
      "1997": 26779.46,
      "1998": 23679.75,
      "1999": 37043.76,
      "2000": 26104.14,
      "2001": 45206.6,
      "2002": 80969.55,
      "2003": 72022,
      "2004": 155270.24,
      "2005": 192368.44,
      "2006": 867814.22,
      "2007": 741939.94,
      "2008": 666443.07,
      "2009": 524722.57,
      "2010": 705638.27,
      "2011": 1028955.78,
      "2012": 1621374.25,
      "2013": 1522960.2,
      "2014": 1880589.92,
      "2015": 1275942.54,
      "2016": 822920.98,
      "2017": 1347763.42,
      "2018": 864230.97,
      "2019": 831272.84,
      "2020": 497917.28
    },
    China: {
      "1997": 413292.94,
      "1998": 461392.99,
      "1999": 555912.4,
      "2000": 686269.56,
      "2001": 971192.4,
      "2002": 1351215.18,
      "2003": 1862513.84,
      "2004": 3189230.68,
      "2005": 4811665.23,
      "2006": 7900860.72,
      "2007": 10911607.12,
      "2008": 14760559.5,
      "2009": 14604861.2,
      "2010": 19807907.58,
      "2011": 26546561.9,
      "2012": 28438458.52,
      "2013": 30923495.99,
      "2014": 36956536.01,
      "2015": 40405084.15,
      "2016": 41110329.33,
      "2017": 49223616.54,
      "2018": 49207928.34,
      "2019": 46152476.82,
      "2020": 48249579.9
    },
    "Christmas Is.": {
      "1997": 60.63,
      "1998": 125.88,
      "1999": 58.52,
      "2000": 28.28,
      "2001": 5.16,
      "2002": null,
      "2003": 16.46,
      "2004": null,
      "2005": 29.72,
      "2006": 6971.82,
      "2007": 284.11,
      "2008": 260.81,
      "2009": 3155.04,
      "2010": 26.41,
      "2011": 0.84,
      "2012": 41.82,
      "2013": 40.46,
      "2014": 50.32,
      "2015": 0.28,
      "2016": 11.8,
      "2017": 428.99,
      "2018": null,
      "2019": 10.67,
      "2020": 1.47
    },
    "Cocos Is": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": null,
      "2002": 24.24,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 6.37,
      "2008": 18.63,
      "2009": 9.92,
      "2010": 22188.55,
      "2011": 14.72,
      "2012": null,
      "2013": 0.72,
      "2014": null,
      "2015": 0,
      "2016": 0,
      "2017": 3.05,
      "2018": 1.5,
      "2019": 0,
      "2020": 0
    },
    Colombia: {
      "1997": 2234.69,
      "1998": 840.16,
      "1999": 1196.91,
      "2000": 4449.38,
      "2001": 2574.17,
      "2002": 2821.35,
      "2003": 4809.72,
      "2004": 6365.48,
      "2005": 4142.25,
      "2006": 34668.6,
      "2007": 33756.81,
      "2008": 8104.24,
      "2009": 200909.96,
      "2010": 386557.8,
      "2011": 272312.36,
      "2012": 1281251.6,
      "2013": 2999627.9,
      "2014": 1307005.55,
      "2015": 526588.92,
      "2016": 398499.97,
      "2017": 382321.78,
      "2018": 739350.31,
      "2019": 576842.95,
      "2020": 1038999.73
    },
    Comoros: {
      "1997": 93.49,
      "1998": 17.22,
      "1999": null,
      "2000": 0.02,
      "2001": 428.83,
      "2002": 154.6,
      "2003": 222.06,
      "2004": 67.03,
      "2005": 1719.72,
      "2006": 3142.49,
      "2007": 834,
      "2008": 143.44,
      "2009": 320.18,
      "2010": 3823.66,
      "2011": 1469.54,
      "2012": 4352.81,
      "2013": 4070.76,
      "2014": 6965.65,
      "2015": 10009.21,
      "2016": 17274.05,
      "2017": 24015.19,
      "2018": 23057.97,
      "2019": 14047.07,
      "2020": 9243.32
    },
    "Democratic Republic of the Congo": {
      "1997": 11930.76,
      "1998": 2747.27,
      "1999": 80.11,
      "2000": 126.67,
      "2001": 57.18,
      "2002": 1.24,
      "2003": 43.32,
      "2004": 1140.61,
      "2005": 6289.91,
      "2006": 7709.94,
      "2007": 5547.29,
      "2008": 47379.75,
      "2009": 67714.27,
      "2010": 4474.81,
      "2011": 456.04,
      "2012": null,
      "2013": 27727.47,
      "2014": 77030.74,
      "2015": 62407.84,
      "2016": 57586.98,
      "2017": 145287.21,
      "2018": 13330.13,
      "2019": 22754.59,
      "2020": 7580.74
    },
    "Republic of Congo": {
      "1997": 865.85,
      "1998": 2835.14,
      "1999": 420.93,
      "2000": 3874.47,
      "2001": 856.77,
      "2002": 1573.02,
      "2003": 2424.43,
      "2004": 9872.98,
      "2005": 19454.58,
      "2006": 27036.17,
      "2007": 41967.83,
      "2008": 218453.37,
      "2009": 256994.16,
      "2010": 248015.56,
      "2011": 127482.83,
      "2012": 250088.34,
      "2013": 53932.6,
      "2014": 223113.33,
      "2015": 130467.86,
      "2016": 104938.3,
      "2017": 128321.44,
      "2018": 286251.85,
      "2019": 409625.42,
      "2020": 182969.96
    },
    "Cook Is": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": null,
      "2002": 2.8,
      "2003": 0,
      "2004": 0,
      "2005": 3.38,
      "2006": 12.14,
      "2007": null,
      "2008": 0.2,
      "2009": null,
      "2010": 36.77,
      "2011": 17.29,
      "2012": null,
      "2013": null,
      "2014": 1.67,
      "2015": 0,
      "2016": 0,
      "2017": 4.79,
      "2018": 13.45,
      "2019": null,
      "2020": 24.76
    },
    "Costa Rica": {
      "1997": 127.48,
      "1998": 626.27,
      "1999": 383.26,
      "2000": 400.05,
      "2001": 607.29,
      "2002": 2561.78,
      "2003": 11768.09,
      "2004": 15935.09,
      "2005": 16761.49,
      "2006": 19875.75,
      "2007": 35584.56,
      "2008": 33977.75,
      "2009": 44336.25,
      "2010": 43596.26,
      "2011": 97882.74,
      "2012": 119693.27,
      "2013": 123594.06,
      "2014": 94184.83,
      "2015": 40268.08,
      "2016": 39423.69,
      "2017": 43361.78,
      "2018": 34962.17,
      "2019": 34274.55,
      "2020": 35044.57
    },
    "Cote D' Ivoire": {
      "1997": 19192.66,
      "1998": 16029.64,
      "1999": 42239.09,
      "2000": 56239.23,
      "2001": 36412.3,
      "2002": 41632.61,
      "2003": 45845.65,
      "2004": 71928.94,
      "2005": 85857.23,
      "2006": 82202.22,
      "2007": 80217.68,
      "2008": 145197.24,
      "2009": 142477.24,
      "2010": 115212.62,
      "2011": 225893.57,
      "2012": 210488.8,
      "2013": 179056.37,
      "2014": 321569.28,
      "2015": 369707.74,
      "2016": 305620.48,
      "2017": 284686.48,
      "2018": 419570.92,
      "2019": 319299.75,
      "2020": 235214.51
    },
    Croatia: {
      "1997": 404.04,
      "1998": 416.91,
      "1999": 96.82,
      "2000": 462.72,
      "2001": 470.26,
      "2002": 308.33,
      "2003": 3424.23,
      "2004": 924.25,
      "2005": 13055.71,
      "2006": 25813.1,
      "2007": 7430.87,
      "2008": 7137.32,
      "2009": 12579.35,
      "2010": 13001.56,
      "2011": 11490.38,
      "2012": 9920.15,
      "2013": 5845.9,
      "2014": 17353.95,
      "2015": 23564.02,
      "2016": 17002.67,
      "2017": 20648.25,
      "2018": 26846.77,
      "2019": 40345.57,
      "2020": 31549.48
    },
    Cuba: {
      "1997": 2140.28,
      "1998": 1569.33,
      "1999": 1987.74,
      "2000": 2284.8,
      "2001": 1809.88,
      "2002": 1759.77,
      "2003": 849.04,
      "2004": 776.21,
      "2005": 1468.98,
      "2006": 503.61,
      "2007": 5735.42,
      "2008": 616.25,
      "2009": 501.87,
      "2010": 502.85,
      "2011": 1888.32,
      "2012": 2159.65,
      "2013": 1469.38,
      "2014": 960.67,
      "2015": 877.3,
      "2016": 878.5,
      "2017": 1326.59,
      "2018": 2661.41,
      "2019": 2522.21,
      "2020": 51444.87
    },
    Cyprus: {
      "1997": 815.68,
      "1998": 238.1,
      "1999": 843.25,
      "2000": 562.41,
      "2001": 3028.16,
      "2002": 1339.74,
      "2003": 1195.38,
      "2004": 2170.99,
      "2005": 11302.38,
      "2006": 50082.04,
      "2007": 58529.75,
      "2008": 68127.22,
      "2009": 6672.31,
      "2010": 9303.58,
      "2011": 13016.52,
      "2012": 11588.51,
      "2013": 12183.26,
      "2014": 13969.5,
      "2015": 31392.27,
      "2016": 44483.01,
      "2017": 8673.89,
      "2018": 273690.87,
      "2019": 52950.61,
      "2020": 17778.41
    },
    "Czech Republic": {
      "1997": 15730.54,
      "1998": 18563.75,
      "1999": 17093.34,
      "2000": 16632.73,
      "2001": 18423.04,
      "2002": 41368.06,
      "2003": 51390.89,
      "2004": 78782.03,
      "2005": 115279.09,
      "2006": 160068.12,
      "2007": 179893.01,
      "2008": 218371.15,
      "2009": 265889.52,
      "2010": 307923.8,
      "2011": 342663.31,
      "2012": 350212.17,
      "2013": 313987.16,
      "2014": 316601.8,
      "2015": 331895.22,
      "2016": 361760.83,
      "2017": 431538.36,
      "2018": 180771.42,
      "2019": 212747.87,
      "2020": 290622.49
    },
    Denmark: {
      "1997": 33732.36,
      "1998": 41944.49,
      "1999": 58680.67,
      "2000": 65099.53,
      "2001": 57522.52,
      "2002": 69379.48,
      "2003": 103667.2,
      "2004": 121414,
      "2005": 228424.71,
      "2006": 153925.59,
      "2007": 186886.22,
      "2008": 218073.47,
      "2009": 282876.68,
      "2010": 215368.79,
      "2011": 293219.65,
      "2012": 294972.26,
      "2013": 269152.23,
      "2014": 280094.3,
      "2015": 281196.34,
      "2016": 323083.97,
      "2017": 364067.88,
      "2018": 375277.2,
      "2019": 448431.76,
      "2020": 436570.52
    },
    Djibouti: {
      "1997": 56.89,
      "1998": 10.39,
      "1999": 96.64,
      "2000": 207.81,
      "2001": 352.85,
      "2002": 396.85,
      "2003": 547.99,
      "2004": 1399.82,
      "2005": 1484.6,
      "2006": 970.07,
      "2007": 1830.79,
      "2008": 1685.39,
      "2009": 564.31,
      "2010": 1093.92,
      "2011": 1032.84,
      "2012": 2811.38,
      "2013": 2426.24,
      "2014": 904.18,
      "2015": 793.38,
      "2016": 1897.17,
      "2017": 3508.9,
      "2018": 11004.01,
      "2019": 13769.32,
      "2020": 14901.46
    },
    "Dominican Republic": {
      "1997": null,
      "1998": 15.51,
      "1999": 186.58,
      "2000": 47.72,
      "2001": null,
      "2002": 56.7,
      "2003": 801.56,
      "2004": 1239.8,
      "2005": 2365.81,
      "2006": 822.3,
      "2007": 1135.27,
      "2008": 4763.87,
      "2009": 4476.22,
      "2010": 7217.24,
      "2011": 3184.58,
      "2012": 5901.94,
      "2013": 7584,
      "2014": 180321.98,
      "2015": 314610.48,
      "2016": 451477.44,
      "2017": 417233.01,
      "2018": 399987.7,
      "2019": 254877.02,
      "2020": 188089.43
    },
    Dominica: {
      "1997": 4.75,
      "1998": null,
      "1999": null,
      "2000": 0.01,
      "2001": 33.14,
      "2002": 6.21,
      "2003": 49.31,
      "2004": 152.92,
      "2005": 75.77,
      "2006": 169.39,
      "2007": 425.35,
      "2008": 291.4,
      "2009": 231.5,
      "2010": 153.85,
      "2011": 132.84,
      "2012": 862.31,
      "2013": 42.98,
      "2014": 62.7,
      "2015": 62.29,
      "2016": 524.49,
      "2017": 177.13,
      "2018": 222.24,
      "2019": 174.96,
      "2020": 184.06
    },
    Ecuador: {
      "1997": 1922.9,
      "1998": 373.56,
      "1999": 3741.79,
      "2000": 3519.36,
      "2001": 2195.41,
      "2002": 1024.91,
      "2003": 2682.81,
      "2004": 10306.65,
      "2005": 9000.59,
      "2006": 22318.8,
      "2007": 87230.92,
      "2008": 15004.57,
      "2009": 32176.25,
      "2010": 75495,
      "2011": 21670.25,
      "2012": 470824.03,
      "2013": 158780.52,
      "2014": 641478.47,
      "2015": 365202.17,
      "2016": 238436.18,
      "2017": 125030.2,
      "2018": 153989.86,
      "2019": 255549.59,
      "2020": 247931.7
    },
    Egypt: {
      "1997": 71585.42,
      "1998": 80941.35,
      "1999": 192338.99,
      "2000": 17721.66,
      "2001": 47664.21,
      "2002": 109650.4,
      "2003": 45127.23,
      "2004": 68585.21,
      "2005": 97598.66,
      "2006": 788705.58,
      "2007": 798278.2,
      "2008": 976506.19,
      "2009": 803588.19,
      "2010": 615785.56,
      "2011": 1435052.72,
      "2012": 1385426.15,
      "2013": 1439114.11,
      "2014": 1064543.8,
      "2015": 792178.69,
      "2016": 781042.81,
      "2017": 833268.83,
      "2018": 1169816.17,
      "2019": 1439089.09,
      "2020": 1401948.41
    },
    "El Salvador": {
      "1997": null,
      "1998": 172.78,
      "1999": 95.56,
      "2000": null,
      "2001": 285.03,
      "2002": 568.28,
      "2003": 500.56,
      "2004": 823.33,
      "2005": 913.79,
      "2006": 1127.53,
      "2007": 2358.84,
      "2008": 2665.85,
      "2009": 2545.6,
      "2010": 2458.04,
      "2011": 3961.8,
      "2012": 4718.83,
      "2013": 4811.83,
      "2014": 6304.36,
      "2015": 4016.46,
      "2016": 3871.05,
      "2017": 5823.11,
      "2018": 2943.64,
      "2019": 2267.15,
      "2020": 2840.22
    },
    "Equatorial Guinea": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": null,
      "2002": 5.44,
      "2003": 120.01,
      "2004": 325.15,
      "2005": 32.45,
      "2006": 47.2,
      "2007": 66050.25,
      "2008": 88.42,
      "2009": 125757.52,
      "2010": 55.83,
      "2011": 104437.74,
      "2012": 286584.21,
      "2013": 186861.36,
      "2014": 458072.28,
      "2015": 301162.32,
      "2016": 535255.58,
      "2017": 486860.6,
      "2018": 442648.96,
      "2019": 685782.18,
      "2020": 211394.33
    },
    Eritrea: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 113.96,
      "2004": 446.46,
      "2005": 438.36,
      "2006": 154.38,
      "2007": 600.78,
      "2008": 2740.54,
      "2009": 77.7,
      "2010": 344.47,
      "2011": 1686.45,
      "2012": 5943.04,
      "2013": 2885.95,
      "2014": 140875.5,
      "2015": 109347.39,
      "2016": 24223.59,
      "2017": 1264.02,
      "2018": 0.49,
      "2019": 0.04,
      "2020": 0.71
    },
    Estonia: {
      "1997": 6.14,
      "1998": 270.39,
      "1999": 370.39,
      "2000": 145.12,
      "2001": 3059.99,
      "2002": 326.7,
      "2003": 109.93,
      "2004": 322.46,
      "2005": 3992.64,
      "2006": 11869.63,
      "2007": 4328.14,
      "2008": 7138.32,
      "2009": 15805.44,
      "2010": 20313.87,
      "2011": 115121.34,
      "2012": 120127.66,
      "2013": 71008.87,
      "2014": 72012.34,
      "2015": 91316.96,
      "2016": 68705.77,
      "2017": 39029.94,
      "2018": 75530.67,
      "2019": 38333.84,
      "2020": 44679.46
    },
    Ethiopia: {
      "1997": 284.62,
      "1998": 124.02,
      "1999": 376.2,
      "2000": 1752.18,
      "2001": 8771.9,
      "2002": 5106.33,
      "2003": 3463.44,
      "2004": 4609.62,
      "2005": 3770.97,
      "2006": 5136.59,
      "2007": 5482.41,
      "2008": 5127.45,
      "2009": 8814.35,
      "2010": 14939.94,
      "2011": 13865.92,
      "2012": 20930.3,
      "2013": 16960.66,
      "2014": 27166.54,
      "2015": 39876.89,
      "2016": 44901.56,
      "2017": 30609.19,
      "2018": 37759.7,
      "2019": 69431.59,
      "2020": 24209.81
    },
    "Falkland Is": {
      "1997": 0,
      "1998": 0,
      "1999": 12.97,
      "2000": null,
      "2001": null,
      "2002": null,
      "2003": 0,
      "2004": 0,
      "2005": "",
      "2006": 6.4,
      "2007": 0.93,
      "2008": 436.63,
      "2009": 365.94,
      "2010": 619.61,
      "2011": 1375.63,
      "2012": 795.95,
      "2013": 988.14,
      "2014": 1589.85,
      "2015": 1107.79,
      "2016": null,
      "2017": 0,
      "2018": null,
      "2019": 22.23,
      "2020": null
    },
    "Faroe Is.": {
      "1997": null,
      "1998": 34.99,
      "1999": null,
      "2000": 20.42,
      "2001": null,
      "2002": 7.61,
      "2003": 0,
      "2004": 0,
      "2005": 10.36,
      "2006": 12.99,
      "2007": 71.34,
      "2008": 11.64,
      "2009": 29.25,
      "2010": 3323.77,
      "2011": 19.1,
      "2012": null,
      "2013": 0.16,
      "2014": 39.86,
      "2015": 23.1,
      "2016": 4.7,
      "2017": 3.48,
      "2018": 20.48,
      "2019": 0,
      "2020": 0
    },
    "Fiji Is": {
      "1997": 0.09,
      "1998": 0.08,
      "1999": 18.7,
      "2000": 0.04,
      "2001": 4.32,
      "2002": 6.47,
      "2003": 784.85,
      "2004": 135.18,
      "2005": 376.92,
      "2006": 8413.1,
      "2007": 103.55,
      "2008": 325.99,
      "2009": 638.2,
      "2010": 1355.23,
      "2011": 1194.01,
      "2012": 753.5,
      "2013": 1148.75,
      "2014": 1112.89,
      "2015": 243.76,
      "2016": 400.09,
      "2017": 314.67,
      "2018": 498.52,
      "2019": 404.15,
      "2020": 983.59
    },
    Finland: {
      "1997": 65332.18,
      "1998": 74875.86,
      "1999": 60130.36,
      "2000": 94745.57,
      "2001": 77305.54,
      "2002": 96304.52,
      "2003": 124111.65,
      "2004": 174699.15,
      "2005": 258326.33,
      "2006": 275924.79,
      "2007": 373001.4,
      "2008": 557769.67,
      "2009": 478772.54,
      "2010": 752769.42,
      "2011": 1009484.39,
      "2012": 600537.79,
      "2013": 642189.88,
      "2014": 559980.29,
      "2015": 655012.19,
      "2016": 679193.12,
      "2017": 902266.44,
      "2018": 779505.34,
      "2019": 442606.24,
      "2020": 527809.69
    },
    "Fr Guiana": {
      "1997": 144.12,
      "1998": 159.7,
      "1999": 649.68,
      "2000": 212.68,
      "2001": 0.03,
      "2002": null,
      "2003": 0,
      "2004": 0,
      "2005": 175.42,
      "2006": 72.38,
      "2007": 770.25,
      "2008": 2527.82,
      "2009": 288.34,
      "2010": 96.7,
      "2011": 50707.01,
      "2012": 742.44,
      "2013": 1529.24,
      "2014": 871.14,
      "2015": 777.34,
      "2016": 978.15,
      "2017": 550.71,
      "2018": 1284.66,
      "2019": 2017.21,
      "2020": 962.65
    },
    "Fr Polynesia": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": null,
      "2004": 3.27,
      "2005": 7.04,
      "2006": 12.42,
      "2007": 9.17,
      "2008": 44.78,
      "2009": 0.01,
      "2010": 4.96,
      "2011": 11.91,
      "2012": 7.89,
      "2013": 14.1,
      "2014": 16.69,
      "2015": 16.24,
      "2016": 7.8,
      "2017": 46.58,
      "2018": 7.09,
      "2019": 38.01,
      "2020": 54.48
    },
    "Fr S Ant Tr": {
      "1997": 4.45,
      "1998": null,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": null,
      "2004": 33.72,
      "2005": 1.23,
      "2006": 0.73,
      "2007": null,
      "2008": 1.92,
      "2009": null,
      "2010": 4.15,
      "2011": null,
      "2012": 12.25,
      "2013": 15.9,
      "2014": null,
      "2015": 17.92,
      "2016": 0,
      "2017": 0,
      "2018": 0,
      "2019": 0,
      "2020": 0
    },
    France: {
      "1997": 291737.38,
      "1998": 302739.64,
      "1999": 308448.63,
      "2000": 292749.39,
      "2001": 402640.07,
      "2002": 529531.54,
      "2003": 500976.76,
      "2004": 851046.97,
      "2005": 1821101.32,
      "2006": 1905933.21,
      "2007": 2517563.53,
      "2008": 2116519.65,
      "2009": 1982887.29,
      "2010": 1686657.13,
      "2011": 2082646,
      "2012": 2535102.98,
      "2013": 2233134.1,
      "2014": 2703005.36,
      "2015": 2442014.05,
      "2016": 3826374.09,
      "2017": 4203232.8,
      "2018": 4659809.26,
      "2019": 4366095.61,
      "2020": 3216077.19
    },
    Gabon: {
      "1997": 3794.76,
      "1998": 3756.68,
      "1999": 2276.6,
      "2000": 6631.52,
      "2001": 4888.87,
      "2002": 10174.27,
      "2003": 10314.2,
      "2004": 19231.65,
      "2005": 18636.77,
      "2006": 52302.36,
      "2007": 48520.64,
      "2008": 76962.53,
      "2009": 86373.14,
      "2010": 141203.42,
      "2011": 72108.43,
      "2012": 447986.89,
      "2013": 507703.34,
      "2014": 481078.29,
      "2015": 69135.71,
      "2016": 46622.2,
      "2017": 254712.03,
      "2018": 319302.77,
      "2019": 97829.22,
      "2020": 299620.39
    },
    "The Gambia": {
      "1997": null,
      "1998": 326.6,
      "1999": 274.1,
      "2000": 110.33,
      "2001": 245.87,
      "2002": 2189.6,
      "2003": 1731.94,
      "2004": 5175.57,
      "2005": 5712.52,
      "2006": 8104.79,
      "2007": 6079.55,
      "2008": 12492.6,
      "2009": 13959.19,
      "2010": 6822.56,
      "2011": 18672,
      "2012": 16650.39,
      "2013": 18222.61,
      "2014": 21959.34,
      "2015": 20384.17,
      "2016": 28957.26,
      "2017": 37795.06,
      "2018": 31578.68,
      "2019": 28898.24,
      "2020": 28516.31
    },
    Georgia: {
      "1997": 0,
      "1998": 0,
      "1999": 5.36,
      "2000": 265.66,
      "2001": 15.36,
      "2002": 3262.2,
      "2003": 2495.08,
      "2004": 6926.24,
      "2005": 8798.2,
      "2006": 33956.51,
      "2007": 4304.82,
      "2008": 7409.24,
      "2009": 4858.48,
      "2010": 17232.22,
      "2011": 27881.52,
      "2012": 31431.26,
      "2013": 14395.04,
      "2014": 11181.6,
      "2015": 15996.15,
      "2016": 21145.61,
      "2017": 17415.07,
      "2018": 15609.39,
      "2019": 8078.39,
      "2020": 63839.42
    },
    Germany: {
      "1997": 939637.59,
      "1998": 900598.21,
      "1999": 797751.99,
      "2000": 803857.32,
      "2001": 967238.31,
      "2002": 1163677.29,
      "2003": 1341124.14,
      "2004": 1804156.35,
      "2005": 2666872.86,
      "2006": 3414674.94,
      "2007": 3973603.74,
      "2008": 5492241.61,
      "2009": 4888575.63,
      "2010": 5413601.64,
      "2011": 7484059.5,
      "2012": 7793366.1,
      "2013": 7820963.81,
      "2014": 7818109.63,
      "2015": 7909817.69,
      "2016": 7770441.21,
      "2017": 8570020.47,
      "2018": 10613052.41,
      "2019": 9692835.06,
      "2020": 10110482.47
    },
    Ghana: {
      "1997": 1842.69,
      "1998": 2304.79,
      "1999": 4892.23,
      "2000": 12379.7,
      "2001": 9501.32,
      "2002": 9017.88,
      "2003": 15132.04,
      "2004": 23038.66,
      "2005": 34943.93,
      "2006": 46701.35,
      "2007": 56554.48,
      "2008": 77856.21,
      "2009": 70019.94,
      "2010": 72943.64,
      "2011": 159109.74,
      "2012": 151596.27,
      "2013": 225026.87,
      "2014": 774069.89,
      "2015": 1940467.7,
      "2016": 1301536.24,
      "2017": 1748430.05,
      "2018": 2620638.11,
      "2019": 1249958.46,
      "2020": 1017266.42
    },
    Gibraltar: {
      "1997": null,
      "1998": 0.06,
      "1999": 0,
      "2000": 0,
      "2001": null,
      "2002": 207.46,
      "2003": null,
      "2004": 1.75,
      "2005": 158.56,
      "2006": 5.43,
      "2007": 98.49,
      "2008": null,
      "2009": 2.02,
      "2010": 5388.55,
      "2011": 28.94,
      "2012": 49.71,
      "2013": 83.56,
      "2014": 0.21,
      "2015": 0,
      "2016": 0,
      "2017": 0,
      "2018": 0,
      "2019": 0,
      "2020": 0
    },
    Greece: {
      "1997": 11184.96,
      "1998": 4351.37,
      "1999": 9285.03,
      "2000": 10051.99,
      "2001": 14101.31,
      "2002": 11040.58,
      "2003": 21639.82,
      "2004": 10701.24,
      "2005": 24958.04,
      "2006": 94867.89,
      "2007": 51029.76,
      "2008": 31933.84,
      "2009": 73953.9,
      "2010": 42440.19,
      "2011": 52754.09,
      "2012": 60957.91,
      "2013": 65885.1,
      "2014": 77879.98,
      "2015": 72201.51,
      "2016": 81882.16,
      "2017": 61888.14,
      "2018": 100353.47,
      "2019": 87582.38,
      "2020": 106003.83
    },
    Greenland: {
      "1997": 0,
      "1998": 0,
      "1999": null,
      "2000": 35.02,
      "2001": 16.95,
      "2002": null,
      "2003": 0.27,
      "2004": 5.64,
      "2005": 0.02,
      "2006": 21.95,
      "2007": 0.06,
      "2008": 1.42,
      "2009": 76.46,
      "2010": 17.4,
      "2011": 11,
      "2012": 30.43,
      "2013": 0.11,
      "2014": 43.2,
      "2015": 67.12,
      "2016": 68.97,
      "2017": 6.94,
      "2018": 0,
      "2019": null,
      "2020": 11.41
    },
    Grenada: {
      "1997": null,
      "1998": 1.75,
      "1999": 40.16,
      "2000": null,
      "2001": 22.72,
      "2002": 124.47,
      "2003": 55.19,
      "2004": 15.98,
      "2005": 69.22,
      "2006": 29.77,
      "2007": 7.43,
      "2008": 33.42,
      "2009": 34.71,
      "2010": 5.88,
      "2011": 12.6,
      "2012": 0.56,
      "2013": 1.45,
      "2014": null,
      "2015": null,
      "2016": 43.22,
      "2017": 171.74,
      "2018": 337.41,
      "2019": 337.14,
      "2020": 337.6
    },
    Guadeloupe: {
      "1997": 0,
      "1998": 0,
      "1999": null,
      "2000": 0.5,
      "2001": 2.38,
      "2002": 11.63,
      "2003": null,
      "2004": 318.04,
      "2005": 115.73,
      "2006": 72.08,
      "2007": 79.64,
      "2008": 33.24,
      "2009": 11.76,
      "2010": 22.13,
      "2011": 880.87,
      "2012": 228.02,
      "2013": 208.35,
      "2014": 48,
      "2015": 39.68,
      "2016": null,
      "2017": 4.25,
      "2018": 261.8,
      "2019": 139.39,
      "2020": 513
    },
    Guam: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": null,
      "2002": 13.19,
      "2003": null,
      "2004": 96.35,
      "2005": 523.21,
      "2006": 87.68,
      "2007": 142.9,
      "2008": 34.07,
      "2009": null,
      "2010": 22.61,
      "2011": 4.7,
      "2012": 0.24,
      "2013": null,
      "2014": 1.44,
      "2015": 0,
      "2016": 0,
      "2017": null,
      "2018": 1.7,
      "2019": 1.2,
      "2020": 23.4
    },
    Guatemala: {
      "1997": null,
      "1998": 368.37,
      "1999": 400.43,
      "2000": 29.79,
      "2001": 459.52,
      "2002": 1175.16,
      "2003": 90.06,
      "2004": 476.89,
      "2005": 809.54,
      "2006": 949.4,
      "2007": 1433.91,
      "2008": 1322.06,
      "2009": 2713.2,
      "2010": 17994.7,
      "2011": 3147.77,
      "2012": 4539.09,
      "2013": 7832.24,
      "2014": 10436.6,
      "2015": 8136.51,
      "2016": 14550.71,
      "2017": 10292.55,
      "2018": 11297.8,
      "2019": 16209.8,
      "2020": 15420.3
    },
    Guernsey: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 0,
      "2008": 0,
      "2009": 0,
      "2010": 0,
      "2011": 0,
      "2012": 0,
      "2013": 0,
      "2014": 0,
      "2015": null,
      "2016": 75.4,
      "2017": 150.14,
      "2018": 0,
      "2019": 0,
      "2020": 0
    },
    Guinea: {
      "1997": 21.04,
      "1998": 2236.26,
      "1999": 2064.79,
      "2000": 2053.19,
      "2001": 7551.88,
      "2002": 7195.48,
      "2003": 1955.22,
      "2004": 12398.74,
      "2005": 9852.01,
      "2006": 154934.34,
      "2007": 281951.86,
      "2008": 120995.25,
      "2009": 149474.17,
      "2010": 47818.84,
      "2011": 126077.4,
      "2012": 287054.88,
      "2013": 111615.73,
      "2014": 444901.14,
      "2015": 240459.32,
      "2016": 187442.98,
      "2017": 331743.31,
      "2018": 286958.52,
      "2019": 295480.86,
      "2020": 1199914.18
    },
    "Guinea-Bissau": {
      "1997": 16072.47,
      "1998": 7908.81,
      "1999": 15639.34,
      "2000": 28173.2,
      "2001": 4618.52,
      "2002": 22933.93,
      "2003": 24104.92,
      "2004": 31841.07,
      "2005": 42558.57,
      "2006": 22503.52,
      "2007": 25706.44,
      "2008": 46596.16,
      "2009": 87269.35,
      "2010": 27571.58,
      "2011": 111942.13,
      "2012": 76754.56,
      "2013": 67775.96,
      "2014": 96686.97,
      "2015": 129430.62,
      "2016": 144264.8,
      "2017": 165129.13,
      "2018": 88937.75,
      "2019": 88627.55,
      "2020": 93792.04
    },
    Guyana: {
      "1997": 11.25,
      "1998": 207.36,
      "1999": 730.95,
      "2000": 608.71,
      "2001": 927.08,
      "2002": 1061.31,
      "2003": 5482.92,
      "2004": 3220.54,
      "2005": 9408.89,
      "2006": 6676.82,
      "2007": 6373.6,
      "2008": 5024.9,
      "2009": 3893.06,
      "2010": 4200.43,
      "2011": 4299.09,
      "2012": 2503.21,
      "2013": 4409.02,
      "2014": 6318.66,
      "2015": 12139.88,
      "2016": 9673.02,
      "2017": 4253.06,
      "2018": 4505.62,
      "2019": 4917.74,
      "2020": 8398.21
    },
    Haiti: {
      "1997": 0,
      "1998": 0,
      "1999": null,
      "2000": 1.69,
      "2001": null,
      "2002": 21.63,
      "2003": 220.14,
      "2004": 458.39,
      "2005": 129.26,
      "2006": 513.22,
      "2007": 790.51,
      "2008": 817.18,
      "2009": 657.5,
      "2010": 608.16,
      "2011": 968.24,
      "2012": 722.19,
      "2013": 579.13,
      "2014": 970.07,
      "2015": 2142.89,
      "2016": 2420.59,
      "2017": 5321,
      "2018": 9669.39,
      "2019": 9871.8,
      "2020": 9974.27
    },
    "Heard Macdonald": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": "",
      "2006": 48.13,
      "2007": null,
      "2008": 13.62,
      "2009": 0.86,
      "2010": null,
      "2011": null,
      "2012": 87.46,
      "2013": 0,
      "2014": 0,
      "2015": null,
      "2016": 0.19,
      "2017": 0,
      "2018": 0,
      "2019": 0,
      "2020": 0
    },
    Honduras: {
      "1997": 12.29,
      "1998": 13.22,
      "1999": 1.06,
      "2000": 27.4,
      "2001": 160.63,
      "2002": 38.29,
      "2003": null,
      "2004": 102.08,
      "2005": 138.24,
      "2006": 766.01,
      "2007": 857.93,
      "2008": 2197.06,
      "2009": 11634.9,
      "2010": 654.21,
      "2011": 3885.52,
      "2012": 9720.28,
      "2013": 13693.28,
      "2014": 15453.8,
      "2015": 10353.03,
      "2016": 14891.73,
      "2017": 8587.76,
      "2018": 12771.28,
      "2019": 13630.94,
      "2020": 9293.36
    },
    "Hong Kong SAR": {
      "1997": 117536.79,
      "1998": 189028.99,
      "1999": 354417.67,
      "2000": 389281.51,
      "2001": 347608.66,
      "2002": 470687.04,
      "2003": 685908.23,
      "2004": 777373.79,
      "2005": 977107.64,
      "2006": 1123930.26,
      "2007": 1086707.05,
      "2008": 2973253.51,
      "2009": 2231668.95,
      "2010": 4282511.81,
      "2011": 4957017.02,
      "2012": 4303011.83,
      "2013": 4410706.05,
      "2014": 3408862.4,
      "2015": 3963591.14,
      "2016": 5490618.08,
      "2017": 6877702.27,
      "2018": 12597191.41,
      "2019": 11999898.32,
      "2020": 11221827.11
    },
    Hungary: {
      "1997": 4478.86,
      "1998": 3945.16,
      "1999": 5366.29,
      "2000": 6879.74,
      "2001": 11405.73,
      "2002": 9976.62,
      "2003": 12555.66,
      "2004": 14165.84,
      "2005": 13999,
      "2006": 52452.66,
      "2007": 45600.43,
      "2008": 88152.53,
      "2009": 91978.46,
      "2010": 156632.32,
      "2011": 206607.18,
      "2012": 143196.61,
      "2013": 133438.29,
      "2014": 146600.68,
      "2015": 158564.93,
      "2016": 146633.86,
      "2017": 174581.99,
      "2018": 168369.14,
      "2019": 132987.95,
      "2020": 162011.34
    },
    Iceland: {
      "1997": 119.9,
      "1998": 1566.68,
      "1999": 417.71,
      "2000": 461.09,
      "2001": 1071.61,
      "2002": 6554.03,
      "2003": 4380.19,
      "2004": 1031.48,
      "2005": 2796.9,
      "2006": 1689.17,
      "2007": 1522.92,
      "2008": 1620.7,
      "2009": 4805.72,
      "2010": 2105.1,
      "2011": 2202.72,
      "2012": 1206.55,
      "2013": 4083.77,
      "2014": 2873.98,
      "2015": 2746.19,
      "2016": 3157.05,
      "2017": 4778.19,
      "2018": 6090.05,
      "2019": 7393.52,
      "2020": 4612.84
    },
    Indonesia: {
      "1997": 271909.25,
      "1998": 348808.99,
      "1999": 413262.45,
      "2000": 415839.1,
      "2001": 494475.81,
      "2002": 668276.05,
      "2003": 975121.14,
      "2004": 1176190.06,
      "2005": 1331795.58,
      "2006": 1886485.99,
      "2007": 1942053.15,
      "2008": 3075129.4,
      "2009": 4100880.75,
      "2010": 4513629.3,
      "2011": 7041989.62,
      "2012": 8096569.76,
      "2013": 8903542.02,
      "2014": 9184535.26,
      "2015": 8579957.42,
      "2016": 9008193.45,
      "2017": 10596111.95,
      "2018": 11114852.9,
      "2019": 10672726.8,
      "2020": 9232528.22
    },
    "Islamic Republic of Iran": {
      "1997": 235255.64,
      "1998": 199302.96,
      "1999": 472090.77,
      "2000": 96498.55,
      "2001": 135360.12,
      "2002": 125004.41,
      "2003": 122609.86,
      "2004": 184313.68,
      "2005": 311004.87,
      "2006": 3451547.4,
      "2007": 4394593.48,
      "2008": 5582184.47,
      "2009": 5463556.74,
      "2010": 4972462.81,
      "2011": 6655215.33,
      "2012": 6302560.76,
      "2013": 6279848.3,
      "2014": 5458848.32,
      "2015": 4082287.05,
      "2016": 7047761.27,
      "2017": 7162010.29,
      "2018": 9411296.93,
      "2019": 976201.73,
      "2020": 244797.12
    },
    Iraq: {
      "1997": 68907.45,
      "1998": 63597.68,
      "1999": 86542.98,
      "2000": 3152.68,
      "2001": 19.57,
      "2002": 12.82,
      "2003": 63.47,
      "2004": 503.45,
      "2005": 908.11,
      "2006": 2500501.79,
      "2007": 2749466.41,
      "2008": 3428501.21,
      "2009": 3327268.42,
      "2010": 4097724.66,
      "2011": 9053148.36,
      "2012": 10459636.16,
      "2013": 11163769.78,
      "2014": 8677872.85,
      "2015": 7056429.54,
      "2016": 7851495.22,
      "2017": 11345266.55,
      "2018": 15660099.03,
      "2019": 16835448.07,
      "2020": 10565526.45
    },
    Ireland: {
      "1997": 13256.96,
      "1998": 16904.87,
      "1999": 24129.51,
      "2000": 32892.29,
      "2001": 40508.22,
      "2002": 47419.23,
      "2003": 60256.79,
      "2004": 82289.84,
      "2005": 71681.09,
      "2006": 130633.23,
      "2007": 104550.82,
      "2008": 110214.16,
      "2009": 125774.04,
      "2010": 118093.36,
      "2011": 197493.25,
      "2012": 270658.1,
      "2013": 337948.27,
      "2014": 325978.97,
      "2015": 361280,
      "2016": 352814.46,
      "2017": 512551.66,
      "2018": 295479.01,
      "2019": 426604.24,
      "2020": 306898.93
    },
    Israel: {
      "1997": 124736.92,
      "1998": 147360.85,
      "1999": 252169.29,
      "2000": 197577.26,
      "2001": 204001.35,
      "2002": 291667.59,
      "2003": 307761.71,
      "2004": 443972.7,
      "2005": 456543.75,
      "2006": 488558.03,
      "2007": 574633.67,
      "2008": 949903.46,
      "2009": 892765.57,
      "2010": 1026344.81,
      "2011": 1268405.85,
      "2012": 1281441.93,
      "2013": 1388773.51,
      "2014": 1422942.54,
      "2015": 1371293.13,
      "2016": 1314732.57,
      "2017": 1332119.79,
      "2018": 1346984.55,
      "2019": 1129659.46,
      "2020": 1448158.25
    },
    Italy: {
      "1997": 332347.18,
      "1998": 457837.69,
      "1999": 318038.39,
      "2000": 330564,
      "2001": 336124.76,
      "2002": 392963.15,
      "2003": 492157.69,
      "2004": 616955,
      "2005": 821552.51,
      "2006": 1210171.66,
      "2007": 1569445,
      "2008": 1998356.25,
      "2009": 1827411.51,
      "2010": 1939469.59,
      "2011": 2450936.87,
      "2012": 2565242.04,
      "2013": 2514057.31,
      "2014": 2586217.4,
      "2015": 2664462.56,
      "2016": 2612844.79,
      "2017": 3033679.21,
      "2018": 3700309.92,
      "2019": 3177753.97,
      "2020": 2859165.94
    },
    Jamaica: {
      "1997": 10.51,
      "1998": 113.98,
      "1999": 32.18,
      "2000": 33.62,
      "2001": 340.77,
      "2002": 73.55,
      "2003": 421.6,
      "2004": 1189.96,
      "2005": 945.52,
      "2006": 272.95,
      "2007": 9327.01,
      "2008": 506.38,
      "2009": 314.82,
      "2010": 351.19,
      "2011": 773.22,
      "2012": 1326.47,
      "2013": 549.78,
      "2014": 1086.91,
      "2015": 1017.65,
      "2016": 784.25,
      "2017": 2807.13,
      "2018": 3500.44,
      "2019": 2090.15,
      "2020": 1644.86
    },
    Japan: {
      "1997": 797147.24,
      "1998": 1037343.65,
      "1999": 1098830.43,
      "2000": 841591.2,
      "2001": 1023679.82,
      "2002": 888697.11,
      "2003": 1225839.38,
      "2004": 1453593.31,
      "2005": 1797990.11,
      "2006": 2079487.72,
      "2007": 2545779.99,
      "2008": 3583282.46,
      "2009": 3189384.21,
      "2010": 3930930.77,
      "2011": 5767103.11,
      "2012": 6754720.77,
      "2013": 5721168.94,
      "2014": 6199039.65,
      "2015": 6449314.69,
      "2016": 6541452.47,
      "2017": 7074524.84,
      "2018": 8927838.13,
      "2019": 8803362.83,
      "2020": 8081915.37
    },
    Jersey: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 0,
      "2008": 0,
      "2009": 0,
      "2010": 0,
      "2011": 0,
      "2012": 0,
      "2013": 0,
      "2014": 0,
      "2015": 0,
      "2016": 0,
      "2017": null,
      "2018": 36.17,
      "2019": 101.87,
      "2020": 0
    },
    Jordan: {
      "1997": 62573.13,
      "1998": 116105.34,
      "1999": 113860.44,
      "2000": 110843.76,
      "2001": 100038.24,
      "2002": 108621.53,
      "2003": 105813.7,
      "2004": 159905.54,
      "2005": 195836.23,
      "2006": 213223.53,
      "2007": 275738,
      "2008": 800087.81,
      "2009": 392371.45,
      "2010": 374402.61,
      "2011": 714506.02,
      "2012": 511172.81,
      "2013": 363718.58,
      "2014": 525293.18,
      "2015": 558032.06,
      "2016": 555610.91,
      "2017": 634875.34,
      "2018": 782225.95,
      "2019": 754261.4,
      "2020": 789638.49
    },
    Kazakhstan: {
      "1997": 13390.91,
      "1998": 5229.28,
      "1999": 5830.22,
      "2000": 6412.81,
      "2001": 3525.72,
      "2002": 6158.71,
      "2003": 4255.69,
      "2004": 6915.21,
      "2005": 11642.77,
      "2006": 39910.77,
      "2007": 30915.45,
      "2008": 73352.81,
      "2009": 73596.96,
      "2010": 63029.21,
      "2011": 93086.46,
      "2012": 76278.01,
      "2013": 397849.12,
      "2014": 426691.98,
      "2015": 229490.16,
      "2016": 347377.19,
      "2017": 585710.04,
      "2018": 495342.41,
      "2019": 1603667.85,
      "2020": 599350.14
    },
    Kenya: {
      "1997": 7785.86,
      "1998": 15232.89,
      "1999": 9108.19,
      "2000": 8690.25,
      "2001": 15234.02,
      "2002": 16235.02,
      "2003": 19268.09,
      "2004": 20994.42,
      "2005": 21480.4,
      "2006": 25556.87,
      "2007": 34829.71,
      "2008": 37619.2,
      "2009": 37621.61,
      "2010": 56611.42,
      "2011": 54144.12,
      "2012": 57736.47,
      "2013": 76313.2,
      "2014": 71708.72,
      "2015": 83744.14,
      "2016": 69914.52,
      "2017": 46781.04,
      "2018": 96121.88,
      "2019": 63278.37,
      "2020": 96145.15
    },
    "Kiribati Rep": {
      "1997": 0,
      "1998": 0,
      "1999": 0.01,
      "2000": null,
      "2001": 0,
      "2002": 0,
      "2003": 79.02,
      "2004": null,
      "2005": "",
      "2006": 17.49,
      "2007": 0.59,
      "2008": 29.45,
      "2009": null,
      "2010": 202.5,
      "2011": null,
      "2012": 43.31,
      "2013": 1.81,
      "2014": 7.69,
      "2015": 0,
      "2016": 0,
      "2017": null,
      "2018": 155.05,
      "2019": 0,
      "2020": 0
    },
    "Korea Dp Rp": {
      "1997": 21323.67,
      "1998": 37947.42,
      "1999": 7184.07,
      "2000": 8819.01,
      "2001": 1522.69,
      "2002": 2274.06,
      "2003": 495.2,
      "2004": 4191.67,
      "2005": 25046.69,
      "2006": 222471.06,
      "2007": 64852.28,
      "2008": 24147.79,
      "2009": 4144.45,
      "2010": 65893.07,
      "2011": 87536.15,
      "2012": 141480.11,
      "2013": 6788.49,
      "2014": 80472.47,
      "2015": 56737.78,
      "2016": 59170.61,
      "2017": 16155.84,
      "2018": 2061.24,
      "2019": 2467.53,
      "2020": 1043.14
    },
    Korea: {
      //rp
      "1997": 353219.02,
      "1998": 586643.45,
      "1999": 478574.32,
      "2000": 408308.66,
      "2001": 544340.61,
      "2002": 736582.79,
      "2003": 1300047.91,
      "2004": 1576541.54,
      "2005": 2020577.01,
      "2006": 2174699.81,
      "2007": 2430790.74,
      "2008": 3965818.96,
      "2009": 4055061.49,
      "2010": 4771247.7,
      "2011": 6157031.43,
      "2012": 7133725.25,
      "2013": 7528258.41,
      "2014": 8272008.53,
      "2015": 8536310.91,
      "2016": 8440432.93,
      "2017": 10542283.54,
      "2018": 11725531.27,
      "2019": 11088343.94,
      "2020": 9447621.69
    },
    Kuwait: {
      "1997": 330689.35,
      "1998": 631518.41,
      "1999": 567957.61,
      "2000": 51469.98,
      "2001": 35142.62,
      "2002": 86871.81,
      "2003": 65472.6,
      "2004": 137465.04,
      "2005": 204478.8,
      "2006": 2711417.52,
      "2007": 3095993.03,
      "2008": 4319944.55,
      "2009": 3898799.19,
      "2010": 4697602.94,
      "2011": 7944946.24,
      "2012": 9018425.3,
      "2013": 10336261.19,
      "2014": 8151069.71,
      "2015": 3237881.59,
      "2016": 2989905.25,
      "2017": 4622089.7,
      "2018": 5194935.58,
      "2019": 6793208.72,
      "2020": 3852493.57
    },
    "Kyrgyz Republic": {
      "1997": 2.92,
      "1998": 42.6,
      "1999": 787.48,
      "2000": 2023.84,
      "2001": 265.44,
      "2002": 225.82,
      "2003": 249.76,
      "2004": 280.93,
      "2005": 652.81,
      "2006": 348.4,
      "2007": 364.42,
      "2008": 451.63,
      "2009": 307.88,
      "2010": 542.35,
      "2011": 420.87,
      "2012": 1142.64,
      "2013": 383.7,
      "2014": 472.15,
      "2015": 1180.11,
      "2016": 1001.36,
      "2017": 19933.8,
      "2018": 1838.61,
      "2019": 928.65,
      "2020": 3798.26
    },
    "Lao P.D.R.": {
      "1997": 4.96,
      "1998": null,
      "1999": 0,
      "2000": 0,
      "2001": 17.9,
      "2002": 71.96,
      "2003": 58.91,
      "2004": 23.24,
      "2005": 46.34,
      "2006": 162.52,
      "2007": 45.69,
      "2008": 214.92,
      "2009": 9218.2,
      "2010": 100.53,
      "2011": 44065.07,
      "2012": 76479.58,
      "2013": 22919.32,
      "2014": 52282.93,
      "2015": 118162.51,
      "2016": 139071.66,
      "2017": 108800.58,
      "2018": 717.78,
      "2019": 2190.32,
      "2020": 1256.39
    },
    Latvia: {
      "1997": 4756.27,
      "1998": 5126.78,
      "1999": 373.62,
      "2000": 2024.49,
      "2001": 441.46,
      "2002": 1375.75,
      "2003": 195.38,
      "2004": 495.95,
      "2005": 4484.86,
      "2006": 14163.44,
      "2007": 16575.32,
      "2008": 53692.34,
      "2009": 73010.77,
      "2010": 89981.77,
      "2011": 66871.38,
      "2012": 40251.81,
      "2013": 62225.41,
      "2014": 21981.11,
      "2015": 39937.9,
      "2016": 26625.16,
      "2017": 33910.15,
      "2018": 111063.91,
      "2019": 188671.75,
      "2020": 36295.7
    },
    Lebanon: {
      "1997": 252.26,
      "1998": 486.29,
      "1999": 5240.62,
      "2000": 3388.22,
      "2001": 5628.54,
      "2002": 6312.43,
      "2003": 3527.96,
      "2004": 9194.92,
      "2005": 8196.83,
      "2006": 5026.93,
      "2007": 3790.52,
      "2008": 6248.27,
      "2009": 3205.55,
      "2010": 8377.64,
      "2011": 10245.74,
      "2012": 16339.74,
      "2013": 22429.09,
      "2014": 24203.07,
      "2015": 18017.12,
      "2016": 20252.52,
      "2017": 24811.44,
      "2018": 30460.74,
      "2019": 21918.94,
      "2020": 20660.49
    },
    Lesotho: {
      "1997": 0.02,
      "1998": null,
      "1999": null,
      "2000": 5.28,
      "2001": null,
      "2002": 2.48,
      "2003": 5.15,
      "2004": null,
      "2005": 0.04,
      "2006": null,
      "2007": null,
      "2008": 120.55,
      "2009": 276.91,
      "2010": 509.7,
      "2011": 1421.26,
      "2012": 2371.06,
      "2013": 1400.97,
      "2014": 843.1,
      "2015": 3190.98,
      "2016": 16718.09,
      "2017": 57835.7,
      "2018": 1.63,
      "2019": 32,
      "2020": 125.96
    },
    Liberia: {
      "1997": 1396.36,
      "1998": null,
      "1999": 5088.09,
      "2000": 2993.92,
      "2001": 20559.76,
      "2002": 1076.5,
      "2003": 1852.75,
      "2004": 20227.22,
      "2005": 56073.33,
      "2006": 21347.3,
      "2007": 96487.68,
      "2008": 63531.98,
      "2009": 9261.59,
      "2010": 8051.66,
      "2011": 4264.8,
      "2012": 11416.12,
      "2013": 15624.16,
      "2014": 35939.59,
      "2015": 20467.44,
      "2016": 5146.04,
      "2017": 23663.41,
      "2018": 93093.53,
      "2019": 5506.31,
      "2020": 5147.48
    },
    Libya: {
      "1997": 10899.56,
      "1998": 1132.86,
      "1999": 8743.55,
      "2000": 3560.34,
      "2001": 4547.37,
      "2002": 1423.64,
      "2003": 4772.03,
      "2004": 6112.33,
      "2005": 5284.61,
      "2006": 61029.12,
      "2007": 501066.51,
      "2008": 307213.58,
      "2009": 294147.65,
      "2010": 440249.32,
      "2011": 19642.56,
      "2012": 998030.08,
      "2013": 264475.55,
      "2014": 41920.23,
      "2015": 5644.55,
      "2016": 5001.11,
      "2017": 65269.87,
      "2018": 100146.38,
      "2019": 30192.29,
      "2020": 51440.44
    },
    Liechtenstein: {
      "1997": null,
      "1998": 13.69,
      "1999": 1.75,
      "2000": 11.62,
      "2001": 8.72,
      "2002": 135.77,
      "2003": 7.2,
      "2004": 229.86,
      "2005": 351.56,
      "2006": 172.92,
      "2007": 1495.16,
      "2008": 34.64,
      "2009": 20.33,
      "2010": 100.72,
      "2011": 311.17,
      "2012": 468.44,
      "2013": 278.94,
      "2014": 718.46,
      "2015": 773.56,
      "2016": 575.74,
      "2017": 1030.31,
      "2018": 2531.6,
      "2019": 293.71,
      "2020": 247.93
    },
    Lithuania: {
      "1997": 108.54,
      "1998": 82.24,
      "1999": 4470.43,
      "2000": 497.37,
      "2001": 454.37,
      "2002": 2706.94,
      "2003": 4963.33,
      "2004": 7924.61,
      "2005": 12820.68,
      "2006": 9116.41,
      "2007": 3900.94,
      "2008": 262067.29,
      "2009": 34953.87,
      "2010": 58029.39,
      "2011": 98445.58,
      "2012": 24931.43,
      "2013": 32325.67,
      "2014": 67795.71,
      "2015": 140414.46,
      "2016": 181924.72,
      "2017": 122289.64,
      "2018": 148021.43,
      "2019": 184321.39,
      "2020": 238198.52
    },
    Luxembourg: {
      "1997": 1756.32,
      "1998": 517.01,
      "1999": 1101.13,
      "2000": 2383.92,
      "2001": 3206.61,
      "2002": 9357.26,
      "2003": 20402.07,
      "2004": 4354.22,
      "2005": 7129.41,
      "2006": 14413.56,
      "2007": 14420.78,
      "2008": 11413.46,
      "2009": 16036.65,
      "2010": 16720.17,
      "2011": 26734.14,
      "2012": 26256.26,
      "2013": 27674.19,
      "2014": 25634.26,
      "2015": 114324.72,
      "2016": 30959.4,
      "2017": 34285.35,
      "2018": 93145.08,
      "2019": 38499.37,
      "2020": 22585.95
    },
    "Macao SAR": {
      "1997": null,
      "1998": 75.76,
      "1999": 53.85,
      "2000": 5.29,
      "2001": 694.03,
      "2002": 6147.43,
      "2003": 1115.62,
      "2004": 0.06,
      "2005": 71.34,
      "2006": 88.27,
      "2007": 152.39,
      "2008": 113.09,
      "2009": 173.09,
      "2010": 88.79,
      "2011": 407.06,
      "2012": 817,
      "2013": 1634.54,
      "2014": 1414.04,
      "2015": 5427.76,
      "2016": 5300.57,
      "2017": 5350.36,
      "2018": 4370.13,
      "2019": 3631.61,
      "2020": 18038.09
    },
    "North Macedonia": {
      "1997": 554.8,
      "1998": 282.59,
      "1999": 9.29,
      "2000": 41,
      "2001": 567.56,
      "2002": 287.71,
      "2003": 25.01,
      "2004": 37.86,
      "2005": 1701.17,
      "2006": 116.18,
      "2007": 112.04,
      "2008": 320.23,
      "2009": 12621.66,
      "2010": 462.01,
      "2011": 7362.6,
      "2012": 12524.77,
      "2013": 12093.12,
      "2014": 5573.21,
      "2015": 4611.68,
      "2016": 16158.96,
      "2017": 30065.42,
      "2018": 16894.54,
      "2019": 10210.66,
      "2020": 4349.18
    },
    Madagascar: {
      "1997": 1289.85,
      "1998": 1259.66,
      "1999": 1463.27,
      "2000": 173.78,
      "2001": 1048.86,
      "2002": 1908.1,
      "2003": 2320.19,
      "2004": 3580.3,
      "2005": 7243.57,
      "2006": 8704.09,
      "2007": 6733.17,
      "2008": 8538,
      "2009": 9073.28,
      "2010": 14581,
      "2011": 39994.48,
      "2012": 39399.91,
      "2013": 31717.09,
      "2014": 58461.35,
      "2015": 93267.53,
      "2016": 80247.2,
      "2017": 107906.04,
      "2018": 205084.01,
      "2019": 84903.38,
      "2020": 59374.23
    },
    Malawi: {
      "1997": 197.64,
      "1998": 185.79,
      "1999": 272.1,
      "2000": 157.26,
      "2001": 808.39,
      "2002": 824.52,
      "2003": 2287.25,
      "2004": 2282.8,
      "2005": 796.94,
      "2006": 2269.47,
      "2007": 6217.63,
      "2008": 3355.31,
      "2009": 48534,
      "2010": 8972.57,
      "2011": 10928.02,
      "2012": 23383.19,
      "2013": 9367.69,
      "2014": 22756.81,
      "2015": 42232.53,
      "2016": 27785.13,
      "2017": 10608.78,
      "2018": 16053.42,
      "2019": 22510.42,
      "2020": 5211.28
    },
    Malaysia: {
      "1997": 438131.79,
      "1998": 676647.52,
      "1999": 844733.91,
      "2000": 537613.59,
      "2001": 540604.78,
      "2002": 709192.46,
      "2003": 940423.25,
      "2004": 1032979.47,
      "2005": 1069473.44,
      "2006": 2395876.1,
      "2007": 2417613.44,
      "2008": 3259156.48,
      "2009": 2449403.25,
      "2010": 2974590.44,
      "2011": 4538502.77,
      "2012": 5419876.33,
      "2013": 5590211.37,
      "2014": 6791883.78,
      "2015": 5937316.99,
      "2016": 5988145.01,
      "2017": 5808128.86,
      "2018": 7549167.12,
      "2019": 6916662.95,
      "2020": 6179121.7
    },
    Maldives: {
      "1997": 88.52,
      "1998": 21.84,
      "1999": 173.54,
      "2000": 86.68,
      "2001": 188.87,
      "2002": 161,
      "2003": 172.17,
      "2004": 274.94,
      "2005": 876.34,
      "2006": 1382.76,
      "2007": 1669.73,
      "2008": 1793.35,
      "2009": 1707.57,
      "2010": 14543.27,
      "2011": 8742.37,
      "2012": 3422.17,
      "2013": 2391.02,
      "2014": 2648.42,
      "2015": 2814.06,
      "2016": 6149.62,
      "2017": 3655.13,
      "2018": 14720.8,
      "2019": 4247.81,
      "2020": 18063.14
    },
    Mali: {
      "1997": 52.04,
      "1998": 33.36,
      "1999": 3766.03,
      "2000": 6081.71,
      "2001": 1029.12,
      "2002": 9578.1,
      "2003": 14353.02,
      "2004": 5874.24,
      "2005": 1137.2,
      "2006": 1260.34,
      "2007": 1611.17,
      "2008": 4303.65,
      "2009": 1834.55,
      "2010": 1537.99,
      "2011": 2982.04,
      "2012": 16077.82,
      "2013": 42572.45,
      "2014": 47576.44,
      "2015": 160648.08,
      "2016": 66210.48,
      "2017": 73565.63,
      "2018": 12656.78,
      "2019": 26790.85,
      "2020": 10460.4
    },
    Malta: {
      "1997": 12347.1,
      "1998": 11.5,
      "1999": 11.78,
      "2000": 20.09,
      "2001": 62.67,
      "2002": 85.14,
      "2003": 426.34,
      "2004": 16561.31,
      "2005": 7006.95,
      "2006": 90477.91,
      "2007": 3144.03,
      "2008": 2332.29,
      "2009": 5695.97,
      "2010": 16398.08,
      "2011": 20132.2,
      "2012": 23130.9,
      "2013": 20960.83,
      "2014": 17367.6,
      "2015": 17373.87,
      "2016": 15007,
      "2017": 10293.18,
      "2018": 30797.23,
      "2019": 87142.23,
      "2020": 17505.05
    },
    "Marshall Islands": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 4882.36,
      "2004": 0.3,
      "2005": 79234.68,
      "2006": 6611.8,
      "2007": 26.89,
      "2008": 62.15,
      "2009": 3.49,
      "2010": 618.13,
      "2011": 4910.29,
      "2012": 477.06,
      "2013": 4040.09,
      "2014": 7.85,
      "2015": 270.67,
      "2016": 5864.04,
      "2017": 70.7,
      "2018": 359.41,
      "2019": 2572.02,
      "2020": 1232.14
    },
    Martinique: {
      "1997": 5.12,
      "1998": 0.46,
      "1999": 0.05,
      "2000": 0.5,
      "2001": 4.52,
      "2002": null,
      "2003": 2.68,
      "2004": 1.21,
      "2005": 15.67,
      "2006": 0.86,
      "2007": 2.2,
      "2008": 185.33,
      "2009": null,
      "2010": 6.44,
      "2011": null,
      "2012": 23.78,
      "2013": 74.11,
      "2014": 58.39,
      "2015": 0,
      "2016": 0,
      "2017": null,
      "2018": 568.75,
      "2019": 204.16,
      "2020": 608.42
    },
    Mauritania: {
      "1997": 27.99,
      "1998": null,
      "1999": 6.78,
      "2000": 5.7,
      "2001": 6.75,
      "2002": 11.95,
      "2003": 14.91,
      "2004": 575.83,
      "2005": 453.19,
      "2006": 290.21,
      "2007": 473.6,
      "2008": 2039.52,
      "2009": 759.34,
      "2010": 893.77,
      "2011": 2182.17,
      "2012": 6127.96,
      "2013": 4437.79,
      "2014": 26473.53,
      "2015": 12165.31,
      "2016": 7347.42,
      "2017": 3509.59,
      "2018": 10961.34,
      "2019": 7585.18,
      "2020": 4062.29
    },
    Mauritius: {
      "1997": 2242.25,
      "1998": 846.26,
      "1999": 1700.99,
      "2000": 2819.83,
      "2001": 1555.84,
      "2002": 7808.31,
      "2003": 3465.57,
      "2004": 3228.42,
      "2005": 3245.36,
      "2006": 6565.8,
      "2007": 4055.79,
      "2008": 6557.86,
      "2009": 5155.1,
      "2010": 7512.67,
      "2011": 18141.52,
      "2012": 15517.22,
      "2013": 12463.61,
      "2014": 12935.74,
      "2015": 13317.06,
      "2016": 12305.93,
      "2017": 13356.05,
      "2018": 49784.66,
      "2019": 19781.51,
      "2020": 31492.94
    },
    Mayotte: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 0,
      "2008": 0,
      "2009": 0,
      "2010": 0,
      "2011": 0,
      "2012": 0,
      "2013": 0,
      "2014": 0,
      "2015": 0,
      "2016": 0,
      "2017": 1.16,
      "2018": 130.68,
      "2019": 254.5,
      "2020": 237.22
    },
    Mexico: {
      "1997": 29543.54,
      "1998": 38715.4,
      "1999": 41811.22,
      "2000": 24590.3,
      "2001": 29681.4,
      "2002": 31709.88,
      "2003": 33954.77,
      "2004": 37124.07,
      "2005": 43216.9,
      "2006": 357648.55,
      "2007": 476543.09,
      "2008": 799801.38,
      "2009": 490842.94,
      "2010": 526882.57,
      "2011": 1241351.05,
      "2012": 2187342.42,
      "2013": 2235302.49,
      "2014": 2071679.82,
      "2015": 1490511.97,
      "2016": 1977611.94,
      "2017": 2532958.98,
      "2018": 3899878.85,
      "2019": 3044137.03,
      "2020": 2109470.67
    },
    Micronesia: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0.21,
      "2004": 4.11,
      "2005": "",
      "2006": 9.7,
      "2007": 6.12,
      "2008": 0.21,
      "2009": 0,
      "2010": 0,
      "2011": null,
      "2012": 0.19,
      "2013": 60.71,
      "2014": null,
      "2015": 0,
      "2016": 0,
      "2017": 0,
      "2018": 0,
      "2019": 0,
      "2020": 0
    },
    Moldova: {
      "1997": 28.52,
      "1998": null,
      "1999": null,
      "2000": 0.05,
      "2001": 48.3,
      "2002": 858.77,
      "2003": 18.66,
      "2004": 65.29,
      "2005": 93.59,
      "2006": 206.13,
      "2007": 157.74,
      "2008": 3044.07,
      "2009": 506.48,
      "2010": 55.57,
      "2011": 213.86,
      "2012": 373.55,
      "2013": 323.6,
      "2014": 876.43,
      "2015": 3232.77,
      "2016": 7853.07,
      "2017": 1011.2,
      "2018": 271.08,
      "2019": 177.74,
      "2020": 734.25
    },
    Monaco: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 4.41,
      "2004": 421.83,
      "2005": 41.91,
      "2006": 79.75,
      "2007": 385,
      "2008": 159.29,
      "2009": 469.75,
      "2010": 640.17,
      "2011": 1298.31,
      "2012": 1759.94,
      "2013": 1321.27,
      "2014": 1041.59,
      "2015": 995.77,
      "2016": 747.11,
      "2017": 687.6,
      "2018": 190.25,
      "2019": 42707.8,
      "2020": 5.39
    },
    Mongolia: {
      "1997": 66.85,
      "1998": 78.77,
      "1999": 127.15,
      "2000": 61.55,
      "2001": 72.39,
      "2002": 249,
      "2003": 342.08,
      "2004": 92.4,
      "2005": 717.45,
      "2006": 1015.88,
      "2007": 3742.61,
      "2008": 8358.64,
      "2009": 601.3,
      "2010": 900.09,
      "2011": 7483.04,
      "2012": 5327.18,
      "2013": 5058.82,
      "2014": 2629.87,
      "2015": 2695.55,
      "2016": 1335.02,
      "2017": 1109.96,
      "2018": 711.19,
      "2019": 920.58,
      "2020": 316.16
    },
    Montenegro: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 24.79,
      "2006": 21.13,
      "2007": 0,
      "2008": 0,
      "2009": 0,
      "2010": 0,
      "2011": 0,
      "2012": 0,
      "2013": 5.57,
      "2014": 718.47,
      "2015": 31.78,
      "2016": 36.15,
      "2017": 6.44,
      "2018": 91.38,
      "2019": 116.23,
      "2020": 299.94
    },
    Montserrat: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 8.52,
      "2004": 10.62,
      "2005": 0,
      "2006": 0,
      "2007": 41.88,
      "2008": 5.81,
      "2009": 0.85,
      "2010": 1.22,
      "2011": 0,
      "2012": 0,
      "2013": 1.72,
      "2014": 2.97,
      "2015": 0.03,
      "2016": null,
      "2017": 0.01,
      "2018": 38.39,
      "2019": 0,
      "2020": 0
    },
    Morocco: {
      "1997": 171478.97,
      "1998": 169123.2,
      "1999": 182039.56,
      "2000": 144037,
      "2001": 127044.65,
      "2002": 96622.23,
      "2003": 117454.11,
      "2004": 162725.56,
      "2005": 202051.99,
      "2006": 222309.64,
      "2007": 200747.26,
      "2008": 429263.55,
      "2009": 408294.01,
      "2010": 384221.35,
      "2011": 800377.87,
      "2012": 712677.65,
      "2013": 538786.44,
      "2014": 572474.35,
      "2015": 705110.98,
      "2016": 532201.46,
      "2017": 502532.51,
      "2018": 926714.39,
      "2019": 672491.47,
      "2020": 1066440.99
    },
    Mozambique: {
      "1997": 5101.92,
      "1998": 10670.39,
      "1999": 14605.13,
      "2000": 9718.11,
      "2001": 3153.39,
      "2002": 13439.94,
      "2003": 10852.05,
      "2004": 18673.96,
      "2005": 22406.53,
      "2006": 12811.14,
      "2007": 19078.92,
      "2008": 14824.63,
      "2009": 32045.39,
      "2010": 46756.03,
      "2011": 48270.38,
      "2012": 158875.13,
      "2013": 178082.53,
      "2014": 199846.54,
      "2015": 237529.91,
      "2016": 366235.55,
      "2017": 600864.64,
      "2018": 767704.56,
      "2019": 591140.42,
      "2020": 468085.13
    },
    Myanmar: {
      "1997": 83253.09,
      "1998": 73101.47,
      "1999": 74356.41,
      "2000": 83004.2,
      "2001": 178572.37,
      "2002": 162628.85,
      "2003": 187946.65,
      "2004": 182382.99,
      "2005": 232862.77,
      "2006": 354094.52,
      "2007": 325928.14,
      "2008": 424076.82,
      "2009": 610794.46,
      "2010": 465115.4,
      "2011": 661401.33,
      "2012": 770118.21,
      "2013": 839148.23,
      "2014": 747653.51,
      "2015": 637838.72,
      "2016": 715611.79,
      "2017": 412038.81,
      "2018": 367425.18,
      "2019": 391051.69,
      "2020": 391592.83
    },
    "N. Mariana Is.": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 151.99,
      "2008": 32.24,
      "2009": 82.61,
      "2010": 29.44,
      "2011": 0,
      "2012": 0,
      "2013": 98.56,
      "2014": null,
      "2015": 150.78,
      "2016": 19.67,
      "2017": 112.03,
      "2018": 14.43,
      "2019": 0.28,
      "2020": 218.68
    },
    Namibia: {
      "1997": 20.57,
      "1998": 3.62,
      "1999": 97.43,
      "2000": 196.84,
      "2001": 89.16,
      "2002": 1582.79,
      "2003": 46,
      "2004": 22,
      "2005": 9195,
      "2006": 1546.76,
      "2007": 8441.5,
      "2008": 1596.75,
      "2009": 20206.01,
      "2010": 17011.44,
      "2011": 4809.2,
      "2012": 4993.42,
      "2013": 7809.39,
      "2014": 20884.47,
      "2015": 6788.87,
      "2016": 33768.21,
      "2017": 40133.89,
      "2018": 37528.12,
      "2019": 20205.22,
      "2020": 22364.29
    },
    Nauru: {
      "1997": 0,
      "1998": 866.8,
      "1999": 3515.7,
      "2000": 3393.96,
      "2001": 830.19,
      "2002": 1099.4,
      "2003": 1680.64,
      "2004": 758.1,
      "2005": 81.65,
      "2006": 343.21,
      "2007": 163.13,
      "2008": 12396.21,
      "2009": 7419.64,
      "2010": 1892.45,
      "2011": 7232.8,
      "2012": 11573.81,
      "2013": 3005.15,
      "2014": 6205.19,
      "2015": 3835.44,
      "2016": 23.89,
      "2017": 8.24,
      "2018": 109.12,
      "2019": 47.92,
      "2020": 39.77
    },
    Nepal: {
      "1997": 35364.41,
      "1998": 60940.47,
      "1999": 81740.58,
      "2000": 116532.95,
      "2001": 169756.17,
      "2002": 136358.98,
      "2003": 131440.13,
      "2004": 155385.77,
      "2005": 168173.09,
      "2006": 138450.9,
      "2007": 252725.72,
      "2008": 225567.56,
      "2009": 214646.32,
      "2010": 233895.01,
      "2011": 263933.16,
      "2012": 295806.57,
      "2013": 320429.69,
      "2014": 391549.76,
      "2015": 306935.08,
      "2016": 298506.5,
      "2017": 282456.55,
      "2018": 355843.04,
      "2019": 504496.71,
      "2020": 497537.21
    },
    Netherlands: {
      "1997": 165454.95,
      "1998": 195287.08,
      "1999": 204055.68,
      "2000": 199884.8,
      "2001": 222466.76,
      "2002": 186680.62,
      "2003": 246098.77,
      "2004": 355613.52,
      "2005": 464673.27,
      "2006": 523286.18,
      "2007": 772866.39,
      "2008": 866962.05,
      "2009": 1004854.6,
      "2010": 842167.95,
      "2011": 1270374.44,
      "2012": 1294749.57,
      "2013": 1892137.58,
      "2014": 1709541.98,
      "2015": 1216809.81,
      "2016": 1272947.67,
      "2017": 1619891.13,
      "2018": 2850016.04,
      "2019": 2399484.32,
      "2020": 2454356.81
    },
    Netherlandantil: {
      "1997": null,
      "1998": 11.32,
      "1999": null,
      "2000": 64.17,
      "2001": 0,
      "2002": 0,
      "2003": 94.15,
      "2004": null,
      "2005": 24.44,
      "2006": 488.25,
      "2007": 504.88,
      "2008": 639.92,
      "2009": 460.64,
      "2010": 1572.64,
      "2011": 13068.22,
      "2012": 8658.89,
      "2013": 2631.05,
      "2014": 6893.06,
      "2015": 39503.19,
      "2016": 44574.08,
      "2017": 5268.89,
      "2018": 6760.1,
      "2019": 16055.91,
      "2020": 3016.17
    },
    "New Caledonia": {
      "1997": 60.3,
      "1998": 18.32,
      "1999": 82.48,
      "2000": 182.94,
      "2001": 829.32,
      "2002": 494.74,
      "2003": 5173.81,
      "2004": 4125.68,
      "2005": 1375.5,
      "2006": 9656.09,
      "2007": 4094.35,
      "2008": 4754.7,
      "2009": 1458.32,
      "2010": 3079.67,
      "2011": 5574.06,
      "2012": 14779.49,
      "2013": 16559.58,
      "2014": 12950.81,
      "2015": 33135.52,
      "2016": 16109.17,
      "2017": 6221.48,
      "2018": 154.8,
      "2019": 164.29,
      "2020": 1340.35
    },
    "New Zealand": {
      "1997": 31127.86,
      "1998": 37161.91,
      "1999": 41817.13,
      "2000": 36245.19,
      "2001": 39189.04,
      "2002": 36975.11,
      "2003": 36249.92,
      "2004": 57493.7,
      "2005": 95909.48,
      "2006": 120266.43,
      "2007": 135305.78,
      "2008": 195096.15,
      "2009": 236380.72,
      "2010": 285380.15,
      "2011": 395230.75,
      "2012": 379980.43,
      "2013": 370075.74,
      "2014": 360837.48,
      "2015": 358454.71,
      "2016": 338524.58,
      "2017": 415177.02,
      "2018": 440309.35,
      "2019": 369381.19,
      "2020": 283203.56
    },
    Nicaragua: {
      "1997": 0,
      "1998": 0,
      "1999": 11.82,
      "2000": 8.28,
      "2001": 274.84,
      "2002": 238.48,
      "2003": 155.78,
      "2004": 89.55,
      "2005": 2430.9,
      "2006": 34.13,
      "2007": 153.59,
      "2008": 290.43,
      "2009": 164.5,
      "2010": 413.51,
      "2011": 463.07,
      "2012": 444.71,
      "2013": 1622.43,
      "2014": 1337.95,
      "2015": 2513.01,
      "2016": 1761.38,
      "2017": 2682.24,
      "2018": 2508.84,
      "2019": 2531.14,
      "2020": 4588.22
    },
    Niger: {
      "1997": 29.71,
      "1998": null,
      "1999": 36.61,
      "2000": 751.04,
      "2001": 285.47,
      "2002": 27.78,
      "2003": 1013.3,
      "2004": 324.52,
      "2005": 337.29,
      "2006": 2448.3,
      "2007": 269.33,
      "2008": 74.33,
      "2009": 120.06,
      "2010": 4900.34,
      "2011": 37074.89,
      "2012": 38684.47,
      "2013": 335.04,
      "2014": 340.71,
      "2015": 212.24,
      "2016": 19.82,
      "2017": 13044.38,
      "2018": 443.74,
      "2019": 644.81,
      "2020": 3086.64
    },
    Nigeria: {
      "1997": 414567.76,
      "1998": 495479.04,
      "1999": 1268952.27,
      "2000": 29139.4,
      "2001": 41547.77,
      "2002": 37810.11,
      "2003": 34758.53,
      "2004": 21746,
      "2005": 32081.93,
      "2006": 3179651.97,
      "2007": 3066290.73,
      "2008": 3999547.98,
      "2009": 3437710.95,
      "2010": 4900514.87,
      "2011": 7010393.87,
      "2012": 6562221.03,
      "2013": 8576722.43,
      "2014": 8343300.11,
      "2015": 6492261.36,
      "2016": 5137453.41,
      "2017": 6129330.96,
      "2018": 7631562.14,
      "2019": 7242829.4,
      "2020": 4199459.73
    },
    "Niue Is": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": "",
      "2006": 131.63,
      "2007": 15.95,
      "2008": null,
      "2009": 28.48,
      "2010": 0.31,
      "2011": 1.83,
      "2012": 3.93,
      "2013": 11.91,
      "2014": 15.46,
      "2015": 1.92,
      "2016": null,
      "2017": 3.72,
      "2018": 0,
      "2019": 0,
      "2020": 0
    },
    "Norfolk Is": {
      "1997": 0,
      "1998": 0,
      "1999": 30.3,
      "2000": 4.87,
      "2001": null,
      "2002": 11.82,
      "2003": null,
      "2004": 39.54,
      "2005": 56.8,
      "2006": 7.35,
      "2007": 166.53,
      "2008": 392.55,
      "2009": 380.54,
      "2010": 73,
      "2011": 64.14,
      "2012": 294.59,
      "2013": 119.15,
      "2014": 76.83,
      "2015": 46.86,
      "2016": 7.51,
      "2017": 300.52,
      "2018": 101.36,
      "2019": 315.17,
      "2020": 180.38
    },
    Norway: {
      "1997": 36364.55,
      "1998": 27900.62,
      "1999": 18638.12,
      "2000": 21402.27,
      "2001": 22881.05,
      "2002": 46919.72,
      "2003": 139243.25,
      "2004": 105626.95,
      "2005": 128101.62,
      "2006": 345727.77,
      "2007": 654444.76,
      "2008": 512341.29,
      "2009": 431102.58,
      "2010": 433873.85,
      "2011": 418985.72,
      "2012": 514019.78,
      "2013": 454010.97,
      "2014": 614774.93,
      "2015": 385797.54,
      "2016": 381645.65,
      "2017": 542521.15,
      "2018": 256868.01,
      "2019": 448091.24,
      "2020": 496548.21
    },
    Oman: {
      "1997": 12065.26,
      "1998": 9540.34,
      "1999": 26970.45,
      "2000": 2894.33,
      "2001": 4046.61,
      "2002": 6695.79,
      "2003": 23379.62,
      "2004": 9259.82,
      "2005": 117584.07,
      "2006": 208233.76,
      "2007": 456384.48,
      "2008": 546437.92,
      "2009": 1644314.08,
      "2010": 1818433.4,
      "2011": 1580693.4,
      "2012": 1093145.52,
      "2013": 1782769.8,
      "2014": 1064756.17,
      "2015": 1095663.85,
      "2016": 865749.48,
      "2017": 2750004.48,
      "2018": 1920820.9,
      "2019": 2608567.58,
      "2020": 2278517.69
    },
    "Pacific Is": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 0,
      "2008": 0,
      "2009": 0,
      "2010": 0,
      "2011": 3.58,
      "2012": null,
      "2013": 0,
      "2014": 0,
      "2015": 0,
      "2016": 0,
      "2017": 0,
      "2018": 0,
      "2019": 0,
      "2020": 0
    },
    Pakistan: {
      "1997": 16519.11,
      "1998": 90219.26,
      "1999": 29558.93,
      "2000": 29249.98,
      "2001": 30883.17,
      "2002": 21704.52,
      "2003": 26490.44,
      "2004": 42673.53,
      "2005": 79498.02,
      "2006": 146272.82,
      "2007": 115871.88,
      "2008": 166838.7,
      "2009": 130461.77,
      "2010": 151395.49,
      "2011": 190030.19,
      "2012": 294370.34,
      "2013": 260656.88,
      "2014": 304066.81,
      "2015": 288450.27,
      "2016": 304877.05,
      "2017": 315030.13,
      "2018": 347648.12,
      "2019": 9764.83,
      "2020": 1768.51
    },
    Palau: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 4.65,
      "2008": 4.48,
      "2009": 5.91,
      "2010": 3.87,
      "2011": 4.25,
      "2012": null,
      "2013": 2.3,
      "2014": 5.33,
      "2015": 2448.88,
      "2016": 2.41,
      "2017": 504.07,
      "2018": 2171.7,
      "2019": 2.46,
      "2020": 0.58
    },
    "Panama Canal Zone": {
      "1997": null,
      "1998": 0.02,
      "1999": 0,
      "2000": 0,
      "2001": null,
      "2002": 13.51,
      "2003": 242.54,
      "2004": 3929.95,
      "2005": 211.81,
      "2006": 0,
      "2007": 13.73,
      "2008": 4.37,
      "2009": 13501.29,
      "2010": null,
      "2011": 26531.17,
      "2012": null,
      "2013": 13029.88,
      "2014": 26271.29,
      "2015": null,
      "2016": 304.44,
      "2017": 0,
      "2018": 0,
      "2019": 0,
      "2020": 0
    },
    Panama: {
      "1997": 17833.64,
      "1998": 7284.62,
      "1999": 66.02,
      "2000": 4340.05,
      "2001": 12758.54,
      "2002": 8684.4,
      "2003": 3671.6,
      "2004": 40830.45,
      "2005": 109549.31,
      "2006": 138370.13,
      "2007": 100570.33,
      "2008": 64680.01,
      "2009": 172962.14,
      "2010": 86252.07,
      "2011": 73368.57,
      "2012": 59674.14,
      "2013": 25147.64,
      "2014": 18584.38,
      "2015": 47327.01,
      "2016": 135252.55,
      "2017": 27918.99,
      "2018": 26648.48,
      "2019": 53454.86,
      "2020": 24596.88
    },
    "Papua New Guinea": {
      "1997": 1059.81,
      "1998": 857.85,
      "1999": 632.43,
      "2000": 140.85,
      "2001": 29.52,
      "2002": 196.51,
      "2003": 435.87,
      "2004": 42773.49,
      "2005": 28389.94,
      "2006": 126685.82,
      "2007": 78154.92,
      "2008": 103976.04,
      "2009": 47716.27,
      "2010": 98538.61,
      "2011": 99677.09,
      "2012": 57059.16,
      "2013": 108384.14,
      "2014": 96054.55,
      "2015": 116105.39,
      "2016": 72549.85,
      "2017": 126615.8,
      "2018": 62619.5,
      "2019": 39419.66,
      "2020": 92840.6
    },
    Paraguay: {
      "1997": 220.33,
      "1998": 213.13,
      "1999": 65.89,
      "2000": 247.35,
      "2001": 8608.95,
      "2002": 308.53,
      "2003": 343.37,
      "2004": 1245.16,
      "2005": 1863.38,
      "2006": 1286.33,
      "2007": 183.06,
      "2008": 263.91,
      "2009": 2441.99,
      "2010": 2464.39,
      "2011": 5312.09,
      "2012": 4654.1,
      "2013": 3278.37,
      "2014": 54041.19,
      "2015": 73630.31,
      "2016": 104160.09,
      "2017": 107740.88,
      "2018": 14648.07,
      "2019": 17948.18,
      "2020": 11570.47
    },
    Peru: {
      "1997": 5233.78,
      "1998": 3922.9,
      "1999": 21461.71,
      "2000": 4169.32,
      "2001": 13327.97,
      "2002": 11988.73,
      "2003": 13744.19,
      "2004": 16331.74,
      "2005": 10208.86,
      "2006": 58097.01,
      "2007": 63269.74,
      "2008": 121693.97,
      "2009": 64143.4,
      "2010": 85276.97,
      "2011": 258883.3,
      "2012": 305080.52,
      "2013": 317161.76,
      "2014": 361652.62,
      "2015": 540676.35,
      "2016": 722711.91,
      "2017": 1533152.22,
      "2018": 1669262.71,
      "2019": 1114119.1,
      "2020": 1121705.99
    },
    Philippines: {
      "1997": 8570.02,
      "1998": 15673.32,
      "1999": 24371.46,
      "2000": 28798.59,
      "2001": 45232.53,
      "2002": 59898.34,
      "2003": 56111.23,
      "2004": 84199.37,
      "2005": 104260.26,
      "2006": 75737.13,
      "2007": 82387.82,
      "2008": 116542.91,
      "2009": 149329.11,
      "2010": 195046.6,
      "2011": 212544.32,
      "2012": 274358.42,
      "2013": 237220.43,
      "2014": 258508.64,
      "2015": 353162.56,
      "2016": 331787.08,
      "2017": 492758.3,
      "2018": 406252.9,
      "2019": 370609.41,
      "2020": 423691.04
    },
    "Pitcairn Is.": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": null,
      "2002": 0.11,
      "2003": null,
      "2004": 0.46,
      "2005": 0.92,
      "2006": 2.33,
      "2007": 1.96,
      "2008": 0.01,
      "2009": 0,
      "2010": 0,
      "2011": 0,
      "2012": 0,
      "2013": null,
      "2014": 67.09,
      "2015": 3.73,
      "2016": 1.9,
      "2017": 144.74,
      "2018": 22.02,
      "2019": 13.34,
      "2020": 5.61
    },
    Poland: {
      "1997": 12152.46,
      "1998": 14222.12,
      "1999": 16680,
      "2000": 19477.2,
      "2001": 14971.79,
      "2002": 18797.81,
      "2003": 22540.83,
      "2004": 40602.95,
      "2005": 47733.33,
      "2006": 53032.94,
      "2007": 76179.69,
      "2008": 121491.09,
      "2009": 182784.1,
      "2010": 176455.88,
      "2011": 298741.97,
      "2012": 469494.51,
      "2013": 378002.92,
      "2014": 389075.63,
      "2015": 373145.41,
      "2016": 463715.7,
      "2017": 494349.98,
      "2018": 554047.55,
      "2019": 599892.28,
      "2020": 522190.27
    },
    Portugal: {
      "1997": 5794.33,
      "1998": 3928.9,
      "1999": 3754.13,
      "2000": 5477.19,
      "2001": 6676.25,
      "2002": 7237.74,
      "2003": 6371.99,
      "2004": 8511.83,
      "2005": 13392.17,
      "2006": 13790.83,
      "2007": 14373.07,
      "2008": 25510.32,
      "2009": 33587.32,
      "2010": 38933.1,
      "2011": 146020.38,
      "2012": 206058.09,
      "2013": 207532.65,
      "2014": 88483.34,
      "2015": 67050.41,
      "2016": 94641.03,
      "2017": 122512.65,
      "2018": 92603.12,
      "2019": 100334.34,
      "2020": 84377.2
    },
    "Puerto Rico": {
      "1997": null,
      "1998": 107.61,
      "1999": 5.34,
      "2000": 31.39,
      "2001": 311.12,
      "2002": 1230.69,
      "2003": 178.89,
      "2004": 2231.29,
      "2005": 3235.98,
      "2006": 3084.79,
      "2007": 3509,
      "2008": 4721.84,
      "2009": 6366.7,
      "2010": 13194.7,
      "2011": 16715,
      "2012": 12630.82,
      "2013": 12530.83,
      "2014": 15910.27,
      "2015": 32918.99,
      "2016": 33475.22,
      "2017": 48262.67,
      "2018": 27218.69,
      "2019": 37091.42,
      "2020": 21613.25
    },
    Qatar: {
      "1997": 37752.6,
      "1998": 29509.33,
      "1999": 90898.53,
      "2000": 36109.87,
      "2001": 43730.27,
      "2002": 54719.55,
      "2003": 85952.91,
      "2004": 302323.52,
      "2005": 399179.06,
      "2006": 935908.92,
      "2007": 988889.45,
      "2008": 1589469.1,
      "2009": 2200996.34,
      "2010": 3103583.21,
      "2011": 6186383.05,
      "2012": 8545778.37,
      "2013": 9500539.18,
      "2014": 8906411.86,
      "2015": 5891199.1,
      "2016": 5127596.43,
      "2017": 5421053.9,
      "2018": 7516661.83,
      "2019": 6871693.07,
      "2020": 5866266.17
    },
    Reunion: {
      "1997": 0,
      "1998": 0,
      "1999": null,
      "2000": 91.93,
      "2001": 151.98,
      "2002": 108.51,
      "2003": 580.86,
      "2004": 1547.88,
      "2005": 1467.17,
      "2006": 2490.47,
      "2007": 5025.66,
      "2008": 11354.13,
      "2009": 7337.81,
      "2010": 8352.99,
      "2011": 13462.7,
      "2012": 15091.66,
      "2013": 13674.82,
      "2014": 13800.86,
      "2015": 12261.66,
      "2016": 9326.78,
      "2017": 11632.91,
      "2018": 12718.99,
      "2019": 13107.38,
      "2020": 13619.59
    },
    Romania: {
      "1997": 22832.19,
      "1998": 18179.67,
      "1999": 8705.98,
      "2000": 9897.64,
      "2001": 23094.45,
      "2002": 22058.91,
      "2003": 33051.17,
      "2004": 75684.48,
      "2005": 119592.32,
      "2006": 132011.66,
      "2007": 168123.18,
      "2008": 152425.37,
      "2009": 107337.24,
      "2010": 108139.8,
      "2011": 217458.14,
      "2012": 169303.89,
      "2013": 226820.24,
      "2014": 181203.6,
      "2015": 202422.04,
      "2016": 212794.93,
      "2017": 265578.21,
      "2018": 219737.15,
      "2019": 184799.25,
      "2020": 143323.91
    },
    Russia: {
      "1997": 239014.97,
      "1998": 229513.64,
      "1999": 270042.6,
      "2000": 236490.05,
      "2001": 255392.53,
      "2002": 286794.04,
      "2003": 440963.87,
      "2004": 594328.52,
      "2005": 895294.01,
      "2006": 1090283.53,
      "2007": 993832.96,
      "2008": 1978742.31,
      "2009": 1692388.55,
      "2010": 1641666.08,
      "2011": 2307542.15,
      "2012": 2302095.04,
      "2013": 2356986.3,
      "2014": 2592365.75,
      "2015": 2998646.79,
      "2016": 3718033.65,
      "2017": 5528709.05,
      "2018": 4081679.07,
      "2019": 5029106.72,
      "2020": 4063240.58
    },
    Rwanda: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 10.64,
      "2002": null,
      "2003": null,
      "2004": 324.52,
      "2005": 17.04,
      "2006": 741.45,
      "2007": 267.38,
      "2008": 1080.42,
      "2009": 120.19,
      "2010": 12.25,
      "2011": 72.4,
      "2012": 130.54,
      "2013": 155.15,
      "2014": 742.09,
      "2015": 867.93,
      "2016": 790.15,
      "2017": 3445.4,
      "2018": 4659.96,
      "2019": 2899.47,
      "2020": 4932.78
    },
    Samoa: {
      "1997": 0,
      "1998": 0,
      "1999": 326.15,
      "2000": 74.98,
      "2001": null,
      "2002": 0.35,
      "2003": 3.06,
      "2004": null,
      "2005": 25.61,
      "2006": 500.1,
      "2007": 2.13,
      "2008": 9.36,
      "2009": 26.45,
      "2010": 55.7,
      "2011": 78.38,
      "2012": 97.41,
      "2013": 10.69,
      "2014": 719.84,
      "2015": 1643.76,
      "2016": 4201.97,
      "2017": 164.96,
      "2018": 1935.26,
      "2019": 2131.45,
      "2020": 337.43
    },
    "San Marino": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 0,
      "2008": 0,
      "2009": 0,
      "2010": 0,
      "2011": 0,
      "2012": 0,
      "2013": 386.49,
      "2014": 201.39,
      "2015": 336.86,
      "2016": 259.47,
      "2017": 416.38,
      "2018": 151.1,
      "2019": 106.4,
      "2020": 61.36
    },
    "São Tomé and Príncipe": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": null,
      "2002": 18.97,
      "2003": 0,
      "2004": 0,
      "2005": 7.51,
      "2006": 0,
      "2007": 1.59,
      "2008": 27.18,
      "2009": 23.95,
      "2010": 51.02,
      "2011": 10.1,
      "2012": 0.36,
      "2013": null,
      "2014": 30.39,
      "2015": 1.85,
      "2016": 13.89,
      "2017": null,
      "2018": 4.32,
      "2019": null,
      "2020": 59.92
    },
    "Saudi Arab": {
      "1997": 639298.78,
      "1998": 770510.35,
      "1999": 1048322.7,
      "2000": 283754.79,
      "2001": 221284.43,
      "2002": 244262.23,
      "2003": 339014.92,
      "2004": 584626.77,
      "2005": 722693.17,
      "2006": 6056149.96,
      "2007": 7811031.38,
      "2008": 8974703.51,
      "2009": 8066428.36,
      "2010": 9285490.58,
      "2011": 15310994.21,
      "2012": 18468480,
      "2013": 22051549.28,
      "2014": 17122086.51,
      "2015": 13257997.96,
      "2016": 13394575.06,
      "2017": 14224074.41,
      "2018": 19939490.29,
      "2019": 19024522.86,
      "2020": 11975862.07
    },
    Senegal: {
      "1997": 17892.58,
      "1998": 49252.92,
      "1999": 37833.93,
      "2000": 20255.73,
      "2001": 63715.32,
      "2002": 83436.48,
      "2003": 66611.27,
      "2004": 81414.15,
      "2005": 129525.86,
      "2006": 32924.24,
      "2007": 60376.01,
      "2008": 99452.94,
      "2009": 112593.74,
      "2010": 94029.7,
      "2011": 39209.04,
      "2012": 215.49,
      "2013": 95373.36,
      "2014": 126985.91,
      "2015": 172677.43,
      "2016": 211786.1,
      "2017": 364878.16,
      "2018": 452503.21,
      "2019": 331631.28,
      "2020": 297539.99
    },
    Serbia: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 0,
      "2008": 0,
      "2009": 0,
      "2010": 0,
      "2011": 0,
      "2012": 0,
      "2013": 2012.72,
      "2014": 14490.92,
      "2015": 14448.19,
      "2016": 18259.25,
      "2017": 15529.62,
      "2018": 15093.43,
      "2019": 8103.08,
      "2020": 5425.14
    },
    Seychelles: {
      "1997": 111.64,
      "1998": 0.01,
      "1999": 7.43,
      "2000": 15.02,
      "2001": 22.07,
      "2002": 25.83,
      "2003": 31.22,
      "2004": 259.93,
      "2005": 507.37,
      "2006": 338.66,
      "2007": 379.31,
      "2008": 536.35,
      "2009": 765,
      "2010": 4222.35,
      "2011": 1247.04,
      "2012": 1305.33,
      "2013": 581.28,
      "2014": 714.54,
      "2015": 438.43,
      "2016": 621.67,
      "2017": 882.5,
      "2018": 3669.51,
      "2019": 3325.3,
      "2020": 2704.48
    },
    "Sierra Leone": {
      "1997": null,
      "1998": 72.37,
      "1999": 47.72,
      "2000": 464.19,
      "2001": 207.72,
      "2002": 2006.1,
      "2003": 2972.31,
      "2004": 1022.07,
      "2005": 1578.07,
      "2006": 1058.77,
      "2007": 20183.12,
      "2008": 3588.12,
      "2009": 1966.51,
      "2010": 3040.76,
      "2011": 2579.47,
      "2012": 3157.53,
      "2013": 3379.2,
      "2014": 14447.86,
      "2015": 10775.66,
      "2016": 8202.56,
      "2017": 15572.45,
      "2018": 8197.4,
      "2019": 17349.49,
      "2020": 6478.38
    },
    Singapore: {
      "1997": 372392,
      "1998": 582325.69,
      "1999": 502794.95,
      "2000": 668780.39,
      "2001": 621944.9,
      "2002": 694381.35,
      "2003": 958260.18,
      "2004": 1191311.7,
      "2005": 1484833.35,
      "2006": 2483996.69,
      "2007": 3268217.81,
      "2008": 3456141.62,
      "2009": 3062330.81,
      "2010": 3254576.75,
      "2011": 3970847.55,
      "2012": 4076395.09,
      "2013": 4106346.94,
      "2014": 4355230.46,
      "2015": 4773489.37,
      "2016": 4754169.45,
      "2017": 4813281.77,
      "2018": 11391875.15,
      "2019": 10439410.04,
      "2020": 9821958.01
    },
    "Slovak Republic": {
      "1997": 11925.58,
      "1998": 4697.26,
      "1999": 5066.78,
      "2000": 3179.6,
      "2001": 6037.25,
      "2002": 5510.4,
      "2003": 4880.9,
      "2004": 10285.9,
      "2005": 17645.53,
      "2006": 8958.71,
      "2007": 17886.42,
      "2008": 20913.92,
      "2009": 19070.39,
      "2010": 40001.37,
      "2011": 42283.53,
      "2012": 34438.1,
      "2013": 32190.03,
      "2014": 84553.82,
      "2015": 42282.7,
      "2016": 45956.3,
      "2017": 61404.42,
      "2018": 40176.96,
      "2019": 38763.66,
      "2020": 26337.01
    },
    Slovenia: {
      "1997": 2125.14,
      "1998": 1813.65,
      "1999": 4803.63,
      "2000": 9997.32,
      "2001": 12552.91,
      "2002": 34040.58,
      "2003": 27962.6,
      "2004": 9637.32,
      "2005": 10454.25,
      "2006": 16426.37,
      "2007": 23185.33,
      "2008": 34414.52,
      "2009": 55775.26,
      "2010": 41995.47,
      "2011": 75654.38,
      "2012": 63906.41,
      "2013": 71394.52,
      "2014": 63948.75,
      "2015": 57943.71,
      "2016": 68185.97,
      "2017": 72239.42,
      "2018": 174409.13,
      "2019": 176444.39,
      "2020": 201409.62
    },
    "Solomon Is": {
      "1997": 735.94,
      "1998": 3326.37,
      "1999": 561.81,
      "2000": 1337.89,
      "2001": 1973.37,
      "2002": 616.1,
      "2003": 1032.21,
      "2004": 132.36,
      "2005": 1227.8,
      "2006": 883.49,
      "2007": 2645.81,
      "2008": 235.78,
      "2009": 551.4,
      "2010": 18.58,
      "2011": 10772.66,
      "2012": 8289.78,
      "2013": 3286.46,
      "2014": 8397.76,
      "2015": 44433.91,
      "2016": 36353.77,
      "2017": 43701.73,
      "2018": 41260.1,
      "2019": 27477.78,
      "2020": 24922.98
    },
    Somalia: {
      "1997": 524.18,
      "1998": 345.13,
      "1999": 347.81,
      "2000": 1435.43,
      "2001": 6824.97,
      "2002": 12895.28,
      "2003": 4446.91,
      "2004": 3356.92,
      "2005": 5116.33,
      "2006": 8209.58,
      "2007": 3112.44,
      "2008": 2741.46,
      "2009": 1917.37,
      "2010": 4181.59,
      "2011": 1664.88,
      "2012": 6805.2,
      "2013": 27922.55,
      "2014": 23088.9,
      "2015": 10064.53,
      "2016": 11860.15,
      "2017": 2289.34,
      "2018": 12952.59,
      "2019": 10542.46,
      "2020": 4238.23
    },
    "South Africa": {
      "1997": 185120.14,
      "1998": 568743.05,
      "1999": 873466.52,
      "2000": 466854.44,
      "2001": 687190.93,
      "2002": 1013146.82,
      "2003": 872706.35,
      "2004": 987443.96,
      "2005": 1094352.7,
      "2006": 1118413.7,
      "2007": 1454655.62,
      "2008": 2488228.72,
      "2009": 2690022.66,
      "2010": 3252514.22,
      "2011": 5244595.25,
      "2012": 4831962.81,
      "2013": 3585833.4,
      "2014": 3974128.43,
      "2015": 3878539.04,
      "2016": 3911693.38,
      "2017": 4405573.9,
      "2018": 4552192.36,
      "2019": 4950724.54,
      "2020": 5576627.21
    },
    "South Sudan": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 0,
      "2008": 0,
      "2009": 0,
      "2010": 0,
      "2011": 0,
      "2012": 0,
      "2013": null,
      "2014": 39285.79,
      "2015": null,
      "2016": 117.53,
      "2017": 45413.12,
      "2018": 62593.59,
      "2019": 5142.62,
      "2020": 1184.71
    },
    Spain: {
      "1997": 59829.6,
      "1998": 90150.14,
      "1999": 60615.65,
      "2000": 64940.87,
      "2001": 80493.92,
      "2002": 85720.15,
      "2003": 118912.82,
      "2004": 175029.46,
      "2005": 253891.51,
      "2006": 283669.65,
      "2007": 400097.68,
      "2008": 466128.41,
      "2009": 519599.75,
      "2010": 677619.66,
      "2011": 865717.33,
      "2012": 989376.79,
      "2013": 1117224.22,
      "2014": 1228091.97,
      "2015": 1075604.88,
      "2016": 1322312.88,
      "2017": 1072053.38,
      "2018": 1172788.86,
      "2019": 1141711.7,
      "2020": 1119018.32
    },
    "Sri Lanka Dsr": {
      "1997": 11226.15,
      "1998": 15850.32,
      "1999": 19166.62,
      "2000": 20560.65,
      "2001": 32134.17,
      "2002": 43955.28,
      "2003": 89484.88,
      "2004": 170019.05,
      "2005": 255768.08,
      "2006": 212955.68,
      "2007": 254091.64,
      "2008": 162367.62,
      "2009": 185001.57,
      "2010": 227873.44,
      "2011": 303660.43,
      "2012": 340419.73,
      "2013": 406445.16,
      "2014": 464275.9,
      "2015": 485357.89,
      "2016": 403973.05,
      "2017": 497649.65,
      "2018": 1037423.26,
      "2019": 640663.4,
      "2020": 475214.29
    },
    "St Helena": {
      "1997": null,
      "1998": 0.03,
      "1999": null,
      "2000": 0.57,
      "2001": 0,
      "2002": 0,
      "2003": 4.33,
      "2004": 19.02,
      "2005": 215.09,
      "2006": 103.04,
      "2007": 1.97,
      "2008": 11.38,
      "2009": 35.88,
      "2010": 81.78,
      "2011": 31.29,
      "2012": 51.54,
      "2013": 58.5,
      "2014": 53.77,
      "2015": null,
      "2016": 1.04,
      "2017": 19.03,
      "2018": 53.09,
      "2019": 10.53,
      "2020": 7.84
    },
    "St. Kitts and Nevis": {
      "1997": null,
      "1998": 10.19,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 4.85,
      "2006": 2.07,
      "2007": 35.7,
      "2008": null,
      "2009": 13.57,
      "2010": null,
      "2011": 46.15,
      "2012": 0.17,
      "2013": 10.79,
      "2014": 223.93,
      "2015": 103.77,
      "2016": 5.2,
      "2017": 0.31,
      "2018": 0.83,
      "2019": null,
      "2020": 0.72
    },
    "St Lucia": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0.35,
      "2002": null,
      "2003": 0,
      "2004": 0,
      "2005": 4.05,
      "2006": 1.6,
      "2007": 1540.71,
      "2008": 12484.21,
      "2009": 241.18,
      "2010": 144.79,
      "2011": 226.49,
      "2012": 222.98,
      "2013": 300.53,
      "2014": 85.88,
      "2015": 299.34,
      "2016": 159.73,
      "2017": 205.39,
      "2018": 393.98,
      "2019": 428.55,
      "2020": 290.06
    },
    "St Pierre": {
      "1997": 7.6,
      "1998": 0.8,
      "1999": 0.38,
      "2000": null,
      "2001": 0,
      "2002": 0,
      "2003": 4.53,
      "2004": 42.83,
      "2005": 1575.69,
      "2006": 7900.77,
      "2007": 11.71,
      "2008": 11.08,
      "2009": 0.03,
      "2010": 5.71,
      "2011": 0,
      "2012": 0,
      "2013": 0.51,
      "2014": null,
      "2015": 16.01,
      "2016": null,
      "2017": 0,
      "2018": 0,
      "2019": 0,
      "2020": 0
    },
    "St. Vincent and the Grenadines": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 738.33,
      "2002": 966.29,
      "2003": 896.1,
      "2004": 1033.23,
      "2005": 32867.49,
      "2006": 1066.39,
      "2007": 14468.76,
      "2008": 3007.23,
      "2009": 521.3,
      "2010": 2.13,
      "2011": 0,
      "2012": 0,
      "2013": null,
      "2014": 0.21,
      "2015": 1.23,
      "2016": null,
      "2017": null,
      "2018": 14.87,
      "2019": null,
      "2020": 8856.74
    },
    "State of Palest": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 0,
      "2008": 0,
      "2009": 0,
      "2010": 0,
      "2011": 0,
      "2012": 0,
      "2013": 0,
      "2014": 0,
      "2015": null,
      "2016": null,
      "2017": null,
      "2018": 835.85,
      "2019": 518.63,
      "2020": 109.2
    },
    Sudan: {
      "1997": 1661.35,
      "1998": 6359.63,
      "1999": 2864.36,
      "2000": 3663.53,
      "2001": 6399.21,
      "2002": 11854.35,
      "2003": 14345.65,
      "2004": 10280.03,
      "2005": 14440.92,
      "2006": 40424.61,
      "2007": 173722.03,
      "2008": 183956.05,
      "2009": 224547.19,
      "2010": 279262.03,
      "2011": 201034.09,
      "2012": 69302.8,
      "2013": 269720.53,
      "2014": 344046.56,
      "2015": 96408.84,
      "2016": 163660.76,
      "2017": 291730.41,
      "2018": 519469.05,
      "2019": 280028.28,
      "2020": 271155.03
    },
    Suriname: {
      "1997": 0.03,
      "1998": 0.55,
      "1999": null,
      "2000": 86.45,
      "2001": 125.75,
      "2002": 1574.65,
      "2003": 234.84,
      "2004": 430.01,
      "2005": 2870.94,
      "2006": 496.17,
      "2007": 343.79,
      "2008": 535.08,
      "2009": 698.05,
      "2010": 400.98,
      "2011": 2107.03,
      "2012": 7306.78,
      "2013": 7898.8,
      "2014": 130911.5,
      "2015": 27867.17,
      "2016": 30854.59,
      "2017": 59857.05,
      "2018": 62304.74,
      "2019": 38756.18,
      "2020": 25194.19
    },
    Swaziland: {
      "1997": 375.46,
      "1998": 132.27,
      "1999": 244.5,
      "2000": 433.52,
      "2001": 102.28,
      "2002": 218.55,
      "2003": 777.35,
      "2004": 1412.66,
      "2005": 10460.1,
      "2006": 25750.29,
      "2007": 14958.46,
      "2008": 17439.11,
      "2009": 15550.71,
      "2010": 50418.33,
      "2011": 22804.23,
      "2012": 38549.88,
      "2013": 77000.79,
      "2014": 13568.75,
      "2015": 26836.06,
      "2016": 26350.35,
      "2017": 5758.46,
      "2018": 6671.21,
      "2019": 4975.93,
      "2020": 37241.76
    },
    Sweden: {
      "1997": 102010.38,
      "1998": 84234.79,
      "1999": 103476.13,
      "2000": 108816.55,
      "2001": 191821.21,
      "2002": 250472.86,
      "2003": 321104.69,
      "2004": 421156.65,
      "2005": 518974.6,
      "2006": 874623,
      "2007": 857810.52,
      "2008": 888754.71,
      "2009": 753098.69,
      "2010": 736767.34,
      "2011": 921359.17,
      "2012": 915570.24,
      "2013": 1015828.97,
      "2014": 1070169.89,
      "2015": 969329.49,
      "2016": 778820.11,
      "2017": 944037.35,
      "2018": 927893.14,
      "2019": 788597.68,
      "2020": 743844.07
    },
    Switzerland: {
      "1997": 981403.97,
      "1998": 1237871.68,
      "1999": 1125655.79,
      "2000": 1443692.53,
      "2001": 1369115.08,
      "2002": 1127550.14,
      "2003": 1522251.93,
      "2004": 2668898.37,
      "2005": 2902482.09,
      "2006": 4128316.92,
      "2007": 3957082.02,
      "2008": 5270320.98,
      "2009": 6923201.92,
      "2010": 11273957.1,
      "2011": 16638338.39,
      "2012": 17451184.99,
      "2013": 11233818.2,
      "2014": 13536732.92,
      "2015": 12607429.32,
      "2016": 11561928.81,
      "2017": 12201780.82,
      "2018": 12617158.63,
      "2019": 11923941.46,
      "2020": 13386751.55
    },
    Syria: {
      "1997": 3314.24,
      "1998": 4886.53,
      "1999": 4970.69,
      "2000": 3046.82,
      "2001": 3532.92,
      "2002": 4276.64,
      "2003": 2136.04,
      "2004": 1715,
      "2005": 2270.64,
      "2006": 35995.02,
      "2007": 8155.82,
      "2008": 76397.86,
      "2009": 68777.56,
      "2010": 16157.03,
      "2011": 88967.45,
      "2012": 44483.91,
      "2013": 47354.56,
      "2014": 17320.39,
      "2015": 26558.5,
      "2016": 21639,
      "2017": 16531.34,
      "2018": 9898.36,
      "2019": 10866.13,
      "2020": 11337.29
    },
    "Taiwan Province of China": {
      "1997": 175570.1,
      "1998": 154586.68,
      "1999": 179745.31,
      "2000": 232393.08,
      "2001": 266729.21,
      "2002": 320729.97,
      "2003": 353340.48,
      "2004": 490685,
      "2005": 612284.48,
      "2006": 759424.86,
      "2007": 966396.61,
      "2008": 1294132.16,
      "2009": 1237084.18,
      "2010": 1803677.66,
      "2011": 2301149.07,
      "2012": 2157624.16,
      "2013": 2438157.26,
      "2014": 2461798.53,
      "2015": 2192053.03,
      "2016": 2107629.87,
      "2017": 2530249.75,
      "2018": 3200062.24,
      "2019": 2865446.74,
      "2020": 2983854.31
    },
    Tajikistan: {
      "1997": null,
      "1998": 1064.62,
      "1999": 1011.8,
      "2000": 248.47,
      "2001": 637.94,
      "2002": 39.43,
      "2003": 1816.46,
      "2004": 1835.92,
      "2005": 2607.04,
      "2006": 3644.23,
      "2007": 3899.27,
      "2008": 8066.21,
      "2009": 8067.02,
      "2010": 10504.34,
      "2011": 4365.7,
      "2012": 6991.29,
      "2013": 533.41,
      "2014": 2727.76,
      "2015": 6479.41,
      "2016": 14639.03,
      "2017": 32409.17,
      "2018": 3000.3,
      "2019": 208.52,
      "2020": 781.8
    },
    "Tanzania Rep": {
      "1997": 25264.23,
      "1998": 52431.47,
      "1999": 53950.5,
      "2000": 27175.97,
      "2001": 36290.34,
      "2002": 44112.57,
      "2003": 50232.96,
      "2004": 59152.95,
      "2005": 53019.98,
      "2006": 44392.18,
      "2007": 66221.28,
      "2008": 91817.54,
      "2009": 111886.4,
      "2010": 148145.28,
      "2011": 117744.78,
      "2012": 409604.81,
      "2013": 429842.43,
      "2014": 667980.35,
      "2015": 608635.41,
      "2016": 636994.86,
      "2017": 663442.69,
      "2018": 628140.57,
      "2019": 728751.37,
      "2020": 688815.94
    },
    Thailand: {
      "1997": 84203.63,
      "1998": 114900.19,
      "1999": 141272.01,
      "2000": 154378.54,
      "2001": 201779.47,
      "2002": 183418.68,
      "2003": 279868.85,
      "2004": 389050.73,
      "2005": 536409.91,
      "2006": 789880.06,
      "2007": 926400.41,
      "2008": 1235265.25,
      "2009": 1388850.94,
      "2010": 1945991.14,
      "2011": 2532484.41,
      "2012": 2911298.1,
      "2013": 3238001.02,
      "2014": 3586241,
      "2015": 3601107.17,
      "2016": 3631307.55,
      "2017": 4598799.09,
      "2018": 5197965,
      "2019": 4804785.44,
      "2020": 4200223.11
    },
    "Timor-Leste": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 11.08,
      "2004": 6.71,
      "2005": 19.3,
      "2006": 270.17,
      "2007": 19.89,
      "2008": 99.76,
      "2009": 7.06,
      "2010": 778.54,
      "2011": 10077.95,
      "2012": 27.77,
      "2013": 506.59,
      "2014": 186.82,
      "2015": 21.47,
      "2016": 116.99,
      "2017": 61.42,
      "2018": 0,
      "2019": null,
      "2020": 29.47
    },
    Togo: {
      "1997": 3698.94,
      "1998": 605.99,
      "1999": 6167.49,
      "2000": 5797.6,
      "2001": 5174.46,
      "2002": 6214.98,
      "2003": 9495.94,
      "2004": 20682.4,
      "2005": 35089.6,
      "2006": 34509.81,
      "2007": 24509.51,
      "2008": 63404.96,
      "2009": 32572.16,
      "2010": 42071.11,
      "2011": 72032.38,
      "2012": 96799.41,
      "2013": 94858.18,
      "2014": 119754.74,
      "2015": 145374.59,
      "2016": 92895.12,
      "2017": 105524.82,
      "2018": 212607.03,
      "2019": 270380.6,
      "2020": 222710.95
    },
    "Tokelau Is": {
      "1997": 1.34,
      "1998": 0.01,
      "1999": null,
      "2000": 94.05,
      "2001": 11.64,
      "2002": 73.83,
      "2003": null,
      "2004": 0.98,
      "2005": 13.24,
      "2006": 109.6,
      "2007": 30.66,
      "2008": 6.37,
      "2009": 6.37,
      "2010": 6.48,
      "2011": 3,
      "2012": 33.9,
      "2013": 164.03,
      "2014": 1.09,
      "2015": 2.4,
      "2016": 7.12,
      "2017": 35.34,
      "2018": 11.88,
      "2019": 54.62,
      "2020": 13.88
    },
    Tonga: {
      "1997": null,
      "1998": 7.46,
      "1999": 0,
      "2000": 0,
      "2001": null,
      "2002": 534.45,
      "2003": 373.76,
      "2004": null,
      "2005": 49.88,
      "2006": 72.14,
      "2007": 16.61,
      "2008": 80.31,
      "2009": 1.99,
      "2010": 99.29,
      "2011": 7.83,
      "2012": 39.45,
      "2013": 12.23,
      "2014": 5.5,
      "2015": 0.07,
      "2016": 33.99,
      "2017": null,
      "2018": 31.6,
      "2019": null,
      "2020": 0.22
    },
    "Trinidad and Tobago": {
      "1997": 974.68,
      "1998": 268.24,
      "1999": 6.24,
      "2000": null,
      "2001": 64.61,
      "2002": 55.44,
      "2003": 3382.2,
      "2004": 6268.35,
      "2005": 807.65,
      "2006": 23012.34,
      "2007": 70164.39,
      "2008": 46896.84,
      "2009": 27270.81,
      "2010": 36727.53,
      "2011": 94941.05,
      "2012": 4817.77,
      "2013": 3268.14,
      "2014": 42741.33,
      "2015": 60878.69,
      "2016": 116432.87,
      "2017": 27576.31,
      "2018": 134950.36,
      "2019": 60025.18,
      "2020": 140758.26
    },
    Tunisia: {
      "1997": 54047.82,
      "1998": 84124.39,
      "1999": 81090.62,
      "2000": 39353.15,
      "2001": 49675.09,
      "2002": 45248.62,
      "2003": 29733.98,
      "2004": 42657.98,
      "2005": 44783.94,
      "2006": 65256,
      "2007": 62845.56,
      "2008": 276172.05,
      "2009": 120466.4,
      "2010": 137587.09,
      "2011": 79294.41,
      "2012": 116567.6,
      "2013": 55730.38,
      "2014": 119128.29,
      "2015": 89854.34,
      "2016": 76858.72,
      "2017": 90263.36,
      "2018": 95943.52,
      "2019": 92852.42,
      "2020": 91566.38
    },
    Turkey: {
      "1997": 23905.68,
      "1998": 41473.31,
      "1999": 41840.92,
      "2000": 19795,
      "2001": 33078.43,
      "2002": 28862.28,
      "2003": 33691.11,
      "2004": 60621.87,
      "2005": 85800.78,
      "2006": 150630.51,
      "2007": 680148.98,
      "2008": 664242.24,
      "2009": 765933.91,
      "2010": 374245.39,
      "2011": 490744.02,
      "2012": 1106090.55,
      "2013": 459367.17,
      "2014": 893654.03,
      "2015": 508004.63,
      "2016": 809661.41,
      "2017": 1378514.52,
      "2018": 1667018.62,
      "2019": 1498523.7,
      "2020": 1087903.2
    },
    Turkmenistan: {
      "1997": 7.27,
      "1998": 44.5,
      "1999": 166.37,
      "2000": 512.74,
      "2001": 927.73,
      "2002": 2615.7,
      "2003": 4290.83,
      "2004": 4882.4,
      "2005": 5468.81,
      "2006": 5457.35,
      "2007": 3454.2,
      "2008": 5537.56,
      "2009": 4711.27,
      "2010": 4466.53,
      "2011": 9201.84,
      "2012": 4528.52,
      "2013": 8705.98,
      "2014": 7946.13,
      "2015": 31040.7,
      "2016": 14286.09,
      "2017": 16902.44,
      "2018": 14337.24,
      "2019": 2799.46,
      "2020": 222.15
    },
    "Turks C Is": {
      "1997": null,
      "1998": 17.45,
      "1999": 1.76,
      "2000": 9.62,
      "2001": 26.49,
      "2002": 4.8,
      "2003": 3.83,
      "2004": null,
      "2005": 1550.19,
      "2006": 144.94,
      "2007": 163.39,
      "2008": 769,
      "2009": 875.49,
      "2010": 34.8,
      "2011": 6.07,
      "2012": 26.07,
      "2013": 73.04,
      "2014": 6.51,
      "2015": 13.62,
      "2016": 20.82,
      "2017": 15.61,
      "2018": null,
      "2019": 7.85,
      "2020": 35.75
    },
    Tuvalu: {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0.89,
      "2004": null,
      "2005": 0.97,
      "2006": null,
      "2007": 639.12,
      "2008": 169.64,
      "2009": 22.38,
      "2010": null,
      "2011": 3.98,
      "2012": null,
      "2013": null,
      "2014": 865.9,
      "2015": 6.85,
      "2016": 84.45,
      "2017": 0.77,
      "2018": 0.14,
      "2019": 0,
      "2020": 0
    },
    "U Arab Emts": {
      "1997": 548196.82,
      "1998": 724135.71,
      "1999": 868059.6,
      "2000": 301052.2,
      "2001": 436423.15,
      "2002": 463138.92,
      "2003": 946527.17,
      "2004": 2085317.23,
      "2005": 1927703.35,
      "2006": 3917494.34,
      "2007": 5423319.3,
      "2008": 10592643.31,
      "2009": 9179885.04,
      "2010": 14912337.84,
      "2011": 17563752.67,
      "2012": 21292332.79,
      "2013": 17412666.37,
      "2014": 15962487.89,
      "2015": 12698138.61,
      "2016": 14423711.97,
      "2017": 14009589.24,
      "2018": 20855076.52,
      "2019": 21444701.89,
      "2020": 19635053.7
    },
    "United Kingdom": {
      "1997": 908042.85,
      "1998": 1102824.17,
      "1999": 1171094.44,
      "2000": 1447246.79,
      "2001": 1222440.07,
      "2002": 1343943.9,
      "2003": 1486226.34,
      "2004": 1602345.39,
      "2005": 1740081.5,
      "2006": 1888929.92,
      "2007": 1994151.94,
      "2008": 2676770.8,
      "2009": 2112542.8,
      "2010": 2456169.4,
      "2011": 3409393.87,
      "2012": 3423118.44,
      "2013": 3604301.73,
      "2014": 3073280.13,
      "2015": 3393630.88,
      "2016": 2458350.56,
      "2017": 3098947.19,
      "2018": 5274420.52,
      "2019": 4749488.09,
      "2020": 3661147.93
    },
    "United States": {
      "1997": 1380366.4,
      "1998": 1531475.16,
      "1999": 1542739,
      "2000": 1377386.76,
      "2001": 1502111.74,
      "2002": 2150482.32,
      "2003": 2313582.66,
      "2004": 3145813.32,
      "2005": 4185945.49,
      "2006": 5310541.44,
      "2007": 8462542.31,
      "2008": 8481827.99,
      "2009": 8058432.69,
      "2010": 9135850.32,
      "2011": 11236290.29,
      "2012": 13723859.06,
      "2013": 13561345.55,
      "2014": 13342060.28,
      "2015": 14267820.18,
      "2016": 14965539.59,
      "2017": 17156448.3,
      "2018": 24855377.39,
      "2019": 25336346.1,
      "2020": 21372470.63
    },
    Uganda: {
      "1997": 174.24,
      "1998": 136.83,
      "1999": 983.18,
      "2000": 1404.09,
      "2001": 571.11,
      "2002": 955.64,
      "2003": 941.58,
      "2004": 2966.86,
      "2005": 1255.84,
      "2006": 2137.74,
      "2007": 6086.68,
      "2008": 8804.17,
      "2009": 6318.39,
      "2010": 6209.96,
      "2011": 8780.57,
      "2012": 14986.34,
      "2013": 19994.11,
      "2014": 22965.9,
      "2015": 29963.23,
      "2016": 46173.1,
      "2017": 36236.51,
      "2018": 16175.45,
      "2019": 24105.29,
      "2020": 28676.32
    },
    Ukraine: {
      "1997": 66799.97,
      "1998": 57142.11,
      "1999": 66139.52,
      "2000": 59502.62,
      "2001": 79587.76,
      "2002": 94350.31,
      "2003": 108054.44,
      "2004": 242030.8,
      "2005": 350817.85,
      "2006": 451866,
      "2007": 356346.79,
      "2008": 703995.74,
      "2009": 752905.2,
      "2010": 645932.75,
      "2011": 1192711.66,
      "2012": 1447143.75,
      "2013": 1089582.77,
      "2014": 1366198.88,
      "2015": 1146457.19,
      "2016": 1665692.39,
      "2017": 1519449.39,
      "2018": 1627960.51,
      "2019": 1461868.87,
      "2020": 1586497.84
    },
    "Union of Serbia & Montenegro": {
      "1997": 1508.5,
      "1998": 1042.14,
      "1999": 804.55,
      "2000": 1499.04,
      "2001": 1029.1,
      "2002": 584.62,
      "2003": 1671.38,
      "2004": 1910.44,
      "2005": 862.17,
      "2006": 785.18,
      "2007": 1707.55,
      "2008": 6173.83,
      "2009": 2740.91,
      "2010": 1408.37,
      "2011": 1173.2,
      "2012": 7200.4,
      "2013": 3296.83,
      "2014": 9334.69,
      "2015": 1913.73,
      "2016": 0,
      "2017": 0,
      "2018": 0,
      "2019": 0,
      "2020": 0
    },
    Unspecified: {
      "1997": 0,
      "1998": 0,
      "1999": 25.7,
      "2000": 6759093.91,
      "2001": 6712573.03,
      "2002": 8606317.98,
      "2003": 9536653.1,
      "2004": 11672714.29,
      "2005": 10983028.66,
      "2006": 307679.87,
      "2007": 715187.06,
      "2008": 654700.57,
      "2009": 449172.01,
      "2010": 1962048.81,
      "2011": 493695.44,
      "2012": 1046971.23,
      "2013": 2474102.26,
      "2014": 4634383.08,
      "2015": 5692553.51,
      "2016": 4915672.88,
      "2017": 5930107.69,
      "2018": 62636.01,
      "2019": 87023.15,
      "2020": 41823.04
    },
    Uruguay: {
      "1997": 1158.45,
      "1998": 798.52,
      "1999": 882.98,
      "2000": 1321.04,
      "2001": 1676.97,
      "2002": 2253.22,
      "2003": 4695.37,
      "2004": 1820.26,
      "2005": 1788.98,
      "2006": 3292.52,
      "2007": 5330.22,
      "2008": 6510.27,
      "2009": 7600.59,
      "2010": 7920.62,
      "2011": 14741.74,
      "2012": 13316.9,
      "2013": 12409.53,
      "2014": 12439.48,
      "2015": 11586.36,
      "2016": 9043.38,
      "2017": 16097,
      "2018": 30136.97,
      "2019": 40673.07,
      "2020": 121053.13
    },
    "Us Minor Outlying Islands": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 0,
      "2008": 0,
      "2009": 0,
      "2010": 0,
      "2011": 0,
      "2012": 0,
      "2013": 187.89,
      "2014": 518.89,
      "2015": 232.54,
      "2016": 373.68,
      "2017": 479.84,
      "2018": 56.01,
      "2019": 285.82,
      "2020": 213.17
    },
    Uzbekistan: {
      "1997": 1006.42,
      "1998": 701.28,
      "1999": 5620.56,
      "2000": 4835.43,
      "2001": 8235.99,
      "2002": 9942.38,
      "2003": 12727.69,
      "2004": 14135.89,
      "2005": 11570.74,
      "2006": 15330.5,
      "2007": 6470.69,
      "2008": 32924,
      "2009": 14134.69,
      "2010": 9385.67,
      "2011": 18120.22,
      "2012": 17332.54,
      "2013": 19177.63,
      "2014": 34472.73,
      "2015": 29804.36,
      "2016": 31202.01,
      "2017": 65800.14,
      "2018": 88511.73,
      "2019": 47085.41,
      "2020": 11073.99
    },
    "Vanuatu Rep": {
      "1997": 0,
      "1998": 0,
      "1999": 3159.81,
      "2000": 13331.49,
      "2001": 20.17,
      "2002": 2155.58,
      "2003": 6263.25,
      "2004": 91.54,
      "2005": 21293.72,
      "2006": 4173.63,
      "2007": 418.33,
      "2008": 3396.47,
      "2009": 0.04,
      "2010": 32.94,
      "2011": null,
      "2012": 2939.52,
      "2013": null,
      "2014": 6.9,
      "2015": 196.78,
      "2016": null,
      "2017": 0.46,
      "2018": null,
      "2019": 178.86,
      "2020": 11.58
    },
    "Vatican City": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": 0,
      "2004": 0,
      "2005": 0,
      "2006": 0,
      "2007": 0,
      "2008": 0,
      "2009": 0,
      "2010": 0,
      "2011": 0,
      "2012": 0,
      "2013": 119.5,
      "2014": 103.65,
      "2015": 1.26,
      "2016": 13.49,
      "2017": null,
      "2018": 65.56,
      "2019": 130.26,
      "2020": 14.75
    },
    Venezuela: {
      "1997": 2517.62,
      "1998": 2776,
      "1999": 1113.32,
      "2000": 6596.08,
      "2001": 1232.46,
      "2002": 1793.62,
      "2003": 1153.98,
      "2004": 1806.11,
      "2005": 4228.41,
      "2006": 336257.42,
      "2007": 159794.62,
      "2008": 1847918.48,
      "2009": 1350691.31,
      "2010": 2374824.56,
      "2011": 3213006,
      "2012": 7683453.41,
      "2013": 8438442.94,
      "2014": 7158579.9,
      "2015": 3715029.41,
      "2016": 3696429.07,
      "2017": 3779668.44,
      "2018": 5080946.23,
      "2019": 4295510.14,
      "2020": 529493.32
    },
    "Vietnam Soc Rep": {
      "1997": 3245.75,
      "1998": 3846.52,
      "1999": 4993.82,
      "2000": 5662.5,
      "2001": 9019.16,
      "2002": 14123.01,
      "2003": 17560.23,
      "2004": 38865.36,
      "2005": 58169.04,
      "2006": 75861.2,
      "2007": 69807.09,
      "2008": 186226.3,
      "2009": 245911.66,
      "2010": 484759.06,
      "2011": 832325.93,
      "2012": 1259442.24,
      "2013": 1556837.66,
      "2014": 1839804.38,
      "2015": 1676311.58,
      "2016": 2226346.53,
      "2017": 3235671.34,
      "2018": 5028580.45,
      "2019": 5149059.73,
      "2020": 4525375.06
    },
    "Virgin Is Us": {
      "1997": 0.21,
      "1998": 2.52,
      "1999": 0,
      "2000": 0,
      "2001": 398.42,
      "2002": 4545.43,
      "2003": 56.07,
      "2004": 31.67,
      "2005": 164.54,
      "2006": 40.43,
      "2007": 880.79,
      "2008": 638.74,
      "2009": 1195.21,
      "2010": 155.59,
      "2011": 44.59,
      "2012": 215.45,
      "2013": 1371.8,
      "2014": 359.73,
      "2015": 708.07,
      "2016": 3040.98,
      "2017": 182.84,
      "2018": 538.33,
      "2019": 5352.15,
      "2020": 63.25
    },
    "Wallis F Is": {
      "1997": 0,
      "1998": 0,
      "1999": 0,
      "2000": 0,
      "2001": 0,
      "2002": 0,
      "2003": null,
      "2004": 71.78,
      "2005": 0.86,
      "2006": 17.76,
      "2007": 6.38,
      "2008": 7.07,
      "2009": 1.14,
      "2010": 1.95,
      "2011": 0,
      "2012": 0,
      "2013": 61.82,
      "2014": 610.29,
      "2015": 154.28,
      "2016": null,
      "2017": 7.12,
      "2018": 0.04,
      "2019": null,
      "2020": 0.62
    },
    "Yemen Republc": {
      "1997": 4384.08,
      "1998": 4506.23,
      "1999": 110583.49,
      "2000": 6999.99,
      "2001": 22801.71,
      "2002": 20441.44,
      "2003": 13673,
      "2004": 13848.03,
      "2005": 4428.49,
      "2006": 909901.92,
      "2007": 591418.94,
      "2008": 334530,
      "2009": 742341.75,
      "2010": 796638.89,
      "2011": 480575.93,
      "2012": 515269.5,
      "2013": 472737.13,
      "2014": 332557.58,
      "2015": 4414.27,
      "2016": 3234.25,
      "2017": 97969.19,
      "2018": 8099.99,
      "2019": 16097.11,
      "2020": 17161.45
    },
    Zambia: {
      "1997": 36139.26,
      "1998": 16451.11,
      "1999": 11244.68,
      "2000": 5285.74,
      "2001": 6433.71,
      "2002": 6969.1,
      "2003": 8524.71,
      "2004": 10318.29,
      "2005": 17957.69,
      "2006": 39023.42,
      "2007": 30116.73,
      "2008": 99228.05,
      "2009": 48060.57,
      "2010": 14528.87,
      "2011": 83222.88,
      "2012": 176911.41,
      "2013": 145368.92,
      "2014": 174047.78,
      "2015": 313442.87,
      "2016": 499101.56,
      "2017": 705579.61,
      "2018": 356718.66,
      "2019": 598602.66,
      "2020": 94448.74
    },
    Zimbabwe: {
      "1997": 6403.44,
      "1998": 6266.93,
      "1999": 6078.26,
      "2000": 3808.38,
      "2001": 8816.03,
      "2002": 6797.07,
      "2003": 7545.55,
      "2004": 12197.03,
      "2005": 11311.17,
      "2006": 14574.13,
      "2007": 8950.79,
      "2008": 6629.79,
      "2009": 9905.92,
      "2010": 5289.13,
      "2011": 1382.62,
      "2012": 18705.49,
      "2013": 7576.84,
      "2014": 20149.97,
      "2015": 16171.3,
      "2016": 40615.92,
      "2017": 40100.88,
      "2018": 5494.96,
      "2019": 9671.87,
      "2020": 4194.3
    },
    "India's Total Import": {
      "1997": 15417628.82,
      "1998": 17833185.44,
      "1999": 21552843.89,
      "2000": 23087276.04,
      "2001": 24519971.86,
      "2002": 29720587.4,
      "2003": 35910766.37,
      "2004": 50106454.03,
      "2005": 66040890.33,
      "2006": 84050631.33,
      "2007": 101231169.93,
      "2008": 137443555.45,
      "2009": 136373554.76,
      "2010": 168346695.57,
      "2011": 234546324.45,
      "2012": 266916195.69,
      "2013": 271543390.74,
      "2014": 273708657.84,
      "2015": 249030553.8,
      "2016": 257767536.7,
      "2017": 300103343.4,
      "2018": 359467461.2,
      "2019": 336095445.6,
      "2020": 291595770
    },
    "": {
      "1997": 1243191.96,
      "1998": null,
      "1999": 1111902.03,
      "2000": 404972.99,
      "2001": null,
      "2002": null,
      "2003": null,
      "2004": 1969299.95,
      "2005": "8,621,800.16",
      "2006": null,
      "2007": null,
      "2008": null,
      "2009": null,
      "2010": null,
      "2011": null,
      "2012": null,
      "2013": null,
      "2014": null,
      "2015": null,
      "2016": null,
      "2017": null,
      "2018": null,
      "2019": null,
      "2020": null
    }
  };

export const usimports = [
  ["China", 1995, 48505590073],
  ["China", 1998, 75094917637],
  ["China", 1999, 87775113556],
  ["China", 2000, 100013000000],
  ["China", 2001, 102267000000],
  ["China", 2002, 133510000000],
  ["China", 2003, 163314000000],
  ["China", 2004, 210500000000],
  ["China", 2005, 259836000000],
  ["China", 2006, 305779000000],
  ["China", 2007, 340107000000],
  ["China", 2008, 356305000000],
  ["China", 2009, 309530000000],
  ["China", 2010, 382965000000],
  ["China", 2011, 417340000000],
  ["China", 2012, 444386000000],
  ["China", 2013, 459108000000],
  ["China", 2014, 486296000000],
  ["China", 2015, 504028000000],
  ["China", 2016, 481310000000],
  ["China", 2017, 525765000000],
  ["China", 2018, 563203000000],
  ["China", 2019, 472465000000],
  ["China", 2020, 457164000000],
  ["China", 2021, 541531000000],
  ["Germany", 1995, 38041611082],
  ["Germany", 1998, 51281904644],
  ["Germany", 1999, 56605937472],
  ["Germany", 2000, 58511297806],
  ["Germany", 2001, 59075091602],
  ["Germany", 2002, 63905160737],
  ["Germany", 2003, 69681503172],
  ["Germany", 2004, 79143125099],
  ["Germany", 2005, 86873024135],
  ["Germany", 2006, 91215229465],
  ["Germany", 2007, 96631595724],
  ["Germany", 2008, 99758479283],
  ["Germany", 2009, 72632756092],
  ["Germany", 2010, 84129523274],
  ["Germany", 2011, 100676000000],
  ["Germany", 2012, 111298000000],
  ["Germany", 2013, 116406000000],
  ["Germany", 2014, 125532000000],
  ["Germany", 2015, 127171000000],
  ["Germany", 2016, 116259000000],
  ["Germany", 2017, 119963000000],
  ["Germany", 2018, 128346000000],
  ["Germany", 2019, 129857000000],
  ["Germany", 2020, 117393000000],
  ["Germany", 2021, 138195000000],
  ["India", 1995, 6090545498],
  ["India", 1998, 8658535090],
  ["India", 1999, 9589900847],
  ["India", 2000, 10686244175],
  ["India", 2001, 9737138347],
  ["India", 2002, 12449857747],
  ["India", 2003, 13754749619],
  ["India", 2004, 16447125728],
  ["India", 2005, 19873206149],
  ["India", 2006, 22992712582],
  ["India", 2007, 25113348871],
  ["India", 2008, 26931322410],
  ["India", 2009, 22042749853],
  ["India", 2010, 30708298250],
  ["India", 2011, 37455488622],
  ["India", 2012, 41904468276],
  ["India", 2013, 43217508473],
  ["India", 2014, 46985468069],
  ["India", 2015, 46677949828],
  ["India", 2016, 47731926317],
  ["India", 2017, 50519287590],
  ["India", 2018, 56443791815],
  ["India", 2019, 59916979523],
  ["India", 2020, 53567301596],
  ["India", 2021, 77019390796],
  ["Russia", 1995, 4269194002],
  ["Russia", 1998, 6008276403],
  ["Russia", 1999, 6016725005],
  ["Russia", 2000, 7658130131],
  ["Russia", 2001, 6264072874],
  ["Russia", 2002, 7207030000],
  ["Russia", 2003, 9106464838],
  ["Russia", 2004, 12618720467],
  ["Russia", 2005, 16177975806],
  ["Russia", 2006, 20730747653],
  ["Russia", 2007, 20219112562],
  ["Russia", 2008, 27931764911],
  ["Russia", 2009, 18993424402],
  ["Russia", 2010, 26538543324],
  ["Russia", 2011, 35557789977],
  ["Russia", 2012, 30232470771],
  ["Russia", 2013, 27851650445],
  ["Russia", 2014, 24465063186],
  ["Russia", 2015, 17162435175],
  ["Russia", 2016, 15292228487],
  ["Russia", 2017, 17777151208],
  ["Russia", 2018, 21595991662],
  ["Russia", 2019, 23185179447],
  ["Russia", 2020, 17711785612],
  ["Russia", 2021, 30762271499],
  ["Saudi Arabia", 1995, 8897840368],
  ["Saudi Arabia", 1998, 7168647770],
  ["Saudi Arabia", 1999, 8903012213],
  ["Saudi Arabia", 2000, 14364708947],
  ["Saudi Arabia", 2001, 13272217245],
  ["Saudi Arabia", 2002, 13898557612],
  ["Saudi Arabia", 2003, 19524636095],
  ["Saudi Arabia", 2004, 22540465985],
  ["Saudi Arabia", 2005, 28927673121],
  ["Saudi Arabia", 2006, 33086758506],
  ["Saudi Arabia", 2007, 37164681640],
  ["Saudi Arabia", 2008, 57005380622],
  ["Saudi Arabia", 2009, 23243140457],
  ["Saudi Arabia", 2010, 32643466126],
  ["Saudi Arabia", 2011, 48760012831],
  ["Saudi Arabia", 2012, 57327161821],
  ["Saudi Arabia", 2013, 53100429979],
  ["Saudi Arabia", 2014, 48352775579],
  ["Saudi Arabia", 2015, 23357522363],
  ["Saudi Arabia", 2016, 18009018899],
  ["Saudi Arabia", 2017, 19616080032],
  ["Saudi Arabia", 2018, 24615651683],
  ["Saudi Arabia", 2019, 13865218115],
  ["Saudi Arabia", 2020, 9520664600],
  ["Saudi Arabia", 2021, 13930625138],
  ["Brazil", 1995, 9428290002],
  ["Brazil", 1998, 10642211988],
  ["Brazil", 1999, 11896362532],
  ["Brazil", 2000, 13852522371],
  ["Brazil", 2001, 14466412455],
  ["Brazil", 2002, 16690150250],
  ["Brazil", 2003, 18991824510],
  ["Brazil", 2004, 22738203208],
  ["Brazil", 2005, 26217184797],
  ["Brazil", 2006, 28031247072],
  ["Brazil", 2007, 27193409748],
  ["Brazil", 2008, 32072884601],
  ["Brazil", 2009, 21017804644],
  ["Brazil", 2010, 25009190211],
  ["Brazil", 2011, 32838643288],
  ["Brazil", 2012, 33254643141],
  ["Brazil", 2013, 28658285220],
  ["Brazil", 2014, 31607113617],
  ["Brazil", 2015, 28517865646],
  ["Brazil", 2016, 27047003993],
  ["Brazil", 2017, 30551943376],
  ["Brazil", 2018, 32286167963],
  ["Brazil", 2019, 32079462357],
  ["Brazil", 2020, 24421640761],
  ["Brazil", 2021, 32843010222],
  ["Germany", 1995, 38041611082],
  ["Germany", 1998, 51281904644],
  ["Germany", 1999, 56605937472],
  ["Germany", 2000, 58511297806],
  ["Germany", 2001, 59075091602],
  ["Germany", 2002, 63905160737],
  ["Germany", 2003, 69681503172],
  ["Germany", 2004, 79143125099],
  ["Germany", 2005, 86873024135],
  ["Germany", 2006, 91215229465],
  ["Germany", 2007, 96631595724],
  ["Germany", 2008, 99758479283],
  ["Germany", 2009, 72632756092],
  ["Germany", 2010, 84129523274],
  ["Germany", 2011, 100676000000],
  ["Germany", 2012, 111298000000],
  ["Germany", 2013, 116406000000],
  ["Germany", 2014, 125532000000],
  ["Germany", 2015, 127171000000],
  ["Germany", 2016, 116259000000],
  ["Germany", 2017, 119963000000],
  ["Germany", 2018, 128346000000],
  ["Germany", 2019, 129857000000],
  ["Germany", 2020, 117393000000],
  ["Germany", 2021, 138195000000],
  ["Italy", 1995, 17304715981],
  ["Italy", 1998, 21913512883],
  ["Italy", 1999, 23344675118],
  ["Italy", 2000, 25041357364],
  ["Italy", 2001, 23789648869],
  ["Italy", 2002, 25345444958],
  ["Italy", 2003, 26630248048],
  ["Italy", 2004, 29414531704],
  ["Italy", 2005, 32417743285],
  ["Italy", 2006, 34100163151],
  ["Italy", 2007, 36466828763],
  ["Italy", 2008, 37492843241],
  ["Italy", 2009, 27275830871],
  ["Italy", 2010, 29472303584],
  ["Italy", 2011, 35087489076],
  ["Italy", 2012, 38179400506],
  ["Italy", 2013, 39862392883],
  ["Italy", 2014, 43352379238],
  ["Italy", 2015, 45424138451],
  ["Italy", 2016, 46607024610],
  ["Italy", 2017, 51322939607],
  ["Italy", 2018, 56256198051],
  ["Italy", 2019, 58739583352],
  ["Italy", 2020, 50996273740],
  ["Italy", 2021, 63045552698],
  ["Japan", 1995, 127195000000],
  ["Japan", 1998, 125090000000],
  ["Japan", 1999, 134871000000],
  ["Japan", 2000, 146479000000],
  ["Japan", 2001, 126473000000],
  ["Japan", 2002, 124566000000],
  ["Japan", 2003, 121239000000],
  ["Japan", 2004, 133552000000],
  ["Japan", 2005, 141861000000],
  ["Japan", 2006, 152244000000],
  ["Japan", 2007, 149423000000],
  ["Japan", 2008, 143352000000],
  ["Japan", 2009, 98401031288],
  ["Japan", 2010, 123763000000],
  ["Japan", 2011, 132559000000],
  ["Japan", 2012, 150447000000],
  ["Japan", 2013, 142137000000],
  ["Japan", 2014, 137504000000],
  ["Japan", 2015, 135024000000],
  ["Japan", 2016, 135071000000],
  ["Japan", 2017, 139733000000],
  ["Japan", 2018, 145902000000],
  ["Japan", 2019, 146974000000],
  ["Japan", 2020, 122484000000],
  ["Japan", 2021, 139390000000],
  ["Spain", 1995, 4160237253],
  ["Spain", 1998, 5111673924],
  ["Spain", 1999, 5406158657],
  ["Spain", 2000, 5713156315],
  ["Spain", 2001, 5197160784],
  ["Spain", 2002, 6118204437],
  ["Spain", 2003, 7109224664],
  ["Spain", 2004, 7805304094],
  ["Spain", 2005, 9125458867],
  ["Spain", 2006, 10401726727],
  ["Spain", 2007, 11074635344],
  ["Spain", 2008, 11572987249],
  ["Spain", 2009, 8190323367],
  ["Spain", 2010, 8905307066],
  ["Spain", 2011, 11461146079],
  ["Spain", 2012, 12199506803],
  ["Spain", 2013, 12090926832],
  ["Spain", 2014, 14901316002],
  ["Spain", 2015, 14679457047],
  ["Spain", 2016, 13964572350],
  ["Spain", 2017, 16278102038],
  ["Spain", 2018, 17808358572],
  ["Spain", 2019, 17483878877],
  ["Spain", 2020, 15925756977],
  ["Spain", 2021, 19451512930],

  ["Australia", 1995, 3560502078],
  ["Australia", 1998, 5697004115],
  ["Australia", 1999, 5584667679],
  ["Australia", 2000, 6424999568],
  ["Australia", 2001, 6470381342],
  ["Australia", 2002, 6816507245],
  ["Australia", 2003, 6713861918],
  ["Australia", 2004, 7899475342],
  ["Australia", 2005, 7659316346],
  ["Australia", 2006, 8541085343],
  ["Australia", 2007, 8956453032],
  ["Australia", 2008, 10925971419],
  ["Australia", 2009, 8220815311],
  ["Australia", 2010, 8795060190],
  ["Australia", 2011, 10364472436],
  ["Australia", 2012, 9673186809],
  ["Australia", 2013, 9203539948],
  ["Australia", 2014, 10765008044],
  ["Australia", 2015, 10965056475],
  ["Australia", 2016, 9556561771],
  ["Australia", 2017, 10166117144],
  ["Australia", 2018, 10209238802],
  ["Australia", 2019, 11017254934],
  ["Australia", 2020, 14334509767],
  ["Australia", 2021, 11865966492],
  ["Canada", 1995, 148277899470],
  ["Canada", 1998, 177916422390],
  ["Canada", 1999, 201433185872],
  ["Canada", 2000, 230816137678],
  ["Canada", 2001, 216234127196],
  ["Canada", 2002, 212398395081],
  ["Canada", 2003, 225035348740],
  ["Canada", 2004, 260161285509],
  ["Canada", 2005, 294382272249],
  ["Canada", 2006, 307723064224],
  ["Canada", 2007, 317481896694],
  ["Canada", 2008, 339074076152],
  ["Canada", 2009, 227577354210],
  ["Canada", 2010, 280426517803],
  ["Canada", 2011, 317921220034],
  ["Canada", 2012, 327502570360],
  ["Canada", 2013, 337126102072],
  ["Canada", 2014, 354171825400],
  ["Canada", 2015, 301942705090],
  ["Canada", 2016, 282919218117],
  ["Canada", 2017, 305647659426],
  ["Canada", 2018, 325683550892],
  ["Canada", 2019, 326628559104],
  ["Canada", 2020, 276195545882],
  ["Canada", 2021, 363904687514],
  ["Mexico", 1995, 62745628522],
  ["Mexico", 1998, 96074621036],
  ["Mexico", 1999, 111067215403],
  ["Mexico", 2000, 135923120580],
  ["Mexico", 2001, 131334695141],
  ["Mexico", 2002, 136025055674],
  ["Mexico", 2003, 139686658091],
  ["Mexico", 2004, 157881923554],
  ["Mexico", 2005, 172388577232],
  ["Mexico", 2006, 200499681947],
  ["Mexico", 2007, 212878005162],
  ["Mexico", 2008, 218066232793],
  ["Mexico", 2009, 178321648369],
  ["Mexico", 2010, 232251414675],
  ["Mexico", 2011, 265113824434],
  ["Mexico", 2012, 279957416737],
  ["Mexico", 2013, 283133714143],
  ["Mexico", 2014, 296855758706],
  ["Mexico", 2015, 299181820273],
  ["Mexico", 2016, 296199943735],
  ["Mexico", 2017, 315733533681],
  ["Mexico", 2018, 349195245116],
  ["Mexico", 2019, 361320937424],
  ["Mexico", 2020, 328861803529],
  ["Mexico", 2021, 388357522055],
  ["Ukraine", 1995, 471725605],
  ["Ukraine", 1998, 584790138],
  ["Ukraine", 1999, 586112632],
  ["Ukraine", 2000, 872184184],
  ["Ukraine", 2001, 673583289],
  ["Ukraine", 2002, 398886623],
  ["Ukraine", 2003, 288818523],
  ["Ukraine", 2004, 870124732],
  ["Ukraine", 2005, 1219912529],
  ["Ukraine", 2006, 1764652168],
  ["Ukraine", 2007, 1319425349],
  ["Ukraine", 2008, 2490510472],
  ["Ukraine", 2009, 513843472],
  ["Ukraine", 2010, 1130035863],
  ["Ukraine", 2011, 1528024894],
  ["Ukraine", 2012, 1408043691],
  ["Ukraine", 2013, 1084735608],
  ["Ukraine", 2014, 973143116],
  ["Ukraine", 2015, 896400360],
  ["Ukraine", 2016, 609292106],
  ["Ukraine", 2017, 1074163194],
  ["Ukraine", 2018, 1425675430],
  ["Ukraine", 2019, 1359495942],
  ["Ukraine", 2020, 1376073850],
  ["Ukraine", 2021, 1988053137],

  ["Argentina", 1995, 1881133710],
  ["Argentina", 1998, 2441024633],
  ["Argentina", 1999, 2793390167],
  ["Argentina", 2000, 3099473404],
  ["Argentina", 2001, 3013446446],
  ["Argentina", 2002, 3434104320],
  ["Argentina", 2003, 3435500071],
  ["Argentina", 2004, 4067279572],
  ["Argentina", 2005, 4956583262],
  ["Argentina", 2006, 4276932932],
  ["Argentina", 2007, 4819989736],
  ["Argentina", 2008, 6177698342],
  ["Argentina", 2009, 4133441888],
  ["Argentina", 2010, 4007943482],
  ["Argentina", 2011, 4735427165],
  ["Argentina", 2012, 4575543037],
  ["Argentina", 2013, 4870331892],
  ["Argentina", 2014, 4453637876],
  ["Argentina", 2015, 4187110074],
  ["Argentina", 2016, 4911097074],
  ["Argentina", 2017, 5001495389],
  ["Argentina", 2018, 5032016626],
  ["Argentina", 2019, 5120074216],
  ["Argentina", 2020, 4378126238],
  ["Argentina", 2021, 5392176906],
  ["Cuba", 1999, 654576],
  ["Cuba", 2000, 323097],
  ["Cuba", 2001, 5565],
  ["Cuba", 2002, 319273],
  ["Cuba", 2003, 313527],
  ["Cuba", 2004, 40915],
  ["Cuba", 2005, 9524],
  ["Cuba", 2006, 158654],
  ["Cuba", 2007, 271701],
  ["Cuba", 2008, 36696],
  ["Cuba", 2009, 27957],
  ["Cuba", 2010, 347480],
  ["Cuba", 2011, 5700],
  ["Cuba", 2012, 95955],
  ["Cuba", 2018, 1275914],
  ["Cuba", 2019, 2587421],
  ["Cuba", 2020, 14908544],
  ["Cuba", 2021, 2902399],
  ["Islamic Republic of Iran", 1995, 191811],
  ["Islamic Republic of Iran", 1998, 27208],
  ["Islamic Republic of Iran", 1999, 2402244],
  ["Islamic Republic of Iran", 2000, 168771054],
  ["Islamic Republic of Iran", 2001, 143384832],
  ["Islamic Republic of Iran", 2002, 162587030],
  ["Islamic Republic of Iran", 2003, 166976642],
  ["Islamic Republic of Iran", 2004, 156349538],
  ["Islamic Republic of Iran", 2005, 179380856],
  ["Islamic Republic of Iran", 2006, 162017150],
  ["Islamic Republic of Iran", 2007, 178221861],
  ["Islamic Republic of Iran", 2008, 105529496],
  ["Islamic Republic of Iran", 2009, 68665634],
  ["Islamic Republic of Iran", 2010, 97163843],
  ["Islamic Republic of Iran", 2011, 1037061],
  ["Islamic Republic of Iran", 2012, 2144148],
  ["Islamic Republic of Iran", 2013, 2219630],
  ["Islamic Republic of Iran", 2015, 11842945],
  ["Islamic Republic of Iran", 2016, 90051429],
  ["Islamic Republic of Iran", 2017, 64627819],
  ["Islamic Republic of Iran", 2018, 72717090],
  ["Islamic Republic of Iran", 2019, 1438814],
  ["Islamic Republic of Iran", 2020, 3915203],
  ["Islamic Republic of Iran", 2021, 1294273],
  ["Ireland", 1995, 4149450590],
  ["Ireland", 1998, 8479083430],
  ["Ireland", 1999, 11092010172],
  ["Ireland", 2000, 16463576619],
  ["Ireland", 2001, 18499248740],
  ["Ireland", 2002, 22534882260],
  ["Ireland", 2003, 25865047589],
  ["Ireland", 2004, 27574777158],
  ["Ireland", 2005, 28882086577],
  ["Ireland", 2006, 28777532242],
  ["Ireland", 2007, 30491013653],
  ["Ireland", 2008, 31709337796],
  ["Ireland", 2009, 28187870794],
  ["Ireland", 2010, 33974844240],
  ["Ireland", 2011, 39506389330],
  ["Ireland", 2012, 33500452553],
  ["Ireland", 2013, 31626776931],
  ["Ireland", 2014, 34097159510],
  ["Ireland", 2015, 39492818091],
  ["Ireland", 2016, 45721162234],
  ["Ireland", 2017, 49089452160],
  ["Ireland", 2018, 57679282033],
  ["Ireland", 2019, 61989364643],
  ["Ireland", 2020, 65779092043],
  ["Ireland", 2021, 74048244654],
  ["Venezuela", 1995, 10427903138],
  ["Venezuela", 1998, 10072806727],
  ["Venezuela", 1999, 11950013184],
  ["Venezuela", 2000, 18623174354],
  ["Venezuela", 2001, 15250267479],
  ["Venezuela", 2002, 15813270178],
  ["Venezuela", 2003, 18065799039],
  ["Venezuela", 2004, 26274102126],
  ["Venezuela", 2005, 35306345154],
  ["Venezuela", 2006, 38386162098],
  ["Venezuela", 2007, 41010703537],
  ["Venezuela", 2008, 52610099830],
  ["Venezuela", 2009, 28780266837],
  ["Venezuela", 2010, 33313828315],
  ["Venezuela", 2011, 43865920825],
  ["Venezuela", 2012, 39341379430],
  ["Venezuela", 2013, 32477093689],
  ["Venezuela", 2014, 30863480753],
  ["Venezuela", 2015, 16223281261],
  ["Venezuela", 2016, 11444169407],
  ["Venezuela", 2017, 12750539107],
  ["Venezuela", 2018, 13515294764],
  ["Venezuela", 2019, 2015839951],
  ["Venezuela", 2020, 179318416],
  ["Venezuela", 2021, 326937356],

  ["Colombia", 1995, 3992017313],
  ["Colombia", 1998, 4939560393],
  ["Colombia", 1999, 6609788524],
  ["Colombia", 2000, 6968000685],
  ["Colombia", 2001, 5710262820],
  ["Colombia", 2002, 5933425105],
  ["Colombia", 2003, 6789572262],
  ["Colombia", 2004, 7731397453],
  ["Colombia", 2005, 9428654602],
  ["Colombia", 2006, 9826571592],
  ["Colombia", 2007, 10033876757],
  ["Colombia", 2008, 13832363807],
  ["Colombia", 2009, 11875343180],
  ["Colombia", 2010, 16229639259],
  ["Colombia", 2011, 23720046987],
  ["Colombia", 2012, 25213481686],
  ["Colombia", 2013, 22157559841],
  ["Colombia", 2014, 18858160801],
  ["Colombia", 2015, 14694420723],
  ["Colombia", 2016, 14435553847],
  ["Colombia", 2017, 14126818925],
  ["Colombia", 2018, 14309499521],
  ["Colombia", 2019, 14705658063],
  ["Colombia", 2020, 11310309333],
  ["Colombia", 2021, 13730705679],
  ["Pakistan", 1995, 1278181213],
  ["Pakistan", 1998, 1788113559],
  ["Pakistan", 1999, 1871365178],
  ["Pakistan", 2000, 2166761514],
  ["Pakistan", 2001, 2249393120],
  ["Pakistan", 2002, 2482750715],
  ["Pakistan", 2003, 2733544263],
  ["Pakistan", 2004, 3090376864],
  ["Pakistan", 2005, 3492929408],
  ["Pakistan", 2006, 3964457454],
  ["Pakistan", 2007, 3830622130],
  ["Pakistan", 2008, 3828337590],
  ["Pakistan", 2009, 3361196923],
  ["Pakistan", 2010, 3727938941],
  ["Pakistan", 2011, 4025928170],
  ["Pakistan", 2012, 3828392685],
  ["Pakistan", 2013, 3887604039],
  ["Pakistan", 2014, 3869193279],
  ["Pakistan", 2015, 3909838802],
  ["Pakistan", 2016, 3617846998],
  ["Pakistan", 2017, 3762269975],
  ["Pakistan", 2018, 3896188972],
  ["Pakistan", 2019, 4117395336],
  ["Pakistan", 2020, 4115927796],
  ["Pakistan", 2021, 5678270467],
  ["Peru", 1995, 1092287056],
  ["Peru", 1998, 2072629963],
  ["Peru", 1999, 2027670785],
  ["Peru", 2000, 1994936003],
  ["Peru", 2001, 1843761346],
  ["Peru", 2002, 2084524112],
  ["Peru", 2003, 2574355864],
  ["Peru", 2004, 3913533243],
  ["Peru", 2005, 5393502244],
  ["Peru", 2006, 6155107712],
  ["Peru", 2007, 5488613985],
  ["Peru", 2008, 6138066992],
  ["Peru", 2009, 4412193636],
  ["Peru", 2010, 5507905667],
  ["Peru", 2011, 6905853718],
  ["Peru", 2012, 6672067895],
  ["Peru", 2013, 8405335731],
  ["Peru", 2014, 6413467323],
  ["Peru", 2015, 5383031539],
  ["Peru", 2016, 6551437683],
  ["Peru", 2017, 7626656967],
  ["Peru", 2018, 8272403890],
  ["Peru", 2019, 6514273377],
  ["Peru", 2020, 5929121327],
  ["Peru", 2021, 7335930861],
  ["United Arab Emirates", 1995, 485533066],
  ["United Arab Emirates", 1998, 707870180],
  ["United Arab Emirates", 1999, 756008891],
  ["United Arab Emirates", 2000, 971334842],
  ["United Arab Emirates", 2001, 1194220425],
  ["United Arab Emirates", 2002, 983856993],
  ["United Arab Emirates", 2003, 1212055141],
  ["United Arab Emirates", 2004, 1215401457],
  ["United Arab Emirates", 2005, 1539848018],
  ["United Arab Emirates", 2006, 1447037067],
  ["United Arab Emirates", 2007, 1394073643],
  ["United Arab Emirates", 2008, 1347550414],
  ["United Arab Emirates", 2009, 1565393403],
  ["United Arab Emirates", 2010, 1196290525],
  ["United Arab Emirates", 2011, 2508100235],
  ["United Arab Emirates", 2012, 2326199501],
  ["United Arab Emirates", 2013, 2382005256],
  ["United Arab Emirates", 2014, 2939275627],
  ["United Arab Emirates", 2015, 2595032388],
  ["United Arab Emirates", 2016, 3556522763],
  ["United Arab Emirates", 2017, 4474244965],
  ["United Arab Emirates", 2018, 5226656092],
  ["United Arab Emirates", 2019, 4569353642],
  ["United Arab Emirates", 2020, 3234654801],
  ["United Arab Emirates", 2021, 6217117605],
  ["United Kingdom", 1995, 27663758497],
  ["United Kingdom", 1998, 35678548542],
  ["United Kingdom", 1999, 40138210988],
  ["United Kingdom", 2000, 43333410391],
  ["United Kingdom", 2001, 41348914664],
  ["United Kingdom", 2002, 41685151613],
  ["United Kingdom", 2003, 43870286252],
  ["United Kingdom", 2004, 47532915634],
  ["United Kingdom", 2005, 52337822475],
  ["United Kingdom", 2006, 54612972216],
  ["United Kingdom", 2007, 58070295574],
  ["United Kingdom", 2008, 59740052569],
  ["United Kingdom", 2009, 48249946912],
  ["United Kingdom", 2010, 50674817700],
  ["United Kingdom", 2011, 52149820173],
  ["United Kingdom", 2012, 56004751199],
  ["United Kingdom", 2013, 53728268470],
  ["United Kingdom", 2014, 55316558838],
  ["United Kingdom", 2015, 58887110546],
  ["United Kingdom", 2016, 55147156285],
  ["United Kingdom", 2017, 54171521570],
  ["United Kingdom", 2018, 61748826091],
  ["United Kingdom", 2019, 64133144834],
  ["United Kingdom", 2020, 50839851484],
  ["United Kingdom", 2021, 56949194450],

  ["France", 1995, 17768148586],
  ["France", 1998, 24736795629],
  ["France", 1999, 26548740850],
  ["France", 2000, 29833154470],
  ["France", 2001, 30431140445],
  ["France", 2002, 28858103026],
  ["France", 2003, 29893781742],
  ["France", 2004, 32409880336],
  ["France", 2005, 34765548696],
  ["France", 2006, 38082574458],
  ["France", 2007, 42492266040],
  ["France", 2008, 44886713687],
  ["France", 2009, 34582355372],
  ["France", 2010, 38982183967],
  ["France", 2011, 40804610648],
  ["France", 2012, 42533569567],
  ["France", 2013, 46540390549],
  ["France", 2014, 47750027418],
  ["France", 2015, 48864400404],
  ["France", 2016, 47766560286],
  ["France", 2017, 50049674524],
  ["France", 2018, 53621601767],
  ["France", 2019, 58491722749],
  ["France", 2020, 43871784332],
  ["France", 2021, 51688760306],
  ["Georgia", 1995, 11580864],
  ["Georgia", 1998, 15242812],
  ["Georgia", 1999, 19162729],
  ["Georgia", 2000, 31906247],
  ["Georgia", 2001, 30727575],
  ["Georgia", 2002, 18823671],
  ["Georgia", 2003, 60091290],
  ["Georgia", 2004, 83968020],
  ["Georgia", 2005, 205977357],
  ["Georgia", 2006, 117665061],
  ["Georgia", 2007, 200445682],
  ["Georgia", 2008, 243824037],
  ["Georgia", 2009, 71616742],
  ["Georgia", 2010, 205912223],
  ["Georgia", 2011, 185189880],
  ["Georgia", 2012, 238337150],
  ["Georgia", 2013, 182789701],
  ["Georgia", 2014, 407572914],
  ["Georgia", 2015, 188466241],
  ["Georgia", 2016, 98206678],
  ["Georgia", 2017, 136503263],
  ["Georgia", 2018, 198595888],
  ["Georgia", 2019, 158617273],
  ["Georgia", 2020, 157327042],
  ["Georgia", 2021, 201957784],
  ["Honduras", 1995, 1541722595],
  ["Honduras", 1998, 2661853545],
  ["Honduras", 1999, 2805540352],
  ["Honduras", 2000, 3090227026],
  ["Honduras", 2001, 3126486476],
  ["Honduras", 2002, 3393596850],
  ["Honduras", 2003, 3455035055],
  ["Honduras", 2004, 3803148193],
  ["Honduras", 2005, 3918939085],
  ["Honduras", 2006, 3893030145],
  ["Honduras", 2007, 4101324331],
  ["Honduras", 2008, 4233721764],
  ["Honduras", 2009, 3489642169],
  ["Honduras", 2010, 4103872666],
  ["Honduras", 2011, 4692888122],
  ["Honduras", 2012, 4853608913],
  ["Honduras", 2013, 4757715204],
  ["Honduras", 2014, 4857913957],
  ["Honduras", 2015, 5007180102],
  ["Honduras", 2016, 4869033564],
  ["Honduras", 2017, 4813710890],
  ["Honduras", 2018, 4932374618],
  ["Honduras", 2019, 5067264683],
  ["Honduras", 2020, 4073688721],
  ["Honduras", 2021, 5448717648],
  ["Netherlands", 1995, 6768947141],
  ["Netherlands", 1998, 8008621396],
  ["Netherlands", 1999, 8876730068],
  ["Netherlands", 2000, 9670171604],
  ["Netherlands", 2001, 9514796260],
  ["Netherlands", 2002, 10290956294],
  ["Netherlands", 2003, 11416919199],
  ["Netherlands", 2004, 13015397443],
  ["Netherlands", 2005, 15503289087],
  ["Netherlands", 2006, 18068285312],
  ["Netherlands", 2007, 19133966199],
  ["Netherlands", 2008, 21813287951],
  ["Netherlands", 2009, 16573999196],
  ["Netherlands", 2010, 20642808243],
  ["Netherlands", 2011, 24949969111],
  ["Netherlands", 2012, 22897908459],
  ["Netherlands", 2013, 19924888955],
  ["Netherlands", 2014, 21580662870],
  ["Netherlands", 2015, 17644076085],
  ["Netherlands", 2016, 16676762852],
  ["Netherlands", 2017, 18463323868],
  ["Netherlands", 2018, 25276774616],
  ["Netherlands", 2019, 30883263358],
  ["Netherlands", 2020, 28204641888],
  ["Netherlands", 2021, 36249608571],
  ["Panama", 1995, 335436252],
  ["Panama", 1998, 330411899],
  ["Panama", 1999, 396173487],
  ["Panama", 2000, 307018400],
  ["Panama", 2001, 290668945],
  ["Panama", 2002, 321008746],
  ["Panama", 2003, 318859055],
  ["Panama", 2004, 335290401],
  ["Panama", 2005, 349471312],
  ["Panama", 2006, 404642884],
  ["Panama", 2007, 390974074],
  ["Panama", 2008, 401651922],
  ["Panama", 2009, 321990552],
  ["Panama", 2010, 402619732],
  ["Panama", 2011, 409989680],
  ["Panama", 2012, 563310189],
  ["Panama", 2013, 467702915],
  ["Panama", 2014, 447203783],
  ["Panama", 2015, 425410884],
  ["Panama", 2016, 426609968],
  ["Panama", 2017, 457852076],
  ["Panama", 2018, 437599896],
  ["Panama", 2019, 468289994],
  ["Panama", 2020, 711141295],
  ["Panama", 2021, 783573863],

  ["Chile", 1995, 2196957891],
  ["Chile", 1998, 2811270861],
  ["Chile", 1999, 3287598056],
  ["Chile", 2000, 3269034710],
  ["Chile", 2001, 3495307949],
  ["Chile", 2002, 4353673647],
  ["Chile", 2003, 4326063527],
  ["Chile", 2004, 5419460634],
  ["Chile", 2005, 7444221167],
  ["Chile", 2006, 10291072878],
  ["Chile", 2007, 9783399229],
  ["Chile", 2008, 8980952306],
  ["Chile", 2009, 6620213966],
  ["Chile", 2010, 7688179905],
  ["Chile", 2011, 9735524799],
  ["Chile", 2012, 10083152844],
  ["Chile", 2013, 11190495589],
  ["Chile", 2014, 10265746050],
  ["Chile", 2015, 9577927641],
  ["Chile", 2016, 9545723021],
  ["Chile", 2017, 11295780585],
  ["Chile", 2018, 12200356723],
  ["Chile", 2019, 11150838054],
  ["Chile", 2020, 10917000710],
  ["Chile", 2021, 15960402475],
  ["Finland", 1995, 2391225295],
  ["Finland", 1998, 2746753860],
  ["Finland", 1999, 3043026322],
  ["Finland", 2000, 3250760508],
  ["Finland", 2001, 3393758778],
  ["Finland", 2002, 3609949397],
  ["Finland", 2003, 3782906879],
  ["Finland", 2004, 4087247920],
  ["Finland", 2005, 4537264431],
  ["Finland", 2006, 5176107849],
  ["Finland", 2007, 5500865507],
  ["Finland", 2008, 6142785607],
  ["Finland", 2009, 4134392023],
  ["Finland", 2010, 4046473590],
  ["Finland", 2011, 4611766741],
  ["Finland", 2012, 5294378976],
  ["Finland", 2013, 4853978876],
  ["Finland", 2014, 5226663235],
  ["Finland", 2015, 4675357388],
  ["Finland", 2016, 4747728858],
  ["Finland", 2017, 6062686319],
  ["Finland", 2018, 7358350507],
  ["Finland", 2019, 6590871078],
  ["Finland", 2020, 5098572162],
  ["Finland", 2021, 7026237078],
  ["Greece", 1995, 437437475],
  ["Greece", 1998, 514686971],
  ["Greece", 1999, 618795588],
  ["Greece", 2000, 589653694],
  ["Greece", 2001, 504712747],
  ["Greece", 2002, 601016396],
  ["Greece", 2003, 669634058],
  ["Greece", 2004, 810310935],
  ["Greece", 2005, 1004238516],
  ["Greece", 2006, 1069744240],
  ["Greece", 2007, 1296831758],
  ["Greece", 2008, 1090360770],
  ["Greece", 2009, 887633995],
  ["Greece", 2010, 844930542],
  ["Greece", 2011, 911189864],
  ["Greece", 2012, 1049904660],
  ["Greece", 2013, 1017837217],
  ["Greece", 2014, 1107544443],
  ["Greece", 2015, 1445905212],
  ["Greece", 2016, 1319660782],
  ["Greece", 2017, 1355349942],
  ["Greece", 2018, 1722823455],
  ["Greece", 2019, 1621364744],
  ["Greece", 2020, 1412356952],
  ["Greece", 2021, 1828826068],
  ["Greenland", 1995, 7671903],
  ["Greenland", 1998, 7657196],
  ["Greenland", 1999, 13695508],
  ["Greenland", 2000, 15731824],
  ["Greenland", 2001, 28752636],
  ["Greenland", 2002, 23491497],
  ["Greenland", 2003, 14285076],
  ["Greenland", 2004, 15166028],
  ["Greenland", 2005, 17836797],
  ["Greenland", 2006, 11426260],
  ["Greenland", 2007, 10216095],
  ["Greenland", 2008, 6278821],
  ["Greenland", 2009, 7862494],
  ["Greenland", 2010, 8537845],
  ["Greenland", 2011, 7641624],
  ["Greenland", 2012, 6933212],
  ["Greenland", 2013, 9654994],
  ["Greenland", 2014, 5577717],
  ["Greenland", 2015, 3851057],
  ["Greenland", 2016, 6009805],
  ["Greenland", 2017, 13335178],
  ["Greenland", 2018, 16534472],
  ["Greenland", 2019, 27445066],
  ["Greenland", 2020, 29197608],
  ["Greenland", 2021, 41643296],
  ["New Zealand", 1995, 1595962325],
  ["New Zealand", 1998, 1802488096],
  ["New Zealand", 1999, 1916804295],
  ["New Zealand", 2000, 2080136036],
  ["New Zealand", 2001, 2199180249],
  ["New Zealand", 2002, 2469488107],
  ["New Zealand", 2003, 2600715643],
  ["New Zealand", 2004, 3178109030],
  ["New Zealand", 2005, 3368297471],
  ["New Zealand", 2006, 3325598594],
  ["New Zealand", 2007, 3316218947],
  ["New Zealand", 2008, 3352228509],
  ["New Zealand", 2009, 2721200255],
  ["New Zealand", 2010, 2936739394],
  ["New Zealand", 2011, 3352480577],
  ["New Zealand", 2012, 3620245399],
  ["New Zealand", 2013, 3666952789],
  ["New Zealand", 2014, 4158630556],
  ["New Zealand", 2015, 4481055935],
  ["New Zealand", 2016, 4215797241],
  ["New Zealand", 2017, 4334671787],
  ["New Zealand", 2018, 4363883424],
  ["New Zealand", 2019, 4280746215],
  ["New Zealand", 2020, 4375785612],
  ["New Zealand", 2021, 5155566358],
  ["Central African Republic", 1995, 456796],
  ["Central African Republic", 1998, 2835234],
  ["Central African Republic", 1999, 2935341],
  ["Central African Republic", 2000, 2965118],
  ["Central African Republic", 2001, 2363674],
  ["Central African Republic", 2002, 2022356],
  ["Central African Republic", 2003, 1983181],
  ["Central African Republic", 2004, 8878770],
  ["Central African Republic", 2005, 5749907],
  ["Central African Republic", 2006, 4372967],
  ["Central African Republic", 2007, 3027263],
  ["Central African Republic", 2008, 9104407],
  ["Central African Republic", 2009, 3475778],
  ["Central African Republic", 2010, 5857704],
  ["Central African Republic", 2011, 6107551],
  ["Central African Republic", 2012, 4179504],
  ["Central African Republic", 2013, 2845632],
  ["Central African Republic", 2014, 1472332],
  ["Central African Republic", 2015, 3167198],
  ["Central African Republic", 2016, 1058282],
  ["Central African Republic", 2017, 2263408],
  ["Central African Republic", 2018, 2923540],
  ["Central African Republic", 2019, 3649133],
  ["Central African Republic", 2020, 992184],
  ["Central African Republic", 2021, 1810295],
  ["Iraq", 1998, 1360678264],
  ["Iraq", 1999, 4516137154],
  ["Iraq", 2000, 6065850854],
  ["Iraq", 2001, 5820309675],
  ["Iraq", 2002, 3752090284],
  ["Iraq", 2003, 4924364395],
  ["Iraq", 2004, 9203443091],
  ["Iraq", 2005, 9673665461],
  ["Iraq", 2006, 12230833166],
  ["Iraq", 2007, 11893852206],
  ["Iraq", 2008, 23071752290],
  ["Iraq", 2009, 9719248553],
  ["Iraq", 2010, 12624747615],
  ["Iraq", 2011, 17368920169],
  ["Iraq", 2012, 19638419749],
  ["Iraq", 2013, 13546577511],
  ["Iraq", 2014, 14158797671],
  ["Iraq", 2015, 4568488842],
  ["Iraq", 2016, 6319320868],
  ["Iraq", 2017, 11159118941],
  ["Iraq", 2018, 12125500688],
  ["Iraq", 2019, 7240173082],
  ["Iraq", 2020, 3266587475],
  ["Iraq", 2021, 3885396684],
  ["Jordan", 1995, 30721964],
  ["Jordan", 1998, 17102941],
  ["Jordan", 1999, 31628158],
  ["Jordan", 2000, 73258841],
  ["Jordan", 2001, 229073317],
  ["Jordan", 2002, 437672834],
  ["Jordan", 2003, 704788583],
  ["Jordan", 2004, 1145326852],
  ["Jordan", 2005, 1319916997],
  ["Jordan", 2006, 1488674696],
  ["Jordan", 2007, 1388218403],
  ["Jordan", 2008, 1183992357],
  ["Jordan", 2009, 954306462],
  ["Jordan", 2010, 1007774475],
  ["Jordan", 2011, 1090566322],
  ["Jordan", 2012, 1188002284],
  ["Jordan", 2013, 1227503108],
  ["Jordan", 2014, 1432620694],
  ["Jordan", 2015, 1525952732],
  ["Jordan", 2016, 1592460969],
  ["Jordan", 2017, 1728198416],
  ["Jordan", 2018, 1856790417],
  ["Jordan", 2019, 2227683505],
  ["Jordan", 2020, 1920196537],
  ["Jordan", 2021, 2827222822],
  ["South Africa", 2000, 4206808750],
  ["South Africa", 2001, 4428372940],
  ["South Africa", 2002, 4178679037],
  ["South Africa", 2003, 4786683620],
  ["South Africa", 2004, 6154240999],
  ["South Africa", 2005, 6083067761],
  ["South Africa", 2006, 7734756293],
  ["South Africa", 2007, 9282117200],
  ["South Africa", 2008, 10132738456],
  ["South Africa", 2009, 5967517804],
  ["South Africa", 2010, 8359458232],
  ["South Africa", 2011, 9623790266],
  ["South Africa", 2012, 8829784618],
  ["South Africa", 2013, 8585148357],
  ["South Africa", 2014, 8466309968],
  ["South Africa", 2015, 7441353516],
  ["South Africa", 2016, 6922227710],
  ["South Africa", 2017, 7886161136],
  ["South Africa", 2018, 8649734976],
  ["South Africa", 2019, 7963631173],
  ["South Africa", 2020, 11440399527],
  ["South Africa", 2021, 15819256804],
  ["Turkey", 1995, 1925587836],
  ["Turkey", 1998, 2677335348],
  ["Turkey", 1999, 2761608335],
  ["Turkey", 2000, 3041421261],
  ["Turkey", 2001, 3054742882],
  ["Turkey", 2002, 3761181964],
  ["Turkey", 2003, 4062445025],
  ["Turkey", 2004, 5320295002],
  ["Turkey", 2005, 5591132321],
  ["Turkey", 2006, 5774354218],
  ["Turkey", 2007, 4897056628],
  ["Turkey", 2008, 4949657147],
  ["Turkey", 2009, 3856853638],
  ["Turkey", 2010, 4424450122],
  ["Turkey", 2011, 5464067068],
  ["Turkey", 2012, 6614586187],
  ["Turkey", 2013, 7007254133],
  ["Turkey", 2014, 7763894847],
  ["Turkey", 2015, 8353762477],
  ["Turkey", 2016, 8493585558],
  ["Turkey", 2017, 9877101059],
  ["Turkey", 2018, 10897623964],
  ["Turkey", 2019, 11305381266],
  ["Turkey", 2020, 11754371122],
  ["Turkey", 2021, 17061287308]
];
