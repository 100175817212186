//https://apps.bea.gov/itable/?ReqID=70&step=1#eyJhcHBpZCI6NzAsInN0ZXBzIjpbMSwyNCwyOSwyNSwzMSwyNiwyNywzMF0sImRhdGEiOltbIlRhYmxlSWQiLCIxMyJdLFsiQ2xhc3NpZmljYXRpb24iLCJOb24tSW5kdXN0cnkiXSxbIk1ham9yX0FyZWEiLCI0Il0sWyJTdGF0ZSIsWyIwIl1dLFsiQXJlYSIsWyIwMDAwMCJdXSxbIlN0YXRpc3RpYyIsWyItMSIsIjIxMjAiLCIyMjEwIiwiMjIyMCIsIjIyMzAiLCIyMzEwIiwiMjMyMCIsIjIzMzAiLCIyMzQwIiwiMjQwMCIsIjI1MDAiXV0sWyJVbml0X29mX21lYXN1cmUiLCJMZXZlbHMiXSxbIlllYXIiLFsiLTEiXV0sWyJZZWFyQmVnaW4iLCItMSJdLFsiWWVhcl9FbmQiLCItMSJdXX0=

import React from "react";

const benefits = {
  /*"Personal current transfer receipts (thousands of dollars)": {
    "1969": 62322000,
    "1970": 74733000,
    "1971": 88248000,
    "1972": 98130000,
    "1973": 112848000,
    "1974": 133639000,
    "1975": 170424000,
    "1976": 184845000,
    "1977": 195111000,
    "1978": 210561000,
    "1979": 236376000,
    "1980": 280874000,
    "1981": 319762000,
    "1982": 356141000,
    "1983": 384671000,
    "1984": 401232000,
    "1985": 425761000,
    "1986": 451711000,
    "1987": 468094000,
    "1988": 497410000,
    "1989": 544186000,
    "1990": 597231000,
    "1991": 668048000,
    "1992": 747939000,
    "1993": 792906000,
    "1994": 828880000,
    "1995": 883374000,
    "1996": 929128000,
    "1997": 954797000,
    "1998": 983878000,
    "1999": 1026144000,
    "2000": 1087280000,
    "2001": 1192565000,
    "2002": 1285190000,
    "2003": 1347228000,
    "2004": 1421206000,
    "2005": 1516711000,
    "2006": 1613814000,
    "2007": 1728065000,
    "2008": 1955082000,
    "2009": 2146693000,
    "2010": 2325117000,
    "2011": 2358654000,
    "2012": 2362988000,
    "2013": 2424317000,
    "2014": 2541516000,
    "2015": 2685370000,
    "2016": 2776983000,
    "2017": 2856320000,
    "2018": 2976288000,
    "2019": 3139054000,
    "2020": 4241091000
  },
  "Current transfer receipts of individuals from governments": {
    "1969": 57683000,
    "1970": 70200000,
    "1971": 83771000,
    "1972": 93129000,
    "1973": 107057000,
    "1974": 126659000,
    "1975": 160829000,
    "1976": 174478000,
    "1977": 186336000,
    "1978": 198899000,
    "1979": 222794000,
    "1980": 266290000,
    "1981": 302752000,
    "1982": 338895000,
    "1983": 365934000,
    "1984": 375911000,
    "1985": 398071000,
    "1986": 422614000,
    "1987": 441602000,
    "1988": 470347000,
    "1989": 514069000,
    "1990": 568060000,
    "1991": 642577000,
    "1992": 723129000,
    "1993": 769383000,
    "1994": 805802000,
    "1995": 854510000,
    "1996": 895821000,
    "1997": 925025000,
    "1998": 947034000,
    "1999": 980524000,
    "2000": 1032404000,
    "2001": 1131508000,
    "2002": 1235362000,
    "2003": 1304243000,
    "2004": 1387144000,
    "2005": 1473518000,
    "2006": 1575359000,
    "2007": 1679768000,
    "2008": 1900544000,
    "2009": 2087422000,
    "2010": 2260351000,
    "2011": 2288747000,
    "2012": 2300206000,
    "2013": 2363160000,
    "2014": 2475559000,
    "2015": 2612313000,
    "2016": 2694124000,
    "2017": 2784542000,
    "2018": 2902300000,
    "2019": 3057831000,
    "2020": 4050252000
  },*/
  "Retirement and disability insurance benefits": {
    "1969": 28931000,
    "1970": 34321000,
    "1971": 40152000,
    "1972": 44966000,
    "1973": 55771000,
    "1974": 63041000,
    "1975": 72015000,
    "1976": 81261000,
    "1977": 90513000,
    "1978": 99237000,
    "1979": 111895000,
    "1980": 128802000,
    "1981": 149780000,
    "1982": 165783000,
    "1983": 177146000,
    "1984": 186272000,
    "1985": 197243000,
    "1986": 208369000,
    "1987": 216862000,
    "1988": 231057000,
    "1989": 245408000,
    "1990": 263888000,
    "1991": 285655000,
    "1992": 304661000,
    "1993": 320858000,
    "1994": 334780000,
    "1995": 350022000,
    "1996": 364760000,
    "1997": 378930000,
    "1998": 391214000,
    "1999": 402394000,
    "2000": 424461000,
    "2001": 449803000,
    "2002": 474483000,
    "2003": 493438000,
    "2004": 517045000,
    "2005": 545484000,
    "2006": 576879000,
    "2007": 608759000,
    "2008": 639044000,
    "2009": 698533000,
    "2010": 724895000,
    "2011": 747448000,
    "2012": 796218000,
    "2013": 832756000,
    "2014": 870337000,
    "2015": 907978000,
    "2016": 930384000,
    "2017": 959239000,
    "2018": 1006324000,
    "2019": 1065496000,
    "2020": 1113849000
  },
  "Social Security benefits": {
    "1969": 26382000,
    "1970": 31380000,
    "1971": 36617000,
    "1972": 40948000,
    "1973": 50665000,
    "1974": 57607000,
    "1975": 65895000,
    "1976": 74501000,
    "1977": 83239000,
    "1978": 91380000,
    "1979": 102581000,
    "1980": 118586000,
    "1981": 138644000,
    "1982": 153708000,
    "1983": 164426000,
    "1984": 173027000,
    "1985": 183275000,
    "1986": 193631000,
    "1987": 200995000,
    "1988": 213916000,
    "1989": 227380000,
    "1990": 244135000,
    "1991": 264204000,
    "1992": 281776000,
    "1993": 297935000,
    "1994": 312161000,
    "1995": 327675000,
    "1996": 342005000,
    "1997": 356592000,
    "1998": 369182000,
    "1999": 379864000,
    "2000": 401393000,
    "2001": 425084000,
    "2002": 446909000,
    "2003": 463531000,
    "2004": 485512000,
    "2005": 512728000,
    "2006": 544096000,
    "2007": 575653000,
    "2008": 605542000,
    "2009": 664470000,
    "2010": 690174000,
    "2011": 713261000,
    "2012": 762139000,
    "2013": 799044000,
    "2014": 834640000,
    "2015": 871793000,
    "2016": 896472000,
    "2017": 926072000,
    "2018": 972412000,
    "2019": 1030743000,
    "2020": 1077928000
  },
  "Excluding Social Security benefits 1/": {
    "1969": 2549000,
    "1970": 2941000,
    "1971": 3535000,
    "1972": 4018000,
    "1973": 5106000,
    "1974": 5434000,
    "1975": 6120000,
    "1976": 6760000,
    "1977": 7274000,
    "1978": 7857000,
    "1979": 9314000,
    "1980": 10216000,
    "1981": 11136000,
    "1982": 12075000,
    "1983": 12720000,
    "1984": 13245000,
    "1985": 13968000,
    "1986": 14738000,
    "1987": 15867000,
    "1988": 17141000,
    "1989": 18028000,
    "1990": 19753000,
    "1991": 21451000,
    "1992": 22885000,
    "1993": 22923000,
    "1994": 22619000,
    "1995": 22347000,
    "1996": 22755000,
    "1997": 22338000,
    "1998": 22032000,
    "1999": 22530000,
    "2000": 23068000,
    "2001": 24719000,
    "2002": 27574000,
    "2003": 29907000,
    "2004": 31533000,
    "2005": 32756000,
    "2006": 32783000,
    "2007": 33106000,
    "2008": 33502000,
    "2009": 34063000,
    "2010": 34721000,
    "2011": 34187000,
    "2012": 34079000,
    "2013": 33712000,
    "2014": 35697000,
    "2015": 36185000,
    "2016": 33912000,
    "2017": 33167000,
    "2018": 33912000,
    "2019": 34753000,
    "2020": 35921000
  },
  "Medical benefits": {
    "1969": 11579000,
    "1970": 13163000,
    "1971": 15381000,
    "1972": 17767000,
    "1973": 20716000,
    "1974": 25051000,
    "1975": 30828000,
    "1976": 35664000,
    "1977": 40520000,
    "1978": 46119000,
    "1979": 53205000,
    "1980": 62624000,
    "1981": 74373000,
    "1982": 84633000,
    "1983": 95586000,
    "1984": 105554000,
    "1985": 114146000,
    "1986": 124156000,
    "1987": 135056000,
    "1988": 145517000,
    "1989": 165773000,
    "1990": 188808000,
    "1991": 222822000,
    "1992": 257716000,
    "1993": 284953000,
    "1994": 311886000,
    "1995": 338579000,
    "1996": 360865000,
    "1997": 377592000,
    "1998": 383406000,
    "1999": 400723000,
    "2000": 427194000,
    "2001": 480761000,
    "2002": 522404000,
    "2003": 556459000,
    "2004": 611374000,
    "2005": 655571000,
    "2006": 720384000,
    "2007": 775933000,
    "2008": 825516000,
    "2009": 890395000,
    "2010": 938402000,
    "2011": 970055000,
    "2012": 1001620000,
    "2013": 1040505000,
    "2014": 1117744000,
    "2015": 1197744000,
    "2016": 1250495000,
    "2017": 1292294000,
    "2018": 1351012000,
    "2019": 1427227000,
    "2020": 1504739000
  },
  "Medicare benefits": {
    "1969": 6695000,
    "1970": 7254000,
    "1971": 8049000,
    "1972": 8836000,
    "1973": 10244000,
    "1974": 12726000,
    "1975": 15633000,
    "1976": 18795000,
    "1977": 22101000,
    "1978": 25543000,
    "1979": 29936000,
    "1980": 36201000,
    "1981": 43499000,
    "1982": 50927000,
    "1983": 57797000,
    "1984": 64748000,
    "1985": 69720000,
    "1986": 75269000,
    "1987": 81585000,
    "1988": 86289000,
    "1989": 98175000,
    "1990": 107638000,
    "1991": 117487000,
    "1992": 132596000,
    "1993": 146785000,
    "1994": 164423000,
    "1995": 181220000,
    "1996": 194868000,
    "1997": 206917000,
    "1998": 205558000,
    "1999": 208736000,
    "2000": 219117000,
    "2001": 242580000,
    "2002": 259736000,
    "2003": 276708000,
    "2004": 304359000,
    "2005": 332127000,
    "2006": 399107000,
    "2007": 428201000,
    "2008": 461613000,
    "2009": 492951000,
    "2010": 513390000,
    "2011": 535609000,
    "2012": 554746000,
    "2013": 572843000,
    "2014": 600007000,
    "2015": 634939000,
    "2016": 662109000,
    "2017": 692547000,
    "2018": 734919000,
    "2019": 785703000,
    "2020": 819202000
  },
  "Public assistance medical care benefits": {
    "1969": 4634000,
    "1970": 5600000,
    "1971": 6969000,
    "1972": 8514000,
    "1973": 9999000,
    "1974": 11827000,
    "1975": 14686000,
    "1976": 16362000,
    "1977": 17874000,
    "1978": 19979000,
    "1979": 22581000,
    "1980": 25659000,
    "1981": 29980000,
    "1982": 32592000,
    "1983": 36514000,
    "1984": 39438000,
    "1985": 42859000,
    "1986": 47062000,
    "1987": 51388000,
    "1988": 56769000,
    "1989": 64976000,
    "1990": 78176000,
    "1991": 102116000,
    "1992": 121786000,
    "1993": 135145000,
    "1994": 144886000,
    "1995": 155007000,
    "1996": 163602000,
    "1997": 168291000,
    "1998": 175278000,
    "1999": 189252000,
    "2000": 205021000,
    "2001": 234643000,
    "2002": 258543000,
    "2003": 274017000,
    "2004": 300137000,
    "2005": 315189000,
    "2006": 311162000,
    "2007": 336816000,
    "2008": 351731000,
    "2009": 383831000,
    "2010": 410957000,
    "2011": 419794000,
    "2012": 431433000,
    "2013": 453638000,
    "2014": 503083000,
    "2015": 547527000,
    "2016": 574219000,
    "2017": 585351000,
    "2018": 601999000,
    "2019": 626648000,
    "2020": 670395000
  },
  "Military medical insurance benefits": {
    "1969": 250000,
    "1970": 309000,
    "1971": 363000,
    "1972": 417000,
    "1973": 473000,
    "1974": 498000,
    "1975": 509000,
    "1976": 507000,
    "1977": 545000,
    "1978": 597000,
    "1979": 688000,
    "1980": 764000,
    "1981": 894000,
    "1982": 1114000,
    "1983": 1275000,
    "1984": 1368000,
    "1985": 1567000,
    "1986": 1825000,
    "1987": 2083000,
    "1988": 2459000,
    "1989": 2622000,
    "1990": 2994000,
    "1991": 3219000,
    "1992": 3334000,
    "1993": 3023000,
    "1994": 2577000,
    "1995": 2352000,
    "1996": 2395000,
    "1997": 2384000,
    "1998": 2570000,
    "1999": 2735000,
    "2000": 3056000,
    "2001": 3538000,
    "2002": 4125000,
    "2003": 5734000,
    "2004": 6878000,
    "2005": 8255000,
    "2006": 10115000,
    "2007": 10916000,
    "2008": 12172000,
    "2009": 13613000,
    "2010": 14055000,
    "2011": 14652000,
    "2012": 15441000,
    "2013": 14024000,
    "2014": 14654000,
    "2015": 15278000,
    "2016": 14167000,
    "2017": 14396000,
    "2018": 14094000,
    "2019": 14876000,
    "2020": 15142000
  },
  "Income maintenance benefits": {
    "1969": 7275000,
    "1970": 9897000,
    "1971": 12220000,
    "1972": 13398000,
    "1973": 13935000,
    "1974": 17887000,
    "1975": 21480000,
    "1976": 23998000,
    "1977": 24773000,
    "1978": 25234000,
    "1979": 28026000,
    "1980": 34933000,
    "1981": 38209000,
    "1982": 38919000,
    "1983": 41936000,
    "1984": 43256000,
    "1985": 44886000,
    "1986": 47513000,
    "1987": 48653000,
    "1988": 52986000,
    "1989": 58178000,
    "1990": 65441000,
    "1991": 74771000,
    "1992": 86836000,
    "1993": 92509000,
    "1994": 98210000,
    "1995": 104951000,
    "1996": 107701000,
    "1997": 106158000,
    "1998": 107098000,
    "1999": 109356000,
    "2000": 110582000,
    "2001": 113847000,
    "2002": 124986000,
    "2003": 137644000,
    "2004": 149094000,
    "2005": 165905000,
    "2006": 170430000,
    "2007": 180646000,
    "2008": 196000000,
    "2009": 230848000,
    "2010": 256034000,
    "2011": 264137000,
    "2012": 267614000,
    "2013": 271171000,
    "2014": 270971000,
    "2015": 273685000,
    "2016": 270676000,
    "2017": 269727000,
    "2018": 262713000,
    "2019": 272103000,
    "2020": 302583000
  },
  "Supplemental Security Income (SSI) benefits": {
    "1969": 2627000,
    "1970": 2953000,
    "1971": 3172000,
    "1972": 3365000,
    "1973": 3449000,
    "1974": 5281000,
    "1975": 5913000,
    "1976": 6137000,
    "1977": 6306000,
    "1978": 6556000,
    "1979": 7081000,
    "1980": 7941000,
    "1981": 8595000,
    "1982": 8979000,
    "1983": 9405000,
    "1984": 10372000,
    "1985": 11058000,
    "1986": 12079000,
    "1987": 12948000,
    "1988": 13785000,
    "1989": 14980000,
    "1990": 16670000,
    "1991": 18612000,
    "1992": 22328000,
    "1993": 24653000,
    "1994": 26269000,
    "1995": 27726000,
    "1996": 28903000,
    "1997": 29154000,
    "1998": 30322000,
    "1999": 31023000,
    "2000": 31675000,
    "2001": 33162000,
    "2002": 34664000,
    "2003": 35719000,
    "2004": 37095000,
    "2005": 38261000,
    "2006": 39903000,
    "2007": 42201000,
    "2008": 44017000,
    "2009": 47581000,
    "2010": 49158000,
    "2011": 50484000,
    "2012": 53038000,
    "2013": 54851000,
    "2014": 55794000,
    "2015": 56661000,
    "2016": 56449000,
    "2017": 56413000,
    "2018": 56858000,
    "2019": 57716000,
    "2020": 57932000
  },
  "Earned Income Tax Credit (EITC)": {
    "1969": "(NA)",
    "1970": "(NA)",
    "1971": "(NA)",
    "1972": "(NA)",
    "1973": "(NA)",
    "1974": "(NA)",
    "1975": "(NA)",
    "1976": 1271000,
    "1977": 1262000,
    "1978": 1132000,
    "1979": 1069000,
    "1980": 1983000,
    "1981": 1933000,
    "1982": 1834000,
    "1983": 1769000,
    "1984": 1694000,
    "1985": 1577000,
    "1986": 2009000,
    "1987": 1936000,
    "1988": 3711000,
    "1989": 5646000,
    "1990": 6313000,
    "1991": 7175000,
    "1992": 10549000,
    "1993": 11649000,
    "1994": 13985000,
    "1995": 19903000,
    "1996": 24336000,
    "1997": 27413000,
    "1998": 29192000,
    "1999": 30276000,
    "2000": 30423000,
    "2001": 30225000,
    "2002": 32156000,
    "2003": 36468000,
    "2004": 37675000,
    "2005": 39237000,
    "2006": 41049000,
    "2007": 43769000,
    "2008": 46649000,
    "2009": 48841000,
    "2010": 59778000,
    "2011": 60950000,
    "2012": 62472000,
    "2013": 65482000,
    "2014": 68920000,
    "2015": 69620000,
    "2016": 70405000,
    "2017": 69544000,
    "2018": 68459000,
    "2019": 67881000,
    "2020": 67150000
  },
  "Supplemental Nutrition Assistance Program (SNAP)": {
    "1969": 264000,
    "1970": 1103000,
    "1971": 1698000,
    "1972": 1980000,
    "1973": 2209000,
    "1974": 3433000,
    "1975": 4597000,
    "1976": 4598000,
    "1977": 4394000,
    "1978": 4585000,
    "1979": 6332000,
    "1980": 8214000,
    "1981": 10065000,
    "1982": 9896000,
    "1983": 11066000,
    "1984": 10669000,
    "1985": 10690000,
    "1986": 10553000,
    "1987": 10607000,
    "1988": 11213000,
    "1989": 12325000,
    "1990": 14741000,
    "1991": 18264000,
    "1992": 21295000,
    "1993": 22211000,
    "1994": 22842000,
    "1995": 22447000,
    "1996": 21953000,
    "1997": 18732000,
    "1998": 16465000,
    "1999": 15473000,
    "2000": 14565000,
    "2001": 15967000,
    "2002": 18612000,
    "2003": 22125000,
    "2004": 25946000,
    "2005": 29492000,
    "2006": 29390000,
    "2007": 30921000,
    "2008": 37034000,
    "2009": 54762000,
    "2010": 66515000,
    "2011": 72728000,
    "2012": 74851000,
    "2013": 74657000,
    "2014": 69409000,
    "2015": 68737000,
    "2016": 65502000,
    "2017": 64223000,
    "2018": 58138000,
    "2019": 54686000,
    "2020": 83766000
  },
  "Other income maintenance benefits": {
    "1969": 4384000,
    "1970": 5841000,
    "1971": 7350000,
    "1972": 8053000,
    "1973": 8277000,
    "1974": 9173000,
    "1975": 10970000,
    "1976": 11992000,
    "1977": 12811000,
    "1978": 12961000,
    "1979": 13544000,
    "1980": 16795000,
    "1981": 17616000,
    "1982": 18210000,
    "1983": 19696000,
    "1984": 20521000,
    "1985": 21561000,
    "1986": 22872000,
    "1987": 23162000,
    "1988": 24277000,
    "1989": 25227000,
    "1990": 27717000,
    "1991": 30720000,
    "1992": 32664000,
    "1993": 33996000,
    "1994": 35114000,
    "1995": 34875000,
    "1996": 32509000,
    "1997": 30859000,
    "1998": 31119000,
    "1999": 32584000,
    "2000": 33919000,
    "2001": 34493000,
    "2002": 39554000,
    "2003": 43332000,
    "2004": 48378000,
    "2005": 58915000,
    "2006": 60088000,
    "2007": 63755000,
    "2008": 68300000,
    "2009": 79664000,
    "2010": 80583000,
    "2011": 79975000,
    "2012": 77253000,
    "2013": 76181000,
    "2014": 76848000,
    "2015": 78667000,
    "2016": 78320000,
    "2017": 79547000,
    "2018": 79258000,
    "2019": 91820000,
    "2020": 93735000
  },
  "Unemployment insurance compensation": {
    "1969": 2328000,
    "1970": 4200000,
    "1971": 6190000,
    "1972": 6051000,
    "1973": 4587000,
    "1974": 7038000,
    "1975": 18178000,
    "1976": 16551000,
    "1977": 13291000,
    "1978": 10292000,
    "1979": 10341000,
    "1980": 18662000,
    "1981": 17032000,
    "1982": 25338000,
    "1983": 26437000,
    "1984": 15996000,
    "1985": 15877000,
    "1986": 16572000,
    "1987": 14800000,
    "1988": 13448000,
    "1989": 14476000,
    "1990": 18208000,
    "1991": 26858000,
    "1992": 39628000,
    "1993": 34871000,
    "1994": 24012000,
    "1995": 21833000,
    "1996": 22406000,
    "1997": 20253000,
    "1998": 19856000,
    "1999": 20747000,
    "2000": 20989000,
    "2001": 32148000,
    "2002": 53736000,
    "2003": 53573000,
    "2004": 37084000,
    "2005": 32277000,
    "2006": 30899000,
    "2007": 33382000,
    "2008": 52014000,
    "2009": 131990000,
    "2010": 139715000,
    "2011": 107871000,
    "2012": 84439000,
    "2013": 63049000,
    "2014": 35787000,
    "2015": 32860000,
    "2016": 32267000,
    "2017": 30634000,
    "2018": 28154000,
    "2019": 27952000,
    "2020": 536927000
  },
  "State unemployment insurance compensation": {
    "1969": 2100000,
    "1970": 3811000,
    "1971": 5582000,
    "1972": 5454000,
    "1973": 4188000,
    "1974": 6570000,
    "1975": 17212000,
    "1976": 15255000,
    "1977": 12213000,
    "1978": 8775000,
    "1979": 9100000,
    "1980": 15331000,
    "1981": 15087000,
    "1982": 24493000,
    "1983": 25561000,
    "1984": 15431000,
    "1985": 15323000,
    "1986": 15906000,
    "1987": 14122000,
    "1988": 12865000,
    "1989": 13964000,
    "1990": 17644000,
    "1991": 26086000,
    "1992": 38195000,
    "1993": 33438000,
    "1994": 23020000,
    "1995": 20932000,
    "1996": 21529000,
    "1997": 19437000,
    "1998": 19154000,
    "1999": 20006000,
    "2000": 20223000,
    "2001": 31336000,
    "2002": 52744000,
    "2003": 52356000,
    "2004": 35598000,
    "2005": 31001000,
    "2006": 29593000,
    "2007": 32007000,
    "2008": 50309000,
    "2009": 129700000,
    "2010": 137016000,
    "2011": 105130000,
    "2012": 81854000,
    "2013": 61114000,
    "2014": 34573000,
    "2015": 31826000,
    "2016": 31415000,
    "2017": 29645000,
    "2018": 27296000,
    "2019": 27139000,
    "2020": 535935000
  },
  "Excluding state unemployment insurance compensation": {
    "1969": 228000,
    "1970": 389000,
    "1971": 608000,
    "1972": 597000,
    "1973": 399000,
    "1974": 468000,
    "1975": 966000,
    "1976": 1296000,
    "1977": 1078000,
    "1978": 1517000,
    "1979": 1241000,
    "1980": 3331000,
    "1981": 1945000,
    "1982": 845000,
    "1983": 876000,
    "1984": 565000,
    "1985": 554000,
    "1986": 666000,
    "1987": 678000,
    "1988": 583000,
    "1989": 512000,
    "1990": 564000,
    "1991": 772000,
    "1992": 1433000,
    "1993": 1433000,
    "1994": 992000,
    "1995": 901000,
    "1996": 877000,
    "1997": 816000,
    "1998": 702000,
    "1999": 741000,
    "2000": 766000,
    "2001": 812000,
    "2002": 992000,
    "2003": 1217000,
    "2004": 1486000,
    "2005": 1276000,
    "2006": 1306000,
    "2007": 1375000,
    "2008": 1705000,
    "2009": 2290000,
    "2010": 2699000,
    "2011": 2741000,
    "2012": 2585000,
    "2013": 1935000,
    "2014": 1214000,
    "2015": 1034000,
    "2016": 852000,
    "2017": 989000,
    "2018": 858000,
    "2019": 813000,
    "2020": 992000
  },
  "Veterans' benefits": {
    "1969": 6612000,
    "1970": 7457000,
    "1971": 8404000,
    "1972": 9374000,
    "1973": 10242000,
    "1974": 11581000,
    "1975": 13950000,
    "1976": 13765000,
    "1977": 13349000,
    "1978": 13597000,
    "1979": 14153000,
    "1980": 14660000,
    "1981": 15782000,
    "1982": 16290000,
    "1983": 16445000,
    "1984": 16275000,
    "1985": 16551000,
    "1986": 16589000,
    "1987": 16463000,
    "1988": 16735000,
    "1989": 17214000,
    "1990": 17687000,
    "1991": 18131000,
    "1992": 18620000,
    "1993": 19307000,
    "1994": 19703000,
    "1995": 20543000,
    "1996": 21425000,
    "1997": 22306000,
    "1998": 23266000,
    "1999": 24139000,
    "2000": 25004000,
    "2001": 26598000,
    "2002": 29543000,
    "2003": 31831000,
    "2004": 34052000,
    "2005": 36389000,
    "2006": 38872000,
    "2007": 41672000,
    "2008": 45040000,
    "2009": 51536000,
    "2010": 57962000,
    "2011": 63267000,
    "2012": 70113000,
    "2013": 79058000,
    "2014": 84162000,
    "2015": 92568000,
    "2016": 96773000,
    "2017": 111409000,
    "2018": 119704000,
    "2019": 130899000,
    "2020": 145543000
  },
  "Education and training assistance": {
    "1969": 926000,
    "1970": 1113000,
    "1971": 1351000,
    "1972": 1484000,
    "1973": 1584000,
    "1974": 1885000,
    "1975": 2523000,
    "1976": 3035000,
    "1977": 3706000,
    "1978": 4239000,
    "1979": 4881000,
    "1980": 6404000,
    "1981": 7362000,
    "1982": 7279000,
    "1983": 8003000,
    "1984": 8175000,
    "1985": 8815000,
    "1986": 8809000,
    "1987": 9081000,
    "1988": 9820000,
    "1989": 11902000,
    "1990": 12286000,
    "1991": 12613000,
    "1992": 13692000,
    "1993": 15394000,
    "1994": 15735000,
    "1995": 17241000,
    "1996": 17117000,
    "1997": 18217000,
    "1998": 20470000,
    "1999": 21270000,
    "2000": 21851000,
    "2001": 25396000,
    "2002": 27825000,
    "2003": 28286000,
    "2004": 30813000,
    "2005": 33015000,
    "2006": 34844000,
    "2007": 36788000,
    "2008": 42488000,
    "2009": 53481000,
    "2010": 64511000,
    "2011": 65044000,
    "2012": 63744000,
    "2013": 63798000,
    "2014": 64656000,
    "2015": 63410000,
    "2016": 64087000,
    "2017": 64904000,
    "2018": 68471000,
    "2019": 70564000,
    "2020": 71941000
  },
  "Other transfer receipts of individuals from governments": {
    "1969": 32000,
    "1970": 49000,
    "1971": 73000,
    "1972": 89000,
    "1973": 222000,
    "1974": 176000,
    "1975": 1855000,
    "1976": 204000,
    "1977": 184000,
    "1978": 181000,
    "1979": 293000,
    "1980": 205000,
    "1981": 214000,
    "1982": 653000,
    "1983": 381000,
    "1984": 383000,
    "1985": 553000,
    "1986": 606000,
    "1987": 687000,
    "1988": 784000,
    "1989": 1118000,
    "1990": 1742000,
    "1991": 1727000,
    "1992": 1976000,
    "1993": 1491000,
    "1994": 1476000,
    "1995": 1341000,
    "1996": 1547000,
    "1997": 1569000,
    "1998": 1724000,
    "1999": 1895000,
    "2000": 2323000,
    "2001": 2955000,
    "2002": 2385000,
    "2003": 3012000,
    "2004": 7682000,
    "2005": 4877000,
    "2006": 3051000,
    "2007": 2588000,
    "2008": 100442000,
    "2009": 30639000,
    "2010": 78832000,
    "2011": 70925000,
    "2012": 16458000,
    "2013": 12823000,
    "2014": 31902000,
    "2015": 44068000,
    "2016": 49442000,
    "2017": 56335000,
    "2018": 65922000,
    "2019": 63590000,
    "2020": 374670000
  },
  "Current transfer receipts of nonprofit institutions": {
    "1969": 2363000,
    "1970": 2404000,
    "1971": 2646000,
    "1972": 2898000,
    "1973": 3025000,
    "1974": 3442000,
    "1975": 3953000,
    "1976": 5150000,
    "1977": 5475000,
    "1978": 7283000,
    "1979": 7645000,
    "1980": 8313000,
    "1981": 8349000,
    "1982": 7766000,
    "1983": 8568000,
    "1984": 9651000,
    "1985": 9902000,
    "1986": 11328000,
    "1987": 11244000,
    "1988": 11360000,
    "1989": 11839000,
    "1990": 11730000,
    "1991": 12598000,
    "1992": 14044000,
    "1993": 15774000,
    "1994": 16926000,
    "1995": 17562000,
    "1996": 18251000,
    "1997": 18701000,
    "1998": 19519000,
    "1999": 22378000,
    "2000": 23105000,
    "2001": 25706000,
    "2002": 25965000,
    "2003": 27546000,
    "2004": 28888000,
    "2005": 33961000,
    "2006": 32427000,
    "2007": 31787000,
    "2008": 31221000,
    "2009": 33322000,
    "2010": 37349000,
    "2011": 36134000,
    "2012": 38732000,
    "2013": 39393000,
    "2014": 40787000,
    "2015": 41377000,
    "2016": 41685000,
    "2017": 41695000,
    "2018": 51524000,
    "2019": 48758000,
    "2020": 155798000
  },
  "Current transfer receipts of individuals from businesses": {
    "1969": 2276000,
    "1970": 2129000,
    "1971": 1831000,
    "1972": 2103000,
    "1973": 2766000,
    "1974": 3538000,
    "1975": 5642000,
    "1976": 5217000,
    "1977": 3300000,
    "1978": 4379000,
    "1979": 5937000,
    "1980": 6271000,
    "1981": 8661000,
    "1982": 9480000,
    "1983": 10169000,
    "1984": 15670000,
    "1985": 17788000,
    "1986": 17769000,
    "1987": 15248000,
    "1988": 15703000,
    "1989": 18278000,
    "1990": 17441000,
    "1991": 12873000,
    "1992": 10766000,
    "1993": 7749000,
    "1994": 6152000,
    "1995": 11302000,
    "1996": 15056000,
    "1997": 11071000,
    "1998": 17325000,
    "1999": 23242000,
    "2000": 31771000,
    "2001": 35351000,
    "2002": 23863000,
    "2003": 15439000,
    "2004": 5174000,
    "2005": 9232000,
    "2006": 6028000,
    "2007": 16510000,
    "2008": 23317000,
    "2009": 25949000,
    "2010": 27417000,
    "2011": 33773000,
    "2012": 24050000,
    "2013": 21764000,
    "2014": 25170000,
    "2015": 31680000,
    "2016": 41174000,
    "2017": 30083000,
    "2018": 22464000,
    "2019": 32465000,
    "2020": 35041000
  },
  "Refundable tax credits": {
    "1969": "(NA)",
    "1970": "(NA)",
    "1971": "(NA)",
    "1972": "(NA)",
    "1973": "(NA)",
    "1974": "(NA)",
    "1975": "(NA)",
    "1976": 1271000,
    "1977": 1262000,
    "1978": 1132000,
    "1979": 1069000,
    "1980": 1983000,
    "1981": 1933000,
    "1982": 1834000,
    "1983": 1769000,
    "1984": 1694000,
    "1985": 1577000,
    "1986": 2009000,
    "1987": 1936000,
    "1988": 3711000,
    "1989": 5646000,
    "1990": 6313000,
    "1991": 7175000,
    "1992": 10549000,
    "1993": 11649000,
    "1994": 13985000,
    "1995": 19903000,
    "1996": 24336000,
    "1997": 27413000,
    "1998": 29192000,
    "1999": 30741000,
    "2000": 31278000,
    "2001": 31189000,
    "2002": 37266000,
    "2003": 42942000,
    "2004": 46678000,
    "2005": 54052000,
    "2006": 56668000,
    "2007": 60123000,
    "2008": 159908000,
    "2009": 76810000,
    "2010": 144341000,
    "2011": 151420000,
    "2012": 98683000,
    "2013": 97626000,
    "2014": 114996000,
    "2015": 125884000,
    "2016": 130814000,
    "2017": 136912000,
    "2018": 148264000,
    "2019": 157671000,
    "2020": 430579000
  }
};

/*const benefits = {
  Australia: [
    {
      Subject: 25,
      date: "1978-Q2",
      Value: 61.32985
    },
    {
      Subject: 25,
      date: "1978-Q3",
      Value: 60.32619
    }
  ]
};*/

class Government extends React.Component {
  constructor(props) {
    super(props);

    let benes = [];
    let noData = [];
    let date = [];
    let socialbenefits = [];
    //let exportss = [];
    var bene = {};
    const laborExchange = true;
    /*
     {
      Austria: [
          {
            date: "1980-01-01",
            Value: 138.5427221,
            exports: 147.112772
          }
        ]
      };
    */
    Object.keys(benefits)
      .sort((a, b) => {
        //console.log(benefits[a]);
        return benefits[b]["2020"] - benefits[a]["2020"];
      })
      .forEach((type, i) => {
        benes.push(type);
        if (!bene[type])
          bene[type] = {
            socialbenefitsData: [],
            socialbenefits: []
          };
        Object.keys(benefits[type]).forEach((d) => {
          const year = Number(d);
          if (benefits[type][d].constructor !== Number) return null;
          //const fullyear = new Date(year).getFullYear();if (fullyear > 1979) {
          var coefficience = 1;

          date.push(year);
          bene[type].year = year; //fullyear
          //console.log(benefits[type][d]);
          socialbenefits.push(benefits[type][d] * coefficience);
          bene[type].socialbenefits.push(benefits[type][d] * coefficience);
          bene[type].socialbenefitsData.push([
            year,
            benefits[type][d] * coefficience
          ]);
        });
      });
    /*const arr = [
      ...Object.keys(bene).map((name, i) => {
        return { name, ...bene[name] };
      })
    ].sort((a, b) =>
      b.socialbenefits[b.socialbenefits.length - 1] > a.socialbenefits[a.socialbenefits.length - 1]
        ? //||b.year > a.year
          1
        : -1
    );*/
    //console.log(arr);
    /*arr.forEach((x) => {
      benefits.push(x.name);
    });*/
    const all = [...socialbenefits];
    var lowBenefits = Math.min(...all);
    var lowDate = Math.min(...date);
    var highBenefits = Math.max(...all);
    var highDate = Math.max(...date);
    const type = benes[0];
    //console.log(highBenefits);
    var state = {
      type,
      lastcountry: type,
      laborExchange,
      ...bene,
      date,
      benes,
      highBenefits,
      noData,
      yAxis: highBenefits - lowBenefits,
      xAxis: highDate - lowDate,
      lowBenefits,
      highDate,
      lowDate,
      exportToINData: []
    };
    this.state = state;
  }
  componentDidUpdate = () => {
    if (
      this.state.openIMB !== this.state.lastopenIMB ||
      this.state.laborExchange !== this.state.laststate ||
      this.state.type !== this.state.lastcountry
    ) {
      this.setState(
        {
          lastopenIMB: this.state.openIMB,
          laststate: this.state.laborExchange,
          lastcountry: this.state.type
        },
        () => {
          let benes = [];
          let noData = [];
          let date = [];
          let socialbenefits = [];
          //let exportss = [];
          var bene = {};
          /*
           {
            Austria: [
                {
                  date: "1980-01-01",
                  Value: 138.5427221,
                  exports: 147.112772
                }
              ]
            };
          */
          Object.keys(benefits)
            .sort((a, b) => {
              //console.log(benefits[a]);
              return benefits[b]["2020"] - benefits[a]["2020"];
            })
            .forEach((type, i) => {
              benes.push(type);
              if (!bene[type])
                bene[type] = {
                  socialbenefitsData: [],
                  socialbenefits: []
                };
              Object.keys(benefits[type]).forEach((d) => {
                const year = Number(d);
                if (
                  this.state.openIMB &&
                  ![
                    "Supplemental Security Income (SSI) benefits",
                    "Earned Income Tax Credit (EITC)",
                    "Supplemental Nutrition Assistance Program (SNAP)",
                    "Other income maintenance benefits"
                  ].includes(type)
                )
                  return null;
                if (benefits[type][d].constructor !== Number) return null;
                //const fullyear = new Date(year).getFullYear();if (fullyear > 1979) {
                var coefficience = 1;

                date.push(year);
                bene[type].year = year; //fullyear

                socialbenefits.push(benefits[type][d] * coefficience);
                bene[type].socialbenefits.push(
                  benefits[type][d] * coefficience
                );
                bene[type].socialbenefitsData.push([
                  year,
                  benefits[type][d] * coefficience
                ]);
              });
            });
          const all = [...socialbenefits]; //...exportToIN
          var lowBenefits = Math.min(...all);
          var lowDate = Math.min(...date);
          var highBenefits = Math.max(...all);
          var highDate = Math.max(...date);
          //console.log(dataData);
          //console.log(exportToINData);
          var state = {
            benes,
            //exportToINData,
            ...bene,
            date,
            highBenefits,
            noData,
            yAxis: highBenefits - lowBenefits,
            xAxis: highDate - lowDate,
            lowBenefits,
            highDate,
            lowDate
          };
          this.setState(state);
        }
      );
    }
  };
  render() {
    /*.filter(
      (name) =>
        this.state.laborExchange === "US" ||
        (!this.state.laborExchange &&
          !["France", "Germany", "United Kingdom", "United States"].includes(
            name
          )) ||
        (this.state.laborExchange && !["United States"].includes(name))
    );
    const labelstyle = {
      backgroundColor: "rgba(50,120,200,.6)",
      top: "0px",
      height: "min-content",
      display: "flex",
      maxWidth: "100%",
      left: "2px",
      flexWrap: "wrap"
    };
    const buttonStyle = {
      userSelect: "none",
      border: "1px solid black",
      color: "black",
      backgroundColor: "rgb(220,220,220)",
      borderRadius: "4px",
      padding: "5px",
      margin: "2px"
    };*/

    const linecss = {
      left: "0px",
      bottom: "0px",
      display: "flex",
      position: "absolute",
      width: "100%",
      height: "200px",
      transform: "translate(0%,0%) scale(1,-1)"
    };
    const shortNumber = (scler, notRound) => {
      var newnum = String(Math.round(scler));
      if (notRound) newnum = String(scler);
      var app = null;
      var decimal = null;
      const suff = ["", "k", "m", "b", "t"];
      for (let i = 0; i < suff.length; i++) {
        if (newnum.length > 3) {
          decimal = newnum[newnum.length - 3];
          newnum = newnum.substring(0, newnum.length - 3);
        } else {
          app = i;
          break;
        }
      }
      return newnum + (decimal ? "." + decimal : "") + suff[app];
    };
    //console.log(this.props.lastWidth);
    const noData = this.state.noData.map(([x, y]) => [
      (this.state.lowDate / this.state.xAxis) * 0.9 * this.props.lastWidth,
      0
    ]);

    var imps = {};
    this.state.benes.forEach((name) => {
      if (!this.state[name]) return console.log(name);
      imps[name] = this.state[name].socialbenefitsData.map(([x, y]) => [
        ((x - this.state.lowDate) / this.state.xAxis) *
          0.9 *
          this.props.lastWidth,
        ((y - this.state.lowBenefits) / this.state.yAxis) * 150
      ]);
    });

    /*const labelitem = {
      fontSize: "10px",
      color: "white",
      width: "max-content",
      margin: "0px 4px"
    };

    const StringDecimal = (y, suffindex = 3) => {
      var string;
      if (y) {
        const x = String(y);
        if (x.includes(".")) {
          const here = x.indexOf(".");
          string = x.substring(0, here) + x.substring(here, here + suffindex);
        } else string = x;
      }
      return string;
    };*/
    //console.log(this.props.lastWidth)
    return (
      <div
        style={{
          width: "100%",
          minHeight: "200px",
          position: "relative",
          backgroundColor: "black"
        }}
      >
        <div
          style={{
            wordWrap: "break-word",
            fontSize:
              this.props.lastWidth < 700
                ? "14px"
                : this.props.lastWidth < 300
                ? "12px"
                : "",
            width: "100%",
            color: "white",
            bottom: "0px",
            zIndex: "1",
            backgroundColor: "rgba(255,255,255,.3)",
            padding: "4px 8px",
            position: "absolute",
            right: "0px"
          }}
        >
          social benefits{" "}
          <a style={{ color: "grey" }} href={"https://vaults.biz"}>
            {this.state.lowDate}
            {" - "}
            {this.state.highDate}
          </a>
        </div>
        <div
          style={{
            width: "100%",
            wordBreak: "break-word",
            zIndex: "1",
            textAlign: "left",
            position: "absolute"
          }}
        >
          <div
            style={{
              position: "relative",
              fontSize: "12px",
              color: "white",
              padding: "4px",
              maxWidth: "100%",
              width: "calc(100% - 80px)"
            }}
          >
            <select
              style={{
                width: "100%"
              }}
              value={this.state.type}
              onChange={(name) => {
                this.setState({ type: name.target.value });
              }}
            >
              {this.state.benes.map((name) => (
                <option key={name}>{name}</option>
              ))}
            </select>
            <div
              style={{
                backgroundColor: "rgba(250,250,250,.6)",
                display: "block",
                alignItems: "center"
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {/*<input
                type="checkbox"
                checked={this.state.laborExchange === "US"}
                onChange={(x) =>
                  this.setState({
                    laborExchange: this.state.laborExchange !== "US" ? "US" : true
                  })
                }
              />{" "}
              U.S.{" "}
              <input
                type="checkbox"
                checked={this.state.laborExchange}
                onChange={(x) =>
                  this.setState({
                    laborExchange: this.state.laborExchange ? false : true
                  })
                }
              />{" "}
              U.K. GE FR <br />*/}
                <div
                  style={{
                    border: "1px solid",
                    padding: "4px 10px",
                    userSelect: "none"
                  }}
                  onClick={() => {
                    const type = this.state.benes.find(
                      (x, i) =>
                        i === this.state.benes.indexOf(this.state.type) - 1
                    );

                    this.setState({
                      type: type
                        ? type
                        : this.state.benes[this.state.benes.length - 1]
                    });
                  }}
                >
                  {"<"}
                </div>
                {this.state.chosenDecade}
                <div
                  style={{
                    border: "1px solid",
                    padding: "4px 10px",
                    userSelect: "none"
                  }}
                  onClick={() => {
                    const type = this.state.benes.find(
                      (x, i) =>
                        i === this.state.benes.indexOf(this.state.type) + 1
                    );

                    this.setState({
                      type: type ? type : this.state.benes[0]
                    });
                  }}
                >
                  {">"}
                </div>{" "}
                <span>
                  {/*<a href="https://qr.ae/pG0Uat" style={{ color: "white" }}>
                  (saver-consumer)/labor
                </a>
              <br />
                <input
                  type="checkbox"
                  checked={this.state.laborExchange}
                  onChange={(x) =>
                    this.setState({
                      laborExchange: !this.state.laborExchange
                    })
                  }
                />
                import*(
                {this.state.laborExchange
                  ? `($GDP/p)/(GDP/p)`
                  : `USD/${
                      this.state.type && this.state.type.substring(0, 4)
                    }`}
                )*/}
                </span>
                <a href="https://apps.bea.gov/itable/?ReqID=70&step=1#eyJhcHBpZCI6NzAsInN0ZXBzIjpbMSwyNCwyOSwyNSwzMSwyNiwyNywzMF0sImRhdGEiOltbIlRhYmxlSWQiLCIxMyJdLFsiQ2xhc3NpZmljYXRpb24iLCJOb24tSW5kdXN0cnkiXSxbIk1ham9yX0FyZWEiLCI0Il0sWyJTdGF0ZSIsWyIwIl1dLFsiQXJlYSIsWyIwMDAwMCJdXSxbIlN0YXRpc3RpYyIsWyItMSIsIjIxMjAiLCIyMjEwIiwiMjIyMCIsIjIyMzAiLCIyMzEwIiwiMjMyMCIsIjIzMzAiLCIyMzQwIiwiMjQwMCIsIjI1MDAiXV0sWyJVbml0X29mX21lYXN1cmUiLCJMZXZlbHMiXSxbIlllYXIiLFsiLTEiXV0sWyJZZWFyQmVnaW4iLCItMSJdLFsiWWVhcl9FbmQiLCItMSJdXX0=">
                  ${shortNumber(this.state.lowBenefits * 1000)}-
                  {shortNumber(this.state.highBenefits * 1000)}
                </a>{" "}
                {[
                  "Income management benefits",
                  "Supplemental Security Income (SSI) benefits",
                  "Earned Income Tax Credit (EITC)",
                  "Supplemental Nutrition Assistance Program (SNAP)",
                  "Other income maintenance benefits"
                ].includes(this.state.type) && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ openIMB: !this.state.openIMB });
                    }}
                  >
                    IMB
                  </button>
                )}
              </div>
            </div>
            made benefits from use
          </div>
        </div>
        <div style={{ transform: "translate(0px,170px)" }}>
          <svg style={linecss} xmlns="http://www.w3.org/2000/svg">
            {noData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={4}
                    height={4}
                    stroke="rgb(230,230,230)"
                    fill="transparent"
                    strokeWidth={0}
                    key={i}
                  />
                )
            )}
            {this.state.benes
              .sort((a, b) => b - a)
              .map((name, i) => {
                return imps[name].map(([x, y], i) => {
                  return (
                    !isNaN(x) &&
                    !isNaN(y) && (
                      <rect
                        style={{
                          opacity: this.state.type === name ? 1 : 0.7
                        }}
                        x={x}
                        y={y}
                        width={this.state.type === name ? 4 : 1}
                        height={1}
                        stroke={
                          this.state.type === name
                            ? "royalblue"
                            : "lightsteelblue"
                        }
                        fill="blue"
                        strokeWidth={1}
                        key={i}
                      />
                    )
                  );
                });
              })}
          </svg>
        </div>
      </div>
    );
  }
}

export default Government;
