import React from "react";
export const currency = [
  ["1947-01-01", 26700],
  ["1947-02-01", 26700],
  ["1947-03-01", 26700],
  ["1947-04-01", 26600],
  ["1947-05-01", 26600],
  ["1947-06-01", 26600],
  ["1947-07-01", 26500],
  ["1947-08-01", 26500],
  ["1947-09-01", 26700],
  ["1947-10-01", 26500],
  ["1947-11-01", 26500],
  ["1947-12-01", 26400],
  ["1948-01-01", 26400],
  ["1948-02-01", 26300],
  ["1948-03-01", 26200],
  ["1948-04-01", 26100],
  ["1948-05-01", 26000],
  ["1948-06-01", 26000],
  ["1948-07-01", 26000],
  ["1948-08-01", 26000],
  ["1948-09-01", 26000],
  ["1948-10-01", 26000],
  ["1948-11-01", 26000],
  ["1948-12-01", 25800],
  ["1949-01-01", 25700],
  ["1949-02-01", 25700],
  ["1949-03-01", 25700],
  ["1949-04-01", 25700],
  ["1949-05-01", 25700],
  ["1949-06-01", 25600],
  ["1949-07-01", 25500],
  ["1949-08-01", 25500],
  ["1949-09-01", 25300],
  ["1949-10-01", 25300],
  ["1949-11-01", 25200],
  ["1949-12-01", 25100],
  ["1950-01-01", 25100],
  ["1950-02-01", 25100],
  ["1950-03-01", 25200],
  ["1950-04-01", 25300],
  ["1950-05-01", 25200],
  ["1950-06-01", 25100],
  ["1950-07-01", 25000],
  ["1950-08-01", 24900],
  ["1950-09-01", 24900],
  ["1950-10-01", 24900],
  ["1950-11-01", 24900],
  ["1950-12-01", 25000],
  ["1951-01-01", 25000],
  ["1951-02-01", 25100],
  ["1951-03-01", 25200],
  ["1951-04-01", 25200],
  ["1951-05-01", 25300],
  ["1951-06-01", 25400],
  ["1951-07-01", 25600],
  ["1951-08-01", 25700],
  ["1951-09-01", 25800],
  ["1951-10-01", 26000],
  ["1951-11-01", 26000],
  ["1951-12-01", 26100],
  ["1952-01-01", 26200],
  ["1952-02-01", 26300],
  ["1952-03-01", 26400],
  ["1952-04-01", 26400],
  ["1952-05-01", 26500],
  ["1952-06-01", 26700],
  ["1952-07-01", 26700],
  ["1952-08-01", 26800],
  ["1952-09-01", 26900],
  ["1952-10-01", 27000],
  ["1952-11-01", 27200],
  ["1952-12-01", 27300],
  ["1953-01-01", 27400],
  ["1953-02-01", 27500],
  ["1953-03-01", 27600],
  ["1953-04-01", 27700],
  ["1953-05-01", 27700],
  ["1953-06-01", 27700],
  ["1953-07-01", 27800],
  ["1953-08-01", 27800],
  ["1953-09-01", 27800],
  ["1953-10-01", 27800],
  ["1953-11-01", 27800],
  ["1953-12-01", 27700],
  ["1954-01-01", 27700],
  ["1954-02-01", 27700],
  ["1954-03-01", 27600],
  ["1954-04-01", 27600],
  ["1954-05-01", 27600],
  ["1954-06-01", 27500],
  ["1954-07-01", 27500],
  ["1954-08-01", 27500],
  ["1954-09-01", 27400],
  ["1954-10-01", 27400],
  ["1954-11-01", 27400],
  ["1954-12-01", 27400],
  ["1955-01-01", 27400],
  ["1955-02-01", 27500],
  ["1955-03-01", 27500],
  ["1955-04-01", 27500],
  ["1955-05-01", 27600],
  ["1955-06-01", 27600],
  ["1955-07-01", 27700],
  ["1955-08-01", 27700],
  ["1955-09-01", 27700],
  ["1955-10-01", 27800],
  ["1955-11-01", 27800],
  ["1955-12-01", 27800],
  ["1956-01-01", 27900],
  ["1956-02-01", 27900],
  ["1956-03-01", 27900],
  ["1956-04-01", 27900],
  ["1956-05-01", 27900],
  ["1956-06-01", 27900],
  ["1956-07-01", 28000],
  ["1956-08-01", 28000],
  ["1956-09-01", 28000],
  ["1956-10-01", 28000],
  ["1956-11-01", 28100],
  ["1956-12-01", 28200],
  ["1957-01-01", 28200],
  ["1957-02-01", 28200],
  ["1957-03-01", 28200],
  ["1957-04-01", 28200],
  ["1957-05-01", 28200],
  ["1957-06-01", 28300],
  ["1957-07-01", 28300],
  ["1957-08-01", 28300],
  ["1957-09-01", 28300],
  ["1957-10-01", 28300],
  ["1957-11-01", 28300],
  ["1957-12-01", 28300],
  ["1958-01-01", 28300],
  ["1958-02-01", 28200],
  ["1958-03-01", 28200],
  ["1958-04-01", 28200],
  ["1958-05-01", 28300],
  ["1958-06-01", 28300],
  ["1958-07-01", 28400],
  ["1958-08-01", 28400],
  ["1958-09-01", 28500],
  ["1958-10-01", 28500],
  ["1958-11-01", 28500],
  ["1958-12-01", 28600],
  ["1959-01-01", 28500],
  ["1959-02-01", 28600],
  ["1959-03-01", 28600],
  ["1959-04-01", 28600],
  ["1959-05-01", 28700],
  ["1959-06-01", 28900],
  ["1959-07-01", 28800],
  ["1959-08-01", 28900],
  ["1959-09-01", 28900],
  ["1959-10-01", 28800],
  ["1959-11-01", 28800],
  ["1959-12-01", 28800],
  ["1960-01-01", 28800],
  ["1960-02-01", 28800],
  ["1960-03-01", 28800],
  ["1960-04-01", 28800],
  ["1960-05-01", 28800],
  ["1960-06-01", 28800],
  ["1960-07-01", 28700],
  ["1960-08-01", 28700],
  ["1960-09-01", 28800],
  ["1960-10-01", 28800],
  ["1960-11-01", 28800],
  ["1960-12-01", 28700],
  ["1961-01-01", 28700],
  ["1961-02-01", 28700],
  ["1961-03-01", 28700],
  ["1961-04-01", 28700],
  ["1961-05-01", 28700],
  ["1961-06-01", 28700],
  ["1961-07-01", 28800],
  ["1961-08-01", 28900],
  ["1961-09-01", 29000],
  ["1961-10-01", 29100],
  ["1961-11-01", 29200],
  ["1961-12-01", 29300],
  ["1962-01-01", 29400],
  ["1962-02-01", 29500],
  ["1962-03-01", 29600],
  ["1962-04-01", 29800],
  ["1962-05-01", 29800],
  ["1962-06-01", 29800],
  ["1962-07-01", 29900],
  ["1962-08-01", 30000],
  ["1962-09-01", 30000],
  ["1962-10-01", 30100],
  ["1962-11-01", 30200],
  ["1962-12-01", 30300],
  ["1963-01-01", 30400],
  ["1963-02-01", 30600],
  ["1963-03-01", 30700],
  ["1963-04-01", 31000],
  ["1963-05-01", 31000],
  ["1963-06-01", 31200],
  ["1963-07-01", 31400],
  ["1963-08-01", 31500],
  ["1963-09-01", 31700],
  ["1963-10-01", 31800],
  ["1963-11-01", 32000],
  ["1963-12-01", 32200],
  ["1964-01-01", 32300],
  ["1964-02-01", 32400],
  ["1964-03-01", 32600],
  ["1964-04-01", 32700],
  ["1964-05-01", 32900],
  ["1964-06-01", 33200],
  ["1964-07-01", 33200],
  ["1964-08-01", 33400],
  ["1964-09-01", 33600],
  ["1964-10-01", 33600],
  ["1964-11-01", 33800],
  ["1964-12-01", 33900],
  ["1965-01-01", 34000],
  ["1965-02-01", 34200],
  ["1965-03-01", 34300],
  ["1965-04-01", 34400],
  ["1965-05-01", 34500],
  ["1965-06-01", 34700],
  ["1965-07-01", 34900],
  ["1965-08-01", 35100],
  ["1965-09-01", 35300],
  ["1965-10-01", 35600],
  ["1965-11-01", 35800],
  ["1965-12-01", 36000],
  ["1966-01-01", 36200],
  ["1966-02-01", 36400],
  ["1966-03-01", 36500],
  ["1966-04-01", 36700],
  ["1966-05-01", 37000],
  ["1966-06-01", 37000],
  ["1966-07-01", 37200],
  ["1966-08-01", 37400],
  ["1966-09-01", 37500],
  ["1966-10-01", 37700],
  ["1966-11-01", 37800],
  ["1966-12-01", 38000],
  ["1967-01-01", 38200],
  ["1967-02-01", 38400],
  ["1967-03-01", 38500],
  ["1967-04-01", 38600],
  ["1967-05-01", 38800],
  ["1967-06-01", 38900],
  ["1967-07-01", 39000],
  ["1967-08-01", 39200],
  ["1967-09-01", 39400],
  ["1967-10-01", 39600],
  ["1967-11-01", 39700],
  ["1967-12-01", 40000],
  ["1968-01-01", 40500],
  ["1968-02-01", 40300],
  ["1968-03-01", 40500],
  ["1968-04-01", 40900],
  ["1968-05-01", 41000],
  ["1968-06-01", 41400],
  ["1968-07-01", 41600],
  ["1968-08-01", 41800],
  ["1968-09-01", 42200],
  ["1968-10-01", 42400],
  ["1968-11-01", 42700],
  ["1968-12-01", 43000],
  ["1969-01-01", 43100],
  ["1969-02-01", 43400],
  ["1969-03-01", 43600],
  ["1969-04-01", 43800],
  ["1969-05-01", 43900],
  ["1969-06-01", 44200],
  ["1969-07-01", 44500],
  ["1969-08-01", 44700],
  ["1969-09-01", 44900],
  ["1969-10-01", 45200],
  ["1969-11-01", 45500],
  ["1969-12-01", 45700],
  ["1970-01-01", 46000],
  ["1970-02-01", 46100],
  ["1970-03-01", 46300],
  ["1970-04-01", 46500],
  ["1970-05-01", 46900],
  ["1970-06-01", 47200],
  ["1970-07-01", 47400],
  ["1970-08-01", 47600],
  ["1970-09-01", 47900],
  ["1970-10-01", 48100],
  ["1970-11-01", 48300],
  ["1970-12-01", 48600],
  ["1971-01-01", 48900],
  ["1971-02-01", 49200],
  ["1971-03-01", 49400],
  ["1971-04-01", 49700],
  ["1971-05-01", 50000],
  ["1971-06-01", 50400],
  ["1971-07-01", 50900],
  ["1971-08-01", 51200],
  ["1971-09-01", 51400],
  ["1971-10-01", 51600],
  ["1971-11-01", 51800],
  ["1971-12-01", 52000],
  ["1972-01-01", 52300],
  ["1972-02-01", 52600],
  ["1972-03-01", 53000],
  ["1972-04-01", 53100],
  ["1972-05-01", 53400],
  ["1972-06-01", 53700],
  ["1972-07-01", 54000],
  ["1972-08-01", 54400],
  ["1972-09-01", 54800],
  ["1972-10-01", 55300],
  ["1972-11-01", 55700],
  ["1972-12-01", 56200],
  ["1973-01-01", 56600],
  ["1973-02-01", 56800],
  ["1973-03-01", 57200],
  ["1973-04-01", 57800],
  ["1973-05-01", 58100],
  ["1973-06-01", 58500],
  ["1973-07-01", 58800],
  ["1973-08-01", 59100],
  ["1973-09-01", 59600],
  ["1973-10-01", 59900],
  ["1973-11-01", 60300],
  ["1973-12-01", 60800],
  ["1974-01-01", 61300],
  ["1974-02-01", 61800],
  ["1974-03-01", 62300],
  ["1974-04-01", 63000],
  ["1974-05-01", 63300],
  ["1974-06-01", 63700],
  ["1974-07-01", 64100],
  ["1974-08-01", 64700],
  ["1974-09-01", 65200],
  ["1974-10-01", 65800],
  ["1974-11-01", 66500],
  ["1974-12-01", 67000],
  ["1975-01-01", 67400],
  ["1975-02-01", 67800],
  ["1975-03-01", 68400],
  ["1975-04-01", 68500],
  ["1975-05-01", 69100],
  ["1975-06-01", 70000],
  ["1975-07-01", 70500],
  ["1975-08-01", 71000],
  ["1975-09-01", 71200],
  ["1975-10-01", 71700],
  ["1975-11-01", 72300],
  ["1975-12-01", 72800],
  ["1976-01-01", 73200],
  ["1976-02-01", 73900],
  ["1976-03-01", 74700],
  ["1976-04-01", 75500],
  ["1976-05-01", 76100],
  ["1976-06-01", 76600],
  ["1976-07-01", 77100],
  ["1976-08-01", 77600],
  ["1976-09-01", 78100],
  ["1976-10-01", 78600],
  ["1976-11-01", 79100],
  ["1976-12-01", 79500],
  ["1977-01-01", 80200],
  ["1977-02-01", 80800],
  ["1977-03-01", 81300],
  ["1977-04-01", 82100],
  ["1977-05-01", 82500],
  ["1977-06-01", 83100],
  ["1977-07-01", 83900],
  ["1977-08-01", 84500],
  ["1977-09-01", 85200],
  ["1977-10-01", 85900],
  ["1977-11-01", 86600],
  ["1977-12-01", 87400],
  ["1978-01-01", 88000],
  ["1978-02-01", 88700],
  ["1978-03-01", 89400],
  ["1978-04-01", 90000],
  ["1978-05-01", 90800],
  ["1978-06-01", 91500],
  ["1978-07-01", 92000],
  ["1978-08-01", 92700],
  ["1978-09-01", 93600],
  ["1978-10-01", 94400],
  ["1978-11-01", 95200],
  ["1978-12-01", 96000],
  ["1979-01-01", 96800],
  ["1979-02-01", 97400],
  ["1979-03-01", 98000],
  ["1979-04-01", 98800],
  ["1979-05-01", 99500],
  ["1979-06-01", 100300],
  ["1979-07-01", 101200],
  ["1979-08-01", 102100],
  ["1979-09-01", 103100],
  ["1979-10-01", 103900],
  ["1979-11-01", 104300],
  ["1979-12-01", 104800],
  ["1980-01-01", 106000],
  ["1980-02-01", 106700],
  ["1980-03-01", 107500],
  ["1980-04-01", 107700],
  ["1980-05-01", 108700],
  ["1980-06-01", 109600],
  ["1980-07-01", 110500],
  ["1980-08-01", 111700],
  ["1980-09-01", 112400],
  ["1980-10-01", 113400],
  ["1980-11-01", 114900],
  ["1980-12-01", 115300],
  ["1981-01-01", 115300],
  ["1981-02-01", 116200],
  ["1981-03-01", 117000],
  ["1981-04-01", 117900],
  ["1981-05-01", 118300],
  ["1981-06-01", 118700],
  ["1981-07-01", 119500],
  ["1981-08-01", 119900],
  ["1981-09-01", 120100],
  ["1981-10-01", 120500],
  ["1981-11-01", 121400],
  ["1981-12-01", 122500],
  ["1982-01-01", 123200],
  ["1982-02-01", 123900],
  ["1982-03-01", 124400],
  ["1982-04-01", 125500],
  ["1982-05-01", 126700],
  ["1982-06-01", 127400],
  ["1982-07-01", 128100],
  ["1982-08-01", 129000],
  ["1982-09-01", 129900],
  ["1982-10-01", 130900],
  ["1982-11-01", 131600],
  ["1982-12-01", 132500],
  ["1983-01-01", 133600],
  ["1983-02-01", 135000],
  ["1983-03-01", 136400],
  ["1983-04-01", 137400],
  ["1983-05-01", 138700],
  ["1983-06-01", 139600],
  ["1983-07-01", 140600],
  ["1983-08-01", 141400],
  ["1983-09-01", 142700],
  ["1983-10-01", 144100],
  ["1983-11-01", 145300],
  ["1983-12-01", 146200],
  ["1984-01-01", 147400],
  ["1984-02-01", 148000],
  ["1984-03-01", 149100],
  ["1984-04-01", 150100],
  ["1984-05-01", 150700],
  ["1984-06-01", 151900],
  ["1984-07-01", 152700],
  ["1984-08-01", 153500],
  ["1984-09-01", 154400],
  ["1984-10-01", 154700],
  ["1984-11-01", 155500],
  ["1984-12-01", 156100],
  ["1985-01-01", 156800],
  ["1985-02-01", 157900],
  ["1985-03-01", 158500],
  ["1985-04-01", 159300],
  ["1985-05-01", 160400],
  ["1985-06-01", 161700],
  ["1985-07-01", 162800],
  ["1985-08-01", 164200],
  ["1985-09-01", 165100],
  ["1985-10-01", 166100],
  ["1985-11-01", 167000],
  ["1985-12-01", 167700],
  ["1986-01-01", 168400],
  ["1986-02-01", 169400],
  ["1986-03-01", 170700],
  ["1986-04-01", 171400],
  ["1986-05-01", 172700],
  ["1986-06-01", 173600],
  ["1986-07-01", 174700],
  ["1986-08-01", 175900],
  ["1986-09-01", 176800],
  ["1986-10-01", 178200],
  ["1986-11-01", 179200],
  ["1986-12-01", 180400],
  ["1987-01-01", 181900],
  ["1987-02-01", 183300],
  ["1987-03-01", 184000],
  ["1987-04-01", 185300],
  ["1987-05-01", 186500],
  ["1987-06-01", 187600],
  ["1987-07-01", 188700],
  ["1987-08-01", 190000],
  ["1987-09-01", 191400],
  ["1987-10-01", 193100],
  ["1987-11-01", 195200],
  ["1987-12-01", 196700],
  ["1988-01-01", 197900],
  ["1988-02-01", 198900],
  ["1988-03-01", 200200],
  ["1988-04-01", 201800],
  ["1988-05-01", 203200],
  ["1988-06-01", 204600],
  ["1988-07-01", 206000],
  ["1988-08-01", 207100],
  ["1988-09-01", 208600],
  ["1988-10-01", 209700],
  ["1988-11-01", 210700],
  ["1988-12-01", 212000],
  ["1989-01-01", 212900],
  ["1989-02-01", 213500],
  ["1989-03-01", 214700],
  ["1989-04-01", 215300],
  ["1989-05-01", 216300],
  ["1989-06-01", 217200],
  ["1989-07-01", 218000],
  ["1989-08-01", 218500],
  ["1989-09-01", 219100],
  ["1989-10-01", 219800],
  ["1989-11-01", 220500],
  ["1989-12-01", 222300],
  ["1990-01-01", 223900],
  ["1990-02-01", 225500],
  ["1990-03-01", 227500],
  ["1990-04-01", 229400],
  ["1990-05-01", 231200],
  ["1990-06-01", 233300],
  ["1990-07-01", 235500],
  ["1990-08-01", 238400],
  ["1990-09-01", 241400],
  ["1990-10-01", 243500],
  ["1990-11-01", 245000],
  ["1990-12-01", 246500],
  ["1991-01-01", 250600],
  ["1991-02-01", 253500],
  ["1991-03-01", 255700],
  ["1991-04-01", 255700],
  ["1991-05-01", 256500],
  ["1991-06-01", 257600],
  ["1991-07-01", 259100],
  ["1991-08-01", 260900],
  ["1991-09-01", 262100],
  ["1991-10-01", 263700],
  ["1991-11-01", 265500],
  ["1991-12-01", 267100],
  ["1992-01-01", 268600],
  ["1992-02-01", 270200],
  ["1992-03-01", 271500],
  ["1992-04-01", 273100],
  ["1992-05-01", 274800],
  ["1992-06-01", 276300],
  ["1992-07-01", 279200],
  ["1992-08-01", 282000],
  ["1992-09-01", 284900],
  ["1992-10-01", 287300],
  ["1992-11-01", 289500],
  ["1992-12-01", 292100],
  ["1993-01-01", 294200],
  ["1993-02-01", 296100],
  ["1993-03-01", 298300],
  ["1993-04-01", 301000],
  ["1993-05-01", 303800],
  ["1993-06-01", 306400],
  ["1993-07-01", 309400],
  ["1993-08-01", 311900],
  ["1993-09-01", 314900],
  ["1993-10-01", 317300],
  ["1993-11-01", 319100],
  ["1993-12-01", 321600],
  ["1994-01-01", 324900],
  ["1994-02-01", 328100],
  ["1994-03-01", 331100],
  ["1994-04-01", 333700],
  ["1994-05-01", 336800],
  ["1994-06-01", 339800],
  ["1994-07-01", 343600],
  ["1994-08-01", 345400],
  ["1994-09-01", 347700],
  ["1994-10-01", 350300],
  ["1994-11-01", 353000],
  ["1994-12-01", 354500],
  ["1995-01-01", 357400],
  ["1995-02-01", 358400],
  ["1995-03-01", 362200],
  ["1995-04-01", 365500],
  ["1995-05-01", 368000],
  ["1995-06-01", 367900],
  ["1995-07-01", 368200],
  ["1995-08-01", 368800],
  ["1995-09-01", 369800],
  ["1995-10-01", 370900],
  ["1995-11-01", 371500],
  ["1995-12-01", 372800],
  ["1996-01-01", 373300],
  ["1996-02-01", 372400],
  ["1996-03-01", 374900],
  ["1996-04-01", 376100],
  ["1996-05-01", 377700],
  ["1996-06-01", 380200],
  ["1996-07-01", 383100],
  ["1996-08-01", 385700],
  ["1996-09-01", 387900],
  ["1996-10-01", 389900],
  ["1996-11-01", 392200],
  ["1996-12-01", 394600],
  ["1997-01-01", 396800],
  ["1997-02-01", 398800],
  ["1997-03-01", 401500],
  ["1997-04-01", 403300],
  ["1997-05-01", 406200],
  ["1997-06-01", 408500],
  ["1997-07-01", 410800],
  ["1997-08-01", 413200],
  ["1997-09-01", 415600],
  ["1997-10-01", 418200],
  ["1997-11-01", 421800],
  ["1997-12-01", 425300],
  ["1998-01-01", 427500],
  ["1998-02-01", 430000],
  ["1998-03-01", 431900],
  ["1998-04-01", 433800],
  ["1998-05-01", 436100],
  ["1998-06-01", 438700],
  ["1998-07-01", 442200],
  ["1998-08-01", 444800],
  ["1998-09-01", 449800],
  ["1998-10-01", 453700],
  ["1998-11-01", 457200],
  ["1998-12-01", 460400],
  ["1999-01-01", 463400],
  ["1999-02-01", 467400],
  ["1999-03-01", 471600],
  ["1999-04-01", 475700],
  ["1999-05-01", 480000],
  ["1999-06-01", 483400],
  ["1999-07-01", 487100],
  ["1999-08-01", 490500],
  ["1999-09-01", 494500],
  ["1999-10-01", 499200],
  ["1999-11-01", 505300],
  ["1999-12-01", 517900],
  ["2000-01-01", 524900],
  ["2000-02-01", 518000],
  ["2000-03-01", 517000],
  ["2000-04-01", 517800],
  ["2000-05-01", 519100],
  ["2000-06-01", 521300],
  ["2000-07-01", 522600],
  ["2000-08-01", 522800],
  ["2000-09-01", 524100],
  ["2000-10-01", 526200],
  ["2000-11-01", 528300],
  ["2000-12-01", 531300],
  ["2001-01-01", 534100],
  ["2001-02-01", 536700],
  ["2001-03-01", 539300],
  ["2001-04-01", 542700],
  ["2001-05-01", 545800],
  ["2001-06-01", 548700],
  ["2001-07-01", 554500],
  ["2001-08-01", 562400],
  ["2001-09-01", 567800],
  ["2001-10-01", 571600],
  ["2001-11-01", 575800],
  ["2001-12-01", 581200],
  ["2002-01-01", 586600],
  ["2002-02-01", 591300],
  ["2002-03-01", 595400],
  ["2002-04-01", 599500],
  ["2002-05-01", 604900],
  ["2002-06-01", 610200],
  ["2002-07-01", 615400],
  ["2002-08-01", 616900],
  ["2002-09-01", 618300],
  ["2002-10-01", 620000],
  ["2002-11-01", 622800],
  ["2002-12-01", 626200],
  ["2003-01-01", 630100],
  ["2003-02-01", 635000],
  ["2003-03-01", 639400],
  ["2003-04-01", 642900],
  ["2003-05-01", 645800],
  ["2003-06-01", 647400],
  ["2003-07-01", 648300],
  ["2003-08-01", 650200],
  ["2003-09-01", 652700],
  ["2003-10-01", 657400],
  ["2003-11-01", 660000],
  ["2003-12-01", 662400],
  ["2004-01-01", 663800],
  ["2004-02-01", 665400],
  ["2004-03-01", 666900],
  ["2004-04-01", 669900],
  ["2004-05-01", 673100],
  ["2004-06-01", 677600],
  ["2004-07-01", 685400],
  ["2004-08-01", 686800],
  ["2004-09-01", 690400],
  ["2004-10-01", 693500],
  ["2004-11-01", 697800],
  ["2004-12-01", 697900],
  ["2005-01-01", 698900],
  ["2005-02-01", 700400],
  ["2005-03-01", 702000],
  ["2005-04-01", 703400],
  ["2005-05-01", 704600],
  ["2005-06-01", 708200],
  ["2005-07-01", 710600],
  ["2005-08-01", 713300],
  ["2005-09-01", 717300],
  ["2005-10-01", 718400],
  ["2005-11-01", 720500],
  ["2005-12-01", 724700],
  ["2006-01-01", 729300],
  ["2006-02-01", 733200],
  ["2006-03-01", 735600],
  ["2006-04-01", 738200],
  ["2006-05-01", 741300],
  ["2006-06-01", 740800],
  ["2006-07-01", 740800],
  ["2006-08-01", 742000],
  ["2006-09-01", 742700],
  ["2006-10-01", 743700],
  ["2006-11-01", 746500],
  ["2006-12-01", 750300],
  ["2007-01-01", 750700],
  ["2007-02-01", 750000],
  ["2007-03-01", 750800],
  ["2007-04-01", 753700],
  ["2007-05-01", 755300],
  ["2007-06-01", 755700],
  ["2007-07-01", 758100],
  ["2007-08-01", 759000],
  ["2007-09-01", 760300],
  ["2007-10-01", 762800],
  ["2007-11-01", 762700],
  ["2007-12-01", 760600],
  ["2008-01-01", 758300],
  ["2008-02-01", 757400],
  ["2008-03-01", 759200],
  ["2008-04-01", 758200],
  ["2008-05-01", 762400],
  ["2008-06-01", 768500],
  ["2008-07-01", 774700],
  ["2008-08-01", 776900],
  ["2008-09-01", 780900],
  ["2008-10-01", 796700],
  ["2008-11-01", 806400],
  ["2008-12-01", 816200],
  ["2009-01-01", 829500],
  ["2009-02-01", 837600],
  ["2009-03-01", 843000],
  ["2009-04-01", 847200],
  ["2009-05-01", 848700],
  ["2009-06-01", 852200],
  ["2009-07-01", 854800],
  ["2009-08-01", 858200],
  ["2009-09-01", 861400],
  ["2009-10-01", 862900],
  ["2009-11-01", 862000],
  ["2009-12-01", 863700],
  ["2010-01-01", 864500],
  ["2010-02-01", 868400],
  ["2010-03-01", 871500],
  ["2010-04-01", 876100],
  ["2010-05-01", 879900],
  ["2010-06-01", 882900],
  ["2010-07-01", 887300],
  ["2010-08-01", 893100],
  ["2010-09-01", 899400],
  ["2010-10-01", 907500],
  ["2010-11-01", 915000],
  ["2010-12-01", 918800],
  ["2011-01-01", 923400],
  ["2011-02-01", 930600],
  ["2011-03-01", 938700],
  ["2011-04-01", 947500],
  ["2011-05-01", 956400],
  ["2011-06-01", 963700],
  ["2011-07-01", 970400],
  ["2011-08-01", 976100],
  ["2011-09-01", 981900],
  ["2011-10-01", 985600],
  ["2011-11-01", 994700],
  ["2011-12-01", 1001900],
  ["2012-01-01", 1009600],
  ["2012-02-01", 1019500],
  ["2012-03-01", 1029400],
  ["2012-04-01", 1034600],
  ["2012-05-01", 1039600],
  ["2012-06-01", 1046300],
  ["2012-07-01", 1052400],
  ["2012-08-01", 1059500],
  ["2012-09-01", 1067700],
  ["2012-10-01", 1075800],
  ["2012-11-01", 1084200],
  ["2012-12-01", 1090600],
  ["2013-01-01", 1096500],
  ["2013-02-01", 1100200],
  ["2013-03-01", 1104800],
  ["2013-04-01", 1109400],
  ["2013-05-01", 1116800],
  ["2013-06-01", 1123500],
  ["2013-07-01", 1130600],
  ["2013-08-01", 1137900],
  ["2013-09-01", 1144700],
  ["2013-10-01", 1150500],
  ["2013-11-01", 1154800],
  ["2013-12-01", 1160300],
  ["2014-01-01", 1165500],
  ["2014-02-01", 1172400],
  ["2014-03-01", 1190100],
  ["2014-04-01", 1198100],
  ["2014-05-01", 1205400],
  ["2014-06-01", 1212200],
  ["2014-07-01", 1218300],
  ["2014-08-01", 1223600],
  ["2014-09-01", 1227100],
  ["2014-10-01", 1231400],
  ["2014-11-01", 1241800],
  ["2014-12-01", 1252400],
  ["2015-01-01", 1266700],
  ["2015-02-01", 1273700],
  ["2015-03-01", 1279500],
  ["2015-04-01", 1285000],
  ["2015-05-01", 1289300],
  ["2015-06-01", 1294100],
  ["2015-07-01", 1301300],
  ["2015-08-01", 1309200],
  ["2015-09-01", 1317200],
  ["2015-10-01", 1325000],
  ["2015-11-01", 1333600],
  ["2015-12-01", 1339200],
  ["2016-01-01", 1345900],
  ["2016-02-01", 1353200],
  ["2016-03-01", 1360400],
  ["2016-04-01", 1366900],
  ["2016-05-01", 1374900],
  ["2016-06-01", 1381800],
  ["2016-07-01", 1388500],
  ["2016-08-01", 1394300],
  ["2016-09-01", 1400400],
  ["2016-10-01", 1406300],
  ["2016-11-01", 1413800],
  ["2016-12-01", 1421300],
  ["2017-01-01", 1430000],
  ["2017-02-01", 1436500],
  ["2017-03-01", 1448800],
  ["2017-04-01", 1459500],
  ["2017-05-01", 1468400],
  ["2017-06-01", 1478000],
  ["2017-07-01", 1485800],
  ["2017-08-01", 1493600],
  ["2017-09-01", 1500900],
  ["2017-10-01", 1509600],
  ["2017-11-01", 1516200],
  ["2017-12-01", 1525100],
  ["2018-01-01", 1534400],
  ["2018-02-01", 1542700],
  ["2018-03-01", 1552400],
  ["2018-04-01", 1561000],
  ["2018-05-01", 1571500],
  ["2018-06-01", 1581700],
  ["2018-07-01", 1590500],
  ["2018-08-01", 1598900],
  ["2018-09-01", 1607300],
  ["2018-10-01", 1612900],
  ["2018-11-01", 1619000],
  ["2018-12-01", 1624200],
  ["2019-01-01", 1629100],
  ["2019-02-01", 1634100],
  ["2019-03-01", 1639000],
  ["2019-04-01", 1645700],
  ["2019-05-01", 1651900],
  ["2019-06-01", 1658500],
  ["2019-07-01", 1666600],
  ["2019-08-01", 1674100],
  ["2019-09-01", 1684200],
  ["2019-10-01", 1692600],
  ["2019-11-01", 1702500],
  ["2019-12-01", 1709700],
  ["2020-01-01", 1718900],
  ["2020-02-01", 1724200],
  ["2020-03-01", 1746100],
  ["2020-04-01", 1781600],
  ["2020-05-01", 1820000],
  ["2020-06-01", 1856600],
  ["2020-07-01", 1885800],
  ["2020-08-01", 1911100],
  ["2020-09-01", 1927900],
  ["2020-10-01", 1944100],
  ["2020-11-01", 1956800],
  ["2020-12-01", 1969300],
  ["2021-01-01", 1992600],
  ["2021-02-01", 2007000],
  ["2021-03-01", 2018000],
  ["2021-04-01", 2050700],
  ["2021-05-01", 2065000],
  ["2021-06-01", 2079500],
  ["2021-07-01", 2089500],
  ["2021-08-01", 2093300],
  ["2021-09-01", 2099200],
  ["2021-10-01", 2105400],
  ["2021-11-01", 2114700],
  ["2021-12-01", 2123900],
  ["2022-01-01", 2138700]
];
const debts = {
  "1945-10-01": {
    alldebt: 363.614,
    domesticlessing: 352.402,
    domesticrelessings: 5.692,
    foreignlessing: 5.52,
    government: 254.385,
    municipalities: 12.635,
    relessings: 0.482,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 44.65,
    selflendings: "",
    heldlendings: 0,
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1946-01-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1946-04-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1946-07-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1946-10-01": {
    alldebt: 357.032,
    domesticlessing: 343.964,
    domesticrelessings: 5.245,
    foreignlessing: 7.823,
    government: 231.306,
    municipalities: 12.683,
    relessings: 1.181,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 49.89,
    selflendings: "",
    heldlendings: 0,
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1947-01-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1947-04-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1947-07-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1947-10-01": {
    alldebt: 373.849,
    domesticlessing: 356.685,
    domesticrelessings: 5.26,
    foreignlessing: 11.904,
    government: 224.898,
    municipalities: 14.313,
    relessings: 1.698,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 56.78,
    selflendings: "",
    heldlendings: 0,
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1948-01-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1948-04-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1948-07-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1948-10-01": {
    alldebt: 389.537,
    domesticlessing: 369.12,
    domesticrelessings: 7.301,
    foreignlessing: 13.116,
    government: 220.012,
    municipalities: 15.717,
    relessings: 2.899,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 62.81,
    selflendings: "",
    heldlendings: 0,
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1949-01-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1949-04-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1949-07-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1949-10-01": {
    alldebt: 406.539,
    domesticlessing: 384.453,
    domesticrelessings: 8.617,
    foreignlessing: 13.469,
    government: 223.629,
    municipalities: 16.618,
    relessings: 3.873,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 64.39,
    selflendings: 18.54,
    heldlendings: 0,
    savingslendings: 0.43,
    brokerlendings: 0,
    foreignborrowings: 0.06
  },
  "1950-01-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1950-04-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1950-07-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1950-10-01": {
    alldebt: 436.322,
    domesticlessing: 411.373,
    domesticrelessings: 11.335,
    foreignlessing: 13.614,
    government: 223.234,
    municipalities: 21.21,
    relessings: 5.307,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 70.44,
    selflendings: 21.65,
    heldlendings: 0,
    savingslendings: 0.81,
    brokerlendings: 0,
    foreignborrowings: 0.08
  },
  "1951-01-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1951-04-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1951-07-01": {
    alldebt: "",
    domesticlessing: "",
    domesticrelessings: "",
    foreignlessing: "",
    government: "",
    municipalities: "",
    relessings: "",
    mortgagelendings: "",
    borrowings: "",
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "1951-10-01": {
    alldebt: 460.709,
    domesticlessing: 434.345,
    domesticrelessings: 12.151,
    foreignlessing: 14.213,
    government: 223.506,
    municipalities: 23.586,
    relessings: 5.966,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 78.87,
    selflendings: 24.94,
    heldlendings: 0,
    savingslendings: 0.91,
    brokerlendings: 0,
    foreignborrowings: 0.1
  },
  "1952-01-01": {
    alldebt: 463.249,
    domesticlessing: 437.281,
    domesticrelessings: 11.523,
    foreignlessing: 14.445,
    government: 222.201,
    municipalities: 25.787,
    relessings: 6.229,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 79.77,
    selflendings: 25.46,
    heldlendings: 0,
    savingslendings: 0.59,
    brokerlendings: 0,
    foreignborrowings: 0.09
  },
  "1952-04-01": {
    alldebt: 473.481,
    domesticlessing: 445.933,
    domesticrelessings: 12.892,
    foreignlessing: 14.656,
    government: 222.032,
    municipalities: 28.324,
    relessings: 6.642,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 80.77,
    selflendings: 26.6,
    heldlendings: 0,
    savingslendings: 0.65,
    brokerlendings: 0,
    foreignborrowings: 0.08
  },
  "1952-07-01": {
    alldebt: 483.583,
    domesticlessing: 456.208,
    domesticrelessings: 12.653,
    foreignlessing: 14.722,
    government: 225.653,
    municipalities: 29.675,
    relessings: 6.873,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 82.53,
    selflendings: 27.16,
    heldlendings: 0,
    savingslendings: 0.72,
    brokerlendings: 0,
    foreignborrowings: 0.09
  },
  "1952-10-01": {
    alldebt: 497.548,
    domesticlessing: 468.756,
    domesticrelessings: 14.027,
    foreignlessing: 14.765,
    government: 229.829,
    municipalities: 30.795,
    relessings: 7.455,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 85.1,
    selflendings: 27.44,
    heldlendings: 0,
    savingslendings: 0.86,
    brokerlendings: 0,
    foreignborrowings: 0.1
  },
  "1953-01-01": {
    alldebt: 499.565,
    domesticlessing: 471.02,
    domesticrelessings: 13.615,
    foreignlessing: 14.93,
    government: 226.911,
    municipalities: 32.134,
    relessings: 7.853,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 86.5,
    selflendings: 27.6,
    heldlendings: 0,
    savingslendings: 0.61,
    brokerlendings: 0,
    foreignborrowings: 0.09
  },
  "1953-04-01": {
    alldebt: 507.781,
    domesticlessing: 478.271,
    domesticrelessings: 14.354,
    foreignlessing: 15.156,
    government: 226.926,
    municipalities: 33.218,
    relessings: 8.401,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 87.35,
    selflendings: 28.45,
    heldlendings: 0,
    savingslendings: 0.72,
    brokerlendings: 0,
    foreignborrowings: 0.09
  },
  "1953-07-01": {
    alldebt: 521.46,
    domesticlessing: 490.377,
    domesticrelessings: 15.486,
    foreignlessing: 15.597,
    government: 233.608,
    municipalities: 34.637,
    relessings: 8.973,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 88.34,
    selflendings: 28.99,
    heldlendings: 0,
    savingslendings: 0.8,
    brokerlendings: 0,
    foreignborrowings: 0.1
  },
  "1953-10-01": {
    alldebt: 530.838,
    domesticlessing: 498.932,
    domesticrelessings: 16.019,
    foreignlessing: 15.887,
    government: 237.379,
    municipalities: 35.786,
    relessings: 8.884,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 89.25,
    selflendings: 28.36,
    heldlendings: 0,
    savingslendings: 0.95,
    brokerlendings: 0,
    foreignborrowings: 0.12
  },
  "1954-01-01": {
    alldebt: 528.667,
    domesticlessing: 497.636,
    domesticrelessings: 15.003,
    foreignlessing: 16.028,
    government: 233.493,
    municipalities: 37.186,
    relessings: 8.837,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 90.17,
    selflendings: 28.8,
    heldlendings: 0,
    savingslendings: 0.63,
    brokerlendings: 0,
    foreignborrowings: 0.12
  },
  "1954-04-01": {
    alldebt: 534.763,
    domesticlessing: 502.9,
    domesticrelessings: 15.982,
    foreignlessing: 15.881,
    government: 232.735,
    municipalities: 38.343,
    relessings: 8.899,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 90.15,
    selflendings: 29.91,
    heldlendings: 0,
    savingslendings: 0.68,
    brokerlendings: 0,
    foreignborrowings: 0.12
  },
  "1954-07-01": {
    alldebt: 543.495,
    domesticlessing: 511.617,
    domesticrelessings: 16.09,
    foreignlessing: 15.788,
    government: 234.566,
    municipalities: 39.829,
    relessings: 8.615,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 91.17,
    selflendings: 30.88,
    heldlendings: 0,
    savingslendings: 0.69,
    brokerlendings: 0,
    foreignborrowings: 0.12
  },
  "1954-10-01": {
    alldebt: 558.13,
    domesticlessing: 525.508,
    domesticrelessings: 16.636,
    foreignlessing: 15.986,
    government: 240.195,
    municipalities: 41.102,
    relessings: 8.295,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 92.84,
    selflendings: 30.91,
    heldlendings: 0,
    savingslendings: 0.87,
    brokerlendings: 0,
    foreignborrowings: 0.18
  },
  "1955-01-01": {
    alldebt: 560.193,
    domesticlessing: 527.63,
    domesticrelessings: 16.687,
    foreignlessing: 15.876,
    government: 236.08,
    municipalities: 42.602,
    relessings: 8.894,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 93.72,
    selflendings: 31.6,
    heldlendings: 0,
    savingslendings: 0.7,
    brokerlendings: 0,
    foreignborrowings: 0.16
  },
  "1955-04-01": {
    alldebt: 571.241,
    domesticlessing: 537.515,
    domesticrelessings: 17.796,
    foreignlessing: 15.93,
    government: 234.433,
    municipalities: 43.705,
    relessings: 9.294,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 95.39,
    selflendings: 33.31,
    heldlendings: 0,
    savingslendings: 1.02,
    brokerlendings: 0,
    foreignborrowings: 0.13
  },
  "1955-07-01": {
    alldebt: 583.323,
    domesticlessing: 548.717,
    domesticrelessings: 18.671,
    foreignlessing: 15.935,
    government: 236.252,
    municipalities: 45.109,
    relessings: 9.774,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 96.87,
    selflendings: 34.49,
    heldlendings: 0,
    savingslendings: 1.28,
    brokerlendings: 0,
    foreignborrowings: 0.14
  },
  "1955-10-01": {
    alldebt: 599.677,
    domesticlessing: 563.842,
    domesticrelessings: 19.865,
    foreignlessing: 15.97,
    government: 240.077,
    municipalities: 46.148,
    relessings: 9.867,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 101.22,
    selflendings: 34.84,
    heldlendings: 0,
    savingslendings: 1.42,
    brokerlendings: 0,
    foreignborrowings: 0.13
  },
  "1956-01-01": {
    alldebt: 601.985,
    domesticlessing: 565.493,
    domesticrelessings: 20.358,
    foreignlessing: 16.134,
    government: 235.799,
    municipalities: 47.39,
    relessings: 11.008,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 103.6,
    selflendings: 35.45,
    heldlendings: 0,
    savingslendings: 1.14,
    brokerlendings: 0,
    foreignborrowings: 0.13
  },
  "1956-04-01": {
    alldebt: 607.252,
    domesticlessing: 569.974,
    domesticrelessings: 20.902,
    foreignlessing: 16.376,
    government: 230.35,
    municipalities: 48.313,
    relessings: 11.275,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 105.98,
    selflendings: 36.91,
    heldlendings: 0,
    savingslendings: 1.17,
    brokerlendings: 0,
    foreignborrowings: 0.14
  },
  "1956-07-01": {
    alldebt: 616.242,
    domesticlessing: 578.465,
    domesticrelessings: 21.315,
    foreignlessing: 16.462,
    government: 231.549,
    municipalities: 49.533,
    relessings: 11.635,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 107.76,
    selflendings: 37.91,
    heldlendings: 0,
    savingslendings: 1.14,
    brokerlendings: 0,
    foreignborrowings: 0.16
  },
  "1956-10-01": {
    alldebt: 629.327,
    domesticlessing: 590.711,
    domesticrelessings: 21.92,
    foreignlessing: 16.696,
    government: 235.039,
    municipalities: 50.381,
    relessings: 11.599,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 110.96,
    selflendings: 37.93,
    heldlendings: 0,
    savingslendings: 1.23,
    brokerlendings: 0,
    foreignborrowings: 0.2
  },
  "1957-01-01": {
    alldebt: 633.237,
    domesticlessing: 594.166,
    domesticrelessings: 22.052,
    foreignlessing: 17.019,
    government: 233.391,
    municipalities: 51.501,
    relessings: 12.361,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 113.54,
    selflendings: 38.25,
    heldlendings: 0,
    savingslendings: 0.96,
    brokerlendings: 0,
    foreignborrowings: 0.21
  },
  "1957-04-01": {
    alldebt: 637.853,
    domesticlessing: 597.908,
    domesticrelessings: 22.761,
    foreignlessing: 17.184,
    government: 227.347,
    municipalities: 52.414,
    relessings: 12.282,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 116.34,
    selflendings: 39.34,
    heldlendings: 0,
    savingslendings: 1.08,
    brokerlendings: 0,
    foreignborrowings: 0.2
  },
  "1957-07-01": {
    alldebt: 649.844,
    domesticlessing: 608.63,
    domesticrelessings: 23.894,
    foreignlessing: 17.32,
    government: 231.343,
    municipalities: 53.836,
    relessings: 12.775,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 117.82,
    selflendings: 40.09,
    heldlendings: 0,
    savingslendings: 1.12,
    brokerlendings: 0,
    foreignborrowings: 0.24
  },
  "1957-10-01": {
    alldebt: 660.779,
    domesticlessing: 617.864,
    domesticrelessings: 24.937,
    foreignlessing: 17.978,
    government: 233.24,
    municipalities: 55.039,
    relessings: 12.963,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 120.63,
    selflendings: 40.38,
    heldlendings: 0,
    savingslendings: 1.27,
    brokerlendings: 0,
    foreignborrowings: 0.26
  },
  "1958-01-01": {
    alldebt: 663.542,
    domesticlessing: 619.802,
    domesticrelessings: 25.324,
    foreignlessing: 18.416,
    government: 232.414,
    municipalities: 56.55,
    relessings: 13.925,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 122.36,
    selflendings: 40.84,
    heldlendings: 0,
    savingslendings: 0.7,
    brokerlendings: 0,
    foreignborrowings: 0.31
  },
  "1958-04-01": {
    alldebt: 674.61,
    domesticlessing: 629.356,
    domesticrelessings: 26.391,
    foreignlessing: 18.863,
    government: 234.972,
    municipalities: 57.73,
    relessings: 13.761,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 123.27,
    selflendings: 42.23,
    heldlendings: 0,
    savingslendings: 0.93,
    brokerlendings: 0,
    foreignborrowings: 0.27
  },
  "1958-07-01": {
    alldebt: 681.056,
    domesticlessing: 637.416,
    domesticrelessings: 24.349,
    foreignlessing: 19.291,
    government: 235.84,
    municipalities: 59.33,
    relessings: 12.911,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 124.28,
    selflendings: 43.31,
    heldlendings: 0,
    savingslendings: 1.01,
    brokerlendings: 0,
    foreignborrowings: 0.26
  },
  "1958-10-01": {
    alldebt: 702.077,
    domesticlessing: 656.559,
    domesticrelessings: 25.67,
    foreignlessing: 19.848,
    government: 243.374,
    municipalities: 60.669,
    relessings: 13.143,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 127.97,
    selflendings: 44.18,
    heldlendings: 0,
    savingslendings: 1.3,
    brokerlendings: 0,
    foreignborrowings: 0.24
  },
  "1959-01-01": {
    alldebt: 708.373,
    domesticlessing: 662.392,
    domesticrelessings: 26.019,
    foreignlessing: 19.962,
    government: 243.058,
    municipalities: 62.272,
    relessings: 14.357,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 128.85,
    selflendings: 45.64,
    heldlendings: 0,
    savingslendings: 1.09,
    brokerlendings: 0,
    foreignborrowings: 0.21
  },
  "1959-04-01": {
    alldebt: 725.402,
    domesticlessing: 676.751,
    domesticrelessings: 28.337,
    foreignlessing: 20.314,
    government: 243.702,
    municipalities: 63.593,
    relessings: 15.417,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 131.93,
    selflendings: 48.03,
    heldlendings: 0,
    savingslendings: 1.54,
    brokerlendings: 0,
    foreignborrowings: 0.2
  },
  "1959-07-01": {
    alldebt: 742.494,
    domesticlessing: 691.697,
    domesticrelessings: 30.172,
    foreignlessing: 20.625,
    government: 247.998,
    municipalities: 65.575,
    relessings: 16.122,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 132.9,
    selflendings: 49.62,
    heldlendings: 0,
    savingslendings: 1.8,
    brokerlendings: 0,
    foreignborrowings: 0.19
  },
  "1959-10-01": {
    alldebt: 760.093,
    domesticlessing: 707.085,
    domesticrelessings: 32.324,
    foreignlessing: 20.684,
    government: 251.18,
    municipalities: 66.696,
    relessings: 16.678,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 136.65,
    selflendings: 50.25,
    heldlendings: 0,
    savingslendings: 2.13,
    brokerlendings: 0,
    foreignborrowings: 0.23
  },
  "1960-01-01": {
    alldebt: 763.258,
    domesticlessing: 709.161,
    domesticrelessings: 33.035,
    foreignlessing: 21.062,
    government: 247.528,
    municipalities: 68.321,
    relessings: 19.06,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 138.74,
    selflendings: 51.42,
    heldlendings: 0,
    savingslendings: 1.52,
    brokerlendings: 0,
    foreignborrowings: 0.28
  },
  "1960-04-01": {
    alldebt: 776.204,
    domesticlessing: 719.969,
    domesticrelessings: 34.754,
    foreignlessing: 21.481,
    government: 245.933,
    municipalities: 69.524,
    relessings: 19.754,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 141.4,
    selflendings: 53.2,
    heldlendings: 0,
    savingslendings: 1.77,
    brokerlendings: 0,
    foreignborrowings: 0.28
  },
  "1960-07-01": {
    alldebt: 786.994,
    domesticlessing: 729.369,
    domesticrelessings: 35.803,
    foreignlessing: 21.822,
    government: 247.439,
    municipalities: 71.174,
    relessings: 19.656,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 141.92,
    selflendings: 54.37,
    heldlendings: 0,
    savingslendings: 1.74,
    brokerlendings: 0,
    foreignborrowings: 0.34
  },
  "1960-10-01": {
    alldebt: 802.889,
    domesticlessing: 742.76,
    domesticrelessings: 37.709,
    foreignlessing: 22.42,
    government: 250.072,
    municipalities: 72.211,
    relessings: 20.201,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 145.59,
    selflendings: 55.02,
    heldlendings: 0,
    savingslendings: 1.98,
    brokerlendings: 0,
    foreignborrowings: 0.41
  },
  "1961-01-01": {
    alldebt: 803.669,
    domesticlessing: 744.867,
    domesticrelessings: 35.786,
    foreignlessing: 23.016,
    government: 248.141,
    municipalities: 73.71,
    relessings: 19.932,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 145.53,
    selflendings: 56.9,
    heldlendings: 0,
    savingslendings: 1.48,
    brokerlendings: 0,
    foreignborrowings: 0.45
  },
  "1961-04-01": {
    alldebt: 816.916,
    domesticlessing: 756.737,
    domesticrelessings: 37.218,
    foreignlessing: 22.961,
    government: 249.032,
    municipalities: 74.653,
    relessings: 19.821,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 147.69,
    selflendings: 58.71,
    heldlendings: 0,
    savingslendings: 1.87,
    brokerlendings: 0,
    foreignborrowings: 0.46
  },
  "1961-07-01": {
    alldebt: 832.266,
    domesticlessing: 770.575,
    domesticrelessings: 38.058,
    foreignlessing: 23.633,
    government: 254.269,
    municipalities: 76.459,
    relessings: 19.689,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 148.37,
    selflendings: 60.61,
    heldlendings: 0,
    savingslendings: 2.12,
    brokerlendings: 0,
    foreignborrowings: 0.49
  },
  "1961-10-01": {
    alldebt: 853.949,
    domesticlessing: 788.596,
    domesticrelessings: 40.94,
    foreignlessing: 24.413,
    government: 258.176,
    municipalities: 77.815,
    relessings: 20.444,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 152.99,
    selflendings: 61.72,
    heldlendings: 0,
    savingslendings: 2.66,
    brokerlendings: 0,
    foreignborrowings: 0.54
  },
  "1962-01-01": {
    alldebt: 860.015,
    domesticlessing: 794.941,
    domesticrelessings: 39.909,
    foreignlessing: 25.165,
    government: 258.932,
    municipalities: 79.588,
    relessings: 20.488,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 153.76,
    selflendings: 63.52,
    heldlendings: 0,
    savingslendings: 2.15,
    brokerlendings: 0,
    foreignborrowings: 0.51
  },
  "1962-04-01": {
    alldebt: 876.832,
    domesticlessing: 809.898,
    domesticrelessings: 41.304,
    foreignlessing: 25.63,
    government: 259.637,
    municipalities: 80.915,
    relessings: 21.273,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 157.22,
    selflendings: 65.95,
    heldlendings: 0,
    savingslendings: 2.77,
    brokerlendings: 0,
    foreignborrowings: 0.47
  },
  "1962-07-01": {
    alldebt: 889.792,
    domesticlessing: 821.209,
    domesticrelessings: 43.005,
    foreignlessing: 25.578,
    government: 260.143,
    municipalities: 82.316,
    relessings: 21.657,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 158.72,
    selflendings: 67.7,
    heldlendings: 0,
    savingslendings: 3.05,
    brokerlendings: 0,
    foreignborrowings: 0.46
  },
  "1962-10-01": {
    alldebt: 914.947,
    domesticlessing: 842.334,
    domesticrelessings: 46.345,
    foreignlessing: 26.268,
    government: 266.044,
    municipalities: 83.77,
    relessings: 22.634,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 163.47,
    selflendings: 69.3,
    heldlendings: 0,
    savingslendings: 3.48,
    brokerlendings: 0,
    foreignborrowings: 0.54
  },
  "1963-01-01": {
    alldebt: 921.386,
    domesticlessing: 850.279,
    domesticrelessings: 44.008,
    foreignlessing: 27.099,
    government: 266.77,
    municipalities: 85.007,
    relessings: 23.371,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 164.73,
    selflendings: 71.2,
    heldlendings: 0,
    savingslendings: 2.51,
    brokerlendings: 0,
    foreignborrowings: 0.52
  },
  "1963-04-01": {
    alldebt: 943.657,
    domesticlessing: 867.62,
    domesticrelessings: 47.658,
    foreignlessing: 28.379,
    government: 267.323,
    municipalities: 86.776,
    relessings: 24.323,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 167.54,
    selflendings: 74.02,
    heldlendings: 0,
    savingslendings: 3.27,
    brokerlendings: 0,
    foreignborrowings: 0.55
  },
  "1963-07-01": {
    alldebt: 958.156,
    domesticlessing: 879.175,
    domesticrelessings: 50.323,
    foreignlessing: 28.658,
    government: 267.111,
    municipalities: 88.053,
    relessings: 24.859,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 168.69,
    selflendings: 75.98,
    heldlendings: 0,
    savingslendings: 4.02,
    brokerlendings: 0,
    foreignborrowings: 0.55
  },
  "1963-10-01": {
    alldebt: 984.096,
    domesticlessing: 900.618,
    domesticrelessings: 54.125,
    foreignlessing: 29.353,
    government: 271.008,
    municipalities: 89.243,
    relessings: 26.401,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 174.18,
    selflendings: 77.97,
    heldlendings: 0,
    savingslendings: 4.78,
    brokerlendings: 0,
    foreignborrowings: 0.58
  },
  "1964-01-01": {
    alldebt: 992.236,
    domesticlessing: 909.144,
    domesticrelessings: 53.17,
    foreignlessing: 29.922,
    government: 271.258,
    municipalities: 90.376,
    relessings: 27.105,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 175.7,
    selflendings: 79.81,
    heldlendings: 0,
    savingslendings: 4.17,
    brokerlendings: 0,
    foreignborrowings: 0.63
  },
  "1964-04-01": {
    alldebt: 1015.391,
    domesticlessing: 926.911,
    domesticrelessings: 57.737,
    foreignlessing: 30.743,
    government: 270.807,
    municipalities: 92.222,
    relessings: 29.13,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 179.7,
    selflendings: 82.68,
    heldlendings: 0,
    savingslendings: 4.77,
    brokerlendings: 0,
    foreignborrowings: 0.64
  },
  "1964-07-01": {
    alldebt: 1032.159,
    domesticlessing: 942.699,
    domesticrelessings: 58.158,
    foreignlessing: 31.302,
    government: 273.696,
    municipalities: 94.132,
    relessings: 29.219,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 181.9,
    selflendings: 84.75,
    heldlendings: 0,
    savingslendings: 4.84,
    brokerlendings: 0,
    foreignborrowings: 0.64
  },
  "1964-10-01": {
    alldebt: 1059.235,
    domesticlessing: 965.255,
    domesticrelessings: 61.043,
    foreignlessing: 32.937,
    government: 278.309,
    municipalities: 95.624,
    relessings: 30.457,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 187.92,
    selflendings: 86.27,
    heldlendings: 0,
    savingslendings: 5.33,
    brokerlendings: 0,
    foreignborrowings: 0.68
  },
  "1965-01-01": {
    alldebt: 1071.335,
    domesticlessing: 976.583,
    domesticrelessings: 60.632,
    foreignlessing: 34.12,
    government: 278.254,
    municipalities: 97.184,
    relessings: 31.333,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 192.27,
    selflendings: 88.38,
    heldlendings: 0,
    savingslendings: 4.75,
    brokerlendings: 0,
    foreignborrowings: 0.67
  },
  "1965-04-01": {
    alldebt: 1095.666,
    domesticlessing: 994.652,
    domesticrelessings: 66.494,
    foreignlessing: 34.52,
    government: 275.83,
    municipalities: 99.513,
    relessings: 33.092,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 197.85,
    selflendings: 91.53,
    heldlendings: 0,
    savingslendings: 5.59,
    brokerlendings: 0,
    foreignborrowings: 0.68
  },
  "1965-07-01": {
    alldebt: 1108.597,
    domesticlessing: 1008.147,
    domesticrelessings: 65.796,
    foreignlessing: 34.654,
    government: 275.013,
    municipalities: 101.624,
    relessings: 33.538,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 201.13,
    selflendings: 93.71,
    heldlendings: 0,
    savingslendings: 5.8,
    brokerlendings: 0,
    foreignborrowings: 0.67
  },
  "1965-10-01": {
    alldebt: 1139.531,
    domesticlessing: 1034.076,
    domesticrelessings: 70.15,
    foreignlessing: 35.305,
    government: 281.252,
    municipalities: 103.174,
    relessings: 35.349,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 208.19,
    selflendings: 95.59,
    heldlendings: 0,
    savingslendings: 6,
    brokerlendings: 0,
    foreignborrowings: 0.69
  },
  "1966-01-01": {
    alldebt: 1154.74,
    domesticlessing: 1047.918,
    domesticrelessings: 70.815,
    foreignlessing: 36.007,
    government: 281.804,
    municipalities: 104.665,
    relessings: 36.597,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 213.61,
    selflendings: 98.99,
    heldlendings: 0,
    savingslendings: 5.69,
    brokerlendings: 0,
    foreignborrowings: 0.68
  },
  "1966-04-01": {
    alldebt: 1182.321,
    domesticlessing: 1068.474,
    domesticrelessings: 77.268,
    foreignlessing: 36.579,
    government: 278.555,
    municipalities: 107.051,
    relessings: 38.069,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 222.31,
    selflendings: 102.19,
    heldlendings: 0,
    savingslendings: 6.78,
    brokerlendings: 0,
    foreignborrowings: 0.69
  },
  "1966-07-01": {
    alldebt: 1194.215,
    domesticlessing: 1081.064,
    domesticrelessings: 76.609,
    foreignlessing: 36.542,
    government: 279.93,
    municipalities: 108.731,
    relessings: 37.41,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 226.6,
    selflendings: 103.71,
    heldlendings: 0,
    savingslendings: 7.18,
    brokerlendings: 0,
    foreignborrowings: 0.68
  },
  "1966-10-01": {
    alldebt: 1221.908,
    domesticlessing: 1102.733,
    domesticrelessings: 81.898,
    foreignlessing: 37.277,
    government: 286.101,
    municipalities: 110.037,
    relessings: 39.733,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 232.92,
    selflendings: 105.28,
    heldlendings: 0,
    savingslendings: 6.94,
    brokerlendings: 0,
    foreignborrowings: 0.73
  },
  "1967-01-01": {
    alldebt: 1232.387,
    domesticlessing: 1113.632,
    domesticrelessings: 80.108,
    foreignlessing: 38.647,
    government: 288.574,
    municipalities: 111.288,
    relessings: 39.834,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 238.65,
    selflendings: 107.11,
    heldlendings: 0,
    savingslendings: 5.18,
    brokerlendings: 0,
    foreignborrowings: 0.75
  },
  "1967-04-01": {
    alldebt: 1245.885,
    domesticlessing: 1126.921,
    domesticrelessings: 79.391,
    foreignlessing: 39.573,
    government: 281.611,
    municipalities: 113.659,
    relessings: 40.019,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 246.02,
    selflendings: 110.53,
    heldlendings: 0,
    savingslendings: 4.3,
    brokerlendings: 0,
    foreignborrowings: 0.83
  },
  "1967-07-01": {
    alldebt: 1270.956,
    domesticlessing: 1149.195,
    domesticrelessings: 81.288,
    foreignlessing: 40.473,
    government: 290.008,
    municipalities: 115.889,
    relessings: 40.239,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 249.54,
    selflendings: 112.84,
    heldlendings: 0,
    savingslendings: 4.12,
    brokerlendings: 0,
    foreignborrowings: 0.84
  },
  "1967-10-01": {
    alldebt: 1318.29,
    domesticlessing: 1190.912,
    domesticrelessings: 85.926,
    foreignlessing: 41.452,
    government: 300.362,
    municipalities: 117.372,
    relessings: 42.098,
    mortgagelendings: 0,
    borrowings: 0,
    corporatelendings: 255.24,
    selflendings: 115.61,
    heldlendings: 0,
    savingslendings: 4.39,
    brokerlendings: 0,
    foreignborrowings: 0.87
  },
  "1968-01-01": {
    alldebt: 1326.896,
    domesticlessing: 1198.703,
    domesticrelessings: 85.805,
    foreignlessing: 42.388,
    government: 306.084,
    municipalities: 118.914,
    relessings: 41.837,
    mortgagelendings: 0.145,
    borrowings: 0,
    corporatelendings: 262.5,
    selflendings: 117.51,
    heldlendings: 0,
    savingslendings: 4.27,
    brokerlendings: 0,
    foreignborrowings: 0.88
  },
  "1968-04-01": {
    alldebt: 1356.942,
    domesticlessing: 1222.676,
    domesticrelessings: 91.574,
    foreignlessing: 42.692,
    government: 303.668,
    municipalities: 121.158,
    relessings: 43.697,
    mortgagelendings: 0.287,
    borrowings: 0,
    corporatelendings: 270.2,
    selflendings: 121.39,
    heldlendings: 0,
    savingslendings: 4.89,
    brokerlendings: 0,
    foreignborrowings: 0.87
  },
  "1968-07-01": {
    alldebt: 1385.16,
    domesticlessing: 1247.522,
    domesticrelessings: 94.365,
    foreignlessing: 43.273,
    government: 311.406,
    municipalities: 123.197,
    relessings: 43.1,
    mortgagelendings: 0.495,
    borrowings: 0,
    corporatelendings: 275.65,
    selflendings: 123.69,
    heldlendings: 0,
    savingslendings: 5.03,
    brokerlendings: 0,
    foreignborrowings: 0.87
  },
  "1968-10-01": {
    alldebt: 1423.541,
    domesticlessing: 1279.692,
    domesticrelessings: 99.722,
    foreignlessing: 44.127,
    government: 314.219,
    municipalities: 126.125,
    relessings: 46.417,
    mortgagelendings: 0.766,
    borrowings: 0,
    corporatelendings: 286.07,
    selflendings: 126.58,
    heldlendings: 0,
    savingslendings: 5.26,
    brokerlendings: 0,
    foreignborrowings: 0.89
  },
  "1969-01-01": {
    alldebt: 1435.254,
    domesticlessing: 1292.363,
    domesticrelessings: 98.001,
    foreignlessing: 44.89,
    government: 314.381,
    municipalities: 129.933,
    relessings: 47.305,
    mortgagelendings: 0.982,
    borrowings: 0,
    corporatelendings: 293.16,
    selflendings: 129.65,
    heldlendings: 0.2,
    savingslendings: 5.33,
    brokerlendings: 0,
    foreignborrowings: 0.9
  },
  "1969-04-01": {
    alldebt: 1464.669,
    domesticlessing: 1311.436,
    domesticrelessings: 107.4,
    foreignlessing: 45.833,
    government: 302.979,
    municipalities: 133.548,
    relessings: 50.437,
    mortgagelendings: 1.098,
    borrowings: 0,
    corporatelendings: 303.73,
    selflendings: 134.79,
    heldlendings: 1.245,
    savingslendings: 6.41,
    brokerlendings: 0,
    foreignborrowings: 0.99
  },
  "1969-07-01": {
    alldebt: 1492.103,
    domesticlessing: 1334.585,
    domesticrelessings: 111.094,
    foreignlessing: 46.424,
    government: 307.801,
    municipalities: 136.325,
    relessings: 50.754,
    mortgagelendings: 1.238,
    borrowings: 0,
    corporatelendings: 309.86,
    selflendings: 138.61,
    heldlendings: 2.595,
    savingslendings: 7.94,
    brokerlendings: 0,
    foreignborrowings: 1.06
  },
  "1969-10-01": {
    alldebt: 1536.207,
    domesticlessing: 1364.896,
    domesticrelessings: 124.19,
    foreignlessing: 47.121,
    government: 312.583,
    municipalities: 138.257,
    relessings: 54.562,
    mortgagelendings: 1.48,
    borrowings: 0,
    corporatelendings: 318.88,
    selflendings: 141.91,
    heldlendings: 4.294,
    savingslendings: 9.29,
    brokerlendings: 0,
    foreignborrowings: 1.1
  },
  "1970-01-01": {
    alldebt: 1549.919,
    domesticlessing: 1373.658,
    domesticrelessings: 128.525,
    foreignlessing: 47.736,
    government: 314.538,
    municipalities: 140.356,
    relessings: 53.664,
    mortgagelendings: 1.526,
    borrowings: 0,
    corporatelendings: 338.22,
    selflendings: 135.08,
    heldlendings: 7.415,
    savingslendings: 9.75,
    brokerlendings: 0,
    foreignborrowings: 1.08
  },
  "1970-04-01": {
    alldebt: 1578.2,
    domesticlessing: 1396.047,
    domesticrelessings: 133.869,
    foreignlessing: 48.284,
    government: 310.108,
    municipalities: 143.57,
    relessings: 55.99,
    mortgagelendings: 1.745,
    borrowings: 0,
    corporatelendings: 350.55,
    selflendings: 140.02,
    heldlendings: 8.581,
    savingslendings: 10.24,
    brokerlendings: 0,
    foreignborrowings: 1.21
  },
  "1970-07-01": {
    alldebt: 1605.959,
    domesticlessing: 1423.178,
    domesticrelessings: 134.146,
    foreignlessing: 48.635,
    government: 317.592,
    municipalities: 146.348,
    relessings: 55.36,
    mortgagelendings: 1.814,
    borrowings: 0,
    corporatelendings: 356.11,
    selflendings: 144.69,
    heldlendings: 5.672,
    savingslendings: 10.53,
    brokerlendings: 0,
    foreignborrowings: 1.18
  },
  "1970-10-01": {
    alldebt: 1647.934,
    domesticlessing: 1456.448,
    domesticrelessings: 141.493,
    foreignlessing: 49.993,
    government: 327.027,
    municipalities: 150.317,
    relessings: 58.199,
    mortgagelendings: 2.179,
    borrowings: 0,
    corporatelendings: 363.38,
    selflendings: 148.56,
    heldlendings: 3.449,
    savingslendings: 10.62,
    brokerlendings: 0,
    foreignborrowings: 1.43
  },
  "1971-01-01": {
    alldebt: 1666.594,
    domesticlessing: 1475.888,
    domesticrelessings: 139.509,
    foreignlessing: 51.197,
    government: 329.092,
    municipalities: 154.655,
    relessings: 57.095,
    mortgagelendings: 2.585,
    borrowings: 0,
    corporatelendings: 369.81,
    selflendings: 152.69,
    heldlendings: 3.811,
    savingslendings: 9.69,
    brokerlendings: 0,
    foreignborrowings: 1.45
  },
  "1971-04-01": {
    alldebt: 1705.87,
    domesticlessing: 1514.067,
    domesticrelessings: 139.542,
    foreignlessing: 52.261,
    government: 332.62,
    municipalities: 158.807,
    relessings: 58.408,
    mortgagelendings: 3.011,
    borrowings: 0,
    corporatelendings: 379.21,
    selflendings: 160.36,
    heldlendings: 3.851,
    savingslendings: 7.24,
    brokerlendings: 0,
    foreignborrowings: 1.54
  },
  "1971-07-01": {
    alldebt: 1747.33,
    domesticlessing: 1551.633,
    domesticrelessings: 142.492,
    foreignlessing: 53.205,
    government: 341.74,
    municipalities: 162.965,
    relessings: 57.093,
    mortgagelendings: 3.455,
    borrowings: 0,
    corporatelendings: 385.81,
    selflendings: 165.71,
    heldlendings: 4.042,
    savingslendings: 7.64,
    brokerlendings: 0,
    foreignborrowings: 1.65
  },
  "1971-10-01": {
    alldebt: 1803.444,
    domesticlessing: 1596.2,
    domesticrelessings: 153.065,
    foreignlessing: 54.179,
    government: 354.745,
    municipalities: 166.747,
    relessings: 61.013,
    mortgagelendings: 4.111,
    borrowings: 0,
    corporatelendings: 392.24,
    selflendings: 171.16,
    heldlendings: 4.211,
    savingslendings: 7.94,
    brokerlendings: 0,
    foreignborrowings: 1.59
  },
  "1972-01-01": {
    alldebt: 1835.288,
    domesticlessing: 1625.36,
    domesticrelessings: 154.439,
    foreignlessing: 55.489,
    government: 358.706,
    municipalities: 171.129,
    relessings: 61.117,
    mortgagelendings: 5.297,
    borrowings: 0,
    corporatelendings: 401.32,
    selflendings: 175.93,
    heldlendings: 4.843,
    savingslendings: 5.99,
    brokerlendings: 0,
    foreignborrowings: 1.61
  },
  "1972-04-01": {
    alldebt: 1880.805,
    domesticlessing: 1664.66,
    domesticrelessings: 160.424,
    foreignlessing: 55.721,
    government: 354.8,
    municipalities: 174.21,
    relessings: 62.74,
    mortgagelendings: 6.363,
    borrowings: 0,
    corporatelendings: 411.59,
    selflendings: 186.16,
    heldlendings: 5.179,
    savingslendings: 6.08,
    brokerlendings: 0,
    foreignborrowings: 1.43
  },
  "1972-07-01": {
    alldebt: 1922.177,
    domesticlessing: 1701.687,
    domesticrelessings: 164.449,
    foreignlessing: 56.041,
    government: 359.989,
    municipalities: 177.747,
    relessings: 63.113,
    mortgagelendings: 7.136,
    borrowings: 0,
    corporatelendings: 417.75,
    selflendings: 193.17,
    heldlendings: 6.106,
    savingslendings: 6.74,
    brokerlendings: 0,
    foreignborrowings: 1.33
  },
  "1972-10-01": {
    alldebt: 1997.432,
    domesticlessing: 1759.092,
    domesticrelessings: 180.771,
    foreignlessing: 57.569,
    government: 372.75,
    municipalities: 180.71,
    relessings: 68.71,
    mortgagelendings: 8.751,
    borrowings: 0,
    corporatelendings: 431.81,
    selflendings: 202.55,
    heldlendings: 6.789,
    savingslendings: 7.98,
    brokerlendings: 0,
    foreignborrowings: 1.39
  },
  "1973-01-01": {
    alldebt: 2046.271,
    domesticlessing: 1805.406,
    domesticrelessings: 182.08,
    foreignlessing: 58.785,
    government: 380.75,
    municipalities: 184.043,
    relessings: 69.303,
    mortgagelendings: 9.929,
    borrowings: 0,
    corporatelendings: 444.4,
    selflendings: 212.98,
    heldlendings: 7.916,
    savingslendings: 8.42,
    brokerlendings: 0,
    foreignborrowings: 1.39
  },
  "1973-04-01": {
    alldebt: 2108.395,
    domesticlessing: 1851.385,
    domesticrelessings: 196.521,
    foreignlessing: 60.489,
    government: 376.407,
    municipalities: 186.85,
    relessings: 72.733,
    mortgagelendings: 11.244,
    borrowings: 0,
    corporatelendings: 461.7,
    selflendings: 224.68,
    heldlendings: 8.956,
    savingslendings: 11.15,
    brokerlendings: 0,
    foreignborrowings: 1.46
  },
  "1973-07-01": {
    alldebt: 2163.544,
    domesticlessing: 1894.209,
    domesticrelessings: 208.964,
    foreignlessing: 60.371,
    government: 374.976,
    municipalities: 190.725,
    relessings: 73.193,
    mortgagelendings: 12.56,
    borrowings: 0,
    corporatelendings: 478.31,
    selflendings: 229.63,
    heldlendings: 10.088,
    savingslendings: 14.3,
    brokerlendings: 0,
    foreignborrowings: 1.65
  },
  "1973-10-01": {
    alldebt: 2227.039,
    domesticlessing: 1940.863,
    domesticrelessings: 223.936,
    foreignlessing: 62.24,
    government: 381.829,
    municipalities: 194.779,
    relessings: 78.066,
    mortgagelendings: 13.696,
    borrowings: 0,
    corporatelendings: 497.44,
    selflendings: 230.14,
    heldlendings: 9.959,
    savingslendings: 15.15,
    brokerlendings: 0,
    foreignborrowings: 1.8
  },
  "1974-01-01": {
    alldebt: 2261.307,
    domesticlessing: 1973.213,
    domesticrelessings: 225.48,
    foreignlessing: 62.614,
    government: 384.779,
    municipalities: 198.757,
    relessings: 78.454,
    mortgagelendings: 14.385,
    borrowings: 0,
    corporatelendings: 510.11,
    selflendings: 239.05,
    heldlendings: 11.575,
    savingslendings: 15,
    brokerlendings: 0,
    foreignborrowings: 2.05
  },
  "1974-04-01": {
    alldebt: 2334.257,
    domesticlessing: 2026.261,
    domesticrelessings: 240.994,
    foreignlessing: 67.002,
    government: 382.611,
    municipalities: 202.564,
    relessings: 80.615,
    mortgagelendings: 13.787,
    borrowings: 0,
    corporatelendings: 529.5,
    selflendings: 250.9,
    heldlendings: 12.961,
    savingslendings: 17.64,
    brokerlendings: 0,
    foreignborrowings: 2.66
  },
  "1974-07-01": {
    alldebt: 2398.793,
    domesticlessing: 2071.135,
    domesticrelessings: 257.896,
    foreignlessing: 69.762,
    government: 386.064,
    municipalities: 206.553,
    relessings: 81.34,
    mortgagelendings: 13.893,
    borrowings: 0,
    corporatelendings: 538.9,
    selflendings: 260.88,
    heldlendings: 15.107,
    savingslendings: 20.77,
    brokerlendings: 0,
    foreignborrowings: 3.24
  },
  "1974-10-01": {
    alldebt: 2460.282,
    domesticlessing: 2116.457,
    domesticrelessings: 270.774,
    foreignlessing: 73.051,
    government: 396.457,
    municipalities: 208.213,
    relessings: 85.108,
    mortgagelendings: 13.969,
    borrowings: 0,
    corporatelendings: 553.07,
    selflendings: 267.25,
    heldlendings: 15.192,
    savingslendings: 21.8,
    brokerlendings: 0,
    foreignborrowings: 3.73
  },
  "1975-01-01": {
    alldebt: 2488.292,
    domesticlessing: 2145.828,
    domesticrelessings: 265.876,
    foreignlessing: 76.588,
    government: 415.886,
    municipalities: 209.744,
    relessings: 81.439,
    mortgagelendings: 13.241,
    borrowings: 0,
    corporatelendings: 556.87,
    selflendings: 272.75,
    heldlendings: 16.049,
    savingslendings: 18.18,
    brokerlendings: 0,
    foreignborrowings: 3.78
  },
  "1975-04-01": {
    alldebt: 2541.559,
    domesticlessing: 2197.394,
    domesticrelessings: 265.46,
    foreignlessing: 78.705,
    government: 437.743,
    municipalities: 212.67,
    relessings: 82.816,
    mortgagelendings: 11.6,
    borrowings: 0,
    corporatelendings: 562.79,
    selflendings: 279.86,
    heldlendings: 15.974,
    savingslendings: 16.82,
    brokerlendings: 0,
    foreignborrowings: 3.58
  },
  "1975-07-01": {
    alldebt: 2590.059,
    domesticlessing: 2247.76,
    domesticrelessings: 261.398,
    foreignlessing: 80.901,
    government: 460.139,
    municipalities: 214.487,
    relessings: 79.293,
    mortgagelendings: 9.871,
    borrowings: 0,
    corporatelendings: 564.88,
    selflendings: 284.19,
    heldlendings: 16.231,
    savingslendings: 17.53,
    brokerlendings: 0,
    foreignborrowings: 3.39
  },
  "1975-10-01": {
    alldebt: 2676.407,
    domesticlessing: 2313.028,
    domesticrelessings: 277.293,
    foreignlessing: 86.086,
    government: 485.926,
    municipalities: 219.359,
    relessings: 81.727,
    mortgagelendings: 8.739,
    borrowings: 0,
    corporatelendings: 572.67,
    selflendings: 287.6,
    heldlendings: 20.654,
    savingslendings: 17.9,
    brokerlendings: 0,
    foreignborrowings: 3.78
  },
  "1976-01-01": {
    alldebt: 2728.697,
    domesticlessing: 2361.864,
    domesticrelessings: 277.302,
    foreignlessing: 89.531,
    government: 509.489,
    municipalities: 224.015,
    relessings: 81.826,
    mortgagelendings: 7.958,
    borrowings: 0,
    corporatelendings: 576.04,
    selflendings: 295.61,
    heldlendings: 21.027,
    savingslendings: 15.81,
    brokerlendings: 0,
    foreignborrowings: 3.82
  },
  "1976-04-01": {
    alldebt: 2808.937,
    domesticlessing: 2432.712,
    domesticrelessings: 283.44,
    foreignlessing: 92.785,
    government: 525.084,
    municipalities: 231.469,
    relessings: 83.267,
    mortgagelendings: 7.601,
    borrowings: 0,
    corporatelendings: 589.49,
    selflendings: 303.3,
    heldlendings: 21.208,
    savingslendings: 15.34,
    brokerlendings: 0,
    foreignborrowings: 4
  },
  "1976-07-01": {
    alldebt: 2879.62,
    domesticlessing: 2494.236,
    domesticrelessings: 289.079,
    foreignlessing: 96.305,
    government: 541.821,
    municipalities: 235.612,
    relessings: 82.85,
    mortgagelendings: 7.421,
    borrowings: 0,
    corporatelendings: 597.9,
    selflendings: 310.6,
    heldlendings: 22.194,
    savingslendings: 16.2,
    brokerlendings: 0,
    foreignborrowings: 3.96
  },
  "1976-10-01": {
    alldebt: 2976.283,
    domesticlessing: 2565.231,
    domesticrelessings: 308.835,
    foreignlessing: 102.217,
    government: 559.712,
    municipalities: 237.83,
    relessings: 88.528,
    mortgagelendings: 6.875,
    borrowings: 0,
    corporatelendings: 614.35,
    selflendings: 317.08,
    heldlendings: 23.03,
    savingslendings: 16,
    brokerlendings: 0,
    foreignborrowings: 4.55
  },
  "1977-01-01": {
    alldebt: 3036.32,
    domesticlessing: 2626.269,
    domesticrelessings: 306.134,
    foreignlessing: 103.917,
    government: 576.214,
    municipalities: 239.122,
    relessings: 89.477,
    mortgagelendings: 6.256,
    borrowings: 0,
    corporatelendings: 630.58,
    selflendings: 327.62,
    heldlendings: 23.765,
    savingslendings: 14.6,
    brokerlendings: 0,
    foreignborrowings: 4.58
  },
  "1977-04-01": {
    alldebt: 3133.744,
    domesticlessing: 2704.709,
    domesticrelessings: 322.501,
    foreignlessing: 106.534,
    government: 576.622,
    municipalities: 246.59,
    relessings: 95.983,
    mortgagelendings: 5.602,
    borrowings: 0,
    corporatelendings: 649.97,
    selflendings: 340.34,
    heldlendings: 25.13,
    savingslendings: 16.36,
    brokerlendings: 0,
    foreignborrowings: 4.74
  },
  "1977-07-01": {
    alldebt: 3240.127,
    domesticlessing: 2797.67,
    domesticrelessings: 331.996,
    foreignlessing: 110.461,
    government: 602.205,
    municipalities: 252.133,
    relessings: 98.289,
    mortgagelendings: 5.21,
    borrowings: 0,
    corporatelendings: 663.88,
    selflendings: 350.36,
    heldlendings: 26.215,
    savingslendings: 17.8,
    brokerlendings: 0,
    foreignborrowings: 4.71
  },
  "1977-10-01": {
    alldebt: 3370.731,
    domesticlessing: 2895.413,
    domesticrelessings: 362.898,
    foreignlessing: 112.42,
    government: 622.606,
    municipalities: 256.165,
    relessings: 108.373,
    mortgagelendings: 5.027,
    borrowings: 0,
    corporatelendings: 690.32,
    selflendings: 359.81,
    heldlendings: 27.1,
    savingslendings: 21.47,
    brokerlendings: 0,
    foreignborrowings: 5.18
  },
  "1978-01-01": {
    alldebt: 3457.466,
    domesticlessing: 2975.023,
    domesticrelessings: 365.684,
    foreignlessing: 116.759,
    government: 641.819,
    municipalities: 264.139,
    relessings: 109.695,
    mortgagelendings: 4.487,
    borrowings: 0,
    corporatelendings: 709.55,
    selflendings: 370.56,
    heldlendings: 28.346,
    savingslendings: 22.62,
    brokerlendings: 0,
    foreignborrowings: 4.89
  },
  "1978-04-01": {
    alldebt: 3591.561,
    domesticlessing: 3080.233,
    domesticrelessings: 391.41,
    foreignlessing: 119.918,
    government: 645.778,
    municipalities: 274.834,
    relessings: 116.017,
    mortgagelendings: 4.353,
    borrowings: 0,
    corporatelendings: 733.61,
    selflendings: 387.21,
    heldlendings: 30.527,
    savingslendings: 27.05,
    brokerlendings: 0,
    foreignborrowings: 4.91
  },
  "1978-07-01": {
    alldebt: 3713.85,
    domesticlessing: 3186.254,
    domesticrelessings: 405.339,
    foreignlessing: 122.257,
    government: 666.778,
    municipalities: 284.847,
    relessings: 116.17,
    mortgagelendings: 4.41,
    borrowings: 0,
    corporatelendings: 745.06,
    selflendings: 403.62,
    heldlendings: 31.905,
    savingslendings: 31.06,
    brokerlendings: 0,
    foreignborrowings: 4.43
  },
  "1978-10-01": {
    alldebt: 3861.857,
    domesticlessing: 3290.005,
    domesticrelessings: 439.642,
    foreignlessing: 132.21,
    government: 682.118,
    municipalities: 295.601,
    relessings: 124.632,
    mortgagelendings: 4.379,
    borrowings: 0,
    corporatelendings: 766.06,
    selflendings: 418.21,
    heldlendings: 35.329,
    savingslendings: 34.63,
    brokerlendings: 0,
    foreignborrowings: 4.93
  },
  "1979-01-01": {
    alldebt: 3950.196,
    domesticlessing: 3367.36,
    domesticrelessings: 448.04,
    foreignlessing: 134.796,
    government: 690.445,
    municipalities: 301.844,
    relessings: 129.448,
    mortgagelendings: 4.383,
    borrowings: 0,
    corporatelendings: 789.22,
    selflendings: 430.84,
    heldlendings: 36.735,
    savingslendings: 33.96,
    brokerlendings: 0,
    foreignborrowings: 4.99
  },
  "1979-04-01": {
    alldebt: 4088.487,
    domesticlessing: 3470.213,
    domesticrelessings: 479.773,
    foreignlessing: 138.501,
    government: 687.429,
    municipalities: 309.535,
    relessings: 136.704,
    mortgagelendings: 4.404,
    borrowings: 0,
    corporatelendings: 815.2,
    selflendings: 455.97,
    heldlendings: 40.056,
    savingslendings: 37.38,
    brokerlendings: 0,
    foreignborrowings: 5.55
  },
  "1979-07-01": {
    alldebt: 4239.834,
    domesticlessing: 3587.73,
    domesticrelessings: 502.795,
    foreignlessing: 149.309,
    government: 707.368,
    municipalities: 317.129,
    relessings: 137.196,
    mortgagelendings: 4.075,
    borrowings: 0,
    corporatelendings: 834.72,
    selflendings: 476.41,
    heldlendings: 42.315,
    savingslendings: 41.51,
    brokerlendings: 0,
    foreignborrowings: 6.44
  },
  "1979-10-01": {
    alldebt: 4382.456,
    domesticlessing: 3688.823,
    domesticrelessings: 538.064,
    foreignlessing: 155.569,
    government: 725.909,
    municipalities: 322.216,
    relessings: 144.701,
    mortgagelendings: 4.142,
    borrowings: 0,
    corporatelendings: 851.77,
    selflendings: 492.02,
    heldlendings: 42.786,
    savingslendings: 45.22,
    brokerlendings: 0,
    foreignborrowings: 6.75
  },
  "1980-01-01": {
    alldebt: 4489.139,
    domesticlessing: 3777.61,
    domesticrelessings: 552.797,
    foreignlessing: 158.732,
    government: 741.331,
    municipalities: 326.092,
    relessings: 144.009,
    mortgagelendings: 4.006,
    borrowings: 0,
    corporatelendings: 875.36,
    selflendings: 509.47,
    heldlendings: 45.802,
    savingslendings: 47.6,
    brokerlendings: 0,
    foreignborrowings: 7.45
  },
  "1980-04-01": {
    alldebt: 4566.56,
    domesticlessing: 3831.116,
    domesticrelessings: 570.434,
    foreignlessing: 165.01,
    government: 748.918,
    municipalities: 332.508,
    relessings: 133.099,
    mortgagelendings: 3.944,
    borrowings: 12.699,
    corporatelendings: 880.92,
    selflendings: 525.62,
    heldlendings: 46.924,
    savingslendings: 46.08,
    brokerlendings: 0,
    foreignborrowings: 8.1
  },
  "1980-07-01": {
    alldebt: 4693.716,
    domesticlessing: 3937.675,
    domesticrelessings: 586.34,
    foreignlessing: 169.701,
    government: 786.215,
    municipalities: 339.435,
    relessings: 134.484,
    mortgagelendings: 3.763,
    borrowings: 13.418,
    corporatelendings: 892.51,
    selflendings: 541.01,
    heldlendings: 48.362,
    savingslendings: 47.89,
    brokerlendings: 0,
    foreignborrowings: 8.89
  },
  "1980-10-01": {
    alldebt: 4847.466,
    domesticlessing: 4051.493,
    domesticrelessings: 620.152,
    foreignlessing: 175.821,
    government: 812.184,
    municipalities: 344.434,
    relessings: 138.474,
    mortgagelendings: 3.755,
    borrowings: 12.523,
    corporatelendings: 919.61,
    selflendings: 555.06,
    heldlendings: 52.108,
    savingslendings: 52.7,
    brokerlendings: 0,
    foreignborrowings: 8.65
  },
  "1981-01-01": {
    alldebt: 4935.914,
    domesticlessing: 4131.939,
    domesticrelessings: 621.569,
    foreignlessing: 182.406,
    government: 843.545,
    municipalities: 349.48,
    relessings: 138.898,
    mortgagelendings: 3.798,
    borrowings: 14.088,
    corporatelendings: 936.89,
    selflendings: 573.16,
    heldlendings: 49.267,
    savingslendings: 52.68,
    brokerlendings: 0,
    foreignborrowings: 9.29
  },
  "1981-04-01": {
    alldebt: 5080.738,
    domesticlessing: 4232.097,
    domesticrelessings: 658.932,
    foreignlessing: 189.709,
    government: 842.407,
    municipalities: 355.332,
    relessings: 146.813,
    mortgagelendings: 3.731,
    borrowings: 14.367,
    corporatelendings: 977.13,
    selflendings: 591.75,
    heldlendings: 54.452,
    savingslendings: 59.37,
    brokerlendings: 0,
    foreignborrowings: 10.25
  },
  "1981-07-01": {
    alldebt: 5241.987,
    domesticlessing: 4351.588,
    domesticrelessings: 696.611,
    foreignlessing: 193.788,
    government: 871.686,
    municipalities: 364.627,
    relessings: 149.662,
    mortgagelendings: 3.758,
    borrowings: 17.269,
    corporatelendings: 1014.01,
    selflendings: 605.46,
    heldlendings: 60.109,
    savingslendings: 67.35,
    brokerlendings: 0,
    foreignborrowings: 10.63
  },
  "1981-10-01": {
    alldebt: 5410.83,
    domesticlessing: 4464.736,
    domesticrelessings: 723.581,
    foreignlessing: 222.513,
    government: 907.658,
    municipalities: 372.054,
    relessings: 153.439,
    mortgagelendings: 3.729,
    borrowings: 16.813,
    corporatelendings: 1038.68,
    selflendings: 619.78,
    heldlendings: 62.648,
    savingslendings: 68.42,
    brokerlendings: 0,
    foreignborrowings: 11.87
  },
  "1982-01-01": {
    alldebt: 5511.182,
    domesticlessing: 4548.56,
    domesticrelessings: 729.805,
    foreignlessing: 232.817,
    government: 937.167,
    municipalities: 380.108,
    relessings: 153.015,
    mortgagelendings: 3.681,
    borrowings: 17.928,
    corporatelendings: 1069.67,
    selflendings: 631.4,
    heldlendings: 64.234,
    savingslendings: 69.09,
    brokerlendings: 0,
    foreignborrowings: 12.42
  },
  "1982-04-01": {
    alldebt: 5659.616,
    domesticlessing: 4644.6,
    domesticrelessings: 769.698,
    foreignlessing: 245.318,
    government: 948.245,
    municipalities: 392.635,
    relessings: 158.771,
    mortgagelendings: 3.704,
    borrowings: 20.058,
    corporatelendings: 1099.61,
    selflendings: 650.04,
    heldlendings: 67.669,
    savingslendings: 72.1,
    brokerlendings: 0,
    foreignborrowings: 13.36
  },
  "1982-07-01": {
    alldebt: 5813.054,
    domesticlessing: 4772.023,
    domesticrelessings: 787.234,
    foreignlessing: 253.797,
    government: 1017.754,
    municipalities: 403.231,
    relessings: 155.585,
    mortgagelendings: 3.724,
    borrowings: 22.355,
    corporatelendings: 1120.25,
    selflendings: 667.12,
    heldlendings: 66.285,
    savingslendings: 70.82,
    brokerlendings: 0,
    foreignborrowings: 12.66
  },
  "1982-10-01": {
    alldebt: 5990.967,
    domesticlessing: 4900.299,
    domesticrelessings: 823.425,
    foreignlessing: 267.243,
    government: 1081.029,
    municipalities: 413.774,
    relessings: 158.294,
    mortgagelendings: 3.714,
    borrowings: 22.756,
    corporatelendings: 1129.93,
    selflendings: 677.65,
    heldlendings: 70.426,
    savingslendings: 69.65,
    brokerlendings: 0,
    foreignborrowings: 13.42
  },
  "1983-01-01": {
    alldebt: 6099.582,
    domesticlessing: 5002.45,
    domesticrelessings: 824.901,
    foreignlessing: 272.231,
    government: 1133.939,
    municipalities: 421.714,
    relessings: 160.939,
    mortgagelendings: 3.709,
    borrowings: 21.861,
    corporatelendings: 1160.66,
    selflendings: 695.77,
    heldlendings: 72.281,
    savingslendings: 63.94,
    brokerlendings: 0.05,
    foreignborrowings: 12.49
  },
  "1983-04-01": {
    alldebt: 6295.113,
    domesticlessing: 5159.665,
    domesticrelessings: 856.687,
    foreignlessing: 278.761,
    government: 1183.081,
    municipalities: 438.394,
    relessings: 165.489,
    mortgagelendings: 3.614,
    borrowings: 22.854,
    corporatelendings: 1184.51,
    selflendings: 712.88,
    heldlendings: 75.077,
    savingslendings: 63.07,
    brokerlendings: 0.07,
    foreignborrowings: 12.43
  },
  "1983-07-01": {
    alldebt: 6506.966,
    domesticlessing: 5328.484,
    domesticrelessings: 887.708,
    foreignlessing: 290.774,
    government: 1242.549,
    municipalities: 449.996,
    relessings: 168.227,
    mortgagelendings: 3.544,
    borrowings: 22.664,
    corporatelendings: 1208.21,
    selflendings: 729.86,
    heldlendings: 79.558,
    savingslendings: 62.37,
    brokerlendings: 0.12,
    foreignborrowings: 14.55
  },
  "1983-10-01": {
    alldebt: 6693.861,
    domesticlessing: 5497.72,
    domesticrelessings: 931.135,
    foreignlessing: 265.006,
    government: 1280.78,
    municipalities: 461.109,
    relessings: 173.769,
    mortgagelendings: 3.458,
    borrowings: 25.949,
    corporatelendings: 1247.24,
    selflendings: 748.98,
    heldlendings: 84.064,
    savingslendings: 63.62,
    brokerlendings: 0.38,
    foreignborrowings: 15.65
  },
  "1984-01-01": {
    alldebt: 6879.377,
    domesticlessing: 5657.789,
    domesticrelessings: 946.182,
    foreignlessing: 275.406,
    government: 1327.638,
    municipalities: 471.54,
    relessings: 179.434,
    mortgagelendings: 4.122,
    borrowings: 26.628,
    corporatelendings: 1291.91,
    selflendings: 775.24,
    heldlendings: 81.384,
    savingslendings: 62.47,
    brokerlendings: 0.13,
    foreignborrowings: 16.36
  },
  "1984-04-01": {
    alldebt: 7150.837,
    domesticlessing: 5865.044,
    domesticrelessings: 994.38,
    foreignlessing: 291.413,
    government: 1362.175,
    municipalities: 488.008,
    relessings: 186.328,
    mortgagelendings: 4.86,
    borrowings: 20.678,
    corporatelendings: 1351.46,
    selflendings: 807.4,
    heldlendings: 91.192,
    savingslendings: 70.64,
    brokerlendings: 0.34,
    foreignborrowings: 18.51
  },
  "1984-07-01": {
    alldebt: 7404.205,
    domesticlessing: 6071.86,
    domesticrelessings: 1041.911,
    foreignlessing: 290.434,
    government: 1428.915,
    municipalities: 504.998,
    relessings: 189.31,
    mortgagelendings: 5.587,
    borrowings: 27.826,
    corporatelendings: 1390.24,
    selflendings: 836.36,
    heldlendings: 94.025,
    savingslendings: 78.25,
    brokerlendings: 0.52,
    foreignborrowings: 21.84
  },
  "1984-10-01": {
    alldebt: 7700.147,
    domesticlessing: 6308.385,
    domesticrelessings: 1104.498,
    foreignlessing: 287.264,
    government: 1496.457,
    municipalities: 513.634,
    relessings: 199.169,
    mortgagelendings: 6.289,
    borrowings: 27.112,
    corporatelendings: 1457.97,
    selflendings: 862.65,
    heldlendings: 100.921,
    savingslendings: 81.42,
    brokerlendings: 1.07,
    foreignborrowings: 22.92
  },
  "1985-01-01": {
    alldebt: 7925.863,
    domesticlessing: 6509.305,
    domesticrelessings: 1129.657,
    foreignlessing: 286.901,
    government: 1547.569,
    municipalities: 537.245,
    relessings: 202.5,
    mortgagelendings: 6.568,
    borrowings: 30.166,
    corporatelendings: 1486.3,
    selflendings: 886.86,
    heldlendings: 104.638,
    savingslendings: 81.89,
    brokerlendings: 0.38,
    foreignborrowings: 22.51
  },
  "1985-04-01": {
    alldebt: 8208.031,
    domesticlessing: 6730.17,
    domesticrelessings: 1190.187,
    foreignlessing: 287.674,
    government: 1597.393,
    municipalities: 563.616,
    relessings: 212.772,
    mortgagelendings: 6.909,
    borrowings: 30.446,
    corporatelendings: 1527.27,
    selflendings: 915.93,
    heldlendings: 107.482,
    savingslendings: 87.68,
    brokerlendings: 0.55,
    foreignborrowings: 23.16
  },
  "1985-07-01": {
    alldebt: 8492.507,
    domesticlessing: 6951.721,
    domesticrelessings: 1253.832,
    foreignlessing: 286.954,
    government: 1658.103,
    municipalities: 589.238,
    relessings: 230.17,
    mortgagelendings: 7.292,
    borrowings: 35.725,
    corporatelendings: 1558.45,
    selflendings: 929.12,
    heldlendings: 112.157,
    savingslendings: 95.57,
    brokerlendings: 0.62,
    foreignborrowings: 23.01
  },
  "1985-10-01": {
    alldebt: 8969.979,
    domesticlessing: 7341.719,
    domesticrelessings: 1338.196,
    foreignlessing: 290.064,
    government: 1762.014,
    municipalities: 677.931,
    relessings: 241.024,
    mortgagelendings: 7.636,
    borrowings: 38.705,
    corporatelendings: 1638.94,
    selflendings: 933.05,
    heldlendings: 118.664,
    savingslendings: 100.5,
    brokerlendings: 1.34,
    foreignborrowings: 24.42
  },
  "1986-01-01": {
    alldebt: 9154.981,
    domesticlessing: 7464.649,
    domesticrelessings: 1392.092,
    foreignlessing: 298.24,
    government: 1797.119,
    municipalities: 680.083,
    relessings: 253.962,
    mortgagelendings: 8.076,
    borrowings: 50.976,
    corporatelendings: 1682.97,
    selflendings: 951.07,
    heldlendings: 114.461,
    savingslendings: 100.04,
    brokerlendings: 0.99,
    foreignborrowings: 24.31
  },
  "1986-04-01": {
    alldebt: 9477.163,
    domesticlessing: 7698.387,
    domesticrelessings: 1481.072,
    foreignlessing: 297.704,
    government: 1853.876,
    municipalities: 711.122,
    relessings: 275.039,
    mortgagelendings: 8.892,
    borrowings: 51.852,
    corporatelendings: 1741.48,
    selflendings: 972.76,
    heldlendings: 123.003,
    savingslendings: 108.79,
    brokerlendings: 1.7,
    foreignborrowings: 24.95
  },
  "1986-07-01": {
    alldebt: 9802.737,
    domesticlessing: 7931.614,
    domesticrelessings: 1567.836,
    foreignlessing: 303.287,
    government: 1922.804,
    municipalities: 727.112,
    relessings: 290.29,
    mortgagelendings: 9.734,
    borrowings: 52.188,
    corporatelendings: 1787.53,
    selflendings: 988.5,
    heldlendings: 126.201,
    savingslendings: 115.19,
    brokerlendings: 1.32,
    foreignborrowings: 27.91
  },
  "1986-10-01": {
    alldebt: 10200.278,
    domesticlessing: 8216.651,
    domesticrelessings: 1683.449,
    foreignlessing: 300.178,
    government: 2007.547,
    municipalities: 752.13,
    relessings: 301.542,
    mortgagelendings: 13.329,
    borrowings: 51.028,
    corporatelendings: 1865.8,
    selflendings: 1000.2,
    heldlendings: 130.031,
    savingslendings: 125.45,
    brokerlendings: 3.4,
    foreignborrowings: 27.24
  },
  "1987-01-01": {
    alldebt: 10387.242,
    domesticlessing: 8333.915,
    domesticrelessings: 1750.788,
    foreignlessing: 302.539,
    government: 2032.516,
    municipalities: 780.422,
    relessings: 303.341,
    mortgagelendings: 14.118,
    borrowings: 56.224,
    corporatelendings: 1910.65,
    selflendings: 1016.51,
    heldlendings: 140.185,
    savingslendings: 126.53,
    brokerlendings: 2.95,
    foreignborrowings: 27.22
  },
  "1987-04-01": {
    alldebt: 10682.381,
    domesticlessing: 8540.905,
    domesticrelessings: 1843.379,
    foreignlessing: 298.097,
    government: 2070.171,
    municipalities: 795.998,
    relessings: 310.998,
    mortgagelendings: 16.628,
    borrowings: 66.489,
    corporatelendings: 1957.76,
    selflendings: 1031.02,
    heldlendings: 144.835,
    savingslendings: 133.87,
    brokerlendings: 3.34,
    foreignborrowings: 32.67
  },
  "1987-07-01": {
    alldebt: 10946.579,
    domesticlessing: 8731.052,
    domesticrelessings: 1916.825,
    foreignlessing: 298.702,
    government: 2105.975,
    municipalities: 820.948,
    relessings: 319.401,
    mortgagelendings: 18.925,
    borrowings: 62.53,
    corporatelendings: 1995.66,
    selflendings: 1044.73,
    heldlendings: 147.522,
    savingslendings: 140.57,
    brokerlendings: 4.02,
    foreignborrowings: 32.39
  },
  "1987-10-01": {
    alldebt: 11227.488,
    domesticlessing: 8958.187,
    domesticrelessings: 1958.854,
    foreignlessing: 310.447,
    government: 2181.714,
    municipalities: 842.576,
    relessings: 313.552,
    mortgagelendings: 21.312,
    borrowings: 67.469,
    corporatelendings: 2059.21,
    selflendings: 1058.23,
    heldlendings: 145.01,
    savingslendings: 154.63,
    brokerlendings: 3.25,
    foreignborrowings: 33.83
  },
  "1988-01-01": {
    alldebt: 11425.123,
    domesticlessing: 9121.103,
    domesticrelessings: 1992.68,
    foreignlessing: 311.34,
    government: 2218.918,
    municipalities: 851.013,
    relessings: 304.862,
    mortgagelendings: 22.009,
    borrowings: 82.245,
    corporatelendings: 2114.33,
    selflendings: 1076.78,
    heldlendings: 146.365,
    savingslendings: 151.04,
    brokerlendings: 3.5,
    foreignborrowings: 32.74
  },
  "1988-04-01": {
    alldebt: 11708.016,
    domesticlessing: 9327.108,
    domesticrelessings: 2068.439,
    foreignlessing: 312.469,
    government: 2245.379,
    municipalities: 861.342,
    relessings: 323.365,
    mortgagelendings: 22.854,
    borrowings: 96.036,
    corporatelendings: 2172.76,
    selflendings: 1102.22,
    heldlendings: 151.414,
    savingslendings: 157.97,
    brokerlendings: 5.33,
    foreignborrowings: 33.27
  },
  "1988-07-01": {
    alldebt: 11964.39,
    domesticlessing: 9532.065,
    domesticrelessings: 2122.822,
    foreignlessing: 309.503,
    government: 2298.445,
    municipalities: 876.662,
    relessings: 319.849,
    mortgagelendings: 22.941,
    borrowings: 101.999,
    corporatelendings: 2204.23,
    selflendings: 1126.78,
    heldlendings: 149.901,
    savingslendings: 164.84,
    brokerlendings: 5.51,
    foreignborrowings: 32.53
  },
  "1988-10-01": {
    alldebt: 12340.695,
    domesticlessing: 9777.621,
    domesticrelessings: 2211.195,
    foreignlessing: 351.879,
    government: 2369.615,
    municipalities: 892.989,
    relessings: 337.507,
    mortgagelendings: 24.84,
    borrowings: 104.685,
    corporatelendings: 2263.52,
    selflendings: 1141.94,
    heldlendings: 149.863,
    savingslendings: 176.14,
    brokerlendings: 7.52,
    foreignborrowings: 34.36
  },
  "1989-01-01": {
    alldebt: 12594.558,
    domesticlessing: 9930.645,
    domesticrelessings: 2311.564,
    foreignlessing: 352.349,
    government: 2402.768,
    municipalities: 901.944,
    relessings: 335.309,
    mortgagelendings: 26.353,
    borrowings: 108.242,
    corporatelendings: 2323.89,
    selflendings: 1152.96,
    heldlendings: 154.292,
    savingslendings: 186.74,
    brokerlendings: 9.65,
    foreignborrowings: 30.5
  },
  "1989-04-01": {
    alldebt: 12828.52,
    domesticlessing: 10117.693,
    domesticrelessings: 2365.064,
    foreignlessing: 345.763,
    government: 2420.785,
    municipalities: 909.38,
    relessings: 348.936,
    mortgagelendings: 26.646,
    borrowings: 109.223,
    corporatelendings: 2385.64,
    selflendings: 1168.11,
    heldlendings: 154.905,
    savingslendings: 184.79,
    brokerlendings: 10.87,
    foreignborrowings: 33.09
  },
  "1989-07-01": {
    alldebt: 13035.959,
    domesticlessing: 10297.262,
    domesticrelessings: 2389.648,
    foreignlessing: 349.049,
    government: 2471.503,
    municipalities: 926.145,
    relessings: 362.301,
    mortgagelendings: 27.003,
    borrowings: 100.006,
    corporatelendings: 2405.96,
    selflendings: 1179.86,
    heldlendings: 157.112,
    savingslendings: 172.45,
    brokerlendings: 10.13,
    foreignborrowings: 34.25
  },
  "1989-10-01": {
    alldebt: 13346.569,
    domesticlessing: 10527.938,
    domesticrelessings: 2463.811,
    foreignlessing: 354.82,
    government: 2550.522,
    municipalities: 940.363,
    relessings: 374.996,
    mortgagelendings: 26.942,
    borrowings: 101.001,
    corporatelendings: 2454.12,
    selflendings: 1190.19,
    heldlendings: 155.158,
    savingslendings: 161.88,
    brokerlendings: 13.78,
    foreignborrowings: 35.39
  },
  "1990-01-01": {
    alldebt: 13569.241,
    domesticlessing: 10725.755,
    domesticrelessings: 2490.024,
    foreignlessing: 353.463,
    government: 2604.054,
    municipalities: 958.689,
    relessings: 381.116,
    mortgagelendings: 26.602,
    borrowings: 93.182,
    corporatelendings: 2511.4,
    selflendings: 1193.62,
    heldlendings: 154.677,
    savingslendings: 151.92,
    brokerlendings: 12.29,
    foreignborrowings: 33.17
  },
  "1990-04-01": {
    alldebt: 13793.437,
    domesticlessing: 10892.207,
    domesticrelessings: 2538.104,
    foreignlessing: 363.125,
    government: 2648.428,
    municipalities: 969.98,
    relessings: 388.974,
    mortgagelendings: 27.222,
    borrowings: 96.836,
    corporatelendings: 2550.37,
    selflendings: 1194.74,
    heldlendings: 146.213,
    savingslendings: 142.17,
    brokerlendings: 10.83,
    foreignborrowings: 32.96
  },
  "1990-07-01": {
    alldebt: 14011.621,
    domesticlessing: 11060.553,
    domesticrelessings: 2584.409,
    foreignlessing: 366.659,
    government: 2725.179,
    municipalities: 980.361,
    relessings: 384.982,
    mortgagelendings: 26.693,
    borrowings: 113.766,
    corporatelendings: 2569.57,
    selflendings: 1195.23,
    heldlendings: 133.341,
    savingslendings: 132.28,
    brokerlendings: 14.31,
    foreignborrowings: 32.13
  },
  "1990-10-01": {
    alldebt: 14292.95,
    domesticlessing: 11245.93,
    domesticrelessings: 2678.281,
    foreignlessing: 368.739,
    government: 2830.77,
    municipalities: 987.42,
    relessings: 398.118,
    mortgagelendings: 27.698,
    borrowings: 121.986,
    corporatelendings: 2583.29,
    selflendings: 1190.52,
    heldlendings: 124.579,
    savingslendings: 130.44,
    brokerlendings: 15.29,
    foreignborrowings: 34.5
  },
  "1991-01-01": {
    alldebt: 14388.192,
    domesticlessing: 11313.892,
    domesticrelessings: 2694.166,
    foreignlessing: 380.134,
    government: 2875.427,
    municipalities: 1001.003,
    relessings: 397.932,
    mortgagelendings: 28.274,
    borrowings: 116.686,
    corporatelendings: 2575.73,
    selflendings: 1183.58,
    heldlendings: 123.288,
    savingslendings: 118.76,
    brokerlendings: 16.42,
    foreignborrowings: 28.11
  },
  "1991-04-01": {
    alldebt: 14541.146,
    domesticlessing: 11454.003,
    domesticrelessings: 2722.599,
    foreignlessing: 364.545,
    government: 2927.929,
    municipalities: 1018.907,
    relessings: 401.322,
    mortgagelendings: 28.158,
    borrowings: 115.603,
    corporatelendings: 2579.18,
    selflendings: 1186.64,
    heldlendings: 121.542,
    savingslendings: 105.74,
    brokerlendings: 18.15,
    foreignborrowings: 28.01
  },
  "1991-07-01": {
    alldebt: 14740.454,
    domesticlessing: 11595.314,
    domesticrelessings: 2776.041,
    foreignlessing: 369.099,
    government: 3037.876,
    municipalities: 1047.76,
    relessings: 394.798,
    mortgagelendings: 27.909,
    borrowings: 122.579,
    corporatelendings: 2557.65,
    selflendings: 1170.64,
    heldlendings: 118.27,
    savingslendings: 92.17,
    brokerlendings: 18.21,
    foreignborrowings: 26.18
  },
  "1991-10-01": {
    alldebt: 15010.831,
    domesticlessing: 11775.541,
    domesticrelessings: 2859.815,
    foreignlessing: 375.474,
    government: 3147.363,
    municipalities: 1078.595,
    relessings: 411.74,
    mortgagelendings: 28.639,
    borrowings: 114.554,
    corporatelendings: 2524.45,
    selflendings: 1161.96,
    heldlendings: 118.463,
    savingslendings: 86.42,
    brokerlendings: 19,
    foreignborrowings: 24.91
  },
  "1992-01-01": {
    alldebt: 15152.695,
    domesticlessing: 11886.798,
    domesticrelessings: 2891.472,
    foreignlessing: 374.425,
    government: 3224.431,
    municipalities: 1080.582,
    relessings: 410.221,
    mortgagelendings: 30.404,
    borrowings: 124.748,
    corporatelendings: 2544.99,
    selflendings: 1154.64,
    heldlendings: 120.679,
    savingslendings: 81.38,
    brokerlendings: 19.69,
    foreignborrowings: 23.14
  },
  "1992-04-01": {
    alldebt: 15369.947,
    domesticlessing: 12011.533,
    domesticrelessings: 2970.48,
    foreignlessing: 387.934,
    government: 3297.691,
    municipalities: 1090.174,
    relessings: 404.536,
    mortgagelendings: 29.42,
    borrowings: 136.542,
    corporatelendings: 2548.42,
    selflendings: 1150.36,
    heldlendings: 118.003,
    savingslendings: 79.85,
    brokerlendings: 23.1,
    foreignborrowings: 22.05
  },
  "1992-07-01": {
    alldebt: 15607.064,
    domesticlessing: 12170.895,
    domesticrelessings: 3044.115,
    foreignlessing: 392.053,
    government: 3388.752,
    municipalities: 1103.719,
    relessings: 413.419,
    mortgagelendings: 29.843,
    borrowings: 137.113,
    corporatelendings: 2545.01,
    selflendings: 1142.17,
    heldlendings: 119.52,
    savingslendings: 80.94,
    brokerlendings: 22.71,
    foreignborrowings: 20.14
  },
  "1992-10-01": {
    alldebt: 15831.257,
    domesticlessing: 12328.512,
    domesticrelessings: 3107.197,
    foreignlessing: 395.548,
    government: 3491.131,
    municipalities: 1095.095,
    relessings: 416.647,
    mortgagelendings: 28.215,
    borrowings: 141.431,
    corporatelendings: 2543.6,
    selflendings: 1135.51,
    heldlendings: 118.371,
    savingslendings: 79.43,
    brokerlendings: 21.69,
    foreignborrowings: 21.16
  },
  "1993-01-01": {
    alldebt: 16011.165,
    domesticlessing: 12436.402,
    domesticrelessings: 3171.233,
    foreignlessing: 403.53,
    government: 3543.006,
    municipalities: 1112.632,
    relessings: 400.916,
    mortgagelendings: 28.308,
    borrowings: 151.735,
    corporatelendings: 2588.23,
    selflendings: 1123.37,
    heldlendings: 123.613,
    savingslendings: 82.2,
    brokerlendings: 19.32,
    foreignborrowings: 19.3
  },
  "1993-04-01": {
    alldebt: 16295.082,
    domesticlessing: 12640.577,
    domesticrelessings: 3236.989,
    foreignlessing: 417.516,
    government: 3613.622,
    municipalities: 1138.174,
    relessings: 398.691,
    mortgagelendings: 27.333,
    borrowings: 159.29,
    corporatelendings: 2630.69,
    selflendings: 1122.74,
    heldlendings: 124.055,
    savingslendings: 85.67,
    brokerlendings: 22.75,
    foreignborrowings: 18.17
  },
  "1993-07-01": {
    alldebt: 16622.977,
    domesticlessing: 12823.406,
    domesticrelessings: 3354.544,
    foreignlessing: 445.027,
    government: 3674.514,
    municipalities: 1153.067,
    relessings: 406.819,
    mortgagelendings: 28.366,
    borrowings: 167.9,
    corporatelendings: 2658,
    selflendings: 1117.67,
    heldlendings: 124.603,
    savingslendings: 89,
    brokerlendings: 26.42,
    foreignborrowings: 16.49
  },
  "1993-10-01": {
    alldebt: 17003.955,
    domesticlessing: 13054.799,
    domesticrelessings: 3464.348,
    foreignlessing: 484.808,
    government: 3784.333,
    municipalities: 1153.04,
    relessings: 415.299,
    mortgagelendings: 29.883,
    borrowings: 177.56,
    corporatelendings: 2690.37,
    selflendings: 1114.86,
    heldlendings: 128.012,
    savingslendings: 91.19,
    brokerlendings: 33.73,
    foreignborrowings: 16.03
  },
  "1994-01-01": {
    alldebt: 17254.368,
    domesticlessing: 13201.497,
    domesticrelessings: 3595.603,
    foreignlessing: 457.268,
    government: 3827.646,
    municipalities: 1149.992,
    relessings: 420.788,
    mortgagelendings: 32.576,
    borrowings: 197.886,
    corporatelendings: 2766.29,
    selflendings: 1113.42,
    heldlendings: 128.954,
    savingslendings: 88.06,
    brokerlendings: 31.26,
    foreignborrowings: 14.37
  },
  "1994-04-01": {
    alldebt: 17490.152,
    domesticlessing: 13341.928,
    domesticrelessings: 3693.034,
    foreignlessing: 455.189,
    government: 3844.798,
    municipalities: 1137.325,
    relessings: 428.151,
    mortgagelendings: 35.49,
    borrowings: 200.227,
    corporatelendings: 2810.37,
    selflendings: 1120.37,
    heldlendings: 133.004,
    savingslendings: 91.85,
    brokerlendings: 31.32,
    foreignborrowings: 14.16
  },
  "1994-07-01": {
    alldebt: 17762.562,
    domesticlessing: 13506.478,
    domesticrelessings: 3797.688,
    foreignlessing: 458.397,
    government: 3896.174,
    municipalities: 1125.325,
    relessings: 435.765,
    mortgagelendings: 39.301,
    borrowings: 208.613,
    corporatelendings: 2841.05,
    selflendings: 1126.24,
    heldlendings: 136.229,
    savingslendings: 95.83,
    brokerlendings: 29.42,
    foreignborrowings: 14.65
  },
  "1994-10-01": {
    alldebt: 18153.701,
    domesticlessing: 13739.431,
    domesticrelessings: 3940.849,
    foreignlessing: 473.421,
    government: 3977.825,
    municipalities: 1107.497,
    relessings: 457.851,
    mortgagelendings: 40.037,
    borrowings: 208.669,
    corporatelendings: 2887.24,
    selflendings: 1129.29,
    heldlendings: 141.526,
    savingslendings: 103.1,
    brokerlendings: 34.26,
    foreignborrowings: 13.44
  },
  "1995-01-01": {
    alldebt: 18386.966,
    domesticlessing: 13889.7,
    domesticrelessings: 4009.732,
    foreignlessing: 487.534,
    government: 4034.816,
    municipalities: 1092.793,
    relessings: 472.105,
    mortgagelendings: 40.561,
    borrowings: 230.34,
    corporatelendings: 2958.33,
    selflendings: 1137.98,
    heldlendings: 145.409,
    savingslendings: 97.67,
    brokerlendings: 26.87,
    foreignborrowings: 13.04
  },
  "1995-04-01": {
    alldebt: 18714.036,
    domesticlessing: 14086.413,
    domesticrelessings: 4124.724,
    foreignlessing: 502.899,
    government: 4070.417,
    municipalities: 1084.057,
    relessings: 487.253,
    mortgagelendings: 41.784,
    borrowings: 239.847,
    corporatelendings: 3026.15,
    selflendings: 1150.6,
    heldlendings: 148.958,
    savingslendings: 94.6,
    brokerlendings: 26.84,
    foreignborrowings: 12.88
  },
  "1995-07-01": {
    alldebt: 19016.019,
    domesticlessing: 14240.8,
    domesticrelessings: 4248.897,
    foreignlessing: 526.322,
    government: 4105.03,
    municipalities: 1058.797,
    relessings: 492.581,
    mortgagelendings: 42.488,
    borrowings: 251.336,
    corporatelendings: 3046.44,
    selflendings: 1163.74,
    heldlendings: 156.954,
    savingslendings: 94.77,
    brokerlendings: 27.37,
    foreignborrowings: 11.64
  },
  "1995-10-01": {
    alldebt: 19441.805,
    domesticlessing: 14428.136,
    domesticrelessings: 4411.145,
    foreignlessing: 602.524,
    government: 4151.993,
    municipalities: 1046.732,
    relessings: 510.284,
    mortgagelendings: 44.573,
    borrowings: 245.632,
    corporatelendings: 3095.2,
    selflendings: 1174.64,
    heldlendings: 155.31,
    savingslendings: 100.48,
    brokerlendings: 29.3,
    foreignborrowings: 10.36
  },
  "1996-01-01": {
    alldebt: 19757.353,
    domesticlessing: 14628.983,
    domesticrelessings: 4514.172,
    foreignlessing: 614.197,
    government: 4228.065,
    municipalities: 1042.115,
    relessings: 526.174,
    mortgagelendings: 46.66,
    borrowings: 256.81,
    corporatelendings: 3142.44,
    selflendings: 1187.33,
    heldlendings: 159.279,
    savingslendings: 102.55,
    brokerlendings: 21.36,
    foreignborrowings: 10.03
  },
  "1996-04-01": {
    alldebt: 20101.859,
    domesticlessing: 14796.194,
    domesticrelessings: 4680.582,
    foreignlessing: 625.083,
    government: 4216.407,
    municipalities: 1043.896,
    relessings: 538.568,
    mortgagelendings: 49.582,
    borrowings: 278.893,
    corporatelendings: 3198.46,
    selflendings: 1208.46,
    heldlendings: 165.179,
    savingslendings: 113.56,
    brokerlendings: 24.65,
    foreignborrowings: 8.78
  },
  "1996-07-01": {
    alldebt: 20418.219,
    domesticlessing: 14975.175,
    domesticrelessings: 4785.072,
    foreignlessing: 657.972,
    government: 4268.812,
    municipalities: 1024.878,
    relessings: 546.082,
    mortgagelendings: 53.068,
    borrowings: 289.266,
    corporatelendings: 3226.66,
    selflendings: 1228.44,
    heldlendings: 165.406,
    savingslendings: 120.29,
    brokerlendings: 26.07,
    foreignborrowings: 8.9
  },
  "1996-10-01": {
    alldebt: 20860.218,
    domesticlessing: 15185.346,
    domesticrelessings: 4985.729,
    foreignlessing: 689.142,
    government: 4343.083,
    municipalities: 1026.189,
    relessings: 568.896,
    mortgagelendings: 56.465,
    borrowings: 313.661,
    corporatelendings: 3244.06,
    selflendings: 1245.08,
    heldlendings: 167.953,
    savingslendings: 124.58,
    brokerlendings: 27.28,
    foreignborrowings: 9.64
  },
  "1997-01-01": {
    alldebt: 21101.367,
    domesticlessing: 15358.709,
    domesticrelessings: 5045.665,
    foreignlessing: 696.993,
    government: 4381.251,
    municipalities: 1034.366,
    relessings: 567.526,
    mortgagelendings: 62.756,
    borrowings: 347.684,
    corporatelendings: 3310.88,
    selflendings: 1265.27,
    heldlendings: 166.069,
    savingslendings: 119.36,
    brokerlendings: 26.57,
    foreignborrowings: 9.74
  },
  "1997-04-01": {
    alldebt: 21449.211,
    domesticlessing: 15500.623,
    domesticrelessings: 5232.296,
    foreignlessing: 716.292,
    government: 4321.557,
    municipalities: 1046.893,
    relessings: 600.839,
    mortgagelendings: 71.3,
    borrowings: 349.651,
    corporatelendings: 3376.99,
    selflendings: 1292.44,
    heldlendings: 174.943,
    savingslendings: 127.59,
    brokerlendings: 35.3,
    foreignborrowings: 10.35
  },
  "1997-07-01": {
    alldebt: 21838.887,
    domesticlessing: 15759.574,
    domesticrelessings: 5332.595,
    foreignlessing: 746.718,
    government: 4347.737,
    municipalities: 1055.63,
    relessings: 580.249,
    mortgagelendings: 79.618,
    borrowings: 362.459,
    corporatelendings: 3455.88,
    selflendings: 1318.47,
    heldlendings: 181.674,
    savingslendings: 132.22,
    brokerlendings: 33.58,
    foreignborrowings: 9.25
  },
  "1997-10-01": {
    alldebt: 22360.505,
    domesticlessing: 16029.496,
    domesticrelessings: 5567.418,
    foreignlessing: 763.591,
    government: 4406.492,
    municipalities: 1076.946,
    relessings: 596.648,
    mortgagelendings: 96.107,
    borrowings: 372.605,
    corporatelendings: 3521.27,
    selflendings: 1347.01,
    heldlendings: 186.259,
    savingslendings: 140.86,
    brokerlendings: 35.34,
    foreignborrowings: 7.22
  },
  "1998-01-01": {
    alldebt: 22855.377,
    domesticlessing: 16274.631,
    domesticrelessings: 5792.576,
    foreignlessing: 788.17,
    government: 4421.163,
    municipalities: 1096.757,
    relessings: 617.136,
    mortgagelendings: 111.883,
    borrowings: 410.3,
    corporatelendings: 3636.6,
    selflendings: 1386.45,
    heldlendings: 199.406,
    savingslendings: 140.64,
    brokerlendings: 35.08,
    foreignborrowings: 5.51
  },
  "1998-04-01": {
    alldebt: 23423.13,
    domesticlessing: 16539.043,
    domesticrelessings: 6071.693,
    foreignlessing: 812.394,
    government: 4350.424,
    municipalities: 1120.962,
    relessings: 651.635,
    mortgagelendings: 127.986,
    borrowings: 409.051,
    corporatelendings: 3746.97,
    selflendings: 1437.39,
    heldlendings: 211.092,
    savingslendings: 147.36,
    brokerlendings: 40.08,
    foreignborrowings: 4
  },
  "1998-07-01": {
    alldebt: 23914.139,
    domesticlessing: 16755.152,
    domesticrelessings: 6350.435,
    foreignlessing: 808.552,
    government: 4336.375,
    municipalities: 1125.239,
    relessings: 637.29,
    mortgagelendings: 147.799,
    borrowings: 416.261,
    corporatelendings: 3814.82,
    selflendings: 1481.03,
    heldlendings: 219.827,
    savingslendings: 158.69,
    brokerlendings: 39.44,
    foreignborrowings: 3.54
  },
  "1998-10-01": {
    alldebt: 24572.03,
    domesticlessing: 17110.682,
    domesticrelessings: 6655.394,
    foreignlessing: 805.954,
    government: 4395.086,
    municipalities: 1143.753,
    relessings: 648.517,
    mortgagelendings: 158.8,
    borrowings: 412.576,
    corporatelendings: 3900.43,
    selflendings: 1526.03,
    heldlendings: 221.197,
    savingslendings: 182.73,
    brokerlendings: 42.53,
    foreignborrowings: 2.41
  },
  "1999-01-01": {
    alldebt: 25120.527,
    domesticlessing: 17390.986,
    domesticrelessings: 6920.013,
    foreignlessing: 809.527,
    government: 4392.185,
    municipalities: 1162.785,
    relessings: 664.939,
    mortgagelendings: 165.21,
    borrowings: 457.096,
    corporatelendings: 4034.6,
    selflendings: 1570.55,
    heldlendings: 231.396,
    savingslendings: 192.91,
    brokerlendings: 34.76,
    foreignborrowings: 2.13
  },
  "1999-04-01": {
    alldebt: 25565.779,
    domesticlessing: 17578.876,
    domesticrelessings: 7180.167,
    foreignlessing: 806.736,
    government: 4294.362,
    municipalities: 1175.256,
    relessings: 694.131,
    mortgagelendings: 160.274,
    borrowings: 447.504,
    corporatelendings: 4111.36,
    selflendings: 1610.19,
    heldlendings: 241.985,
    savingslendings: 206.14,
    brokerlendings: 30.18,
    foreignborrowings: 2.1
  },
  "1999-07-01": {
    alldebt: 26211.079,
    domesticlessing: 17925.953,
    domesticrelessings: 7460.016,
    foreignlessing: 825.11,
    government: 4290.764,
    municipalities: 1176.73,
    relessings: 686.76,
    mortgagelendings: 162.243,
    borrowings: 459.584,
    corporatelendings: 4250.32,
    selflendings: 1662.15,
    heldlendings: 248.283,
    savingslendings: 220.75,
    brokerlendings: 34.62,
    foreignborrowings: 1.82
  },
  "1999-10-01": {
    alldebt: 26830.558,
    domesticlessing: 18288.519,
    domesticrelessings: 7765.36,
    foreignlessing: 776.679,
    government: 4365,
    municipalities: 1181.009,
    relessings: 720.854,
    mortgagelendings: 164.77,
    borrowings: 504.17,
    corporatelendings: 4308.33,
    selflendings: 1719.84,
    heldlendings: 260.426,
    savingslendings: 246.41,
    brokerlendings: 25.31,
    foreignborrowings: 2
  },
  "2000-01-01": {
    alldebt: 27320.428,
    domesticlessing: 18556.014,
    domesticrelessings: 7967.461,
    foreignlessing: 796.953,
    government: 4326.404,
    municipalities: 1184.349,
    relessings: 734.442,
    mortgagelendings: 167.407,
    borrowings: 518.859,
    corporatelendings: 4440.76,
    selflendings: 1762.97,
    heldlendings: 264.835,
    savingslendings: 246.52,
    brokerlendings: 36.42,
    foreignborrowings: 1.96
  },
  "2000-04-01": {
    alldebt: 27657.527,
    domesticlessing: 18715.474,
    domesticrelessings: 8162.519,
    foreignlessing: 779.534,
    government: 4147.231,
    municipalities: 1191.301,
    relessings: 769.958,
    mortgagelendings: 168.139,
    borrowings: 527.374,
    corporatelendings: 4561.56,
    selflendings: 1825.62,
    heldlendings: 274.248,
    savingslendings: 259.48,
    brokerlendings: 36.25,
    foreignborrowings: 2.03
  },
  "2000-07-01": {
    alldebt: 28119.017,
    domesticlessing: 18938.326,
    domesticrelessings: 8369.806,
    foreignlessing: 810.885,
    government: 4107.482,
    municipalities: 1185.474,
    relessings: 792.327,
    mortgagelendings: 166.045,
    borrowings: 519.628,
    corporatelendings: 4615.08,
    selflendings: 1862.43,
    heldlendings: 278.062,
    savingslendings: 255.19,
    brokerlendings: 42.59,
    foreignborrowings: 1.94
  },
  "2000-10-01": {
    alldebt: 28696.286,
    domesticlessing: 19172.303,
    domesticrelessings: 8686.869,
    foreignlessing: 837.115,
    government: 4090.025,
    municipalities: 1197.892,
    relessings: 807.111,
    mortgagelendings: 167.572,
    borrowings: 513.957,
    corporatelendings: 4666.71,
    selflendings: 1912.26,
    heldlendings: 288.162,
    savingslendings: 266.24,
    brokerlendings: 40.94,
    foreignborrowings: 1.96
  },
  "2001-01-01": {
    alldebt: 29070.938,
    domesticlessing: 19335.999,
    domesticrelessings: 8888.281,
    foreignlessing: 846.659,
    government: 4122.03,
    municipalities: 1225.169,
    relessings: 788.746,
    mortgagelendings: 167.84,
    borrowings: 597.253,
    corporatelendings: 4730.61,
    selflendings: 1948.75,
    heldlendings: 312.411,
    savingslendings: 270.17,
    brokerlendings: 35.01,
    foreignborrowings: 1.82
  },
  "2001-04-01": {
    alldebt: 29494.55,
    domesticlessing: 19559.331,
    domesticrelessings: 9101.135,
    foreignlessing: 834.084,
    government: 3976.229,
    municipalities: 1259.385,
    relessings: 805.114,
    mortgagelendings: 167.593,
    borrowings: 557.111,
    corporatelendings: 4805.07,
    selflendings: 1995.24,
    heldlendings: 308.651,
    savingslendings: 268.46,
    brokerlendings: 43.91,
    foreignborrowings: 1.35
  },
  "2001-07-01": {
    alldebt: 30232.675,
    domesticlessing: 19959.657,
    domesticrelessings: 9460.818,
    foreignlessing: 812.2,
    government: 4058.181,
    municipalities: 1265.264,
    relessings: 808.971,
    mortgagelendings: 165.94,
    borrowings: 520.011,
    corporatelendings: 4824.27,
    selflendings: 2031.81,
    heldlendings: 318.973,
    savingslendings: 270.84,
    brokerlendings: 47.06,
    foreignborrowings: 1.03
  },
  "2001-10-01": {
    alldebt: 30795.431,
    domesticlessing: 20261.333,
    domesticrelessings: 9704.645,
    foreignlessing: 829.453,
    government: 4145.237,
    municipalities: 1303.35,
    relessings: 817.992,
    mortgagelendings: 171.372,
    borrowings: 546.627,
    corporatelendings: 4826.84,
    selflendings: 2075.74,
    heldlendings: 305.48,
    savingslendings: 262.72,
    brokerlendings: 42.34,
    foreignborrowings: 1.09
  },
  "2002-01-01": {
    alldebt: 31234.636,
    domesticlessing: 20493.016,
    domesticrelessings: 9894.831,
    foreignlessing: 846.789,
    government: 4183.973,
    municipalities: 1325.391,
    relessings: 804.084,
    mortgagelendings: 173.504,
    borrowings: 550.736,
    corporatelendings: 4846.27,
    selflendings: 2110.06,
    heldlendings: 310.49,
    savingslendings: 251.12,
    brokerlendings: 38.43,
    foreignborrowings: 0.97
  },
  "2002-04-01": {
    alldebt: 31736.511,
    domesticlessing: 20794.914,
    domesticrelessings: 10070.596,
    foreignlessing: 871.001,
    government: 4213.894,
    municipalities: 1375.525,
    relessings: 834.654,
    mortgagelendings: 184.732,
    borrowings: 533.1,
    corporatelendings: 4838,
    selflendings: 2151.55,
    heldlendings: 304.497,
    savingslendings: 240.38,
    brokerlendings: 42.81,
    foreignborrowings: 0.94
  },
  "2002-07-01": {
    alldebt: 32289.853,
    domesticlessing: 21158.065,
    domesticrelessings: 10259.148,
    foreignlessing: 872.64,
    government: 4314.504,
    municipalities: 1398.907,
    relessings: 861.32,
    mortgagelendings: 191.361,
    borrowings: 517.485,
    corporatelendings: 4819.58,
    selflendings: 2188.76,
    heldlendings: 309.274,
    savingslendings: 241.03,
    brokerlendings: 46.61,
    foreignborrowings: 1.07
  },
  "2002-10-01": {
    alldebt: 33108.217,
    domesticlessing: 21618.346,
    domesticrelessings: 10545.401,
    foreignlessing: 944.47,
    government: 4427.328,
    municipalities: 1447.9,
    relessings: 884.204,
    mortgagelendings: 198.361,
    borrowings: 540.195,
    corporatelendings: 4829.8,
    selflendings: 2227.61,
    heldlendings: 325.086,
    savingslendings: 235.12,
    brokerlendings: 40.62,
    foreignborrowings: 0.66
  },
  "2003-01-01": {
    alldebt: 33684.602,
    domesticlessing: 21929.545,
    domesticrelessings: 10833.972,
    foreignlessing: 921.085,
    government: 4517.892,
    municipalities: 1474.767,
    relessings: 883.053,
    mortgagelendings: 201.586,
    borrowings: 565.286,
    corporatelendings: 4830.28,
    selflendings: 2243.84,
    heldlendings: 339.771,
    savingslendings: 241.65,
    brokerlendings: 50.23,
    foreignborrowings: 0.54
  },
  "2003-04-01": {
    alldebt: 34579.04,
    domesticlessing: 22474.17,
    domesticrelessings: 11162.294,
    foreignlessing: 942.576,
    government: 4585.543,
    municipalities: 1529.588,
    relessings: 942.26,
    mortgagelendings: 205.031,
    borrowings: 527.765,
    corporatelendings: 4839.66,
    selflendings: 2263.24,
    heldlendings: 352.741,
    savingslendings: 239.97,
    brokerlendings: 46.18,
    foreignborrowings: 0.46
  },
  "2003-07-01": {
    alldebt: 35217.871,
    domesticlessing: 22897.193,
    domesticrelessings: 11407.836,
    foreignlessing: 912.842,
    government: 4702.629,
    municipalities: 1538.504,
    relessings: 956.506,
    mortgagelendings: 215.894,
    borrowings: 513.023,
    corporatelendings: 4853.09,
    selflendings: 2280.45,
    heldlendings: 356.666,
    savingslendings: 246.72,
    brokerlendings: 48.64,
    foreignborrowings: 0.47
  },
  "2003-10-01": {
    alldebt: 36042.736,
    domesticlessing: 23343.538,
    domesticrelessings: 11554.8,
    foreignlessing: 1144.398,
    government: 4848.035,
    municipalities: 1568.435,
    relessings: 995.291,
    mortgagelendings: 230.695,
    borrowings: 536.129,
    corporatelendings: 4847.3,
    selflendings: 2301.51,
    heldlendings: 375.986,
    savingslendings: 254.4,
    brokerlendings: 46.99,
    foreignborrowings: 0.59
  },
  "2004-01-01": {
    alldebt: 37538.073,
    domesticlessing: 24569.134,
    domesticrelessings: 11796.958,
    foreignlessing: 1171.981,
    government: 4970.105,
    municipalities: 2349.308,
    relessings: 1012.01,
    mortgagelendings: 246.587,
    borrowings: 551.687,
    corporatelendings: 4895.22,
    selflendings: 2367.1,
    heldlendings: 402.926,
    savingslendings: 277.71,
    brokerlendings: 59.98,
    foreignborrowings: 0.55
  },
  "2004-04-01": {
    alldebt: 38311.91,
    domesticlessing: 25077.478,
    domesticrelessings: 12070.104,
    foreignlessing: 1164.328,
    government: 5018.844,
    municipalities: 2412.633,
    relessings: 1029.952,
    mortgagelendings: 264.527,
    borrowings: 527.717,
    corporatelendings: 4906.16,
    selflendings: 2429.78,
    heldlendings: 405.092,
    savingslendings: 293.55,
    brokerlendings: 60.6,
    foreignborrowings: 0.58
  },
  "2004-07-01": {
    alldebt: 39101.662,
    domesticlessing: 25587.728,
    domesticrelessings: 12305.466,
    foreignlessing: 1208.468,
    government: 5117.264,
    municipalities: 2446.68,
    relessings: 1063.194,
    mortgagelendings: 287.535,
    borrowings: 515.314,
    corporatelendings: 4944.32,
    selflendings: 2491.89,
    heldlendings: 427.132,
    savingslendings: 287.49,
    brokerlendings: 68.9,
    foreignborrowings: 0.65
  },
  "2004-10-01": {
    alldebt: 40182.423,
    domesticlessing: 26257.832,
    domesticrelessings: 12659.224,
    foreignlessing: 1265.367,
    government: 5250.883,
    municipalities: 2491.251,
    relessings: 1129.623,
    mortgagelendings: 340.111,
    borrowings: 531.641,
    corporatelendings: 5001.58,
    selflendings: 2556.25,
    heldlendings: 431.654,
    savingslendings: 316.91,
    brokerlendings: 62.24,
    foreignborrowings: 0.74
  },
  "2005-01-01": {
    alldebt: 40967.213,
    domesticlessing: 26792.808,
    domesticrelessings: 12893.359,
    foreignlessing: 1281.046,
    government: 5403.995,
    municipalities: 2545.679,
    relessings: 1139.914,
    mortgagelendings: 359.195,
    borrowings: 563.574,
    corporatelendings: 5050.49,
    selflendings: 2632.4,
    heldlendings: 451.748,
    savingslendings: 308.37,
    brokerlendings: 65.03,
    foreignborrowings: 0.64
  },
  "2005-04-01": {
    alldebt: 41839.492,
    domesticlessing: 27291.427,
    domesticrelessings: 13236.713,
    foreignlessing: 1311.352,
    government: 5356.972,
    municipalities: 2593.67,
    relessings: 1143.863,
    mortgagelendings: 383.14,
    borrowings: 616.426,
    corporatelendings: 5115.94,
    selflendings: 2720.11,
    heldlendings: 458.985,
    savingslendings: 330.38,
    brokerlendings: 63.72,
    foreignborrowings: 0.76
  },
  "2005-07-01": {
    alldebt: 42522.401,
    domesticlessing: 27881.3,
    domesticrelessings: 13310.534,
    foreignlessing: 1330.567,
    government: 5443.776,
    municipalities: 2620.027,
    relessings: 1034.618,
    mortgagelendings: 397.228,
    borrowings: 612.308,
    corporatelendings: 5168.15,
    selflendings: 2807.25,
    heldlendings: 468.759,
    savingslendings: 329.74,
    brokerlendings: 70.72,
    foreignborrowings: 0.78
  },
  "2005-10-01": {
    alldebt: 43611.008,
    domesticlessing: 28538.7,
    domesticrelessings: 13746.626,
    foreignlessing: 1325.682,
    government: 5584.775,
    municipalities: 2656.872,
    relessings: 1108.578,
    mortgagelendings: 395.482,
    borrowings: 638.842,
    corporatelendings: 5268.24,
    selflendings: 2898.03,
    heldlendings: 481.771,
    savingslendings: 334.18,
    brokerlendings: 62.36,
    foreignborrowings: 0.77
  },
  "2006-01-01": {
    alldebt: 44676.172,
    domesticlessing: 29221.65,
    domesticrelessings: 14087.087,
    foreignlessing: 1367.435,
    government: 5726.756,
    municipalities: 2662.53,
    relessings: 1099.322,
    mortgagelendings: 411.655,
    borrowings: 662.319,
    corporatelendings: 5355.06,
    selflendings: 2998,
    heldlendings: 496.688,
    savingslendings: 326.9,
    brokerlendings: 71.14,
    foreignborrowings: 0.76
  },
  "2006-04-01": {
    alldebt: 45735.025,
    domesticlessing: 29765.473,
    domesticrelessings: 14521.696,
    foreignlessing: 1447.856,
    government: 5656.53,
    municipalities: 2701.313,
    relessings: 1122.244,
    mortgagelendings: 419.491,
    borrowings: 670.764,
    corporatelendings: 5470.19,
    selflendings: 3105.24,
    heldlendings: 526.122,
    savingslendings: 332.67,
    brokerlendings: 72.77,
    foreignborrowings: 0.72
  },
  "2006-07-01": {
    alldebt: 46666.666,
    domesticlessing: 30324.461,
    domesticrelessings: 14775.467,
    foreignlessing: 1566.738,
    government: 5714.828,
    municipalities: 2720.582,
    relessings: 1119.521,
    mortgagelendings: 425.883,
    borrowings: 655.188,
    corporatelendings: 5502.41,
    selflendings: 3207.62,
    heldlendings: 534.431,
    savingslendings: 336.43,
    brokerlendings: 74.01,
    foreignborrowings: 0.7
  },
  "2006-10-01": {
    alldebt: 47778.039,
    domesticlessing: 30999.693,
    domesticrelessings: 15148.146,
    foreignlessing: 1630.2,
    government: 5803.923,
    municipalities: 2773.293,
    relessings: 1144.173,
    mortgagelendings: 410.987,
    borrowings: 667.038,
    corporatelendings: 5677.12,
    selflendings: 3313.43,
    heldlendings: 558.703,
    savingslendings: 270.51,
    brokerlendings: 68.79,
    foreignborrowings: 0.49
  },
  "2007-01-01": {
    alldebt: 48979.442,
    domesticlessing: 31593.058,
    domesticrelessings: 15689.992,
    foreignlessing: 1696.392,
    government: 5940.824,
    municipalities: 2833.586,
    relessings: 1234.196,
    mortgagelendings: 429.156,
    borrowings: 737.296,
    corporatelendings: 5786.31,
    selflendings: 3427.28,
    heldlendings: 580.011,
    savingslendings: 262.54,
    brokerlendings: 83.65,
    foreignborrowings: 0.34
  },
  "2007-04-01": {
    alldebt: 50118.62,
    domesticlessing: 32145.111,
    domesticrelessings: 16167.052,
    foreignlessing: 1806.457,
    government: 5819.983,
    municipalities: 2883.422,
    relessings: 1240.306,
    mortgagelendings: 427.135,
    borrowings: 744.676,
    corporatelendings: 5994.82,
    selflendings: 3542.75,
    heldlendings: 624.239,
    savingslendings: 265.64,
    brokerlendings: 93.62,
    foreignborrowings: 0.41
  },
  "2007-07-01": {
    alldebt: 51564.231,
    domesticlessing: 32839.232,
    domesticrelessings: 16814.425,
    foreignlessing: 1910.574,
    government: 5941.94,
    municipalities: 2899.834,
    relessings: 1280.367,
    mortgagelendings: 423.033,
    borrowings: 757.525,
    corporatelendings: 6175.23,
    selflendings: 3657.43,
    heldlendings: 663.643,
    savingslendings: 347.79,
    brokerlendings: 86.27,
    foreignborrowings: 0.29
  },
  "2007-10-01": {
    alldebt: 52803.656,
    domesticlessing: 33510.369,
    domesticrelessings: 17333.583,
    foreignlessing: 1959.704,
    government: 6074.315,
    municipalities: 2948.311,
    relessings: 1279.631,
    mortgagelendings: 421.212,
    borrowings: 818.178,
    corporatelendings: 6336.1,
    selflendings: 3775.12,
    heldlendings: 710.608,
    savingslendings: 361.31,
    brokerlendings: 64.8,
    foreignborrowings: 0.51
  },
  "2008-01-01": {
    alldebt: 53688.432,
    domesticlessing: 34027.153,
    domesticrelessings: 17680.584,
    foreignlessing: 1980.695,
    government: 6259.453,
    municipalities: 2972.667,
    relessings: 1301.438,
    mortgagelendings: 403.763,
    borrowings: 810.124,
    corporatelendings: 6451.33,
    selflendings: 3859.1,
    heldlendings: 741.27,
    savingslendings: 374.04,
    brokerlendings: 120.11,
    foreignborrowings: 0.37
  },
  "2008-04-01": {
    alldebt: 53982.065,
    domesticlessing: 34138.433,
    domesticrelessings: 17934.434,
    foreignlessing: 1909.198,
    government: 6217.605,
    municipalities: 2979.372,
    relessings: 1299.264,
    mortgagelendings: 397.531,
    borrowings: 811.467,
    corporatelendings: 6581.94,
    selflendings: 3946.65,
    heldlendings: 834.392,
    savingslendings: 366.23,
    brokerlendings: 85.94,
    foreignborrowings: 0.33
  },
  "2008-07-01": {
    alldebt: 54853.841,
    domesticlessing: 34944.543,
    domesticrelessings: 18228.685,
    foreignlessing: 1680.613,
    government: 6762.926,
    municipalities: 2975.314,
    relessings: 1264.678,
    mortgagelendings: 389.907,
    borrowings: 835.799,
    corporatelendings: 6641.02,
    selflendings: 4018.33,
    heldlendings: 783.049,
    savingslendings: 307.36,
    brokerlendings: 276.66,
    foreignborrowings: 0
  },
  "2008-10-01": {
    alldebt: 54933.02,
    domesticlessing: 35295.847,
    domesticrelessings: 18075.047,
    foreignlessing: 1562.126,
    government: 7376.817,
    municipalities: 2989.629,
    relessings: 1200.265,
    mortgagelendings: 367.381,
    borrowings: 1320.543,
    corporatelendings: 6592.97,
    selflendings: 4093.99,
    heldlendings: 807.914,
    savingslendings: 281.13,
    brokerlendings: 142.55,
    foreignborrowings: 0
  },
  "2009-01-01": {
    alldebt: 54659.627,
    domesticlessing: 35563.147,
    domesticrelessings: 17511.71,
    foreignlessing: 1584.77,
    government: 7820.167,
    municipalities: 3018.667,
    relessings: 1149.211,
    mortgagelendings: 351.443,
    borrowings: 785.561,
    corporatelendings: 6529.98,
    selflendings: 4078.8,
    heldlendings: 1262.082,
    savingslendings: 254.31,
    brokerlendings: 102.45,
    foreignborrowings: 0
  },
  "2009-04-01": {
    alldebt: 54534.798,
    domesticlessing: 35833.397,
    domesticrelessings: 16970.062,
    foreignlessing: 1731.339,
    government: 8162.894,
    municipalities: 3051.77,
    relessings: 1103.603,
    mortgagelendings: 346.878,
    borrowings: 596.707,
    corporatelendings: 6479.72,
    selflendings: 4048.55,
    heldlendings: 1261.777,
    savingslendings: 191.2,
    brokerlendings: 102.21,
    foreignborrowings: 0
  },
  "2009-07-01": {
    alldebt: 54535.487,
    domesticlessing: 36090.743,
    domesticrelessings: 16553.349,
    foreignlessing: 1891.395,
    government: 8557.508,
    municipalities: 3072.278,
    relessings: 1074.944,
    mortgagelendings: 339.99,
    borrowings: 462.149,
    corporatelendings: 6344.71,
    selflendings: 4016.18,
    heldlendings: 1281.722,
    savingslendings: 181.05,
    brokerlendings: 104.05,
    foreignborrowings: 0
  },
  "2009-10-01": {
    alldebt: 54381.936,
    domesticlessing: 36264.804,
    domesticrelessings: 16148.233,
    foreignlessing: 1968.899,
    government: 8882.645,
    municipalities: 3132.341,
    relessings: 1044.063,
    mortgagelendings: 339.828,
    borrowings: 408.609,
    corporatelendings: 6150.63,
    selflendings: 3985.38,
    heldlendings: 1253.704,
    savingslendings: 141.35,
    brokerlendings: 92.85,
    foreignborrowings: 0
  },
  "2010-01-01": {
    alldebt: 54188.864,
    domesticlessing: 36618.779,
    domesticrelessings: 15520.777,
    foreignlessing: 2049.308,
    government: 9343.729,
    municipalities: 3166.081,
    relessings: 1048.106,
    mortgagelendings: 345.78,
    borrowings: 366.23,
    corporatelendings: 6119.38,
    selflendings: 3972.45,
    heldlendings: 1239.469,
    savingslendings: 132,
    brokerlendings: 92.25,
    foreignborrowings: 0
  },
  "2010-04-01": {
    alldebt: 54149.945,
    domesticlessing: 36835.416,
    domesticrelessings: 15274.283,
    foreignlessing: 2040.246,
    government: 9692.462,
    municipalities: 3162.617,
    relessings: 1017.659,
    mortgagelendings: 336.487,
    borrowings: 336.515,
    corporatelendings: 6035.94,
    selflendings: 3965.31,
    heldlendings: 1205.435,
    savingslendings: 127.38,
    brokerlendings: 100.91,
    foreignborrowings: 0
  },
  "2010-07-01": {
    alldebt: 54510.899,
    domesticlessing: 37236.842,
    domesticrelessings: 15053.655,
    foreignlessing: 2220.402,
    government: 10099.248,
    municipalities: 3170.494,
    relessings: 974.852,
    mortgagelendings: 342.743,
    borrowings: 339.145,
    corporatelendings: 6085.95,
    selflendings: 3962.2,
    heldlendings: 1268.103,
    savingslendings: 122.94,
    brokerlendings: 96.12,
    foreignborrowings: 0
  },
  "2010-10-01": {
    alldebt: 55043.56,
    domesticlessing: 37682.5,
    domesticrelessings: 15113.349,
    foreignlessing: 2247.711,
    government: 10528.562,
    municipalities: 3227.655,
    relessings: 1280.724,
    mortgagelendings: 339.884,
    borrowings: 295.427,
    corporatelendings: 6013.04,
    selflendings: 3950.86,
    heldlendings: 1237.235,
    savingslendings: 117.58,
    brokerlendings: 129.72,
    foreignborrowings: 0
  },
  "2011-01-01": {
    alldebt: 55280.669,
    domesticlessing: 37884.364,
    domesticrelessings: 15055.581,
    foreignlessing: 2340.724,
    government: 10774.648,
    municipalities: 3209.705,
    relessings: 1267.943,
    mortgagelendings: 351.016,
    borrowings: 270.985,
    corporatelendings: 6053.69,
    selflendings: 3947.44,
    heldlendings: 1258.123,
    savingslendings: 109.77,
    brokerlendings: 128.24,
    foreignborrowings: 0
  },
  "2011-04-01": {
    alldebt: 55365.537,
    domesticlessing: 37978.955,
    domesticrelessings: 14899.119,
    foreignlessing: 2487.463,
    government: 10885.275,
    municipalities: 3180.174,
    relessings: 1252.565,
    mortgagelendings: 356.271,
    borrowings: 246.145,
    corporatelendings: 6126.01,
    selflendings: 3944.67,
    heldlendings: 1269.712,
    savingslendings: 104.7,
    brokerlendings: 110.07,
    foreignborrowings: 0
  },
  "2011-07-01": {
    alldebt: 55589.41,
    domesticlessing: 38393.424,
    domesticrelessings: 14735.668,
    foreignlessing: 2460.318,
    government: 11276.944,
    municipalities: 3178.396,
    relessings: 1264.137,
    mortgagelendings: 361.386,
    borrowings: 220.526,
    corporatelendings: 6217.41,
    selflendings: 3942.02,
    heldlendings: 1248.814,
    savingslendings: 102.17,
    brokerlendings: 99.21,
    foreignborrowings: 0
  },
  "2011-10-01": {
    alldebt: 55931.685,
    domesticlessing: 38895.25,
    domesticrelessings: 14566.646,
    foreignlessing: 2469.789,
    government: 11667.25,
    municipalities: 3196.367,
    relessings: 1261.866,
    mortgagelendings: 365.489,
    borrowings: 174.878,
    corporatelendings: 6307.63,
    selflendings: 3946.61,
    heldlendings: 1220.766,
    savingslendings: 99.61,
    brokerlendings: 91.83,
    foreignborrowings: 0
  },
  "2012-01-01": {
    alldebt: 56322.79,
    domesticlessing: 39314.067,
    domesticrelessings: 14491.688,
    foreignlessing: 2517.035,
    government: 12049.105,
    municipalities: 3202.454,
    relessings: 1250.454,
    mortgagelendings: 372.024,
    borrowings: 158.802,
    corporatelendings: 6364.96,
    selflendings: 3972.82,
    heldlendings: 1301.79,
    savingslendings: 80.77,
    brokerlendings: 98.02,
    foreignborrowings: 0
  },
  "2012-04-01": {
    alldebt: 56470.313,
    domesticlessing: 39597.276,
    domesticrelessings: 14319.53,
    foreignlessing: 2553.507,
    government: 12253.605,
    municipalities: 3217.994,
    relessings: 1233.631,
    mortgagelendings: 379.379,
    borrowings: 131.375,
    corporatelendings: 6431.18,
    selflendings: 4005.9,
    heldlendings: 1211.746,
    savingslendings: 78.45,
    brokerlendings: 102.14,
    foreignborrowings: 0
  },
  "2012-07-01": {
    alldebt: 57196.294,
    domesticlessing: 40044.107,
    domesticrelessings: 14429.298,
    foreignlessing: 2722.889,
    government: 12503.109,
    municipalities: 3212.019,
    relessings: 1244.032,
    mortgagelendings: 384.959,
    borrowings: 89.815,
    corporatelendings: 6565.7,
    selflendings: 4036.38,
    heldlendings: 1426.523,
    savingslendings: 80.87,
    brokerlendings: 85.83,
    foreignborrowings: 0
  },
  "2012-10-01": {
    alldebt: 57672.132,
    domesticlessing: 40547.891,
    domesticrelessings: 14296.35,
    foreignlessing: 2827.891,
    government: 12847.828,
    municipalities: 3192.961,
    relessings: 1205.119,
    mortgagelendings: 400.991,
    borrowings: 77.654,
    corporatelendings: 6715.22,
    selflendings: 4068.21,
    heldlendings: 1416.399,
    savingslendings: 77.9,
    brokerlendings: 90.16,
    foreignborrowings: 0
  },
  "2013-01-01": {
    alldebt: 58117.012,
    domesticlessing: 40925.189,
    domesticrelessings: 14298.322,
    foreignlessing: 2893.501,
    government: 13195.652,
    municipalities: 3200.651,
    relessings: 1192.978,
    mortgagelendings: 402.989,
    borrowings: 103.612,
    corporatelendings: 6796.34,
    selflendings: 4064.33,
    heldlendings: 1410.273,
    savingslendings: 73.69,
    brokerlendings: 89.11,
    foreignborrowings: 0
  },
  "2013-04-01": {
    alldebt: 58145.04,
    domesticlessing: 40984.421,
    domesticrelessings: 14306.386,
    foreignlessing: 2854.233,
    government: 13104.008,
    municipalities: 3182.606,
    relessings: 1196.038,
    mortgagelendings: 555.927,
    borrowings: 108.339,
    corporatelendings: 6913.45,
    selflendings: 4101.95,
    heldlendings: 1372.858,
    savingslendings: 79.8,
    brokerlendings: 103.07,
    foreignborrowings: 0
  },
  "2013-07-01": {
    alldebt: 58553.673,
    domesticlessing: 41373.96,
    domesticrelessings: 14240.989,
    foreignlessing: 2938.724,
    government: 13257.919,
    municipalities: 3157.059,
    relessings: 1206.636,
    mortgagelendings: 559.98,
    borrowings: 86.857,
    corporatelendings: 7054.63,
    selflendings: 4138.57,
    heldlendings: 1363.443,
    savingslendings: 80.16,
    brokerlendings: 96.12,
    foreignborrowings: 0
  },
  "2013-10-01": {
    alldebt: 59582.085,
    domesticlessing: 42144.869,
    domesticrelessings: 14463.722,
    foreignlessing: 2973.494,
    government: 13705.127,
    municipalities: 3187.338,
    relessings: 1209.319,
    mortgagelendings: 596.585,
    borrowings: 111.402,
    corporatelendings: 7120.69,
    selflendings: 4182.77,
    heldlendings: 1402.676,
    savingslendings: 72.5,
    brokerlendings: 112.4,
    foreignborrowings: 0
  },
  "2014-01-01": {
    alldebt: 60037.286,
    domesticlessing: 42559.049,
    domesticrelessings: 14416.128,
    foreignlessing: 3062.109,
    government: 13954.551,
    municipalities: 3191.145,
    relessings: 1182.086,
    mortgagelendings: 631.142,
    borrowings: 130.403,
    corporatelendings: 7270.41,
    selflendings: 4198.56,
    heldlendings: 1405.895,
    savingslendings: "",
    brokerlendings: 122.73,
    foreignborrowings: 0
  },
  "2014-04-01": {
    alldebt: 60526.828,
    domesticlessing: 42761.866,
    domesticrelessings: 14502.05,
    foreignlessing: 3262.912,
    government: 13911.001,
    municipalities: 3177.614,
    relessings: 1185.069,
    mortgagelendings: 643.684,
    borrowings: 122.318,
    corporatelendings: 7354.51,
    selflendings: 4257.64,
    heldlendings: 1406.887,
    savingslendings: "",
    brokerlendings: 113.38,
    foreignborrowings: 0
  },
  "2014-07-01": {
    alldebt: 60959.017,
    domesticlessing: 43095.005,
    domesticrelessings: 14625.452,
    foreignlessing: 3238.56,
    government: 14141.265,
    municipalities: 3104.449,
    relessings: 1198.992,
    mortgagelendings: 655.817,
    borrowings: 150.168,
    corporatelendings: 7483.32,
    selflendings: 4301.61,
    heldlendings: 1395.637,
    savingslendings: "",
    brokerlendings: 123.9,
    foreignborrowings: 0
  },
  "2014-10-01": {
    alldebt: 61733.491,
    domesticlessing: 43695.387,
    domesticrelessings: 14753.272,
    foreignlessing: 3284.832,
    government: 14441.054,
    municipalities: 3114.302,
    relessings: 1221.542,
    mortgagelendings: 673.626,
    borrowings: 138.582,
    corporatelendings: 7610.32,
    selflendings: 4399.93,
    heldlendings: 1394.348,
    savingslendings: "",
    brokerlendings: 123.88,
    foreignborrowings: 0
  },
  "2015-01-01": {
    alldebt: 61951.446,
    domesticlessing: 43978.133,
    domesticrelessings: 14657.048,
    foreignlessing: 3316.265,
    government: 14519.909,
    municipalities: 3137.505,
    relessings: 1224.311,
    mortgagelendings: 683.276,
    borrowings: 144.605,
    corporatelendings: 7762.98,
    selflendings: 4460.25,
    heldlendings: 1380.515,
    savingslendings: "",
    brokerlendings: 107.41,
    foreignborrowings: 0
  },
  "2015-04-01": {
    alldebt: 62443.894,
    domesticlessing: 44378.197,
    domesticrelessings: 14765.19,
    foreignlessing: 3300.507,
    government: 14505.616,
    municipalities: 3145.388,
    relessings: 1212.386,
    mortgagelendings: 692.538,
    borrowings: 166.924,
    corporatelendings: 7943.91,
    selflendings: "",
    heldlendings: 1380.215,
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2015-07-01": {
    alldebt: 62730.243,
    domesticlessing: 44677.468,
    domesticrelessings: 14807.385,
    foreignlessing: 3245.39,
    government: 14600.73,
    municipalities: 3136.063,
    relessings: 1219.7,
    mortgagelendings: 703.948,
    borrowings: 198.536,
    corporatelendings: "",
    selflendings: "",
    heldlendings: 1360.35,
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2015-10-01": {
    alldebt: 63345.314,
    domesticlessing: 45416.538,
    domesticrelessings: 14697.403,
    foreignlessing: 3231.373,
    government: 15165.626,
    municipalities: 3128.527,
    relessings: 1112.891,
    mortgagelendings: 695.11,
    borrowings: 221.213,
    corporatelendings: "",
    selflendings: "",
    heldlendings: 1212.783,
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2016-01-01": {
    alldebt: 64028.415,
    domesticlessing: 45925.74,
    domesticrelessings: 14802.619,
    foreignlessing: 3300.056,
    government: 15401.755,
    municipalities: 3145.449,
    relessings: 1097.476,
    mortgagelendings: 715.721,
    borrowings: 263.354,
    corporatelendings: "",
    selflendings: "",
    heldlendings: 1247.88,
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2016-04-01": {
    alldebt: 64592.525,
    domesticlessing: 46240.347,
    domesticrelessings: 14985.574,
    foreignlessing: 3366.604,
    government: 15409.707,
    municipalities: 3166.537,
    relessings: 1074.242,
    mortgagelendings: 712.741,
    borrowings: 258.403,
    corporatelendings: "",
    selflendings: "",
    heldlendings: 1263.893,
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2016-07-01": {
    alldebt: 65337.258,
    domesticlessing: 46866.004,
    domesticrelessings: 15094.681,
    foreignlessing: 3376.573,
    government: 15668.297,
    municipalities: 3159.219,
    relessings: 1059.086,
    mortgagelendings: 706.468,
    borrowings: 232.15,
    corporatelendings: "",
    selflendings: "",
    heldlendings: 1374.341,
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2016-10-01": {
    alldebt: 65764.6,
    domesticlessing: 47357.369,
    domesticrelessings: 15095.484,
    foreignlessing: 3311.747,
    government: 16008.291,
    municipalities: 3159.37,
    relessings: 1045.778,
    mortgagelendings: 701.594,
    borrowings: 232.325,
    corporatelendings: "",
    selflendings: "",
    heldlendings: 1344.868,
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2017-01-01": {
    alldebt: 66198.95,
    domesticlessing: 47652.738,
    domesticrelessings: 15090.886,
    foreignlessing: 3455.326,
    government: 16005.625,
    municipalities: 3146.739,
    relessings: 1016.757,
    mortgagelendings: 704.761,
    borrowings: 256.685,
    corporatelendings: "",
    selflendings: "",
    heldlendings: 1359.38,
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2017-04-01": {
    alldebt: 66847.421,
    domesticlessing: 47999.36,
    domesticrelessings: 15282.976,
    foreignlessing: 3565.085,
    government: 15964.128,
    municipalities: 3143.888,
    relessings: 1005.351,
    mortgagelendings: 705.623,
    borrowings: 245.412,
    corporatelendings: "",
    selflendings: "",
    heldlendings: 1411.337,
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2017-07-01": {
    alldebt: 67685.906,
    domesticlessing: 48590.975,
    domesticrelessings: 15388.364,
    foreignlessing: 3706.567,
    government: 16267.151,
    municipalities: 3128.385,
    relessings: 1012.123,
    mortgagelendings: 705.754,
    borrowings: 232.619,
    corporatelendings: "",
    selflendings: "",
    heldlendings: 1459.593,
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2017-10-01": {
    alldebt: 68772.939,
    domesticlessing: 49460.142,
    domesticrelessings: 15425.094,
    foreignlessing: 3887.703,
    government: 16606.939,
    municipalities: 3158.998,
    relessings: 983.228,
    mortgagelendings: 727.143,
    borrowings: 255.048,
    corporatelendings: "",
    selflendings: "",
    heldlendings: 1448.675,
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2018-01-01": {
    alldebt: 69490.04,
    domesticlessing: 50117.992,
    domesticrelessings: 15402.083,
    foreignlessing: 3969.965,
    government: 17070.617,
    municipalities: 3140.359,
    relessings: 982.784,
    mortgagelendings: 725.378,
    borrowings: 269.578,
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2018-04-01": {
    alldebt: 70200.867,
    domesticlessing: 50737.063,
    domesticrelessings: 15526.939,
    foreignlessing: 3936.865,
    government: 17114.196,
    municipalities: 3139.526,
    relessings: 1003.061,
    mortgagelendings: 718.37,
    borrowings: 267.676,
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2018-07-01": {
    alldebt: 70943.272,
    domesticlessing: 51370.779,
    domesticrelessings: 15580.241,
    foreignlessing: 3992.252,
    government: 17440.621,
    municipalities: 3131.301,
    relessings: 1022.82,
    mortgagelendings: 722.181,
    borrowings: 260.026,
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2018-10-01": {
    alldebt: 71842.563,
    domesticlessing: 52116.927,
    domesticrelessings: 15723.434,
    foreignlessing: 4002.202,
    government: 17865.026,
    municipalities: 3120.282,
    relessings: 1026.865,
    mortgagelendings: 745.25,
    borrowings: 265.157,
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2019-01-01": {
    alldebt: 72431.386,
    domesticlessing: 52653.695,
    domesticrelessings: 15714.501,
    foreignlessing: 4063.19,
    government: 18143.779,
    municipalities: 3103.959,
    relessings: 1009.948,
    mortgagelendings: 749.665,
    borrowings: 272.585,
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2019-04-01": {
    alldebt: 73062.725,
    domesticlessing: 52898.011,
    domesticrelessings: 15911.008,
    foreignlessing: 4253.706,
    government: 18053.021,
    municipalities: 3087.108,
    relessings: 994.449,
    mortgagelendings: 764.333,
    borrowings: 269.567,
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2019-07-01": {
    alldebt: 74198.574,
    domesticlessing: 53814.317,
    domesticrelessings: 16001.006,
    foreignlessing: 4383.251,
    government: 18616.233,
    municipalities: 3083.823,
    relessings: 1003.073,
    mortgagelendings: 781.346,
    borrowings: 266.921,
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2019-10-01": {
    alldebt: 75077.298,
    domesticlessing: 54541.523,
    domesticrelessings: 16045.103,
    foreignlessing: 4490.672,
    government: 19039.898,
    municipalities: 3119.025,
    relessings: 989.02,
    mortgagelendings: 802.526,
    borrowings: 261.943,
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2020-01-01": {
    alldebt: 77352.93,
    domesticlessing: 55928.482,
    domesticrelessings: 17181.03,
    foreignlessing: 4243.418,
    government: 19539.608,
    municipalities: 3125.952,
    relessings: 1019.934,
    mortgagelendings: 829.086,
    borrowings: 366.373,
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2020-04-01": {
    alldebt: 80695.191,
    domesticlessing: 59444.273,
    domesticrelessings: 16784.924,
    foreignlessing: 4465.994,
    government: 22391.682,
    municipalities: 3147.773,
    relessings: 1016.079,
    mortgagelendings: 831.851,
    borrowings: 341.893,
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2020-07-01": {
    alldebt: 81499.462,
    domesticlessing: 60228.003,
    domesticrelessings: 16711.047,
    foreignlessing: 4560.412,
    government: 22920.579,
    municipalities: 3183.625,
    relessings: 991.642,
    mortgagelendings: 827.545,
    borrowings: 338.478,
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2020-10-01": {
    alldebt: 83030.563,
    domesticlessing: 61270.383,
    domesticrelessings: 16996.22,
    foreignlessing: 4763.96,
    government: 23621.06,
    municipalities: 3210.373,
    relessings: 993.626,
    mortgagelendings: 836.701,
    borrowings: 358.911,
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2021-01-01": {
    alldebt: 84121.844,
    domesticlessing: 62096.548,
    domesticrelessings: 17219.606,
    foreignlessing: 4805.69,
    government: 23962.809,
    municipalities: 3235.445,
    relessings: 970.448,
    mortgagelendings: 837.847,
    borrowings: 460.803,
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2021-04-01": {
    alldebt: 85330.524,
    domesticlessing: 62844.12,
    domesticrelessings: 17568.305,
    foreignlessing: 4918.099,
    government: 24321.359,
    municipalities: 3253.657,
    relessings: 1015.182,
    mortgagelendings: 859.707,
    borrowings: 443.053,
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2021-07-01": {
    alldebt: 86326.585,
    domesticlessing: 63462.268,
    domesticrelessings: 17687.154,
    foreignlessing: 5177.163,
    government: 24449.968,
    municipalities: 3261.747,
    relessings: 970.77,
    mortgagelendings: 849.235,
    borrowings: 443.618,
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  },
  "2021-10-01": {
    alldebt: 88308.437,
    domesticlessing: 65059.542,
    domesticrelessings: 17983.162,
    foreignlessing: 5265.733,
    government: 25314.346,
    municipalities: 3271.343,
    relessings: 964.754,
    mortgagelendings: 869.617,
    borrowings: 439.051,
    corporatelendings: "",
    selflendings: "",
    heldlendings: "",
    savingslendings: "",
    brokerlendings: "",
    foreignborrowings: ""
  }
};
const operating = {
  "1947-01-01": {
    gdp: 243.164,
    profits: 33.466,
    government: 40.902
  },
  "1947-04-01": {
    gdp: 245.968,
    profits: 31.668,
    government: 40.346
  },
  "1947-07-01": {
    gdp: 249.585,
    profits: 31.288,
    government: 42.007
  },
  "1947-10-01": {
    gdp: 259.745,
    profits: 34.123,
    government: 38.149
  },
  "1948-01-01": {
    gdp: 265.742,
    profits: 35.747,
    government: 38.915
  },
  "1948-04-01": {
    gdp: 272.567,
    profits: 37.589,
    government: 39.904
  },
  "1948-07-01": {
    gdp: 279.196,
    profits: 36.959,
    government: 42.739
  },
  "1948-10-01": {
    gdp: 280.366,
    profits: 35.712,
    government: 44.051
  },
  "1949-01-01": {
    gdp: 275.034,
    profits: 32.519,
    government: 45.908
  },
  "1949-04-01": {
    gdp: 271.351,
    profits: 28.692,
    government: 47.016
  },
  "1949-07-01": {
    gdp: 272.889,
    profits: 29.752,
    government: 46.824
  },
  "1949-10-01": {
    gdp: 270.627,
    profits: 29.205,
    government: 45.583
  },
  "1950-01-01": {
    gdp: 280.828,
    profits: 33.477,
    government: 52.902
  },
  "1950-04-01": {
    gdp: 290.383,
    profits: 39.923,
    government: 46.098
  },
  "1950-07-01": {
    gdp: 308.153,
    profits: 48.501,
    government: 42.215
  },
  "1950-10-01": {
    gdp: 319.945,
    profits: 53.221,
    government: 46.464
  },
  "1951-01-01": {
    gdp: 336,
    profits: 52.393,
    government: 51.8
  },
  "1951-04-01": {
    gdp: 344.09,
    profits: 44.593,
    government: 56.56
  },
  "1951-07-01": {
    gdp: 351.385,
    profits: 40.666,
    government: 61.393
  },
  "1951-10-01": {
    gdp: 356.178,
    profits: 43.868,
    government: 63.905
  },
  "1952-01-01": {
    gdp: 359.82,
    profits: 41.59,
    government: 63.546
  },
  "1952-04-01": {
    gdp: 361.03,
    profits: 39.667,
    government: 67.23
  },
  "1952-07-01": {
    gdp: 367.701,
    profits: 39.898,
    government: 70.056
  },
  "1952-10-01": {
    gdp: 380.812,
    profits: 43.972,
    government: 70.885
  },
  "1953-01-01": {
    gdp: 387.98,
    profits: 45.893,
    government: 71.596
  },
  "1953-04-01": {
    gdp: 391.749,
    profits: 46.034,
    government: 73.781
  },
  "1953-07-01": {
    gdp: 391.171,
    profits: 45.077,
    government: 72.127
  },
  "1953-10-01": {
    gdp: 385.97,
    profits: 35.591,
    government: 73.561
  },
  "1954-01-01": {
    gdp: 385.345,
    profits: 38.019,
    government: 71.375
  },
  "1954-04-01": {
    gdp: 386.121,
    profits: 39.141,
    government: 70.056
  },
  "1954-07-01": {
    gdp: 390.996,
    profits: 41.473,
    government: 69.904
  },
  "1954-10-01": {
    gdp: 399.734,
    profits: 44.539,
    government: 70.683
  },
  "1955-01-01": {
    gdp: 413.073,
    profits: 49.715,
    government: 71.276
  },
  "1955-04-01": {
    gdp: 421.532,
    profits: 50.444,
    government: 70.836
  },
  "1955-07-01": {
    gdp: 430.221,
    profits: 51.731,
    government: 72.676
  },
  "1955-10-01": {
    gdp: 437.092,
    profits: 53.871,
    government: 71.777
  },
  "1956-01-01": {
    gdp: 439.746,
    profits: 52.285,
    government: 71.909
  },
  "1956-04-01": {
    gdp: 446.01,
    profits: 53.542,
    government: 74.834
  },
  "1956-07-01": {
    gdp: 451.191,
    profits: 50.939,
    government: 74.181
  },
  "1956-10-01": {
    gdp: 460.463,
    profits: 53.735,
    government: 76.822
  },
  "1957-01-01": {
    gdp: 469.779,
    profits: 54.518,
    government: 80.058
  },
  "1957-04-01": {
    gdp: 472.025,
    profits: 52.239,
    government: 81.446
  },
  "1957-07-01": {
    gdp: 479.49,
    profits: 51.138,
    government: 81.451
  },
  "1957-10-01": {
    gdp: 474.864,
    profits: 46.878,
    government: 84.813
  },
  "1958-01-01": {
    gdp: 467.54,
    profits: 40.564,
    government: 83.792
  },
  "1958-04-01": {
    gdp: 471.978,
    profits: 41.276,
    government: 88.476
  },
  "1958-07-01": {
    gdp: 485.841,
    profits: 45.972,
    government: 89.193
  },
  "1958-10-01": {
    gdp: 499.555,
    profits: 52.306,
    government: 90.999
  },
  "1959-01-01": {
    gdp: 510.33,
    profits: 55.502,
    government: 89.118
  },
  "1959-04-01": {
    gdp: 522.653,
    profits: 60.398,
    government: 90.132
  },
  "1959-07-01": {
    gdp: 525.034,
    profits: 54.549,
    government: 90.742
  },
  "1959-10-01": {
    gdp: 528.6,
    profits: 53.368,
    government: 92.047
  },
  "1960-01-01": {
    gdp: 542.648,
    profits: 58.778,
    government: 90.543
  },
  "1960-04-01": {
    gdp: 541.08,
    profits: 54.428,
    government: 92.628
  },
  "1960-07-01": {
    gdp: 545.604,
    profits: 52.155,
    government: 93.723
  },
  "1960-10-01": {
    gdp: 540.197,
    profits: 50.05,
    government: 96.698
  },
  "1961-01-01": {
    gdp: 545.018,
    profits: 49.179,
    government: 96.51
  },
  "1961-04-01": {
    gdp: 555.545,
    profits: 52.095,
    government: 100.155
  },
  "1961-07-01": {
    gdp: 567.664,
    profits: 55.033,
    government: 100.502
  },
  "1961-10-01": {
    gdp: 580.612,
    profits: 59.311,
    government: 102.076
  },
  "1962-01-01": {
    gdp: 594.013,
    profits: 58.34,
    government: 105.952
  },
  "1962-04-01": {
    gdp: 600.366,
    profits: 58.026,
    government: 107.795
  },
  "1962-07-01": {
    gdp: 609.027,
    profits: 59.757,
    government: 109.32
  },
  "1962-10-01": {
    gdp: 612.28,
    profits: 60.694,
    government: 111.161
  },
  "1963-01-01": {
    gdp: 621.672,
    profits: 60.428,
    government: 112.116
  },
  "1963-04-01": {
    gdp: 629.752,
    profits: 64.064,
    government: 112.048
  },
  "1963-07-01": {
    gdp: 644.444,
    profits: 65.769,
    government: 113.773
  },
  "1963-10-01": {
    gdp: 653.938,
    profits: 67.404,
    government: 116.055
  },
  "1964-01-01": {
    gdp: 669.822,
    profits: 70.805,
    government: 117.829
  },
  "1964-04-01": {
    gdp: 678.674,
    profits: 70.797,
    government: 118.785
  },
  "1964-07-01": {
    gdp: 692.031,
    profits: 72.492,
    government: 118.123
  },
  "1964-10-01": {
    gdp: 697.319,
    profits: 71.69,
    government: 118.105
  },
  "1965-01-01": {
    gdp: 717.79,
    profits: 79.043,
    government: 119.954
  },
  "1965-04-01": {
    gdp: 730.191,
    profits: 81.806,
    government: 122.907
  },
  "1965-07-01": {
    gdp: 749.323,
    profits: 83.277,
    government: 128.665
  },
  "1965-10-01": {
    gdp: 771.857,
    profits: 88.313,
    government: 132.243
  },
  "1966-01-01": {
    gdp: 795.734,
    profits: 90.826,
    government: 136.056
  },
  "1966-04-01": {
    gdp: 804.981,
    profits: 91.204,
    government: 142.395
  },
  "1966-07-01": {
    gdp: 819.638,
    profits: 90.553,
    government: 146.957
  },
  "1966-10-01": {
    gdp: 833.302,
    profits: 88.953,
    government: 152.091
  },
  "1967-01-01": {
    gdp: 844.17,
    profits: 85.784,
    government: 161.919
  },
  "1967-04-01": {
    gdp: 848.983,
    profits: 85.602,
    government: 164.163
  },
  "1967-07-01": {
    gdp: 865.233,
    profits: 87.148,
    government: 166.058
  },
  "1967-10-01": {
    gdp: 881.439,
    profits: 91.881,
    government: 170.992
  },
  "1968-01-01": {
    gdp: 909.387,
    profits: 95.276,
    government: 177.285
  },
  "1968-04-01": {
    gdp: 934.344,
    profits: 97.59,
    government: 183.665
  },
  "1968-07-01": {
    gdp: 950.825,
    profits: 98.14,
    government: 186.229
  },
  "1968-10-01": {
    gdp: 968.03,
    profits: 100.619,
    government: 190.202
  },
  "1969-01-01": {
    gdp: 993.337,
    profits: 100.694,
    government: 188.097
  },
  "1969-04-01": {
    gdp: 1009.02,
    profits: 97.803,
    government: 194.972
  },
  "1969-07-01": {
    gdp: 1029.956,
    profits: 94.702,
    government: 199.772
  },
  "1969-10-01": {
    gdp: 1038.147,
    profits: 93.411,
    government: 205.097
  },
  "1970-01-01": {
    gdp: 1051.2,
    profits: 86.399,
    government: 208.693
  },
  "1970-04-01": {
    gdp: 1067.375,
    profits: 86.35,
    government: 220.11
  },
  "1970-07-01": {
    gdp: 1086.059,
    profits: 88.276,
    government: 223.37
  },
  "1970-10-01": {
    gdp: 1088.608,
    profits: 84.026,
    government: 227.603
  },
  "1971-01-01": {
    gdp: 1135.156,
    profits: 94.536,
    government: 233.12
  },
  "1971-04-01": {
    gdp: 1156.271,
    profits: 97.736,
    government: 242.896
  },
  "1971-07-01": {
    gdp: 1177.675,
    profits: 101.096,
    government: 242.609
  },
  "1971-10-01": {
    gdp: 1190.297,
    profits: 103.715,
    government: 247.782
  },
  "1972-01-01": {
    gdp: 1230.609,
    profits: 109.925,
    government: 258.846
  },
  "1972-04-01": {
    gdp: 1266.369,
    profits: 111.285,
    government: 267.56
  },
  "1972-07-01": {
    gdp: 1290.566,
    profits: 115.772,
    government: 260.264
  },
  "1972-10-01": {
    gdp: 1328.904,
    profits: 126.556,
    government: 285.409
  },
  "1973-01-01": {
    gdp: 1377.49,
    profits: 141.563,
    government: 283.042
  },
  "1973-04-01": {
    gdp: 1413.887,
    profits: 145.069,
    government: 287.36
  },
  "1973-07-01": {
    gdp: 1433.838,
    profits: 145.145,
    government: 286.911
  },
  "1973-10-01": {
    gdp: 1476.289,
    profits: 152.745,
    government: 292.909
  },
  "1974-01-01": {
    gdp: 1491.209,
    profits: 157.26,
    government: 302.072
  },
  "1974-04-01": {
    gdp: 1530.056,
    profits: 162.207,
    government: 314.483
  },
  "1974-07-01": {
    gdp: 1560.026,
    profits: 172.572,
    government: 324.454
  },
  "1974-10-01": {
    gdp: 1599.679,
    profits: 154.068,
    government: 338.388
  },
  "1975-01-01": {
    gdp: 1616.116,
    profits: 133.019,
    government: 354.024
  },
  "1975-04-01": {
    gdp: 1651.853,
    profits: 140.826,
    government: 377.665
  },
  "1975-07-01": {
    gdp: 1709.82,
    profits: 167.855,
    government: 379.106
  },
  "1975-10-01": {
    gdp: 1761.831,
    profits: 177.391,
    government: 388.241
  },
  "1976-01-01": {
    gdp: 1820.487,
    profits: 193.546,
    government: 393.991
  },
  "1976-04-01": {
    gdp: 1852.332,
    profits: 195.931,
    government: 395.709
  },
  "1976-07-01": {
    gdp: 1886.558,
    profits: 197.82,
    government: 407.495
  },
  "1976-10-01": {
    gdp: 1934.273,
    profits: 197.559,
    government: 416.741
  },
  "1977-01-01": {
    gdp: 1988.648,
    profits: 212.751,
    government: 420.834
  },
  "1977-04-01": {
    gdp: 2055.909,
    profits: 227.171,
    government: 428.835
  },
  "1977-07-01": {
    gdp: 2118.473,
    profits: 235.983,
    government: 442.98
  },
  "1977-10-01": {
    gdp: 2164.27,
    profits: 238.067,
    government: 456.618
  },
  "1978-01-01": {
    gdp: 2202.76,
    profits: 238.131,
    government: 468.33
  },
  "1978-04-01": {
    gdp: 2331.633,
    profits: 270.75,
    government: 478.557
  },
  "1978-07-01": {
    gdp: 2395.053,
    profits: 277.887,
    government: 492.031
  },
  "1978-10-01": {
    gdp: 2476.949,
    profits: 294.871,
    government: 504.542
  },
  "1979-01-01": {
    gdp: 2526.61,
    profits: 294.422,
    government: 511.805
  },
  "1979-04-01": {
    gdp: 2591.247,
    profits: 303.013,
    government: 522.934
  },
  "1979-07-01": {
    gdp: 2667.565,
    profits: 306.828,
    government: 543.29
  },
  "1979-10-01": {
    gdp: 2723.883,
    profits: 300.304,
    government: 559.516
  },
  "1980-01-01": {
    gdp: 2789.842,
    profits: 308.632,
    government: 583.861
  },
  "1980-04-01": {
    gdp: 2797.352,
    profits: 259.646,
    government: 608.694
  },
  "1980-07-01": {
    gdp: 2856.483,
    profits: 274.406,
    government: 639.968
  },
  "1980-10-01": {
    gdp: 2985.557,
    profits: 288.347,
    government: 657.298
  },
  "1981-01-01": {
    gdp: 3124.206,
    profits: 291.311,
    government: 680.243
  },
  "1981-04-01": {
    gdp: 3162.532,
    profits: 270.691,
    government: 695.699
  },
  "1981-07-01": {
    gdp: 3260.609,
    profits: 278.516,
    government: 722.114
  },
  "1981-10-01": {
    gdp: 3280.818,
    profits: 265.981,
    government: 738.484
  },
  "1982-01-01": {
    gdp: 3274.302,
    profits: 234.08,
    government: 753.575
  },
  "1982-04-01": {
    gdp: 3331.972,
    profits: 238.1,
    government: 767.553
  },
  "1982-07-01": {
    gdp: 3366.322,
    profits: 233.94,
    government: 794.534
  },
  "1982-10-01": {
    gdp: 3402.561,
    profits: 223.19,
    government: 828.404
  },
  "1983-01-01": {
    gdp: 3473.413,
    profits: 226.41,
    government: 831.494
  },
  "1983-04-01": {
    gdp: 3578.848,
    profits: 260.694,
    government: 852.517
  },
  "1983-07-01": {
    gdp: 3689.179,
    profits: 280.503,
    government: 864.3
  },
  "1983-10-01": {
    gdp: 3794.706,
    profits: 287.942,
    government: 859.457
  },
  "1984-01-01": {
    gdp: 3908.054,
    profits: 308.544,
    government: 874.942
  },
  "1984-04-01": {
    gdp: 4009.601,
    profits: 308.101,
    government: 897.828
  },
  "1984-07-01": {
    gdp: 4084.25,
    profits: 287.037,
    government: 914.311
  },
  "1984-10-01": {
    gdp: 4148.551,
    profits: 289.279,
    government: 943.661
  },
  "1985-01-01": {
    gdp: 4230.168,
    profits: 285.28,
    government: 952.096
  },
  "1985-04-01": {
    gdp: 4294.887,
    profits: 284.662,
    government: 966.688
  },
  "1985-07-01": {
    gdp: 4386.773,
    profits: 294.63,
    government: 984.669
  },
  "1985-10-01": {
    gdp: 4444.094,
    profits: 294.025,
    government: 996.43
  },
  "1986-01-01": {
    gdp: 4507.894,
    profits: 264.68,
    government: 1007.454
  },
  "1986-04-01": {
    gdp: 4545.34,
    profits: 261.373,
    government: 1033.105
  },
  "1986-07-01": {
    gdp: 4607.669,
    profits: 261.503,
    government: 1052.329
  },
  "1986-10-01": {
    gdp: 4657.627,
    profits: 277.22,
    government: 1042.504
  },
  "1987-01-01": {
    gdp: 4722.156,
    profits: 289.374,
    government: 1045.149
  },
  "1987-04-01": {
    gdp: 4806.16,
    profits: 330.457,
    government: 1061.117
  },
  "1987-07-01": {
    gdp: 4884.555,
    profits: 350.053,
    government: 1064.747
  },
  "1987-10-01": {
    gdp: 5007.994,
    profits: 353.278,
    government: 1089.898
  },
  "1988-01-01": {
    gdp: 5073.372,
    profits: 362.23,
    government: 1107.967
  },
  "1988-04-01": {
    gdp: 5190.036,
    profits: 385.181,
    government: 1108.813
  },
  "1988-07-01": {
    gdp: 5282.835,
    profits: 393.782,
    government: 1120.902
  },
  "1988-10-01": {
    gdp: 5399.509,
    profits: 412.231,
    government: 1151.873
  },
  "1989-01-01": {
    gdp: 5511.253,
    profits: 412.27,
    government: 1171.136
  },
  "1989-04-01": {
    gdp: 5612.463,
    profits: 391.61,
    government: 1194.208
  },
  "1989-07-01": {
    gdp: 5695.365,
    profits: 376.843,
    government: 1212.452
  },
  "1989-10-01": {
    gdp: 5747.237,
    profits: 376.892,
    government: 1229.556
  },
  "1990-01-01": {
    gdp: 5872.701,
    profits: 385.623,
    government: 1261.251
  },
  "1990-04-01": {
    gdp: 5960.028,
    profits: 404.009,
    government: 1287.513
  },
  "1990-07-01": {
    gdp: 6015.116,
    profits: 413.339,
    government: 1297.056
  },
  "1990-10-01": {
    gdp: 6004.733,
    profits: 413.234,
    government: 1317.797
  },
  "1991-01-01": {
    gdp: 6035.178,
    profits: 423.45,
    government: 1281.354
  },
  "1991-04-01": {
    gdp: 6126.862,
    profits: 425.449,
    government: 1345.598
  },
  "1991-07-01": {
    gdp: 6205.937,
    profits: 431.732,
    government: 1384.626
  },
  "1991-10-01": {
    gdp: 6264.54,
    profits: 436.638,
    government: 1413.344
  },
  "1992-01-01": {
    gdp: 6363.102,
    profits: 459.017,
    government: 1458.39
  },
  "1992-04-01": {
    gdp: 6470.763,
    profits: 473.119,
    government: 1476.444
  },
  "1992-07-01": {
    gdp: 6566.641,
    profits: 451.034,
    government: 1498.726
  },
  "1992-10-01": {
    gdp: 6680.803,
    profits: 467.072,
    government: 1521.869
  },
  "1993-01-01": {
    gdp: 6729.459,
    profits: 470.684,
    government: 1522.365
  },
  "1993-04-01": {
    gdp: 6808.939,
    profits: 497.402,
    government: 1532.659
  },
  "1993-07-01": {
    gdp: 6882.098,
    profits: 502.315,
    government: 1550.905
  },
  "1993-10-01": {
    gdp: 7013.738,
    profits: 553.377,
    government: 1572.369
  },
  "1994-01-01": {
    gdp: 7115.652,
    profits: 560.915,
    government: 1552.96
  },
  "1994-04-01": {
    gdp: 7246.931,
    profits: 582.456,
    government: 1567.862
  },
  "1994-07-01": {
    gdp: 7331.075,
    profits: 619.929,
    government: 1596.722
  },
  "1994-10-01": {
    gdp: 7455.288,
    profits: 643.516,
    government: 1622.331
  },
  "1995-01-01": {
    gdp: 7522.289,
    profits: 662.349,
    government: 1642.508
  },
  "1995-04-01": {
    gdp: 7580.997,
    profits: 682.223,
    government: 1659.406
  },
  "1995-07-01": {
    gdp: 7683.125,
    profits: 696.765,
    government: 1670.844
  },
  "1995-10-01": {
    gdp: 7772.586,
    profits: 699.668,
    government: 1665.404
  },
  "1996-01-01": {
    gdp: 7868.468,
    profits: 726.619,
    government: 1701.217
  },
  "1996-04-01": {
    gdp: 8032.84,
    profits: 740.315,
    government: 1711.469
  },
  "1996-07-01": {
    gdp: 8131.408,
    profits: 736.36,
    government: 1715.062
  },
  "1996-10-01": {
    gdp: 8259.771,
    profits: 751.667,
    government: 1735.168
  },
  "1997-01-01": {
    gdp: 8362.655,
    profits: 764.966,
    government: 1736.733
  },
  "1997-04-01": {
    gdp: 8518.825,
    profits: 787.741,
    government: 1755.842
  },
  "1997-07-01": {
    gdp: 8662.823,
    profits: 821.463,
    government: 1757.977
  },
  "1997-10-01": {
    gdp: 8765.907,
    profits: 813.375,
    government: 1786.848
  },
  "1998-01-01": {
    gdp: 8866.48,
    profits: 736.753,
    government: 1768.79
  },
  "1998-04-01": {
    gdp: 8969.699,
    profits: 731.17,
    government: 1781.911
  },
  "1998-07-01": {
    gdp: 9121.097,
    profits: 733.218,
    government: 1788.538
  },
  "1998-10-01": {
    gdp: 9293.991,
    profits: 711.168,
    government: 1814.518
  },
  "1999-01-01": {
    gdp: 9411.682,
    profits: 749.51,
    government: 1811.988
  },
  "1999-04-01": {
    gdp: 9526.21,
    profits: 761.403,
    government: 1816.961
  },
  "1999-07-01": {
    gdp: 9686.626,
    profits: 764.385,
    government: 1843.205
  },
  "1999-10-01": {
    gdp: 9900.169,
    profits: 788.155,
    government: 1875.154
  },
  "2000-01-01": {
    gdp: 10002.179,
    profits: 770.883,
    government: 1866.308
  },
  "2000-04-01": {
    gdp: 10247.72,
    profits: 761.52,
    government: 1908.645
  },
  "2000-07-01": {
    gdp: 10318.165,
    profits: 736.092,
    government: 1918.617
  },
  "2000-10-01": {
    gdp: 10435.744,
    profits: 717.789,
    government: 1938.796
  },
  "2001-01-01": {
    gdp: 10470.231,
    profits: 717.433,
    government: 1982.626
  },
  "2001-04-01": {
    gdp: 10599,
    profits: 734.373,
    government: 2003.581
  },
  "2001-07-01": {
    gdp: 10598.02,
    profits: 668.439,
    government: 2037.703
  },
  "2001-10-01": {
    gdp: 10660.465,
    profits: 635.928,
    government: 2045.404
  },
  "2002-01-01": {
    gdp: 10783.5,
    profits: 685.452,
    government: 2091.469
  },
  "2002-04-01": {
    gdp: 10887.46,
    profits: 749.824,
    government: 2130.186
  },
  "2002-07-01": {
    gdp: 10984.04,
    profits: 813.945,
    government: 2148.447
  },
  "2002-10-01": {
    gdp: 11061.433,
    profits: 904.966,
    government: 2184.697
  },
  "2003-01-01": {
    gdp: 11174.129,
    profits: 921.467,
    government: 2220.262
  },
  "2003-04-01": {
    gdp: 11312.766,
    profits: 919.833,
    government: 2299.919
  },
  "2003-07-01": {
    gdp: 11566.669,
    profits: 981.564,
    government: 2309.622
  },
  "2003-10-01": {
    gdp: 11772.234,
    profits: 1057.618,
    government: 2344.18
  },
  "2004-01-01": {
    gdp: 11923.447,
    profits: 1179.638,
    government: 2381.04
  },
  "2004-04-01": {
    gdp: 12112.815,
    profits: 1239.285,
    government: 2400.4
  },
  "2004-07-01": {
    gdp: 12305.307,
    profits: 1302.93,
    government: 2428.795
  },
  "2004-10-01": {
    gdp: 12527.214,
    profits: 1308.967,
    government: 2476.05
  },
  "2005-01-01": {
    gdp: 12767.286,
    profits: 1604.404,
    government: 2548.019
  },
  "2005-04-01": {
    gdp: 12922.656,
    profits: 1616.546,
    government: 2578.686
  },
  "2005-07-01": {
    gdp: 13142.642,
    profits: 1667.23,
    government: 2617.33
  },
  "2005-10-01": {
    gdp: 13324.204,
    profits: 1772.577,
    government: 2649.819
  },
  "2006-01-01": {
    gdp: 13599.16,
    profits: 1809.292,
    government: 2728.184
  },
  "2006-04-01": {
    gdp: 13753.424,
    profits: 1857.786,
    government: 2749.085
  },
  "2006-07-01": {
    gdp: 13870.188,
    profits: 1896.081,
    government: 2775.712
  },
  "2006-10-01": {
    gdp: 14039.56,
    profits: 1813.484,
    government: 2789.906
  },
  "2007-01-01": {
    gdp: 14215.651,
    profits: 1744.562,
    government: 2869.326
  },
  "2007-04-01": {
    gdp: 14402.082,
    profits: 1814.11,
    government: 2905.202
  },
  "2007-07-01": {
    gdp: 14564.117,
    profits: 1722.347,
    government: 2945.721
  },
  "2007-10-01": {
    gdp: 14715.058,
    profits: 1726.757,
    government: 2991.765
  },
  "2008-01-01": {
    gdp: 14706.538,
    profits: 1558.756,
    government: 3043.523
  },
  "2008-04-01": {
    gdp: 14865.701,
    profits: 1570.559,
    government: 3397.452
  },
  "2008-07-01": {
    gdp: 14898.999,
    profits: 1496.693,
    government: 3194.11
  },
  "2008-10-01": {
    gdp: 14608.208,
    profits: 891.223,
    government: 3192.866
  },
  "2009-01-01": {
    gdp: 14430.901,
    profits: 1254.684,
    government: 3322.767
  },
  "2009-04-01": {
    gdp: 14381.236,
    profits: 1356.958,
    government: 3524.671
  },
  "2009-07-01": {
    gdp: 14448.882,
    profits: 1553.371,
    government: 3532.903
  },
  "2009-10-01": {
    gdp: 14651.248,
    profits: 1704.953,
    government: 3560.619
  },
  "2010-01-01": {
    gdp: 14764.611,
    profits: 1786.945,
    government: 3710.549
  },
  "2010-04-01": {
    gdp: 14980.193,
    profits: 1779.315,
    government: 3745.712
  },
  "2010-07-01": {
    gdp: 15141.605,
    profits: 1879.334,
    government: 3792.438
  },
  "2010-10-01": {
    gdp: 15309.471,
    profits: 1890.461,
    government: 3809.802
  },
  "2011-01-01": {
    gdp: 15351.444,
    profits: 1749.344,
    government: 3811.259
  },
  "2011-04-01": {
    gdp: 15557.535,
    profits: 1814.197,
    government: 3849.373
  },
  "2011-07-01": {
    gdp: 15647.681,
    profits: 1795.846,
    government: 3783.833
  },
  "2011-10-01": {
    gdp: 15842.267,
    profits: 1913.534,
    government: 3786.535
  },
  "2012-01-01": {
    gdp: 16068.824,
    profits: 2199.909,
    government: 3774.361
  },
  "2012-04-01": {
    gdp: 16207.13,
    profits: 2135.981,
    government: 3772.881
  },
  "2012-07-01": {
    gdp: 16319.54,
    profits: 2160.051,
    government: 3777.297
  },
  "2012-10-01": {
    gdp: 16420.386,
    profits: 2128.47,
    government: 3769.473
  },
  "2013-01-01": {
    gdp: 16629.05,
    profits: 2127.093,
    government: 3759.998
  },
  "2013-04-01": {
    gdp: 16699.551,
    profits: 2114.721,
    government: 3777.383
  },
  "2013-07-01": {
    gdp: 16911.068,
    profits: 2157.51,
    government: 3774.113
  },
  "2013-10-01": {
    gdp: 17133.114,
    profits: 2206.766,
    government: 3773.864
  },
  "2014-01-01": {
    gdp: 17144.281,
    profits: 2171.327,
    government: 3820.883
  },
  "2014-04-01": {
    gdp: 17462.703,
    profits: 2290.948,
    government: 3877.657
  },
  "2014-07-01": {
    gdp: 17743.227,
    profits: 2315.635,
    government: 3933.198
  },
  "2014-10-01": {
    gdp: 17852.54,
    profits: 2279.979,
    government: 3929.666
  },
  "2015-01-01": {
    gdp: 17991.348,
    profits: 2185.428,
    government: 3973.876
  },
  "2015-04-01": {
    gdp: 18193.707,
    profits: 2208.945,
    government: 3993.062
  },
  "2015-07-01": {
    gdp: 18306.96,
    profits: 2123.057,
    government: 4015.19
  },
  "2015-10-01": {
    gdp: 18332.079,
    profits: 1995.626,
    government: 4054.768
  },
  "2016-01-01": {
    gdp: 18425.306,
    profits: 2071.8,
    government: 4089.853
  },
  "2016-04-01": {
    gdp: 18611.617,
    profits: 2137.312,
    government: 4103.589
  },
  "2016-07-01": {
    gdp: 18775.459,
    profits: 2139.392,
    government: 4153.525
  },
  "2016-10-01": {
    gdp: 18968.041,
    profits: 2188.432,
    government: 4178.499
  },
  "2017-01-01": {
    gdp: 19153.912,
    profits: 2211.787,
    government: 4193.7
  },
  "2017-04-01": {
    gdp: 19322.92,
    profits: 2202.282,
    government: 4199.146
  },
  "2017-07-01": {
    gdp: 19558.693,
    profits: 2271.793,
    government: 4262.241
  },
  "2017-10-01": {
    gdp: 19882.965,
    profits: 2093.142,
    government: 4328.522
  },
  "2018-01-01": {
    gdp: 20143.716,
    profits: 2203.383,
    government: 4404.027
  },
  "2018-04-01": {
    gdp: 20492.492,
    profits: 2254.954,
    government: 4465.605
  },
  "2018-07-01": {
    gdp: 20659.102,
    profits: 2273.248,
    government: 4521.297
  },
  "2018-10-01": {
    gdp: 20813.325,
    profits: 2304.476,
    government: 4597.545
  },
  "2019-01-01": {
    gdp: 21001.591,
    profits: 2234.596,
    government: 4691.794
  },
  "2019-04-01": {
    gdp: 21289.268,
    profits: 2289.342,
    government: 4739.145
  },
  "2019-07-01": {
    gdp: 21505.012,
    profits: 2214.949,
    government: 4789.838
  },
  "2019-10-01": {
    gdp: 21694.458,
    profits: 2279.536,
    government: 4823.772
  },
  "2020-01-01": {
    gdp: 21481.367,
    profits: 1988.819,
    government: 4909.191
  },
  "2020-04-01": {
    gdp: 19477.444,
    profits: 1818.89,
    government: 9106.697
  },
  "2020-07-01": {
    gdp: 21138.574,
    profits: 2448.448,
    government: 7206.777
  },
  "2020-10-01": {
    gdp: 21477.597,
    profits: 2480.094,
    government: 5955.209
  },
  "2021-01-01": {
    gdp: 22038.226,
    profits: 2711.312,
    government: 8071.42
  },
  "2021-04-01": {
    gdp: 22740.959,
    profits: 3068.988,
    government: 7490.479
  },
  "2021-07-01": {
    gdp: 23202.344,
    profits: 3114.936,
    government: 6560.351
  },
  "2021-10-01": {
    gdp: 24008.472,
    profits: null,
    government: 5959.189
  }
};
const flows = {
  "1959-01-01": {
    expenses: 306.1,
    income: 391.8
  },
  "1959-02-01": {
    expenses: 309.6,
    income: 393.7
  },
  "1959-03-01": {
    expenses: 312.7,
    income: 396.5
  },
  "1959-04-01": {
    expenses: 312.2,
    income: 399.9
  },
  "1959-05-01": {
    expenses: 316.1,
    income: 402.4
  },
  "1959-06-01": {
    expenses: 318.2,
    income: 404.8
  },
  "1959-07-01": {
    expenses: 317.8,
    income: 405.4
  },
  "1959-08-01": {
    expenses: 320.2,
    income: 403.7
  },
  "1959-09-01": {
    expenses: 324.2,
    income: 405.1
  },
  "1959-10-01": {
    expenses: 322.8,
    income: 406.6
  },
  "1959-11-01": {
    expenses: 322.9,
    income: 409.9
  },
  "1959-12-01": {
    expenses: 322.9,
    income: 414.6
  },
  "1960-01-01": {
    expenses: 323.6,
    income: 415.7
  },
  "1960-02-01": {
    expenses: 325.3,
    income: 416.6
  },
  "1960-03-01": {
    expenses: 330.2,
    income: 417.6
  },
  "1960-04-01": {
    expenses: 336.5,
    income: 420.6
  },
  "1960-05-01": {
    expenses: 330,
    income: 422.1
  },
  "1960-06-01": {
    expenses: 330.1,
    income: 422.6
  },
  "1960-07-01": {
    expenses: 331.4,
    income: 424.1
  },
  "1960-08-01": {
    expenses: 331.6,
    income: 424.1
  },
  "1960-09-01": {
    expenses: 333.4,
    income: 424.9
  },
  "1960-10-01": {
    expenses: 335.4,
    income: 426.7
  },
  "1960-11-01": {
    expenses: 335.4,
    income: 426.3
  },
  "1960-12-01": {
    expenses: 331.3,
    income: 424.4
  },
  "1961-01-01": {
    expenses: 332.2,
    income: 428.3
  },
  "1961-02-01": {
    expenses: 333.7,
    income: 430.3
  },
  "1961-03-01": {
    expenses: 337.7,
    income: 431.9
  },
  "1961-04-01": {
    expenses: 337.6,
    income: 432.5
  },
  "1961-05-01": {
    expenses: 339.9,
    income: 436.1
  },
  "1961-06-01": {
    expenses: 340.9,
    income: 440.5
  },
  "1961-07-01": {
    expenses: 340.6,
    income: 442.7
  },
  "1961-08-01": {
    expenses: 342.1,
    income: 443.3
  },
  "1961-09-01": {
    expenses: 344.4,
    income: 444.3
  },
  "1961-10-01": {
    expenses: 346.5,
    income: 448.7
  },
  "1961-11-01": {
    expenses: 349.9,
    income: 452.7
  },
  "1961-12-01": {
    expenses: 352.4,
    income: 455.6
  },
  "1962-01-01": {
    expenses: 353.2,
    income: 456
  },
  "1962-02-01": {
    expenses: 354,
    income: 459.1
  },
  "1962-03-01": {
    expenses: 357.4,
    income: 462.9
  },
  "1962-04-01": {
    expenses: 359,
    income: 465.8
  },
  "1962-05-01": {
    expenses: 362,
    income: 466.8
  },
  "1962-06-01": {
    expenses: 360.4,
    income: 467.7
  },
  "1962-07-01": {
    expenses: 361.4,
    income: 469.7
  },
  "1962-08-01": {
    expenses: 362.9,
    income: 471.1
  },
  "1962-09-01": {
    expenses: 368.8,
    income: 473.7
  },
  "1962-10-01": {
    expenses: 366.5,
    income: 475.7
  },
  "1962-11-01": {
    expenses: 371.7,
    income: 477.8
  },
  "1962-12-01": {
    expenses: 373.6,
    income: 479.8
  },
  "1963-01-01": {
    expenses: 374.4,
    income: 484
  },
  "1963-02-01": {
    expenses: 373.4,
    income: 481.9
  },
  "1963-03-01": {
    expenses: 375,
    income: 483.7
  },
  "1963-04-01": {
    expenses: 376.4,
    income: 485
  },
  "1963-05-01": {
    expenses: 377.2,
    income: 487.9
  },
  "1963-06-01": {
    expenses: 381.7,
    income: 491.3
  },
  "1963-07-01": {
    expenses: 384.4,
    income: 492.5
  },
  "1963-08-01": {
    expenses: 386.3,
    income: 494.9
  },
  "1963-09-01": {
    expenses: 385.5,
    income: 498.2
  },
  "1963-10-01": {
    expenses: 386,
    income: 501.9
  },
  "1963-11-01": {
    expenses: 389.4,
    income: 503.6
  },
  "1963-12-01": {
    expenses: 394.7,
    income: 508.4
  },
  "1964-01-01": {
    expenses: 396.8,
    income: 511.3
  },
  "1964-02-01": {
    expenses: 400.2,
    income: 514.1
  },
  "1964-03-01": {
    expenses: 401.8,
    income: 517
  },
  "1964-04-01": {
    expenses: 402.8,
    income: 520.6
  },
  "1964-05-01": {
    expenses: 408.4,
    income: 523.8
  },
  "1964-06-01": {
    expenses: 411.4,
    income: 526.3
  },
  "1964-07-01": {
    expenses: 414.9,
    income: 529.9
  },
  "1964-08-01": {
    expenses: 417.7,
    income: 533.3
  },
  "1964-09-01": {
    expenses: 416.6,
    income: 536.3
  },
  "1964-10-01": {
    expenses: 419.6,
    income: 536.9
  },
  "1964-11-01": {
    expenses: 416.4,
    income: 541.3
  },
  "1964-12-01": {
    expenses: 421,
    income: 547.5
  },
  "1965-01-01": {
    expenses: 424.4,
    income: 551.5
  },
  "1965-02-01": {
    expenses: 431.4,
    income: 552
  },
  "1965-03-01": {
    expenses: 433.3,
    income: 555.4
  },
  "1965-04-01": {
    expenses: 437.1,
    income: 558.6
  },
  "1965-05-01": {
    expenses: 437.6,
    income: 562.9
  },
  "1965-06-01": {
    expenses: 435.2,
    income: 566.2
  },
  "1965-07-01": {
    expenses: 441,
    income: 570.4
  },
  "1965-08-01": {
    expenses: 444.4,
    income: 572.9
  },
  "1965-09-01": {
    expenses: 452,
    income: 587.2
  },
  "1965-10-01": {
    expenses: 458.5,
    income: 584.9
  },
  "1965-11-01": {
    expenses: 459.1,
    income: 590.2
  },
  "1965-12-01": {
    expenses: 461.7,
    income: 595.5
  },
  "1966-01-01": {
    expenses: 465.2,
    income: 597.7
  },
  "1966-02-01": {
    expenses: 470.2,
    income: 603.1
  },
  "1966-03-01": {
    expenses: 475,
    income: 607.9
  },
  "1966-04-01": {
    expenses: 476.9,
    income: 609.5
  },
  "1966-05-01": {
    expenses: 473.1,
    income: 612.2
  },
  "1966-06-01": {
    expenses: 475.5,
    income: 616.7
  },
  "1966-07-01": {
    expenses: 480.6,
    income: 620.4
  },
  "1966-08-01": {
    expenses: 483.2,
    income: 625.9
  },
  "1966-09-01": {
    expenses: 489.2,
    income: 631.7
  },
  "1966-10-01": {
    expenses: 488.3,
    income: 636
  },
  "1966-11-01": {
    expenses: 490.1,
    income: 640.9
  },
  "1966-12-01": {
    expenses: 491.8,
    income: 642
  },
  "1967-01-01": {
    expenses: 494.2,
    income: 648
  },
  "1967-02-01": {
    expenses: 493,
    income: 649.6
  },
  "1967-03-01": {
    expenses: 495.8,
    income: 653.9
  },
  "1967-04-01": {
    expenses: 501.3,
    income: 654.4
  },
  "1967-05-01": {
    expenses: 502.2,
    income: 656.9
  },
  "1967-06-01": {
    expenses: 506.8,
    income: 661.6
  },
  "1967-07-01": {
    expenses: 506.7,
    income: 667.2
  },
  "1967-08-01": {
    expenses: 509.8,
    income: 672.5
  },
  "1967-09-01": {
    expenses: 515.6,
    income: 675
  },
  "1967-10-01": {
    expenses: 512.2,
    income: 677.8
  },
  "1967-11-01": {
    expenses: 517.4,
    income: 684.4
  },
  "1967-12-01": {
    expenses: 525.1,
    income: 687.4
  },
  "1968-01-01": {
    expenses: 530.9,
    income: 694.3
  },
  "1968-02-01": {
    expenses: 533.6,
    income: 702.5
  },
  "1968-03-01": {
    expenses: 544.3,
    income: 710.8
  },
  "1968-04-01": {
    expenses: 544,
    income: 716.2
  },
  "1968-05-01": {
    expenses: 549.8,
    income: 722.6
  },
  "1968-06-01": {
    expenses: 556.3,
    income: 728.3
  },
  "1968-07-01": {
    expenses: 563.2,
    income: 735
  },
  "1968-08-01": {
    expenses: 567,
    income: 741.4
  },
  "1968-09-01": {
    expenses: 568.2,
    income: 747.4
  },
  "1968-10-01": {
    expenses: 571.6,
    income: 752
  },
  "1968-11-01": {
    expenses: 576.7,
    income: 758
  },
  "1968-12-01": {
    expenses: 576.5,
    income: 762.6
  },
  "1969-01-01": {
    expenses: 583.5,
    income: 766.3
  },
  "1969-02-01": {
    expenses: 588.7,
    income: 771.8
  },
  "1969-03-01": {
    expenses: 588.9,
    income: 779
  },
  "1969-04-01": {
    expenses: 593.9,
    income: 784.3
  },
  "1969-05-01": {
    expenses: 600.3,
    income: 790.5
  },
  "1969-06-01": {
    expenses: 600.9,
    income: 797.1
  },
  "1969-07-01": {
    expenses: 602.7,
    income: 805
  },
  "1969-08-01": {
    expenses: 609.9,
    income: 812.2
  },
  "1969-09-01": {
    expenses: 613.2,
    income: 817.7
  },
  "1969-10-01": {
    expenses: 618.5,
    income: 822
  },
  "1969-11-01": {
    expenses: 620.5,
    income: 826.4
  },
  "1969-12-01": {
    expenses: 622.8,
    income: 831.9
  },
  "1970-01-01": {
    expenses: 628.7,
    income: 836.4
  },
  "1970-02-01": {
    expenses: 634,
    income: 841.8
  },
  "1970-03-01": {
    expenses: 632.3,
    income: 847.1
  },
  "1970-04-01": {
    expenses: 636,
    income: 859.9
  },
  "1970-05-01": {
    expenses: 642.4,
    income: 857.7
  },
  "1970-06-01": {
    expenses: 646.3,
    income: 862.6
  },
  "1970-07-01": {
    expenses: 648.5,
    income: 870.6
  },
  "1970-08-01": {
    expenses: 652.9,
    income: 875.1
  },
  "1970-09-01": {
    expenses: 659.1,
    income: 878.4
  },
  "1970-10-01": {
    expenses: 658.3,
    income: 879.7
  },
  "1970-11-01": {
    expenses: 656.6,
    income: 882.3
  },
  "1970-12-01": {
    expenses: 665.6,
    income: 889.2
  },
  "1971-01-01": {
    expenses: 676.1,
    income: 898.8
  },
  "1971-02-01": {
    expenses: 679.4,
    income: 903.2
  },
  "1971-03-01": {
    expenses: 682,
    income: 909
  },
  "1971-04-01": {
    expenses: 688.8,
    income: 915
  },
  "1971-05-01": {
    expenses: 691.1,
    income: 922.4
  },
  "1971-06-01": {
    expenses: 699.8,
    income: 943.8
  },
  "1971-07-01": {
    expenses: 698.9,
    income: 934.8
  },
  "1971-08-01": {
    expenses: 704.9,
    income: 940.8
  },
  "1971-09-01": {
    expenses: 713,
    income: 947.7
  },
  "1971-10-01": {
    expenses: 715.8,
    income: 951.8
  },
  "1971-11-01": {
    expenses: 720.9,
    income: 958.6
  },
  "1971-12-01": {
    expenses: 728.4,
    income: 967.4
  },
  "1972-01-01": {
    expenses: 731.5,
    income: 976.1
  },
  "1972-02-01": {
    expenses: 736.2,
    income: 987.9
  },
  "1972-03-01": {
    expenses: 749.2,
    income: 995.6
  },
  "1972-04-01": {
    expenses: 752.5,
    income: 998.5
  },
  "1972-05-01": {
    expenses: 758,
    income: 1004.9
  },
  "1972-06-01": {
    expenses: 761.6,
    income: 1009.2
  },
  "1972-07-01": {
    expenses: 769.9,
    income: 1019
  },
  "1972-08-01": {
    expenses: 776.3,
    income: 1031.4
  },
  "1972-09-01": {
    expenses: 781.1,
    income: 1040
  },
  "1972-10-01": {
    expenses: 794.9,
    income: 1065.1
  },
  "1972-11-01": {
    expenses: 800.5,
    income: 1079.2
  },
  "1972-12-01": {
    expenses: 806.1,
    income: 1086.6
  },
  "1973-01-01": {
    expenses: 816.5,
    income: 1085.6
  },
  "1973-02-01": {
    expenses: 825.8,
    income: 1095.3
  },
  "1973-03-01": {
    expenses: 832.8,
    income: 1104.4
  },
  "1973-04-01": {
    expenses: 835.7,
    income: 1114.7
  },
  "1973-05-01": {
    expenses: 841.6,
    income: 1125.1
  },
  "1973-06-01": {
    expenses: 844.3,
    income: 1134.7
  },
  "1973-07-01": {
    expenses: 854.1,
    income: 1142.2
  },
  "1973-08-01": {
    expenses: 853.3,
    income: 1152
  },
  "1973-09-01": {
    expenses: 869.2,
    income: 1163.4
  },
  "1973-10-01": {
    expenses: 868.2,
    income: 1180.2
  },
  "1973-11-01": {
    expenses: 876.9,
    income: 1192.6
  },
  "1973-12-01": {
    expenses: 876.6,
    income: 1199.2
  },
  "1974-01-01": {
    expenses: 884.5,
    income: 1200.4
  },
  "1974-02-01": {
    expenses: 889.7,
    income: 1207.1
  },
  "1974-03-01": {
    expenses: 901.4,
    income: 1213.1
  },
  "1974-04-01": {
    expenses: 910.8,
    income: 1220.9
  },
  "1974-05-01": {
    expenses: 922.4,
    income: 1234.1
  },
  "1974-06-01": {
    expenses: 928,
    income: 1243.7
  },
  "1974-07-01": {
    expenses: 937.9,
    income: 1257.8
  },
  "1974-08-01": {
    expenses: 954.8,
    income: 1268.8
  },
  "1974-09-01": {
    expenses: 955.1,
    income: 1280.7
  },
  "1974-10-01": {
    expenses: 959.2,
    income: 1294
  },
  "1974-11-01": {
    expenses: 956.2,
    income: 1296
  },
  "1974-12-01": {
    expenses: 961.8,
    income: 1305.4
  },
  "1975-01-01": {
    expenses: 975.6,
    income: 1312.3
  },
  "1975-02-01": {
    expenses: 989.4,
    income: 1318.8
  },
  "1975-03-01": {
    expenses: 990.6,
    income: 1324.2
  },
  "1975-04-01": {
    expenses: 995,
    income: 1330.3
  },
  "1975-05-01": {
    expenses: 1018.9,
    income: 1343.8
  },
  "1975-06-01": {
    expenses: 1026.8,
    income: 1375.8
  },
  "1975-07-01": {
    expenses: 1039.8,
    income: 1372.3
  },
  "1975-08-01": {
    expenses: 1047,
    income: 1388.5
  },
  "1975-09-01": {
    expenses: 1054.8,
    income: 1398.7
  },
  "1975-10-01": {
    expenses: 1060.9,
    income: 1413.4
  },
  "1975-11-01": {
    expenses: 1075.8,
    income: 1422.4
  },
  "1975-12-01": {
    expenses: 1092.1,
    income: 1432.3
  },
  "1976-01-01": {
    expenses: 1107.1,
    income: 1447.2
  },
  "1976-02-01": {
    expenses: 1107.7,
    income: 1457.5
  },
  "1976-03-01": {
    expenses: 1114.9,
    income: 1463.6
  },
  "1976-04-01": {
    expenses: 1125.4,
    income: 1472.5
  },
  "1976-05-01": {
    expenses: 1122.7,
    income: 1481.2
  },
  "1976-06-01": {
    expenses: 1140.5,
    income: 1489.9
  },
  "1976-07-01": {
    expenses: 1149.6,
    income: 1506.7
  },
  "1976-08-01": {
    expenses: 1158,
    income: 1519.3
  },
  "1976-09-01": {
    expenses: 1168.8,
    income: 1529.2
  },
  "1976-10-01": {
    expenses: 1176.8,
    income: 1536.7
  },
  "1976-11-01": {
    expenses: 1189,
    income: 1557.2
  },
  "1976-12-01": {
    expenses: 1211.5,
    income: 1570.7
  },
  "1977-01-01": {
    expenses: 1215,
    income: 1572.2
  },
  "1977-02-01": {
    expenses: 1231.3,
    income: 1589.6
  },
  "1977-03-01": {
    expenses: 1238.3,
    income: 1604.7
  },
  "1977-04-01": {
    expenses: 1247.3,
    income: 1620.9
  },
  "1977-05-01": {
    expenses: 1257.1,
    income: 1633.2
  },
  "1977-06-01": {
    expenses: 1263.6,
    income: 1644.9
  },
  "1977-07-01": {
    expenses: 1280.5,
    income: 1662.6
  },
  "1977-08-01": {
    expenses: 1285.7,
    income: 1676.9
  },
  "1977-09-01": {
    expenses: 1294.5,
    income: 1693.7
  },
  "1977-10-01": {
    expenses: 1311.4,
    income: 1716.3
  },
  "1977-11-01": {
    expenses: 1327,
    income: 1739.8
  },
  "1977-12-01": {
    expenses: 1336,
    income: 1756
  },
  "1978-01-01": {
    expenses: 1329.5,
    income: 1760.1
  },
  "1978-02-01": {
    expenses: 1355.1,
    income: 1776.2
  },
  "1978-03-01": {
    expenses: 1377.5,
    income: 1797.9
  },
  "1978-04-01": {
    expenses: 1396.4,
    income: 1821.9
  },
  "1978-05-01": {
    expenses: 1412,
    income: 1837
  },
  "1978-06-01": {
    expenses: 1425.8,
    income: 1855.8
  },
  "1978-07-01": {
    expenses: 1426.8,
    income: 1876.2
  },
  "1978-08-01": {
    expenses: 1447,
    income: 1894.2
  },
  "1978-09-01": {
    expenses: 1452.9,
    income: 1907.5
  },
  "1978-10-01": {
    expenses: 1466.9,
    income: 1929.7
  },
  "1978-11-01": {
    expenses: 1480.6,
    income: 1944
  },
  "1978-12-01": {
    expenses: 1496.5,
    income: 1964.2
  },
  "1979-01-01": {
    expenses: 1502.4,
    income: 1985.7
  },
  "1979-02-01": {
    expenses: 1517.8,
    income: 2004.3
  },
  "1979-03-01": {
    expenses: 1531.2,
    income: 2026.1
  },
  "1979-04-01": {
    expenses: 1538.4,
    income: 2030.9
  },
  "1979-05-01": {
    expenses: 1558.8,
    income: 2044.8
  },
  "1979-06-01": {
    expenses: 1575.7,
    income: 2060.5
  },
  "1979-07-01": {
    expenses: 1586.1,
    income: 2091.9
  },
  "1979-08-01": {
    expenses: 1615.6,
    income: 2111.1
  },
  "1979-09-01": {
    expenses: 1633.9,
    income: 2129.5
  },
  "1979-10-01": {
    expenses: 1641.6,
    income: 2146.8
  },
  "1979-11-01": {
    expenses: 1657.3,
    income: 2168.4
  },
  "1979-12-01": {
    expenses: 1666.3,
    income: 2191.9
  },
  "1980-01-01": {
    expenses: 1697.3,
    income: 2217.7
  },
  "1980-02-01": {
    expenses: 1701.4,
    income: 2231.4
  },
  "1980-03-01": {
    expenses: 1708.2,
    income: 2246.7
  },
  "1980-04-01": {
    expenses: 1695.2,
    income: 2255.8
  },
  "1980-05-01": {
    expenses: 1700.1,
    income: 2266.3
  },
  "1980-06-01": {
    expenses: 1718.8,
    income: 2286.3
  },
  "1980-07-01": {
    expenses: 1747.1,
    income: 2321.4
  },
  "1980-08-01": {
    expenses: 1763.8,
    income: 2345.9
  },
  "1980-09-01": {
    expenses: 1780.5,
    income: 2378.1
  },
  "1980-10-01": {
    expenses: 1817.1,
    income: 2418.1
  },
  "1980-11-01": {
    expenses: 1826.8,
    income: 2444
  },
  "1980-12-01": {
    expenses: 1851.7,
    income: 2472
  },
  "1981-01-01": {
    expenses: 1870,
    income: 2488
  },
  "1981-02-01": {
    expenses: 1884.2,
    income: 2506.5
  },
  "1981-03-01": {
    expenses: 1902.9,
    income: 2532.2
  },
  "1981-04-01": {
    expenses: 1904.4,
    income: 2538.8
  },
  "1981-05-01": {
    expenses: 1913.8,
    income: 2558.1
  },
  "1981-06-01": {
    expenses: 1934.5,
    income: 2582.5
  },
  "1981-07-01": {
    expenses: 1942.1,
    income: 2637.2
  },
  "1981-08-01": {
    expenses: 1966.6,
    income: 2663.9
  },
  "1981-09-01": {
    expenses: 1965.5,
    income: 2676.3
  },
  "1981-10-01": {
    expenses: 1963.9,
    income: 2678.6
  },
  "1981-11-01": {
    expenses: 1970.6,
    income: 2696.3
  },
  "1981-12-01": {
    expenses: 1988.8,
    income: 2703.1
  },
  "1982-01-01": {
    expenses: 1997.1,
    income: 2713.1
  },
  "1982-02-01": {
    expenses: 2021.2,
    income: 2732.7
  },
  "1982-03-01": {
    expenses: 2024.1,
    income: 2737.8
  },
  "1982-04-01": {
    expenses: 2026.3,
    income: 2758.1
  },
  "1982-05-01": {
    expenses: 2044.5,
    income: 2774.1
  },
  "1982-06-01": {
    expenses: 2048.1,
    income: 2784.7
  },
  "1982-07-01": {
    expenses: 2072.2,
    income: 2801.1
  },
  "1982-08-01": {
    expenses: 2080.1,
    income: 2815
  },
  "1982-09-01": {
    expenses: 2104.6,
    income: 2821.3
  },
  "1982-10-01": {
    expenses: 2125.8,
    income: 2834.9
  },
  "1982-11-01": {
    expenses: 2149.3,
    income: 2854
  },
  "1982-12-01": {
    expenses: 2161.6,
    income: 2872.6
  },
  "1983-01-01": {
    expenses: 2174,
    income: 2882.2
  },
  "1983-02-01": {
    expenses: 2177,
    income: 2888.8
  },
  "1983-03-01": {
    expenses: 2202.8,
    income: 2906.2
  },
  "1983-04-01": {
    expenses: 2226.4,
    income: 2921.6
  },
  "1983-05-01": {
    expenses: 2245.9,
    income: 2944.8
  },
  "1983-06-01": {
    expenses: 2276,
    income: 2959.8
  },
  "1983-07-01": {
    expenses: 2304.4,
    income: 2985
  },
  "1983-08-01": {
    expenses: 2320.4,
    income: 2993.7
  },
  "1983-09-01": {
    expenses: 2334.9,
    income: 3024.9
  },
  "1983-10-01": {
    expenses: 2357.6,
    income: 3059.3
  },
  "1983-11-01": {
    expenses: 2366.3,
    income: 3088.5
  },
  "1983-12-01": {
    expenses: 2393.6,
    income: 3117.9
  },
  "1984-01-01": {
    expenses: 2419.4,
    income: 3142.7
  },
  "1984-02-01": {
    expenses: 2403.5,
    income: 3182.1
  },
  "1984-03-01": {
    expenses: 2431.6,
    income: 3212.1
  },
  "1984-04-01": {
    expenses: 2457.5,
    income: 3245.1
  },
  "1984-05-01": {
    expenses: 2474.5,
    income: 3256.6
  },
  "1984-06-01": {
    expenses: 2495.6,
    income: 3286.9
  },
  "1984-07-01": {
    expenses: 2494.6,
    income: 3306.1
  },
  "1984-08-01": {
    expenses: 2512.2,
    income: 3338
  },
  "1984-09-01": {
    expenses: 2533.8,
    income: 3368
  },
  "1984-10-01": {
    expenses: 2531.3,
    income: 3366
  },
  "1984-11-01": {
    expenses: 2571.4,
    income: 3388.3
  },
  "1984-12-01": {
    expenses: 2582.6,
    income: 3420.7
  },
  "1985-01-01": {
    expenses: 2618.8,
    income: 3433.9
  },
  "1985-02-01": {
    expenses: 2640.8,
    income: 3452.3
  },
  "1985-03-01": {
    expenses: 2648.5,
    income: 3480
  },
  "1985-04-01": {
    expenses: 2659.5,
    income: 3478.1
  },
  "1985-05-01": {
    expenses: 2696.4,
    income: 3493.4
  },
  "1985-06-01": {
    expenses: 2689.4,
    income: 3514.9
  },
  "1985-07-01": {
    expenses: 2715.7,
    income: 3528.4
  },
  "1985-08-01": {
    expenses: 2752.1,
    income: 3539.5
  },
  "1985-09-01": {
    expenses: 2794.7,
    income: 3561.4
  },
  "1985-10-01": {
    expenses: 2755.8,
    income: 3584
  },
  "1985-11-01": {
    expenses: 2771.1,
    income: 3599.5
  },
  "1985-12-01": {
    expenses: 2811.3,
    income: 3633.1
  },
  "1986-01-01": {
    expenses: 2827.1,
    income: 3647
  },
  "1986-02-01": {
    expenses: 2820.2,
    income: 3664.7
  },
  "1986-03-01": {
    expenses: 2823.6,
    income: 3691.2
  },
  "1986-04-01": {
    expenses: 2835.2,
    income: 3696.1
  },
  "1986-05-01": {
    expenses: 2857.5,
    income: 3707.3
  },
  "1986-06-01": {
    expenses: 2861.7,
    income: 3722.9
  },
  "1986-07-01": {
    expenses: 2881.2,
    income: 3742.4
  },
  "1986-08-01": {
    expenses: 2898.6,
    income: 3756
  },
  "1986-09-01": {
    expenses: 2971.8,
    income: 3773
  },
  "1986-10-01": {
    expenses: 2932.9,
    income: 3782.2
  },
  "1986-11-01": {
    expenses: 2928.4,
    income: 3796.8
  },
  "1986-12-01": {
    expenses: 2997.1,
    income: 3817.4
  },
  "1987-01-01": {
    expenses: 2935.5,
    income: 3831.4
  },
  "1987-02-01": {
    expenses: 3001.7,
    income: 3858.1
  },
  "1987-03-01": {
    expenses: 3013.3,
    income: 3878.4
  },
  "1987-04-01": {
    expenses: 3038.8,
    income: 3891.1
  },
  "1987-05-01": {
    expenses: 3048.4,
    income: 3925
  },
  "1987-06-01": {
    expenses: 3072.8,
    income: 3936.5
  },
  "1987-07-01": {
    expenses: 3094.7,
    income: 3958.9
  },
  "1987-08-01": {
    expenses: 3130.8,
    income: 3995.2
  },
  "1987-09-01": {
    expenses: 3126.5,
    income: 4011.5
  },
  "1987-10-01": {
    expenses: 3134.5,
    income: 4049.8
  },
  "1987-11-01": {
    expenses: 3144.2,
    income: 4075.8
  },
  "1987-12-01": {
    expenses: 3174.1,
    income: 4127.4
  },
  "1988-01-01": {
    expenses: 3213.7,
    income: 4137.6
  },
  "1988-02-01": {
    expenses: 3221.4,
    income: 4160.3
  },
  "1988-03-01": {
    expenses: 3260.5,
    income: 4183.9
  },
  "1988-04-01": {
    expenses: 3263,
    income: 4213.8
  },
  "1988-05-01": {
    expenses: 3293.6,
    income: 4229.2
  },
  "1988-06-01": {
    expenses: 3318.5,
    income: 4261.4
  },
  "1988-07-01": {
    expenses: 3342.7,
    income: 4300.8
  },
  "1988-08-01": {
    expenses: 3368,
    income: 4324.5
  },
  "1988-09-01": {
    expenses: 3375,
    income: 4353.7
  },
  "1988-10-01": {
    expenses: 3413.7,
    income: 4392
  },
  "1988-11-01": {
    expenses: 3430.2,
    income: 4403.6
  },
  "1988-12-01": {
    expenses: 3459.7,
    income: 4440.2
  },
  "1989-01-01": {
    expenses: 3483.7,
    income: 4504.1
  },
  "1989-02-01": {
    expenses: 3488,
    income: 4533.2
  },
  "1989-03-01": {
    expenses: 3498.8,
    income: 4574.4
  },
  "1989-04-01": {
    expenses: 3543,
    income: 4587.1
  },
  "1989-05-01": {
    expenses: 3551.8,
    income: 4584.3
  },
  "1989-06-01": {
    expenses: 3566.6,
    income: 4607.3
  },
  "1989-07-01": {
    expenses: 3585.7,
    income: 4633.7
  },
  "1989-08-01": {
    expenses: 3620.6,
    income: 4644.3
  },
  "1989-09-01": {
    expenses: 3621.9,
    income: 4667.5
  },
  "1989-10-01": {
    expenses: 3633.6,
    income: 4708.1
  },
  "1989-11-01": {
    expenses: 3643.3,
    income: 4722.8
  },
  "1989-12-01": {
    expenses: 3684.2,
    income: 4740.1
  },
  "1990-01-01": {
    expenses: 3730.7,
    income: 4787.7
  },
  "1990-02-01": {
    expenses: 3728.2,
    income: 4823.2
  },
  "1990-03-01": {
    expenses: 3754.9,
    income: 4845.6
  },
  "1990-04-01": {
    expenses: 3770,
    income: 4886.1
  },
  "1990-05-01": {
    expenses: 3775.8,
    income: 4891.2
  },
  "1990-06-01": {
    expenses: 3804.5,
    income: 4920.3
  },
  "1990-07-01": {
    expenses: 3821.7,
    income: 4948.5
  },
  "1990-08-01": {
    expenses: 3848.3,
    income: 4949.5
  },
  "1990-09-01": {
    expenses: 3870.1,
    income: 4977.5
  },
  "1990-10-01": {
    expenses: 3870.6,
    income: 4964.6
  },
  "1990-11-01": {
    expenses: 3871.9,
    income: 4970.7
  },
  "1990-12-01": {
    expenses: 3861.3,
    income: 5000.4
  },
  "1991-01-01": {
    expenses: 3841,
    income: 4985.8
  },
  "1991-02-01": {
    expenses: 3866.7,
    income: 4991.7
  },
  "1991-03-01": {
    expenses: 3913,
    income: 4999.9
  },
  "1991-04-01": {
    expenses: 3907.1,
    income: 5028
  },
  "1991-05-01": {
    expenses: 3933.2,
    income: 5049.5
  },
  "1991-06-01": {
    expenses: 3940.5,
    income: 5084.7
  },
  "1991-07-01": {
    expenses: 3966,
    income: 5081.2
  },
  "1991-08-01": {
    expenses: 3969.1,
    income: 5103.2
  },
  "1991-09-01": {
    expenses: 3984.7,
    income: 5136.8
  },
  "1991-10-01": {
    expenses: 3976,
    income: 5153.5
  },
  "1991-11-01": {
    expenses: 4003.6,
    income: 5173.7
  },
  "1991-12-01": {
    expenses: 4020.5,
    income: 5230.9
  },
  "1992-01-01": {
    expenses: 4084.7,
    income: 5264.2
  },
  "1992-02-01": {
    expenses: 4099.5,
    income: 5303.8
  },
  "1992-03-01": {
    expenses: 4117,
    income: 5325.5
  },
  "1992-04-01": {
    expenses: 4131.5,
    income: 5359.8
  },
  "1992-05-01": {
    expenses: 4158.4,
    income: 5395.6
  },
  "1992-06-01": {
    expenses: 4177.1,
    income: 5427.8
  },
  "1992-07-01": {
    expenses: 4204.8,
    income: 5441.3
  },
  "1992-08-01": {
    expenses: 4220.9,
    income: 5469.7
  },
  "1992-09-01": {
    expenses: 4255.3,
    income: 5457.7
  },
  "1992-10-01": {
    expenses: 4284.7,
    income: 5450.1
  },
  "1992-11-01": {
    expenses: 4300.5,
    income: 5472.7
  },
  "1992-12-01": {
    expenses: 4336.4,
    income: 5682.1
  },
  "1993-01-01": {
    expenses: 4340.7,
    income: 5539.2
  },
  "1993-02-01": {
    expenses: 4355.3,
    income: 5573
  },
  "1993-03-01": {
    expenses: 4352.5,
    income: 5569.9
  },
  "1993-04-01": {
    expenses: 4393.4,
    income: 5626.7
  },
  "1993-05-01": {
    expenses: 4422.4,
    income: 5642.8
  },
  "1993-06-01": {
    expenses: 4440,
    income: 5637.9
  },
  "1993-07-01": {
    expenses: 4468.9,
    income: 5665.2
  },
  "1993-08-01": {
    expenses: 4481.1,
    income: 5687.2
  },
  "1993-09-01": {
    expenses: 4511.5,
    income: 5680.3
  },
  "1993-10-01": {
    expenses: 4532.8,
    income: 5674.3
  },
  "1993-11-01": {
    expenses: 4554.1,
    income: 5699.6
  },
  "1993-12-01": {
    expenses: 4571.1,
    income: 5899.1
  },
  "1994-01-01": {
    expenses: 4585.1,
    income: 5784.5
  },
  "1994-02-01": {
    expenses: 4632.6,
    income: 5803.8
  },
  "1994-03-01": {
    expenses: 4646,
    income: 5835.5
  },
  "1994-04-01": {
    expenses: 4671.1,
    income: 5896.8
  },
  "1994-05-01": {
    expenses: 4669.5,
    income: 5917.5
  },
  "1994-06-01": {
    expenses: 4708.9,
    income: 5923.9
  },
  "1994-07-01": {
    expenses: 4720.6,
    income: 5952.6
  },
  "1994-08-01": {
    expenses: 4762.6,
    income: 5974.8
  },
  "1994-09-01": {
    expenses: 4775,
    income: 6007.6
  },
  "1994-10-01": {
    expenses: 4812.9,
    income: 6071.6
  },
  "1994-11-01": {
    expenses: 4825.6,
    income: 6081.8
  },
  "1994-12-01": {
    expenses: 4841.6,
    income: 6114.7
  },
  "1995-01-01": {
    expenses: 4851.2,
    income: 6155.5
  },
  "1995-02-01": {
    expenses: 4850.8,
    income: 6180.4
  },
  "1995-03-01": {
    expenses: 4885.4,
    income: 6207.6
  },
  "1995-04-01": {
    expenses: 4890.2,
    income: 6231.3
  },
  "1995-05-01": {
    expenses: 4933.1,
    income: 6245.8
  },
  "1995-06-01": {
    expenses: 4977.5,
    income: 6275.1
  },
  "1995-07-01": {
    expenses: 4970.2,
    income: 6304.3
  },
  "1995-08-01": {
    expenses: 5005.3,
    income: 6327.2
  },
  "1995-09-01": {
    expenses: 5020.5,
    income: 6356.2
  },
  "1995-10-01": {
    expenses: 5013.9,
    income: 6382.3
  },
  "1995-11-01": {
    expenses: 5055.6,
    income: 6403.1
  },
  "1995-12-01": {
    expenses: 5097.5,
    income: 6427.4
  },
  "1996-01-01": {
    expenses: 5085.7,
    income: 6463.8
  },
  "1996-02-01": {
    expenses: 5132.8,
    income: 6528.1
  },
  "1996-03-01": {
    expenses: 5173.3,
    income: 6570.9
  },
  "1996-04-01": {
    expenses: 5208,
    income: 6608.1
  },
  "1996-05-01": {
    expenses: 5223.8,
    income: 6648.4
  },
  "1996-06-01": {
    expenses: 5229.8,
    income: 6693
  },
  "1996-07-01": {
    expenses: 5251.9,
    income: 6694
  },
  "1996-08-01": {
    expenses: 5275,
    income: 6722.5
  },
  "1996-09-01": {
    expenses: 5296.6,
    income: 6761.1
  },
  "1996-10-01": {
    expenses: 5328.5,
    income: 6783
  },
  "1996-11-01": {
    expenses: 5351.2,
    income: 6817.2
  },
  "1996-12-01": {
    expenses: 5378.6,
    income: 6852.1
  },
  "1997-01-01": {
    expenses: 5411.1,
    income: 6901.2
  },
  "1997-02-01": {
    expenses: 5434,
    income: 6938.1
  },
  "1997-03-01": {
    expenses: 5454.2,
    income: 6978.7
  },
  "1997-04-01": {
    expenses: 5459.3,
    income: 6991.4
  },
  "1997-05-01": {
    expenses: 5460.2,
    income: 7020.4
  },
  "1997-06-01": {
    expenses: 5494.2,
    income: 7054.9
  },
  "1997-07-01": {
    expenses: 5548.8,
    income: 7090.6
  },
  "1997-08-01": {
    expenses: 5587,
    income: 7138.2
  },
  "1997-09-01": {
    expenses: 5601.7,
    income: 7177.1
  },
  "1997-10-01": {
    expenses: 5637.7,
    income: 7224.3
  },
  "1997-11-01": {
    expenses: 5661.1,
    income: 7276.1
  },
  "1997-12-01": {
    expenses: 5692.1,
    income: 7318.6
  },
  "1998-01-01": {
    expenses: 5689.9,
    income: 7387.7
  },
  "1998-02-01": {
    expenses: 5723.8,
    income: 7434.5
  },
  "1998-03-01": {
    expenses: 5750.3,
    income: 7481.4
  },
  "1998-04-01": {
    expenses: 5788.1,
    income: 7518.6
  },
  "1998-05-01": {
    expenses: 5837.9,
    income: 7563.6
  },
  "1998-06-01": {
    expenses: 5871.7,
    income: 7601
  },
  "1998-07-01": {
    expenses: 5890,
    income: 7633.9
  },
  "1998-08-01": {
    expenses: 5925,
    income: 7673.9
  },
  "1998-09-01": {
    expenses: 5965.6,
    income: 7696.6
  },
  "1998-10-01": {
    expenses: 5998.8,
    income: 7728.5
  },
  "1998-11-01": {
    expenses: 6015.4,
    income: 7766.6
  },
  "1998-12-01": {
    expenses: 6070.5,
    income: 7793.5
  },
  "1999-01-01": {
    expenses: 6073.9,
    income: 7834.6
  },
  "1999-02-01": {
    expenses: 6099.2,
    income: 7863.4
  },
  "1999-03-01": {
    expenses: 6133,
    income: 7879.4
  },
  "1999-04-01": {
    expenses: 6199.5,
    income: 7895.9
  },
  "1999-05-01": {
    expenses: 6232.1,
    income: 7920.3
  },
  "1999-06-01": {
    expenses: 6260.3,
    income: 7952.6
  },
  "1999-07-01": {
    expenses: 6287.6,
    income: 7994.8
  },
  "1999-08-01": {
    expenses: 6331.9,
    income: 8047.2
  },
  "1999-09-01": {
    expenses: 6386.5,
    income: 8072.9
  },
  "1999-10-01": {
    expenses: 6411.4,
    income: 8135.5
  },
  "1999-11-01": {
    expenses: 6448.7,
    income: 8199.3
  },
  "1999-12-01": {
    expenses: 6541,
    income: 8285.8
  },
  "2000-01-01": {
    expenses: 6542.9,
    income: 8382.6
  },
  "2000-02-01": {
    expenses: 6625.3,
    income: 8443.7
  },
  "2000-03-01": {
    expenses: 6686.5,
    income: 8503.7
  },
  "2000-04-01": {
    expenses: 6679.1,
    income: 8542.9
  },
  "2000-05-01": {
    expenses: 6709.7,
    income: 8580.9
  },
  "2000-06-01": {
    expenses: 6746.9,
    income: 8634.2
  },
  "2000-07-01": {
    expenses: 6768.5,
    income: 8701.2
  },
  "2000-08-01": {
    expenses: 6802.8,
    income: 8747.4
  },
  "2000-09-01": {
    expenses: 6888.6,
    income: 8792.1
  },
  "2000-10-01": {
    expenses: 6893.8,
    income: 8825.5
  },
  "2000-11-01": {
    expenses: 6909.8,
    income: 8842
  },
  "2000-12-01": {
    expenses: 6952.2,
    income: 8874.7
  },
  "2001-01-01": {
    expenses: 6987.4,
    income: 8975.6
  },
  "2001-02-01": {
    expenses: 7001.8,
    income: 9010.9
  },
  "2001-03-01": {
    expenses: 6996.7,
    income: 9041.2
  },
  "2001-04-01": {
    expenses: 7004.2,
    income: 9033.8
  },
  "2001-05-01": {
    expenses: 7052.8,
    income: 9030.6
  },
  "2001-06-01": {
    expenses: 7069.7,
    income: 9032.9
  },
  "2001-07-01": {
    expenses: 7082.3,
    income: 9028.1
  },
  "2001-08-01": {
    expenses: 7121.7,
    income: 9013.6
  },
  "2001-09-01": {
    expenses: 7007,
    income: 8987.3
  },
  "2001-10-01": {
    expenses: 7212.9,
    income: 9000
  },
  "2001-11-01": {
    expenses: 7182.3,
    income: 8997.6
  },
  "2001-12-01": {
    expenses: 7166.7,
    income: 9002.1
  },
  "2002-01-01": {
    expenses: 7184.6,
    income: 9025.1
  },
  "2002-02-01": {
    expenses: 7225.2,
    income: 9040.4
  },
  "2002-03-01": {
    expenses: 7243.4,
    income: 9059
  },
  "2002-04-01": {
    expenses: 7312.5,
    income: 9108.7
  },
  "2002-05-01": {
    expenses: 7288.9,
    income: 9140.4
  },
  "2002-06-01": {
    expenses: 7322.5,
    income: 9174.8
  },
  "2002-07-01": {
    expenses: 7387.3,
    income: 9168.1
  },
  "2002-08-01": {
    expenses: 7412.6,
    income: 9186.9
  },
  "2002-09-01": {
    expenses: 7391.5,
    income: 9210.1
  },
  "2002-10-01": {
    expenses: 7435.2,
    income: 9244.4
  },
  "2002-11-01": {
    expenses: 7463.8,
    income: 9273.3
  },
  "2002-12-01": {
    expenses: 7519.9,
    income: 9300.2
  },
  "2003-01-01": {
    expenses: 7541.3,
    income: 9297.2
  },
  "2003-02-01": {
    expenses: 7548.6,
    income: 9302
  },
  "2003-03-01": {
    expenses: 7611.5,
    income: 9341.7
  },
  "2003-04-01": {
    expenses: 7634.5,
    income: 9369.8
  },
  "2003-05-01": {
    expenses: 7650.3,
    income: 9431.9
  },
  "2003-06-01": {
    expenses: 7699.6,
    income: 9475.2
  },
  "2003-07-01": {
    expenses: 7757,
    income: 9512.4
  },
  "2003-08-01": {
    expenses: 7852.1,
    income: 9560.2
  },
  "2003-09-01": {
    expenses: 7853.7,
    income: 9604.3
  },
  "2003-10-01": {
    expenses: 7867.4,
    income: 9642.4
  },
  "2003-11-01": {
    expenses: 7922.6,
    income: 9710.1
  },
  "2003-12-01": {
    expenses: 7950.4,
    income: 9734.9
  },
  "2004-01-01": {
    expenses: 8007.1,
    income: 9753.2
  },
  "2004-02-01": {
    expenses: 8040.4,
    income: 9786.7
  },
  "2004-03-01": {
    expenses: 8098.8,
    income: 9837.2
  },
  "2004-04-01": {
    expenses: 8107.2,
    income: 9887.5
  },
  "2004-05-01": {
    expenses: 8176.5,
    income: 9972.9
  },
  "2004-06-01": {
    expenses: 8157.6,
    income: 10013
  },
  "2004-07-01": {
    expenses: 8236.9,
    income: 10060
  },
  "2004-08-01": {
    expenses: 8271.6,
    income: 10107.8
  },
  "2004-09-01": {
    expenses: 8341.5,
    income: 10142.9
  },
  "2004-10-01": {
    expenses: 8397.1,
    income: 10194.7
  },
  "2004-11-01": {
    expenses: 8444.5,
    income: 10210.6
  },
  "2004-12-01": {
    expenses: 8504.4,
    income: 10564.5
  },
  "2005-01-01": {
    expenses: 8497.7,
    income: 10296.1
  },
  "2005-02-01": {
    expenses: 8559.1,
    income: 10335.8
  },
  "2005-03-01": {
    expenses: 8598.4,
    income: 10403.5
  },
  "2005-04-01": {
    expenses: 8678.4,
    income: 10468.7
  },
  "2005-05-01": {
    expenses: 8671.6,
    income: 10522.6
  },
  "2005-06-01": {
    expenses: 8753.4,
    income: 10559.1
  },
  "2005-07-01": {
    expenses: 8853.8,
    income: 10622.7
  },
  "2005-08-01": {
    expenses: 8850.1,
    income: 10684.7
  },
  "2005-09-01": {
    expenses: 8900.4,
    income: 10750.8
  },
  "2005-10-01": {
    expenses: 8938.5,
    income: 10825.6
  },
  "2005-11-01": {
    expenses: 8946.2,
    income: 10873.6
  },
  "2005-12-01": {
    expenses: 8981.1,
    income: 10915.9
  },
  "2006-01-01": {
    expenses: 9071.6,
    income: 11110.9
  },
  "2006-02-01": {
    expenses: 9096,
    income: 11180.6
  },
  "2006-03-01": {
    expenses: 9132.9,
    income: 11251.7
  },
  "2006-04-01": {
    expenses: 9191.6,
    income: 11307.8
  },
  "2006-05-01": {
    expenses: 9231.8,
    income: 11329.1
  },
  "2006-06-01": {
    expenses: 9259.6,
    income: 11372
  },
  "2006-07-01": {
    expenses: 9343.8,
    income: 11404.2
  },
  "2006-08-01": {
    expenses: 9342.2,
    income: 11431.6
  },
  "2006-09-01": {
    expenses: 9375.4,
    income: 11471.2
  },
  "2006-10-01": {
    expenses: 9393.6,
    income: 11507.6
  },
  "2006-11-01": {
    expenses: 9400.2,
    income: 11570.5
  },
  "2006-12-01": {
    expenses: 9488.3,
    income: 11679.2
  },
  "2007-01-01": {
    expenses: 9538.7,
    income: 11737.9
  },
  "2007-02-01": {
    expenses: 9566,
    income: 11824.1
  },
  "2007-03-01": {
    expenses: 9611.7,
    income: 11910.2
  },
  "2007-04-01": {
    expenses: 9643.6,
    income: 11955.5
  },
  "2007-05-01": {
    expenses: 9685.8,
    income: 11995.3
  },
  "2007-06-01": {
    expenses: 9706.8,
    income: 12015.3
  },
  "2007-07-01": {
    expenses: 9751.1,
    income: 12041.5
  },
  "2007-08-01": {
    expenses: 9798.9,
    income: 12050.3
  },
  "2007-09-01": {
    expenses: 9845.1,
    income: 12115.9
  },
  "2007-10-01": {
    expenses: 9882.7,
    income: 12142.4
  },
  "2007-11-01": {
    expenses: 9955.9,
    income: 12197.5
  },
  "2007-12-01": {
    expenses: 9972.8,
    income: 12270.4
  },
  "2008-01-01": {
    expenses: 9996.4,
    income: 12302.1
  },
  "2008-02-01": {
    expenses: 9981.7,
    income: 12329.8
  },
  "2008-03-01": {
    expenses: 10035.3,
    income: 12374
  },
  "2008-04-01": {
    expenses: 10070.3,
    income: 12360.2
  },
  "2008-05-01": {
    expenses: 10132.3,
    income: 12934.6
  },
  "2008-06-01": {
    expenses: 10187.1,
    income: 12713.5
  },
  "2008-07-01": {
    expenses: 10185.1,
    income: 12564
  },
  "2008-08-01": {
    expenses: 10175.7,
    income: 12472.5
  },
  "2008-09-01": {
    expenses: 10116.4,
    income: 12489.4
  },
  "2008-10-01": {
    expenses: 10034.1,
    income: 12489.4
  },
  "2008-11-01": {
    expenses: 9885.2,
    income: 12427.1
  },
  "2008-12-01": {
    expenses: 9801.5,
    income: 12274.1
  },
  "2009-01-01": {
    expenses: 9847.2,
    income: 12155.6
  },
  "2009-02-01": {
    expenses: 9824.5,
    income: 12031.7
  },
  "2009-03-01": {
    expenses: 9773.2,
    income: 11983.8
  },
  "2009-04-01": {
    expenses: 9772.5,
    income: 12022.2
  },
  "2009-05-01": {
    expenses: 9791.6,
    income: 12188.9
  },
  "2009-06-01": {
    expenses: 9852.4,
    income: 12050.9
  },
  "2009-07-01": {
    expenses: 9886.3,
    income: 12007.4
  },
  "2009-08-01": {
    expenses: 10004.1,
    income: 12021
  },
  "2009-09-01": {
    expenses: 9927.8,
    income: 12071.4
  },
  "2009-10-01": {
    expenses: 9976.7,
    income: 12080.2
  },
  "2009-11-01": {
    expenses: 9985.7,
    income: 12143.6
  },
  "2009-12-01": {
    expenses: 10052.6,
    income: 12208.3
  },
  "2010-01-01": {
    expenses: 10056.1,
    income: 12296.6
  },
  "2010-02-01": {
    expenses: 10093.4,
    income: 12301.4
  },
  "2010-03-01": {
    expenses: 10156,
    income: 12365.6
  },
  "2010-04-01": {
    expenses: 10182.3,
    income: 12473.3
  },
  "2010-05-01": {
    expenses: 10210.8,
    income: 12575.2
  },
  "2010-06-01": {
    expenses: 10231.3,
    income: 12585.4
  },
  "2010-07-01": {
    expenses: 10268.1,
    income: 12629.5
  },
  "2010-08-01": {
    expenses: 10307.1,
    income: 12697.4
  },
  "2010-09-01": {
    expenses: 10327.1,
    income: 12719.1
  },
  "2010-10-01": {
    expenses: 10386.4,
    income: 12768
  },
  "2010-11-01": {
    expenses: 10433.6,
    income: 12808.4
  },
  "2010-12-01": {
    expenses: 10471,
    income: 12914.3
  },
  "2011-01-01": {
    expenses: 10514.3,
    income: 13120
  },
  "2011-02-01": {
    expenses: 10540.6,
    income: 13193.3
  },
  "2011-03-01": {
    expenses: 10619.7,
    income: 13214.3
  },
  "2011-04-01": {
    expenses: 10652.1,
    income: 13247.4
  },
  "2011-05-01": {
    expenses: 10672.2,
    income: 13270.9
  },
  "2011-06-01": {
    expenses: 10694.8,
    income: 13323.5
  },
  "2011-07-01": {
    expenses: 10731.6,
    income: 13398.4
  },
  "2011-08-01": {
    expenses: 10750.3,
    income: 13420.6
  },
  "2011-09-01": {
    expenses: 10783.2,
    income: 13422.3
  },
  "2011-10-01": {
    expenses: 10802.9,
    income: 13430.4
  },
  "2011-11-01": {
    expenses: 10806.8,
    income: 13447.9
  },
  "2011-12-01": {
    expenses: 10817.8,
    income: 13582.4
  },
  "2012-01-01": {
    expenses: 10896.8,
    income: 13683.6
  },
  "2012-02-01": {
    expenses: 10987.2,
    income: 13798.1
  },
  "2012-03-01": {
    expenses: 10993.9,
    income: 13877.1
  },
  "2012-04-01": {
    expenses: 11018.5,
    income: 13954.9
  },
  "2012-05-01": {
    expenses: 11006.8,
    income: 13944.6
  },
  "2012-06-01": {
    expenses: 10989.8,
    income: 13938.1
  },
  "2012-07-01": {
    expenses: 11016.8,
    income: 13859.9
  },
  "2012-08-01": {
    expenses: 11056,
    income: 13879.8
  },
  "2012-09-01": {
    expenses: 11105.3,
    income: 14028.2
  },
  "2012-10-01": {
    expenses: 11137.4,
    income: 14164.6
  },
  "2012-11-01": {
    expenses: 11178.4,
    income: 14346.4
  },
  "2012-12-01": {
    expenses: 11181.2,
    income: 14696.7
  },
  "2013-01-01": {
    expenses: 11245.8,
    income: 13996.7
  },
  "2013-02-01": {
    expenses: 11282.1,
    income: 14015.4
  },
  "2013-03-01": {
    expenses: 11268.9,
    income: 14046.4
  },
  "2013-04-01": {
    expenses: 11259.3,
    income: 14089.6
  },
  "2013-05-01": {
    expenses: 11295.1,
    income: 14168.4
  },
  "2013-06-01": {
    expenses: 11318.5,
    income: 14200.8
  },
  "2013-07-01": {
    expenses: 11346.8,
    income: 14191.1
  },
  "2013-08-01": {
    expenses: 11376.9,
    income: 14240.1
  },
  "2013-09-01": {
    expenses: 11413.9,
    income: 14301.2
  },
  "2013-10-01": {
    expenses: 11465.2,
    income: 14290.7
  },
  "2013-11-01": {
    expenses: 11531.3,
    income: 14357.7
  },
  "2013-12-01": {
    expenses: 11558.6,
    income: 14425.7
  },
  "2014-01-01": {
    expenses: 11543.7,
    income: 14529.9
  },
  "2014-02-01": {
    expenses: 11615.4,
    income: 14620.1
  },
  "2014-03-01": {
    expenses: 11695.2,
    income: 14725.2
  },
  "2014-04-01": {
    expenses: 11737.4,
    income: 14800.2
  },
  "2014-05-01": {
    expenses: 11778.6,
    income: 14872.4
  },
  "2014-06-01": {
    expenses: 11838,
    income: 14951.3
  },
  "2014-07-01": {
    expenses: 11879.2,
    income: 15026.9
  },
  "2014-08-01": {
    expenses: 11958.8,
    income: 15105.5
  },
  "2014-09-01": {
    expenses: 11964.9,
    income: 15166
  },
  "2014-10-01": {
    expenses: 12035.5,
    income: 15233.2
  },
  "2014-11-01": {
    expenses: 12058.4,
    income: 15304.9
  },
  "2014-12-01": {
    expenses: 12067.6,
    income: 15383.1
  },
  "2015-01-01": {
    expenses: 12036.5,
    income: 15444.5
  },
  "2015-02-01": {
    expenses: 12083.1,
    income: 15525.3
  },
  "2015-03-01": {
    expenses: 12132.2,
    income: 15523.4
  },
  "2015-04-01": {
    expenses: 12170.3,
    income: 15581.5
  },
  "2015-05-01": {
    expenses: 12233.6,
    income: 15650.7
  },
  "2015-06-01": {
    expenses: 12270.3,
    income: 15687.4
  },
  "2015-07-01": {
    expenses: 12327.5,
    income: 15727.5
  },
  "2015-08-01": {
    expenses: 12359.3,
    income: 15761.1
  },
  "2015-09-01": {
    expenses: 12356.4,
    income: 15782.3
  },
  "2015-10-01": {
    expenses: 12362.3,
    income: 15822.4
  },
  "2015-11-01": {
    expenses: 12397.5,
    income: 15831
  },
  "2015-12-01": {
    expenses: 12432.8,
    income: 15885.7
  },
  "2016-01-01": {
    expenses: 12452.1,
    income: 15906.1
  },
  "2016-02-01": {
    expenses: 12526.3,
    income: 15906.3
  },
  "2016-03-01": {
    expenses: 12506.8,
    income: 15943.3
  },
  "2016-04-01": {
    expenses: 12586,
    income: 15974.1
  },
  "2016-05-01": {
    expenses: 12624.4,
    income: 15993.9
  },
  "2016-06-01": {
    expenses: 12701.7,
    income: 16038.1
  },
  "2016-07-01": {
    expenses: 12720.6,
    income: 16105
  },
  "2016-08-01": {
    expenses: 12749.8,
    income: 16142.6
  },
  "2016-09-01": {
    expenses: 12806.8,
    income: 16207.3
  },
  "2016-10-01": {
    expenses: 12828.1,
    income: 16264.5
  },
  "2016-11-01": {
    expenses: 12853.6,
    income: 16307.3
  },
  "2016-12-01": {
    expenses: 12962.9,
    income: 16374.1
  },
  "2017-01-01": {
    expenses: 13015.1,
    income: 16485.8
  },
  "2017-02-01": {
    expenses: 13034.7,
    income: 16559.1
  },
  "2017-03-01": {
    expenses: 13089.6,
    income: 16611.2
  },
  "2017-04-01": {
    expenses: 13127.7,
    income: 16666.6
  },
  "2017-05-01": {
    expenses: 13128.7,
    income: 16761.8
  },
  "2017-06-01": {
    expenses: 13176.8,
    income: 16790.6
  },
  "2017-07-01": {
    expenses: 13198.3,
    income: 16851.5
  },
  "2017-08-01": {
    expenses: 13241,
    income: 16920
  },
  "2017-09-01": {
    expenses: 13365.1,
    income: 17025.6
  },
  "2017-10-01": {
    expenses: 13394.8,
    income: 17110.8
  },
  "2017-11-01": {
    expenses: 13495.7,
    income: 17177.4
  },
  "2017-12-01": {
    expenses: 13601.8,
    income: 17241.9
  },
  "2018-01-01": {
    expenses: 13620.1,
    income: 17340
  },
  "2018-02-01": {
    expenses: 13657.2,
    income: 17406.8
  },
  "2018-03-01": {
    expenses: 13725,
    income: 17471.4
  },
  "2018-04-01": {
    expenses: 13809.3,
    income: 17525.8
  },
  "2018-05-01": {
    expenses: 13872.1,
    income: 17596
  },
  "2018-06-01": {
    expenses: 13912.9,
    income: 17673.6
  },
  "2018-07-01": {
    expenses: 13962.6,
    income: 17766.6
  },
  "2018-08-01": {
    expenses: 14014.5,
    income: 17837.6
  },
  "2018-09-01": {
    expenses: 14030.7,
    income: 17859.1
  },
  "2018-10-01": {
    expenses: 14119.6,
    income: 17912
  },
  "2018-11-01": {
    expenses: 14187.8,
    income: 17945.9
  },
  "2018-12-01": {
    expenses: 14050.6,
    income: 18137.1
  },
  "2019-01-01": {
    expenses: 14104.4,
    income: 18173.2
  },
  "2019-02-01": {
    expenses: 14117.9,
    income: 18240.7
  },
  "2019-03-01": {
    expenses: 14244.4,
    income: 18302.7
  },
  "2019-04-01": {
    expenses: 14329.3,
    income: 18327.4
  },
  "2019-05-01": {
    expenses: 14372.2,
    income: 18340.6
  },
  "2019-06-01": {
    expenses: 14425.7,
    income: 18368.2
  },
  "2019-07-01": {
    expenses: 14487.4,
    income: 18392.3
  },
  "2019-08-01": {
    expenses: 14536.4,
    income: 18479.9
  },
  "2019-09-01": {
    expenses: 14564.7,
    income: 18522.1
  },
  "2019-10-01": {
    expenses: 14607.9,
    income: 18587.4
  },
  "2019-11-01": {
    expenses: 14667.6,
    income: 18683
  },
  "2019-12-01": {
    expenses: 14686.3,
    income: 18675.1
  },
  "2020-01-01": {
    expenses: 14769.9,
    income: 18873.9
  },
  "2020-02-01": {
    expenses: 14785.1,
    income: 19006.4
  },
  "2020-03-01": {
    expenses: 13762.2,
    income: 18646.3
  },
  "2020-04-01": {
    expenses: 12021.8,
    income: 20971.8
  },
  "2020-05-01": {
    expenses: 13058.1,
    income: 20125.8
  },
  "2020-06-01": {
    expenses: 13889.3,
    income: 19948.5
  },
  "2020-07-01": {
    expenses: 14129.2,
    income: 20123.1
  },
  "2020-08-01": {
    expenses: 14270.5,
    income: 19533.9
  },
  "2020-09-01": {
    expenses: 14481.7,
    income: 19675.3
  },
  "2020-10-01": {
    expenses: 14546,
    income: 19628.8
  },
  "2020-11-01": {
    expenses: 14467.3,
    income: 19435
  },
  "2020-12-01": {
    expenses: 14389.5,
    income: 19562.2
  },
  "2021-01-01": {
    expenses: 14857.9,
    income: 21504.5
  },
  "2021-02-01": {
    expenses: 14699.6,
    income: 19955.1
  },
  "2021-03-01": {
    expenses: 15458.9,
    income: 24142.4
  },
  "2021-04-01": {
    expenses: 15618.7,
    income: 20924.5
  },
  "2021-05-01": {
    expenses: 15624.4,
    income: 20514.7
  },
  "2021-06-01": {
    expenses: 15802,
    income: 20570.5
  },
  "2021-07-01": {
    expenses: 15814.9,
    income: 20835.3
  },
  "2021-08-01": {
    expenses: 15991.1,
    income: 20916.3
  },
  "2021-09-01": {
    expenses: 16088.9,
    income: 20719.8
  },
  "2021-10-01": {
    expenses: 16317.8,
    income: 20843.1
  },
  "2021-11-01": {
    expenses: 16409.7,
    income: 20960.5
  },
  "2021-12-01": {
    expenses: 16278.8,
    income: 21034.6
  }
};
const poverty = {
  "1947-01-01": {
    clothing: 17.623,
    food: 39.079,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1947-04-01": {
    clothing: 17.754,
    food: 39.964,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1947-07-01": {
    clothing: 18.215,
    food: 40.652,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1947-10-01": {
    clothing: 18.558,
    food: 41.073,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1948-01-01": {
    clothing: 18.761,
    food: 41.553,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1948-04-01": {
    clothing: 19.184,
    food: 42.455,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1948-07-01": {
    clothing: 19.477,
    food: 41.763,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1948-10-01": {
    clothing: 19.915,
    food: 41.58,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1949-01-01": {
    clothing: 19.483,
    food: 41.046,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1949-04-01": {
    clothing: 18.844,
    food: 40.631,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1949-07-01": {
    clothing: 17.835,
    food: 39.994,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1949-10-01": {
    clothing: 18.289,
    food: 39.745,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1950-01-01": {
    clothing: 18.164,
    food: 40.231,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1950-04-01": {
    clothing: 18.51,
    food: 40.699,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1950-07-01": {
    clothing: 19.636,
    food: 42.135,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1950-10-01": {
    clothing: 19.408,
    food: 42.574,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1951-01-01": {
    clothing: 20.542,
    food: 45.251,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1951-04-01": {
    clothing: 20.097,
    food: 45.574,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1951-07-01": {
    clothing: 20.529,
    food: 46.253,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1951-10-01": {
    clothing: 20.745,
    food: 46.986,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1952-01-01": {
    clothing: 20.439,
    food: 46.963,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1952-04-01": {
    clothing: 20.77,
    food: 48.243,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1952-07-01": {
    clothing: 21.188,
    food: 49.176,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1952-10-01": {
    clothing: 22.331,
    food: 49.293,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1953-01-01": {
    clothing: 21.585,
    food: 49.614,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1953-04-01": {
    clothing: 21.701,
    food: 49.332,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1953-07-01": {
    clothing: 21.195,
    food: 49.084,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1953-10-01": {
    clothing: 20.802,
    food: 49.113,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1954-01-01": {
    clothing: 21.288,
    food: 50.065,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1954-04-01": {
    clothing: 21.257,
    food: 50.221,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1954-07-01": {
    clothing: 21.335,
    food: 50.781,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1954-10-01": {
    clothing: 21.694,
    food: 51.228,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1955-01-01": {
    clothing: 21.865,
    food: 51.475,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1955-04-01": {
    clothing: 22.443,
    food: 51.903,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1955-07-01": {
    clothing: 22.359,
    food: 52.14,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1955-10-01": {
    clothing: 22.832,
    food: 52.671,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1956-01-01": {
    clothing: 22.995,
    food: 53.415,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1956-04-01": {
    clothing: 23.234,
    food: 53.847,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1956-07-01": {
    clothing: 23.646,
    food: 54.423,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1956-10-01": {
    clothing: 23.715,
    food: 54.979,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1957-01-01": {
    clothing: 23.486,
    food: 55.894,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1957-04-01": {
    clothing: 23.425,
    food: 56.643,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1957-07-01": {
    clothing: 23.969,
    food: 57.98,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1957-10-01": {
    clothing: 23.435,
    food: 58.025,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1958-01-01": {
    clothing: 23.215,
    food: 59.284,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1958-04-01": {
    clothing: 23.666,
    food: 59.716,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1958-07-01": {
    clothing: 24.195,
    food: 59.822,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1958-10-01": {
    clothing: 24.599,
    food: 60.248,
    rentandutilities: null,
    standardizedguaranteeschemesmedicine: null
  },
  "1959-01-01": {
    clothing: 24.785,
    food: 61.24,
    rentandutilities: 14.463,
    standardizedguaranteeschemesmedicine: 51.312
  },
  "1959-04-01": {
    clothing: 25.5,
    food: 61.261,
    rentandutilities: 14.727,
    standardizedguaranteeschemesmedicine: 52.171
  },
  "1959-07-01": {
    clothing: 25.44,
    food: 61.768,
    rentandutilities: 14.939,
    standardizedguaranteeschemesmedicine: 53.398
  },
  "1959-10-01": {
    clothing: 25.74,
    food: 62.118,
    rentandutilities: 15.196,
    standardizedguaranteeschemesmedicine: 54.512
  },
  "1960-01-01": {
    clothing: 25.878,
    food: 61.765,
    rentandutilities: 15.547,
    standardizedguaranteeschemesmedicine: 55.463
  },
  "1960-04-01": {
    clothing: 26.196,
    food: 62.939,
    rentandutilities: 16.09,
    standardizedguaranteeschemesmedicine: 56.191
  },
  "1960-07-01": {
    clothing: 25.867,
    food: 62.512,
    rentandutilities: 15.987,
    standardizedguaranteeschemesmedicine: 57.034
  },
  "1960-10-01": {
    clothing: 25.817,
    food: 63.009,
    rentandutilities: 16.498,
    standardizedguaranteeschemesmedicine: 58.193
  },
  "1961-01-01": {
    clothing: 26.063,
    food: 63.704,
    rentandutilities: 16.64,
    standardizedguaranteeschemesmedicine: 58.795
  },
  "1961-04-01": {
    clothing: 26.279,
    food: 63.933,
    rentandutilities: 17.052,
    standardizedguaranteeschemesmedicine: 59.975
  },
  "1961-07-01": {
    clothing: 26.616,
    food: 63.601,
    rentandutilities: 17.222,
    standardizedguaranteeschemesmedicine: 60.641
  },
  "1961-10-01": {
    clothing: 27.325,
    food: 63.75,
    rentandutilities: 17.567,
    standardizedguaranteeschemesmedicine: 61.795
  },
  "1962-01-01": {
    clothing: 27.365,
    food: 64.301,
    rentandutilities: 18.138,
    standardizedguaranteeschemesmedicine: 62.851
  },
  "1962-04-01": {
    clothing: 27.779,
    food: 64.367,
    rentandutilities: 18.816,
    standardizedguaranteeschemesmedicine: 63.865
  },
  "1962-07-01": {
    clothing: 28.015,
    food: 64.997,
    rentandutilities: 19.483,
    standardizedguaranteeschemesmedicine: 64.945
  },
  "1962-10-01": {
    clothing: 28.275,
    food: 64.961,
    rentandutilities: 20.106,
    standardizedguaranteeschemesmedicine: 66.243
  },
  "1963-01-01": {
    clothing: 28.194,
    food: 65.243,
    rentandutilities: 20.469,
    standardizedguaranteeschemesmedicine: 67.114
  },
  "1963-04-01": {
    clothing: 28.421,
    food: 65.345,
    rentandutilities: 20.76,
    standardizedguaranteeschemesmedicine: 67.435
  },
  "1963-07-01": {
    clothing: 29.002,
    food: 66.35,
    rentandutilities: 21.097,
    standardizedguaranteeschemesmedicine: 68.684
  },
  "1963-10-01": {
    clothing: 28.856,
    food: 66.543,
    rentandutilities: 21.584,
    standardizedguaranteeschemesmedicine: 69.596
  },
  "1964-01-01": {
    clothing: 30.146,
    food: 67.648,
    rentandutilities: 22.671,
    standardizedguaranteeschemesmedicine: 70.616
  },
  "1964-04-01": {
    clothing: 31.011,
    food: 68.892,
    rentandutilities: 23.736,
    standardizedguaranteeschemesmedicine: 71.497
  },
  "1964-07-01": {
    clothing: 31.443,
    food: 70.397,
    rentandutilities: 24.727,
    standardizedguaranteeschemesmedicine: 72.611
  },
  "1964-10-01": {
    clothing: 31.739,
    food: 71.024,
    rentandutilities: 25.464,
    standardizedguaranteeschemesmedicine: 73.871
  },
  "1965-01-01": {
    clothing: 31.889,
    food: 71.659,
    rentandutilities: 26.061,
    standardizedguaranteeschemesmedicine: 74.751
  },
  "1965-04-01": {
    clothing: 31.902,
    food: 73.217,
    rentandutilities: 26.194,
    standardizedguaranteeschemesmedicine: 76.061
  },
  "1965-07-01": {
    clothing: 32.75,
    food: 74.787,
    rentandutilities: 25.868,
    standardizedguaranteeschemesmedicine: 77.215
  },
  "1965-10-01": {
    clothing: 34.095,
    food: 78.106,
    rentandutilities: 26.026,
    standardizedguaranteeschemesmedicine: 78.446
  },
  "1966-01-01": {
    clothing: 35.102,
    food: 79.541,
    rentandutilities: 26.731,
    standardizedguaranteeschemesmedicine: 79.458
  },
  "1966-04-01": {
    clothing: 35.524,
    food: 80.59,
    rentandutilities: 28.06,
    standardizedguaranteeschemesmedicine: 80.626
  },
  "1966-07-01": {
    clothing: 36.292,
    food: 81.217,
    rentandutilities: 29.306,
    standardizedguaranteeschemesmedicine: 81.729
  },
  "1966-10-01": {
    clothing: 36.328,
    food: 81.092,
    rentandutilities: 30.616,
    standardizedguaranteeschemesmedicine: 83.089
  },
  "1967-01-01": {
    clothing: 36.76,
    food: 81.868,
    rentandutilities: 31.193,
    standardizedguaranteeschemesmedicine: 84.072
  },
  "1967-04-01": {
    clothing: 37.376,
    food: 81.971,
    rentandutilities: 31.665,
    standardizedguaranteeschemesmedicine: 85.603
  },
  "1967-07-01": {
    clothing: 37.957,
    food: 82.497,
    rentandutilities: 32.135,
    standardizedguaranteeschemesmedicine: 86.892
  },
  "1967-10-01": {
    clothing: 38.097,
    food: 83.899,
    rentandutilities: 32.721,
    standardizedguaranteeschemesmedicine: 88.808
  },
  "1968-01-01": {
    clothing: 39.753,
    food: 85.711,
    rentandutilities: 34.128,
    standardizedguaranteeschemesmedicine: 90.472
  },
  "1968-04-01": {
    clothing: 40.764,
    food: 87.92,
    rentandutilities: 35.947,
    standardizedguaranteeschemesmedicine: 91.499
  },
  "1968-07-01": {
    clothing: 42.141,
    food: 90.157,
    rentandutilities: 37.326,
    standardizedguaranteeschemesmedicine: 93.456
  },
  "1968-10-01": {
    clothing: 42.383,
    food: 91.538,
    rentandutilities: 38.903,
    standardizedguaranteeschemesmedicine: 95.566
  },
  "1969-01-01": {
    clothing: 43.366,
    food: 93.066,
    rentandutilities: 40.26,
    standardizedguaranteeschemesmedicine: 97.712
  },
  "1969-04-01": {
    clothing: 44.045,
    food: 94.114,
    rentandutilities: 41.602,
    standardizedguaranteeschemesmedicine: 99.524
  },
  "1969-07-01": {
    clothing: 44.841,
    food: 95.928,
    rentandutilities: 42.541,
    standardizedguaranteeschemesmedicine: 102.198
  },
  "1969-10-01": {
    clothing: 45.072,
    food: 98.376,
    rentandutilities: 43.831,
    standardizedguaranteeschemesmedicine: 104.494
  },
  "1970-01-01": {
    clothing: 44.88,
    food: 101.638,
    rentandutilities: 45.474,
    standardizedguaranteeschemesmedicine: 106.212
  },
  "1970-04-01": {
    clothing: 45.017,
    food: 102.598,
    rentandutilities: 46.653,
    standardizedguaranteeschemesmedicine: 107.939
  },
  "1970-07-01": {
    clothing: 45.289,
    food: 104.058,
    rentandutilities: 48.272,
    standardizedguaranteeschemesmedicine: 110.471
  },
  "1970-10-01": {
    clothing: 46.616,
    food: 105.73,
    rentandutilities: 50.255,
    standardizedguaranteeschemesmedicine: 113.065
  },
  "1971-01-01": {
    clothing: 47.357,
    food: 105.682,
    rentandutilities: 51.256,
    standardizedguaranteeschemesmedicine: 115.841
  },
  "1971-04-01": {
    clothing: 48.986,
    food: 107.247,
    rentandutilities: 52.882,
    standardizedguaranteeschemesmedicine: 118.632
  },
  "1971-07-01": {
    clothing: 49.5,
    food: 107.132,
    rentandutilities: 54.632,
    standardizedguaranteeschemesmedicine: 121.301
  },
  "1971-10-01": {
    clothing: 50.344,
    food: 108.438,
    rentandutilities: 56.13,
    standardizedguaranteeschemesmedicine: 124.148
  },
  "1972-01-01": {
    clothing: 51.05,
    food: 109.715,
    rentandutilities: 57.619,
    standardizedguaranteeschemesmedicine: 126.807
  },
  "1972-04-01": {
    clothing: 52.478,
    food: 113.834,
    rentandutilities: 59.037,
    standardizedguaranteeschemesmedicine: 129.576
  },
  "1972-07-01": {
    clothing: 54.15,
    food: 115.771,
    rentandutilities: 60.636,
    standardizedguaranteeschemesmedicine: 132.238
  },
  "1972-10-01": {
    clothing: 56.286,
    food: 118.566,
    rentandutilities: 62.078,
    standardizedguaranteeschemesmedicine: 136.119
  },
  "1973-01-01": {
    clothing: 58.311,
    food: 121.14,
    rentandutilities: 64.445,
    standardizedguaranteeschemesmedicine: 138.56
  },
  "1973-04-01": {
    clothing: 58.963,
    food: 124.031,
    rentandutilities: 66.479,
    standardizedguaranteeschemesmedicine: 141.794
  },
  "1973-07-01": {
    clothing: 59.391,
    food: 129.67,
    rentandutilities: 68.055,
    standardizedguaranteeschemesmedicine: 145.317
  },
  "1973-10-01": {
    clothing: 60.305,
    food: 132.017,
    rentandutilities: 69.722,
    standardizedguaranteeschemesmedicine: 148.27
  },
  "1974-01-01": {
    clothing: 61.63,
    food: 137.625,
    rentandutilities: 71.857,
    standardizedguaranteeschemesmedicine: 151.489
  },
  "1974-04-01": {
    clothing: 62.715,
    food: 139.639,
    rentandutilities: 74.612,
    standardizedguaranteeschemesmedicine: 156.434
  },
  "1974-07-01": {
    clothing: 63.568,
    food: 145.761,
    rentandutilities: 77.426,
    standardizedguaranteeschemesmedicine: 160.794
  },
  "1974-10-01": {
    clothing: 61.776,
    food: 149.127,
    rentandutilities: 80.436,
    standardizedguaranteeschemesmedicine: 165.599
  },
  "1975-01-01": {
    clothing: 64.117,
    food: 151.461,
    rentandutilities: 84.534,
    standardizedguaranteeschemesmedicine: 169.97
  },
  "1975-04-01": {
    clothing: 66.474,
    food: 154.808,
    rentandutilities: 87.719,
    standardizedguaranteeschemesmedicine: 174.897
  },
  "1975-07-01": {
    clothing: 68.076,
    food: 159.768,
    rentandutilities: 89.523,
    standardizedguaranteeschemesmedicine: 178.72
  },
  "1975-10-01": {
    clothing: 68.801,
    food: 160.227,
    rentandutilities: 94.224,
    standardizedguaranteeschemesmedicine: 182.518
  },
  "1976-01-01": {
    clothing: 70.257,
    food: 163.337,
    rentandutilities: 97.328,
    standardizedguaranteeschemesmedicine: 186.962
  },
  "1976-04-01": {
    clothing: 71.014,
    food: 165.833,
    rentandutilities: 100.338,
    standardizedguaranteeschemesmedicine: 190.901
  },
  "1976-07-01": {
    clothing: 73.007,
    food: 168.542,
    rentandutilities: 103.355,
    standardizedguaranteeschemesmedicine: 196.503
  },
  "1976-10-01": {
    clothing: 74.485,
    food: 171.476,
    rentandutilities: 106.375,
    standardizedguaranteeschemesmedicine: 204.287
  },
  "1977-01-01": {
    clothing: 75.695,
    food: 175.038,
    rentandutilities: 109.72,
    standardizedguaranteeschemesmedicine: 210.162
  },
  "1977-04-01": {
    clothing: 77.39,
    food: 178.888,
    rentandutilities: 114.804,
    standardizedguaranteeschemesmedicine: 213.281
  },
  "1977-07-01": {
    clothing: 79.847,
    food: 180.282,
    rentandutilities: 117.979,
    standardizedguaranteeschemesmedicine: 221.138
  },
  "1977-10-01": {
    clothing: 84.288,
    food: 184.875,
    rentandutilities: 120.26,
    standardizedguaranteeschemesmedicine: 226.642
  },
  "1978-01-01": {
    clothing: 84.709,
    food: 188.633,
    rentandutilities: 125.249,
    standardizedguaranteeschemesmedicine: 234.466
  },
  "1978-04-01": {
    clothing: 89.074,
    food: 193.791,
    rentandutilities: 129.525,
    standardizedguaranteeschemesmedicine: 241.254
  },
  "1978-07-01": {
    clothing: 90.497,
    food: 198.478,
    rentandutilities: 132.784,
    standardizedguaranteeschemesmedicine: 246.731
  },
  "1978-10-01": {
    clothing: 92.99,
    food: 203.545,
    rentandutilities: 137.25,
    standardizedguaranteeschemesmedicine: 254.663
  },
  "1979-01-01": {
    clothing: 93.176,
    food: 210.921,
    rentandutilities: 141.66,
    standardizedguaranteeschemesmedicine: 262.397
  },
  "1979-04-01": {
    clothing: 94.669,
    food: 215.701,
    rentandutilities: 146.077,
    standardizedguaranteeschemesmedicine: 268.138
  },
  "1979-07-01": {
    clothing: 98.109,
    food: 220.077,
    rentandutilities: 151.006,
    standardizedguaranteeschemesmedicine: 275.989
  },
  "1979-10-01": {
    clothing: 99.53,
    food: 226.781,
    rentandutilities: 156.615,
    standardizedguaranteeschemesmedicine: 287.089
  },
  "1980-01-01": {
    clothing: 100.219,
    food: 232.795,
    rentandutilities: 162.44,
    standardizedguaranteeschemesmedicine: 296.449
  },
  "1980-04-01": {
    clothing: 100.589,
    food: 235.963,
    rentandutilities: 165.68,
    standardizedguaranteeschemesmedicine: 306.343
  },
  "1980-07-01": {
    clothing: 103.932,
    food: 241.285,
    rentandutilities: 174.673,
    standardizedguaranteeschemesmedicine: 317.755
  },
  "1980-10-01": {
    clothing: 107.268,
    food: 246.625,
    rentandutilities: 183.849,
    standardizedguaranteeschemesmedicine: 329.267
  },
  "1981-01-01": {
    clothing: 110.921,
    food: 250.608,
    rentandutilities: 191.413,
    standardizedguaranteeschemesmedicine: 334.179
  },
  "1981-04-01": {
    clothing: 112.453,
    food: 253.984,
    rentandutilities: 199.025,
    standardizedguaranteeschemesmedicine: 347.936
  },
  "1981-07-01": {
    clothing: 114.727,
    food: 256.699,
    rentandutilities: 206.166,
    standardizedguaranteeschemesmedicine: 357.638
  },
  "1981-10-01": {
    clothing: 114.61,
    food: 260.063,
    rentandutilities: 211.047,
    standardizedguaranteeschemesmedicine: 368.532
  },
  "1982-01-01": {
    clothing: 116.066,
    food: 262.257,
    rentandutilities: 215.363,
    standardizedguaranteeschemesmedicine: 378.474
  },
  "1982-04-01": {
    clothing: 114.873,
    food: 265.873,
    rentandutilities: 221.097,
    standardizedguaranteeschemesmedicine: 382.215
  },
  "1982-07-01": {
    clothing: 117.179,
    food: 268.586,
    rentandutilities: 228.28,
    standardizedguaranteeschemesmedicine: 389.515
  },
  "1982-10-01": {
    clothing: 118.863,
    food: 271.846,
    rentandutilities: 235.99,
    standardizedguaranteeschemesmedicine: 399.789
  },
  "1983-01-01": {
    clothing: 120.499,
    food: 270.915,
    rentandutilities: 243.577,
    standardizedguaranteeschemesmedicine: 405.991
  },
  "1983-04-01": {
    clothing: 125.3,
    food: 275.722,
    rentandutilities: 250.172,
    standardizedguaranteeschemesmedicine: 415.877
  },
  "1983-07-01": {
    clothing: 127.905,
    food: 279.786,
    rentandutilities: 256.246,
    standardizedguaranteeschemesmedicine: 426.531
  },
  "1983-10-01": {
    clothing: 132.079,
    food: 281.722,
    rentandutilities: 262.504,
    standardizedguaranteeschemesmedicine: 436.508
  },
  "1984-01-01": {
    clothing: 134.38,
    food: 285.441,
    rentandutilities: 269.179,
    standardizedguaranteeschemesmedicine: 440.69
  },
  "1984-04-01": {
    clothing: 138.196,
    food: 290.613,
    rentandutilities: 273.637,
    standardizedguaranteeschemesmedicine: 453.367
  },
  "1984-07-01": {
    clothing: 137.286,
    food: 293.516,
    rentandutilities: 278.738,
    standardizedguaranteeschemesmedicine: 463.056
  },
  "1984-10-01": {
    clothing: 140.626,
    food: 294.838,
    rentandutilities: 284.352,
    standardizedguaranteeschemesmedicine: 472.927
  },
  "1985-01-01": {
    clothing: 143.07,
    food: 297.822,
    rentandutilities: 290.984,
    standardizedguaranteeschemesmedicine: 487.032
  },
  "1985-04-01": {
    clothing: 145.66,
    food: 300.54,
    rentandutilities: 297.708,
    standardizedguaranteeschemesmedicine: 491.708
  },
  "1985-07-01": {
    clothing: 148.005,
    food: 303.773,
    rentandutilities: 306.086,
    standardizedguaranteeschemesmedicine: 505.29
  },
  "1985-10-01": {
    clothing: 150.646,
    food: 309.677,
    rentandutilities: 314.004,
    standardizedguaranteeschemesmedicine: 518.409
  },
  "1986-01-01": {
    clothing: 153.433,
    food: 314.516,
    rentandutilities: 320.588,
    standardizedguaranteeschemesmedicine: 522.927
  },
  "1986-04-01": {
    clothing: 156.979,
    food: 314.119,
    rentandutilities: 325.843,
    standardizedguaranteeschemesmedicine: 533.161
  },
  "1986-07-01": {
    clothing: 158.876,
    food: 316.276,
    rentandutilities: 332.633,
    standardizedguaranteeschemesmedicine: 542.013
  },
  "1986-10-01": {
    clothing: 159.511,
    food: 320.747,
    rentandutilities: 341.573,
    standardizedguaranteeschemesmedicine: 549.802
  },
  "1987-01-01": {
    clothing: 164.183,
    food: 321.893,
    rentandutilities: 350.593,
    standardizedguaranteeschemesmedicine: 555.62
  },
  "1987-04-01": {
    clothing: 166.687,
    food: 323.996,
    rentandutilities: 360.978,
    standardizedguaranteeschemesmedicine: 566.766
  },
  "1987-07-01": {
    clothing: 169.457,
    food: 325.238,
    rentandutilities: 371.927,
    standardizedguaranteeschemesmedicine: 576.545
  },
  "1987-10-01": {
    clothing: 170.582,
    food: 326.124,
    rentandutilities: 380.547,
    standardizedguaranteeschemesmedicine: 587.306
  },
  "1988-01-01": {
    clothing: 172.747,
    food: 332.985,
    rentandutilities: 392.68,
    standardizedguaranteeschemesmedicine: 599.844
  },
  "1988-04-01": {
    clothing: 175.026,
    food: 339.884,
    rentandutilities: 404.419,
    standardizedguaranteeschemesmedicine: 607.057
  },
  "1988-07-01": {
    clothing: 179.295,
    food: 347.087,
    rentandutilities: 416.735,
    standardizedguaranteeschemesmedicine: 620.142
  },
  "1988-10-01": {
    clothing: 185.788,
    food: 351.27,
    rentandutilities: 426.373,
    standardizedguaranteeschemesmedicine: 630.707
  },
  "1989-01-01": {
    clothing: 186.202,
    food: 357.772,
    rentandutilities: 437.111,
    standardizedguaranteeschemesmedicine: 639.751
  },
  "1989-04-01": {
    clothing: 189.582,
    food: 361.995,
    rentandutilities: 444.815,
    standardizedguaranteeschemesmedicine: 646.722
  },
  "1989-07-01": {
    clothing: 191.482,
    food: 367.827,
    rentandutilities: 454.493,
    standardizedguaranteeschemesmedicine: 657.841
  },
  "1989-10-01": {
    clothing: 194.321,
    food: 373.958,
    rentandutilities: 468.414,
    standardizedguaranteeschemesmedicine: 676.358
  },
  "1990-01-01": {
    clothing: 195.67,
    food: 382.993,
    rentandutilities: 483.085,
    standardizedguaranteeschemesmedicine: 675.224
  },
  "1990-04-01": {
    clothing: 195.682,
    food: 390.002,
    rentandutilities: 499.377,
    standardizedguaranteeschemesmedicine: 692.414
  },
  "1990-07-01": {
    clothing: 195.928,
    food: 395.755,
    rentandutilities: 515.239,
    standardizedguaranteeschemesmedicine: 706.359
  },
  "1990-10-01": {
    clothing: 193.698,
    food: 395.953,
    rentandutilities: 527.102,
    standardizedguaranteeschemesmedicine: 712.162
  },
  "1991-01-01": {
    clothing: 196.226,
    food: 400.564,
    rentandutilities: 536.323,
    standardizedguaranteeschemesmedicine: 719.675
  },
  "1991-04-01": {
    clothing: 200.294,
    food: 406.296,
    rentandutilities: 548.092,
    standardizedguaranteeschemesmedicine: 733.638
  },
  "1991-07-01": {
    clothing: 201.334,
    food: 405.069,
    rentandutilities: 561.328,
    standardizedguaranteeschemesmedicine: 739.972
  },
  "1991-10-01": {
    clothing: 198.538,
    food: 399.884,
    rentandutilities: 577.296,
    standardizedguaranteeschemesmedicine: 747.706
  },
  "1992-01-01": {
    clothing: 205.754,
    food: 402.725,
    rentandutilities: 593.101,
    standardizedguaranteeschemesmedicine: 754.676
  },
  "1992-04-01": {
    clothing: 208.874,
    food: 402.249,
    rentandutilities: 607.777,
    standardizedguaranteeschemesmedicine: 766.639
  },
  "1992-07-01": {
    clothing: 213.453,
    food: 404.85,
    rentandutilities: 620.468,
    standardizedguaranteeschemesmedicine: 774.463
  },
  "1992-10-01": {
    clothing: 216.852,
    food: 408.032,
    rentandutilities: 629.795,
    standardizedguaranteeschemesmedicine: 788.67
  },
  "1993-01-01": {
    clothing: 215.434,
    food: 408.289,
    rentandutilities: 638.784,
    standardizedguaranteeschemesmedicine: 797.979
  },
  "1993-04-01": {
    clothing: 217.918,
    food: 411.418,
    rentandutilities: 644.976,
    standardizedguaranteeschemesmedicine: 806.843
  },
  "1993-07-01": {
    clothing: 220.185,
    food: 414.434,
    rentandutilities: 652.806,
    standardizedguaranteeschemesmedicine: 822.359
  },
  "1993-10-01": {
    clothing: 222.675,
    food: 419.748,
    rentandutilities: 658.668,
    standardizedguaranteeschemesmedicine: 832.576
  },
  "1994-01-01": {
    clothing: 224.881,
    food: 423.942,
    rentandutilities: 667.115,
    standardizedguaranteeschemesmedicine: 847.155
  },
  "1994-04-01": {
    clothing: 225.451,
    food: 430.151,
    rentandutilities: 675.466,
    standardizedguaranteeschemesmedicine: 860.52
  },
  "1994-07-01": {
    clothing: 227.68,
    food: 435.241,
    rentandutilities: 684.684,
    standardizedguaranteeschemesmedicine: 868.106
  },
  "1994-10-01": {
    clothing: 231.726,
    food: 439.104,
    rentandutilities: 694.578,
    standardizedguaranteeschemesmedicine: 877.59
  },
  "1995-01-01": {
    clothing: 228.498,
    food: 440.815,
    rentandutilities: 707.806,
    standardizedguaranteeschemesmedicine: 889.706
  },
  "1995-04-01": {
    clothing: 229.68,
    food: 442.461,
    rentandutilities: 715.579,
    standardizedguaranteeschemesmedicine: 907.767
  },
  "1995-07-01": {
    clothing: 233.43,
    food: 443.819,
    rentandutilities: 723.568,
    standardizedguaranteeschemesmedicine: 924.107
  },
  "1995-10-01": {
    clothing: 233.374,
    food: 447.897,
    rentandutilities: 732.589,
    standardizedguaranteeschemesmedicine: 933.142
  },
  "1996-01-01": {
    clothing: 234.991,
    food: 453.374,
    rentandutilities: 736.339,
    standardizedguaranteeschemesmedicine: 950.21
  },
  "1996-04-01": {
    clothing: 239.775,
    food: 460.108,
    rentandutilities: 748.011,
    standardizedguaranteeschemesmedicine: 959.068
  },
  "1996-07-01": {
    clothing: 240.423,
    food: 464.283,
    rentandutilities: 754.784,
    standardizedguaranteeschemesmedicine: 963.165
  },
  "1996-10-01": {
    clothing: 242.978,
    food: 469.75,
    rentandutilities: 769.13,
    standardizedguaranteeschemesmedicine: 977.316
  },
  "1997-01-01": {
    clothing: 243.688,
    food: 473.505,
    rentandutilities: 776.345,
    standardizedguaranteeschemesmedicine: 988.129
  },
  "1997-04-01": {
    clothing: 244.404,
    food: 471.79,
    rentandutilities: 785.383,
    standardizedguaranteeschemesmedicine: 1001.145
  },
  "1997-07-01": {
    clothing: 249.883,
    food: 476.392,
    rentandutilities: 796.49,
    standardizedguaranteeschemesmedicine: 1014.417
  },
  "1997-10-01": {
    clothing: 252.009,
    food: 477.422,
    rentandutilities: 805.573,
    standardizedguaranteeschemesmedicine: 1035.598
  },
  "1998-01-01": {
    clothing: 254.621,
    food: 478.923,
    rentandutilities: 820.165,
    standardizedguaranteeschemesmedicine: 1041.346
  },
  "1998-04-01": {
    clothing: 257.668,
    food: 484.447,
    rentandutilities: 829.312,
    standardizedguaranteeschemesmedicine: 1061.775
  },
  "1998-07-01": {
    clothing: 257.448,
    food: 489.935,
    rentandutilities: 836.746,
    standardizedguaranteeschemesmedicine: 1076.617
  },
  "1998-10-01": {
    clothing: 261.484,
    food: 496.443,
    rentandutilities: 841.957,
    standardizedguaranteeschemesmedicine: 1082.15
  },
  "1999-01-01": {
    clothing: 267.506,
    food: 504.531,
    rentandutilities: 848.878,
    standardizedguaranteeschemesmedicine: 1099.544
  },
  "1999-04-01": {
    clothing: 271.455,
    food: 511.115,
    rentandutilities: 856.132,
    standardizedguaranteeschemesmedicine: 1115.976
  },
  "1999-07-01": {
    clothing: 272.166,
    food: 517.65,
    rentandutilities: 867.806,
    standardizedguaranteeschemesmedicine: 1134.672
  },
  "1999-10-01": {
    clothing: 273.261,
    food: 528.825,
    rentandutilities: 881.542,
    standardizedguaranteeschemesmedicine: 1142.34
  },
  "2000-01-01": {
    clothing: 275.939,
    food: 526.783,
    rentandutilities: 893.835,
    standardizedguaranteeschemesmedicine: 1158.783
  },
  "2000-04-01": {
    clothing: 280.083,
    food: 538.526,
    rentandutilities: 910.022,
    standardizedguaranteeschemesmedicine: 1184.33
  },
  "2000-07-01": {
    clothing: 282.522,
    food: 544.627,
    rentandutilities: 926.69,
    standardizedguaranteeschemesmedicine: 1208.497
  },
  "2000-10-01": {
    clothing: 284.781,
    food: 552.377,
    rentandutilities: 943.151,
    standardizedguaranteeschemesmedicine: 1242.613
  },
  "2001-01-01": {
    clothing: 280.641,
    food: 555.858,
    rentandutilities: 966.199,
    standardizedguaranteeschemesmedicine: 1271.442
  },
  "2001-04-01": {
    clothing: 278.398,
    food: 563.455,
    rentandutilities: 985.03,
    standardizedguaranteeschemesmedicine: 1281.297
  },
  "2001-07-01": {
    clothing: 275.29,
    food: 566.481,
    rentandutilities: 1006.119,
    standardizedguaranteeschemesmedicine: 1295.33
  },
  "2001-10-01": {
    clothing: 277.145,
    food: 570.218,
    rentandutilities: 1028.908,
    standardizedguaranteeschemesmedicine: 1301.904
  },
  "2002-01-01": {
    clothing: 279.185,
    food: 571.347,
    rentandutilities: 1052.143,
    standardizedguaranteeschemesmedicine: 1305.576
  },
  "2002-04-01": {
    clothing: 278.669,
    food: 572.91,
    rentandutilities: 1074.64,
    standardizedguaranteeschemesmedicine: 1323.113
  },
  "2002-07-01": {
    clothing: 275.506,
    food: 574.794,
    rentandutilities: 1094.293,
    standardizedguaranteeschemesmedicine: 1335.675
  },
  "2002-10-01": {
    clothing: 281.979,
    food: 581.159,
    rentandutilities: 1110.412,
    standardizedguaranteeschemesmedicine: 1353.772
  },
  "2003-01-01": {
    clothing: 277.998,
    food: 588.901,
    rentandutilities: 1126.395,
    standardizedguaranteeschemesmedicine: 1370.047
  },
  "2003-04-01": {
    clothing: 282.45,
    food: 591.881,
    rentandutilities: 1144.689,
    standardizedguaranteeschemesmedicine: 1385.048
  },
  "2003-07-01": {
    clothing: 290.127,
    food: 605.797,
    rentandutilities: 1163.163,
    standardizedguaranteeschemesmedicine: 1395.071
  },
  "2003-10-01": {
    clothing: 290.607,
    food: 611.742,
    rentandutilities: 1181.95,
    standardizedguaranteeschemesmedicine: 1414.172
  },
  "2004-01-01": {
    clothing: 296.567,
    food: 620.474,
    rentandutilities: 1201.988,
    standardizedguaranteeschemesmedicine: 1432.895
  },
  "2004-04-01": {
    clothing: 294.515,
    food: 627.762,
    rentandutilities: 1225.984,
    standardizedguaranteeschemesmedicine: 1450.356
  },
  "2004-07-01": {
    clothing: 296.501,
    food: 635.538,
    rentandutilities: 1252.486,
    standardizedguaranteeschemesmedicine: 1475.907
  },
  "2004-10-01": {
    clothing: 301.935,
    food: 646.644,
    rentandutilities: 1275.036,
    standardizedguaranteeschemesmedicine: 1507.373
  },
  "2005-01-01": {
    clothing: 306.051,
    food: 655.17,
    rentandutilities: 1291.891,
    standardizedguaranteeschemesmedicine: 1534.053
  },
  "2005-04-01": {
    clothing: 311.088,
    food: 664.267,
    rentandutilities: 1309.461,
    standardizedguaranteeschemesmedicine: 1568.97
  },
  "2005-07-01": {
    clothing: 308.231,
    food: 670.853,
    rentandutilities: 1333.172,
    standardizedguaranteeschemesmedicine: 1597.127
  },
  "2005-10-01": {
    clothing: 316.667,
    food: 682.575,
    rentandutilities: 1347.609,
    standardizedguaranteeschemesmedicine: 1620.269
  },
  "2006-01-01": {
    clothing: 317.407,
    food: 688.842,
    rentandutilities: 1362.221,
    standardizedguaranteeschemesmedicine: 1628.124
  },
  "2006-04-01": {
    clothing: 318.361,
    food: 695.608,
    rentandutilities: 1382.372,
    standardizedguaranteeschemesmedicine: 1656.102
  },
  "2006-07-01": {
    clothing: 320.625,
    food: 701.742,
    rentandutilities: 1401.48,
    standardizedguaranteeschemesmedicine: 1685.443
  },
  "2006-10-01": {
    clothing: 323.699,
    food: 714.85,
    rentandutilities: 1421.494,
    standardizedguaranteeschemesmedicine: 1693.192
  },
  "2007-01-01": {
    clothing: 325.89,
    food: 725.559,
    rentandutilities: 1449.312,
    standardizedguaranteeschemesmedicine: 1726.687
  },
  "2007-04-01": {
    clothing: 322.894,
    food: 731.78,
    rentandutilities: 1464.526,
    standardizedguaranteeschemesmedicine: 1747.451
  },
  "2007-07-01": {
    clothing: 322.377,
    food: 740.535,
    rentandutilities: 1487.965,
    standardizedguaranteeschemesmedicine: 1770.429
  },
  "2007-10-01": {
    clothing: 322.735,
    food: 751.454,
    rentandutilities: 1511.033,
    standardizedguaranteeschemesmedicine: 1793.659
  },
  "2008-01-01": {
    clothing: 319.631,
    food: 757.328,
    rentandutilities: 1535.344,
    standardizedguaranteeschemesmedicine: 1835.082
  },
  "2008-04-01": {
    clothing: 324.9,
    food: 770.703,
    rentandutilities: 1548.081,
    standardizedguaranteeschemesmedicine: 1868.948
  },
  "2008-07-01": {
    clothing: 319.125,
    food: 775.272,
    rentandutilities: 1562.174,
    standardizedguaranteeschemesmedicine: 1885.146
  },
  "2008-10-01": {
    clothing: 305.834,
    food: 773.038,
    rentandutilities: 1575.785,
    standardizedguaranteeschemesmedicine: 1901.68
  },
  "2009-01-01": {
    clothing: 304.508,
    food: 773.282,
    rentandutilities: 1600.08,
    standardizedguaranteeschemesmedicine: 1899.184
  },
  "2009-04-01": {
    clothing: 301.236,
    food: 771.094,
    rentandutilities: 1627.52,
    standardizedguaranteeschemesmedicine: 1892.094
  },
  "2009-07-01": {
    clothing: 304.666,
    food: 771.314,
    rentandutilities: 1647.796,
    standardizedguaranteeschemesmedicine: 1894.948
  },
  "2009-10-01": {
    clothing: 305.761,
    food: 776.03,
    rentandutilities: 1655.552,
    standardizedguaranteeschemesmedicine: 1913.812
  },
  "2010-01-01": {
    clothing: 312.69,
    food: 784.482,
    rentandutilities: 1663.318,
    standardizedguaranteeschemesmedicine: 1928.174
  },
  "2010-04-01": {
    clothing: 315.309,
    food: 783.355,
    rentandutilities: 1687.097,
    standardizedguaranteeschemesmedicine: 1939.893
  },
  "2010-07-01": {
    clothing: 315.682,
    food: 785.342,
    rentandutilities: 1718.993,
    standardizedguaranteeschemesmedicine: 1956.399
  },
  "2010-10-01": {
    clothing: 322.764,
    food: 794.285,
    rentandutilities: 1728.828,
    standardizedguaranteeschemesmedicine: 1967.323
  },
  "2011-01-01": {
    clothing: 327.147,
    food: 806.802,
    rentandutilities: 1741.477,
    standardizedguaranteeschemesmedicine: 1966.651
  },
  "2011-04-01": {
    clothing: 331.727,
    food: 817.534,
    rentandutilities: 1753.447,
    standardizedguaranteeschemesmedicine: 1975.555
  },
  "2011-07-01": {
    clothing: 333.279,
    food: 824.634,
    rentandutilities: 1755.429,
    standardizedguaranteeschemesmedicine: 1995.175
  },
  "2011-10-01": {
    clothing: 338.189,
    food: 829.199,
    rentandutilities: 1778.232,
    standardizedguaranteeschemesmedicine: 1995.728
  },
  "2012-01-01": {
    clothing: 345.07,
    food: 839.132,
    rentandutilities: 1811.171,
    standardizedguaranteeschemesmedicine: 1984.278
  },
  "2012-04-01": {
    clothing: 342.492,
    food: 845.399,
    rentandutilities: 1814.154,
    standardizedguaranteeschemesmedicine: 2010.748
  },
  "2012-07-01": {
    clothing: 346.186,
    food: 847.367,
    rentandutilities: 1823.392,
    standardizedguaranteeschemesmedicine: 2027.915
  },
  "2012-10-01": {
    clothing: 347.13,
    food: 852.893,
    rentandutilities: 1836.456,
    standardizedguaranteeschemesmedicine: 2036.016
  },
  "2013-01-01": {
    clothing: 351.958,
    food: 859.275,
    rentandutilities: 1838.265,
    standardizedguaranteeschemesmedicine: 2063.005
  },
  "2013-04-01": {
    clothing: 349.557,
    food: 857.893,
    rentandutilities: 1848.208,
    standardizedguaranteeschemesmedicine: 2076.428
  },
  "2013-07-01": {
    clothing: 349.531,
    food: 866.176,
    rentandutilities: 1859.129,
    standardizedguaranteeschemesmedicine: 2083.652
  },
  "2013-10-01": {
    clothing: 351.087,
    food: 872.632,
    rentandutilities: 1887.358,
    standardizedguaranteeschemesmedicine: 2110.791
  },
  "2014-01-01": {
    clothing: 351.787,
    food: 882.378,
    rentandutilities: 1880.867,
    standardizedguaranteeschemesmedicine: 2142.935
  },
  "2014-04-01": {
    clothing: 360.992,
    food: 892.313,
    rentandutilities: 1921.278,
    standardizedguaranteeschemesmedicine: 2144.217
  },
  "2014-07-01": {
    clothing: 362.757,
    food: 902.146,
    rentandutilities: 1961.127,
    standardizedguaranteeschemesmedicine: 2147.089
  },
  "2014-10-01": {
    clothing: 367.467,
    food: 910.585,
    rentandutilities: 1998.917,
    standardizedguaranteeschemesmedicine: 2171.329
  },
  "2015-01-01": {
    clothing: 366.171,
    food: 917.371,
    rentandutilities: 2018.368,
    standardizedguaranteeschemesmedicine: 2194.774
  },
  "2015-04-01": {
    clothing: 368.315,
    food: 916.93,
    rentandutilities: 2042.686,
    standardizedguaranteeschemesmedicine: 2195.214
  },
  "2015-07-01": {
    clothing: 370.385,
    food: 924.494,
    rentandutilities: 2081.34,
    standardizedguaranteeschemesmedicine: 2214.462
  },
  "2015-10-01": {
    clothing: 370.066,
    food: 925.027,
    rentandutilities: 2086.9,
    standardizedguaranteeschemesmedicine: 2222.146
  },
  "2016-01-01": {
    clothing: 374.513,
    food: 931.013,
    rentandutilities: 2123.822,
    standardizedguaranteeschemesmedicine: 2242.307
  },
  "2016-04-01": {
    clothing: 375.141,
    food: 940.881,
    rentandutilities: 2160.308,
    standardizedguaranteeschemesmedicine: 2270.193
  },
  "2016-07-01": {
    clothing: 377.492,
    food: 941.764,
    rentandutilities: 2159.385,
    standardizedguaranteeschemesmedicine: 2299.055
  },
  "2016-10-01": {
    clothing: 375.68,
    food: 948.883,
    rentandutilities: 2194.196,
    standardizedguaranteeschemesmedicine: 2311.732
  },
  "2017-01-01": {
    clothing: 379.317,
    food: 958.969,
    rentandutilities: 2214.724,
    standardizedguaranteeschemesmedicine: 2316.223
  },
  "2017-04-01": {
    clothing: 380.178,
    food: 967.509,
    rentandutilities: 2215.995,
    standardizedguaranteeschemesmedicine: 2354.964
  },
  "2017-07-01": {
    clothing: 380.571,
    food: 976.08,
    rentandutilities: 2248.738,
    standardizedguaranteeschemesmedicine: 2372.597
  },
  "2017-10-01": {
    clothing: 383.893,
    food: 989.731,
    rentandutilities: 2275.741,
    standardizedguaranteeschemesmedicine: 2408.882
  },
  "2018-01-01": {
    clothing: 387.33,
    food: 992.808,
    rentandutilities: 2300.178,
    standardizedguaranteeschemesmedicine: 2431.848
  },
  "2018-04-01": {
    clothing: 394.122,
    food: 997.736,
    rentandutilities: 2326.262,
    standardizedguaranteeschemesmedicine: 2460.168
  },
  "2018-07-01": {
    clothing: 393.731,
    food: 1002.277,
    rentandutilities: 2364.418,
    standardizedguaranteeschemesmedicine: 2479.416
  },
  "2018-10-01": {
    clothing: 392.792,
    food: 1008.561,
    rentandutilities: 2367.415,
    standardizedguaranteeschemesmedicine: 2517.031
  },
  "2019-01-01": {
    clothing: 393.358,
    food: 1013.441,
    rentandutilities: 2408.726,
    standardizedguaranteeschemesmedicine: 2534.726
  },
  "2019-04-01": {
    clothing: 398.285,
    food: 1027.365,
    rentandutilities: 2449.708,
    standardizedguaranteeschemesmedicine: 2554.133
  },
  "2019-07-01": {
    clothing: 400,
    food: 1041.34,
    rentandutilities: 2469.921,
    standardizedguaranteeschemesmedicine: 2585.343
  },
  "2019-10-01": {
    clothing: 401.018,
    food: 1041.498,
    rentandutilities: 2504.266,
    standardizedguaranteeschemesmedicine: 2611.833
  },
  "2020-01-01": {
    clothing: 366.262,
    food: 1125.137,
    rentandutilities: 2406.893,
    standardizedguaranteeschemesmedicine: 2622.579
  },
  "2020-04-01": {
    clothing: 293.634,
    food: 1152.114,
    rentandutilities: 2000.289,
    standardizedguaranteeschemesmedicine: 2667.698
  },
  "2020-07-01": {
    clothing: 394.242,
    food: 1159.491,
    rentandutilities: 2369.192,
    standardizedguaranteeschemesmedicine: 2682.553
  },
  "2020-10-01": {
    clothing: 395.605,
    food: 1149.962,
    rentandutilities: 2457.229,
    standardizedguaranteeschemesmedicine: 2699.745
  },
  "2021-01-01": {
    clothing: 436.819,
    food: 1201.534,
    rentandutilities: 2464.208,
    standardizedguaranteeschemesmedicine: 2727.182
  },
  "2021-04-01": {
    clothing: 476.952,
    food: 1223.364,
    rentandutilities: 2534.433,
    standardizedguaranteeschemesmedicine: 2753.433
  },
  "2021-07-01": {
    clothing: 479.697,
    food: 1245.342,
    rentandutilities: 2574.472,
    standardizedguaranteeschemesmedicine: 2792.633
  },
  "2021-10-01": {
    clothing: 481.924,
    food: 1271.659,
    rentandutilities: 2618.844,
    standardizedguaranteeschemesmedicine: 2836.469
  }
};
const premiums = [
  ["2011-01-01", 191945],
  ["2012-01-01", 191446],
  ["2013-01-01", 192420],
  ["2014-01-01", 207534],
  ["2015-01-01", 215385],
  ["2016-01-01", 222273],
  ["2017-01-01", 230831],
  ["2018-01-01", 240595],
  ["2019-01-01", 235482],
  ["2020-01-01", 242290]
];
const healthexpense = {
  "2013": {
    healthcare: 1886680,
    ambulance: 721522,
    physicians: 353977,
    internists: 349821,
    psychiatry: 4156,
    dentistry: 88116,
    otherhealth: 56046,
    chiropracty: 9140,
    optometry: 9380,
    psychiatristoffice: 6101,
    functionaloffice: 21926,
    podiatristoffice: 3617,
    otherhealthoffice: 5882,
    voluntary: 93002,
    familyplanners: 2093,
    voluntarypanicrooms: 14218,
    "HMO Medical Centers": 7900,
    kidneydialysis: 15045,
    cashmedical: 17019,
    othervoluntary: 36727,
    diagnosticlabs: 39691,
    medicallabs: 25401,
    imagingcenters: 14290,
    homecare: 62172,
    otherambulanceservice: 28518,
    ambulanceservice: 11737,
    bloodbanks: 9756,
    othermiscellaneousambulance: 7025,
    hospitals: 829193,
    govhospitals: 168506,
    corphospitals: 603407,
    govpsychiatry: 13402,
    privatepsychiatry: 8539,
    govpractice: 6277,
    privatepractice: 29062,
    nursinghomes: 193467,
    nursingcare: 104502,
    unable: 33023,
    disability: 21817,
    intervention: 11206
  },
  "2014": {
    healthcare: 1944925,
    ambulance: 751643,
    physicians: 368257,
    internists: 364083,
    psychiatry: 4174,
    dentistry: 88030,
    otherhealth: 57815,
    chiropracty: 9129,
    optometry: 9595,
    psychiatristoffice: 6301,
    functionaloffice: 22904,
    podiatristoffice: 3554,
    otherhealthoffice: 6332,
    voluntary: 102812,
    familyplanners: 2140,
    voluntarypanicrooms: 14919,
    "HMO Medical Centers": 12504,
    kidneydialysis: 16550,
    cashmedical: 17709,
    othervoluntary: 38990,
    diagnosticlabs: 41096,
    medicallabs: 26977,
    imagingcenters: 14119,
    homecare: 64511,
    otherambulanceservice: 29122,
    ambulanceservice: 12278,
    bloodbanks: 9533,
    othermiscellaneousambulance: 7311,
    hospitals: 846658,
    govhospitals: 173296,
    corphospitals: 614653,
    govpsychiatry: 13485,
    privatepsychiatry: 9328,
    govpractice: 6380,
    privatepractice: 29516,
    nursinghomes: 198901,
    nursingcare: 105918,
    unable: 34813,
    disability: 22898,
    intervention: 11915
  },
  "2015": {
    healthcare: 2056820,
    ambulance: 806820,
    physicians: 390823,
    internists: 386639,
    psychiatry: 4184,
    dentistry: 90499,
    otherhealth: 60400,
    chiropracty: 9336,
    optometry: 9884,
    psychiatristoffice: 6413,
    functionaloffice: 24411,
    podiatristoffice: 3550,
    otherhealthoffice: 6806,
    voluntary: 124912,
    familyplanners: 2272,
    voluntarypanicrooms: 16096,
    "HMO Medical Centers": 26617,
    kidneydialysis: 19541,
    cashmedical: 19051,
    othervoluntary: 41335,
    diagnosticlabs: 42532,
    medicallabs: 28297,
    imagingcenters: 14235,
    homecare: 67582,
    otherambulanceservice: 30072,
    ambulanceservice: 12708,
    bloodbanks: 9658,
    othermiscellaneousambulance: 7706,
    hospitals: 888178,
    govhospitals: 173355,
    corphospitals: 654528,
    govpsychiatry: 13006,
    privatepsychiatry: 10230,
    govpractice: 6717,
    privatepractice: 30342,
    nursinghomes: 206934,
    nursingcare: 108851,
    unable: 36632,
    disability: 23712,
    intervention: 12920
  },
  "2016": {
    healthcare: 2174536,
    ambulance: 856200,
    physicians: 415368,
    internists: 410992,
    psychiatry: 4376,
    dentistry: 94540,
    otherhealth: 63759,
    chiropracty: 9702,
    optometry: 10465,
    psychiatristoffice: 6946,
    functionaloffice: 25689,
    podiatristoffice: 3587,
    otherhealthoffice: 7370,
    voluntary: 136597,
    familyplanners: 2425,
    voluntarypanicrooms: 17869,
    "HMO Medical Centers": 28228,
    kidneydialysis: 21040,
    cashmedical: 22018,
    othervoluntary: 45017,
    diagnosticlabs: 43276,
    medicallabs: 28706,
    imagingcenters: 14570,
    homecare: 70851,
    otherambulanceservice: 31809,
    ambulanceservice: 13748,
    bloodbanks: 9907,
    othermiscellaneousambulance: 8154,
    hospitals: 937765,
    govhospitals: 185604,
    corphospitals: 689780,
    govpsychiatry: 13083,
    privatepsychiatry: 10737,
    govpractice: 7273,
    privatepractice: 31288,
    nursinghomes: 215844,
    nursingcare: 112725,
    unable: 38763,
    disability: 24825,
    intervention: 13938
  },
  "2017": {
    healthcare: 2271503,
    ambulance: 900839,
    physicians: 434079,
    internists: 429445,
    psychiatry: 4634,
    dentistry: 99391,
    otherhealth: 66948,
    chiropracty: 10208,
    optometry: 10788,
    psychiatristoffice: 7460,
    functionaloffice: 26767,
    podiatristoffice: 3694,
    otherhealthoffice: 8031,
    voluntary: 146157,
    familyplanners: 2549,
    voluntarypanicrooms: 19389,
    "HMO Medical Centers": 30363,
    kidneydialysis: 21854,
    cashmedical: 24081,
    othervoluntary: 47921,
    diagnosticlabs: 44288,
    medicallabs: 29248,
    imagingcenters: 15040,
    homecare: 75968,
    otherambulanceservice: 34008,
    ambulanceservice: 14756,
    bloodbanks: 10310,
    othermiscellaneousambulance: 8942,
    hospitals: 971991,
    govhospitals: 197356,
    corphospitals: 710946,
    govpsychiatry: 12506,
    privatepsychiatry: 11493,
    govpractice: 7576,
    privatepractice: 32114,
    nursinghomes: 221491,
    nursingcare: 113303,
    unable: 41244,
    disability: 26401,
    intervention: 14843
  },
  "2018": {
    healthcare: 2360363,
    ambulance: 931880,
    physicians: 448611,
    internists: 443719,
    psychiatry: 4892,
    dentistry: 101587,
    otherhealth: 69466,
    chiropracty: 10074,
    optometry: 10827,
    psychiatristoffice: 8026,
    functionaloffice: 28104,
    podiatristoffice: 3818,
    otherhealthoffice: 8617,
    voluntary: 153671,
    familyplanners: 2722,
    voluntarypanicrooms: 20511,
    "HMO Medical Centers": 32216,
    kidneydialysis: 23301,
    cashmedical: 24848,
    othervoluntary: 50073,
    diagnosticlabs: 44329,
    medicallabs: 29384,
    imagingcenters: 14945,
    homecare: 79456,
    otherambulanceservice: 34760,
    ambulanceservice: 14229,
    bloodbanks: 10701,
    othermiscellaneousambulance: 9830,
    hospitals: 1017663,
    govhospitals: 206017,
    corphospitals: 746124,
    govpsychiatry: 12950,
    privatepsychiatry: 11659,
    govpractice: 7772,
    privatepractice: 33141,
    nursinghomes: 224839,
    nursingcare: 111766,
    unable: 42789,
    disability: 27187,
    intervention: 15602
  },
  "2019": {
    healthcare: 2481352,
    ambulance: 982154,
    physicians: 470465,
    internists: 465099,
    psychiatry: 5366,
    dentistry: 107175,
    otherhealth: 74105,
    chiropracty: 10724,
    optometry: 11044,
    psychiatristoffice: 8926,
    functionaloffice: 29752,
    podiatristoffice: 4013,
    otherhealthoffice: 9646,
    voluntary: 162040,
    familyplanners: 2845,
    voluntarypanicrooms: 22242,
    "HMO Medical Centers": 34591,
    kidneydialysis: 24636,
    cashmedical: 25586,
    othervoluntary: 52140,
    diagnosticlabs: 45535,
    medicallabs: 29815,
    imagingcenters: 15720,
    homecare: 86470,
    otherambulanceservice: 36364,
    ambulanceservice: 14694,
    bloodbanks: 11369,
    othermiscellaneousambulance: 10301,
    hospitals: 1068499,
    govhospitals: 219713,
    corphospitals: 780129,
    govpsychiatry: 13341,
    privatepsychiatry: 12206,
    govpractice: 8490,
    privatepractice: 34620,
    nursinghomes: 235004,
    nursingcare: 116711,
    unable: 44491,
    disability: 27712,
    intervention: 16779
  },
  "2020": {
    healthcare: 2559101,
    ambulance: 1000593,
    physicians: 478982,
    internists: 472963,
    psychiatry: 6019,
    dentistry: 101202,
    otherhealth: 75005,
    chiropracty: 11051,
    optometry: 10931,
    psychiatristoffice: 10173,
    functionaloffice: 28520,
    podiatristoffice: 4100,
    otherhealthoffice: 10230,
    voluntary: 171288,
    familyplanners: 2909,
    voluntarypanicrooms: 23257,
    "HMO Medical Centers": 36918,
    kidneydialysis: 26076,
    cashmedical: 27850,
    othervoluntary: 54278,
    diagnosticlabs: 47348,
    medicallabs: 32002,
    imagingcenters: 15346,
    homecare: 89803,
    otherambulanceservice: 36965,
    ambulanceservice: 14589,
    bloodbanks: 11487,
    othermiscellaneousambulance: 10889,
    hospitals: 1109120,
    govhospitals: 231491,
    corphospitals: 804680,
    govpsychiatry: 14231,
    privatepsychiatry: 12206, //"S"
    govpractice: 8691,
    privatepractice: 37034,
    nursinghomes: 247301,
    nursingcare: 123075,
    unable: 47183,
    disability: 29079,
    intervention: 18104
  }
};
class ReverseAmortize extends React.Component {
  constructor(props) {
    super(props);

    let noData = [];
    let date = [];
    let types = [];
    let curren = [];
    let currenData = [];
    let gdp = [];
    let gdpData = [];
    let growthcurren = [];
    let growthcurrenData = [];
    let growthdebt = [];
    let growthdebtData = [];
    let growthGDP = [];
    let growthGDPdata = [];
    let healthexpData = [];
    Object.keys(healthexpense).forEach((year, i) => {
      const data = Object.values(healthexpense)[i];
      var health = 0;
      Object.keys(data).forEach((name, i) => {
        const num = Object.values(data)[i];
        if (num) {
          if (
            [
              "voluntarypanicrooms",
              "intervention",
              "functionaloffice",
              "psychiatry",
              "psychiatristoffice",
              "privatepsychiatry",
              "govpsychiatry"
            ].includes(name)
          ) {
            health = health + num;
          } else if (name === "govhospitals") {
            healthexpData.push([new Date(year + "-01-01").getTime(), num]);
          } // else health = health + num;
        }
      });
      //healthexpData.push([new Date(year + "-01-01").getTime(), health]);
      healthexpData.push([new Date(year + "-01-01").getTime(), health]);
    });
    currency.forEach((set, i) => {
      const yr = set[0];
      if (Number(yr.substring(0, 4)) > 1958 && debts[yr]) {
        const year = new Date(yr).getTime(); // + 1347155200000;
        const debt = debts[yr].alldebt;
        if (Number(yr.substring(0, 4)) === 1959) types = Object.keys(debts[yr]);
        const income = flows[yr].income;
        const expense = flows[yr].expenses;
        const basics =
          //poverty[yr].clothing +
          //poverty[yr].food +
          //poverty[yr].rentandutilities +
          poverty[yr].standardizedguaranteeschemesmedicine;
        var last2 = null;
        Object.keys(poverty).forEach((x, i) => {
          if (yr === x) {
            var povert = poverty[Object.keys(poverty)[i - 1]];
            if (povert)
              last2 =
                //povert.clothing +
                //povert.food +
                //povert.rentandutilities +
                povert.standardizedguaranteeschemesmedicine;
          }
        });
        if (last2) {
          const chg = (basics - last2) / last2;
          if (Math.abs(chg) < 0.05) {
            growthdebt.push(chg);
            growthdebtData.push([year, chg]);
          }
        }
        if (operating[yr]) {
          const gdps = operating[yr].gdp;
          gdp.push((set[1] / (debt * 1000)) * gdps * 1000);
          gdpData.push([year, (set[1] / (debt * 1000)) * gdps * 1000]);
          var last3 = null;
          Object.keys(operating).forEach((x, i) => {
            if (yr === x) {
              var povert = operating[Object.keys(operating)[i - 1]];
              if (povert) last3 = povert.gdp;
            }
          });
          if (last3) {
            const chg = (gdps - last3) / last3;
            if (Math.abs(chg) < 0.05) {
              const gdpdelta = growthdebtData.find((x) => x[0] === year);
              if (gdpdelta) {
                growthGDP.push(gdpdelta[1] - chg);
                growthGDPdata.push([year, gdpdelta[1] - chg]);
              }
            }
          }
        }
        curren.push((set[1] / (debt * 1000)) * (income - expense) * 1000);
        currenData.push([
          year,
          (set[1] / (debt * 1000)) * (income - expense) * 1000
        ]);
        const last = currency[i - 1][1];
        if (last) {
          const adj = (set[1] - last) / last;
          growthcurren.push(adj * 12);
          growthcurrenData.push([year, adj * 12]);
        }
        date.push(year);
        noData.push([year, 0]);
      }
    });
    const chartType = "savingslendings";
    let aux = [];
    let auxData = [];
    Object.keys(debts).forEach((yr) => {
      const year = new Date(yr).getTime(); // + 1347155200000;
      const ese = debts[yr];
      Object.keys(ese).forEach((x, i) => {
        if (x === chartType) {
          aux.push(
            Object.values(debts[yr])[i] === ""
              ? 0
              : Object.values(debts[yr])[i] * 1000
          );
          auxData.push([
            year,
            Object.values(debts[yr])[i] === ""
              ? 0
              : Object.values(debts[yr])[i] * 1000
          ]);
        }
      });
    });
    const premiumsData = premiums.map((x) => [new Date(x[0]).getTime(), x[1]]);
    const all = [...curren, ...gdp, ...aux];
    var lowDollar = Math.min(...all);
    var lowChangeRate = Math.min(...growthGDP);
    var lowCurrency = Math.min(...growthcurren);
    var lowDate = Math.min(...date);
    var highDollar = Math.max(...all);
    var highChangeRate = Math.max(...growthGDP);
    var highCurrency = Math.max(...growthcurren);
    var highDate = Math.max(...date);
    var state = {
      //"engl churchill; Is H; mlk pearl harbor, 911"
      healthexpData,
      auxData,
      premiumsData,
      lowChangeRate,
      highChangeRate,
      chartType,
      types,
      date,
      highDollar,
      growthcurrenData,
      growthdebtData,
      growthGDPdata,
      noData,
      gdpData,
      yAxis: highDollar - lowDollar,
      yAxisChange: highChangeRate - lowChangeRate,
      yAxisCurrency: highCurrency - lowCurrency,
      lowCurrency,
      xAxis: highDate - lowDate,
      lowDollar,
      highDate,
      lowDate,
      currenData,
      FCSU: [
        "clothing",
        "food",
        "rentandutilities",
        "standardizedguaranteeschemesmedicine"
      ]
    };
    this.state = state;
  }
  componentDidUpdate = () => {
    if (
      this.state.chartType !== this.state.laststate ||
      this.state.FCSU !== this.state.lastFCSU
    ) {
      this.setState(
        { laststate: this.state.chartType, lastFCSU: this.state.FCSU },
        () => {
          let noData = [];
          let date = [];
          let aux = [];
          let auxData = [];
          let curren = [];
          let currenData = [];
          let growthdebtData = [];
          let gdp = [];
          let gdpData = [];
          currency.forEach((set) => {
            const yr = set[0];
            if (Number(yr.substring(0, 4)) > 1958 && debts[yr]) {
              const year = new Date(yr).getTime(); // + 1347155200000;
              const debt = debts[yr].alldebt;
              const income = flows[yr].income;
              const expense = flows[yr].expenses;
              var basics = 0;
              [
                "clothing",
                "food",
                "rentandutilities",
                "standardizedguaranteeschemesmedicine"
              ].forEach((x) => {
                if (this.state.FCSU.includes(x))
                  basics = basics + poverty[yr][x];
              });
              var last2 = null;
              Object.keys(poverty).forEach((x, i) => {
                if (yr === x) {
                  var povert = poverty[Object.keys(poverty)[i - 1]];
                  if (povert) {
                    [
                      "clothing",
                      "food",
                      "rentandutilities",
                      "standardizedguaranteeschemesmedicine"
                    ].forEach((x) => {
                      if (this.state.FCSU.includes(x))
                        last2 = last2 + povert[x];
                    });
                  }
                }
              });
              if (last2) {
                const chg = (basics - last2) / last2;
                if (Math.abs(chg) < 0.05) {
                  growthdebtData.push([year, chg]);
                }
              }
              if (operating[yr]) {
                const gdps = operating[yr].gdp;
                gdp.push((set[1] / (debt * 1000)) * gdps * 1000);
                gdpData.push([year, (set[1] / (debt * 1000)) * gdps * 1000]);
              }
              curren.push((set[1] / (debt * 1000)) * (income - expense) * 1000);
              currenData.push([
                year,
                (set[1] / (debt * 1000)) * (income - expense) * 1000
              ]);
              date.push(year);
              noData.push([year, 0]);
            }
          });
          Object.keys(debts).forEach((yr) => {
            const year = new Date(yr).getTime(); // + 1347155200000;
            const ese = debts[yr];
            Object.keys(ese).forEach((x, i) => {
              if (x === this.state.chartType) {
                aux.push(
                  Object.values(debts[yr])[i] === ""
                    ? 0
                    : Object.values(debts[yr])[i] * 1000
                );
                auxData.push([
                  year,
                  Object.values(debts[yr])[i] === ""
                    ? 0
                    : Object.values(debts[yr])[i] * 1000
                ]);
              }
            });
          });
          const all = [...curren, ...gdp, ...aux];

          var lowDollar = Math.min(...all);
          var lowDate = Math.min(...date);
          var highDollar = Math.max(...all);
          var highDate = Math.max(...date);
          //console.log(dataData);
          var state = {
            date,
            noData,
            auxData,
            gdpData,
            highDollar,
            growthdebtData,
            yAxis: highDollar - lowDollar,
            xAxis: highDate - lowDate,
            lowDollar,
            highDate,
            lowDate,
            currenData
          };
          this.setState(state);
        }
      );
    }
  };
  render() {
    const { types } = this.state;
    const labelstyle = {
      backgroundColor: "rgba(50,120,200,.6)",
      top: "0px",
      height: "min-content",
      display: "flex",
      maxWidth: "100%",
      left: "2px",
      flexWrap: "wrap"
    };
    const buttonStyle = {
      userSelect: "none",
      border: "1px solid black",
      color: "black",
      backgroundColor: "rgb(220,220,220)",
      borderRadius: "4px",
      padding: "5px",
      margin: "2px"
    };

    const linecss = {
      left: "0px",
      bottom: "0px",
      display: "flex",
      position: "absolute",
      width: "100%",
      height: "200px",
      transform: "translate(0%,0%) scale(1,-1)"
    };
    const shortNumber = (scler, notRound) => {
      var newnum = String(Math.round(scler));
      if (notRound) newnum = String(scler);
      var app = null;
      var decimal = null;
      const suff = ["", "k", "m", "b", "t"];
      for (let i = 0; i < suff.length; i++) {
        if (newnum.length > 3) {
          decimal = newnum[newnum.length - 3];
          newnum = newnum.substring(0, newnum.length - 3);
        } else {
          app = i;
          break;
        }
      }
      return newnum + (decimal ? "." + decimal : "") + suff[app];
    };
    //console.log(this.state.oilprice);
    const noData = this.state.noData.map(([x, y]) => [
      ((x - this.state.lowDate) / this.state.xAxis) *
        0.9 *
        this.props.lastWidth,
      0
    ]);
    //console.log(this.state.oilprice);

    const currenData = this.state.currenData
      .filter((x) => x[1] !== 0)
      .map(([x, y]) => [
        ((x - this.state.lowDate) / this.state.xAxis) *
          0.9 *
          this.props.lastWidth,
        ((y - this.state.lowDollar) / this.state.yAxis) * 150
      ]);
    const gdpData = this.state.gdpData
      .filter((x) => x[1] !== 0)
      .map(([x, y]) => [
        ((x - this.state.lowDate) / this.state.xAxis) *
          0.9 *
          this.props.lastWidth,
        ((y - this.state.lowDollar) / this.state.yAxis) * 150
      ]);
    const auxData = this.state.auxData.map(([x, y]) => [
      ((x - this.state.lowDate) / this.state.xAxis) *
        0.9 *
        this.props.lastWidth,
      ((y - this.state.lowDollar) / this.state.yAxis) * 150
    ]);
    const premiumsData = this.state.premiumsData
      .filter((x) => x[1] !== 0)
      .map(([x, y]) => [
        ((x - this.state.lowDate) / this.state.xAxis) *
          0.9 *
          this.props.lastWidth,
        ((y - this.state.lowDollar) / this.state.yAxis) * 150
      ]);
    const healthcare = this.state.healthexpData
      .filter((x) => x[1] !== 0)
      .map(([x, y]) => [
        ((x - this.state.lowDate) / this.state.xAxis) *
          0.9 *
          this.props.lastWidth,
        ((y - this.state.lowDollar) / this.state.yAxis) * 150
      ]);
    const growthcurrenData = this.state.growthcurrenData
      .filter((x) => x[1] !== 0)
      .map(([x, y]) => [
        ((x - this.state.lowDate) / this.state.xAxis) *
          0.9 *
          this.props.lastWidth,
        ((y - this.state.lowCurrency) / this.state.yAxisCurrency) * 150
      ]);
    const growthdebtData = this.state.growthdebtData
      .filter((x) => x[1] !== 0)
      .map(([x, y]) => [
        ((x - this.state.lowDate) / this.state.xAxis) *
          0.9 *
          this.props.lastWidth,
        ((y - this.state.lowChangeRate) / this.state.yAxisChange) * 150
      ]);
    const growthGDPdata = this.state.growthGDPdata
      .filter((x) => x[1] !== 0)
      .map(([x, y]) => [
        ((x - this.state.lowDate) / this.state.xAxis) *
          0.9 *
          this.props.lastWidth,
        ((y - this.state.lowChangeRate) / this.state.yAxisChange) * 150
      ]);
    const labelitem = {
      fontSize: "10px",
      color: "white",
      width: "max-content",
      margin: "0px 4px"
    };

    const StringDecimal = (y, suffindex = 3) => {
      var string;
      if (y) {
        const x = String(y);
        if (x.includes(".")) {
          const here = x.indexOf(".");
          string = x.substring(0, here) + x.substring(here, here + suffindex);
        } else string = x;
      }
      return string;
    };
    const checkFCSU = (x) =>
      this.setState({
        FCSU: this.state.FCSU.includes(x.target.id)
          ? this.state.FCSU.filter((c) => c !== x.target.id)
          : [...this.state.FCSU, x.target.id]
      });
    return (
      <div
        style={{
          width: "100%",
          minHeight: "240px",
          position: "relative",
          backgroundColor: "black"
        }}
      >
        <div
          style={{
            bottom: "0px",
            zIndex: "1",
            backgroundColor: "rgba(255,255,255,.3)",
            padding: "4px 8px",
            position: "absolute",
            right: "0px"
          }}
        >
          <a
            style={{ color: "grey" }}
            href={
              this.state.chartType !== ""
                ? "https://fredaccount.stlouisfed.org/public/datalist/1587"
                : "https://3under2.us"
            }
          >
            {new Date(this.state.lowDate).getFullYear()}
            &nbsp;-&nbsp;
            {new Date(this.state.highDate).getFullYear()}
          </a>
        </div>
        <div
          style={{
            zIndex: "1",
            textAlign: "left",
            position: "absolute"
          }}
        >
          <div
            style={{
              fontSize: "12px",
              backgroundColor: "rgba(250,250,250,.6)",
              color: "white",
              padding: "4px",
              maxWidth: "max-content",
              width: "calc(100% - 80px)"
            }}
          >
            reverse amortization: ${shortNumber(this.state.lowDollar * 1000000)}
            /yr - {shortNumber(this.state.highDollar * 1000000)}
            /yr;
            {StringDecimal(this.state.lowChangeRate)}-
            {StringDecimal(this.state.highChangeRate)}%/yr
            <br />{" "}
            <input
              checked={this.state.FCSU.includes("food")}
              onChange={checkFCSU}
              type="checkbox"
              id="food"
            />
            F
            <input
              checked={this.state.FCSU.includes("clothing")}
              onChange={checkFCSU}
              type="checkbox"
              id="clothing"
            />
            C
            <input
              checked={this.state.FCSU.includes("rentandutilities")}
              onChange={checkFCSU}
              type="checkbox"
              id="rentandutilities" //shelter
            />
            SU
            <input
              checked={this.state.FCSU.includes(
                "standardizedguaranteeschemesmedicine"
              )}
              onChange={checkFCSU}
              type="checkbox"
              id="standardizedguaranteeschemesmedicine" //health
            />
            H&bull;Currency(qtrly)
            <br />
            <span
              style={{
                fontSize: "14px"
              }}
            >
              <a href="https://qr.ae/pGLLCL">without debt</a> yet{" "}
              <a href="https://2024nj.com/ssa">SGS</a> and{" "}
              <a href="https://implausibleuselease.com">IUL</a> (
              <a href="https://www.lawinsider.com/clause/no-surrender-of-others-freedom">
                SFBLD
              </a>
              )
            </span>
          </div>
          <select
            defaultValue={this.state.chartType}
            state={this.state.chartType}
            onChange={(name) => {
              this.setState({ chartType: name.target.value });
            }}
          >
            {["", ...types].map((name) => (
              <option key={name}>{name}</option>
            ))}
          </select>
          <div style={{ marginTop: "6px", width: "max-content" }}>
            <div style={labelitem}>
              <div
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "red"
                }}
              />
              (currency/debt)*(
              <a href="https://commie.dev" style={{ color: "white" }}>
                expenses
              </a>
              +
              <a href="https://qr.ae/pGLLH7" style={{ color: "white" }}>
                government
              </a>
              +
              <a href="https://froth.app" style={{ color: "white" }}>
                structures
              </a>
              )
            </div>
            <div style={labelitem}>
              <div
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "deepskyblue"
                }}
              />
              (currency/debt)*(income-expenses)
            </div>
            <div style={labelitem}>
              <div
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "yellow"
                }}
              />
              medical invoices or guarantees
            </div>
            <div style={labelitem}>
              <div
                style={{
                  width: "5px",
                  height: "5px",
                  backgroundColor: "gold"
                }}
              />
              govhospitals and psychiatry
            </div>
          </div>
        </div>
        <div style={{ transform: "translate(0px,230px)" }}>
          <svg style={linecss} xmlns="http://www.w3.org/2000/svg">
            {noData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={4}
                    height={4}
                    stroke="rgb(230,230,230)"
                    fill="transparent"
                    strokeWidth={0}
                    key={i}
                  />
                )
            )}
            {gdpData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={2}
                    height={1}
                    stroke="red"
                    fill="blue"
                    strokeWidth={1}
                    key={i}
                  />
                )
            )}
            {currenData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={2}
                    height={1}
                    stroke="deepskyblue"
                    fill="blue"
                    strokeWidth={1}
                    key={i}
                  />
                )
            )}
            {auxData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={2}
                    height={1}
                    stroke="green"
                    fill="blue"
                    strokeWidth={1}
                    key={i}
                  />
                )
            )}
            {growthcurrenData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={2}
                    height={1}
                    stroke="deepskyblue"
                    fill="blue"
                    strokeWidth={1}
                    key={i}
                  />
                )
            )}
            {growthdebtData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={2}
                    height={1}
                    stroke="red"
                    fill="blue"
                    strokeWidth={1}
                    key={i}
                  />
                )
            )}
            {growthGDPdata.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={2}
                    height={1}
                    stroke="green"
                    fill="blue"
                    strokeWidth={1}
                    key={i}
                  />
                )
            )}
            {premiumsData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={2}
                    height={1}
                    stroke="yellow"
                    fill="blue"
                    strokeWidth={1}
                    key={i}
                  />
                )
            )}
            {healthcare.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={2}
                    height={1}
                    stroke="gold"
                    fill="blue"
                    strokeWidth={1}
                    key={i}
                  />
                )
            )}
          </svg>
        </div>
        {/*<div
          style={{
            backgroundColor: "rgba(250,250,250,0.6)",
            top: "10px",
            height: "40px",
            display: "flex",
            position: "relative",
            width: "100%",
            left: "2px",
            zIndex: "0",
            overflowX: "auto",
            overflowY: "hidden"
          }}
        >
          <div
            style={{
              fontSize: "15px",
              display: "flex",
              position: "absolute",
              width: "max-content"
            }}
          ></div>
          </div>*/}
      </div>
    );
  }
}

export default ReverseAmortize; /**

        const basics =
          expense -
          poverty[yr].clothing +
          poverty[yr].food +
          poverty[yr].rentandutilities +
          poverty[yr].standardizedguaranteeschemesmedicine;
        var last2 = null;
        var lastexp = null;
        Object.keys(flows).forEach((x, i) => {
          if (yr === x) {
            const yar = Object.keys(flows)[i - 1];
            //var flo = flows[Object.keys(flows)[i - 1]];
            //if (flo) last3 = flo.income;
            var povert = flows[yar];
            if (povert) lastexp = povert.expenses;
          }
        });
        //var last3 = null;
        Object.keys(poverty).forEach((x, i) => {
          if (yr === x) {
            const yar = Object.keys(poverty)[i - 1];
            //var flo = flows[Object.keys(flows)[i - 1]];
            //if (flo) last3 = flo.income;
            var povert = poverty[yar];
            if (povert)
              last2 =
                lastexp -
                povert.clothing +
                povert.food +
                povert.rentandutilities +
                povert.standardizedguaranteeschemesmedicine;
          }
        });
        if (last2) {
          growthdebt.push((basics - last2) / last2);
          growthdebtData.push([year, (basics - last2) / last2]);
          //growthcurren.push((income - last3) / last3);
          //growthcurrenData.push([year, (income - last3) / last3]);
        }
* */
